import api from '../../../../../Comum/Services/Api';

const rota = 'simplo7-sincronizar-produtos-elo';

class EmpresaSimplo7SincronizarProdutosEloComunicador {
  public async store(): Promise<any> {
    const response = await api.post(rota);

    return response.data;
  }
}

export default new EmpresaSimplo7SincronizarProdutosEloComunicador();
