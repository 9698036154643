import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import {
  BuscaCnpjCpfEnum,
  FormatarEnum,
  TipoContaBancariaEnum,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import Textarea from '../../../../../../../../Componentes/Inputs/Textarea';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Select from '../../../../../../../../Componentes/Select';
import InputCnpjCpf, {
  IOnBlurInputCnpjCpfValidoEvent,
} from '../../../../../../../../Componentes/Inputs/InputCnpjCpf';
import InputAutoCompleteBanco from '../../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteBanco';
import IPessoa from '../../../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPessoa';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import PesquisaPessoaCnpjCpfDetalhe from '../../../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe';
import IPesquisaPessoaCnpjCpfDetalheRef from '../../../../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPesquisaPessoaCnpjCpfDetalheRef';
import { IModalPessoaRef } from '../../../GeralTab/ListaPessoasRepetidas';
import PessoaObterPorCnpjCpfComunicador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorCnpjCpfComunicador';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';

interface IPessoaDadosBancariosModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const PessoaDadosBancariosDetalhe: React.FC<IPessoaDadosBancariosModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ComercialPessoasPessoa: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();
  const janelaCnpjCpfRef = useRef<IPesquisaPessoaCnpjCpfDetalheRef>(null);
  const modalPessoaRef = useRef<IModalPessoaRef>(null);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handlePesquisarCnpjCpf = useCallback(async (): Promise<void> => {
    const dados = formRefDetalhe.current?.getDataPesquisa();

    await janelaCnpjCpfRef.current?.pesquisarCnpjCpf({
      cnpjCpf: dados.cnpjCpfTitular,
      nomeRazaoSocial: dados.titular,

      cidade: {
        id: undefined,
        nome: undefined,
        estado: {
          sigla: undefined,
        },
      },
      logradouro: undefined,
      bairro: undefined,
      complemento: undefined,
      numero: undefined,
    });
  }, [formRefDetalhe]);

  const handleOnSelecionarPessoa = useCallback(
    (pessoa: IPessoa) => {
      formRefDetalhe.current?.setFieldValue('cnpjCpfTitular', pessoa.cnpjCpf);
      formRefDetalhe.current?.setFieldValue('titular', pessoa.nomeRazaoSocial);
    },
    [formRefDetalhe]
  );

  const handleChangeCnpjCpf = useCallback(
    async (event: IOnBlurInputCnpjCpfValidoEvent) => {
      try {
        if (!event.isZero && event.mudou && event.valor) {
          const response = await PessoaObterPorCnpjCpfComunicador.index({
            params: {
              cnpjCpf: event.valor,
              listaIdRemover: idDetalheRegistro ? [idDetalheRegistro] : [],
            },
          });

          modalPessoaRef.current?.alterarListaPessoa(response);
        }
      } catch (error) {
        TratarErros(error);
      }
    },
    [idDetalheRegistro]
  );

  return (
    <JanelaDetalhe
      titulo="Dados Bancários"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={12} md={12}>
                  <InputAutoCompleteBanco
                    ref={inputRefFocus}
                    label="Banco"
                    name="idBanco"
                    placeholder="Banco"
                    nomeObjeto="banco"
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={12}>
                  <Input
                    label="Agência"
                    name="codigoAgencia"
                    placeholder="Agência"
                    maxLength={20}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Input
                    label="Conta"
                    name="conta"
                    placeholder="Conta"
                    maxLength={20}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Select
                    label="Tipo de Conta"
                    name="tipoConta"
                    placeholder="Tipo de Conta"
                    options={FormatarEnum({
                      enumObj: TipoContaBancariaEnum,
                      nullavel: true,
                    })}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={5} md={12}>
                  <InputCnpjCpf
                    label="CNPJ/CPF do Títular"
                    name="cnpjCpfTitular"
                    placeholder="CNPJ/CPF do Títular"
                    maxLength={14}
                    onBlurValido={async (event) => {
                      refresh();
                      await handleChangeCnpjCpf(event);
                    }}
                    onPesquisarCnpjCpf={handlePesquisarCnpjCpf}
                  />
                  <PesquisaPessoaCnpjCpfDetalhe
                    handleOnSelecionarPessoa={handleOnSelecionarPessoa}
                    tipoBusca={BuscaCnpjCpfEnum.dadosBancarios}
                    ref={janelaCnpjCpfRef}
                  />
                </Col>

                <Col lg={7} md={12}>
                  <Input
                    label="Nome do Títular"
                    name="titular"
                    placeholder="Nome do Títular"
                    maxLength={200}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12}>
                  <Textarea
                    label="Observações"
                    name="observacoes"
                    placeholder="Observações"
                    maxLength={500}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default PessoaDadosBancariosDetalhe;
