import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Modal, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import {
  FormatarDataParaPtBr,
  FormatarEnum,
  TipoFechamentoPeriodoEnum,
} from '@elogestor/util';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import Select from '../../../../../Componentes/Select';
import InputAutoCompleteTagTipoFechamentoPeriodoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoFechamentoPeriodoEnum';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaTipoFechamento =
        parametros.pesquisaAvancada?.listaTipoFechamento;
      if (listaTipoFechamento && listaTipoFechamento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo de Fechamento',
          valor: listaTipoFechamento.map((item) => item.descricao).join(', '),
        });
      }
      const dataFechamentoFinal =
        parametros.pesquisaAvancada?.dataFechamentoFinal;

      const dataFechamentoInicial =
        parametros.pesquisaAvancada?.dataFechamentoInicial;

      if (dataFechamentoInicial || dataFechamentoFinal) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Preríodo Data de Fechamento',
          valor: `${dataFechamentoInicial ? `"${FormatarDataParaPtBr(dataFechamentoInicial)}"` : '∞'} a
           ${dataFechamentoFinal ? `"${FormatarDataParaPtBr(dataFechamentoFinal)}"` : '∞'}`,
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagTipoFechamentoPeriodoEnum
                  label="Tipos de Fechamento"
                  name="listaTipoFechamento"
                />
              </Col>

              <Col lg={12} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Fechamento Inicial"
                  labelDataFinal="Data Fechamento Final"
                  nomeDataInicial="dataFechamentoInicial"
                  nomeDataFinal="dataFechamentoFinal"
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
