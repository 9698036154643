/* eslint-disable no-empty */
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TableDraggable from '../TableDraggable';
import { UseTableDraggable } from '../TableDraggable/HooksTable/TableDraggableContext';
import Paginacao from './Paginacao';
import ITh from './Interface/ITh';
import IPesquisa from './Interface/IPesquisa';
import { UseLista } from './ListaHooks';
import { Container } from './styles';
import TratarErros from '../../Util/Erro/TratarErros';
import PersonalizacaoListaCamposComunicador from '../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import PersonalizacaoListaCamposSalvarTodosComunicador from '../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposSalvarTodosComunicador';
import LoadingDiv from '../LoadingDiv';

interface IDados {
  [key: string]: string | JSX.Element;
}

interface IPesquisarDadosResposta {
  dados: IDados[];
  totalPaginas: number;
}

interface ILista {
  salvarTh?: (newTh: ITh) => Promise<void>;
  salvarListaTh?: (newListaTh: ITh[]) => Promise<void>;
  pesquisarDados(params: IPesquisa): Promise<IPesquisarDadosResposta>;
  permitirAtualizar?: () => Promise<boolean>;
  onClickLinhaModal?(index: number): Promise<void>;
  pesquisarListaTh(): Promise<ITh[]>;
  opcao1000?: boolean;
  paginacaoPadrao?: number;
  detalhe?: boolean;
  headerLista?: any;
  refresh?: boolean;
  loading?: boolean;
  exibirPaginacao?: boolean;
  styleLista?: React.CSSProperties;
}

const BOTAO_DO_MEIO_MOUSE = 1;

const handleSalvarTh = async (newTh: ITh): Promise<void> => {
  try {
    const { id, ...rest } = newTh;
    await PersonalizacaoListaCamposComunicador.update({
      id,
      params: rest,
    });
  } catch (error) {
    TratarErros(error);
  }
};

const handleSalvarListaTh = async (newListaTh: ITh[]): Promise<void> => {
  try {
    await PersonalizacaoListaCamposSalvarTodosComunicador.update({
      params: newListaTh,
    });
  } catch (error) {
    TratarErros(error);
  }
};

const Lista: React.FC<ILista> = ({
  salvarTh = handleSalvarTh,
  salvarListaTh = handleSalvarListaTh,
  pesquisarDados,
  pesquisarListaTh,
  opcao1000 = false,
  paginacaoPadrao = 10,
  detalhe = true,
  headerLista,
  refresh,
  loading = false,
  exibirPaginacao = true,
  permitirAtualizar,
  onClickLinhaModal,
  styleLista,
}) => {
  const navigate = useNavigate();
  const { pathname: path } = useLocation();

  const {
    paginacao: { alterarTotalPaginas },
  } = UseLista();

  const { setParametros } = UseTableDraggable();

  const [dados, setDados] = useState<IDados[]>([]);
  const [listaTh, setListaTh] = useState<ITh[]>([]);

  const handlePermitirAtualizar = useCallback(async (): Promise<boolean> => {
    if (permitirAtualizar) {
      return permitirAtualizar();
    }

    return true;
  }, [permitirAtualizar]);

  const handlePesquisarDados = useCallback(
    async (parametros: IPesquisa) => {
      if (await handlePermitirAtualizar()) {
        setDados([]);

        const { dados: resposta, totalPaginas } =
          await pesquisarDados(parametros);

        setDados(resposta);
        alterarTotalPaginas(totalPaginas);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alterarTotalPaginas]
  );

  useEffect(() => {
    async function SelecionarTh(): Promise<void> {
      const resposta = await pesquisarListaTh();
      setListaTh(resposta);
    }

    SelecionarTh();
  }, [pesquisarListaTh]);

  const handleChangeOrdem = useCallback(
    async (newListaThs: ITh[]) => {
      salvarListaTh(newListaThs);
    },
    [salvarListaTh]
  );

  const handleChangeOrdemPesquisa = useCallback(
    async (order: string, descAsc: string) => {
      if (await handlePermitirAtualizar()) {
        setParametros({
          descAsc,
          order,
        });
      }
    },
    [handlePermitirAtualizar, setParametros]
  );

  const handleChangeTamanho = useCallback(
    async (newTh: ITh) => {
      salvarTh(newTh);
    },
    [salvarTh]
  );

  const handleChangeVisivel = useCallback(
    async (newTh: ITh) => {
      salvarTh(newTh);
    },
    [salvarTh]
  );

  const handleClickLinha = useCallback(
    (
      e: any,
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      index: number
    ) => {
      if (onClickLinhaModal) {
        onClickLinhaModal(index);
        return;
      }

      const abrirEmNovaAba = (url: string): void => {
        window.open(url);
      };

      const navegarParaNovaJanela = (url: string): void => {
        navigate(url);
      };

      if (event.ctrlKey || event.button === BOTAO_DO_MEIO_MOUSE) {
        abrirEmNovaAba(`${path}/detalhe/${e.id}`);
      } else {
        navegarParaNovaJanela(`${path}/detalhe/${e.id}`);
      }
    },
    [navigate, onClickLinhaModal, path]
  );

  const handleGetKey = useCallback((item: any) => {
    return item.id;
  }, []);

  return (
    <>
      <LoadingDiv isLoading={loading} />

      <Container>
        {headerLista && headerLista}

        <div style={{ overflow: 'auto', minHeight: 180, ...styleLista }}>
          <TableDraggable
            listaTh={listaTh}
            listaDado={dados}
            getKey={handleGetKey}
            onChangeOrdem={handleChangeOrdem}
            onChangeOrdemPesquisa={handleChangeOrdemPesquisa}
            onChangeTamanho={handleChangeTamanho}
            onChangeVisivel={handleChangeVisivel}
            onClickLinha={handleClickLinha}
            onPesquisar={handlePesquisarDados}
            permitirAtualizar={handlePermitirAtualizar}
            detalhe={detalhe}
            refresh={refresh}
          />
        </div>

        {exibirPaginacao && (
          <Paginacao
            opcao1000={opcao1000}
            paginacaoPadrao={paginacaoPadrao}
            refresh={refresh}
            permitirAtualizar={handlePermitirAtualizar}
          />
        )}
      </Container>
    </>
  );
};

export default Lista;
