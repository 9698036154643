import React, { useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { FiFile } from 'react-icons/fi/index.mjs';
import { v4 } from 'uuid';
import { GiMoneyStack } from 'react-icons/gi/index.mjs';
import {
  EnumObterValue,
  SituacaoConhecimentoTransporteEnum,
} from '@elogestor/util';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoConhecimentoTransporteModalRodoviario from '../../../../../DocumentosEletronicos/CTe/ImpressaoModalRodoviario/index';
import ImpressaoConhecimentoTransporteModalAereo from '../../../../../DocumentosEletronicos/CTe/ImpressaoModalAereo/index';
import ConhecimentoTransporteTerceiroExportarXmlComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroExportarXmlComunicador';
import VisualizarPDF from '../../../../../../Util/Relatorios/VisualizarPDF';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import ConhecimentoTransporteTerceiroUploadPdfComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Comunicador/ConhecimentoTransporteTerceiroUploadPdfComunicador';
import ConhecimentoTransporteTerceiroImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/NotaFiscalConhecimento/ConhecimentoTransporteTerceiro/Impressao/ConhecimentoTransporteTerceirompressao/ConhecimentoTransporteTerceiroImpressaoComunicador';

const AcoesConhecimentoTransporteTerceiro: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { loading, formRef, getIdDetalheRegistro, handleSubmit } = UseForm();
  const [, setLoading] = useState(false);

  const [showFinanceiro, setShowFinanceiro] = useState(false);

  const idImportacaoXmlAutorizacao = formRef.current?.getFieldValue(
    'conhecimentoTransporteTerceiroTransmissao.idImportacaoXmlAutorizacao'
  );
  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      if (formRef.current?.validarSeAlterou()) {
        const data = formRef.current?.getData();
        const { erro } = await handleSubmit(data);
        return !erro;
      }

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      const idRegistro = getIdDetalheRegistro() || '';

      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response =
        await ConhecimentoTransporteTerceiroImpressaoComunicador.show({
          id: idRegistro,
        });

      if (response.pdf) {
        if (visualizarPdf) {
          VisualizarPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else if (response.impressao.rodo) {
        if (visualizarPdf) {
          await ImpressaoConhecimentoTransporteModalRodoviario.handleVisualizarPdf(
            response.impressao
          );
        }

        blobPdf =
          await ImpressaoConhecimentoTransporteModalRodoviario.handleObterBlobPdf(
            response.impressao
          );
        pdfSalvo = false;
      } else if (response.impressao.aereo) {
        if (visualizarPdf) {
          await ImpressaoConhecimentoTransporteModalAereo.handleVisualizarPdf(
            response.impressao
          );
        }

        blobPdf =
          await ImpressaoConhecimentoTransporteModalAereo.handleObterBlobPdf(
            response.impressao
          );
        pdfSalvo = false;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [getIdDetalheRegistro]
  );

  const handleImprimir = useCallback(async (): Promise<void> => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const dadosPdf = await handleVisualizarObterBlobPdf(true);
      if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
        const chaveAcesso = formRef.current?.getFieldValue(
          'conhecimentoTransporteTerceiroTransmissao.chaveAcesso'
        );
        const situacao = formRef.current?.getFieldValue('situacao');

        const situacaoFormatado = EnumObterValue(
          SituacaoConhecimentoTransporteEnum,
          situacao
        );

        const formData = new FormData();
        formData.append(
          'bloblPdf',
          dadosPdf.blobPdf as any,
          `${chaveAcesso}#situacao:${situacaoFormatado}`
        );

        await ConhecimentoTransporteTerceiroUploadPdfComunicador.update({
          params: formData,
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, handleSalvar, handleVisualizarObterBlobPdf]);

  const handleExportarXml = useCallback(async (): Promise<void> => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const listaResponse =
        await ConhecimentoTransporteTerceiroExportarXmlComunicador.show({
          id: idRegistro,
        });

      for (let i = 0; i < listaResponse.length; i++) {
        const response = listaResponse[i];

        await ImpressaoConhecimentoTransporteModalRodoviario.handleExportarXml(
          response.xml,
          response.nomeArquivo
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();
    await handleSubmit(data);

    setShowFinanceiro(false);
  }, [formRef, handleSubmit]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  return (
    <>
      <SplitAcoes
        disabled={loading}
        id={v4()}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginRight: 10, marginLeft: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading || !idImportacaoXmlAutorizacao}
          onClick={handleImprimir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading || !idImportacaoXmlAutorizacao}
          onClick={handleExportarXml}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <FiFile style={{ marginRight: 5 }} />
            Exportar XML (Download)
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}
    </>
  );
};

export default AcoesConhecimentoTransporteTerceiro;
