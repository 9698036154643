import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/integracao/empresa-simplo7/consultar-situacoes';

interface IEmpresaSimplo7Index {
  params: any;
}

class EmpresaSimplo7ConsultarSituacoesComunicador {
  public async index(params?: IEmpresaSimplo7Index): Promise<any> {
    const response = await api.get(rota, { params: params?.params });

    return response.data;
  }
}

export default new EmpresaSimplo7ConsultarSituacoesComunicador();
