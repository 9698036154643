import { IUsuarioMenuPermissaoValoresAlterar } from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Modal, Row, Table } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { ImArrowRight } from 'react-icons/im/index.mjs';
import { FiRefreshCcw } from 'react-icons/fi/index.mjs';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputCheckbox from '../../../../../../../Componentes/Inputs/InputCheckbox';
import { TBody } from '../../../../../../../Componentes/Styles/TBody';
import UseStep from '../../../../../../../Hooks/UseStep';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { CheckboxContainer } from '../../../../../../Comercial/Pessoas/Pessoa/Detalhe/ContatoTab/ListaDetalheContato/Lista/styles';
import { Step, StepBox, StepContainer, StepNome } from './styles';
import UsuarioReplicarDadosComunicador from '../../../../../../../Comunicador/Configuracao/Usuario/ReplicarDados/Comunicador/UsuarioReplicarDadosComunicador';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';

interface IReplicarPara {
  aberto: boolean;
  fecharFormModal(): void;
}

function RemoverIdUsuarioPermissao(
  listaPermissao: IUsuarioMenuPermissaoValoresAlterar[]
): void {
  listaPermissao.forEach((permissao) => {
    permissao.idUsuarioPermissao = '';
    if (permissao.listaFilho) {
      RemoverIdUsuarioPermissao(permissao.listaFilho);
    }
  });
}

const ReplicarPara: React.FC<IReplicarPara> = ({ aberto, fecharFormModal }) => {
  const { proximoStep, step, setStep } = UseStep(2);
  const formRef = useRef<IFormCiaHandles>(null);
  const formPrincipal = UseForm();
  const nomeUsuario = formPrincipal.formRef.current?.getFieldValue('nome');
  const [listaUsuario, setListaUsuario] = useState<
    {
      id: string;
      nome: string;
    }[]
  >([]);

  useEffect(() => {
    UsuarioReplicarDadosComunicador.index().then((response) => {
      setListaUsuario(
        response.filter((usuario: any) => {
          return usuario.nome !== nomeUsuario;
        })
      );
    });
  }, [nomeUsuario]);

  const handleValidarDados = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          dadosReplicados: Yup.mixed()
            .nullable()
            .test({
              message: 'Nenhum dado a ser copiado foi marcado!',
              test: () => {
                return !(
                  data.dadosReplicados.tipo === undefined &&
                  data.dadosReplicados.ativo === undefined &&
                  data.dadosReplicados.listaPermissao === undefined &&
                  data.dadosReplicados.usuarioLiberacao === undefined
                );
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleValidarUsuarios = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          listaUsuario: Yup.array()
            .nullable()
            .required('Selecione um ou mais usuários a serem atualizados!'),

          dadosReplicados: Yup.mixed()
            .nullable()
            .test({
              message: 'Nenhum dado a ser copiado foi marcado!',
              test: () => {
                return !(
                  data.dadosReplicados.tipo === undefined &&
                  data.dadosReplicados.ativo === undefined &&
                  data.dadosReplicados.listaPermissao === undefined &&
                  data.dadosReplicados.usuarioLiberacao === undefined
                );
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    []
  );

  const handleFechar = useCallback(() => {
    setStep(1);
    fecharFormModal();
  }, [fecharFormModal, setStep]);

  const handleProximoPasso = useCallback(async () => {
    try {
      const data = formRef.current?.getData() as any;

      const dados = {
        listaUsuario: listaUsuario.filter((_, index) => {
          return data.listaUsuario[index];
        }),
        dadosReplicados: {},
      } as any;

      if (data.tipo) {
        dados.dadosReplicados.tipo =
          formPrincipal.formRef.current?.getFieldValue('tipo');
      }
      if (data.ativo) {
        dados.dadosReplicados.ativo =
          formPrincipal.formRef.current?.getFieldValue('ativo');
      }
      if (data.permissoes) {
        dados.dadosReplicados.listaPermissao =
          formPrincipal.formRef.current?.getFieldValue('listaPermissao');
        RemoverIdUsuarioPermissao(dados.dadosReplicados.listaPermissao);
      }

      if (data.liberacoes) {
        dados.dadosReplicados.usuarioLiberacao =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto(
            'usuarioLiberacao'
          );
      }

      if (!(await handleValidarDados(dados))) {
        return { erro: true };
      }

      proximoStep();

      return { erro: false };
    } catch (error) {
      TratarErros(error);
      return { erro: true };
    }
  }, [listaUsuario, handleValidarDados, proximoStep, formPrincipal.formRef]);

  const handleSubmit = useCallback(async () => {
    try {
      const data = formRef.current?.getData() as any;

      const dados = {
        listaUsuario: listaUsuario.filter((_, index) => {
          return data.listaUsuario[index];
        }),
        dadosReplicados: {},
      } as any;

      if (data.tipo) {
        dados.dadosReplicados.tipo =
          formPrincipal.formRef.current?.getFieldValue('tipo');
      }
      if (data.ativo) {
        dados.dadosReplicados.ativo =
          formPrincipal.formRef.current?.getFieldValue('ativo');
      }
      if (data.permissoes) {
        dados.dadosReplicados.listaPermissao =
          formPrincipal.formRef.current?.getFieldValue('listaPermissao');
        RemoverIdUsuarioPermissao(dados.dadosReplicados.listaPermissao);
      }

      if (data.liberacoes) {
        dados.dadosReplicados.usuarioLiberacao =
          formPrincipal.formRef.current?.getFieldValueNomeObjeto(
            'usuarioLiberacao'
          );
      }

      if (!(await handleValidarUsuarios(dados))) {
        return { erro: true };
      }

      // await handleValidarDados(dados);
      // await handleValidarUsuarios(dados);

      await UsuarioReplicarDadosComunicador.update({ params: dados });
      await UsuarioReplicarDadosComunicador.update({ params: dados });

      ToastSucesso('Dados atualizados com sucesso!');
      handleFechar();
      return { erro: false };
    } catch (error) {
      TratarErros(error);
      return { erro: true };
    }
  }, [
    listaUsuario,
    handleValidarUsuarios,
    handleFechar,
    formPrincipal.formRef,
  ]);

  return (
    <Modal show={aberto} onHide={handleFechar} size="lg">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Replicar para outros usuários</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFechar}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <FormCia ref={formRef}>
          <StepContainer>
            <Step>
              <StepBox
                type="button"
                onClick={() => {
                  setStep(1);
                }}
                $selecionado={step === 1}
              >
                1
              </StepBox>
              <StepNome>Dados a serem replicados</StepNome>
            </Step>
            <Step>
              <StepBox
                type="button"
                onClick={() => {
                  setStep(2);
                }}
                $selecionado={step === 2}
              >
                2
              </StepBox>
              <StepNome>
                Usuário para os quais os dados serão atualizados
              </StepNome>
            </Step>
          </StepContainer>

          <div style={{ display: step === 1 ? 'block' : 'none' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th style={{ width: '10%' }} className="lista-texto">
                    <CheckboxContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <InputCheckbox
                        name="copiarTodos"
                        onChange={async (event) => {
                          await formRef.current?.setDataIgnorarUndefined({
                            copiarTodos: event.target.checked,
                            tipo: event.target.checked,
                            ativo: event.target.checked,
                            permissoes: event.target.checked,
                            liberacoes: event.target.checked,
                          });
                        }}
                      />
                    </CheckboxContainer>
                  </th>
                  <th className="lista-texto">
                    <span>Dados a serem copiados</span>
                  </th>
                </tr>
              </thead>

              <TBody>
                <tr>
                  <td className="lista-texto">
                    <CheckboxContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <InputCheckbox name="tipo" />
                    </CheckboxContainer>
                  </td>
                  <td className="lista-texto">
                    <div>Tipo</div>
                  </td>
                </tr>
                <tr>
                  <td className="lista-texto">
                    <CheckboxContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <InputCheckbox name="ativo" />
                    </CheckboxContainer>
                  </td>
                  <td className="lista-texto">
                    <div>Ativo</div>
                  </td>
                </tr>
                <tr>
                  <td className="lista-texto">
                    <CheckboxContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <InputCheckbox name="permissoes" />
                    </CheckboxContainer>
                  </td>
                  <td className="lista-texto">
                    <div>Permissões</div>
                  </td>
                </tr>
                <tr>
                  <td className="lista-texto">
                    <CheckboxContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <InputCheckbox name="liberacoes" />
                    </CheckboxContainer>
                  </td>
                  <td className="lista-texto">
                    <div>Liberações</div>
                  </td>
                </tr>
              </TBody>
            </Table>
          </div>

          <div style={{ display: step === 2 ? 'block' : 'none' }}>
            <Table striped hover bordered variant="light">
              <thead>
                <tr>
                  <th style={{ width: '10%' }} className="lista-texto">
                    <CheckboxContainer
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <InputCheckbox
                        name="todosUsuarios"
                        onChange={async (event) => {
                          const listaUsuarioValor = listaUsuario.map(() => {
                            return event.target.checked;
                          });
                          await formRef.current?.setDataIgnorarUndefined({
                            listaUsuario: listaUsuarioValor,
                          });
                        }}
                      />
                    </CheckboxContainer>
                  </th>
                  <th className="lista-texto">
                    <span>Usuários os quais os dados serão atualizados</span>
                  </th>
                </tr>
              </thead>

              <TBody>
                {listaUsuario.map((usuario, index) => {
                  return (
                    <tr>
                      <td className="lista-texto">
                        <CheckboxContainer
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 0,
                          }}
                        >
                          <InputCheckbox name={`listaUsuario[${index}]`} />
                        </CheckboxContainer>
                      </td>
                      <td className="lista-texto">
                        <div>{usuario.nome}</div>
                      </td>
                    </tr>
                  );
                })}
              </TBody>
            </Table>
          </div>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div>
          <Container>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div style={{ display: step === 1 ? 'block' : 'none' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <button
                    type="button"
                    className="btn-padrao btn-cinza-claro"
                    onClick={handleFechar}
                  >
                    <IoMdReturnLeft />
                    <span style={{ marginLeft: 10 }}>Voltar</span>
                  </button>

                  <button
                    type="button"
                    className="btn-padrao btn-azul-claro"
                    onClick={handleProximoPasso}
                    style={{
                      marginLeft: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ImArrowRight />
                    <span style={{ marginLeft: 10 }}>Próximo passo</span>
                  </button>
                </div>
              </div>

              <div style={{ display: step === 2 ? 'block' : 'none' }}>
                <button
                  type="button"
                  className="btn-padrao btn-cinza-claro"
                  onClick={fecharFormModal}
                >
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </button>

                <button
                  type="button"
                  className="btn-padrao btn-azul-claro"
                  onClick={handleSubmit}
                  style={{ marginLeft: 10 }}
                >
                  <FiRefreshCcw />
                  <span style={{ marginLeft: 10 }}>Atualizar</span>
                </button>
              </div>
            </Row>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReplicarPara;
