/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  TipoProdutoServicoEnum,
  ValidarCodigoBarrasGtin,
  IProdutoValoresAlterar,
  OrigemValorUnitarioIcmsStRetidoEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import ProdutoComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoComunicador';
import OrigemIcmsObterPorNomeComunicador from '../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/OrigemIcmsObterPorNomeComunicador';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const parametros = UseParametros();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);
  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IProdutoValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        dadosDuplicados.ativo = true;
        dadosDuplicados.codigo = null;
        dadosDuplicados.produtoEngenharia.codigoBarras = null;
        dadosDuplicados.produtoEcommerce.productIdTray = null;
        dadosDuplicados.produtoEcommerce.variacaoIdTray = null;
        dadosDuplicados.produtoEcommerce.skuIdTray = null;
        await formRef.current?.setDataDuplicar(dadosDuplicados);

        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idEditar) return;

        const response = await ProdutoComunicador.show({
          id: idEditar,
        });

        await formRef.current?.setDataInicial(response);

        SetarDadosObrigatorios();
        if (
          parametros.BloquearAlteracaoCodigoQuandoMovimentado &&
          response.produtoComMovimento === true
        ) {
          formRef.current?.setFieldDisabled('codigo', true);
        }
        if (
          parametros.BloquearAlteracaoUnidadeMedidaQuandoMovimentado &&
          response.produtoComMovimento === true
        ) {
          formRef.current?.setFieldDisabled(
            'produtoEstoque.unidadeMedida',
            true
          );
        }
      }

      async function SetarDadosPadrao(): Promise<void> {
        const response = await OrigemIcmsObterPorNomeComunicador.index({
          params: {
            nome: 'Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8',
          },
        });

        const origemPadrao = response;

        await formRef.current?.setDataInicial({
          tipoProdutoServico: TipoProdutoServicoEnum.produto,
          ativo: true,
          produtoFiscal: {
            origemIcms: origemPadrao,
            origemValorUnitarioIcmsStRetido:
              OrigemValorUnitarioIcmsStRetidoEnum.ultimaCompra,
          },
          produtoEstoque: {
            controlarEstoque: parametros.ControlarEstoques,
          },
          produtoCusto: {
            tipoCusto: parametros.TipoCustoPadraoNovosProdutos,
            tipoCalculoCustoBlocoH:
              parametros.TipoCalculoCustoBlocoHNovosProdutos,
          },
          produtoEngenharia: {
            quantidadeVolumes:
              parametros.ConsiderarQuantidadeVolumesUnitarioComo1 ? 1 : null,
          },

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idEditar) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [
      getIdDetalheRegistro,
      parametros.ControlarEstoques,
      parametros.ControlarLotes,
      parametros.TipoCustoPadraoNovosProdutos,
      parametros.TipoCalculoCustoBlocoHNovosProdutos,
      redirecionar,
      refresh,
    ]
  );

  const handleValidar = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Descrição é obrigatória!'),
          tipoProdutoServico: Yup.string()
            .nullable()
            .required('Tipo é obrigatório!'),

          codigoBarras: Yup.string()
            .test({
              name: 'Código de Barras',
              message: 'Código de Barras inválido!',
              test: (codigoBarras) => {
                if (!codigoBarras) return true;
                return ValidarCodigoBarrasGtin(codigoBarras);
              },
            })
            .nullable(),

          idGrupoProdutoServico: Yup.mixed()
            .nullable()
            .test({
              message: 'Grupo é obrigatório!',
              test: (value: any) => {
                if (!value && parametros.GrupoObrigatorio) {
                  return false;
                }
                return true;
              },
            }),

          produtoEstoque: Yup.object().shape({
            idUnidadeMedidaEstoque: Yup.string()
              .nullable()
              .required('Unidade de Medida é obrigatória!'),
          }),

          produtoFiscal: Yup.object().shape({
            idNcm: Yup.mixed()
              .nullable()
              .test({
                message: 'NCM é obrigatório!',
                test: (value: any) => {
                  if (
                    data.tipoProdutoServico ===
                      TipoProdutoServicoEnum.produto &&
                    !value
                  )
                    return false;

                  return true;
                },
              }),

            idCest: Yup.string().nullable(),

            idOrigemIcms: Yup.mixed()
              .nullable()
              .test({
                message: 'Origem é obrigatória',
                test: (value: any) => {
                  if (
                    !value &&
                    data.tipoProdutoServico === TipoProdutoServicoEnum.produto
                  ) {
                    return false;
                  }
                  return true;
                },
              }),

            idSpedTipoProduto: Yup.mixed()
              .nullable()
              .test({
                message: 'Tipo Sped é obrigatório!',
                test: (value: any) => {
                  if (!value && parametros.TipoSpedObrigatorio) {
                    return false;
                  }
                  return true;
                },
              }),

            idSpedGeneroItemServico: Yup.string().nullable(),
            idAnp: Yup.string().nullable(),

            idProdutoTributado: Yup.mixed()
              .nullable()
              .test({
                message:
                  'Informada a quantidade tributada e NÃO informado o produto tributado!',
                test: () => {
                  if (data.produtoFiscal.quantidadeTributado)
                    return data.produtoFiscal.idProdutoTributado;
                  return true;
                },
              }),

            quantidadeTributado: Yup.mixed()
              .nullable()
              .test({
                message:
                  'Informado o produto tributado e NÃO informada a quantidade tributada!',
                test: () => {
                  if (data.produtoFiscal.idProdutoTributado)
                    return data.produtoFiscal.quantidadeTributado;
                  return true;
                },
              }),

            origemValorUnitarioIcmsStRetido: Yup.string()
              .required('Origem dos Valores do ICMS ST. retido é Obrigatório!')
              .nullable(),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [parametros.GrupoObrigatorio, parametros.TipoSpedObrigatorio]
  );

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);
        let id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }
        if (id) {
          await ProdutoComunicador.update({ id, params: data });
        } else {
          const response = await ProdutoComunicador.store({ params: data });
          setIdDetalheRegistro(response.id);
          id = response.id;
          formRef.current?.setFieldValue('codigo', response.codigo);
        }

        setLoading(false);
        await formRef.current?.atualizarDataInicial();
        ToastSucesso('Registro Salvo!');

        return { id, erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar, redirecionar, setIdDetalheRegistro]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
