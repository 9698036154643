"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoFechamentoPeriodoEnum;
(function (TipoFechamentoPeriodoEnum) {
    TipoFechamentoPeriodoEnum["estoque"] = "Estoque";
    TipoFechamentoPeriodoEnum["contasReceber"] = "Contas a Receber";
    TipoFechamentoPeriodoEnum["contasPagar"] = "Contas a Pagar";
    TipoFechamentoPeriodoEnum["notasFiscaisSaida"] = "Notas Fiscais de Sa\u00EDda";
    TipoFechamentoPeriodoEnum["notasFiscaisEntrada"] = "Notas Fiscais de Entrada";
})(TipoFechamentoPeriodoEnum || (TipoFechamentoPeriodoEnum = {}));
exports.default = TipoFechamentoPeriodoEnum;
