/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { AiOutlinePrinter, AiOutlineSearch } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { MdCancel, MdDelete } from 'react-icons/md/index.mjs';
import { GiMoneyStack, GiPresent } from 'react-icons/gi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { TbShoppingCartDiscount } from 'react-icons/tb/index.mjs';
import {
  AmbienteEnum,
  EnumObterValue,
  IFrenteCaixaPdvFinalizarVendaValoresInserir,
  ModeloImpressaoConsignadoEnum,
  SituacaoNotaFiscalSaidaEnum,
} from '@elogestor/util';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { HiCash } from 'react-icons/hi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import DescontoAcrescimoDetalhe from './DescontoAcrescimo/Detalhe';
import RepresentantesDetalhe from './Representantes/Detalhe';
import { UseFrenteCaixaPdv } from '../Hooks/FrenteCaixaPdvHook';
import CancelarHook from './Cancelar/Hooks';
import CancelarDetalhe from './Cancelar/Detalhe';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import NotaFiscalSaidaExportarXmlComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaExportarXmlComunicador';
import NotaFiscalSaidaConsultarProtocoloComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaConsultarProtocoloComunicador';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import NotaFiscalConsumidorImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Impressao/NotaFiscalConsumidorImpressao/NotaFiscalConsumidorImpressaoComunicador';
import ImpressaoNotaFiscalConsumidor from '../../../../../DocumentosEletronicos/NFCe/Impressao';
import ImpressaoNotaFiscalComprovanteTef from '../../../../../DocumentosEletronicos/NFCe/ImpressaoComprovanteTef';
import NotaFiscalSaidaUploadPdfComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaUploadPdfComunicador';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import ImprimirDiretoPDF from '../../../../../../Util/Relatorios/ImprimirDiretoPDF';
import TratarValidadeCertificado from '../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import ConsultaEstoqueLista from '../../../../../Suprimentos/Produtos/ConsultaEstoque';
import ConsultaEstoqueListaHook from '../../../../../Suprimentos/Produtos/ConsultaEstoque/Hook';
import { UseLiberacoes } from '../../../../../../Hooks/LiberacoesHook';
import NotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaComunicador';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import NotaFiscalSaidaTransmitirComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/NotaFiscalSaidaTransmitirComunicador';
import { UseFrenteCaixaPdvCalculo } from '../Hooks/FrenteCaixaPdvCalculoHook';
import FaturarRetiradaConsignado from './FaturarRetiradaConsignado';
import FaturarRetiradaConsignadoHook from './FaturarRetiradaConsignado/Hook';
import PedidoVendaComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaComunicador';
import PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoRetiradaConsignadoImpressao/PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador';
import PedidoVendaRetratoRetiradaConsignadoImpressao from '../../../PedidoVenda/Detalhe/AcoesPedidoVenda/Impressao/Template/PedidoVendaRetratoRetiradaConsignadoImpressao';
import PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao from '../../../PedidoVenda/Detalhe/AcoesPedidoVenda/Impressao/Template/PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao';
import ToastAviso from '../../../../../../Util/Toasts/ToastAviso';
import FrenteCaixaPdvObterPagamentosSiTefComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterPagamentosSiTefComunicador';
import { ParcelasHook } from '../AcoesFinalizarVenda/FinalizarVenda/Hooks/ParcelasHook';
import FinalizarVenda from '../AcoesFinalizarVenda/FinalizarVenda';
import FrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicador';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import FrenteCaixaPdvObterNotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterNotaFiscalSaidaComunicador';

interface IAcoesFrenteCaixaPdv {
  permissao?: IPermissao;
}

const AcoesFrenteCaixaPdv: React.FC<IAcoesFrenteCaixaPdv> = ({ permissao }) => {
  const parametros = UseParametros();
  const liberacoes = UseLiberacoes();
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { formRef, refresh, loading, setLoading, handleCarregarDados } =
    UseForm();
  const {
    idNotaFiscalSaida,
    idPreVenda,
    listaIdItemConsignado,
    setListaIdItemConsignado,
    setIdNotaFiscalSaida,
  } = UseFrenteCaixaPdv();
  const { liberar } = UseFrenteCaixaPdvCalculo();
  const { abrirJanela } = UseConfirmacao();

  const valorSubTotalGeral =
    formRef.current?.getFieldValue('valorSubTotalGeral');
  const situacao = formRef.current?.getFieldValue('situacao');
  const numero = formRef.current?.getFieldValue('numero');
  const valorTotalGeral = formRef.current?.getFieldValue('valorTotalGeral');

  const [showEnviarEmail, setShowEnviarEmail] = useState(false);
  const [showCancelar, setShowCancelar] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [showDescontoAcrescimo, setShowDescontoAcrescimo] = useState(false);
  const [showRepresentantes, setShowRepresentantes] = useState(false);
  const [showConsultaEstoque, setShowConsultaEstoque] = useState(false);

  const [showFaturarRetiradaConsignado, setShowFaturarRetiradaConsignado] =
    useState(false);

  const [showValePresente, setShowValePresente] = useState(false);

  const [possuiPagamentoSiTef, setPossuiPagamentoSiTef] = useState(false);

  const showModalRepresentantes = formRef.current?.getFieldValue(
    'modalRepresentantes'
  );

  const cliente = formRef.current?.getFieldValue('idCliente');

  useEffect(() => {
    if (showModalRepresentantes === true) {
      setShowRepresentantes(true);
    }
    formRef.current?.setFieldValue('modalRepresentantes', false);
  }, [formRef, showModalRepresentantes]);

  const handleCarregar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (idNotaFiscalSaida) {
        const response =
          await FrenteCaixaPdvObterPagamentosSiTefComunicador.show({
            id: idNotaFiscalSaida,
          });

        if (response && response.length > 0) {
          setPossuiPagamentoSiTef(true);
        } else {
          setPossuiPagamentoSiTef(false);
        }
      }

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [idNotaFiscalSaida, refresh, setLoading]);

  useEffect(() => {
    handleCarregar();
  }, [handleCarregar, handleCarregarDados, idNotaFiscalSaida]);

  const handleVisualizarObterBlobPdfNotaFiscalSaida = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response = await NotaFiscalConsumidorImpressaoComunicador.show({
        id: String(idNotaFiscalSaida),
      });

      if (response.pdf) {
        if (visualizarPdf) {
          await ImprimirDiretoPDF({ dados: response.pdf });
        }
        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await ImpressaoNotaFiscalConsumidor.handleImprimirDireto(
            response.impressao
          );
        }

        blobPdf = await ImpressaoNotaFiscalConsumidor.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      if (
        response.listaMovimentoPortadorTef &&
        response.listaMovimentoPortadorTef.length > 0
      ) {
        for (let i = 0; i < response.listaMovimentoPortadorTef.length; i++) {
          const movimentoPortadorTef = response.listaMovimentoPortadorTef[i];

          if (movimentoPortadorTef.comprovanteCliente) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteCliente
            );
          }

          if (movimentoPortadorTef.comprovanteEstabelecimento) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteEstabelecimento
            );
          }

          if (movimentoPortadorTef.comprovanteClienteCancelamento) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteClienteCancelamento
            );
          }

          if (movimentoPortadorTef.comprovanteEstabelecimentoCancelamento) {
            await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
              movimentoPortadorTef.comprovanteEstabelecimentoCancelamento
            );
          }
        }
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [idNotaFiscalSaida]
  );

  const handleVisualizarObterBlobPdfConsignado = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
    }> => {
      let blobPdf: Blob | undefined;

      if (listaIdItemConsignado) {
        switch (parametros.ModeloImpressaoConsignado) {
          case ModeloImpressaoConsignadoEnum.termica80mm:
            {
              const response =
                await PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador.show(
                  { params: { idPedidoVendaItem: listaIdItemConsignado[0] } }
                );

              if (visualizarPdf) {
                await PedidoVendaRetratoRetiradaConsignadoImpressao.handleVisualizarPdf(
                  response
                );
              } else {
                blobPdf =
                  await PedidoVendaRetratoRetiradaConsignadoImpressao.handleObterBlobPdf(
                    response
                  );
              }
            }
            break;

          case ModeloImpressaoConsignadoEnum.A4Paisagem2Vias:
            {
              const response =
                await PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador.show(
                  { params: { idPedidoVendaItem: listaIdItemConsignado[0] } }
                );

              if (visualizarPdf) {
                await PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao.handleVisualizarPdf(
                  response
                );
              } else {
                blobPdf =
                  await PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao.handleObterBlobPdf(
                    response
                  );
              }
            }
            break;

          default:
            ToastAviso(
              'Selecione um Modelo de Impressão para a Retirada Consignada, nos Parâmetros!'
            );
        }
      }

      return {
        blobPdf,
      };
    },
    [listaIdItemConsignado, parametros.ModeloImpressaoConsignado]
  );

  const handleTransmitirNotaFiscalSaida =
    useCallback(async (): Promise<boolean> => {
      let notaTransmistidaComSucesso = false;

      if (idNotaFiscalSaida) {
        try {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span style={{ fontSize: 20 }}>
                A venda já foi finalizada, mas não transmitida. Deseja
                transmitir a venda?
              </span>
            ),
            confimar: 'Sim',
            cancelar: 'Não',
          });

          if (resposta) {
            await TratarValidadeCertificado();

            const responseTransmitir =
              await NotaFiscalSaidaTransmitirComunicador.update({
                id: idNotaFiscalSaida,
              });

            if (responseTransmitir.mensagemErro) {
              notaTransmistidaComSucesso = false;
              ToastErro(responseTransmitir.mensagemErro, { autoClose: 4000 });
            } else {
              notaTransmistidaComSucesso = true;
              ToastSucesso(responseTransmitir.mensagem);
            }
          }
        } catch (error) {
          TratarErros(error);
          notaTransmistidaComSucesso = false;
        }
      }

      return notaTransmistidaComSucesso;
    }, [abrirJanela, idNotaFiscalSaida]);

  const handleImprimir = useCallback(async () => {
    try {
      setLoading(true);

      const chaveAcesso = formRef.current?.getFieldValue('chaveAcesso');
      const ambiente = parametros.AmbienteTransmissaoConhecimentoTransporte;

      const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);
      const situacaoFormatado = EnumObterValue(
        SituacaoNotaFiscalSaidaEnum,
        situacao
      );

      if (idNotaFiscalSaida) {
        if (
          situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao ||
          situacao === SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
          situacao === SituacaoNotaFiscalSaidaEnum.rejeitada
        ) {
          if (!(await handleTransmitirNotaFiscalSaida())) {
            const resposta = await abrirJanela({
              titulo: <h2>Confirmação</h2>,
              mensagem: (
                <span style={{ fontSize: 20 }}>
                  Documento Fiscal não transmitido, deseja imprimir os
                  comprovantes do TEF?
                </span>
              ),
              confimar: 'Sim',
              cancelar: 'Não',
            });

            if (resposta) {
              const response =
                await NotaFiscalConsumidorImpressaoComunicador.show({
                  id: String(idNotaFiscalSaida),
                });

              if (
                response.listaMovimentoPortadorTef &&
                response.listaMovimentoPortadorTef.length > 0
              ) {
                for (
                  let i = 0;
                  i < response.listaMovimentoPortadorTef.length;
                  i++
                ) {
                  const movimentoPortadorTef =
                    response.listaMovimentoPortadorTef[i];

                  if (movimentoPortadorTef.comprovanteCliente) {
                    await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                      movimentoPortadorTef.comprovanteCliente
                    );
                  }

                  if (movimentoPortadorTef.comprovanteEstabelecimento) {
                    await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                      movimentoPortadorTef.comprovanteEstabelecimento
                    );
                  }

                  if (movimentoPortadorTef.comprovanteClienteCancelamento) {
                    await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                      movimentoPortadorTef.comprovanteClienteCancelamento
                    );
                  }

                  if (
                    movimentoPortadorTef.comprovanteEstabelecimentoCancelamento
                  ) {
                    await ImpressaoNotaFiscalComprovanteTef.handleVisualizarPdf(
                      movimentoPortadorTef.comprovanteEstabelecimentoCancelamento
                    );
                  }
                }
              }
            }

            setLoading(false);
            return;
          }
        }

        const dadosPdf =
          await handleVisualizarObterBlobPdfNotaFiscalSaida(true);

        if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
          const formData = new FormData();
          formData.append(
            'bloblPdf',
            dadosPdf.blobPdf as any,
            `${chaveAcesso}#ambiente:${ambienteFormatado}#situacao:${situacaoFormatado}`
          );

          await NotaFiscalSaidaUploadPdfComunicador.update({
            params: formData,
          });
        }
      } else if (listaIdItemConsignado) {
        await handleVisualizarObterBlobPdfConsignado(true);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    formRef,
    handleTransmitirNotaFiscalSaida,
    handleVisualizarObterBlobPdfConsignado,
    handleVisualizarObterBlobPdfNotaFiscalSaida,
    idNotaFiscalSaida,
    listaIdItemConsignado,
    parametros.AmbienteTransmissaoConhecimentoTransporte,
    setLoading,
    situacao,
  ]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    setShowEnviarEmail(true);
  }, []);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const idCliente = formRef.current?.getFieldValue('idCliente');

      let listaDestinatario: any[] = [];
      if (idCliente) {
        const responseDestinatario =
          await PessoaContatoObterEmailComunicador.index({
            params: {
              listaIdPessoa: [idCliente],
              direcionarBoletos: false,
              direcionarDocumentosFiscais: true,
              direcionarOrcamento: false,
              direcionarPedidoVenda: false,
              direcionarCotacaoCompra: false,
              direcionarPedidoCompra: false,
              direcionarOrdemServico: false,
            },
          });

        listaDestinatario = responseDestinatario.map((valor: string) => {
          return { email: valor };
        });
      }

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      if (responseRemetente.length === 0) {
        ToastErro('Empresa não Possui uma Conta Email Cadastrada!');
        return;
      }
      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];
      const anexo = await handleVisualizarObterBlobPdfNotaFiscalSaida(false);
      const data = new Uint8Array((anexo as any).blobPdf.data);
      const blobPdf = new Blob([data], {
        type: 'application/pdf',
      });

      listaAnexo.push({
        nomeArquivo: `Nota Fiscal de Consumidor ${numero}.pdf`,
        file: blobPdf,
        permitirExcluir: false,
      });

      const listaResponseXml = await NotaFiscalSaidaExportarXmlComunicador.show(
        { id: String(idNotaFiscalSaida) }
      );

      for (let i = 0; i < listaResponseXml.length; i++) {
        const responseXml = listaResponseXml[i];

        const utf8Content = new TextEncoder().encode(responseXml as any);
        const blobXml = new Blob([utf8Content], {
          type: 'text/xml;charset=UTF-8',
        });

        listaAnexo.push({
          nomeArquivo: `${responseXml.nomeArquivo}.xml`,
          file: blobXml,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Nota Fiscal de Consumidor número ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [
      formRef,
      handleVisualizarObterBlobPdfNotaFiscalSaida,
      idNotaFiscalSaida,
      numero,
    ]
  );

  // #endregion Email

  // #region Cancelar

  const handleCancelar = useCallback(async () => {
    if (!liberacoes.permiteCancelarCupon) {
      ToastErro(
        'Usuário sem permissão para cancelar Cupom/Nota de Consumidor!'
      );

      setShowCancelar(false);
    } else {
      setShowCancelar(true);
    }
  }, [liberacoes.permiteCancelarCupon]);

  const handleCancelarSalvarFormModal = useCallback(async () => {
    setShowCancelar(false);

    await handleCarregarDados();
    await handleImprimir();
    await handleCarregar();
  }, [handleCarregar, handleCarregarDados, handleImprimir]);

  const handleCancelarFecharFormModal = useCallback(async () => {
    setShowCancelar(false);

    await handleCarregar();
  }, [handleCarregar]);

  // #endregion Cancelar

  // #region Excluir

  const handleExcluir = useCallback(async () => {
    try {
      if (!idNotaFiscalSaida && !idPreVenda && !listaIdItemConsignado) return;

      setLoading(true);

      if (idNotaFiscalSaida) {
        if (!liberacoes.permiteExcluirCupon) {
          ToastErro(
            'Usuário sem permissão para excluir Cupom/Nota de Consumidor!'
          );
        } else {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span style={{ fontSize: 20 }}>
                Deseja realmente excluir o Cupon?
              </span>
            ),
          });

          if (!resposta) {
            setLoading(false);
            return;
          }

          const response = await NotaFiscalSaidaComunicador.delete({
            id: String(idNotaFiscalSaida),
          });

          if (response) {
            ToastSucesso('Registro Deletado');
          }

          await liberar();
          refresh();
        }
      } else if (idPreVenda) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja realmente excluir a Pré Venda?
            </span>
          ),
        });

        if (!resposta) {
          setLoading(false);
          return;
        }

        const response = await PedidoVendaComunicador.delete({
          id: String(idPreVenda),
        });

        if (response) {
          ToastSucesso('Registro Deletado');
        }

        await liberar();
        refresh();
      } else if (listaIdItemConsignado) {
        if (!liberacoes.permiteExcluirConsignado) {
          ToastErro('Usuário sem permissão para excluir Consignado!');
        } else {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,
            mensagem: (
              <span style={{ fontSize: 20 }}>
                Deseja realmente excluir o consignado (todos os itens desse
                consignado serão excluídos)?
              </span>
            ),
          });

          if (!resposta) {
            setLoading(false);
            return;
          }

          const response = await PedidoVendaComunicador.delete({
            idPedidoVendaItem: listaIdItemConsignado[0],
          });

          if (response) {
            ToastSucesso('Registro Deletado');
          }

          await liberar();
          refresh();
        }

        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    idNotaFiscalSaida,
    idPreVenda,
    liberacoes.permiteExcluirConsignado,
    liberacoes.permiteExcluirCupon,
    liberar,
    listaIdItemConsignado,
    refresh,
    setLoading,
  ]);

  // #endregion Excluir

  const handleConsultarProtocolo = useCallback(async () => {
    try {
      if (!idNotaFiscalSaida) return;

      setLoading(true);

      await TratarValidadeCertificado();

      const response =
        await NotaFiscalSaidaConsultarProtocoloComunicador.update({
          id: String(idNotaFiscalSaida),
        });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      await handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleCarregarDados, handleImprimir, idNotaFiscalSaida, setLoading]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    setShowFinanceiro(true);
  }, []);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleDevolucao = useCallback(async () => {
    try {
      setLoading(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [setLoading]);

  // #region Vale Presente

  const handleValePresente = useCallback(async () => {
    setShowValePresente(true);
  }, []);

  const handleValePresenteSalvarFormModal = useCallback(
    async (
      dados: IFrenteCaixaPdvFinalizarVendaValoresInserir,
      idNotaFiscal?: string | null
    ) => {
      try {
        setLoading(true);

        dados = {
          ...dados,
          idNotaFiscalSaida: idNotaFiscal,
        };

        const response =
          await FrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicador.store(
            {
              params: {
                ...dados,
                tornarDisponivelValePresente: true,
              },
            }
          );

        if (!response.idNotaFiscalSaida) {
          idNotaFiscal = null;
          setIdNotaFiscalSaida(null);
          refresh();
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        if (idNotaFiscal) {
          const response =
            await FrenteCaixaPdvObterNotaFiscalSaidaComunicador.show({
              id: idNotaFiscal,
            });

          if (response.financeiroConferido) {
            await handleTransmitirNotaFiscalSaida();
          }
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      } finally {
        setShowValePresente(false);
        await Sleep(100);
        await liberar();
      }
    },
    [
      handleTransmitirNotaFiscalSaida,
      liberar,
      refresh,
      setIdNotaFiscalSaida,
      setLoading,
    ]
  );

  const handleValePresenteFecharFormModal = useCallback(async () => {
    setShowValePresente(false);
  }, []);

  // #endregion Vale Presente

  // #region Pesquisar Consignado

  const handleFaturarRetiradaConsignado = useCallback(async () => {
    setShowFaturarRetiradaConsignado(true);
  }, []);

  const handleFaturarRetiradaConsignadoSelecionarFormModal = useCallback(
    async (listaIdItemConsignadoSelecionado: string[]) => {
      try {
        setLoading(true);

        await liberar();

        setShowFaturarRetiradaConsignado(false);
        setListaIdItemConsignado(listaIdItemConsignadoSelecionado);
        await handleCarregarDados();

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [handleCarregarDados, liberar, setListaIdItemConsignado, setLoading]
  );

  const handleFaturarRetiradaConsignadoFecharFormModal =
    useCallback(async () => {
      setShowFaturarRetiradaConsignado(false);
    }, []);

  // #endregion Pesquisar Consignado

  // #region Desconto / Acrescimo

  const handleDescontoAcrescimo = useCallback(async () => {
    setShowDescontoAcrescimo(true);
  }, []);

  const handleDescontoAcrescimoSalvarFormModal = useCallback(async () => {
    setShowDescontoAcrescimo(false);
  }, []);

  const handleDescontoAcrescimoFecharFormModal = useCallback(async () => {
    setShowDescontoAcrescimo(false);
  }, []);

  // #endregion Desconto / Acrescimo

  // #region Representantes

  const handleRepresentantes = useCallback(async () => {
    setShowRepresentantes(true);
  }, []);

  const handleRepresentantesSalvarFormModal = useCallback(async () => {
    setShowRepresentantes(false);
  }, []);

  const handleRepresentantesFecharFormModal = useCallback(async () => {
    setShowRepresentantes(false);
  }, []);

  // #endregion Representantes

  // #region Liquidação em Lote

  const handleLiquidacaoEmLote = useCallback(async () => {
    if (!cliente) {
      ToastErro('Cliente deve ser informado!');
      return;
    }

    const dados = formRef?.current?.getDataPesquisa();

    try {
      setLoading(true);

      localStorage.setItem(
        '@EloGestorle:pesquisaAvancada',
        JSON.stringify({
          cliente: dados.idCliente,
        })
      );

      window.open('/financeiro/movimentacoes/liquidacao-contas-em-lote');

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [cliente, formRef, setLoading]);

  // #endregion Liquidação em Lote

  // #region Consulta Estoques

  const handleConsultarEstoques = useCallback(async () => {
    setShowConsultaEstoque(true);
  }, []);

  const handleConsultarEstoqueFecharFormModal = useCallback(async () => {
    setShowConsultaEstoque(false);
  }, []);

  // #endregion Consulta Estoques

  return (
    <>
      <FaturarRetiradaConsignadoHook>
        <SplitAcoes
          id={v4()}
          className="btn-group"
          style={{ width: 190, minWidth: 50 }}
          title={
            <div>
              <TextoLoading loading={loading}>
                <CgMoreO />
                {telaGrande && (
                  <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
                )}
              </TextoLoading>
            </div>
          }
          disabled={loading}
        >
          <Dropdown.Item
            disabled={
              loading ||
              (!idNotaFiscalSaida && !listaIdItemConsignado) ||
              String(numero).includes(';')
            }
            onClick={handleImprimir}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlinePrinter style={{ marginRight: 5 }} />
              Imprimir
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={loading || !idNotaFiscalSaida}
            onClick={handleEnviarPorEmail}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <BiMailSend style={{ marginRight: 5 }} />
              Enviar por E-mail
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !idNotaFiscalSaida ||
              (!possuiPagamentoSiTef &&
                (situacao === SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
                  situacao === SituacaoNotaFiscalSaidaEnum.emTransmissao ||
                  situacao === SituacaoNotaFiscalSaidaEnum.rejeitada ||
                  situacao === SituacaoNotaFiscalSaidaEnum.denegada))
            }
            onClick={handleCancelar}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <MdCancel style={{ marginRight: 5 }} />
              Cancelar
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              (!idNotaFiscalSaida && !idPreVenda && !listaIdItemConsignado) ||
              (!!idNotaFiscalSaida &&
                (situacao === SituacaoNotaFiscalSaidaEnum.autorizada ||
                  situacao === SituacaoNotaFiscalSaidaEnum.cancelada ||
                  situacao === SituacaoNotaFiscalSaidaEnum.denegada)) ||
              String(numero).includes(';')
            }
            onClick={handleExcluir}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <MdDelete style={{ marginRight: 5 }} />
              Excluir
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !idNotaFiscalSaida ||
              situacao === SituacaoNotaFiscalSaidaEnum.denegada ||
              situacao === SituacaoNotaFiscalSaidaEnum.cancelada
            }
            onClick={handleConsultarProtocolo}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <BiDetail style={{ marginRight: 5 }} />
              Consultar Protocolo NFC-e
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={loading || !idNotaFiscalSaida}
            onClick={handleFinanceiro}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <GiMoneyStack style={{ marginRight: 5 }} />
              Financeiro
            </span>
          </Dropdown.Item>

          <Dropdown.Item disabled={loading} onClick={handleDevolucao}>
            <span style={{ display: 'flex', fontSize: 16 }}>
              <IoMdReturnLeft style={{ marginRight: 5 }} />
              Devolução
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !!idNotaFiscalSaida ||
              !!idPreVenda ||
              !!listaIdItemConsignado ||
              valorTotalGeral !== 0
            }
            onClick={handleValePresente}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <GiPresent style={{ marginRight: 5 }} />
              Vale Presente
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={
              loading ||
              !!idNotaFiscalSaida ||
              !!idPreVenda ||
              !!listaIdItemConsignado
            }
            onClick={handleFaturarRetiradaConsignado}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <BiDetail style={{ marginRight: 5 }} />
              Faturar Consignado
            </span>
          </Dropdown.Item>

          <Dropdown.Item
            disabled={loading || valorSubTotalGeral === 0}
            onClick={handleDescontoAcrescimo}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <TbShoppingCartDiscount style={{ marginRight: 5 }} />
              Desconto / Acréscimo
            </span>
          </Dropdown.Item>

          <Dropdown.Item disabled={loading} onClick={handleRepresentantes}>
            <span style={{ display: 'flex', fontSize: 16 }}>
              <BiDetail style={{ marginRight: 5 }} />
              Representantes
            </span>
          </Dropdown.Item>

          <Dropdown.Item disabled={loading} onClick={handleConsultarEstoques}>
            <span style={{ display: 'flex', fontSize: 16 }}>
              <AiOutlineSearch style={{ marginRight: 5 }} />
              Consultar Estoque
            </span>
          </Dropdown.Item>

          <Dropdown.Item disabled={loading} onClick={handleLiquidacaoEmLote}>
            <span style={{ display: 'flex', fontSize: 16 }}>
              <HiCash style={{ marginRight: 5 }} />
              Liquidação em Lote
            </span>
          </Dropdown.Item>
        </SplitAcoes>

        {showEnviarEmail && (
          <JanelaEnvioEmail
            titulo="Envio de Nota Fiscal de Consumidor por E-mail"
            mensagemPadrao={`Segue em anexo a Nota Fiscal de Consumidor número ${numero}`}
            direcionarDocumentosFiscais
            onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
            onFecharFormModal={() => {
              setShowEnviarEmail(false);
            }}
          />
        )}

        {showCancelar && (
          <CancelarHook>
            <CancelarDetalhe
              onSalvarFormModal={handleCancelarSalvarFormModal}
              onFecharFormModal={handleCancelarFecharFormModal}
            />
          </CancelarHook>
        )}

        {showFinanceiro && (
          <FinanceiroDetalhe
            onSalvarFormModal={handleFinanceiroSalvarFormModal}
            onFecharFormModal={handleFinanceiroFecharFormModal}
          />
        )}

        {showDescontoAcrescimo && (
          <DescontoAcrescimoDetalhe
            onSalvarFormModal={handleDescontoAcrescimoSalvarFormModal}
            onFecharFormModal={handleDescontoAcrescimoFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {showRepresentantes && (
          <RepresentantesDetalhe
            onSalvarFormModal={handleRepresentantesSalvarFormModal}
            onFecharFormModal={handleRepresentantesFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {showConsultaEstoque && (
          <ConsultaEstoqueListaHook>
            <ConsultaEstoqueLista
              titulo="Consulta Estoque"
              onFecharFormModal={handleConsultarEstoqueFecharFormModal}
              loading={loading}
              setLoading={setLoading}
            />
          </ConsultaEstoqueListaHook>
        )}

        {showFaturarRetiradaConsignado && (
          <FaturarRetiradaConsignado
            titulo="Seleção de Consignados para Faturamento/Devolução"
            onSelecionarListaItemFormModal={
              handleFaturarRetiradaConsignadoSelecionarFormModal
            }
            onFecharFormModal={handleFaturarRetiradaConsignadoFecharFormModal}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {showValePresente && (
          <ParcelasHook>
            <FinalizarVenda
              tituloJanela="Vale Presente"
              onFinalizarFormModal={handleValePresenteSalvarFormModal}
              onFecharFormModal={handleValePresenteFecharFormModal}
              loading={loading}
              setLoading={setLoading}
              gerarNotaFiscalSaida
              isValePresente
            />
          </ParcelasHook>
        )}
      </FaturarRetiradaConsignadoHook>
    </>
  );
};

export default AcoesFrenteCaixaPdv;
