import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPedidoVendaPaisagemComPesoVolumeFinanceiroImpressao,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoPDF {
  dados: IPedidoVendaPaisagemComPesoVolumeFinanceiroImpressao;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  headerEmpresa: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5,
  },

  headerCliente: {
    position: 'relative',
    marginBottom: 5,
  },

  data: {
    fontSize: 8,
    width: '100%',
    flex: 1,
  },
});

const PedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoPDF: React.FC<
  IPedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.headerEmpresa} fixed>
          <View
            style={{
              width: '25%',
              alignItems: 'center',
              marginTop: '10pt',
              marginBottom: '10pt',
            }}
          >
            {dados.caminhoLogo !== '' && (
              <Image
                src={dados.caminhoLogo}
                style={{ width: 120, borderRadius: 0 }}
              />
            )}
          </View>
          <View style={{ width: '50%', alignItems: 'center' }}>
            <View style={{ marginTop: '10pt', alignItems: 'center' }}>
              <Text style={{ fontSize: 11, fontWeight: 'bold' }}>
                PEDIDO DE VENDA
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {dados.empresa.nomeRazaoSocial}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {`CNPJ/CPF: ${dados.empresa.cnpjCpf} `}
                {dados.empresa.inscricaoEstadual &&
                  `/ IE: ${dados.empresa.inscricaoEstadual}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.logradouro}
                {`, ${dados.empresa.empresaEndereco.numero}`}
                {`, ${dados.empresa.empresaEndereco.bairro}`}
                {`, ${dados.empresa.empresaEndereco.complemento}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.empresaEndereco.cep}
                {` - ${dados.empresa.empresaEndereco.cidade.nome}`}
                {` - ${dados.empresa.empresaEndereco.cidade.estado.sigla}`}
              </Text>
              <Text style={{ fontSize: 10 }}>
                {dados.empresa.telefone && `${dados.empresa.telefone}, `}
                {dados.empresa.email && dados.empresa.email}
              </Text>
            </View>
          </View>

          <View
            style={{ width: '25%', height: '100%', alignItems: 'flex-end' }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                marginBottom: '10pt',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {`Número: `}
                  </Text>
                </View>
                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 12, fontWeight: 'bold' }}>
                    {dados.numero}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '50%', alignItems: 'flex-end' }}>
                  <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {`Data Emissão: `}
                  </Text>
                </View>

                <View style={{ width: '50%', alignItems: 'flex-start' }}>
                  <Text style={{ fontSize: 10 }}>
                    {FormatarDataParaPtBr(dados.dataHoraEmissao)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerCliente}>
          <View style={{ width: '100%' }}>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  borderTop: '1pt solid black',
                  backgroundColor: '#E4E4E4',
                }}
              >
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {'Cliente: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {`${dados.pedidoVendaPessoa.pessoa.codigo} - ${dados.pedidoVendaPessoa.pessoa.nomeRazaoSocial}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {`Cnpj/Cpf: `}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.pedidoVendaPessoa.pessoa.cnpjCpf}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {`Endereço: `}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.pedidoVendaPessoaEndereco.logradouro &&
                    `${dados.pedidoVendaPessoaEndereco.logradouro}, `}
                  {dados.pedidoVendaPessoaEndereco.numero &&
                    `${dados.pedidoVendaPessoaEndereco.numero}, `}
                  {dados.pedidoVendaPessoaEndereco.bairro &&
                    `${dados.pedidoVendaPessoaEndereco.bairro}, `}
                  {dados.pedidoVendaPessoaEndereco.complemento &&
                    dados.pedidoVendaPessoaEndereco.complemento}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {'Cidade: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.pedidoVendaPessoaEndereco?.cidade &&
                    `${dados.pedidoVendaPessoaEndereco?.cidade?.nome} - ${dados.pedidoVendaPessoaEndereco?.cidade?.estado.sigla}`}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold', width: '9%' }}>
                  {'Cep: '}
                </Text>
                <Text style={{ fontSize: 10, width: '91%' }}>
                  {dados.pedidoVendaPessoaEndereco.cep}
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row', width: '60%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '15%' }}
                  >
                    {'E-mail: '}
                  </Text>
                  <Text style={{ fontSize: 10, width: '85%' }}>
                    {dados.pedidoVendaPessoa.pessoa.email}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', width: '40%' }}>
                  <Text
                    style={{ fontSize: 10, fontWeight: 'bold', width: '20%' }}
                  >
                    {'Telefone: '}
                  </Text>
                  <Text style={{ fontSize: 10, width: '80%' }}>
                    {dados.pedidoVendaPessoa.pessoa.telefone}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.data}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderTop: '1pt solid black',
              borderBottom: '1pt solid black',
              backgroundColor: '#cdcdcd',
              fontSize: 9,
            }}
          >
            <View style={{ width: '4%', alignItems: 'flex-end' }}>
              <Text>Seq.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '6%', alignItems: 'flex-end' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '35%' }}>
              <Text>Descrição</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '4%' }}>
              <Text>UN</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '3%', alignItems: 'flex-end' }}>
              <Text>Qtde.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Unit. (R$)</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Val. Produto</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Val. Desc.</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>Val. com Impostos</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Peso Líquido</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>Peso Bruto</Text>
            </View>
          </View>

          {dados.listaPedidoVendaItem?.map((dado, index) => {
            return (
              <View
                key={dado.ordem}
                style={{
                  flexDirection: 'row',
                  backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                }}
              >
                <View style={{ width: '4%', alignItems: 'flex-end' }}>
                  <Text>{dado.ordem}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '6%', alignItems: 'flex-end' }}>
                  <Text>{dado.produto.codigo}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '35%' }}>
                  <Text>{dado.produto.descricao}</Text>
                  <Text>{dado.informacoesAdicionaisProduto}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '4%' }}>
                  <Text>{dado.unidadeMedida.sigla}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '3%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.quantidade).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '7%', alignItems: 'flex-end' }}>
                  <Text>{Number(dado.valorUnitario).FormatarParaPtBr()}</Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '7%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorTotalProduto).FormatarParaPtBr()}
                  </Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorDesconto).FormatarParaPtBr() || ''}
                  </Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '5%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(dado.valorTotalBruto).FormatarParaPtBr() || ''}
                  </Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '7%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(
                      dado.pedidoVendaItemEngenharia.pesoLiquido
                    ).FormatarParaPtBr()}
                  </Text>
                </View>
                <View style={{ width: '1%' }} />

                <View style={{ width: '7%', alignItems: 'flex-end' }}>
                  <Text>
                    {Number(
                      dado.pedidoVendaItemEngenharia.pesoBruto
                    ).FormatarParaPtBr()}
                  </Text>
                </View>
              </View>
            );
          })}

          <View
            style={{
              flexDirection: 'row',
              borderTop: '1px solid black',
              fontWeight: 'bold',
              fontSize: 9,
            }}
          >
            <View style={{ width: '53%', alignItems: 'flex-end' }} />

            <View style={{ width: '3%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.somatorioTotalQuantidade).FormatarParaPtBr()}
              </Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '8%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.somatorioTotalValorProduto).FormatarParaPtBr()}
              </Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.somatorioTotalValorDesconto).FormatarParaPtBr() ||
                  ''}
              </Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%', alignItems: 'flex-end' }}>
              <Text>
                {Number(
                  dados.somatorioTotalValorComImpostos
                ).FormatarParaPtBr() || ''}
              </Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.somatorioTotalPesoLiquido).FormatarParaPtBr()}
              </Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '7%', alignItems: 'flex-end' }}>
              <Text>
                {Number(dados.somatorioTotalPesoBruto).FormatarParaPtBr()}
              </Text>
            </View>
          </View>

          <View
            style={{
              marginTop: '10pt',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ alignItems: 'flex-start' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Número de volumes: `}
                </Text>
              </View>
              <View>
                <Text style={{ fontSize: 10 }}>
                  {Number(dados.pedidoVendaTransporteVolume.quantidade)}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ alignItems: 'flex-start' }}>
                <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                  {`Condição de pagamento: `}
                </Text>
              </View>

              <View>
                <Text style={{ fontSize: 10 }}>
                  {dados.listaConta[0].condicaoPagamento}
                </Text>
              </View>
            </View>
          </View>

          {dados.observacoesComerciais && (
            <View
              style={{
                marginTop: '10pt',
                padding: '4pt',
                backgroundColor: '#eeeeee',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                  {dados.observacoesComerciais
                    ? 'OBSERVAÇÕES COMERCIAIS: '
                    : ''}
                </Text>
                <Text style={{ fontSize: 8, width: '100%', marginTop: '5pt' }}>
                  {dados.observacoesComerciais
                    ? dados.observacoesComerciais
                    : ''}
                </Text>
              </View>
            </View>
          )}
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            Impresso por {usuarioLogado.nome} em{' '}
            {FormatarDataParaPtBr(dados.dataHoraAtual)} às
            {` ${FormatarHoraParaPtBr(dados.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoPDF;
