import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-forma-pagamento/ordem';

interface IRegraEscolhaFormaPagamentoOrdemComunicadorUpdate {
  params: any;
}

class RegraEscolhaFormaPagamentoOrdemComunicador {
  public async update(
    params: IRegraEscolhaFormaPagamentoOrdemComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);

    return response.data;
  }
}

export default new RegraEscolhaFormaPagamentoOrdemComunicador();
