/* eslint-disable default-case */
import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';

import { BiTrash } from 'react-icons/bi/index.mjs';
import { FaSync } from 'react-icons/fa/index.mjs';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import EmpresaFw7Comunicador from '../../../../../Comunicador/Configuracao/Integracao/Fw7/Comunicador/EmpresaFw7Comunicador';

interface IAcoesFw7 {
  permissao?: IPermissao;
}

const AcoesFw7: React.FC<IAcoesFw7> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { refresh, formRef, loading } = UseForm();

  const code = formRef.current?.getFieldValue('code');

  const handleExcluir = useCallback(async (): Promise<void> => {
    try {
      const response = await EmpresaFw7Comunicador.delete();

      if (response) {
        ToastSucesso('Registro excluido!');
      }

      formRef.current?.limparFormSemExecutarEvento();

      refresh();
    } catch (error) {
      TratarErros(error);
    }
  }, [formRef, refresh]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={
          loading || permissao?.altera === false || permissao?.exclui === false
        }
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={!permissao?.altera || loading || !code}
          onClick={handleExcluir}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <BiTrash style={{ marginRight: 5 }} />
            Excluir
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!permissao?.altera || loading || !code}
          // onClick={handleExcluir}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Pessoas FW7
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!permissao?.altera || loading || !code}
          // onClick={handleExcluir}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Produtos FW7
          </span>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!permissao?.altera || loading || !code}
          // onClick={handleExcluir}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Pedidos FW7
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesFw7;
