import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { GiMoneyStack } from 'react-icons/gi/index.mjs';
import {
  ArraysPossuemMesmosValores,
  IContaParcelaCategoriaValoresAlterar,
  IContasParcelaReversaoLista,
  IContaValoresAlterar,
} from '@elogestor/util';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import { UseContasParcelaReversaoLista } from '../Hooks/ContasParcelaReversaoListaHook';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import ContaParcelaReversaoDentroFechamentoPeriodoComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/ContasParcelaReversao/Comunicador/ContaParcelaReversaoDentroFechamentoPeriodoComunicador';

interface IAcoesContasParcelaReversao {
  loading: boolean;
  setLoading: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  possuiItemSelecionado: boolean;
}

const AcoesContasParcelaReversao: React.FC<IAcoesContasParcelaReversao> = ({
  loading,
  setLoading,
  setRefresh,
  possuiItemSelecionado,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();

  const { listaValor, listaItemSelecionadoRef } =
    UseContasParcelaReversaoLista();

  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [dadosPadrao, setDadosPadrao] = useState<IContaValoresAlterar | any>();

  const handleLimparSelecionados = useCallback(async () => {
    listaItemSelecionadoRef.current?.forEach((item, index) => {
      if (item) {
        listaValor[index].selecionado = false;
        item.checked = false;
      }
    });
  }, [listaItemSelecionadoRef, listaValor]);

  const handleValidar = useCallback((): boolean => {
    const listaMensagemErro: string[] = [];

    const listaItensFiltrados = listaValor.filter((it) => it.selecionado);

    if (
      !possuiItemSelecionado ||
      (!listaItensFiltrados && listaItensFiltrados.length === 0)
    ) {
      listaMensagemErro.push(
        `Selecione pelo menos uma Parcela para Realizar a Reversão!`
      );
    } else {
      const { tipo } = listaItensFiltrados[0].conta;

      listaValor.forEach((item) => {
        if (item.selecionado && item.conta.tipo !== tipo) {
          listaMensagemErro.push(
            `Selecione somente um tipo de contas (Receber/Pagar)`
          );
        }
      });
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, [listaValor, possuiItemSelecionado]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    setShowFinanceiro(false);
    setRefresh(true);
    handleLimparSelecionados();
  }, [handleLimparSelecionados, setRefresh]);

  const handleFormatarListaContaCategoria = useCallback(
    (
      listaDadosFiltrados: IContaParcelaCategoriaValoresAlterar[],
      valorConta: number
    ): any[] => {
      const listaContaCategoria: any[] = [];

      for (let i = 0; i < listaDadosFiltrados.length; i++) {
        const dados = listaDadosFiltrados[i] as any;

        let deveAgrupar = false;
        let indexAgrupar = 0;

        const listaIdFormaPagamento =
          dados.listaContaParcelaCategoriaFormaPagamento.listaValor.map(
            (e: any) => e.id
          );

        listaContaCategoria.forEach((item: any, index: number) => {
          if (
            item.idCategoria === dados.idCategoria &&
            item.tipoLancamento === dados.tipoLancamento &&
            item.avista === dados.avista
          ) {
            const listaIdFormaPagamentoItem =
              item.listaContaCategoriaFormaPagamento.listaValor.map(
                (e: any) => e.id
              );

            if (
              ArraysPossuemMesmosValores({
                array1: listaIdFormaPagamento,
                array2: listaIdFormaPagamentoItem,
              })
            ) {
              deveAgrupar = true;
              indexAgrupar = index;
            }
          }
        });

        dados.listaContaCategoriaFormaPagamento =
          dados.listaContaParcelaCategoriaFormaPagamento;

        delete dados.listaContaParcelaCategoriaFormaPagamento;

        if (deveAgrupar) {
          listaContaCategoria[indexAgrupar].valor =
            Number(listaContaCategoria[indexAgrupar].valor) +
            Number(dados.valor);
        } else {
          listaContaCategoria.push(dados);
        }
      }

      listaContaCategoria.forEach((contaCategoria) => {
        contaCategoria.percentualCategoria =
          (Number(contaCategoria.valor) / Number(valorConta)) * 100;
      });

      return listaContaCategoria;
    },
    []
  );

  const handleGerarNovaConta = useCallback(async () => {
    try {
      setLoading(true);
      setRefresh(false);

      if (!handleValidar()) {
        setLoading(false);
        return;
      }
      const listaDadosFiltrados: IContasParcelaReversaoLista[] =
        listaValor.filter((item) => item.selecionado);

      const listaParcelaCategoriaAgrupada: any[] = [];

      const somaParcelasValorTotalSelecionadas = listaDadosFiltrados.reduce(
        (acc, item) => {
          acc += Number(item.valorTotalParcela);
          return acc;
        },
        0
      );

      const somaParcelasValorSelecionadas = listaDadosFiltrados.reduce(
        (acc, item) => {
          acc += Number(item.valorParcela);
          return acc;
        },
        0
      );

      const contaDadosPadroes: IContaValoresAlterar = {
        tipo: listaDadosFiltrados[0].conta.tipo,
        idPessoa: listaDadosFiltrados[0].conta.pessoa
          ? listaDadosFiltrados[0].conta.pessoa?.id
          : null,
        pessoa: listaDadosFiltrados[0].conta.pessoa
          ? { ...listaDadosFiltrados[0].conta.pessoa }
          : undefined,
        numeroDocumento: '',
        dataHoraEmissao: new Date(),
        valor: somaParcelasValorSelecionadas,
        valorTotal: somaParcelasValorTotalSelecionadas,
        quantidadeParcelas: listaDadosFiltrados.length,
        tipoDocumento: 'REVERSÃO',
        revertida: false,
        listaContaParcela: [],
        listaContaCategoria: [],
      };

      listaDadosFiltrados.forEach((item, index) => {
        if (
          item.listaContaParcelaCategoria &&
          item.listaContaParcelaCategoria.length > 0
        ) {
          item.listaContaParcelaCategoria.forEach((categoriaS) => {
            categoriaS.idContaParcela = undefined;
            categoriaS.id = undefined;
            listaParcelaCategoriaAgrupada.push({
              ...categoriaS,
              id: undefined,
            });
          });
        }

        item.listaContaParcelaLiquidacao?.forEach((liquidacao) => {
          liquidacao.id = undefined;
          liquidacao.idContaParcela = undefined;

          if (
            liquidacao.listaMovimentoPortadorCategoria &&
            liquidacao.listaMovimentoPortadorCategoria.length > 0
          ) {
            liquidacao.listaMovimentoPortadorCategoria.forEach(
              (liquidacaoCategoria) => {
                liquidacaoCategoria.id = undefined;
                liquidacaoCategoria.idFinanceiroMovimentoPortador = undefined;
              }
            );
          }
        });

        contaDadosPadroes.listaContaParcela?.push({
          ...item,
          id: undefined,
          idConta: undefined,
          idRegraEscolhaIntegracaoPagamento: undefined,
          avista: false,
          idBandeiraCartao: item.idBandeiraCartao ?? null,
          bandeiraCartao: item.bandeiraCartao ?? undefined,
          idFormaPagamento: item.idFormaPagamento ?? null,
          formaPagamento: item.formaPagamento ?? undefined,
          idPortador: item.idPortador ?? null,
          portador: item.portador ?? undefined,
          sequencia: index + 1,
          numeroDocumento: '',
          dataVencimento: undefined,
        });
      });

      if (listaParcelaCategoriaAgrupada) {
        const listaContaCategoria = handleFormatarListaContaCategoria(
          listaParcelaCategoriaAgrupada,
          somaParcelasValorTotalSelecionadas
        );

        for (let j = 0; j < listaContaCategoria.length; j++) {
          const cat = listaContaCategoria[j];
          if (contaDadosPadroes.listaContaCategoria)
            contaDadosPadroes.listaContaCategoria.push(cat);
        }
      }

      const listaIdConta = Array.from(
        new Set(
          contaDadosPadroes.listaContaParcela.map(
            (parcela: any) => parcela.conta.id
          )
        )
      );

      await ContaParcelaReversaoDentroFechamentoPeriodoComunicador.show({
        params: {
          listaIdConta,
        },
      });

      setShowFinanceiro(true);
      setDadosPadrao(contaDadosPadroes);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleFormatarListaContaCategoria,
    handleValidar,
    listaValor,
    setLoading,
    setRefresh,
  ]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
    setRefresh(true);
    handleLimparSelecionados();
  }, [handleLimparSelecionados, setRefresh]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !possuiItemSelecionado}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item onClick={handleGerarNovaConta}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Gerar Nova Conta
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showFinanceiro && (
        <FinanceiroDetalhe
          onFecharFormModal={handleFinanceiroFecharFormModal}
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          dadosPadrao={dadosPadrao}
          isAdiantamentoAntecipacao={
            dadosPadrao.listaContaParcela[0].conta.isAdiantamentoAntecipacao
          }
        />
      )}
    </>
  );
};

export default AcoesContasParcelaReversao;
