const PessoaPorDataCadastroPlanilha = [
  { titulo: 'Código', valor: 'listaPessoa.codigo' },
  { titulo: 'Nome / RazãoSocial', valor: 'listaPessoa.nomeRazaoSocial' },
  { titulo: 'CNPJ/CPF', valor: 'listaPessoa.cnpjCpfVirtual' },
  { titulo: 'Telefone', valor: 'listaPessoa.telefone' },
  {
    titulo: 'Cidade',
    valor: 'listaPessoa.pessoaEnderecoPrincipal.cidade.nome',
  },
  { titulo: 'Insc. Estadual', valor: 'listaPessoa.inscricaoEstadual' },
  {
    titulo: 'Estado',
    valor: 'listaPessoa.pessoaEnderecoPrincipal.cidade.estado.sigla',
  },
  { titulo: 'Email', valor: 'listaPessoa.email' },
];

export default PessoaPorDataCadastroPlanilha;
