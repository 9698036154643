import React, { createContext, useCallback, useContext } from 'react';
import {
  ConfiguracaoTributacaoTipoLancamentoEnum,
  EstoqueIndicadorTipoEnum,
  IcmsOperacoesInterestaduaisCalculo,
  ImpostoCalculoPorCst,
  IOrcamentoItemComercialRepresentanteLista,
  IrRetidoCalculo,
  ModalidadeBaseCalculoIcmsEnum,
  ObterTipoMercado,
  RateioItemComCasaDecimalCalculo,
  TipoMovimentoEntradaSaidaEnum,
  TipoTransacaoOperacaoFiscalEnum,
  ValorTotalDocumentoCalculo,
} from '@elogestor/util';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseListaItem } from '../../../Hook/ListaItemContext';
import { UseItemIcmsTab } from './ItemIcmsTabHook';
import { UseItemIpiTab } from './ItemIpiTabHook';
import { UseItemPisTab } from './ItemPisTabHook';
import { UseItemCofinsTab } from './ItemCofinsTabHook';
import { UseItemIcmsOperacaoInterestadualTab } from './ItemIcmsOperacaoInterestadualTabHook';
import ConfiguracaoTributariaComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/ConfiguracaoTributaria/Comunicador/ConfiguracaoTributariaComunicador';
import ConfiguracaoTributariaObterConformeProdutoComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/ConfiguracaoTributaria/Comunicador/ConfiguracaoTributariaObterConformeProdutoComunicador';
import RegraEscolhaAliquotaObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Comunicador/RegraEscolhaAliquotaObterConformeCriteriosComunicador';
import RegraCalculoComissaoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Comercial/Comissao/Comunicador/RegraCalculoComissaoObterConformeCriteriosComunicador';
import RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Comunicador/RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador';
import RegraEscolhaParametroObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaParametro/Comunicador/RegraEscolhaParametroObterConformeCriteriosComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { UseAuth } from '../../../../../../../../Hooks/Auth';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import ObterValorUnitarioCustoSaidaComunicador from '../../../../../../../../Comunicador/Suprimentos/Produtos/Estoque/MovimentoManualEstoque/Comunicador/ObterValorUnitarioCustoSaidaComunicador';

interface IItemTabContext {
  validador: boolean;
  setItemOrdem(): Promise<void>;
  atualizarCampos(): Promise<void>;
  calculaValorTotalProduto(): Promise<void>;
  calcularRateioItem(): Promise<void>;
  calcularIcmsOperacaoInterestadual(): Promise<void>;
  calcularImpostosPorCst(): Promise<void>;
  calcularValorTotalBruto(): Promise<void>;
  obterConfiguracaoTributariaConformeProduto(): Promise<void>;
  obterConfiguracaoTributaria(): Promise<void>;
  calcularQuantidadeTributadoProdutoTributado(): Promise<void>;
  calcularQuantidadeUnitariaTributadoProdutoTributado(): Promise<void>;
  calcularValorUnitarioTributadoProdutoTributado(): Promise<void>;
  obterRegraEscolhaAliquota(): Promise<void>;
  obterRegraEscolhaAliquotaOperacaoInterestadual(): Promise<void>;
  obterDadosItemComercialRepresentante(): Promise<void>;
  limparDadosItemComercialRepresentante(): Promise<void>;
  calcularBaseCalculoItemComercialRepresentante(): Promise<void>;
  obterRegraEscolhaTabelaPreco(): Promise<void>;
  obterRegraEscolhaParametros(): Promise<void>;
  calcularItemEngenhariaPesagem(): Promise<void>;
  calcularItemEngenhariaMetragem(): Promise<void>;
  calcularImpostoRetido(): Promise<void>;
  limparConfiguracaoTributaria(): Promise<void>;
}

const ItemTabContext = createContext<IItemTabContext>({} as IItemTabContext);

const ItemTabHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRefDetalhe, getIdDetalheRegistro, setLoading } =
    UseListaDetalheForm();
  const {
    formRef: formPrincipal,
    getIdDetalheRegistro: getIdDetalheRegistroFormPrincipal,
  } = UseForm();
  const { obterVisibilidadeBloqueioCamposPorIcmsCst } = UseItemIcmsTab();
  const { obterVisibilidadeBloqueioCamposPorIpiCst } = UseItemIpiTab();
  const { obterVisibilidadeBloqueioCamposPorPisCst } = UseItemPisTab();
  const { obterVisibilidadeBloqueioCamposPorCofinsCst } = UseItemCofinsTab();
  const { obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual } =
    UseItemIcmsOperacaoInterestadualTab();
  const { listaItem } = UseListaItem();

  const { empresa } = UseAuth();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleSetItemOrdem = useCallback(async () => {
    const ordem = listaItem.length + 1;
    formRefDetalhe.current?.setFieldValorInicial('ordem', ordem);
  }, [formRefDetalhe, listaItem.length]);

  const handleCalcularValorTotalBruto = useCallback(async () => {
    const deduzirDesoneracaoIcms = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.deduzirDesoneracaoIcms'
    );
    const valorDesoneracaoIcms = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.valorDesoneracaoIcms'
    );

    const resultado = ValorTotalDocumentoCalculo({
      valorSubtotalItem:
        formRefDetalhe.current?.getFieldValue('valorTotalProduto'),
      valorDescontoItem: formRefDetalhe.current?.getFieldValue('valorDesconto'),
      valorFreteItem: formRefDetalhe.current?.getFieldValue('valorFrete'),
      valorSeguroItem: formRefDetalhe.current?.getFieldValue('valorSeguro'),
      valorOutrasDespesasItem: formRefDetalhe.current?.getFieldValue(
        'valorOutrasDespesas'
      ),
      valorIcmsSt: formRefDetalhe.current?.getFieldValue(
        'orcamentoItemImpostoIcms.valorIcmsSt'
      ),
      valorFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
        'orcamentoItemImpostoIcms.valorFcpIcmsSt'
      ),
      valorIpi: formRefDetalhe.current?.getFieldValue(
        'orcamentoItemImpostoIpi.valor'
      ),
      valorImpostoImportacao: null,
      valorServicos: null,

      valorDesoneracaoIcmsDeduzir: deduzirDesoneracaoIcms
        ? Number(valorDesoneracaoIcms)
        : null,
    });

    formRefDetalhe.current?.setFieldValue('valorTotalBruto', resultado);
  }, [formRefDetalhe]);

  const handleAtualizarCampos = useCallback(async () => {
    obterVisibilidadeBloqueioCamposPorIcmsCst();
    obterVisibilidadeBloqueioCamposPorIpiCst();
    obterVisibilidadeBloqueioCamposPorPisCst();
    obterVisibilidadeBloqueioCamposPorCofinsCst();
    obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
  }, [
    obterVisibilidadeBloqueioCamposPorCofinsCst,
    obterVisibilidadeBloqueioCamposPorIcmsCst,
    obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual,
    obterVisibilidadeBloqueioCamposPorIpiCst,
    obterVisibilidadeBloqueioCamposPorPisCst,
  ]);

  const handleObterRegraEscolhaTabelaPreco = useCallback(async () => {
    await Sleep(1);

    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
      'orcamentoPessoa.pessoa'
    );
    const destinado = formRefDetalhe.current?.getFieldValue('destinado');
    const operacaoFiscal =
      formRefDetalhe.current?.getFieldValueNomeObjeto('operacaoFiscal');

    const listaRepresentante = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemComercial.listaOrcamentoItemComercialRepresentante'
    );
    const utilizarValorTabelaPreco = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemComercial.utilizarValorTabelaPreco'
    );

    const listaIdPessoaRepresentante = listaRepresentante.map(
      (representante: IOrcamentoItemComercialRepresentanteLista) => {
        return representante.pessoaRepresentante.id;
      }
    );

    if (!dataHoraEmissao || !produto || !pessoa) {
      return;
    }

    try {
      setLoading(true);

      if (
        operacaoFiscal?.tipoTransacao ===
          TipoTransacaoOperacaoFiscalEnum.entradaTransferencia ||
        operacaoFiscal?.tipoTransacao ===
          TipoTransacaoOperacaoFiscalEnum.saidaTransferencia
      ) {
        const response = await ObterValorUnitarioCustoSaidaComunicador.index({
          params: {
            idProduto: produto.id,
            dataMovimento: dataHoraEmissao,
            idPessoa: pessoa.id,
            indicadorTipoEstoque:
              EstoqueIndicadorTipoEnum.proprioEmPoderProprio,
          },
        });

        if (response) {
          formRefDetalhe.current?.setFieldValue(
            'valorUnitario',
            response.custos.valorUnitarioCustoMedio
          );

          await formRefDetalhe.current?.setSemExecutarEvento({
            orcamentoItemComercial: {
              regraEscolhaTabelaPreco: null,
              valorUnitarioTabelaPreco: 0,
              utilizarValorTabelaPreco: false,
            },
          });
        }
      } else {
        const response =
          await RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador.show({
            params: {
              dataPesquisaVigencia: dataHoraEmissao,
              idProduto: produto.id,
              idPessoa: pessoa.id,
              listaIdPessoaRepresentante,
              destinado,
            },
          });

        if (response && response.regraEscolhaTabelaPreco) {
          if (utilizarValorTabelaPreco) {
            formRefDetalhe.current?.setFieldValue(
              'valorUnitario',
              response?.regraEscolhaTabelaPreco?.produtoRegraEscolhaTabelaPreco
                .valorUnitarioVenda
            );
          }

          await formRefDetalhe.current?.setSemExecutarEvento({
            orcamentoItemComercial: {
              regraEscolhaTabelaPreco: response?.regraEscolhaTabelaPreco,
              valorUnitarioTabelaPreco:
                response?.regraEscolhaTabelaPreco
                  ?.produtoRegraEscolhaTabelaPreco.valorUnitarioVenda,
            },
          });
        }
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [formPrincipal, formRefDetalhe, setLoading]);

  const handleCalcularIcmsOperacaoInterestadual = useCallback(async () => {
    try {
      const resultado = IcmsOperacoesInterestaduaisCalculo({
        valorFreteItem: formRefDetalhe.current?.getFieldValue('valorFrete'),
        valorSeguroItem: formRefDetalhe.current?.getFieldValue('valorSeguro'),
        valorOutrasDespesasItem: formRefDetalhe.current?.getFieldValue(
          'valorOutrasDespesas'
        ),
        valorDescontoItem:
          formRefDetalhe.current?.getFieldValue('valorDesconto'),
        quantidadeItem: formRefDetalhe.current?.getFieldValue('quantidade'),
        valorUnitarioItem:
          formRefDetalhe.current?.getFieldValue('valorUnitario'),

        utiliza: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.utiliza'
        ),
        calcular: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.calcular'
        ),
        aliquotaEstadoDestino: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino'
        ),
        aliquotaInterestadual: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual'
        ),
        aliquotaOrigemDestino: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino'
        ),
        estadoOrigemBaseCalculo: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo'
        ),
        estadoOrigemPercentualPartilha: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha'
        ),
        estadoOrigemValorIcms: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms'
        ),
        estadoDestinoBaseCalculo: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo'
        ),
        estadoDestinoPercentualPartilha: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha'
        ),
        estadoDestinoValorIcmsSemFcp: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp'
        ),
        estadoDestinoPercentualFcp: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp'
        ),
        estadoDestinoValorFcp: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp'
        ),
        estadoDestinoValorIcmsComFcp: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp'
        ),
        valorIpiItem: formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIpi.valor'
        ),
      });

      await formRefDetalhe.current?.setSemExecutarEvento({
        orcamentoItemImpostoIcmsOperacaoInterestadual: {
          calcular: resultado.calcular,
          aliquotaEstadoDestino: resultado.aliquotaEstadoDestino,
          aliquotaInterestadual: resultado.aliquotaInterestadual,
          aliquotaOrigemDestino: resultado.aliquotaOrigemDestino,
          estadoOrigemBaseCalculo: resultado.estadoOrigemBaseCalculo,
          estadoOrigemPercentualPartilha:
            resultado.estadoOrigemPercentualPartilha,
          estadoOrigemValorIcms: resultado.estadoOrigemValorIcms,
          estadoDestinoBaseCalculo: resultado.estadoDestinoBaseCalculo,
          estadoDestinoPercentualPartilha:
            resultado.estadoDestinoPercentualPartilha,
          estadoDestinoValorIcmsSemFcp: resultado.estadoDestinoValorIcmsSemFcp,
          estadoDestinoPercentualFcp: resultado.estadoDestinoPercentualFcp,
          estadoDestinoValorFcp: resultado.estadoDestinoValorFcp,
          estadoDestinoValorIcmsComFcp: resultado.estadoDestinoValorIcmsComFcp,
        },

        aliquotaOrigem: resultado.aliquotaOrigemDestino,
        aliquotaDestino: resultado.aliquotaOrigemDestino,
      });

      handleAtualizarCampos();
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe, handleAtualizarCampos]);

  const handleCalcularImpostosPorCst = useCallback(async () => {
    try {
      const resultado = ImpostoCalculoPorCst({
        valorDescontoItem:
          formRefDetalhe.current?.getFieldValue('valorDesconto'),
        valorFreteItem: formRefDetalhe.current?.getFieldValue('valorFrete'),
        valorSeguroItem: formRefDetalhe.current?.getFieldValue('valorSeguro'),
        valorOutrasDespesasItem: formRefDetalhe.current?.getFieldValue(
          'valorOutrasDespesas'
        ),
        quantidadeItem: formRefDetalhe.current?.getFieldValue('quantidade'),
        valorUnitarioItem:
          formRefDetalhe.current?.getFieldValue('valorUnitario'),

        icms: {
          valorIpiItem: null,
          destinadoItem: formRefDetalhe.current?.getFieldValue('destinado'),

          cstCsosn:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'orcamentoItemImpostoIcms.situacaoTributaria'
            )?.codigo || '',
          tipoMercado:
            formRefDetalhe.current?.getFieldValueNomeObjeto('operacaoFiscal')
              ?.tipoMercado || '',

          // #region ICMS

          calcularIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularIcms'
          ),
          modalidadeBaseCalculoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.modalidadeBaseCalculoIcms'
          ),
          aliquotaInterestadualIcms: null,

          percentualReducaoBaseCalculoIcms:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.percentualReducaoBaseCalculoIcms'
            ),
          baseCalculoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoIcms'
          ),
          aliquotaIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaIcms'
          ),
          valorIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorIcms'
          ),

          baseCalculoFcpIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoFcpIcms'
          ),
          aliquotaFcpIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaFcpIcms'
          ),
          valorFcpIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorFcpIcms'
          ),

          percentualDiferimentoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.percentualDiferimentoIcms'
          ),
          valorSemDiferimentoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorSemDiferimentoIcms'
          ),
          valorDiferidoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorDiferidoIcms'
          ),

          valorUnitarioPautaIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorUnitarioPautaIcms'
          ),
          valorTotalPautaIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorTotalPautaIcms'
          ),

          calcularIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularIcmsEfetivo'
          ),
          percentualReducaoBaseCalculoIcmsEfetivo:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.percentualReducaoBaseCalculoIcmsEfetivo'
            ),
          baseCalculoIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoIcmsEfetivo'
          ),
          aliquotaIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaIcmsEfetivo'
          ),
          valorIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorIcmsEfetivo'
          ),

          calcularIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularIcmsSubstituto'
          ),
          valorUnitarioIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorUnitarioIcmsSubstituto'
          ),
          valorIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorIcmsSubstituto'
          ),

          motivoDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.motivoDesoneracaoIcms'
          ),
          calcularDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularDesoneracaoIcms'
          ),
          baseCalculoDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoDesoneracaoIcms'
          ),
          aliquotaDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaDesoneracaoIcms'
          ),
          valorDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorDesoneracaoIcms'
          ),

          baseCalculoSemReducaoIcms: null,
          valorSemReducaoIcms: null,
          baseCalculoReducaoIcms: null,
          valorReducaoIcms: null,

          // #endregion ICMS

          // #region ICMS ST

          calcularIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularIcmsSt'
          ),
          modalidadeBaseCalculoIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.modalidadeBaseCalculoIcmsSt'
          ),

          percentualMvaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.percentualMvaIcmsSt'
          ),
          percentualReducaoMvaIcmsSt: null,
          percentualReducaoBaseCalculoIcmsSt:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.percentualReducaoBaseCalculoIcmsSt'
            ),
          aliquotaInterestadualIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaInterestadualIcmsSt'
          ),
          aliquotaInternaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaInternaIcmsSt'
          ),
          baseCalculoIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoIcmsSt'
          ),
          valorIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorIcmsSt'
          ),

          baseCalculoFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoFcpIcmsSt'
          ),
          aliquotaFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaFcpIcmsSt'
          ),
          valorFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorFcpIcmsSt'
          ),

          calcularIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularIcmsStRetido'
          ),
          baseCalculoIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoIcmsStRetido'
          ),
          baseCalculoUnitariaProdutoIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.baseCalculoUnitariaProdutoIcmsStRetido'
            ),
          aliquotaIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaIcmsStRetido'
          ),
          valorIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorIcmsStRetido'
          ),
          valorUnitarioProdutoIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.valorUnitarioProdutoIcmsStRetido'
            ),

          baseCalculoFcpIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.baseCalculoFcpIcmsStRetido'
          ),
          baseCalculoUnitariaProdutoFcpIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.baseCalculoUnitariaProdutoFcpIcmsStRetido'
            ),
          aliquotaFcpIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.aliquotaFcpIcmsStRetido'
          ),
          valorFcpIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorFcpIcmsStRetido'
          ),
          valorUnitarioProdutoFcpIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcms.valorUnitarioProdutoFcpIcmsStRetido'
            ),

          valorUnitarioPautaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorUnitarioPautaIcmsSt'
          ),
          valorTotalPautaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorTotalPautaIcmsSt'
          ),

          // #endregion ICMS ST

          // #region ICMS SN

          calcularCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.calcularCreditoIcmsSn'
          ),
          percentualCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.percentualCreditoIcmsSn'
          ),
          valorCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcms.valorCreditoIcmsSn'
          ),

          // #endregion ICMS SN
        },

        icmsOperacaoInterestadual: {
          utiliza: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.utiliza'
          ),
          calcular: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.calcular'
          ),
          aliquotaEstadoDestino: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino'
          ),
          aliquotaInterestadual: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual'
          ),
          aliquotaOrigemDestino: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino'
          ),
          estadoOrigemBaseCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo'
          ),
          estadoOrigemPercentualPartilha: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha'
          ),
          estadoOrigemValorIcms: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms'
          ),
          estadoDestinoBaseCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo'
          ),
          estadoDestinoPercentualPartilha:
            formRefDetalhe.current?.getFieldValue(
              'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha'
            ),
          estadoDestinoValorIcmsSemFcp: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp'
          ),
          estadoDestinoPercentualFcp: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp'
          ),
          estadoDestinoValorFcp: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp'
          ),
          estadoDestinoValorIcmsComFcp: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp'
          ),
        },

        ipi: {
          calcular: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.calcular'
          ),
          tipoCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.tipoCalculo'
          ),
          cst:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'orcamentoItemImpostoIpi.situacaoTributaria'
            )?.codigo || '',
          quantidadeUnidadeIpi: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.quantidadeUnidade'
          ),
          valorUnidadeIpi: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.valorUnidade'
          ),
          baseCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.baseCalculo'
          ),
          aliquota: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.aliquota'
          ),
          valor: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoIpi.valor'
          ),
        },

        pis: {
          calcular: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.calcular'
          ),
          tipoCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.tipoCalculo'
          ),
          cst:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'orcamentoItemImpostoPis.situacaoTributaria'
            )?.codigo || '',
          quantidadeUnidadePis: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.quantidadeUnidade'
          ),
          valorUnidadePis: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.valorUnidade'
          ),
          baseCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.baseCalculo'
          ),
          aliquota: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.aliquota'
          ),
          valor: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.valor'
          ),

          calcularRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.calcularRetido'
          ),
          baseCalculoRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.baseCalculoRetido'
          ),
          aliquotaRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.aliquotaRetido'
          ),
          valorRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoPis.valorRetido'
          ),
        },

        cofins: {
          calcular: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.calcular'
          ),
          tipoCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.tipoCalculo'
          ),
          cst:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'orcamentoItemImpostoCofins.situacaoTributaria'
            )?.codigo || '',
          quantidadeUnidadeCofins: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.quantidadeUnidade'
          ),
          valorUnidadeCofins: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.valorUnidade'
          ),
          baseCalculo: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.baseCalculo'
          ),
          aliquota: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.aliquota'
          ),
          valor: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.valor'
          ),

          calcularRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.calcularRetido'
          ),
          baseCalculoRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.baseCalculoRetido'
          ),
          aliquotaRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.aliquotaRetido'
          ),
          valorRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoCofins.valorRetido'
          ),
        },

        irRetido: {
          aliquotaIrRetido: formRefDetalhe.current?.getFieldValue(
            'orcamentoItemImpostoRetido.aliquotaIRRetido'
          ),
        },
      });

      await formRefDetalhe.current?.setSemExecutarEvento({
        orcamentoItemImpostoIcms: {
          // #region ICMS

          modalidadeBaseCalculoIcms: resultado.icms?.modalidadeBaseCalculoIcms,
          // aliquotaInterestadualIcms: number | null;

          percentualReducaoBaseCalculoIcms:
            resultado.icms?.percentualReducaoBaseCalculoIcms,
          baseCalculoIcms: resultado.icms?.baseCalculoIcms,
          aliquotaIcms: resultado.icms?.aliquotaIcms,
          valorIcms: resultado.icms?.valorIcms,

          baseCalculoFcpIcms: resultado.icms?.baseCalculoFcpIcms,
          aliquotaFcpIcms: resultado.icms?.aliquotaFcpIcms,
          valorFcpIcms: resultado.icms?.valorFcpIcms,
          valorIcmsEValorFcpIcms: resultado.icms?.valorIcmsEValorFcpIcms,

          percentualDiferimentoIcms: resultado.icms?.percentualDiferimentoIcms,
          valorSemDiferimentoIcms: resultado.icms?.valorSemDiferimentoIcms,
          valorDiferidoIcms: resultado.icms?.valorDiferidoIcms,

          valorUnitarioPautaIcms: resultado.icms?.valorUnitarioPautaIcms,
          valorTotalPautaIcms: resultado.icms?.valorTotalPautaIcms,

          percentualReducaoBaseCalculoIcmsEfetivo:
            resultado.icms?.percentualReducaoBaseCalculoIcmsEfetivo,
          baseCalculoIcmsEfetivo: resultado.icms?.baseCalculoIcmsEfetivo,
          aliquotaIcmsEfetivo: resultado.icms?.aliquotaIcmsEfetivo,
          valorIcmsEfetivo: resultado.icms?.valorIcmsEfetivo,

          valorUnitarioIcmsSubstituto:
            resultado.icms?.valorUnitarioIcmsSubstituto,
          valorIcmsSubstituto: resultado.icms?.valorIcmsSubstituto,

          motivoDesoneracaoIcms: resultado.icms?.motivoDesoneracaoIcms,
          calcularDesoneracaoIcms: resultado.icms?.calcularDesoneracaoIcms,
          baseCalculoDesoneracaoIcms:
            resultado.icms?.baseCalculoDesoneracaoIcms,
          aliquotaDesoneracaoIcms: resultado.icms?.aliquotaDesoneracaoIcms,
          valorDesoneracaoIcms: resultado.icms?.valorDesoneracaoIcms,

          // baseCalculoSemReducao: number | null;
          // valorSemReducao: number | null;
          // baseCalculoReducao: number | null;
          // valorReducao: number | null;

          // #endregion ICMS

          // #region ICMS ST

          modalidadeBaseCalculoIcmsSt:
            resultado.icms?.modalidadeBaseCalculoIcmsSt,

          percentualMvaIcmsSt: resultado.icms?.percentualMvaIcmsSt,
          // percentualReducaoMvaIcmsSt: number | null;
          percentualReducaoBaseCalculoIcmsSt:
            resultado.icms?.percentualReducaoBaseCalculoIcmsSt,
          aliquotaInterestadualIcmsSt:
            resultado.icms?.aliquotaInterestadualIcmsSt,
          aliquotaInternaIcmsSt: resultado.icms?.aliquotaInternaIcmsSt,
          baseCalculoIcmsSt: resultado.icms?.baseCalculoIcmsSt,
          valorIcmsSt: resultado.icms?.valorIcmsSt,

          baseCalculoFcpIcmsSt: resultado.icms?.baseCalculoFcpIcmsSt,
          aliquotaFcpIcmsSt: resultado.icms?.aliquotaFcpIcmsSt,
          valorFcpIcmsSt: resultado.icms?.valorFcpIcmsSt,
          valorIcmsStEValorFcpIcmsSt:
            resultado.icms?.valorIcmsStEValorFcpIcmsSt,

          baseCalculoIcmsStRetido: resultado.icms?.baseCalculoIcmsStRetido,
          baseCalculoUnitariaProdutoIcmsStRetido:
            resultado.icms?.baseCalculoUnitariaProdutoIcmsStRetido,
          aliquotaIcmsStRetido: resultado.icms?.aliquotaIcmsStRetido,
          valorIcmsStRetido: resultado.icms?.valorIcmsStRetido,
          valorUnitarioProdutoIcmsStRetido:
            resultado.icms?.valorUnitarioProdutoIcmsStRetido,

          baseCalculoFcpIcmsStRetido:
            resultado.icms?.baseCalculoFcpIcmsStRetido,
          baseCalculoUnitariaProdutoFcpIcmsStRetido:
            resultado.icms?.baseCalculoUnitariaProdutoFcpIcmsStRetido,
          aliquotaFcpIcmsStRetido: resultado.icms?.aliquotaFcpIcmsStRetido,
          valorFcpIcmsStRetido: resultado.icms?.valorFcpIcmsStRetido,
          valorUnitarioProdutoFcpIcmsStRetido:
            resultado.icms?.valorUnitarioProdutoFcpIcmsStRetido,

          valorUnitarioPautaIcmsSt: resultado.icms?.valorUnitarioPautaIcmsSt,
          valorTotalPautaIcmsSt: resultado.icms?.valorTotalPautaIcmsSt,

          // #endregion ICMS ST

          // #region ICMS SN

          percentualCreditoIcmsSn: resultado.icms?.percentualCreditoIcmsSn,
          valorCreditoIcmsSn: resultado.icms?.valorCreditoIcmsSn,

          // #endregion ICMS SN
        },

        orcamentoItemImpostoIcmsOperacaoInterestadual: {
          calcular: resultado.icmsOperacaoInterestadual?.calcular,
          aliquotaEstadoDestino:
            resultado.icmsOperacaoInterestadual?.aliquotaEstadoDestino,
          aliquotaInterestadual:
            resultado.icmsOperacaoInterestadual?.aliquotaInterestadual,
          aliquotaOrigemDestino:
            resultado.icmsOperacaoInterestadual?.aliquotaOrigemDestino,
          estadoOrigemBaseCalculo:
            resultado.icmsOperacaoInterestadual?.estadoOrigemBaseCalculo,
          estadoOrigemPercentualPartilha:
            resultado.icmsOperacaoInterestadual?.estadoOrigemPercentualPartilha,
          estadoOrigemValorIcms:
            resultado.icmsOperacaoInterestadual?.estadoOrigemValorIcms,
          estadoDestinoBaseCalculo:
            resultado.icmsOperacaoInterestadual?.estadoDestinoBaseCalculo,
          estadoDestinoPercentualPartilha:
            resultado.icmsOperacaoInterestadual
              ?.estadoDestinoPercentualPartilha,
          estadoDestinoValorIcmsSemFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoValorIcmsSemFcp,
          estadoDestinoPercentualFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoPercentualFcp,
          estadoDestinoValorFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoValorFcp,
          estadoDestinoValorIcmsComFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoValorIcmsComFcp,
        },

        aliquotaOrigem:
          resultado.icmsOperacaoInterestadual?.aliquotaOrigemDestino,
        aliquotaDestino:
          resultado.icmsOperacaoInterestadual?.aliquotaOrigemDestino,

        orcamentoItemImpostoIpi: {
          tipoCalculo: resultado.ipi?.tipoCalculo,
          quantidadeUnidade: resultado.ipi?.quantidadeUnidadeIpi,
          valorUnidade: resultado.ipi?.valorUnidadeIpi,
          baseCalculo: resultado.ipi?.baseCalculo,
          aliquota: resultado.ipi?.aliquota,
          valor: resultado.ipi?.valor,
        },

        orcamentoItemImpostoPis: {
          tipoCalculo: resultado.pis?.tipoCalculo,
          quantidadeUnidade: resultado.pis?.quantidadeUnidadePisCofins,
          valorUnidade: resultado.pis?.valorUnidadePisCofins,
          baseCalculo: resultado.pis?.baseCalculo,
          aliquota: resultado.pis?.aliquota,
          valor: resultado.pis?.valor,

          baseCalculoRetido: resultado.pis?.baseCalculoRetido,
          aliquotaRetido: resultado.pis?.aliquotaRetido,
          valorRetido: resultado.pis?.valorRetido,
        },

        orcamentoItemImpostoCofins: {
          tipoCalculo: resultado.cofins?.tipoCalculo,
          quantidadeUnidade: resultado.cofins?.quantidadeUnidadePisCofins,
          valorUnidade: resultado.cofins?.valorUnidadePisCofins,
          baseCalculo: resultado.cofins?.baseCalculo,
          aliquota: resultado.cofins?.aliquota,
          valor: resultado.cofins?.valor,

          baseCalculoRetido: resultado.cofins?.baseCalculoRetido,
          aliquotaRetido: resultado.cofins?.aliquotaRetido,
          valorRetido: resultado.cofins?.valorRetido,
        },

        orcamentoItemImpostoRetido: {
          baseCalculoIRRetido: resultado.irRetido?.baseCalculoIrRetido,
          aliquotaIRRetido: resultado.irRetido?.aliquotaIrRetido,
          valorIRRetido: resultado.irRetido?.valorIrRetido,
        },
      });

      handleCalcularValorTotalBruto();
      handleAtualizarCampos();
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe, handleAtualizarCampos, handleCalcularValorTotalBruto]);

  const handleCalcularRateioItem = useCallback(async () => {
    const valorTotalProduto =
      formRefDetalhe.current?.getFieldValue('valorTotalProduto');

    const valorTotal =
      listaItem.reduce((acumulador, item) => {
        if (item.id !== idDetalheRegistro)
          acumulador += Number(item.valorTotalProduto);
        return acumulador;
      }, 0) + valorTotalProduto;

    const valorFrete = formPrincipal.current?.getFieldValue('valorFrete');
    const valorSeguro = formPrincipal.current?.getFieldValue('valorSeguro');
    const valorOutrasDespesas = formPrincipal.current?.getFieldValue(
      'valorOutrasDespesas'
    );
    const valorDesconto = formPrincipal.current?.getFieldValue('valorDesconto');

    const formaCalculoRateioFrete = formPrincipal.current?.getFieldValue(
      'freteFormaCalculoPorRateio'
    );
    const formaCalculoRateioSeguro = formPrincipal.current?.getFieldValue(
      'seguroFormaCalculoPorRateio'
    );
    const formaCalculoRateioOutrasDespesas =
      formPrincipal.current?.getFieldValue(
        'outrasDespesasFormaCalculoPorRateio'
      );
    const formaCalculoRateioDesconto = formPrincipal.current?.getFieldValue(
      'descontoFormaCalculoPorRateio'
    );

    if (formaCalculoRateioFrete) {
      const rateioFrete = RateioItemComCasaDecimalCalculo({
        valorRateado: valorFrete,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue('valorFrete', rateioFrete);
    }

    if (formaCalculoRateioSeguro) {
      const rateioSeguro = RateioItemComCasaDecimalCalculo({
        valorRateado: valorSeguro,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue('valorSeguro', rateioSeguro);
    }

    if (formaCalculoRateioOutrasDespesas) {
      const rateioOutrasDespesas = RateioItemComCasaDecimalCalculo({
        valorRateado: valorOutrasDespesas,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue(
        'valorOutrasDespesas',
        rateioOutrasDespesas
      );
    }

    if (formaCalculoRateioDesconto) {
      const rateioDesconto = RateioItemComCasaDecimalCalculo({
        valorRateado: valorDesconto,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue('valorDesconto', rateioDesconto);
    }

    handleCalcularImpostosPorCst();
  }, [
    formRefDetalhe,
    listaItem,
    formPrincipal,
    handleCalcularImpostosPorCst,
    idDetalheRegistro,
  ]);

  const handleCalculaValorTotalProduto = useCallback(async () => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const valorUnitario =
      formRefDetalhe.current?.getFieldValue('valorUnitario');

    const valor = quantidade * valorUnitario;
    await formRefDetalhe.current?.setSemExecutarEvento({
      valorTotalProduto: valor,
    });

    handleCalcularRateioItem();
  }, [formRefDetalhe, handleCalcularRateioItem]);

  const handleCalcularBaseCalculoItemComercialRepresentante = useCallback(
    async (listaDados?: any[]) => {
      if (!listaDados) {
        listaDados = formRefDetalhe.current?.getFieldValue(
          'orcamentoItemComercial.listaOrcamentoItemComercialRepresentante'
        );
      }
      if (listaDados && listaDados.length > 0) {
        const {
          valorTotalBruto,
          valorSeguro,
          valorFrete,
          valorOutrasDespesas,

          orcamentoItemImpostoIpi,
          orcamentoItemImpostoIcms,
        } = formRefDetalhe.current?.getData() as any;

        const valorImpostoIpi = orcamentoItemImpostoIpi
          ? orcamentoItemImpostoIpi.valor
          : 0;
        const valorImpostoIcms = orcamentoItemImpostoIcms
          ? orcamentoItemImpostoIcms.valorIcmsStEValorFcpIcmsSt
          : 0;

        const baseCalculo =
          valorTotalBruto -
          (valorSeguro +
            valorFrete +
            valorOutrasDespesas +
            valorImpostoIpi +
            valorImpostoIcms);
        const listaValor = listaDados.map((item: any) => {
          return {
            ...item,
            baseCalculo,
            valor: baseCalculo * (item.aliquota / 100),
          };
        });

        await formRefDetalhe.current?.setSemExecutarEvento({
          orcamentoItemComercial: {
            listaOrcamentoItemComercialRepresentante: listaDados,
          },
        });
      }
    },
    [formRefDetalhe]
  );

  const handleCarregarDadosItemComercialRepresentante =
    useCallback(async () => {
      let listaDados;
      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

      const listaOrcamentoComercialRepresentante =
        formPrincipal.current?.getFieldValue(
          'orcamentoComercial.listaOrcamentoComercialRepresentante'
        );
      const idPessoa = formPrincipal.current?.getFieldValue(
        'orcamentoPessoa.idPessoa'
      );

      const dataHoraEmissao =
        formPrincipal.current?.getFieldValue('dataHoraEmissao');

      if (
        listaOrcamentoComercialRepresentante &&
        listaOrcamentoComercialRepresentante.length > 0
      ) {
        listaDados = await Promise.all(
          listaOrcamentoComercialRepresentante.map(async (item: any) => {
            delete item.id;

            if (item.formaCalculoPorRegra) {
              const response =
                await RegraCalculoComissaoObterConformeCriteriosComunicador.show(
                  {
                    params: {
                      idPessoa,
                      idPessoaRepresentante: item.idPessoaRepresentante,
                      idProduto: produto.id,
                      idGrupoProdutoServico: produto.idGrupoProdutoServico,
                      dataPesquisaVigencia: new Date(dataHoraEmissao),
                      percentualDesconto: 0,
                    },
                  }
                );

              item.aliquota =
                response && response.regraCalculoComissao
                  ? response.regraCalculoComissao.percentualComissao
                  : 0;
            }

            return item;
          })
        );

        formRefDetalhe.current?.setFieldValue(
          'orcamentoItemComercial.listaOrcamentoItemComercialRepresentante',
          listaDados
        );
      }

      handleCalcularBaseCalculoItemComercialRepresentante(listaDados);
      handleObterRegraEscolhaTabelaPreco();
    }, [
      formPrincipal,
      formRefDetalhe,
      handleCalcularBaseCalculoItemComercialRepresentante,
      handleObterRegraEscolhaTabelaPreco,
    ]);

  const handleLimparDadosItemComercialRepresentante = useCallback(async () => {
    formRefDetalhe.current?.clearField(
      'orcamentoItemComercial.listaOrcamentoItemComercialRepresentante'
    );
  }, [formRefDetalhe]);

  const handleLimparConfiguracaoTributaria = useCallback(async () => {
    formRefDetalhe.current?.clearField('operacaoFiscal', {
      setValorInicial: false,
    });

    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcms.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField('orcamentoItemImpostoIcms.calcularIcms');
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcms.calcularIcmsEfetivo'
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcms.calcularIcmsSubstituto'
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcms.calcularIcmsSt'
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcms.calcularIcmsStRetido'
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcms.calcularCreditoIcmsSn'
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.percentualReducaoBaseCalculoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoSemReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorSemReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorUnitarioPautaIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorTotalPautaIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorSemDiferimentoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.percentualDiferimentoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorDiferidoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcmsEValorFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.percentualMvaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.percentualReducaoBaseCalculoIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaInternaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaInterestadualIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoUnitariaProdutoIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorUnitarioProdutoIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorUnitarioIcmsSubstituto',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcmsSubstituto',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorUnitarioPautaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorTotalPautaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorDesoneracaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorTotalPautaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcmsStEValorFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoUnitariaProdutoFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorUnitarioProdutoFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.percentualReducaoBaseCalculoIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.baseCalculoIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.aliquotaIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.percentualCreditoIcmsSn',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcms.valorCreditoIcmsSn',
      null
    );

    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.utiliza'
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.calcular'
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha',
      null
    );
    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp',
      null
    );

    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIpi.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoIpi.ipiCodigoEnquadramentoLegal',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField('orcamentoItemImpostoIpi.calcular');

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIpi.baseCalculoSpedCompleto',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIpi.baseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIpi.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIpi.quantidadeUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIpi.valorUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoIpi.valor',
      null
    );

    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoPis.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField('orcamentoItemImpostoPis.calcular');

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.baseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.quantidadeUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.valorUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.valor',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.baseCalculoRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.aliquotaRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoPis.valorRetido',
      null
    );

    formRefDetalhe.current?.clearField(
      'orcamentoItemImpostoCofins.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField('orcamentoItemImpostoCofins.calcular');

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.baseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.quantidadeUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.valorUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.valor',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.baseCalculoRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.aliquotaRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemImpostoCofins.valorRetido',
      null
    );

    formRefDetalhe.current?.clearField(
      'orcamentoItemInformacaoSped.codigoBeneficiarioFiscalSped'
    );
    formRefDetalhe.current?.clearField(
      'orcamentoItemInformacaoSped.baseLegalIcms'
    );

    formRefDetalhe.current?.clearField('baseLegalIpi');
    formRefDetalhe.current?.clearField('outraBaseLegal');
    formRefDetalhe.current?.clearField('informacoesContribuinte');

    // formRefDetalhe.current?.clearField('gerarFinanceiro');
    // formRefDetalhe.current?.clearField('gerarFaturamento');
    // formRefDetalhe.current?.clearField('movimentarEstoqueFisico');
    // formRefDetalhe.current?.clearField('movimentarEstoqueFiscal');
    // formRefDetalhe.current?.clearField('porContaOrdemTerceiro');
    // formRefDetalhe.current?.clearField('gerarComissao');
    // formRefDetalhe.current?.clearField('indicadorTipoEstoque');
    // formRefDetalhe.current?.clearField('tipoMovimento');

    handleCalcularImpostosPorCst();
  }, [formRefDetalhe, handleCalcularImpostosPorCst]);

  const handleObterConfiguracaoTributaria = useCallback(async () => {
    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');
    const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
      'orcamentoPessoa.pessoa'
    );
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const tipoNota =
      formRefDetalhe.current?.getFieldValueNomeObjeto('tipoNota');
    const tipoNotaMotivo =
      formRefDetalhe.current?.getFieldValueNomeObjeto('tipoNotaMotivo');
    const destinado = formRefDetalhe.current?.getFieldValue('destinado');
    const finalidadeEmissao =
      formPrincipal.current?.getFieldValueNomeObjeto('finalidadeEmissao');
    const origemIcms = formRefDetalhe.current?.getFieldValueNomeObjeto(
      'orcamentoItemImpostoIcms.origemIcms'
    );

    if (
      !dataHoraEmissao ||
      !pessoa ||
      !produto ||
      !tipoNota ||
      !tipoNotaMotivo ||
      !destinado
    ) {
      handleLimparConfiguracaoTributaria();
      return;
    }

    const indicadorPresenca =
      formPrincipal.current?.getFieldValue('indicadorPresenca');

    const ufPessoa =
      formPrincipal.current?.getFieldValue(
        'orcamentoPessoaEndereco.cidade.estado.sigla'
      ) || null;

    const ufPessoaEntrega =
      formPrincipal.current?.getFieldValue(
        'orcamentoPessoaEnderecoEntrega.cidade.estado.sigla'
      ) || null;

    const tipoMercado = ObterTipoMercado({
      ufEstadoOrigem: empresa.uf,
      ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
      indicadorPresenca,
    });

    try {
      setLoading(true);

      const response = await ConfiguracaoTributariaComunicador.show({
        params: {
          dataPesquisaVigencia: dataHoraEmissao,
          idPessoa: pessoa.id,
          idProduto: produto.id,
          idTipoNota: tipoNota.id,
          idTipoNotaMotivo: tipoNotaMotivo.id,
          destinado,
          finalidadeEmissao,
          idOrigemIcms: origemIcms?.id,
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
          tipoMercado,
        },
      });

      if (response) {
        // #region Operacao Fiscal

        await formRefDetalhe.current?.setSemExecutarEvento({
          operacaoFiscal: response?.regraEscolhaOperacaoFiscal?.operacaoFiscal,
          naturezaOperacao:
            response?.regraEscolhaOperacaoFiscal?.naturezaOperacao,
        });

        // #endregion Operacao Fiscal

        // #region CST

        await formRefDetalhe.current?.setSemExecutarEvento({
          orcamentoItemImpostoIcms: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaIcms,
            calcularIcms: response?.regraEscolhaCst?.calcularIcms,
            calcularIcmsEfetivo: response?.regraEscolhaCst?.calcularIcmsEfetivo,
            calcularIcmsSubstituto:
              response?.regraEscolhaCst?.calcularIcmsSubstituto,
            calcularIcmsSt: response?.regraEscolhaCst?.calcularSt,
            calcularIcmsStRetido:
              response?.regraEscolhaCst?.calcularIcmsStRetido,
            calcularCreditoIcmsSn:
              response?.regraEscolhaCst?.calcularCreditoIcmsSn,
            modalidadeBaseCalculoIcms:
              response?.regraEscolhaCst?.modalidadeBaseCalculoIcms,
            modalidadeBaseCalculoIcmsSt:
              response?.regraEscolhaCst?.modalidadeBaseCalculoIcmsSt,
            motivoDesoneracaoIcms:
              response?.regraEscolhaCst?.motivoDesoneracaoIcms,
            calcularDesoneracaoIcms:
              response?.regraEscolhaCst?.calcularDesoneracaoIcms,
            deduzirDesoneracaoIcms:
              response?.regraEscolhaCst?.deduzirDesoneracaoIcms,
          },

          orcamentoItemImpostoIcmsOperacaoInterestadual: {
            utiliza:
              response?.regraEscolhaCst?.utilizaIcmsOperacaoInterestadual,
            calcular:
              response?.regraEscolhaCst?.calcularIcmsOperacaoInterestadual,
          },

          orcamentoItemImpostoIpi: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaIpi,
            ipiCodigoEnquadramentoLegal:
              response?.regraEscolhaCst?.ipiCodigoEnquadramentoLegal,
            calcular: response?.regraEscolhaCst?.calcularIpi,
          },

          orcamentoItemImpostoPis: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaPisCofins,
            calcular: response?.regraEscolhaCst?.calcularPisCofins,
            calcularRetido: response?.regraEscolhaCst?.calcularPisCofinsRetido,
          },

          orcamentoItemImpostoCofins: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaPisCofins,
            calcular: response?.regraEscolhaCst?.calcularPisCofins,
            calcularRetido: response?.regraEscolhaCst?.calcularPisCofinsRetido,
          },

          orcamentoItemInformacaoSped: {
            codigoBeneficiarioFiscalSped:
              response?.regraEscolhaCst?.codigoBeneficiarioFiscalSped,
            baseLegalIcms: response?.regraEscolhaCst?.baseLegalIcms,
          },

          baseLegalIpi: response?.regraEscolhaCst?.baseLegalIpi,
          outraBaseLegal: response?.regraEscolhaCst?.outraBaseLegal,
          informacoesContribuinte:
            response?.regraEscolhaCst?.informacoesContribuinte,
        });

        // #endregion CST

        // #region Parametro

        await formRefDetalhe.current?.setSemExecutarEvento({
          orcamentoItemComercial: {
            gerarFinanceiro: response?.regraEscolhaParametro?.gerarFinanceiro,
            gerarFaturamento: response?.regraEscolhaParametro?.gerarFaturamento,
            gerarComissao: response?.regraEscolhaParametro?.gerarComissao,
          },
        });

        if (response?.regraEscolhaParametro?.gerarComissao) {
          handleCarregarDadosItemComercialRepresentante();
        } else {
          handleLimparDadosItemComercialRepresentante();
        }

        handleObterRegraEscolhaTabelaPreco();

        // #endregion Parametro

        // #region Aliquota

        await formRefDetalhe.current?.setSemExecutarEvento({
          orcamentoItemImpostoIcms: {
            // #region ICMS

            percentualReducaoBaseCalculoIcms:
              response?.regraEscolhaAliquota?.percentualReducaoBaseCalculoIcms,
            aliquotaIcms: response?.regraEscolhaAliquota?.aliquotaIcms,
            aliquotaFcpIcms: response?.regraEscolhaAliquota?.aliquotaFcpIcms,

            aliquotaIcmsEfetivo:
              response?.regraEscolhaAliquota?.aliquotaIcmsEfetivo,

            valorUnitarioIcmsSubstituto:
              response?.regraEscolhaAliquota?.valorUnitarioIcmsSubstituto,

            aliquotaDesoneracaoIcms:
              response?.regraEscolhaAliquota?.aliquotaDesoneracaoIcms,

            // #endregion ICMS

            // #region ICMS ST

            modalidadeBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota?.modalidadeBaseCalculoIcmsSt,
            aliquotaInternaIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInternaIcmsSt,
            aliquotaInterestadualIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInterestadualIcmsSt,
            percentualMvaIcmsSt:
              response?.regraEscolhaAliquota?.percentualMvaIcmsSt,

            valorUnitarioPautaIcmsSt:
              response?.regraEscolhaAliquota?.valorUnitarioPautaIcmsSt,
            percentualReducaoBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota
                ?.percentualReducaoBaseCalculoIcmsSt,
            aliquotaFcpIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaFcpIcmsSt,

            baseCalculoUnitariaProdutoIcmsStRetido:
              response?.regraEscolhaAliquota
                ?.baseCalculoUnitariaProdutoIcmsStRetido,
            aliquotaIcmsStRetido:
              response?.regraEscolhaAliquota?.aliquotaIcmsStRetido,
            valorUnitarioProdutoIcmsStRetido:
              response?.regraEscolhaAliquota?.valorUnitarioProdutoIcmsStRetido,

            baseCalculoUnitariaProdutoFcpIcmsStRetido:
              response?.regraEscolhaAliquota
                ?.baseCalculoUnitariaProdutoFcpIcmsStRetido,
            aliquotaFcpIcmsStRetido:
              response?.regraEscolhaAliquota?.aliquotaFcpIcmsStRetido,
            valorUnitarioProdutoFcpIcmsStRetido:
              response?.regraEscolhaAliquota
                ?.valorUnitarioProdutoFcpIcmsStRetido,

            // #endregion ICMS ST

            // #region ICMS SN

            percentualCreditoIcmsSn:
              response?.regraEscolhaAliquota?.percentualCreditoIcmsSn,

            // #endregion ICMS SN
          },

          orcamentoItemImpostoIcmsOperacaoInterestadual: {
            estadoOrigemPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoOrigem,
            estadoDestinoPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoDestino,

            aliquotaEstadoDestino:
              response?.regraEscolhaAliquota
                ?.aliquotaEstadoDestinoIcmsOperacaoInterestadual,
            aliquotaInterestadual:
              response?.regraEscolhaAliquota
                ?.aliquotaInterestadualIcmsOperacaoInterestadual,
          },

          orcamentoItemImpostoIpi: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaIpi,
          },

          orcamentoItemImpostoPis: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaPis,
            aliquotaRetido: response?.regraEscolhaAliquota?.aliquotaPisRetido,
          },

          orcamentoItemImpostoCofins: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaCofins,
            aliquotaRetido:
              response?.regraEscolhaAliquota?.aliquotaCofinsRetido,
          },

          orcamentoItemImpostoRetido: {
            aliquotaIRRetido: response?.regraEscolhaAliquota?.aliquotaIrRetido,
          },
        });

        // #endregion Aliquota

        const modalidadeBaseCalculo = formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcms.modalidadeBaseCalculoIcms'
        );

        if (
          response?.regraEscolhaCst?.calcularIcms &&
          response?.regraEscolhaAliquota?.aliquotaIcms &&
          !modalidadeBaseCalculo
        ) {
          await formRefDetalhe.current?.setSemExecutarEvento({
            orcamentoItemImpostoIcms: {
              modalidadeBaseCalculoIcms:
                ModalidadeBaseCalculoIcmsEnum.valorOperacao,
            },
          });
        }
      } else {
        handleLimparConfiguracaoTributaria();
      }

      handleCalcularImpostosPorCst();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [
    empresa.uf,
    formPrincipal,
    formRefDetalhe,
    handleCalcularImpostosPorCst,
    handleCarregarDadosItemComercialRepresentante,
    handleLimparConfiguracaoTributaria,
    handleLimparDadosItemComercialRepresentante,
    handleObterRegraEscolhaTabelaPreco,
    setLoading,
  ]);

  const handleObterConfiguracaoTributariaConformeProduto =
    useCallback(async () => {
      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

      if (!produto) {
        handleLimparConfiguracaoTributaria();
        return;
      }

      const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
        'orcamentoPessoa.pessoa'
      );
      const idOrcamento = getIdDetalheRegistroFormPrincipal();

      try {
        setLoading(true);

        const response =
          await ConfiguracaoTributariaObterConformeProdutoComunicador.show({
            params: {
              idProduto: produto.id,
              idPessoa: pessoa.id,
              tipoLancamento:
                ConfiguracaoTributacaoTipoLancamentoEnum.orcamento,
              idLancamento: idOrcamento,
            },
          });

        await formRefDetalhe.current?.setSemExecutarEvento({
          destinado: response?.destinado,
          tipoNota: response?.tipoNota,
          tipoNotaMotivo: response?.tipoNotaMotivo,
          unidadeMedida: response?.unidadeMedida,
          produtoTributado: response?.produtoTributado,
          unidadeMedidaTributado: response?.unidadeMedidaTributado,

          orcamentoItemImpostoIcms: {
            origemIcms: response?.origemIcms,
          },
        });

        formRefDetalhe.current?.setFieldValue(
          'quantidadeUnitariaTributado',
          response?.quantidadeUnitariaTributado
        );

        handleObterConfiguracaoTributaria();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    }, [
      formPrincipal,
      formRefDetalhe,
      getIdDetalheRegistroFormPrincipal,
      handleLimparConfiguracaoTributaria,
      handleObterConfiguracaoTributaria,
      setLoading,
    ]);

  const handleCalcularValorUnitarioTributadoProdutoTributado =
    useCallback(async () => {
      const valorUnitario =
        formRefDetalhe.current?.getFieldValue('valorUnitario');
      const quantidadeUnitariaTributado = formRefDetalhe.current?.getFieldValue(
        'quantidadeUnitariaTributado'
      );

      const valorUnitarioTributado = quantidadeUnitariaTributado
        ? valorUnitario / quantidadeUnitariaTributado
        : 0;

      await formRefDetalhe.current?.setSemExecutarEvento({
        valorUnitarioTributado,
      });
    }, [formRefDetalhe]);

  const handleCalcularQuantidadeTributadoProdutoTributado =
    useCallback(async () => {
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      const quantidadeUnitariaTributado = formRefDetalhe.current?.getFieldValue(
        'quantidadeUnitariaTributado'
      );

      if (quantidadeUnitariaTributado > 0 && quantidade > 0) {
        const quantidadeTributado = quantidade * quantidadeUnitariaTributado;

        await formRefDetalhe.current?.setSemExecutarEvento({
          quantidadeTributado,
        });
      }

      handleCalcularValorUnitarioTributadoProdutoTributado();
    }, [formRefDetalhe, handleCalcularValorUnitarioTributadoProdutoTributado]);

  const handleCalcularQuantidadeUnitariaTributadoProdutoTributado =
    useCallback(async () => {
      const quantidadeTributado = formRefDetalhe.current?.getFieldValue(
        'quantidadeTributado'
      );
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');

      if (quantidade > 0) {
        const quantidadeUnitariaTributado = quantidadeTributado / quantidade;

        await formRefDetalhe.current?.setSemExecutarEvento({
          quantidadeUnitariaTributado,
        });
      }

      handleCalcularValorUnitarioTributadoProdutoTributado();
    }, [formRefDetalhe, handleCalcularValorUnitarioTributadoProdutoTributado]);

  const handleObterRegraEscolhaAliquota = useCallback(async () => {
    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
      'orcamentoPessoa.pessoa'
    );
    const destinado = formRefDetalhe.current?.getFieldValue('destinado');

    const calcularIcms = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.calcularIcms'
    );
    const calcularSt = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.calcularIcmsSt'
    );
    const calcularIpi = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIpi.calcular'
    );
    const calcularPis = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoPis.calcular'
    );
    const calcularPisRetido = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoPis.calcularRetido'
    );
    const calcularCofins = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoCofins.calcular'
    );
    const calcularCofinsRetido = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoCofins.calcularRetido'
    );
    const calcularCreditoIcmsSn = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.calcularCreditoIcmsSn'
    );
    const utilizaIcmsOperacaoInterestadual =
      formRefDetalhe.current?.getFieldValue(
        'orcamentoItemImpostoIcmsOperacaoInterestadual.utiliza'
      );
    const calcularIcmsOperacaoInterestadual =
      formRefDetalhe.current?.getFieldValue(
        'orcamentoItemImpostoIcmsOperacaoInterestadual.calcular'
      );
    const origemIcms = formRefDetalhe.current?.getFieldValueNomeObjeto(
      'orcamentoItemImpostoIcms.origemIcms'
    );
    const calcularDesoneracaoIcms = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.calcularDesoneracaoIcms'
    );

    const idTipoNota = formRefDetalhe.current?.getFieldValue('idTipoNota');
    const idTipoNotaMotivo =
      formRefDetalhe.current?.getFieldValue('idTipoNotaMotivo');

    const idCodigoSituacaoTributaria = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoIcms.idSituacaoTributaria'
    );

    const calcularPisCofins = calcularPis || calcularCofins;
    const calcularPisCofinsRetido = calcularPisRetido || calcularCofinsRetido;
    if (
      !dataHoraEmissao ||
      !produto ||
      !pessoa ||
      !destinado ||
      !idTipoNota ||
      !idTipoNotaMotivo ||
      !idCodigoSituacaoTributaria
    ) {
      return;
    }

    try {
      setLoading(true);

      const response =
        await RegraEscolhaAliquotaObterConformeCriteriosComunicador.show({
          params: {
            dataPesquisaVigencia: dataHoraEmissao,
            idProduto: produto.id,
            idPessoa: pessoa.id,
            destinado,
            idTipoNota,
            idTipoNotaMotivo,
            idCodigoSituacaoTributaria,

            calcularIcms,
            calcularSt,
            calcularIpi,
            calcularPisCofins,
            calcularPisCofinsRetido,
            calcularCreditoIcmsSn,

            utilizaIcmsOperacaoInterestadual,
            calcularIcmsOperacaoInterestadual,

            idOrigemIcms: origemIcms?.id,

            calcularDesoneracaoIcms,
          },
        });

      if (response) {
        await formRefDetalhe.current?.setSemExecutarEvento({
          orcamentoItemImpostoIcms: {
            percentualReducaoBaseCalculoIcms:
              response?.regraEscolhaAliquota?.percentualReducaoBaseCalculoIcms,
            aliquotaIcms: response?.regraEscolhaAliquota?.aliquotaIcms,
            aliquotaFcpIcms: response?.regraEscolhaAliquota?.aliquotaFcpIcms,
            percentualDiferimentoIcms:
              response?.regraEscolhaAliquota?.percentualDiferimentoIcms,
            modalidadeBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota?.modalidadeBaseCalculoIcmsSt,
            aliquotaInternaIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInternaIcmsSt,
            aliquotaInterestadualIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInterestadualIcmsSt,
            percentualMvaIcmsSt:
              response?.regraEscolhaAliquota?.percentualMvaIcmsSt,
            valorUnitarioPautaIcmsSt:
              response?.regraEscolhaAliquota?.valorUnitarioPautaIcmsSt,
            percentualReducaoBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota
                ?.percentualReducaoBaseCalculoIcmsSt,
            aliquotaFcpIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaFcpIcmsSt,
            aliquotaDesoneracaoIcms:
              response?.regraEscolhaAliquota?.aliquotaDesoneracaoIcms,
          },

          orcamentoItemImpostoIcmsOperacaoInterestadual: {
            estadoOrigemPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoOrigem,
            estadoDestinoPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoDestino,
          },

          orcamentoItemImpostoIpi: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaIpi,
          },

          orcamentoItemImpostoPis: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaPis,
            aliquotaRetido: response?.regraEscolhaAliquota?.aliquotaPisRetido,
          },

          orcamentoItemImpostoCofins: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaCofins,
            aliquotaRetido:
              response?.regraEscolhaAliquota?.aliquotaCofinsRetido,
          },

          orcamentoItemImpostoRetido: {
            aliquotaIRRetido: response?.regraEscolhaAliquota?.aliquotaIrRetido,
          },
        });
      }

      handleCalcularImpostosPorCst();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [formPrincipal, formRefDetalhe, handleCalcularImpostosPorCst, setLoading]);

  const handleObterRegraEscolhaAliquotaOperacaoInterestadual =
    useCallback(async () => {
      const dataHoraEmissao =
        formPrincipal.current?.getFieldValue('dataHoraEmissao');
      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
        'orcamentoPessoa.pessoa'
      );
      const destinado = formRefDetalhe.current?.getFieldValue('destinado');

      const utilizaIcmsOperacaoInterestadual =
        formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.utiliza'
        );
      const calcularIcmsOperacaoInterestadual =
        formRefDetalhe.current?.getFieldValue(
          'orcamentoItemImpostoIcmsOperacaoInterestadual.calcular'
        );
      const origemIcms = formRefDetalhe.current?.getFieldValueNomeObjeto(
        'orcamentoItemImpostoIcms.origemIcms'
      );

      if (!dataHoraEmissao || !produto || !pessoa || !destinado) {
        return;
      }

      try {
        setLoading(true);

        const response =
          await RegraEscolhaAliquotaObterConformeCriteriosComunicador.show({
            params: {
              dataPesquisaVigencia: dataHoraEmissao,
              idProduto: produto.id,
              idPessoa: pessoa.id,
              destinado,

              utilizaIcmsOperacaoInterestadual,
              calcularIcmsOperacaoInterestadual,

              idOrigemIcms: origemIcms?.id,
            },
          });

        if (response) {
          await formRefDetalhe.current?.setSemExecutarEvento({
            orcamentoItemImpostoIcmsOperacaoInterestadual: {
              estadoOrigemPercentualPartilha:
                response?.regraEscolhaAliquota?.percentualPartilhaEstadoOrigem,
              estadoDestinoPercentualPartilha:
                response?.regraEscolhaAliquota?.percentualPartilhaEstadoDestino,

              aliquotaEstadoDestino:
                response?.regraEscolhaAliquota
                  ?.aliquotaEstadoDestinoIcmsOperacaoInterestadual,
              aliquotaInterestadual:
                response?.regraEscolhaAliquota
                  ?.aliquotaInterestadualIcmsOperacaoInterestadual,
            },
          });
        }

        handleCalcularIcmsOperacaoInterestadual();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    }, [
      formPrincipal,
      formRefDetalhe,
      handleCalcularIcmsOperacaoInterestadual,
      setLoading,
    ]);

  const handleObterRegraEscolhaParametros = useCallback(async () => {
    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');

    const idProduto = formRefDetalhe.current?.getFieldValue('idProduto');
    const idTipoNota = formRefDetalhe.current?.getFieldValue('idTipoNota');
    const idTipoNotaMotivo =
      formRefDetalhe.current?.getFieldValue('idTipoNotaMotivo');
    const idOperacaoFiscal =
      formRefDetalhe.current?.getFieldValue('idOperacaoFiscal');

    const destinado = formRefDetalhe.current?.getFieldValue('destinado');

    if (
      !dataHoraEmissao ||
      !idProduto ||
      !idTipoNota ||
      !idTipoNotaMotivo ||
      !idOperacaoFiscal ||
      !destinado
    ) {
      return;
    }

    try {
      setLoading(true);

      const response =
        await RegraEscolhaParametroObterConformeCriteriosComunicador.show({
          params: {
            dataPesquisaVigencia: dataHoraEmissao,
            idOperacaoFiscal,
            destinado,
            idProduto,
            idTipoNota,
            idTipoNotaMotivo,
            tipoMovimento: TipoMovimentoEntradaSaidaEnum.saida,
          },
        });

      if (response) {
        if (response.regraEscolhaParametro) {
          await formRefDetalhe.current?.setSemExecutarEvento({
            orcamentoItemComercial: {
              gerarFinanceiro: response.regraEscolhaParametro.gerarFinanceiro,
              gerarFaturamento: response.regraEscolhaParametro.gerarFaturamento,
              gerarComissao: response.regraEscolhaParametro.gerarComissao,
            },
          });

          if (response?.regraEscolhaParametro?.gerarComissao) {
            handleCarregarDadosItemComercialRepresentante();
          } else {
            handleLimparDadosItemComercialRepresentante();
          }
        }
      }
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [
    formPrincipal,
    formRefDetalhe,
    handleCarregarDadosItemComercialRepresentante,
    handleLimparDadosItemComercialRepresentante,
    setLoading,
  ]);

  const handleCalcularItemEngenhariaPesagem = useCallback(async () => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const pesoLiquido = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemEngenharia.pesoLiquidoUnitario'
    );

    const pesoBruto = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemEngenharia.pesoBrutoUnitario'
    );

    const quantidadeVolumes = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemEngenharia.quantidadeVolumesUnitario'
    );

    const calculoPesoBruto = quantidade * pesoBruto;
    const calculoPesoLiquido = quantidade * pesoLiquido;
    const calculoQuantidadeVolumes = Number(
      Number(quantidade) * Number(quantidadeVolumes)
    ).ArredondarParaCima(0);

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.quantidadeVolumes',
      calculoQuantidadeVolumes
    );
    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.pesoBruto',
      calculoPesoBruto
    );
    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.pesoLiquido',
      calculoPesoLiquido
    );
  }, [formRefDetalhe]);

  const handleCalcularItemEngenhariaMetragem = useCallback(async () => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');

    const metroQuadrado = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemEngenharia.metroQuadradoUnitario'
    );
    const metroCubico = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemEngenharia.metroCubicoUnitario'
    );

    const calculoMetroQuadrado = quantidade * metroQuadrado;
    const calculoMetroCubico = quantidade * metroCubico;

    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.metroQuadrado',
      calculoMetroQuadrado
    );
    formRefDetalhe.current?.setFieldValue(
      'orcamentoItemEngenharia.metroCubico',
      calculoMetroCubico
    );
  }, [formRefDetalhe]);

  const handleCalcularImpostoRetido = useCallback(async () => {
    const valorFreteItem = formRefDetalhe.current?.getFieldValue('valorFrete');
    const valorSeguroItem =
      formRefDetalhe.current?.getFieldValue('valorSeguro');
    const valorOutrasDespesasItem = formRefDetalhe.current?.getFieldValue(
      'valorOutrasDespesas'
    );
    const valorDescontoItem =
      formRefDetalhe.current?.getFieldValue('valorDesconto');
    const quantidadeItem = formRefDetalhe.current?.getFieldValue('quantidade');
    const valorUnitarioItem =
      formRefDetalhe.current?.getFieldValue('valorUnitario');
    const aliquotaIRRetido = formRefDetalhe.current?.getFieldValue(
      'orcamentoItemImpostoRetido.aliquotaIRRetido'
    );

    try {
      setLoading(true);

      const response = IrRetidoCalculo({
        valorFreteItem,
        valorSeguroItem,
        valorOutrasDespesasItem,
        valorDescontoItem,
        quantidadeItem,
        valorUnitarioItem,
        aliquotaIrRetido: aliquotaIRRetido,
      });

      if (response) {
        await formRefDetalhe.current?.setSemExecutarEvento({
          orcamentoItemImpostoRetido: {
            baseCalculoIRRetido: response.baseCalculoIrRetido,
            aliquotaIRRetido: response.aliquotaIrRetido,
            valorIRRetido: response.valorIrRetido,
          },
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [formRefDetalhe, setLoading]);

  return (
    <ItemTabContext.Provider
      value={{
        setItemOrdem: handleSetItemOrdem,
        atualizarCampos: handleAtualizarCampos,
        calculaValorTotalProduto: handleCalculaValorTotalProduto,
        calcularRateioItem: handleCalcularRateioItem,
        calcularIcmsOperacaoInterestadual:
          handleCalcularIcmsOperacaoInterestadual,
        calcularImpostosPorCst: handleCalcularImpostosPorCst,
        calcularValorTotalBruto: handleCalcularValorTotalBruto,
        obterConfiguracaoTributariaConformeProduto:
          handleObterConfiguracaoTributariaConformeProduto,
        obterConfiguracaoTributaria: handleObterConfiguracaoTributaria,
        calcularQuantidadeTributadoProdutoTributado:
          handleCalcularQuantidadeTributadoProdutoTributado,
        calcularQuantidadeUnitariaTributadoProdutoTributado:
          handleCalcularQuantidadeUnitariaTributadoProdutoTributado,
        calcularValorUnitarioTributadoProdutoTributado:
          handleCalcularValorUnitarioTributadoProdutoTributado,
        obterRegraEscolhaAliquota: handleObterRegraEscolhaAliquota,
        obterRegraEscolhaAliquotaOperacaoInterestadual:
          handleObterRegraEscolhaAliquotaOperacaoInterestadual,
        obterDadosItemComercialRepresentante:
          handleCarregarDadosItemComercialRepresentante,
        limparDadosItemComercialRepresentante:
          handleLimparDadosItemComercialRepresentante,
        calcularBaseCalculoItemComercialRepresentante:
          handleCalcularBaseCalculoItemComercialRepresentante,
        obterRegraEscolhaTabelaPreco: handleObterRegraEscolhaTabelaPreco,
        obterRegraEscolhaParametros: handleObterRegraEscolhaParametros,
        calcularItemEngenhariaPesagem: handleCalcularItemEngenhariaPesagem,
        calcularItemEngenhariaMetragem: handleCalcularItemEngenhariaMetragem,
        calcularImpostoRetido: handleCalcularImpostoRetido,
        limparConfiguracaoTributaria: handleLimparConfiguracaoTributaria,

        validador: true,
      }}
    >
      {children}
    </ItemTabContext.Provider>
  );
};

function UseItemTab(): Omit<IItemTabContext, 'validador'> {
  const context = useContext(ItemTabContext);

  if (!context.validador) {
    throw new Error('UseItemTab deve ser usado com um ItemTabHook');
  }

  return context;
}

export { ItemTabHook, UseItemTab };
