"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validarAoMenosUm = void 0;
const Yup = __importStar(require("yup"));
/**
 * Aplica uma validação personalizada para verificar se ao menos uma das propriedades informadas está preenchida.
 * Caso nenhum dos campos esteja preenchido, gera um erro de validação para cada campo na lista fornecida.
 *
 * @param {Yup.ObjectSchema<any>} schema - O esquema Yup original para validação dos campos.
 * @param {string[]} list - Lista de nomes das propriedades a serem verificadas.
 * @returns {Yup.ObjectSchema<any>} - O esquema Yup modificado com a validação adicional.
 *
 * @example
 * const schema = validarAoMenosUm(
 *   Yup.object().shape({
 *     data: Yup.string().nullable(),
 *     dataContabil: Yup.string().nullable(),
 *   }),
 *   ['data', 'dataContabil']
 * );
 */
const validarAoMenosUm = (schema, list) => {
    return schema.test({
        name: 'validarAoMenosUm',
        test(value) {
            const algumPreenchido = list.some((field) => value && value[field] != null && value[field] !== '');
            if (algumPreenchido)
                return true;
            const errors = list
                .map((field) => {
                if (!value || value[field] == null || value[field] === '') {
                    return this.createError({
                        path: field,
                        message: '*',
                    });
                }
                return null;
            })
                .filter((error) => error !== null);
            if (errors.length > 0) {
                const erroGeral = this.createError({
                    path: '',
                    message: `Pelo menos um dos campos deve ser preenchido. [ ${list.join(', ')} ]`,
                });
                throw new Yup.ValidationError([erroGeral, ...errors]);
            }
            return true;
        },
    });
};
exports.validarAoMenosUm = validarAoMenosUm;
exports.default = Yup;
