import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/cadastro-mesa/log';

interface ICadastroMesaLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface ICadastroMesaLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class CadastroMesaLogComunicador {
  public async index(
    params: ICadastroMesaLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: ICadastroMesaLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new CadastroMesaLogComunicador();
