"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var MeioPagamentoEnum;
(function (MeioPagamentoEnum) {
    MeioPagamentoEnum["dinheiro"] = "01 - Dinheiro";
    MeioPagamentoEnum["cheque"] = "02 - Cheque";
    MeioPagamentoEnum["cartaoCredito"] = "03 - Cart\u00E3o de Cr\u00E9dito";
    MeioPagamentoEnum["cartaoDebito"] = "04 - Cart\u00E3o de D\u00E9bito";
    MeioPagamentoEnum["creditoLoja"] = "05 - Cart\u00E3o da Loja, Credi\u00E1r. Digi. Outr. Credi\u00E1r.";
    MeioPagamentoEnum["valeAlimentaca"] = "10 - Vale Alimenta\u00E7\u00E3o";
    MeioPagamentoEnum["valeRefeicao"] = "11 - Vale Refei\u00E7\u00E3o";
    MeioPagamentoEnum["valePresente"] = "12 - Vale Presente";
    MeioPagamentoEnum["valeCombustivel"] = "13 - Vale Combust\u00EDvel";
    MeioPagamentoEnum["duplicataMercantil"] = "14 - Duplicata Mercantil";
    MeioPagamentoEnum["boletoBancario"] = "15 - Boleto Banc\u00E1rio";
    MeioPagamentoEnum["depositoBancario"] = "16 - Dep\u00F3sito Banc\u00E1rio";
    MeioPagamentoEnum["pagamentoInstantaneoPix"] = "17 - Pagamento Instant\u00E2neo (PIX) - Din\u00E2mico";
    MeioPagamentoEnum["transferenciaBancariaCarteiraDigital"] = "18 - Transfer\u00EAncia Banc\u00E1ria, Carteira Digital";
    MeioPagamentoEnum["programaFidelidadeCashbackCreditoVirtual"] = "19 - Programa de Fidelidade, Cashback, Cr\u00E9dito Virtual";
    MeioPagamentoEnum["pagamentoInstantaneoPixEstatico"] = "20 - Pagamento Instant\u00E2neo (PIX) - Est\u00E1tico";
    MeioPagamentoEnum["creditoEmLoja"] = "21 - Cr\u00E9dito em Loja";
    MeioPagamentoEnum["pagamentoEletronicoNaoInformado"] = "22 - Pagamento Eletr\u00F4nico n\u00E3o Informado - falha de hardware";
    MeioPagamentoEnum["semPagamento"] = "90 - Sem Pagamento";
    MeioPagamentoEnum["outro"] = "99 - Outros";
})(MeioPagamentoEnum || (MeioPagamentoEnum = {}));
exports.default = MeioPagamentoEnum;
