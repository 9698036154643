/* eslint-disable array-callback-return */
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  EnumObterValue,
  SituacaoManifestoTransporteEnum,
  AmbienteEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend, BiXCircle } from 'react-icons/bi/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { FiFile } from 'react-icons/fi/index.mjs';
import IPermissao from '../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import ManifestoTransporteTransmitirComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteTransmitirComunicador';
import CancelarDetalhe from './Cancelar/Detalhe';
import CancelarHook from './Cancelar/Hooks';
import EncerrarDetalhe from './Encerrar/Detalhe';
import EncerrarHook from './Encerrar/Hooks';
import ManifestoTransporteImpressaoComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Impressao/ManifestoTransporteImpressao/ManifestoTransporteImpressaoComunicador';
import ImpressaoManifestoTransporte from '../../../../DocumentosEletronicos/MDFe/Impressao/index';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import JanelaEnvioEmail from '../../../../../Componentes/JanelaEnvioEmail';
import ContaEmailObterDadosComunicador from '../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import PessoaContatoObterEmailComunicador from '../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import ManifestoTransporteExportarXmlComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteExportarXmlComunicador';
import VisualizarPDF from '../../../../../Util/Relatorios/VisualizarPDF';
import ManifestoTransporteUploadPdfComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteUploadPdfComunicador';
import TratarValidadeCertificado from '../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import ManifestoTransporteConsultarProtocoloComunicador from '../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteConsultarProtocoloComunicador';

interface IAcoesManifestoTransporte {
  permissao?: IPermissao;
}

const AcoesManifestoTransporte: React.FC<IAcoesManifestoTransporte> = ({
  permissao,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();
  const { abrirJanela } = UseConfirmacao();

  const [showFormModalCancelar, setShowFormModalCancelar] = useState(false);
  const [showFormModalEncerrar, setShowFormModalEncerrar] = useState(false);
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const idRegistroDetalhe = getIdDetalheRegistro();
  const situacao = formRef.current?.getFieldValue('situacao');
  const numero = formRef.current?.getFieldValue('numero');

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      if (formRef.current?.validarSeAlterou()) {
        const data = formRef.current?.getData();
        const { erro } = await handleSubmit(data);
        return !erro;
      }

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      const idRegistro = getIdDetalheRegistro() || '';

      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      const response = await ManifestoTransporteImpressaoComunicador.show({
        id: idRegistro,
      });

      if (response.pdf) {
        if (visualizarPdf) {
          VisualizarPDF({ dados: response.pdf });
        }

        blobPdf = response.pdf;
        pdfSalvo = true;
      } else {
        if (visualizarPdf) {
          await ImpressaoManifestoTransporte.handleVisualizarPdf(
            response.impressao
          );
        }

        blobPdf = await ImpressaoManifestoTransporte.handleObterBlobPdf(
          response.impressao
        );
        pdfSalvo = false;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [getIdDetalheRegistro]
  );

  const handleVisualizarImpressao = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      await handleVisualizarObterBlobPdf(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleSalvar, handleVisualizarObterBlobPdf, setLoading]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const dadosPdf = await handleVisualizarObterBlobPdf(true);
      if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
        const chaveAcesso = formRef.current?.getFieldValue(
          'transmissao.chaveAcesso'
        );
        const ambiente = formRef.current?.getFieldValue('transmissao.ambiente');

        const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);

        const formData = new FormData();
        formData.append(
          'bloblPdf',
          dadosPdf.blobPdf as any,
          `${chaveAcesso}#ambiente:${ambienteFormatado}`
        );

        await ManifestoTransporteUploadPdfComunicador.update({
          params: formData,
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, handleSalvar, handleVisualizarObterBlobPdf, setLoading]);

  const handleExportarXml = useCallback(async (): Promise<void> => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const listaResponse =
        await ManifestoTransporteExportarXmlComunicador.show({
          id: idRegistro,
        });

      for (let i = 0; i < listaResponse.length; i++) {
        const response = listaResponse[i];

        await ImpressaoManifestoTransporte.handleExportarXml(
          response.xml,
          response.nomeArquivo
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const idRegistro = getIdDetalheRegistro() || '';

      const listaIdContratante: string[] = [];
      const listaManifestoTransporteContratante =
        formRef.current?.getFieldValue('listaManifestoTransporteContratante');
      listaManifestoTransporteContratante.listaValor.map((valor: any) => {
        listaIdContratante.push(valor.id);
      });

      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: listaIdContratante,
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: true,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];

      const anexo = await handleVisualizarObterBlobPdf(false);

      const data = new Uint8Array((anexo as any).blobPdf.data);
      const blobPdf = new Blob([data], {
        type: 'application/pdf',
      });

      listaAnexo.push({
        nomeArquivo: `Manifesto de Transporte ${numero}.pdf`,
        file: blobPdf,
        permitirExcluir: false,
      });

      const listaResponseXml =
        await ManifestoTransporteExportarXmlComunicador.show({
          id: idRegistro,
        });

      for (let i = 0; i < listaResponseXml.length; i++) {
        const responseXml = listaResponseXml[i];

        const utf8Content = new TextEncoder().encode(responseXml.xml as any);
        const blobXml = new Blob([utf8Content], {
          type: 'text/xml;charset=UTF-8',
        });

        listaAnexo.push({
          nomeArquivo: `${responseXml.nomeArquivo}.xml`,
          file: blobXml,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Manifesto de Transporte número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [formRef, getIdDetalheRegistro, handleVisualizarObterBlobPdf, numero]
  );

  // #endregion Email

  // #region Encerrar

  const handleTransmitir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente transmitir esse Manifesto de Transporte?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response = await ManifestoTransporteTransmitirComunicador.update({
        id: idRegistro,
      });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    handleSalvar,
    setLoading,
  ]);

  const handleEncerrar = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFormModalEncerrar(true);
  }, [handleSalvar]);

  const handleEncerrarSalvarFormModal = useCallback(async (): Promise<void> => {
    setShowFormModalEncerrar(false);
    await handleImprimir();
    await handleCarregarDados();
  }, [handleCarregarDados, handleImprimir]);

  const handleEncerrarFecharFormModal = useCallback(async (): Promise<void> => {
    setShowFormModalEncerrar(false);
  }, []);

  // #endregion Encerrar

  // #region Cancelar

  const handleCancelar = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFormModalCancelar(true);
  }, [handleSalvar]);

  const handleCancelarSalvarFormModal = useCallback(async (): Promise<void> => {
    setShowFormModalCancelar(false);
    await handleImprimir();
    await handleCarregarDados();
  }, [handleCarregarDados, handleImprimir]);

  const handleCancelarFecharFormModal = useCallback(async (): Promise<void> => {
    setShowFormModalCancelar(false);
  }, []);

  // #endregion Cancelar

  const handleConsultarProtocolo = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response =
        await ManifestoTransporteConsultarProtocoloComunicador.update({
          id: idRegistro,
        });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    handleSalvar,
    setLoading,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={
            loading ||
            (situacao !== SituacaoManifestoTransporteEnum.naoTransmitido &&
              situacao !== SituacaoManifestoTransporteEnum.rejeitado)
          }
          onClick={handleVisualizarImpressao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Visualizar Impressão
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacao !== SituacaoManifestoTransporteEnum.autorizado &&
              situacao !== SituacaoManifestoTransporteEnum.encerrado &&
              situacao !== SituacaoManifestoTransporteEnum.cancelado)
          }
          onClick={handleImprimir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleExportarXml}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <FiFile style={{ marginRight: 5 }} />
            Exportar XML (Download)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacao !== SituacaoManifestoTransporteEnum.autorizado &&
              situacao !== SituacaoManifestoTransporteEnum.encerrado &&
              situacao !== SituacaoManifestoTransporteEnum.cancelado)
          }
          onClick={handleEnviarPorEmail}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !permissao?.altera ||
            situacao === SituacaoManifestoTransporteEnum.autorizado ||
            situacao === SituacaoManifestoTransporteEnum.encerrado ||
            situacao === SituacaoManifestoTransporteEnum.cancelado
          }
          onClick={handleTransmitir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Transmitir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacao === SituacaoManifestoTransporteEnum.naoTransmitido ||
            situacao === SituacaoManifestoTransporteEnum.cancelado ||
            situacao === SituacaoManifestoTransporteEnum.rejeitado ||
            situacao === SituacaoManifestoTransporteEnum.encerrado
          }
          onClick={handleEncerrar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiXCircle style={{ marginRight: 5 }} />
            Encerrar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacao === SituacaoManifestoTransporteEnum.naoTransmitido ||
            situacao === SituacaoManifestoTransporteEnum.encerrado ||
            situacao === SituacaoManifestoTransporteEnum.rejeitado ||
            situacao === SituacaoManifestoTransporteEnum.cancelado
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleConsultarProtocolo}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Consultar Protocolo MDF-e
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showFormModalCancelar && (
        <CancelarHook>
          <CancelarDetalhe
            onSalvarFormModal={handleCancelarSalvarFormModal}
            onFecharFormModal={handleCancelarFecharFormModal}
          />
        </CancelarHook>
      )}

      {showFormModalEncerrar && (
        <EncerrarHook>
          <EncerrarDetalhe
            onSalvarFormModal={handleEncerrarSalvarFormModal}
            onFecharFormModal={handleEncerrarFecharFormModal}
          />
        </EncerrarHook>
      )}

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Manifesto de Transporte por E-mail"
          mensagemPadrao={`Segue em anexo o Manifesto de Transporte número: ${numero}`}
          direcionarOrcamento
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}
    </>
  );
};

export default AcoesManifestoTransporte;
