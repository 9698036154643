const ComissoesPedidoVendaPorFaturamentoComClientePlanilha = [
  {
    titulo: 'Representante',
    valor: 'listaComissoesPedidoVendaItem.representanteNomeRazaoSocial',
  },
  {
    titulo: 'Data Emissão',
    valor: 'listaComissoesPedidoVendaItem.pedidoVendaDataHoraEmissao',
  },

  {
    titulo: 'Tipo ',
    valor: 'listaComissoesPedidoVendaItem.tipo',
  },

  {
    titulo: 'Número',
    valor: 'listaComissoesPedidoVendaItem.pedidoVendaNumero',
  },
  {
    titulo: 'Cliente',
    valor: 'listaComissoesPedidoVendaItem.clienteNomeRazaoSocial',
  },

  {
    titulo: 'Base Comissão',
    valor: 'listaComissoesPedidoVendaItem.baseCalculo',
  },

  {
    titulo: 'Percentual',
    valor: 'listaComissoesPedidoVendaItem.aliquota',
  },
  {
    titulo: 'Valor',
    valor: 'listaComissoesPedidoVendaItem.valor',
  },
];

export default ComissoesPedidoVendaPorFaturamentoComClientePlanilha;
