import {
  IcmsObterVisibilidadeBloqueioCamposPorCst,
  IsSimplesNacional,
} from '@elogestor/util';
import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputHiddenHtml from '../../../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import NotaFiscalEntradaItemImpostoIcmsComunicador from '../../../../../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Item/Comunicador/Imposto/NotaFiscalEntradaItemImpostoIcmsComunicador';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRedirecionar } from '../../../../../../../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../../../../../../../Util/Erro/TratarErros';
import { UseRegimeTributario } from '../../../../../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';

const camposEscondidosIcmsPadrao = {
  // #region ICMS
  calcularIcms: true,

  percentualReducaoBaseCalculoIcms: true,
  baseCalculoIcms: true,
  aliquotaIcms: true,
  valorIcms: true,

  baseCalculoFcpIcms: true,
  aliquotaFcpIcms: true,
  valorFcpIcms: true,
  valorIcmsEValorFcpIcms: true,

  valorSemDiferimentoIcms: true,
  percentualDiferimentoIcms: true,
  valorDiferidoIcms: true,

  calcularIcmsEfetivo: true,
  percentualReducaoBaseCalculoIcmsEfetivo: true,
  baseCalculoIcmsEfetivo: true,
  aliquotaIcmsEfetivo: true,
  valorIcmsEfetivo: true,

  calcularIcmsSubstituto: true,
  valorIcmsSubstituto: true,

  // #endregion ICMS

  // #region ICMS ST

  calcularIcmsStRetido: true,
  baseCalculoIcmsStRetido: true,
  aliquotaIcmsStRetido: true,
  valorIcmsStRetido: true,

  baseCalculoFcpIcmsStRetido: true,
  aliquotaFcpIcmsStRetido: true,
  valorFcpIcmsStRetido: true,

  // #endregion ICMS ST
};

const camposBloqueadosIcmsPadrao = {
  // #region ICMS

  calcularIcms: true,

  percentualReducaoBaseCalculoIcms: true,
  baseCalculoIcms: true,
  aliquotaIcms: true,
  valorIcms: true,

  baseCalculoFcpIcms: true,
  aliquotaFcpIcms: true,
  valorFcpIcms: true,
  valorIcmsEValorFcpIcms: true,

  valorSemDiferimentoIcms: true,
  percentualDiferimentoIcms: true,
  valorDiferidoIcms: true,

  calcularIcmsEfetivo: true,
  percentualReducaoBaseCalculoIcmsEfetivo: true,
  baseCalculoIcmsEfetivo: true,
  aliquotaIcmsEfetivo: true,
  valorIcmsEfetivo: true,

  calcularIcmsSubstituto: true,
  valorIcmsSubstituto: true,

  // #endregion ICMS

  // #region ICMS ST

  calcularIcmsStRetido: true,
  baseCalculoIcmsStRetido: true,
  aliquotaIcmsStRetido: true,
  valorIcmsStRetido: true,

  baseCalculoFcpIcmsStRetido: true,
  aliquotaFcpIcmsStRetido: true,
  valorFcpIcmsStRetido: true,

  // #endregion ICMS ST
};

const ICMSTab: React.FC = () => {
  const formPrincipal = UseForm();
  const { formRefDetalhe, setLoading } = UseListaDetalheForm();
  const { regimeTributario } = UseRegimeTributario();
  const { redirecionar } = UseRedirecionar();

  const [isEmitenteSimplesNacional, setIsEmitenteSimplesNacional] =
    useState(false);

  const [camposEscondidosIcms, setCamposEscondidosIcms] = useState(
    camposEscondidosIcmsPadrao
  );

  const [camposBloqueadosIcms, setCamposBloqueadosIcms] = useState(
    camposBloqueadosIcmsPadrao
  );

  const calcularIcms = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsFiscalSn.carregarCreditarIcmsConformeDocumento'
  );
  const calcularIcmsEfetivo = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsFiscalSn.calcularIcmsEfetivoConformeDocumento'
  );
  const calcularIcmsSubstituto = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsFiscalSn.creditarIcmsSubstitutoeRetidoConformeDocumento'
  );
  const calcularIcmsStRetido = formRefDetalhe.current?.getFieldValue(
    'notaFiscalEntradaItemImpostoIcmsFiscalSn.creditarIcmsSubstitutoeRetidoConformeDocumento'
  );

  const cst =
    formRefDetalhe.current?.getFieldValueNomeObjeto(
      'notaFiscalEntradaItemImpostoIcmsFiscalSn.situacaoTributaria'
    )?.codigo || '';

  useEffect(() => {
    if (regimeTributario) {
      if (IsSimplesNacional(regimeTributario)) {
        setIsEmitenteSimplesNacional(true);
      } else {
        setIsEmitenteSimplesNacional(false);
      }
    }
  }, [isEmitenteSimplesNacional, regimeTributario]);

  const handleObterVisibilidadeBloqueioCamposPorIcmsCst = useCallback(() => {
    const retorno = IcmsObterVisibilidadeBloqueioCamposPorCst({
      calcularIcms,
      calcularIcmsEfetivo,
      calcularIcmsSubstituto,
      calcularIcmsStRetido,
      calcularCreditoIcmsSn: false,
      calcularIcmsSt: false,
      calcularDesoneracaoIcms: false,
      cst,
    });

    setCamposEscondidosIcms(retorno.escondido);
    setCamposBloqueadosIcms(retorno.bloqueado);
  }, [
    calcularIcms,
    calcularIcmsEfetivo,
    calcularIcmsStRetido,
    calcularIcmsSubstituto,
    cst,
  ]);

  const handleObterDadosItemImpostoIcms = useCallback(
    async (creditarIcms: boolean) => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() ?? '';
        const retorno = await NotaFiscalEntradaItemImpostoIcmsComunicador.show({
          id,
          params: {
            creditarIcms,
          },
        });

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaFcpIcms',
          retorno.aliquotaFcpIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaIcms',
          retorno.aliquotaFcpIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoFcpIcms',
          retorno.baseCalculoFcpIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoIcms',
          retorno.baseCalculoIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.percentualDiferimentoIcms',
          retorno.percentualDiferimentoIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.percentualReducaoBaseCalculoIcms',
          retorno.percentualReducaoBaseCalculoIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorDiferidoIcms',
          retorno.valorDiferidoIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorFcpIcms',
          retorno.valorFcpIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcms',
          retorno.valorIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsEValorFcpIcms',
          retorno.valorIcmsEValorFcpIcms
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorSemDiferimentoIcms',
          retorno.valorSemDiferimentoIcms
        );
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
      }
    },
    [formPrincipal, formRefDetalhe, redirecionar, setLoading]
  );

  const handleObterDadodsItemImpostoIcmsSubEfetRet = useCallback(
    async (atualizarCreditarIcmsSubstitutoRetidoEfetivo: boolean) => {
      try {
        setLoading(true);

        const id = formPrincipal.getIdDetalheRegistro() ?? '';
        const retorno = await NotaFiscalEntradaItemImpostoIcmsComunicador.show({
          id,
          params: {
            atualizarCreditarIcmsSubstitutoRetidoEfetivo,
          },
        });

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoIcmsStRetido',
          retorno.baseCalculoIcmsStRetido
        );

        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaIcmsStRetido',
          retorno.aliquotaIcmsStRetido
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsStRetido',
          retorno.valorIcmsStRetido
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsSubstituto',
          retorno.valorIcmsSubstituto
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoFcpIcmsStRetido',
          retorno.baseCalculoFcpIcmsStRetido
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaFcpIcmsStRetido',
          retorno.aliquotaFcpIcmsStRetido
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorFcpIcmsStRetido',
          retorno.valorFcpIcmsStRetido
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoIcmsEfetivo',
          retorno.baseCalculoIcmsEfetivo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaIcmsEfetivo',
          retorno.aliquotaIcmsEfetivo
        );
        formRefDetalhe.current?.setFieldValue(
          'notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsEfetivo',
          retorno.valorIcmsEfetivo
        );
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
      }
    },
    [formPrincipal, formRefDetalhe, redirecionar, setLoading]
  );

  useEffect(() => {
    handleObterVisibilidadeBloqueioCamposPorIcmsCst();
  }, [handleObterVisibilidadeBloqueioCamposPorIcmsCst]);

  return (
    <div>
      <Divisor>
        <Row>
          <InputHiddenHtml name="notaFiscalEntradaItemImpostoIcmsFiscalSn.id" />
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CSOSN ou CST"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.idSituacaoTributaria"
              placeholder="CSOSN ou CST"
              nomeObjeto="notaFiscalEntradaItemImpostoIcmsFiscalSn.situacaoTributaria"
              icms={!isEmitenteSimplesNacional}
              icmsSimplesNacional={isEmitenteSimplesNacional}
              onChangeItemAtualAposCarregarSemClear={() => {
                handleObterVisibilidadeBloqueioCamposPorIcmsCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcms}
          >
            <div className="checkbox-container">
              <InputCheckbox
                label="Carregar e Creditar ICMS Conforme Documento"
                name="notaFiscalEntradaItemImpostoIcmsFiscalSn.carregarCreditarIcmsConformeDocumento"
                disabled={camposBloqueadosIcms.calcularIcms}
                onChange={async (event) => {
                  handleObterVisibilidadeBloqueioCamposPorIcmsCst();
                  if (event.target.checked) {
                    await handleObterDadosItemImpostoIcms(event.target.checked);
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualReducaoBaseCalculoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualReducaoBaseCalculoIcms}
              label="Percentual Redução Base"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.percentualReducaoBaseCalculoIcms"
              placeholder="Percentual Redução Base"
              casasInteiras={5}
              casasDecimais={6}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcms}
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoIcms"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.aliquotaIcms}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcms}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaIcms"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorIcms}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcms}
              label="Valor Total"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcms"
              placeholder="Valor Total"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.valorSemDiferimentoIcms &&
          camposEscondidosIcms.percentualDiferimentoIcms &&
          camposEscondidosIcms.valorDiferidoIcms
        }
      >
        <h5>Diferimento</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorSemDiferimentoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorSemDiferimentoIcms}
              label="Valor Sem Diferimento"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorSemDiferimentoIcms"
              placeholder="Valor Sem Diferimento"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualDiferimentoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualDiferimentoIcms}
              label="Percentual Diferimento"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.percentualDiferimentoIcms"
              placeholder="Percentual Diferimento"
              casasInteiras={5}
              casasDecimais={6}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorDiferidoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorDiferidoIcms}
              label="Valor Diferido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorDiferidoIcms"
              placeholder="Valor Diferido"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.baseCalculoFcpIcms &&
          camposEscondidosIcms.aliquotaFcpIcms &&
          camposEscondidosIcms.valorFcpIcms &&
          camposEscondidosIcms.valorIcmsEValorFcpIcms
        }
      >
        <h5>Fundo de Combate a Pobreza</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoFcpIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoFcpIcms}
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoFcpIcms"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaFcpIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaFcpIcms}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaFcpIcms"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorFcpIcms}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorFcpIcms}
              label="Valor"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorFcpIcms"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsEValorFcpIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsEValorFcpIcms}
              label="Valor e Valor ICMS"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsEValorFcpIcms"
              placeholder="Valor e Valor ICMS"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsEfetivo &&
          camposEscondidosIcms.percentualReducaoBaseCalculoIcmsEfetivo &&
          camposEscondidosIcms.baseCalculoIcmsEfetivo &&
          camposEscondidosIcms.aliquotaIcmsEfetivo &&
          camposEscondidosIcms.valorIcmsEfetivo
        }
      >
        <h5>Efetivo</h5>
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcmsEfetivo}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularIcmsEfetivo}
                label="Calcular ICMS Efetivo Conforme Documento"
                name="notaFiscalEntradaItemImpostoIcmsFiscalSn.calcularIcmsEfetivoConformeDocumento"
                onChange={() => {
                  handleObterVisibilidadeBloqueioCamposPorIcmsCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcms.percentualReducaoBaseCalculoIcmsEfetivo
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.percentualReducaoBaseCalculoIcmsEfetivo
              }
              label="Percentual Redução Base"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.percentualReducaoBaseCalculoIcmsEfetivo"
              placeholder="Percentual Redução Base"
              casasInteiras={5}
              casasDecimais={6}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcmsEfetivo}
              label="Base de Cálculo"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoIcmsEfetivo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcmsEfetivo}
              label="Alíquota"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaIcmsEfetivo"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsEfetivo}
              label="Valor ICMS Efetivo"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsEfetivo"
              placeholder="Valor ICMS Efetivo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsStRetido &&
          camposEscondidosIcms.calcularIcmsSubstituto &&
          camposEscondidosIcms.baseCalculoIcmsStRetido &&
          camposEscondidosIcms.aliquotaIcmsStRetido &&
          camposEscondidosIcms.valorIcmsStRetido &&
          camposEscondidosIcms.baseCalculoFcpIcmsStRetido &&
          camposEscondidosIcms.aliquotaFcpIcmsStRetido &&
          camposEscondidosIcms.valorFcpIcmsStRetido &&
          camposEscondidosIcms.valorIcmsSubstituto
        }
      >
        <h5>Substituto / Retido</h5>
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="div-checkbox"
            hidden={
              camposEscondidosIcms.calcularIcmsStRetido &&
              camposEscondidosIcms.calcularIcmsSubstituto
            }
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={
                  camposBloqueadosIcms.calcularIcmsStRetido &&
                  camposBloqueadosIcms.calcularIcmsSubstituto
                }
                label="Calcular ICMS Substituto e Retido Conforme Documento"
                name="notaFiscalEntradaItemImpostoIcmsFiscalSn.creditarIcmsSubstitutoeRetidoConformeDocumento"
                onChange={async (event) => {
                  handleObterVisibilidadeBloqueioCamposPorIcmsCst();
                  if (event.target.checked) {
                    await handleObterDadodsItemImpostoIcmsSubEfetRet(
                      event.target.checked
                    );
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcmsStRetido}
              label="Base Calc. ICMS ST Retido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoIcmsStRetido"
              placeholder="Base Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcmsStRetido}
              label="Alíquota ICMS ST Retido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaIcmsStRetido"
              placeholder="Alíquota"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsStRetido}
              label="Valor ICMS ST Retido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsStRetido"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoFcpIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoFcpIcmsStRetido}
              label="Base Calc. FCP ICMS ST Retido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.baseCalculoFcpIcmsStRetido"
              placeholder="Base Cálculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaFcpIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaFcpIcmsStRetido}
              label="Alíquota FCP ICMS ST Retido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.aliquotaFcpIcmsStRetido"
              placeholder="Alíquota"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorFcpIcmsStRetido}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorFcpIcmsStRetido}
              label="Valor FCP ICMS ST Retido"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorFcpIcmsStRetido"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsSubstituto}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsSubstituto}
              label="Valor ICMS Substituto"
              name="notaFiscalEntradaItemImpostoIcmsFiscalSn.valorIcmsSubstituto"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ICMSTab;
