import { BooleanEnum, FormatarEnum, TipoContaEnum } from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import Select from '../../../../../Componentes/Select';
import InputAutoCompleteTagSpedTipoProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagModeloDocumento from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagFormaPagamento from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteTagCategoria from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagCategoria';
import InputAutoCompleteTagPessoa from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagRamoAtividadeProfissao from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagRamoAtividadeProfissao';
import InputAutoCompleteTagTipoLancamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoLancamentoEnum';
import InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    data.pesquisaAvancada = true;
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaFormaPagamento =
        parametros.pesquisaAvancada?.listaFormaPagamento;
      if (listaFormaPagamento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Formas de Pagamento',
          valor: listaFormaPagamento.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaPessoa = parametros.pesquisaAvancada?.listaPessoa;
      if (listaPessoa.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Pessoas',
          valor: listaPessoa.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaSpedTipoProduto =
        parametros.pesquisaAvancada?.listaSpedTipoProduto;
      if (listaSpedTipoProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipos SPED',
          valor: listaSpedTipoProduto.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaModeloDocumento =
        parametros.pesquisaAvancada?.listaModeloDocumento;
      if (listaModeloDocumento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Modelo Documento',
          valor: listaModeloDocumento.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaRamoAtividadeProfissao =
        parametros.pesquisaAvancada?.listaRamoAtividadeProfissao;
      if (listaRamoAtividadeProfissao.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ramos de Atividade',
          valor: listaRamoAtividadeProfissao.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaGrupoProdutoServico =
        parametros.pesquisaAvancada?.listaGrupoProdutoServico;
      if (listaGrupoProdutoServico.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Grupos de Produto',
          valor: listaGrupoProdutoServico.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaOperacaoFiscal =
        parametros.pesquisaAvancada?.listaOperacaoFiscal;
      if (listaOperacaoFiscal.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Lista de CFOP',
          valor: listaOperacaoFiscal.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.cfop}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaTipoTransacao =
        parametros.pesquisaAvancada?.listaTipoTransacao;
      if (listaTipoTransacao.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipos de Trans. Oper. Fiscal (CFOP)',
          valor: listaTipoTransacao.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaTipoLancamento =
        parametros.pesquisaAvancada?.listaTipoLancamento;
      if (listaTipoLancamento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipos de Lançamento',
          valor: listaTipoLancamento.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaCategoria = parametros.pesquisaAvancada?.listaCategoria;
      if (listaCategoria.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Categorias',
          valor: listaCategoria.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const tipoConta = parametros.pesquisaAvancada?.tipoConta;
      if (!tipoConta) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Operação (Débito/Crédito)',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo (Pagar/Receber)',
          valor: tipoConta === 'pagar' ? 'Pagar' : 'Receber',
        });
      }

      const avista = parametros.pesquisaAvancada?.avista;
      if (!avista) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'A Vista',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'A Vista',
          valor: avista,
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <Select
                  label="Tipo (Receber / Pagar)"
                  name="tipoConta"
                  options={FormatarEnum({
                    enumObj: TipoContaEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagTipoLancamentoEnum
                  label="Tipos de Lançamento"
                  name="listaTipoLancamento"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagFormaPagamento
                  label="Formas de Pagamento"
                  name="listaFormaPagamento"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagPessoa
                  label="Pessoas"
                  name="listaPessoa"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagRamoAtividadeProfissao
                  label="Ramos de Atividade"
                  name="listaRamoAtividadeProfissao"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagModeloDocumento
                  label="Modelo Documento"
                  name="listaModeloDocumento"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagSpedTipoProduto
                  label="Tipos SPED"
                  name="listaSpedTipoProduto"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagTipoTransacaoOperacaoFiscalEnum
                  label="Tipos de Trans. Oper. Fiscal (CFOP)"
                  name="listaTipoTransacao"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagOperacaoFiscal
                  label="Lista de CFOP"
                  name="listaOperacaoFiscal"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagGrupoProdutoServico
                  label="Grupos de Produto"
                  name="listaGrupoProdutoServico"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagProduto
                  label="Produtos"
                  name="listaProduto"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCategoria
                  label="Categorias"
                  name="listaCategoria"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={12}>
                <Select
                  name="avista"
                  label="A Vista"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="ativo"
                  label="Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
