import { FormatarEnum, DiaSemanaEnum } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import Select from '../../../../../../../Componentes/Select';
import { UsePermissoes } from '../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import InputPeriodoHora from '../../../../../../../Componentes/Inputs/InputPeriodoHora';

interface IUsuarioAcessoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const UsuarioAcessoDetalhe: React.FC<IUsuarioAcessoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseListaDetalheForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    if (!idDetalheRegistro || idDetalheRegistro === '') {
      formRefDetalhe.current?.setData({
        horaInicial: '00:00',
        horaFinal: '23:59',
      });
    }
  }, [formRefDetalhe, idDetalheRegistro]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();

    const { erro } = await handleSubmit(data, formRefDetalhe.current);

    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Acesso"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <FormCia ref={formRefDetalhe}>
          <Divisor>
            <Row>
              <Col lg={8} md={8} sm={8}>
                <Select
                  label="Dia da Semana"
                  name="diaSemana"
                  options={FormatarEnum({
                    enumObj: DiaSemanaEnum,
                  })}
                />
              </Col>
              <Col lg={4} md={4} sm={4}>
                <InputPeriodoHora
                  nomeHoraInicial="horaInicial"
                  nomeHoraFinal="horaFinal"
                  labelHoraInicial="Hora Inicial"
                  labelHoraFinal="Hora Final"
                />
              </Col>
            </Row>
          </Divisor>
        </FormCia>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default UsuarioAcessoDetalhe;
