"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = AtualizarLeiConformeVariaveis;
function AtualizarLeiConformeVariaveis({ lei, baseIcmsSt, valorIcmsSt, valorTotalPisRetido, valorTotalCofinsRetido, percentualCredito, valorCredito, valorTotalIcmsFundoCombatePobrezaSt, valorTotalIcmsSemDiferimento, valorTotalIcmsDiferimento, percentualDiferimento, valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp, valorTotalIcmsOperacaoInterestadualEstadoDestino, aliquotaFunrural, valorFunrural, baseIcmsStRetido, valorIcmsStRetido, valorTotalFcpIcms, }) {
    let leiFormatada = lei;
    if (leiFormatada.includes('<#BaseIcmsSt#>') && baseIcmsSt)
        leiFormatada = leiFormatada.replace('<#BaseIcmsSt#>', baseIcmsSt);
    if (leiFormatada.includes('<#ValorIcmsSt#>') && valorIcmsSt)
        leiFormatada = leiFormatada.replace('<#ValorIcmsSt#>', valorIcmsSt);
    if (leiFormatada.includes('<#ValorTotalPisRetido#>') && valorTotalPisRetido)
        leiFormatada = leiFormatada.replace('<#ValorTotalPisRetido#>', valorTotalPisRetido);
    if (leiFormatada.includes('<#ValorTotalCofinsRetido#>') &&
        valorTotalCofinsRetido)
        leiFormatada = leiFormatada.replace('<#ValorTotalCofinsRetido#>', valorTotalCofinsRetido);
    if (leiFormatada.includes('<#PercentualCredito#>') && percentualCredito)
        leiFormatada = leiFormatada.replace('<#PercentualCredito#>', percentualCredito);
    if (leiFormatada.includes('<#ValorCredito#>') && valorCredito)
        leiFormatada = leiFormatada.replace('<#ValorCredito#>', valorCredito);
    if (leiFormatada.includes('<#ValorTotalIcmsFundoCombatePobrezaST#>') &&
        valorTotalIcmsFundoCombatePobrezaSt)
        leiFormatada = leiFormatada.replace('<#ValorTotalIcmsFundoCombatePobrezaST#>', valorTotalIcmsFundoCombatePobrezaSt);
    if (leiFormatada.includes('<#ValorTotalIcmsSemDiferimento#>') &&
        valorTotalIcmsSemDiferimento)
        leiFormatada = leiFormatada.replace('<#ValorTotalIcmsSemDiferimento#>', valorTotalIcmsSemDiferimento);
    if (leiFormatada.includes('<#ValorTotalIcmsDiferimento#>') &&
        valorTotalIcmsDiferimento)
        leiFormatada = leiFormatada.replace('<#ValorTotalIcmsDiferimento#>', valorTotalIcmsDiferimento);
    if (leiFormatada.includes('<#PercentualDiferimento#>') &&
        percentualDiferimento)
        leiFormatada = leiFormatada.replace('<#PercentualDiferimento#>', percentualDiferimento);
    if (leiFormatada.includes('<#AliquotaFunrural#>') && aliquotaFunrural)
        leiFormatada = leiFormatada.replace('<#AliquotaFunrural#>', aliquotaFunrural);
    if (leiFormatada.includes('<#ValorFunrural#>') && valorFunrural)
        leiFormatada = leiFormatada.replace('<#ValorFunrural#>', valorFunrural);
    if (leiFormatada.includes('<#BaseIcmsStRetido#>') && baseIcmsStRetido)
        leiFormatada = leiFormatada.replace('<#BaseIcmsStRetido#>', baseIcmsStRetido);
    if (leiFormatada.includes('<#ValorIcmsStRetido#>') && valorIcmsStRetido)
        leiFormatada = leiFormatada.replace('<#ValorIcmsStRetido#>', valorIcmsStRetido);
    if (leiFormatada.includes('<#ValorTotalIcmsOperacaoInterestadualEstadoDestinoFcp#>') &&
        (valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp === '0,00' ||
            !valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp)) {
        leiFormatada = leiFormatada.replace('Valor Total do ICMS Interestadual Relativo ao Fundo de Combate à Pobreza devido a UF de destino: R$ <#ValorTotalIcmsOperacaoInterestadualEstadoDestinoFcp#>', '');
    }
    else if (leiFormatada.includes('<#ValorTotalIcmsOperacaoInterestadualEstadoDestinoFcp#>') &&
        valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp) {
        leiFormatada = leiFormatada.replace('<#ValorTotalIcmsOperacaoInterestadualEstadoDestinoFcp#>', valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp);
    }
    // if (
    //   valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp &&
    //   String(
    //     valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp
    //   ).ConverterParaNumber() > 0
    // ) {
    //   const leiAdicional = `Valor Total do ICMS Interestadual Relativo ao Fundo de Combate à Pobreza devido a UF de destino: R$ ${valorTotalIcmsOperacaoInterestadualEstadoDestinoFcp}`;
    //   if (!leiFormatada.IsNullOrEmpty()) {
    //     leiFormatada += '\n';
    //   }
    //   leiFormatada += leiAdicional;
    // }
    if (leiFormatada.includes('<#ValorTotalIcmsOperacaoInterestadualEstadoDestino#>') &&
        (valorTotalIcmsOperacaoInterestadualEstadoDestino === '0,00' ||
            !valorTotalIcmsOperacaoInterestadualEstadoDestino)) {
        leiFormatada = leiFormatada.replace('Valor Total do ICMS Interestadual devido a UF de destino: R$ <#ValorTotalIcmsOperacaoInterestadualEstadoDestino#>', '');
    }
    else if (leiFormatada.includes('<#ValorTotalIcmsOperacaoInterestadualEstadoDestino#>') &&
        valorTotalIcmsOperacaoInterestadualEstadoDestino) {
        leiFormatada = leiFormatada.replace('<#ValorTotalIcmsOperacaoInterestadualEstadoDestino#>', valorTotalIcmsOperacaoInterestadualEstadoDestino);
    }
    // if (
    //   valorTotalIcmsOperacaoInterestadualEstadoDestino &&
    //   String(
    //     valorTotalIcmsOperacaoInterestadualEstadoDestino
    //   ).ConverterParaNumber() > 0
    // ) {
    //   const leiAdicional = `Valor Total do ICMS Interestadual devido a UF de destino: R$ ${valorTotalIcmsOperacaoInterestadualEstadoDestino}`;
    //   if (!leiFormatada.IsNullOrEmpty()) {
    //     leiFormatada += '\n';
    //   }
    //   leiFormatada += leiAdicional;
    // }
    if (leiFormatada.includes('<#ValorTotalFcpIcms#>') && valorTotalFcpIcms)
        leiFormatada = leiFormatada.replace('<#ValorTotalFcpIcms#>', valorTotalFcpIcms);
    return leiFormatada;
}
