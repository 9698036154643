import {
  FormatarCnpjCpf,
  FormatarTelefone,
  IPessoaPorDataCadastroCabecalhoRelatorio,
  IPessoaPorDataCadastroRelatorio,
  IPessoaFiltroRelatorioFrontend,
  IPessoaFiltroRelatorioBackend,
  FormatarDataAnoParaPtBr,
  FormatarDataParaPtBr,
} from '@elogestor/util';
import React from 'react';
import { empresaLogada } from '../../../../../../Hooks/Auth';
import CSV from '../../../../../../Util/Relatorios/CSV';
import PDF from '../../../../../../Util/Relatorios/PDF';
import Planilha from '../../../../../../Util/Relatorios/Planilha';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PessoaPorDataCadastroPDF from './PessoaPorDataCadastroPDF';
import PessoaPorDataCadastroPlanilha from './PessoaPorDataCadastroPlanilha';
import PessoaPorDataCadastroRelatorioComunicador from '../../../../../../Comunicador/Comercial/Relatorios/Pessoas/PessoaPorDataCadastroRelatorio/PessoaPorDataCadastroRelatorioComunicador';

interface IRelatorios {
  handleExportCSV(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleExportPlanilha(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleVisualizarPdf(filtros: IPessoaFiltroRelatorioFrontend): Promise<void>;
  handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined>;
}

const PessoaPorDataCadastroRelatorio = (): IRelatorios => {
  const ObterRelatorio = async (
    filtros: IPessoaFiltroRelatorioFrontend,
    exibirToastPeriodoSemDados = true
  ): Promise<IPessoaPorDataCadastroRelatorio | null> => {
    const filtroBack: IPessoaFiltroRelatorioBackend = {
      mesDiaInicial: filtros.mesDiaInicial,
      mesDiaFinal: filtros.mesDiaFinal,
    };

    if (filtros.dataHoraInicialCadastro) {
      filtroBack.dataHoraInicialCadastro = filtros.dataHoraInicialCadastro;
    }

    if (filtros.dataHoraFinalCadastro) {
      filtroBack.dataHoraFinalCadastro = filtros.dataHoraFinalCadastro;
    }

    if (filtros.listaCidade) {
      filtroBack.listaIdCidade =
        filtros.listaCidade.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaEstado) {
      filtroBack.listaIdEstado =
        filtros.listaEstado.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaRepresentante) {
      filtroBack.listaIdRepresentante =
        filtros.listaRepresentante.listaValor.map((value) => value.id) || [];
    }

    if (filtros.listaTags) {
      filtroBack.listaTextoTags =
        filtros.listaTags.listaValor.map((value) => value.textoTag) || [];
    }

    if (filtros?.cliente === 'true' || filtros?.cliente === 'false') {
      filtroBack.cliente = filtros?.cliente === 'true';
    }

    if (filtros?.fornecedor === 'true' || filtros?.fornecedor === 'false') {
      filtroBack.fornecedor = filtros?.fornecedor === 'true';
    }

    if (
      filtros?.transportador === 'true' ||
      filtros?.transportador === 'false'
    ) {
      filtroBack.transportador = filtros?.transportador === 'true';
    }

    if (
      filtros?.representante === 'true' ||
      filtros?.representante === 'false'
    ) {
      filtroBack.representante = filtros?.representante === 'true';
    }

    if (filtros?.produtor === 'true' || filtros?.produtor === 'false') {
      filtroBack.produtor = filtros?.produtor === 'true';
    }

    if (filtros?.motorista === 'true' || filtros?.motorista === 'false') {
      filtroBack.motorista = filtros?.motorista === 'true';
    }

    if (filtros?.tecnico === 'true' || filtros?.tecnico === 'false') {
      filtroBack.tecnico = filtros?.tecnico === 'true';
    }

    if (filtros?.ativo === 'true' || filtros?.ativo === 'false') {
      filtroBack.ativo = filtros?.ativo === 'true';
    }

    const response = await PessoaPorDataCadastroRelatorioComunicador.index({
      params: { filtro: filtroBack },
    });

    if (!response.listaDados || response.listaDados.length === 0) {
      if (exibirToastPeriodoSemDados) ToastInfo('Relatório sem dados!');
      return null;
    }

    const retorno = response as IPessoaPorDataCadastroRelatorio;
    const listaDados = await Promise.all(
      retorno.listaDados.map((dado) => {
        dado.listaPessoa.forEach((pessoa) => {
          pessoa.cnpjCpfVirtual = FormatarCnpjCpf(pessoa.cnpjCpfVirtual);
          pessoa.telefone = FormatarTelefone(pessoa.telefone);
        });

        return {
          ...dado,
        };
      })
    );

    return {
      ...retorno,
      listaDados,
    };
  };

  function FormatarCabecalho(
    filtros: IPessoaFiltroRelatorioFrontend,
    cabecalho: IPessoaPorDataCadastroCabecalhoRelatorio
  ): IPessoaPorDataCadastroCabecalhoRelatorio {
    cabecalho.possuiFiltro = false;
    cabecalho.nomeEmpresa = empresaLogada.nomeRazaoSocial ?? '';
    cabecalho.filtroTipoPessoa = '';

    if (filtros.dataHoraInicialCadastro) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataInicial = FormatarDataParaPtBr(
        filtros.dataHoraInicialCadastro
      );
    }

    if (filtros.dataHoraFinalCadastro) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroDataFinal = FormatarDataParaPtBr(
        filtros.dataHoraFinalCadastro
      );
    }

    if (filtros.cliente) {
      cabecalho.possuiFiltro = true;
      cabecalho.filtroTipoPessoa +=
        filtros.cliente === 'true' ? 'Cliente: Sim' : 'Cliente: Não';
    }

    if (filtros.fornecedor) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.fornecedor === 'true' ? 'Fornecedor: Sim' : 'Fornecedor: Não';
    }

    if (filtros.transportador) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.transportador === 'true'
          ? 'Transportador: Sim'
          : 'Transportador: Não';
    }

    if (filtros.representante) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.representante === 'true'
          ? 'Representante: Sim'
          : 'Representante: Não';
    }

    if (filtros.produtor) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.produtor === 'true' ? 'Produtor: Sim' : 'Produtos: Não';
    }

    if (filtros.motorista) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.motorista === 'true' ? 'Motorista: Sim' : 'Motorista: Não';
    }

    if (filtros.tecnico) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.tecnico === 'true' ? 'Técnico: Sim' : 'Técnico: Não';
    }

    if (filtros.ativo) {
      cabecalho.possuiFiltro = true;

      if (!cabecalho.filtroTipoPessoa?.IsNullOrEmpty())
        cabecalho.filtroTipoPessoa += ', ';

      cabecalho.filtroTipoPessoa +=
        filtros.ativo === 'true' ? 'Ativo: Sim' : 'Ativo: Não';
    }

    if (filtros.listaEstado && filtros.listaEstado.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroEstado = filtros.listaEstado.listaValor
        .map((valor: any) => {
          return valor.siglaNome;
        })
        .join(', ');
    }

    if (filtros.listaCidade && filtros.listaCidade.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroCidade = filtros.listaCidade.listaValor
        .map((valor: any) => {
          return valor.cidadeUf;
        })
        .join(', ');
    }

    if (
      filtros.listaRepresentante &&
      filtros.listaRepresentante.listaValor.length > 0
    ) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroRepresentante = filtros.listaRepresentante.listaValor
        .map((valor: any) => {
          return `${valor.codigo} - ${valor.nomeRazaoSocial}`;
        })
        .join(', ');
    }
    if (filtros.listaTags && filtros.listaTags.listaValor.length > 0) {
      cabecalho.possuiFiltro = true;

      cabecalho.filtroTag = filtros.listaTags.listaValor
        .map((valor: any) => {
          return valor.textoTag;
        })
        .join(', ');
    }
    return cabecalho;
  }

  async function handleExportCSV(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await CSV({
      campos: PessoaPorDataCadastroPlanilha,
      nomeArquivo: 'PessoaPorDataCadastro',
      unwind: ['listaPessoa'],
      valores: dados.listaDados,
    });

    relatorio.AbrirArquivo();
  }

  async function handleExportPlanilha(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    const relatorio = await Planilha({
      campos: PessoaPorDataCadastroPlanilha,
      nomeArquivo: 'PessoaPorDataCadastro',
      unwind: ['listaPessoa'],
      valores: dados.listaDados as any,
    });

    relatorio.AbrirArquivo();
  }

  async function handleVisualizarPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<void> {
    const dados = await ObterRelatorio(filtros);
    if (!dados) return;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorDataCadastroPDF dados={dados} />,
    });

    relatorio.AbrirArquivo();
  }

  async function handleObterBlobPdf(
    filtros: IPessoaFiltroRelatorioFrontend
  ): Promise<Blob | undefined> {
    const dados = await ObterRelatorio(filtros, false);
    if (!dados) return undefined;

    dados.cabecalho = FormatarCabecalho(filtros, dados.cabecalho);

    const relatorio = await PDF({
      documento: <PessoaPorDataCadastroPDF dados={dados} />,
    });

    return relatorio.GetBlob();
  }

  return {
    handleExportCSV,
    handleExportPlanilha,
    handleVisualizarPdf,
    handleObterBlobPdf,
  };
};

export default PessoaPorDataCadastroRelatorio();
