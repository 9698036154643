import api from '../../../../../Comum/Services/Api';

const rota = 'configuracao/integracao/empresa-simplo7';

interface IEmpresaSimplo7Store {
  params: any;
}

class EmpresaSimplo7Comunicador {
  public async show(): Promise<any> {
    const response = await api.get(rota);

    return response.data;
  }

  public async store(params: IEmpresaSimplo7Store): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async delete(): Promise<any> {
    const response = await api.delete(rota);

    return response.data;
  }
}

export default new EmpresaSimplo7Comunicador();
