const TabelaPrecoProdutosComValorVendaCustoMarkupPlanilha = [
  {
    titulo: 'Tabela Preço',
    valor: 'descricao',
  },
  {
    titulo: 'Data Início Vigência',
    valor: 'dataInicioVigencia',
  },
  {
    titulo: 'Data Final Vigência',
    valor: 'dataFinalVigencia',
  },
  {
    titulo: 'Código',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.produto.codigo',
  },
  {
    titulo: 'Descrição',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.produto.descricao',
  },
  {
    titulo: 'Unidade',
    valor:
      'listaProdutoRegraEscolhaTabelaPreco.produto.produtoEstoque.unidadeMedida.sigla',
  },
  {
    titulo: 'Valor Unitário Custo',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.valorUnitarioBase',
  },
  {
    titulo: 'Valor Unitário Venda',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.valorUnitarioVenda',
  },
  {
    titulo: 'Valor Unitário Venda - Valor Unitário Custo',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.subtracaoVendaBase',
  },
  {
    titulo: 'Porcentagem de Markup',
    valor: 'listaProdutoRegraEscolhaTabelaPreco.porcentagemMarkup',
  },
];

export default TabelaPrecoProdutosComValorVendaCustoMarkupPlanilha;
