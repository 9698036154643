"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = IcmsDesoneracaoCalculo;
const Enum_1 = require("../../Enum");
function IcmsDesoneracaoCalculo(dados) {
    const retorno = Object.assign(Object.assign({}, dados), { baseCalculoDesoneracaoIcms: null, valorDesoneracaoIcms: null });
    if (dados.aliquotaDesoneracaoIcms === null)
        retorno.baseCalculoDesoneracaoIcms = null;
    else if (dados.aliquotaDesoneracaoIcms === 0)
        retorno.baseCalculoDesoneracaoIcms = 0;
    else {
        let baseCalculoIcmsNormal = Number(dados.quantidadeItem) * Number(dados.valorUnitarioItem) +
            Number(dados.valorFreteItem) +
            Number(dados.valorSeguroItem) +
            Number(dados.valorOutrasDespesasItem) -
            Number(dados.valorDescontoItem);
        if (dados.destinadoItem === Enum_1.DestinadoEnum.usoeConsumo ||
            dados.destinadoItem === Enum_1.DestinadoEnum.ativoImobilizado)
            baseCalculoIcmsNormal += Number(dados.valorIpiItem);
        if (dados.percentualReducaoBaseCalculoIcms != null)
            baseCalculoIcmsNormal = baseCalculoIcmsNormal.CalcularPercentual(100 - dados.percentualReducaoBaseCalculoIcms);
        retorno.baseCalculoDesoneracaoIcms = baseCalculoIcmsNormal.Arredondar();
    }
    if (dados.aliquotaDesoneracaoIcms != null &&
        retorno.baseCalculoDesoneracaoIcms != null) {
        const valorPercentual = retorno.baseCalculoDesoneracaoIcms.CalcularPercentual(dados.aliquotaDesoneracaoIcms);
        retorno.valorDesoneracaoIcms = valorPercentual.Arredondar();
        if (dados.percentualDiferimentoIcms != null) {
            let valorDiferidoIcms = retorno.baseCalculoDesoneracaoIcms.CalcularPercentual(dados.percentualDiferimentoIcms);
            valorDiferidoIcms = valorDiferidoIcms
                .CalcularPercentual(dados.aliquotaDesoneracaoIcms)
                .Arredondar();
            if (valorDiferidoIcms != null && valorDiferidoIcms > 0) {
                retorno.valorDesoneracaoIcms -= valorDiferidoIcms;
            }
        }
    }
    return retorno;
}
