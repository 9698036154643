/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormatarDataParaPtBr,
  ILiquidacaoContasEmLoteLista,
  TipoContaEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lista from '../../../../../Componentes/Lista';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import AcoesLiquidacaoContasEmLote from '../AcoesLiquidacaoContasEmLote';
import { UseLiquidacaoContasEmLoteLista } from '../Hooks/LiquidacaoContasEmLoteListaHook';
import LiquidacaoContasEmLoteComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/LiquidacaoContasEmLote/Comunicador/LiquidacaoContasEmLoteComunicador';
import ToastInfo from '../../../../../Util/Toasts/ToastInfo';
import { Sleep } from '../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

const LiquidacaoContasEmLoteLista: React.FC = () => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroMovimentacoesReversaoContas: permissao } = permissoes;

  const {
    listaValor,
    setListaValor,
    listaItemSelecionadoRef,
    possuiItemSelecionado,
    setPossuiItemSelecionado,
  } = UseLiquidacaoContasEmLoteLista();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carregarDados, setCarregarDados] = useState(false);
  const [, setListaColunas] = useState<ITh[]>([]);
  const selecionarTodosRef = useRef<HTMLInputElement>(null);

  const pesquisaAvancadaLocalStorage = localStorage.getItem(
    '@EloGestorle:pesquisaAvancada'
  );

  const pesquisaAvancadaLocalStorageJSON = pesquisaAvancadaLocalStorage
    ? JSON.parse(pesquisaAvancadaLocalStorage)
    : null;

  useEffect(() => {
    if (pesquisaAvancadaLocalStorageJSON) {
      ToastInfo('Mostrando resultados conforme filtro.');
    }
  }, []);

  const handleAlterouSelecionado = useCallback(async () => {
    const itensSelecionados = listaItemSelecionadoRef.current?.filter(
      (item) => {
        return item?.checked;
      }
    );
    setPossuiItemSelecionado(itensSelecionados?.length !== 0);
  }, [listaItemSelecionadoRef]);

  const handleSelecionarTodos = useCallback(
    (checked: boolean): void => {
      listaItemSelecionadoRef.current?.forEach((item, index) => {
        if (item) {
          listaValor[index].selecionado = checked;
          item.checked = checked;
        }
      });
      handleAlterouSelecionado();
    },
    [handleAlterouSelecionado, listaItemSelecionadoRef, listaValor]
  );

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);
        setCarregarDados(true);
        let listaDados: ILiquidacaoContasEmLoteLista[] = [];

        const itensSelecionados = listaDados.filter(
          (item: ILiquidacaoContasEmLoteLista) => {
            return item.selecionado;
          }
        );

        setPossuiItemSelecionado(itensSelecionados.length > 0);

        const response = await LiquidacaoContasEmLoteComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        listaDados = response.dados[0].map(
          (data: ILiquidacaoContasEmLoteLista) => {
            return { ...data, selecionado: false };
          }
        );

        const totalPaginas = Number(response.dados[1]);

        setListaValor(listaDados);

        const dados = listaDados.map(
          (data: ILiquidacaoContasEmLoteLista, index: number) => {
            const listaCodigoNomeRazaoSocial: string[] = [];

            data.conta.listaContaParcelaReversao?.map((item: any) => {
              const valor =
                item.contaParcelaOrigem.conta?.pessoa?.codigo &&
                item.contaParcelaOrigem.conta?.pessoa?.nomeRazaoSocial
                  ? `${item.contaParcelaOrigem.conta?.pessoa?.codigo} - ${item.contaParcelaOrigem.conta?.pessoa?.nomeRazaoSocial}`
                  : '';

              if (valor !== '' && !listaCodigoNomeRazaoSocial.includes(valor)) {
                listaCodigoNomeRazaoSocial.push(valor);
              }
            });

            const listaNumeroPedidoIntegracao: string[] = [];
            data.conta.notaFiscalSaida?.listaNotaFiscalSaidaItem?.map(
              (notaFiscalSaidaItemElement: any) => {
                notaFiscalSaidaItemElement?.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.map(
                  (pedidoVendaItemElement: any) => {
                    const valor =
                      pedidoVendaItemElement?.pedidoVendaItem?.pedidoVenda
                        ?.numeroPedidoIntegracao;

                    if (!listaNumeroPedidoIntegracao.includes(valor)) {
                      valor !== null && listaNumeroPedidoIntegracao.push(valor);
                    }
                  }
                );
              }
            );

            return {
              id: data.id,

              selecionado: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={data.selecionado}
                    ref={(instance) => {
                      if (
                        listaItemSelecionadoRef &&
                        listaItemSelecionadoRef.current
                      ) {
                        listaItemSelecionadoRef.current[index] = instance;
                      }
                    }}
                    onChange={async (ev) => {
                      handleAlterouSelecionado();
                      listaDados[index].selecionado = ev.target.checked;
                    }}
                  />
                </div>
              ),

              'conta.tipo': (
                <div
                  className="lista-texto"
                  style={{
                    color:
                      data.conta.tipo === TipoContaEnum.receber
                        ? 'green'
                        : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {data.conta.tipo}
                </div>
              ),

              numeroDocumento: (
                <div className="lista-texto">{data.numeroDocumento}</div>
              ),

              sequencia: (
                <div className="lista-texto">{`${data.sequencia}/${data.conta.quantidadeParcelas}`}</div>
              ),

              'conta.dataHoraEmissao': (
                <div className="lista-data">
                  {FormatarDataParaPtBr(data.conta.dataHoraEmissao)}
                </div>
              ),

              dataVencimento: (
                <div className="lista-data">
                  {FormatarDataParaPtBr(data.dataVencimento)}
                </div>
              ),

              valorParcela: (
                <div className="lista-valor">
                  {data.valorParcela
                    ? Number(data.valorParcela).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),

              valorTotalParcela: (
                <div className="lista-valor">
                  {data.valorTotalParcela
                    ? Number(data.valorTotalParcela).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),

              valorTotalMovimentos: (
                <div className="lista-valor">
                  {data.valorTotalMovimentos
                    ? Number(data.valorTotalMovimentos).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),

              valorTotalAberto: (
                <div className="lista-valor">
                  {data.valorTotalAberto
                    ? Number(data.valorTotalAberto).FormatarParaPtBr({
                        minimoCasasDecimais: 2,
                        maximoCasasDecimais: 2,
                      })
                    : ''}
                </div>
              ),

              'conta.observacoes': (
                <div className="lista-texto">{data.conta.observacoes}</div>
              ),

              'conta.pessoa.codigoNomeRazaoSocial': (
                <div className="lista-texto">
                  {data.conta.pessoa &&
                    `${data.conta.pessoa.codigo} - ${data.conta.pessoa.nomeRazaoSocial}`}
                </div>
              ),

              'listaContaParcelaReversao.conta.pessoa.codigoNomeRazaoSocial': (
                <div className="lista-texto">
                  {listaCodigoNomeRazaoSocial.length > 0
                    ? listaCodigoNomeRazaoSocial.map((valor) => {
                        return <div key={valor}>{`${valor};`}</div>;
                      })
                    : ''}
                </div>
              ),

              'conta.notaFiscalSaida.listaNotaFiscalSaidaItem.listaNotaFiscalSaidaItemImportacaoPedidoVendaItem.pedidoVendaItem.pedidoVenda.numeroPedidoIntegracao':
                (
                  <div className="lista-texto">
                    {data.conta?.notaFiscalSaida?.listaNotaFiscalSaidaItem
                      .length > 0 &&
                      listaNumeroPedidoIntegracao.map((valor) => {
                        return <div key={valor}>{`${valor};`}</div>;
                      })}
                  </div>
                ),
            };
          }
        );

        setLoading(false);
        setCarregarDados(false);
        return { dados, totalPaginas };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        setCarregarDados(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAlterouSelecionado, setListaValor, listaValor.length]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      setLoading(true);

      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'financeiroLiquidacaoContasEmLote' },
      });

      const listaTh: ITh[] = [...response.campos];

      listaTh.splice(0, 0, {
        id: 'selecionado-key',
        arrastavel: false,
        nomeCampo: 'selecionado',
        ordenar: false,
        tamanho: null,
        titulo: '',
        visivel: true,
        permitirEsconder: true,
        style: { width: 50 },
        campoPersonalizado: (
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <input
              ref={selecionarTodosRef}
              type="checkbox"
              onChange={(event) => {
                handleSelecionarTodos(event.target.checked);
              }}
            />
          </div>
        ),
      });

      setListaColunas(listaTh);

      setLoading(false);
      return listaTh;
    } catch (error) {
      TratarErros(error);
      setLoading(false);
      return [];
    }
  }, [handleSelecionarTodos]);

  // useEffect(() => {
  //   if (refresh) handlePesquisarDados({});
  // }, [refresh]);

  return (
    <>
      <Container>
        <SubHeader
          titulo="Liquidação de Contas (Receber/Pagar) em Lote"
          permissao={permissao}
          botaoNovo={false}
          quantidadeColunaIncrementar={1}
          botoes={
            <>
              <AcoesLiquidacaoContasEmLote
                loading={loading}
                setLoading={setLoading}
                setRefresh={setRefresh}
                possuiItemSelecionado={possuiItemSelecionado}
              />
            </>
          }
        />
        <PesquisaAvancada />

        <Lista
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          detalhe={false}
          refresh={refresh}
          loading={carregarDados}
          paginacaoPadrao={1000}
          opcao1000
        />
      </Container>
    </>
  );
};

export default LiquidacaoContasEmLoteLista;
