import {
  FormatarEnum,
  IControleChequesValoresAlterar,
  TipoChequeEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDate from '../../../../../../Componentes/Inputs/InputDate';
import Select from '../../../../../../Componentes/Select';
import InputAutoCompleteCliente from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import InputAutoCompleteBanco from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteBanco';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';

type IProps = IMain<IControleChequesValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroMovimentacoesControleDeCheques: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const [labelTipoCheque, setLabelTipoCheque] = useState('Recebido de');
  const [labelData, setLabelData] = useState('Data Recebimento');

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [formRef, idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  useEffect(() => {
    formRef.current?.setDataInicial({
      ...dadosRecuperados,
      tipo: TipoChequeEnum.receber,
      dataEmissao: new Date(),
      dataRecebimentoOuPagamento: new Date(),
      dataPreDatado: new Date(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={10}>
              <Row className="mb-1">
                <Col lg={3} sm={12}>
                  <InputInteiroNulavel name="numero" label={'Número'} />
                </Col>

                <Col lg={3} sm={12}>
                  <InputDate name="dataEmissao" label="Data Emissão" />
                </Col>

                <Col lg={3} sm={12}>
                  <InputDate
                    name="dataRecebimentoOuPagamento"
                    label={labelData}
                  />
                </Col>

                <Col lg={3} sm={12}>
                  <InputDate name="dataPreDatado" label="Pré-datado" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={6} sm={12}>
                  <Select
                    name="tipo"
                    label="Tipo"
                    options={FormatarEnum({
                      enumObj: TipoChequeEnum,
                    })}
                    onChange={(event) => {
                      if (event) {
                        const { value } = event.target;

                        if (value === TipoChequeEnum.receber) {
                          setLabelTipoCheque('Recebido de');
                          setLabelData('Data de Recebimento');
                        } else {
                          setLabelTipoCheque('Emitido para');
                          setLabelData('Data de Pagamento');
                        }
                      }
                    }}
                  />
                </Col>

                <Col lg={6} sm={12}>
                  <InputDecimal
                    casasDecimais={2}
                    casasInteiras={18}
                    name="valor"
                    label="Valor"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={12} sm={12}>
                  <Input name="emissor" label="Emissor" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={12} sm={12}>
                  <InputAutoCompleteCliente
                    name="idPessoa"
                    label={labelTipoCheque}
                    nomeObjeto="pessoa"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={12} sm={12}>
                  <InputAutoCompleteBanco
                    label="Banco"
                    name="idBanco"
                    nomeObjeto="banco"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Textarea
                  name="observacoes"
                  label="Observações"
                  maxLength={500}
                />
              </Row>
            </Col>
            <Col lg={2}>
              <Row className="mb-1">
                <InputSwitch
                  ativo="Sim"
                  inativo="Não"
                  name="compensado"
                  label="Compensado"
                />
              </Row>
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
