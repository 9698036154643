import api from '../../../../Comum/Services/Api';

const rota = 'configuracao/usuario/acesso';

interface IUsuarioAcessoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IUsuarioAcessoComunicadorShow {
  id: string;
}

interface IUsuarioAcessoComunicadorStore {
  params: any;
}

interface IUsuarioAcessoComunicadorUpdate {
  id: string;
  params: any;
}

interface IUsuarioAcessoComunicadorDeletar {
  id: string;
}

class UsuarioAcessoComunicador {
  public async index(params: IUsuarioAcessoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IUsuarioAcessoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IUsuarioAcessoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IUsuarioAcessoComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(params: IUsuarioAcessoComunicadorDeletar): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new UsuarioAcessoComunicador();
