"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEstoqueOrdemProducaoEnum;
(function (TipoEstoqueOrdemProducaoEnum) {
    TipoEstoqueOrdemProducaoEnum["proprioEmPoderProprio"] = "Pr\u00F3prio em poder pr\u00F3prio";
    TipoEstoqueOrdemProducaoEnum["terceiroEmPoderProprio"] = "Terceiro em poder pr\u00F3prio";
    TipoEstoqueOrdemProducaoEnum["terceiroEmPoderTerceiro"] = "Terceiro em poder de terceiro";
})(TipoEstoqueOrdemProducaoEnum || (TipoEstoqueOrdemProducaoEnum = {}));
exports.default = TipoEstoqueOrdemProducaoEnum;
