import api from '../../../../../Comum/Services/Api';

const rota =
  'manufatura/producao/ordem-producao/gerar-ordem-producao-item-automatica';

interface IGerarOrdemProducaoItemAutomaticaComunicadorStore {
  params?: any;
  gerarFinalizado?: boolean;
  dataHoraInicio?: Date;
  dataHoraTermino?: Date;
}

class GerarOrdemProducaoItemAutomaticaComunicador {
  public async store(
    data: IGerarOrdemProducaoItemAutomaticaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, data);

    return response.data;
  }
}

export default new GerarOrdemProducaoItemAutomaticaComunicador();
