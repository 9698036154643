import React, { useState, useEffect } from 'react';
import { IOrdemProducaoItem } from '..';
import InputTabelaDecimal from '../../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';

interface IGerarOrdemProducaoItem {
  item: IOrdemProducaoItem;
  onChange: (item: IOrdemProducaoItem) => void;
  index: number;
  marcarTodos?: boolean;
}

const GerarOrdemProducaoItem: React.FC<IGerarOrdemProducaoItem> = ({
  item,
  onChange,
  index,
  marcarTodos,
}) => {
  const [selecionado, setSelecionado] = useState<boolean>(item.selecionado);
  const [quantidadeProduzir, setQuantidadeProduzir] = useState<number>(
    item.quantidade
  );

  useEffect(() => {
    onChange({
      ...item,
      selecionado: marcarTodos || selecionado,
      quantidade: quantidadeProduzir,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantidadeProduzir, selecionado]);

  useEffect(() => {
    if (marcarTodos === true) {
      onChange({
        ...item,
        selecionado: true,
        quantidade: quantidadeProduzir,
      });
    } else {
      onChange({
        ...item,
        selecionado,
        quantidade: quantidadeProduzir,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcarTodos]);

  return (
    <tr>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <input
          disabled={marcarTodos}
          type="checkbox"
          checked={marcarTodos || selecionado}
          onChange={(e) => {
            setSelecionado(e.target.checked);
          }}
        />
      </td>
      <td className="lista-texto">
        <div>{item.codigo}</div>
      </td>
      <td className="lista-texto">
        <div>{item.descricao}</div>
      </td>
      <td className="lista-valor" style={{ padding: 5 }}>
        <div>
          <InputTabelaDecimal
            valorPadrao={item.quantidade}
            casasInteiras={15}
            casasDecimais={4}
            onChange={(e) => {
              setQuantidadeProduzir(
                String(e.target.value).ConverterParaNumber()
              );
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default GerarOrdemProducaoItem;
