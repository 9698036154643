import ILogPesquisaParametro from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaParametro';
import ILogPesquisaRetorno from '../../../../../../Componentes/LogModal/Interfaces/ILogPesquisaRetorno';
import api from '../../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-forma-pagamento/log';

interface IRegraEscolhaFormaPagamentoLogComunicadorIndex {
  params: ILogPesquisaParametro;
}

interface IRegraEscolhaFormaPagamentoLogComunicadorShow {
  id: string;
  params: ILogPesquisaParametro;
}

class RegraEscolhaFormaPagamentoLogComunicador {
  public async index(
    params: IRegraEscolhaFormaPagamentoLogComunicadorIndex
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaFormaPagamentoLogComunicadorShow
  ): Promise<ILogPesquisaRetorno> {
    const response = await api.get(`${rota}/${params.id}`, {
      params: params.params,
    });

    return response.data;
  }
}

export default new RegraEscolhaFormaPagamentoLogComunicador();
