const PedidoVendaPorClienteDataFaturamentoPlanilha = [
  {
    titulo: 'Data de Faturamento',
    valor: 'chave',
  },

  {
    titulo: 'Código do Cliente',
    valor: 'listaPedidoVendaItem.clienteCodigo',
  },

  {
    titulo: 'Cliente',
    valor: 'listaPedidoVendaItem.clienteNomeRazaoSocial',
  },

  {
    titulo: 'Quantidade',
    valor: 'listaPedidoVendaItem.quantidade',
  },

  {
    titulo: 'Referencia',
    valor: 'listaPedidoVendaItem.referencia',
  },

  {
    titulo: 'Produto',
    valor: 'listaPedidoVendaItem.produto',
  },

  {
    titulo: 'Produto',
    valor: 'listaPedidoVendaItem.produto',
  },

  {
    titulo: 'Unidade',
    valor: 'listaPedidoVendaItem.unidadeMedida',
  },

  {
    titulo: 'Telefone',
    valor: 'listaPedidoVendaItem.telefone',
  },

  {
    titulo: 'Hora de Faturamento',
    valor: 'listaPedidoVendaItem.pedidoVendaHoraFaturamento',
  },

  {
    titulo: 'Numero do Pedido',
    valor: 'listaPedidoVendaItem.pedidoVendaNumero',
  },
];

export default PedidoVendaPorClienteDataFaturamentoPlanilha;
