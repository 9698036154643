import React, { useCallback, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import { PiCertificateBold } from 'react-icons/pi/index.mjs';
import { RiDeleteBin6Line } from 'react-icons/ri/index.mjs';
import { isAfter, parseISO } from 'date-fns';
import Divisor from '../../../../../../Componentes/Divisor';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputSenha from '../../../../../../Componentes/Inputs/InputSenha';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseAutoComplete } from '../../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import UseFuncoesPadrao from '../../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import ApiCertificadoHook from './CertificadoDetalhe/Hooks';
import ApiCertificadoDetalhe from './CertificadoDetalhe/Detalhe';
import PortadorBoletoCertificadoComunicador from '../../../../../../Comunicador/Financeiro/Geral/Portador/Certificado/Comunicador/PortadorBoletoCertificadoComunicador';

const ApiTab: React.FC = () => {
  const {
    formRef,
    loading,
    setLoading,
    handleCarregarDados,
    getIdDetalheRegistro,
  } = UseForm();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { abrirJanela } = UseConfirmacao();
  const { permissoes } = UsePermissoes();
  const { FinanceiroGeralPortador: permissao } = permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const banco = formRef.current?.getFieldValueNomeObjeto('banco');

  // #region Certificado

  const possuiCertificado = formRef.current?.getFieldValue(
    'portadorBoleto.emitidoParaCertificadoItau'
  );

  const certificadoVencido = formRef.current?.getFieldValue(
    'portadorBoleto.dataHoraValidadeFinalCertificadoItau'
  )
    ? !isAfter(
        parseISO(
          formRef.current?.getFieldValue(
            'portadorBoleto.dataHoraValidadeFinalCertificadoItau'
          )
        ),
        new Date()
      )
    : false;

  const [showFormModalCertificado, setShowFormModalCertificado] =
    useState(false);

  const handleNovoCertificado = useCallback(async () => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModalCertificado(true);
  }, [handleSalvarContinuar, isJanelaAutoComplete]);

  const handleSalvarFormModal = useCallback((): void => {
    setShowFormModalCertificado(false);
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleFecharFormModal = useCallback((): void => {
    setShowFormModalCertificado(false);
  }, []);

  const handleExcluirCertificado = useCallback(async () => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja excluir o certificado?</span>
        ),
      });

      if (resposta) {
        setLoading(true);

        const idDetalheRegistro = getIdDetalheRegistro() || '';
        const response = await PortadorBoletoCertificadoComunicador.delete({
          id: idDetalheRegistro,
        });

        setLoading(false);
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, getIdDetalheRegistro, handleCarregarDados, setLoading]);

  // #endregion Certificado

  return (
    <div>
      <Divisor hidden={!banco || banco.codigo !== '001'}>
        <h5>Banco do Brasil</h5>

        <Row>
          <Col xl={4} lg={4} md={6} sm={12}>
            <Input
              name="portadorBoleto.developerApplicationKeyBB"
              placeholder="Developer Application Key"
              label="Developer Application Key"
              maxLength={50}
            />
          </Col>
          <Col xl={8} lg={8} md={6} sm={12}>
            <InputSenha
              autoComplete="new-password"
              name="portadorBoleto.tokenBB"
              ocultarExibirSenhaButton
              label="Token"
              placeholder="Token"
              maxLength={1000}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!banco || banco.codigo !== '041'}>
        <h5>Banrisul</h5>

        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Input
              name="portadorBoleto.clientIdBanrisul"
              label="Client Id"
              placeholder="Client Id"
              maxLength={50}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Input
              name="portadorBoleto.clientSecretBanrisul"
              label="Client Secret"
              placeholder="Client Secret"
              maxLength={50}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!banco || banco.codigo !== '341'}>
        <h5>Itaú</h5>

        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Input
              name="portadorBoleto.clientIdItau"
              label="Client Id"
              placeholder="Client Id"
              maxLength={50}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12}>
            <Input
              name="portadorBoleto.clientSecretItau"
              label="Client Secret"
              placeholder="Client Secret"
              maxLength={50}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <div style={{ width: 260, display: 'flex' }}>
              <button
                type="button"
                style={{ width: 201, display: 'flex', alignItems: 'center' }}
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleNovoCertificado}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <PiCertificateBold />
                  <span style={{ marginLeft: 10 }}>Novo Certificado</span>
                </TextoLoading>
              </button>

              <Button
                type="button"
                style={{ marginLeft: 5, height: 35 }}
                onClick={handleExcluirCertificado}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <RiDeleteBin6Line />
                </TextoLoading>
              </Button>
            </div>

            <div
              style={{
                display: possuiCertificado ? 'block' : 'none',
                marginBottom: 10,
              }}
            >
              <div style={{ display: 'flex' }}>
                <span style={{ marginBottom: 5, marginTop: 5 }}>
                  Seu Certificado:
                </span>
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex' }}>
                <span style={{ marginRight: 5 }}>Emitido Para:</span>
                <InputLabel
                  name="portadorBoleto.emitidoParaCertificadoItau"
                  label=""
                />
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex' }}>
                <span style={{ marginRight: 5 }}>Emitido Por:</span>
                <InputLabel
                  name="portadorBoleto.emitidoPorCertificadoItau"
                  label=""
                />
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex' }}>
                <span style={{ marginRight: 5 }}>Valido de</span>
                <InputLabel
                  name="portadorBoleto.dataHoraValidadeInicialCertificadoItau"
                  label=""
                  formatarValor={(value) => {
                    return FormatarDataHoraCompletaParaPtBr(value);
                  }}
                />
                <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                <InputLabel
                  name="portadorBoleto.dataHoraValidadeFinalCertificadoItau"
                  label=""
                  formatarValor={(value) => {
                    return FormatarDataHoraCompletaParaPtBr(value);
                  }}
                />
              </div>

              <div
                style={{
                  display: !certificadoVencido ? 'block' : 'none',
                  fontWeight: 'bold',
                  color: 'green',
                }}
              >
                ESTE CERTIFICADO ESTÁ VÁLIDO
              </div>

              <div
                style={{
                  display: certificadoVencido ? 'block' : 'none',
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                ESTE CERTIFICADO ESTÁ VENCIDO
              </div>
            </div>
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!banco || banco.codigo !== '748'}>
        <h5>Sicredi</h5>

        <Row>
          <Col xl={4} lg={4} md={6} sm={12}>
            <InputInteiroNulavel
              name="portadorBoleto.digitoByteSicredi"
              label="Dígito Byte"
              placeholder="Dígito Byte"
            />
          </Col>
          <Col xl={8} lg={8} md={6} sm={12}>
            <InputSenha
              autoComplete="off"
              name="portadorBoleto.chaveMasterSicredi"
              label="Chave Master"
              placeholder="Chave Master"
              maxLength={100}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!banco || banco.codigo !== '756'}>
        <h5>Sicoob</h5>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Input
              name="portadorBoleto.clientIdSicoob"
              label="Client Id"
              placeholder="Client Id"
              maxLength={50}
            />
          </Col>
        </Row>
      </Divisor>

      {showFormModalCertificado && (
        <ApiCertificadoHook>
          <ApiCertificadoDetalhe
            onSalvarFormModal={handleSalvarFormModal}
            onFecharFormModal={handleFecharFormModal}
          />
        </ApiCertificadoHook>
      )}
    </div>
  );
};

export default ApiTab;
