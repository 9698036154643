"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoEstoqueEstruturaProdutoEnum;
(function (TipoEstoqueEstruturaProdutoEnum) {
    TipoEstoqueEstruturaProdutoEnum["proprioEmPoderProprio"] = "Pr\u00F3prio em poder pr\u00F3prio";
    TipoEstoqueEstruturaProdutoEnum["terceiroEmPoderProprio"] = "Terceiro em poder pr\u00F3prio";
    TipoEstoqueEstruturaProdutoEnum["terceiroEmPoderTerceiro"] = "Terceiro em poder de terceiro";
})(TipoEstoqueEstruturaProdutoEnum || (TipoEstoqueEstruturaProdutoEnum = {}));
exports.default = TipoEstoqueEstruturaProdutoEnum;
