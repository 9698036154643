import {
  FormatarEnum,
  SituacaoAdiantamentoEnum,
  SituacaoConsignadoRetiradaConsignadoEnum,
  SituacaoNotaFiscalSaidaEnum,
  TipoPesquisaFrenteCaixaPdvEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../../../Componentes/Lista/ListaHooks';
import Divisor from '../../../../../../../Componentes/Divisor';
import Select from '../../../../../../../Componentes/Select/index';
import InputAutoCompleteTagProduto from '../../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputAutoCompleteTagCidade from '../../../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagCliente from '../../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputPeriodoData from '../../../../../../../Componentes/Inputs/InputPeriodoData';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputInteiroNulavel from '../../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { Modal } from './styles';
import { UseListaDraggable } from './Hook/ListaDraggableHook';
import InputTag from '../../../../../../../Componentes/Inputs/TagsInput/InputTag';

interface PesquisaAvancadaProps {
  tipoPesquisa: string;
}
const PesquisaAvancada: React.FC<PesquisaAvancadaProps> = ({
  tipoPesquisa,
}) => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setPaginaAtual } = UseListaDraggable();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const periodoDataEmissaoRef = useRef<any>(null);
  const periodoDataSaidaRef = useRef<any>(null);

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();

    setPaginaAtual(1);
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setPaginaAtual, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      if (tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.valePresente) {
        const situacaoValePresente =
          parametros.pesquisaAvancada?.situacaoValePresente;
        if (situacaoValePresente) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Situação',
            valor: situacaoValePresente,
          });
        } else {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Situação',
            valor: 'Todos',
          });
        }
      } else {
        const situacao = parametros.pesquisaAvancada?.situacao;
        if (situacao) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Situação',
            valor: situacao,
          });
        }
        const situacaoFaturado = parametros.pesquisaAvancada?.situacaoFaturado;
        if (
          situacaoFaturado === SituacaoConsignadoRetiradaConsignadoEnum.faturado
        ) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Situação',
            valor: 'Faturado',
          });
        } else if (
          situacaoFaturado ===
          SituacaoConsignadoRetiradaConsignadoEnum.naoFaturado
        ) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Situação',
            valor: 'Não Faturado',
          });
        } else {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Situação',
            valor: 'Todos',
          });
        }
      }

      const listaCliente = parametros.pesquisaAvancada?.listaCliente;
      if (listaCliente && listaCliente.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Clientes',
          valor: listaCliente.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.nomeRazaoSocial}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto && listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo} - ${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCidade = parametros.pesquisaAvancada?.listaCidade;
      if (listaCidade && listaCidade?.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cidades',
          valor: listaCidade.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.cidadeUf}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaNumeroConsignado =
        parametros.pesquisaAvancada?.listaNumeroConsignado;

      if (listaNumeroConsignado && listaNumeroConsignado?.listaValor) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Números',
          valor: listaNumeroConsignado.listaValor
            .map((item: any) => item.numero)
            .join(', '),
        });
      }

      const pedidoCompraNumero =
        parametros.pesquisaAvancada?.pedidoCompraNumero;
      if (pedidoCompraNumero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Núm. do Pedido de Compra',
          valor: pedidoCompraNumero,
        });
      }
      const pedidoCompraNumeroItem =
        parametros.pesquisaAvancada?.pedidoCompraNumeroItem;
      if (pedidoCompraNumeroItem) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Item do Pedido de Compra',
          valor: pedidoCompraNumeroItem,
        });
      }

      if (periodoDataEmissaoRef.current) {
        const periodoDataEmissao = periodoDataEmissaoRef.current.getPeriodo();
        if (periodoDataEmissao) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Emissão',
            valor: periodoDataEmissao,
          });
        }
      }

      if (periodoDataSaidaRef.current) {
        const periodoDataSaida = periodoDataSaidaRef.current.getPeriodo();
        if (periodoDataSaida) {
          listaValoresParaExibirNoOverlay.push({
            titulo: 'Período Data Saída',
            valor: periodoDataSaida,
          });
        }
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros, tipoPesquisa]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        situacaoFaturado: SituacaoConsignadoRetiradaConsignadoEnum.naoFaturado,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              {(tipoPesquisa ===
                TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida ||
                tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.preVenda) && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacao"
                    label="Situação"
                    options={FormatarEnum({
                      enumObj: SituacaoNotaFiscalSaidaEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}

              {tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.consignado && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoFaturado"
                    label="Situação"
                    options={FormatarEnum({
                      enumObj: SituacaoConsignadoRetiradaConsignadoEnum,
                    })}
                  />
                </Col>
              )}

              {tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.valePresente && (
                <Col lg={6} md={12}>
                  <Select
                    name="situacaoValePresente"
                    label="Situação"
                    options={FormatarEnum({
                      enumObj: SituacaoAdiantamentoEnum,
                      todos: true,
                    })}
                  />
                </Col>
              )}

              <Col lg={6} md={12}>
                <InputAutoCompleteTagCliente
                  label="Clientes"
                  name="listaCliente"
                />
              </Col>

              {tipoPesquisa !== TipoPesquisaFrenteCaixaPdvEnum.valePresente && (
                <Col lg={6} md={12}>
                  <InputAutoCompleteTagProduto
                    label="Produtos"
                    name="listaProduto"
                  />
                </Col>
              )}

              {(tipoPesquisa ===
                TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida ||
                tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.preVenda ||
                tipoPesquisa ===
                  TipoPesquisaFrenteCaixaPdvEnum.valePresente) && (
                <Col lg={6} md={12}>
                  <InputAutoCompleteTagCidade
                    label="Cidades"
                    name="listaCidade"
                  />
                </Col>
              )}

              {(tipoPesquisa ===
                TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida ||
                tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.preVenda) && (
                <Col lg={6} md={12}>
                  <Input
                    name="pedidoCompraNumero"
                    label="Núm. do Pedido de Compra"
                    maxLength={15}
                  />
                </Col>
              )}

              {(tipoPesquisa ===
                TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida ||
                tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.preVenda) && (
                <Col lg={6} md={12}>
                  <InputInteiroNulavel
                    name="pedidoCompraNumeroItem"
                    label="Item do Pedido de Compra"
                  />
                </Col>
              )}

              {tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.consignado && (
                <Col lg={6} md={12}>
                  <InputTag
                    name="listaNumeroConsignado"
                    label="Números"
                    nomeCampo="numero"
                  />
                </Col>
              )}

              <Col lg={6} md={12}>
                <InputPeriodoData
                  labelDataInicial="Data Emissão Inicial"
                  labelDataFinal="Data Emissão Final"
                  nomeDataInicial="dataEmissaoInicial"
                  nomeDataFinal="dataEmissaoFinal"
                  ref={periodoDataEmissaoRef}
                />
              </Col>

              {(tipoPesquisa ===
                TipoPesquisaFrenteCaixaPdvEnum.notaFiscalSaida ||
                tipoPesquisa === TipoPesquisaFrenteCaixaPdvEnum.preVenda) && (
                <Col lg={6} md={12}>
                  <InputPeriodoData
                    labelDataInicial="Dt. Saída Inicial"
                    labelDataFinal="Dt. Saída Final"
                    nomeDataInicial="dataSaidaInicial"
                    nomeDataFinal="dataSaidaFinal"
                    ref={periodoDataSaidaRef}
                  />
                </Col>
              )}
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
