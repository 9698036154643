/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Modal,
  Container,
  Col,
  Row,
  Button,
  Table,
  Dropdown,
} from 'react-bootstrap';
import { IFrenteCaixaPdvRepresentanteLista } from '@elogestor/util';
import {
  IoMdAdd,
  IoMdRemoveCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { v4 } from 'uuid';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../../../../Componentes/Divisor';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import GetValidationErrors from '../../../../../../../../Util/Erro/GetValidationErrors';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteRepresentante from '../../../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRepresentante';
import BtnPadraoButton from '../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import { Tabela } from '../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import { UseFrenteCaixaPdv } from '../../../Hooks/FrenteCaixaPdvHook';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import PessoaObterPessoaRepresentantePorUsuarioComunicador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPessoaRepresentantePorUsuarioComunicador';
import PessoaObterPessoaRepresentantePorClienteComunicador from '../../../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPessoaRepresentantePorClienteComunicador';
import ToastInfo from '../../../../../../../../Util/Toasts/ToastInfo';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';

interface IRepresentantesDetalheModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  loading: boolean;
  setLoading(loading: boolean): void;
}

const RepresentantesDetalhe: React.FC<IRepresentantesDetalheModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
  loading,
  setLoading,
}) => {
  const { formRef: formRefPrincipal } = UseForm();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const { idNotaFiscalSaida } = UseFrenteCaixaPdv();
  const formRef = useRef<IFormCiaHandles>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const [listaValor, setListaValor] = useState<
    IFrenteCaixaPdvRepresentanteLista[]
  >([]);

  const listaId = listaValor.map((valor) => {
    return valor.id;
  });

  const bloquearCampos = useMemo(() => {
    if (idNotaFiscalSaida) return true;

    return false;
  }, [idNotaFiscalSaida]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const obrigarRepresentante = formRefPrincipal.current?.getFieldValue(
      'obrigarRepresentante'
    );

    if (
      obrigarRepresentante === true &&
      (!listaValor || (listaValor && listaValor.length === 0))
    ) {
      ToastErro('Representante é obrigatório!');
      return;
    }

    formRefPrincipal.current?.setFieldValue('listaRepresentante', listaValor);

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefPrincipal, listaValor, onSalvarFormModal]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, onFecharFormModal]);

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const numero = formRefPrincipal.current?.getFieldValue('numero');
      const idCliente = formRefPrincipal.current.getFieldValue('idCliente');

      const listaRepresentanteFormRef =
        formRefPrincipal.current.getFieldValue('listaRepresentante');

      if (
        !numero &&
        (!listaRepresentanteFormRef || listaRepresentanteFormRef.length === 0)
      ) {
        let responseRepresentantesPessoaVinculada: any;
        if (idCliente) {
          responseRepresentantesPessoaVinculada =
            await PessoaObterPessoaRepresentantePorClienteComunicador.show({
              idPessoaCliente: idCliente,
            });
        }

        const responseRepresentantesCadastroUsuarios =
          await PessoaObterPessoaRepresentantePorUsuarioComunicador.show({
            params: {
              comportamentoUsuarioRepresentante: false,
            },
          });

        const representantesConcatenados = [];

        if (
          responseRepresentantesPessoaVinculada &&
          responseRepresentantesPessoaVinculada.length > 0
        ) {
          representantesConcatenados.push(
            ...responseRepresentantesPessoaVinculada
          );
        }

        if (
          responseRepresentantesCadastroUsuarios &&
          responseRepresentantesCadastroUsuarios.length > 0
        ) {
          representantesConcatenados.push(
            ...responseRepresentantesCadastroUsuarios
          );
        }

        const listaRepresentantes = representantesConcatenados.filter(
          (representante, index, self) =>
            index === self.findIndex((r) => r.id === representante.id)
        );

        if (listaRepresentantes && listaRepresentantes.length > 0) {
          const listaRepresentantePorCliente: IFrenteCaixaPdvRepresentanteLista[] =
            [];

          for (let i = 0; i < listaRepresentantes.length; i++) {
            const representante = listaRepresentantes[i];

            listaRepresentantePorCliente.push({
              id: representante.id,
              codigo: representante.codigo,
              nomeRazaoSocial: representante.nomeRazaoSocial,
            });
          }

          if (
            listaRepresentantePorCliente &&
            listaRepresentantePorCliente.length > 0
          ) {
            setListaValor([...listaRepresentantePorCliente]);

            ToastInfo(
              'Representantes carregados automaticamente a partir do cliente e do usuário.'
            );
          }

          refresh();
        }
      } else {
        const listaRepresentante =
          formRefPrincipal.current?.getFieldValue('listaRepresentante');

        if (listaRepresentante) {
          setListaValor([...listaRepresentante]);
        }
      }
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRefPrincipal, refresh, setLoading]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region Representante

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;

      const schema = Yup.object().shape({
        idRepresentante: Yup.string()
          .nullable()
          .required('Representante é obrigatório!'),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleAdicionar = useCallback(async () => {
    try {
      setLoading(true);

      const representante =
        formRef.current?.getFieldValueNomeObjeto('representante');

      if (!(await handleValidar())) {
        setLoading(false);
        return;
      }

      const novoRepresentante: IFrenteCaixaPdvRepresentanteLista = {
        id: representante.id,
        codigo: representante.codigo,
        nomeRazaoSocial: representante.nomeRazaoSocial,
      };

      const novaListaRepresentante = [...listaValor, novoRepresentante];
      setListaValor(novaListaRepresentante);

      formRef.current?.setFieldValue('representante', null);

      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleValidar, listaValor, refresh, setLoading]);

  const handleRemover = useCallback(
    async (index: number) => {
      try {
        setLoading(true);

        listaValor.splice(index, 1);
        setListaValor([...listaValor]);

        refresh();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [listaValor, refresh, setLoading]
  );

  // #endregion Representante

  return (
    <JanelaDetalhe
      titulo="Representantes"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Header>
          <Divisor style={{ width: '100%' }}>
            <Row>
              <Col lg={8} md={8} sm={12}>
                <InputAutoCompleteRepresentante
                  label="Representante"
                  name="idRepresentante"
                  nomeObjeto="representante"
                  permitirAdicionar
                  listaIdParaRemover={listaId}
                  disabled={bloquearCampos}
                />
              </Col>

              <Col
                lg={4}
                md={4}
                sm={12}
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                <BtnPadraoButton
                  type="button"
                  className="btn-padrao btn-verde-claro btn-adicionar"
                  onClick={handleAdicionar}
                  disabled={loading || bloquearCampos}
                >
                  <TextoLoading loading={loading}>
                    <IoMdAdd />
                    <span style={{ marginLeft: 10 }}>Adicionar</span>
                  </TextoLoading>
                </BtnPadraoButton>
              </Col>
            </Row>
          </Divisor>
        </Modal.Header>

        <Modal.Body style={{ height: 400, display: 'flex' }}>
          <Divisor style={{ overflow: 'auto', width: '100%' }}>
            <Tabela>
              <Table striped hover bordered variant="light">
                <thead>
                  <tr>
                    <th className="lista-texto" style={{ width: '100%' }}>
                      <span>Representante</span>
                    </th>
                    <th className="lista-acoes">
                      <span>Ações</span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {listaValor &&
                    listaValor.map((pessoaRepresentante, index) => {
                      return (
                        <tr key={v4()}>
                          <td className="lista-texto">
                            <div>{`${pessoaRepresentante.codigo} - ${pessoaRepresentante.nomeRazaoSocial}`}</div>
                          </td>
                          <td className="tdButton lista-acoes">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <BtnAcoesButton>
                                <Dropdown.Item
                                  onClick={() => {
                                    handleRemover(index);
                                  }}
                                  disabled={loading || bloquearCampos}
                                >
                                  <span
                                    style={{ display: 'flex', fontSize: 16 }}
                                  >
                                    <IoMdRemoveCircleOutline
                                      style={{ marginRight: 5 }}
                                    />
                                    Remover
                                  </span>
                                </Dropdown.Item>
                              </BtnAcoesButton>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Tabela>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <div className="alinhar-direita espacamento-interno-para-esquerda-15">
            <Container style={{ display: 'flex' }}>
              <button
                type="button"
                className="btn-padrao btn-cinza-claro"
                onClick={handleClickVoltar}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <IoMdReturnLeft />
                  <span style={{ marginLeft: 10 }}>Voltar</span>
                </TextoLoading>
              </button>

              <Button
                style={{
                  fontWeight: 'bold',
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                }}
                type="button"
                onClick={handleClickSalvar}
                disabled={bloquearCampos}
              >
                <TextoLoading loading={loading}>
                  <AiOutlineCheck />
                  <span style={{ marginLeft: 10 }}>OK</span>
                </TextoLoading>
              </Button>
            </Container>
          </div>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default RepresentantesDetalhe;
