/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  AmbienteEnum,
  FormatarDataHoraCompletaParaPtBr,
  FormatarEnum,
  SituacaoInutilizacaoEnum,
  IInutilizacaoNumeracaoValoresAlterar,
} from '@elogestor/util';
import { FormCia } from '@elogestor/unformcia';
import { HiUpload } from 'react-icons/hi/index.mjs';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { BtnContainer } from '../../../../../Suprimentos/Produtos/UnidadeMedida/Detalhe/Main/styles';
import Divisor from '../../../../../../Componentes/Divisor';
import Select from '../../../../../../Componentes/Select';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import InputInteiroNulavel from '../../../../../../Componentes/Inputs/InputInteiroNulavel';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import InputVisualSemValor from '../../../../../../Componentes/Inputs/InputVisualSemValor';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import InputAutoCompleteSerie, {
  IOnChangeItemAtualAutoCompleteSerieEvent,
} from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSerie';
import InutilizacaoNumeracaoTransmitirComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/InutilizacaoNumeracao/Comunicador/InutilizacaoNumeracaoTransmitirComunicador';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import TratarValidadeCertificado from '../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';

type IProps = IMain<IInutilizacaoNumeracaoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { abrirJanela } = UseConfirmacao();
  const { FiscalDocumentosEletronicosInutilizacaoNumeracao: permissao } =
    permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    setLoading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleClickTransmitir = useCallback(async () => {
    const idRegistroAtual = getIdDetalheRegistro();
    if (!idRegistroAtual) return;

    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>Deseja Transmitir a inutilização?</span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (resposta) {
      try {
        setLoading(true);

        await TratarValidadeCertificado();

        await InutilizacaoNumeracaoTransmitirComunicador.update({
          id: idRegistroAtual,
        });

        ToastSucesso('Registro Transmitido!');
        handleCarregarDados();
        setLoading(false);
      } catch (error) {
        TratarErros(error);
        handleCarregarDados();
        setLoading(false);
      }
    }
  }, [abrirJanela, getIdDetalheRegistro, handleCarregarDados, setLoading]);

  const handleChangeSerie = useCallback(
    (event: IOnChangeItemAtualAutoCompleteSerieEvent) => {
      formRef.current?.setFieldValue(
        'serie.modeloDocumento.codigoDescricao',
        event.itemAtual?.modeloDocumento.codigoDescricao || ''
      );
    },
    [formRef]
  );

  const situacaoInutilizacao = formRef.current?.getFieldValue(
    'situacaoInutilizacao'
  );
  const situacaoBloquearCampos =
    situacaoInutilizacao === SituacaoInutilizacaoEnum.transmitida ||
    situacaoInutilizacao === SituacaoInutilizacaoEnum.inutilizadaAnteriormente;

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <InputAutoCompleteSerie
                ref={inputRefFocus}
                label="Série"
                name="idSerie"
                placeholder="Série"
                nomeObjeto="serie"
                onChangeItemAtual={handleChangeSerie}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={3} md={6} sm={12}>
              <InputVisualSemValor
                label="Modelo"
                name="serie.modeloDocumento.codigoDescricao"
                placeholder="Modelo"
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <Select
                name="ambiente"
                label="Ambiente"
                options={FormatarEnum({
                  enumObj: AmbienteEnum,
                })}
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputLabel
                valorStyle={{
                  color:
                    situacaoInutilizacao ===
                    SituacaoInutilizacaoEnum.naoTransmitida
                      ? 'darkgoldenrod'
                      : situacaoInutilizacao ===
                            SituacaoInutilizacaoEnum.transmitida ||
                          situacaoInutilizacao ===
                            SituacaoInutilizacaoEnum.inutilizadaAnteriormente
                        ? 'green'
                        : 'red',
                }}
                label="Situação"
                name="situacaoInutilizacao"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputLabel
                label="Data de Transmissao"
                name="dataHoraTransmissao"
                formatarValor={(valor) => {
                  return FormatarDataHoraCompletaParaPtBr(valor);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                onBlur={(event) => {
                  const numeroInicial = event.target.value;
                  const numeroFinal =
                    formRef.current?.getFieldValue('numeroFinal');
                  if (!numeroFinal) {
                    formRef.current?.setFieldValue(
                      'numeroFinal',
                      numeroInicial
                    );
                  }
                }}
                label="Número Inicial"
                name="numeroInicial"
                disabled={situacaoBloquearCampos}
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputInteiroNulavel
                label="Número Final"
                name="numeroFinal"
                disabled={situacaoBloquearCampos}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <Textarea
                style={{ height: 150 }}
                label="Justificativa"
                name="justificativa"
                maxLength={255}
                disabled={situacaoBloquearCampos}
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickTransmitir}
          className="btn-padrao btn-azul"
          disabled={
            (idDetalheRegistro && !permissao?.altera) ||
            loading ||
            situacaoBloquearCampos ||
            !idDetalheRegistro
          }
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextoLoading loading={loading}>
              <HiUpload />
              <span style={{ marginLeft: 10 }}>Transmitir</span>
            </TextoLoading>
          </div>
        </button>

        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            situacaoBloquearCampos ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
