import api from '../../../../Comum/Services/Api';

const rota = 'geral/erro';

interface IErroComunicadorStore {
  params: any;
}

class ErroComunicador {
  public async store(params: IErroComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new ErroComunicador();
