import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../Componentes/Divisor';
import Input from '../../../../../../../Componentes/Inputs/Input';

const Simplo7Tab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={3} md={12} sm={12}>
            <Input
              label="Sku (Id Simplo7)"
              name="produtoEcommerce.skuIdSimplo7"
              maxLength={20}
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Input
              label="Product_id"
              name="produtoEcommerce.productIdSimplo7"
            />
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Input
              label="Product_Estoque_id"
              name="produtoEcommerce.productEstoqueIdSimplo7"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default Simplo7Tab;
