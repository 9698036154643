import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';

const ParametroTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={2} md={4} sm={12}>
            <InputSwitch
              name="empresaParametro.recalcularCusto"
              label="Recalcular Custos"
              ativo="Sim"
              inativo="Não"
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ParametroTab;
