import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { SituacaoOrdemProducaoEnum } from '@elogestor/util';
import { BiDetail } from 'react-icons/bi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import OrdemProducaoImpressaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Impressao/OrdemProducaoImpressaoComunicador';
import ImpressaoOrdemProducao from './Impressao/Template/OrdemProducaoImpressao';
import OrdemProducaoReportarConsumoProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Comunicador/OrdemProducaoReportarConsumoProducaoComunicador';
import { UseOrdemProducao } from '../Hooks/OrdemProducaoHook';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import OrdemProducaoComunicador from '../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Comunicador/OrdemProducaoComunicador';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import GerarOrdemProducaoItens from './GerarOrdemProducaoItens';

const AcoesOrdemProducao: React.FC = () => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleSubmit,
    refresh,
  } = UseForm();
  const { handleAlterarSituacao } = UseOrdemProducao();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [gerarOrdemProducaoItem, setGerarOrdemProducaoItem] = useState(false);

  const handleSalvar = useCallback(async (): Promise<boolean> => {
    try {
      const data: any = formRef.current?.getData();

      const { erro } = await handleSubmit(data);
      return !erro;

      return true;
    } catch (error) {
      TratarErros(error);
      return false;
    }
  }, [formRef, handleSubmit]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const id = getIdDetalheRegistro();
      if (!id) return;

      const response = await OrdemProducaoImpressaoComunicador.show({
        id,
      });
      await ImpressaoOrdemProducao.handleVisualizarPdf(response);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  const handleReportarConsumoProducao = useCallback(async () => {
    try {
      const dataHoraTermino = formRef.current?.getFieldValue('dataHoraTermino');

      if (dataHoraTermino === null || dataHoraTermino === undefined) {
        formRef.current?.setFieldValue(
          'dataHoraTermino',
          new Date().SetarSegundosMilisegundosZero()
        );
      }

      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const response =
        await OrdemProducaoReportarConsumoProducaoComunicador.show({
          id: String(idRegistroDetalhe),
        });

      if (response.ordemProducaoProduzidaConsumida) {
        ToastInfo('Todas os Consumos e Produções já Foram Reportados!');
        setLoading(false);
        return;
      }

      const listaOrdemProducaoLocalEstoque =
        formRef.current?.getFieldValue('listaOrdemProducaoLocalEstoque') ?? [];

      const listaOrdemProducaoItem = formRef.current?.getFieldValue(
        'listaOrdemProducaoItem'
      );

      if (
        response?.listaOrdemProducaoLocalEstoque &&
        response?.listaOrdemProducaoLocalEstoque[0].localEstoque === null
      ) {
        ToastErro('Local de Estoque do item pai não informado');
        setLoading(false);
        return;
      }

      if (
        response.listaOrdemProducaoLocalEstoque &&
        response.listaOrdemProducaoLocalEstoque.length > 0
      ) {
        listaOrdemProducaoLocalEstoque.push(
          response.listaOrdemProducaoLocalEstoque[0]
        );

        formRef.current?.setFieldValue(
          'listaOrdemProducaoLocalEstoque',
          listaOrdemProducaoLocalEstoque
        );
      }

      if (response.listaOrdemProducaoItem && response.listaOrdemProducaoItem) {
        for (let i = 0; i < response.listaOrdemProducaoItem.length; i++) {
          const item = response.listaOrdemProducaoItem[i];

          if (
            item.listaOrdemProducaoItemLocalEstoque &&
            item.listaOrdemProducaoItemLocalEstoque.length > 0
          ) {
            const itemCadastrado = listaOrdemProducaoItem.find(
              (it: any) => it.id === item.id
            );
            itemCadastrado.consumido = true;
            itemCadastrado.listaOrdemProducaoItemLocalEstoque.push(
              item.listaOrdemProducaoItemLocalEstoque[0]
            );
          }
        }
      }

      // const dataHoraTermino = formRef.current?.getFieldValue('dataHoraTermino');

      // if (dataHoraTermino === null || dataHoraTermino === undefined) {
      //   formRef.current?.setFieldValue(
      //     'dataHoraTermino',
      //     new Date().SetarSegundosMilisegundosZero()
      //   );
      // }

      const alterarSituacao = handleAlterarSituacao({
        listaOrdemProducaoItem,
        listaOrdemProducaoLocalEstoque,
        dataHoraInicio: formRef.current?.getFieldValue('dataHoraInicio'),
        dataHoraTermino: formRef.current?.getFieldValue('dataHoraTermino'),
        quantidade: formRef.current?.getFieldValue('quantidade'),
      });

      if (alterarSituacao) {
        formRef.current?.setFieldValue(
          'situacao',
          SituacaoOrdemProducaoEnum.concluida
        );

        OrdemProducaoComunicador.update({
          id: String(idRegistroDetalhe),
          params: {
            dataHoraTermino: formRef.current?.getFieldValue('dataHoraTermino'),
          },
        });
      } else {
        formRef.current?.setFieldValue(
          'situacao',
          SituacaoOrdemProducaoEnum.emAndamento
        );
      }

      refresh();
      setLoading(false);
    } catch (err) {
      TratarErros(err);
      setLoading(false);
    }
  }, [
    formRef,
    handleAlterarSituacao,
    handleSalvar,
    idRegistroDetalhe,
    refresh,
    setLoading,
  ]);

  const handleGerarOrdemProducaoItens = useCallback(async () => {
    setGerarOrdemProducaoItem(true);
  }, []);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={handleReportarConsumoProducao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Reportar Consumo e Produção
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={handleGerarOrdemProducaoItens}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar O.P. Itens
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {gerarOrdemProducaoItem && (
        <GerarOrdemProducaoItens
          show={gerarOrdemProducaoItem}
          onClose={() => setGerarOrdemProducaoItem(false)}
        />
      )}
    </>
  );
};

export default AcoesOrdemProducao;
