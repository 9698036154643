import {
  BooleanEnum,
  DestinadoEnum,
  FormatarEnum,
  RegimeTributarioEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteTagNcm from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import Select from '../../../../../Componentes/Select';
import InputAutoCompleteTagCest from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCest';
import InputAutoCompleteTagCidade from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagCidade';
import InputAutoCompleteTagEstado from '../../../../../Componentes/Inputs/AutoCompleteTag/Geral/InputAutoCompleteTagEstado';
import InputAutoCompleteTagProduto from '../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagModeloDocumento from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagModeloDocumento';
import InputAutoCompleteTagTipoNota from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagTipoNota';
import InputAutoCompleteTagTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagTipoNotaMotivo';
import InputAutoCompleteTagCodigoSituacaoTributariaIcms from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCodigoSituacaoTributariaIcms';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const [listaIdEstado, setListaIdEstado] = useState<string[]>([]);
  const [listaIdTipoNota, setListaIdTipoNota] = useState<string[]>([]);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    data.pesquisaAvancada = true;
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaTipoNota = parametros.pesquisaAvancada?.listaTipoNota;
      if (listaTipoNota.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Nota',
          valor: listaTipoNota.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaTipoNotaMotivo =
        parametros.pesquisaAvancada?.listaTipoNotaMotivo;
      if (listaTipoNotaMotivo.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Motivo',
          valor: listaTipoNotaMotivo.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaNcm = parametros.pesquisaAvancada?.listaNcm;
      if (listaNcm.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'NCM',
          valor: listaNcm.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCstIcms = parametros.pesquisaAvancada?.listaCstIcms;
      if (listaCstIcms.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'CST do ICMS',
          valor: listaCstIcms.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCest = parametros.pesquisaAvancada?.listaCest;
      if (listaCest.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'CEST',
          valor: listaCest.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaEstado = parametros.pesquisaAvancada?.listaEstado;
      if (listaEstado.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Estados',
          valor: listaEstado.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.sigla}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaCidade = parametros.pesquisaAvancada?.listaCidade;
      if (listaCidade.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Cidades',
          valor: listaCidade.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.cidadeUf}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaProduto = parametros.pesquisaAvancada?.listaProduto;
      if (listaProduto.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Produtos',
          valor: listaProduto.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaModeloDocumento =
        parametros.pesquisaAvancada?.listaModeloDocumento;
      if (listaModeloDocumento.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Modelos de Documento',
          valor: listaModeloDocumento.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const destinado = parametros.pesquisaAvancada?.destinado;
      if (!destinado) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Destinado',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Destinado',
          valor: destinado,
        });
      }

      const regimeTributario = parametros.pesquisaAvancada?.regimeTributario;
      if (!regimeTributario) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Regime Tributário',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Regime Tributário',
          valor: regimeTributario,
        });
      }

      const contribuinte = parametros.pesquisaAvancada?.contribuinte;
      if (!contribuinte) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Contribuinte',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Contribuinte',
          valor: contribuinte === 'true' ? 'Sim' : 'Não',
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  const handleChangeListaItemAtualEstado = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdEstado(listaId);
    },
    []
  );

  const handleChangeListaItemAtualTipoNota = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdTipoNota(listaId);
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagTipoNota
                  label="Tipo Nota"
                  name="listaTipoNota"
                  onChangeListaItemAtual={({ listaItemAtual }) => {
                    handleChangeListaItemAtualTipoNota(listaItemAtual);
                  }}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagTipoNotaMotivo
                  label="Motivo"
                  name="listaTipoNotaMotivo"
                  listaIdTipoNota={listaIdTipoNota}
                />
              </Col>
              <Col lg={12} md={12}>
                <InputAutoCompleteTagCodigoSituacaoTributariaIcms
                  label="CST DO ICMS"
                  name="listaCstIcms"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagNcm label="NCM" name="listaNcm" />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCest label="CEST" name="listaCest" />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagEstado
                  label="Estados"
                  name="listaEstado"
                  onChangeListaItemAtual={({ listaItemAtual }) => {
                    handleChangeListaItemAtualEstado(listaItemAtual);
                  }}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCidade
                  label="Cidades"
                  name="listaCidade"
                  listaIdEstado={listaIdEstado}
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagProduto
                  label="Produtos"
                  name="listaProduto"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagModeloDocumento
                  label="Modelos de Documento"
                  name="listaModeloDocumento"
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Destinado"
                  name="destinado"
                  options={FormatarEnum({
                    enumObj: DestinadoEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Regime Tributário"
                  name="regimeTributario"
                  options={FormatarEnum({
                    enumObj: RegimeTributarioEnum,
                    todos: true,
                  })}
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="contribuinte"
                  label="Contribuinte"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  label="Ativo"
                  name="ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
