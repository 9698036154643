import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/obter-valor-utilizado-adiantamento';

interface IFrenteCaixaPdvObterValorUtilizadoAdiantamentoComunicadorShow {
  params: {
    idContaParcela: string;
    idContaAdiantamento: string;
  };
}

class FrenteCaixaPdvObterValorUtilizadoAdiantamentoComunicador {
  public async show(
    params: IFrenteCaixaPdvObterValorUtilizadoAdiantamentoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new FrenteCaixaPdvObterValorUtilizadoAdiantamentoComunicador();
