"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var AbrirObservacaoClienteEnum;
(function (AbrirObservacaoClienteEnum) {
    AbrirObservacaoClienteEnum["nuncaAbrir"] = "Nunca Abrir";
    AbrirObservacaoClienteEnum["somenteAbrirQuandoTiverObs"] = "Somente abrir quando tiver Observa\u00E7\u00F5es no cadastro do Cliente";
    AbrirObservacaoClienteEnum["sempreAbrir"] = "Sempre Abrir";
})(AbrirObservacaoClienteEnum || (AbrirObservacaoClienteEnum = {}));
exports.default = AbrirObservacaoClienteEnum;
