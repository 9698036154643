import {
  IFrenteCaixaPdvItemLista,
  RateioComCasaDecimalCalculo,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext } from 'react';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseFrenteCaixaPdv } from './FrenteCaixaPdvHook';

interface IFrenteCaixaPdvCalculoContext {
  validador: boolean;

  calcularRateiosItens(
    listaItemCalcular: IFrenteCaixaPdvItemLista[]
  ): Promise<void>;
  calcularTotais(listaItemCalcular: IFrenteCaixaPdvItemLista[]): Promise<void>;
  liberar(): Promise<void>;
}

const FrenteCaixaPdvCalculoContext =
  createContext<IFrenteCaixaPdvCalculoContext>(
    {} as IFrenteCaixaPdvCalculoContext
  );

const FrenteCaixaPdvCalculoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef, setLoading, refresh } = UseForm();
  const {
    setListaItem,
    setItemSelecionado,
    setIdNotaFiscalSaida,
    setIdPreVenda,
    setListaIdItemConsignado,
  } = UseFrenteCaixaPdv();

  const handleCalcularRateiosItens = useCallback(
    async (listaItemCalcular: IFrenteCaixaPdvItemLista[]) => {
      const descontoFormaCalculoPorRateio = formRef.current?.getFieldValue(
        'descontoFormaCalculoPorRateio'
      );
      const outrasDespesasFormaCalculoPorRateio =
        formRef.current?.getFieldValue('outrasDespesasFormaCalculoPorRateio');

      if (descontoFormaCalculoPorRateio) {
        const valorDesconto =
          formRef.current?.getFieldValue('valorDescontoGeral');

        const listaValorSubTotal: number[] = [];
        for (let i = 0; i < listaItemCalcular.length; i++) {
          listaValorSubTotal.push(listaItemCalcular[i].subTotal);
        }

        const listaValorDescontoRateio = RateioComCasaDecimalCalculo({
          valor: valorDesconto,
          valores: listaValorSubTotal,
          casasDecimais: 2,
        });

        for (let i = 0; i < listaItemCalcular.length; i++) {
          const item = listaItemCalcular[i];

          item.valorDesconto = listaValorDescontoRateio[i];
          item.percentualDesconto = item.subTotal
            ? (item.valorDesconto * 100) / item.subTotal
            : 0;
          item.valorTotalItem =
            item.subTotal - item.valorDesconto + item.valorAcrescimo;
        }
      }

      if (outrasDespesasFormaCalculoPorRateio) {
        const valorAcrescimo = formRef.current?.getFieldValue(
          'valorAcrescimoGeral'
        );

        const listaValorSubTotal: number[] = [];
        for (let i = 0; i < listaItemCalcular.length; i++) {
          listaValorSubTotal.push(listaItemCalcular[i].subTotal);
        }

        const listaValorAcrescimoRateio = RateioComCasaDecimalCalculo({
          valor: valorAcrescimo,
          valores: listaValorSubTotal,
          casasDecimais: 2,
        });

        for (let i = 0; i < listaItemCalcular.length; i++) {
          const item = listaItemCalcular[i];

          item.valorAcrescimo = listaValorAcrescimoRateio[i];
          item.percentualAcrescimo = item.subTotal
            ? (item.valorAcrescimo * 100) / item.subTotal
            : 0;
          item.valorTotalItem =
            item.subTotal - item.valorDesconto + item.valorAcrescimo;
        }
      }
    },
    [formRef]
  );

  const handleCalcularTotais = useCallback(
    async (listaItemCalcular: IFrenteCaixaPdvItemLista[]) => {
      const valorSubTotal = listaItemCalcular.reduce(
        (acumulador: number, item: IFrenteCaixaPdvItemLista) => {
          acumulador += Number(item.subTotal);
          return acumulador;
        },
        0
      );
      const valorTotal = listaItemCalcular.reduce(
        (acumulador: number, item: IFrenteCaixaPdvItemLista) => {
          acumulador += Number(item.valorTotalItem);
          return acumulador;
        },
        0
      );
      const valorDesconto = listaItemCalcular.reduce(
        (acumulador: number, item: IFrenteCaixaPdvItemLista) => {
          acumulador += Number(item.valorDesconto);
          return acumulador;
        },
        0
      );
      const valorAcrescimo = listaItemCalcular.reduce(
        (acumulador: number, item: IFrenteCaixaPdvItemLista) => {
          acumulador += Number(item.valorAcrescimo);
          return acumulador;
        },
        0
      );

      const valorTotalGeralAtual =
        formRef.current?.getFieldValue('valorTotalGeral');

      if (valorTotalGeralAtual !== valorTotal) {
        formRef.current?.setFieldValue('listaPagamentoSalvo', []);
      }

      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'valorSubTotalGeral',
        valorSubTotal.Arredondar()
      );
      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'valorTotalGeral',
        valorTotal.Arredondar()
      );
      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'valorDescontoGeral',
        valorDesconto.Arredondar()
      );
      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'valorAcrescimoGeral',
        valorAcrescimo.Arredondar()
      );

      const percentualDesconto = valorSubTotal
        ? (valorDesconto * 100) / valorSubTotal
        : 0;
      const percentualAcrescimo = valorSubTotal
        ? (valorAcrescimo * 100) / valorSubTotal
        : 0;

      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'percentualDescontoGeral',
        percentualDesconto.Arredondar()
      );
      formRef.current?.setFieldValorInicialSemExecutarEvento(
        'percentualAcrescimoGeral',
        percentualAcrescimo.Arredondar()
      );
    },
    [formRef]
  );

  const handleLiberar = useCallback(async () => {
    try {
      setLoading(true);

      setIdNotaFiscalSaida(null);
      setIdPreVenda(null);
      setListaIdItemConsignado(null);
      setListaItem([]);
      setItemSelecionado(null);

      await handleCalcularTotais([]);

      await formRef.current?.setDataInicial({
        valorSubTotalGeral: 0,
        valorDescontoGeral: 0,
        valorAcrescimoGeral: 0,
        valorTotalGeral: 0,
        descontoFormaCalculoPorRateio: true,
        outrasDespesasFormaCalculoPorRateio: true,

        quantidade: 0,
        valorUnitario: 0,
        subTotal: 0,

        listaRepresentante: [],
      });

      setItemSelecionado(null);
      refresh();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleCalcularTotais,
    refresh,
    setIdNotaFiscalSaida,
    setIdPreVenda,
    setItemSelecionado,
    setListaIdItemConsignado,
    setListaItem,
    setLoading,
  ]);

  return (
    <FrenteCaixaPdvCalculoContext.Provider
      value={{
        calcularRateiosItens: handleCalcularRateiosItens,
        calcularTotais: handleCalcularTotais,
        liberar: handleLiberar,

        validador: true,
      }}
    >
      {children}
    </FrenteCaixaPdvCalculoContext.Provider>
  );
};

function UseFrenteCaixaPdvCalculo(): Omit<
  IFrenteCaixaPdvCalculoContext,
  'validador'
> {
  const context = useContext(FrenteCaixaPdvCalculoContext);

  if (!context.validador) {
    throw new Error(
      'UseFrenteCaixaPdvCalculo deve ser usado com um FrenteCaixaPdvProvider'
    );
  }

  return context;
}

export { FrenteCaixaPdvCalculoHook, UseFrenteCaixaPdvCalculo };
