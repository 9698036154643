/* eslint-disable no-nested-ternary */
import {
  CalcularValorTotalParcela,
  FormatarEnum,
  IContaParcelaCategoriaValoresInserir,
  IContaParcelaValoresInserir,
  IMovimentoPortadorValoresInserir,
  ObterParcelasCondicaoPagamento,
  ParcelasCalculo,
  SituacaoAdiantamentoEnum,
  SituacaoBoletoEnum,
  SituacaoContaEnum,
  TipoAdiantamentoEnum,
  TipoContaEnum,
  TipoLancamentoEnum,
} from '@elogestor/util';
import React, { useCallback } from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { v4 } from 'uuid';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteClienteFornecedor from '../../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteClienteFornecedor';
import InputAutoCompleteCondicaoPagamentoSugestao from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteCondicaoPagamentoSugestao';
import Input from '../../../../../../Componentes/Inputs/Input';
import InputDateTime from '../../../../../../Componentes/Inputs/InputDateTime';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import InputLabel from '../../../../../../Componentes/Inputs/InputLabel';
import Select from '../../../../../../Componentes/Select';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import { Divisor } from '../../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import { UseContaListaCategoria } from '../Hooks/ContaListaCategoriaHook';
import ContaParcelaHook from './Parcela/Hook';
import ParcelaLista from './Parcela/Lista';
import { UseAntecipacaoPrevisao } from '../Hooks/AntecipacaoPrevisaoHook';

interface IContaTabProps {
  isAdiantamentoAntecipacao: boolean;
  bloquearCampos?: boolean;
  bloquearCamposReversao?: boolean;
}

const ContaTab: React.FC<IContaTabProps> = ({
  isAdiantamentoAntecipacao,
  bloquearCampos,
  bloquearCamposReversao,
}) => {
  const { abrirJanela } = UseConfirmacao();
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { formRef, refresh, terminouCarregarDados } = UseForm();
  const { calcularValorTotalCategoria } = UseContaListaCategoria();
  const { atualizaAntecipacaoPrevisao } = UseAntecipacaoPrevisao();

  const tipoAdiantamento = formRef.current?.getFieldValue('tipoAdiantamento');
  const possuiTransferencia = formRef.current?.getFieldValue(
    'possuiTransferencia'
  );

  bloquearCamposReversao = formRef.current?.getFieldValue('revertida');
  const listaContaParcelaBoleto =
    formRef.current?.getFieldValue('listaContaParcela');
  const isBoletoRegistrado = listaContaParcelaBoleto?.some((event: any) => {
    return event.boleto?.situacao === SituacaoBoletoEnum.registrado;
  });

  const handleObterParcelasConformeCondicaoPagamento = useCallback(async () => {
    let listaContaParcelaNova: IContaParcelaValoresInserir[] = [];

    const listaContaParcela = formRef.current?.getFieldValue(
      'listaContaParcela'
    ) as IContaParcelaValoresInserir[];

    const dataHoraEmissao = formRef.current?.getFieldValue('dataHoraEmissao');
    const valorConta = formRef.current?.getFieldValue('valor');

    const condicaoPagamento =
      formRef.current?.getFieldValue('condicaoPagamento');

    const numeroDocumento = formRef.current?.getFieldValue('numeroDocumento');
    if (
      !dataHoraEmissao ||
      !valorConta ||
      valorConta <= 0 ||
      !condicaoPagamento
    ) {
      return;
    }

    const listaRetornoParcelas = ObterParcelasCondicaoPagamento(
      Number(valorConta),
      dataHoraEmissao,
      condicaoPagamento
    );

    if (listaRetornoParcelas.length > 0) {
      listaContaParcelaNova = listaRetornoParcelas.map((item, index) => {
        const percentualParcela = (item.valor / valorConta) * 100;

        if (listaContaParcela[index]) {
          const valorTotalParcela = CalcularValorTotalParcela({
            valorParcela: item.valor,
            valorJurosSoma: Number(listaContaParcela[index].valorJurosSoma),
            valorDescontoSubtrai: Number(
              listaContaParcela[index].valorDescontoSubtrai
            ),
            valorMultaSoma: Number(listaContaParcela[index].valorMultaSoma),
            valorOutrasDespesasSoma: Number(
              listaContaParcela[index].valorOutrasDespesasSoma
            ),
            valorOutrasDespesasSubtrai: Number(
              listaContaParcela[index].valorOutrasDespesasSubtrai
            ),
            valorDespesasCartaoSubtrai: Number(
              listaContaParcela[index].valorDespesasCartaoSubtrai
            ),
            valorDespesasCartorioSoma: Number(
              listaContaParcela[index].valorDespesasCartorioSoma
            ),
            valorDespesasEnvioSoma: Number(
              listaContaParcela[index].valorDespesasEnvioSoma
            ),
            valorComissoesSoma: Number(
              listaContaParcela[index].valorComissoesSoma
            ),
            valorDespesasEnvioSubtrai: Number(
              listaContaParcela[index].valorDespesasEnvioSubtrai
            ),
            valorComissoesSubtrai: Number(
              listaContaParcela[index].valorComissoesSubtrai
            ),
          });

          let valorTotalAberto = 0;
          let valorTotalMovimentos = 0;
          let situacao = SituacaoContaEnum.aberta;

          let listaContaParcelaLiquidacao: IMovimentoPortadorValoresInserir[] =
            [];
          let listaContaParcelaCategoria: IContaParcelaCategoriaValoresInserir[] =
            [];

          if (item.avista) {
            valorTotalMovimentos = valorTotalParcela;
            valorTotalAberto = 0;
            situacao = SituacaoContaEnum.liquidada;

            const listaContaParcelaLiquidacaoAntiga =
              listaContaParcela[index].listaContaParcelaLiquidacao;

            if (
              listaContaParcelaLiquidacaoAntiga &&
              listaContaParcelaLiquidacaoAntiga.length > 0
            ) {
              listaContaParcelaLiquidacao.push({
                ...listaContaParcelaLiquidacaoAntiga[0],
                valor: valorTotalParcela,
                percentualLiquidado: 100,
                valorGeral: valorTotalParcela,
              });
            } else {
              listaContaParcelaLiquidacao.push({
                idFormaPagamento: listaContaParcela[index].idFormaPagamento,
                formaPagamento: listaContaParcela[index].formaPagamento,
                idPortador: listaContaParcela[index].idPortador,
                portador: listaContaParcela[index].portador,
                idContaParcela: listaContaParcela[index].id,
                data: new Date(listaContaParcela[index].dataVencimento),
                dataContabil: new Date(listaContaParcela[index].dataVencimento),
                valor: valorTotalParcela,
                tipo: 1,
                percentualLiquidado: 100,
                valorGeral: listaContaParcela[index].valorParcela,
                valorJurosSoma: listaContaParcela[index].valorJurosSoma,
                valorDescontoSubtrai:
                  listaContaParcela[index].valorDescontoSubtrai,
                valorMultaSoma: listaContaParcela[index].valorMultaSoma,
                valorOutrasDespesasSoma:
                  listaContaParcela[index].valorOutrasDespesasSoma,
                valorOutrasDespesasSubtrai:
                  listaContaParcela[index].valorOutrasDespesasSubtrai,
                valorDespesasCartaoSubtrai:
                  listaContaParcela[index].valorDespesasCartaoSubtrai,
                valorDespesasCartorioSoma:
                  listaContaParcela[index].valorDespesasCartorioSoma,
                valorDespesasEnvioSoma:
                  listaContaParcela[index].valorDespesasEnvioSoma,
                valorComissoesSoma: listaContaParcela[index].valorComissoesSoma,
                valorDespesasEnvioSubtrai:
                  listaContaParcela[index].valorDespesasEnvioSubtrai,
                valorComissoesSubtrai:
                  listaContaParcela[index].valorComissoesSubtrai,
              });
            }
          } else {
            valorTotalMovimentos = Number(
              listaContaParcela[index].valorTotalMovimentos
            );
            valorTotalAberto =
              valorTotalParcela -
              Number(listaContaParcela[index].valorTotalMovimentos);

            if (valorTotalAberto === 0) {
              situacao = SituacaoContaEnum.liquidada;
            } else if (valorTotalAberto !== valorTotalParcela) {
              situacao = SituacaoContaEnum.parcialmenteLiquidada;
            } else if (valorTotalAberto === valorTotalParcela) {
              situacao = SituacaoContaEnum.aberta;
            }

            listaContaParcelaLiquidacao =
              listaContaParcela[index].listaContaParcelaLiquidacao ?? [];
            listaContaParcelaCategoria =
              listaContaParcela[index].listaContaParcelaCategoria ?? [];
          }
          const formaPagamento =
            listaContaParcela[index].formaPagamento ?? null;

          const contaParcela: IContaParcelaValoresInserir = {
            ...listaContaParcela[index],
            numeroDocumento:
              numeroDocumento || listaContaParcela[index].numeroDocumento,
            idPortador: listaContaParcela[index].idPortador ?? undefined,
            idFormaPagamento:
              listaContaParcela[index].idFormaPagamento ?? undefined,
            portador: listaContaParcela[index].portador
              ? listaContaParcela[index].portador
              : undefined,
            formaPagamento: formaPagamento
              ? {
                  id: String(formaPagamento?.id),
                  descricao: String(formaPagamento?.descricao),
                  ativo: true,
                  diarioAuxiliar: true,
                  formaPagamentoParcelasNasInformacoesContribuinte: true,
                  listaFormaPagamentoEmpresa: [],
                  listaFormaPagamentoEmpresaWake: [],
                  padraoSistema: true,
                }
              : undefined,
            dataVencimento: item.data,
            valorParcela: item.valor,
            avista: item.avista,
            percentualParcela,
            valorTotalParcela,
            valorTotalMovimentos,
            valorTotalAberto,
            situacao,

            listaContaParcelaLiquidacao,
            listaContaParcelaCategoria,
          };

          return contaParcela;
        }

        const formaPagamento = listaContaParcela[0].formaPagamento ?? null;
        const portadorParcela = listaContaParcela[0].portador ?? undefined;

        const contaParcela: IContaParcelaValoresInserir = {
          numeroDocumento: numeroDocumento || '',
          idPortador: portadorParcela?.id,
          idFormaPagamento: formaPagamento?.id,
          portador: portadorParcela,
          formaPagamento: formaPagamento
            ? {
                id: String(formaPagamento.id),
                descricao: String(formaPagamento.descricao),
                ativo: true,
                diarioAuxiliar: true,
                formaPagamentoParcelasNasInformacoesContribuinte: true,
                listaFormaPagamentoEmpresa: [],
                listaFormaPagamentoEmpresaWake: [],

                padraoSistema: true,
              }
            : undefined,
          valorParcela: item.valor,
          situacao: SituacaoContaEnum.aberta,
          valorTotalParcela: item.valor,
          valorTotalAberto: item.valor,
          valorTotalMovimentos: 0,
          dataVencimento: item.data,
          avista: item.avista,
          percentualParcela,
          sequencia: item.parcela,
          valorJurosSoma: 0,
          valorDescontoSubtrai: 0,
          valorMultaSoma: 0,
          valorOutrasDespesasSoma: 0,
          valorOutrasDespesasSubtrai: 0,
          valorDespesasCartaoSubtrai: 0,
          valorDespesasCartorioSoma: 0,
          valorDespesasEnvioSoma: 0,
          valorComissoesSoma: 0,
          valorDespesasEnvioSubtrai: 0,
          valorComissoesSubtrai: 0,

          listaContaParcelaLiquidacao: [],
          listaContaParcelaCategoria: [],
        };

        return contaParcela;
      });

      formRef.current?.setFieldValue(
        'listaContaParcela',
        listaContaParcelaNova
      );

      formRef.current?.setFieldValue(
        'quantidadeParcelas',
        listaRetornoParcelas.length
      );
    }
  }, [formRef]);

  const handleAtualizarListaContaParcelaNumeroDocumento =
    useCallback(async (): Promise<void> => {
      const numeroDocumento = formRef.current?.getFieldValue('numeroDocumento');
      if (numeroDocumento) {
        const listaContaParcela =
          formRef.current?.getFieldValue('listaContaParcela');

        if (listaContaParcela.length > 1) {
          const resposta = await abrirJanela({
            titulo: <h2>Confirmação</h2>,

            mensagem: (
              <span style={{ fontSize: 18 }}>
                {`Deseja Alterar o Número do Documento das Parcelas Cadastradas para ${numeroDocumento}?`}
              </span>
            ),
          });

          if (!resposta) {
            return;
          }

          const novaLista = listaContaParcela.map((item: any) => {
            return {
              ...item,
              numeroDocumento,
            };
          });

          formRef.current?.setFieldValue('listaContaParcela', novaLista);
        } else {
          listaContaParcela[0].numeroDocumento = numeroDocumento;
          const novaLista = [...listaContaParcela];
          formRef.current?.setFieldValue('listaContaParcela', novaLista);
        }
      }
    }, [abrirJanela, formRef]);

  const handleAtualizarListaContaParcelaValorParcela = useCallback(async () => {
    let quantidadeParcelasSemValorParcela = 0;
    const condicaoPagamento =
      formRef.current?.getFieldValue('condicaoPagamento');

    if (!condicaoPagamento) {
      const listaContaParcela: IContaParcelaValoresInserir[] =
        formRef.current?.getFieldValue('listaContaParcela');

      listaContaParcela.forEach((item: any) => {
        if (!item.valorParcela) {
          quantidadeParcelasSemValorParcela++;
        }
      });
      if (listaContaParcela.length === quantidadeParcelasSemValorParcela) {
        const valorTotalConta = formRef.current?.getFieldValue('valor');
        const quantidadeParcelas =
          formRef.current?.getFieldValue('quantidadeParcelas');

        const parcelasCalculo = ParcelasCalculo({
          valor: valorTotalConta,
          parcelas: quantidadeParcelas,
          casasDecimais: 2,
        });

        const novaLista = parcelasCalculo.map((item, index) => {
          return {
            ...listaContaParcela[index],
            valorParcela: item,
          };
        });

        formRef.current?.setFieldValue('listaContaParcela', novaLista);

        refresh();
      }
    }
  }, [formRef, refresh]);

  const handleAtualizarValoresListaContaCategoria = useCallback(
    async (valorAtual: number, valorAnterior: number) => {
      const listaContaCategoria = formRef.current?.getFieldValue(
        'listaContaCategoria'
      );

      let quantidadeCategoriaGeral = 0;

      if (listaContaCategoria && listaContaCategoria.length > 0) {
        quantidadeCategoriaGeral = listaContaCategoria.filter((item: any) => {
          return item.tipoLancamento === TipoLancamentoEnum.geral;
        }).length;

        if (quantidadeCategoriaGeral === 1) {
          listaContaCategoria.map(async (item: any) => {
            if (
              item.tipoLancamento === TipoLancamentoEnum.geral &&
              Number(item.valor) === valorAnterior
            ) {
              item.valor = valorAtual;
            }
          });

          calcularValorTotalCategoria(listaContaCategoria);
        }
      }
    },
    [calcularValorTotalCategoria, formRef]
  );

  const handleAtualizarTipoCategorias = useCallback(async () => {
    const listaContaCategoria = formRef.current?.getFieldValue(
      'listaContaCategoria'
    );
    const tipo = formRef.current?.getFieldValue('tipo');

    if (listaContaCategoria.length > 0) {
      const tipoConta = tipo === TipoContaEnum.receber ? 1 : -1;

      listaContaCategoria.forEach((item: any) => {
        const tipoLancamentoCategoria = item.tipoLancamento[1] === '-' ? -1 : 1;
        item.tipo = tipoConta * tipoLancamentoCategoria;
      });

      formRef.current?.setFieldValue(
        'listaContaCategoria',
        listaContaCategoria
      );
    }
  }, [formRef]);

  const handleAlterarPercentualCategorias = useCallback(
    async (valorConta: number) => {
      const listaContaCategoria = formRef.current?.getFieldValue(
        'listaContaCategoria'
      );

      if (listaContaCategoria && listaContaCategoria.length > 0) {
        listaContaCategoria.forEach((item: any) => {
          if (valorConta > 0) {
            item.percentualCategoria =
              (Number(item.valor) / Number(valorConta)) * 100;
          } else {
            item.percentualCategoria = 0;
          }
        });
      }
    },
    [formRef]
  );

  const tipo = formRef.current?.getFieldValue('tipo');

  return (
    <div>
      <InputHiddenHtml name="id" />
      <InputHiddenHtml name="quantidadeParcelas" />
      <InputHiddenHtml name="pessoa" />
      <InputHiddenHtml name="isAdiantamentoAntecipacao" />
      <InputHiddenHtml name="ativo" />
      <InputHiddenHtml name="possuiTransferencia" />

      <Divisor>
        <Row>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Input
              label="Número Documento"
              name="numeroDocumento"
              placeholder="Número Documento"
              onBlurCia={(event) => {
                if (event.mudou && terminouCarregarDados)
                  handleAtualizarListaContaParcelaNumeroDocumento();
              }}
              disabled={bloquearCamposReversao}
            />
          </Col>
          <Col xl={3} lg={3} md={6} sm={12}>
            <Input
              label="Tipo Documento"
              name="tipoDocumento"
              placeholder="Tipo Documento"
              disabled={bloquearCampos || bloquearCamposReversao}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <InputDateTime
              label="Data Hora Emissão"
              name="dataHoraEmissao"
              onBlur={handleObterParcelasConformeCondicaoPagamento}
              disabled={bloquearCampos || bloquearCamposReversao}
            />
          </Col>
          <Col xl={2} lg={2} md={6} sm={12}>
            <InputLabel
              label="Revertida"
              name="revertida"
              valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
              formatarValor={(valor) => {
                return (
                  <div
                    className="lista-texto"
                    style={{
                      fontWeight: 'bold',
                      alignItems: 'flex-start',
                      color: valor ? 'green' : 'black',
                    }}
                  >
                    {valor ? 'Sim' : 'Não'}
                  </div>
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={3} lg={4} md={6} sm={12}>
            <Select
              label="Tipo"
              name="tipo"
              onChange={() => {
                refresh();
                handleAtualizarTipoCategorias();
              }}
              options={FormatarEnum({
                enumObj: TipoContaEnum,
              })}
              disabled={bloquearCampos || bloquearCamposReversao}
            />
          </Col>
          <Col xl={3} lg={4} md={6} sm={12}>
            <InputDecimal
              label="Valor Conta"
              name="valor"
              casasDecimais={2}
              casasInteiras={18}
              onChangeValue={async (event, props) => {
                await handleAtualizarValoresListaContaCategoria(
                  event.valor,
                  props.valorAnteriorOnChange.ConverterParaNumber()
                );
                handleAlterarPercentualCategorias(Number(event.valor));
              }}
              onBlurCia={async (event, props) => {
                if (props.mudou) {
                  await handleAtualizarValoresListaContaCategoria(
                    event.target.value.ConverterParaNumber(),
                    props.valorAnteriorOnBlur.ConverterParaNumber()
                  );
                  handleAlterarPercentualCategorias(
                    event.target.value.ConverterParaNumber()
                  );
                  await handleObterParcelasConformeCondicaoPagamento();
                  await handleAtualizarListaContaParcelaValorParcela();
                }
              }}
              disabled={bloquearCampos || bloquearCamposReversao}
            />
          </Col>
          <Col xl={3} lg={4} md={6} sm={12}>
            <InputLabel
              label="Val. Total Conta"
              name="valorTotal"
              valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
              formatarValor={(value = 0) => {
                return Number(value).FormatarParaPtBr();
              }}
            />
          </Col>
        </Row>

        <Row style={{ display: isAdiantamentoAntecipacao ? '' : 'none' }}>
          <Col xl={3} lg={4} md={6} sm={12}>
            <Select
              label="Tipo de Adiantamento"
              name="tipoAdiantamento"
              options={FormatarEnum({
                enumObj: TipoAdiantamentoEnum,
                nullavel: true,
              })}
              onChange={async () => {
                await atualizaAntecipacaoPrevisao();
                refresh();
              }}
              disabled={bloquearCamposReversao || possuiTransferencia}
            />
          </Col>
          <Col
            xl={2}
            lg={2}
            md={6}
            sm={6}
            style={{ display: tipoAdiantamento !== null ? '' : 'none' }}
          >
            <InputLabel
              label="Val. Tot. Adiantamento"
              name="valorTotalAdiantamento"
              valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
              formatarValor={(value = 0) => {
                return Number(value).FormatarParaPtBr();
              }}
            />
          </Col>
          <Col
            xl={2}
            lg={2}
            md={6}
            sm={6}
            style={{ display: tipoAdiantamento !== null ? '' : 'none' }}
          >
            <InputLabel
              label="Val. Util. Adiantamento"
              name="valorUtilizadoAdiantamento"
              valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
              formatarValor={(value = 0) => {
                return Number(value).FormatarParaPtBr();
              }}
            />
          </Col>
          <Col
            xl={2}
            lg={2}
            md={6}
            sm={6}
            style={{ display: tipoAdiantamento !== null ? '' : 'none' }}
          >
            <InputLabel
              label="Val. Disp. Adiantamento"
              name="valorDisponivelAdiantamento"
              valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
              formatarValor={(value = 0) => {
                return Number(value).FormatarParaPtBr();
              }}
            />
          </Col>
          <Col
            xl={3}
            lg={2}
            md={6}
            sm={12}
            style={{ display: tipoAdiantamento !== null ? '' : 'none' }}
          >
            <InputLabel
              label="Situação do Adiantamento"
              name="situacaoAdiantamento"
              valorStyle={{ alignItems: 'flex-center', marginTop: 5 }}
              formatarValor={(situacaoAdiantamento) => {
                return (
                  <div
                    className="lista-texto"
                    style={{ fontWeight: 'bold', alignItems: 'flex-start' }}
                  >
                    <span
                      style={{
                        color:
                          situacaoAdiantamento ===
                          SituacaoAdiantamentoEnum.disponivel
                            ? 'green'
                            : situacaoAdiantamento ===
                                SituacaoAdiantamentoEnum.parcialmenteUtilizado
                              ? 'goldenrod'
                              : situacaoAdiantamento ===
                                  SituacaoAdiantamentoEnum.pendente
                                ? 'orange'
                                : 'red',
                      }}
                    >
                      {situacaoAdiantamento}
                    </span>
                  </div>
                );
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={8} md={6} sm={6}>
            <InputAutoCompleteClienteFornecedor
              label="Cliente / Fornecedor (Pessoa)"
              placeholder={
                tipo === TipoContaEnum.pagar ? 'Fornecedor' : 'Cliente'
              }
              name="idPessoa"
              nomeObjeto="pessoa"
              informacaoAdicional
              disabled={bloquearCampos || bloquearCamposReversao}
              cliente={tipo === TipoContaEnum.receber}
              fornecedor={tipo === TipoContaEnum.pagar}
            />
          </Col>
          <OverlayTrigger
            placement={telaPequena ? 'top' : 'top'}
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ width: telaPequena ? 'auto' : 225 }} id={v4()}>
                <Popover.Header>
                  <strong>Exemplos:</strong>
                </Popover.Header>
                <Popover.Body>
                  <ul>
                    <li>A VISTA</li>
                    <li>A VISTA/28/56 DIAS</li>
                    <li>15/30 DIAS</li>
                    <li>10 PARCELAS A CADA 20 DIAS</li>
                    <li>10 PARCELAS COM VENCIMENTO NO DIA 10</li>
                    <li>10 PARCELAS (Serão geradas a cada 30 dias)</li>
                  </ul>
                </Popover.Body>
              </Popover>
            }
          >
            <Col lg={4} md={6} sm={6}>
              <InputAutoCompleteCondicaoPagamentoSugestao
                label="Condição de Pagamento"
                name="condicaoPagamento"
                placeholder="Condição de Pagamento"
                maxLength={200}
                onChangeItemAtual={async () => {
                  await handleObterParcelasConformeCondicaoPagamento();
                  refresh();
                }}
                disabled={isBoletoRegistrado || bloquearCamposReversao}
              />
            </Col>
          </OverlayTrigger>
        </Row>
      </Divisor>

      <ContaParcelaHook>
        <ParcelaLista name="listaContaParcela" />
      </ContaParcelaHook>
    </div>
  );
};

export default ContaTab;
