/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import InputTime, { IInputTimeRef } from '../InputTime';

export interface IInputPeriodoTimeRef {
  focus(): void;
  getPeriodo(): string;
  getHoraFinal(): string | null;
  getHoraInicial(): string | null;
}

interface IInputPeriodoTimeProps {
  nomeHoraInicial: string;
  nomeHoraFinal: string;
  labelHoraInicial?: string;
  labelHoraFinal?: string;

  onChange?: (event: {
    inputHoraInicial: IInputTimeRef;
    inputHoraFinal: IInputTimeRef;
  }) => void;
  onBlur?: (event: {
    inputHoraInicial: IInputTimeRef | null;
    inputHoraFinal: IInputTimeRef | null;
  }) => void;
}

const InputPeriodoHora: React.ForwardRefRenderFunction<
  IInputPeriodoTimeRef,
  IInputPeriodoTimeProps
> = (
  {
    nomeHoraInicial,
    nomeHoraFinal,
    labelHoraInicial,
    labelHoraFinal,
    onChange,
    onBlur,
  },
  ref
) => {
  const horaInicioRef = useRef<IInputTimeRef>(null);
  const horaFimRef = useRef<IInputTimeRef>(null);

  const validarHoras = useCallback(() => {
    if (!horaInicioRef.current || !horaFimRef.current) return;

    horaInicioRef.current?.setErro('');
    horaFimRef.current?.setErro('');

    const horaInicio = horaInicioRef.current?.value();
    const horaFim = horaFimRef.current?.value();

    if (horaInicio && horaFim && horaInicio > horaFim) {
      horaInicioRef.current?.setErro('Hora final menor que hora inicial');
      horaFimRef.current?.setErro('Hora final menor que hora inicial');
    }

    if (onChange)
      onChange({
        inputHoraInicial: horaInicioRef.current,
        inputHoraFinal: horaFimRef.current,
      });
  }, [onChange]);

  const getHoraFinal = useCallback(() => {
    if (!horaFimRef.current?.value()) return null;
    return horaFimRef.current?.value();
  }, []);

  const getHoraInicial = useCallback(() => {
    if (!horaInicioRef.current?.value()) return null;
    return horaInicioRef.current?.value();
  }, []);

  useImperativeHandle(ref, () => ({
    focus() {
      horaInicioRef.current?.focus();
    },

    getHoraFinal() {
      return getHoraFinal();
    },

    getHoraInicial() {
      return getHoraInicial();
    },

    getPeriodo() {
      const horaInicio = horaInicioRef.current?.getHora();
      const horaFim = horaFimRef.current?.getHora();

      if ((!horaInicio && horaFim) || (horaInicio && !horaFim)) {
        return horaInicio || (horaFim as string);
      }

      return `${horaInicio} até ${horaFim}`;
    },
  }));

  const handleChangeHoraInicio = useCallback(() => {
    if (!horaInicioRef.current?.getHora()) {
      horaInicioRef.current?.setHora('00:00');
    }
  }, []);

  const handleChangeHoraFim = useCallback(() => {
    if (!horaFimRef.current?.getHora()) {
      horaFimRef.current?.setHora('23:59');
    }
  }, []);

  const handleInputBlur = useCallback(() => {
    if (onBlur) {
      onBlur({
        inputHoraInicial: horaInicioRef.current,
        inputHoraFinal: horaFimRef.current,
      });
    }
  }, [onBlur]);

  return (
    <>
      <Row>
        <Col>
          <InputTime
            ref={horaInicioRef}
            name={nomeHoraInicial}
            label={labelHoraInicial}
            onChange={validarHoras}
            onBlur={handleInputBlur}
          />
        </Col>
        <Col>
          <InputTime
            ref={horaFimRef}
            name={nomeHoraFinal}
            label={labelHoraFinal}
            onChange={validarHoras}
            onBlur={handleInputBlur}
          />
        </Col>
      </Row>
    </>
  );
};

export default forwardRef(InputPeriodoHora);
