import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/tabela-preco/produtos-valores';

interface ITabelaPrecoProdutosValoresComunicadorIndex {
  params: {
    filtro: any;
  };
}

class TabelaPrecoProdutoValoresRelatorioComunicador {
  public async index(
    params: ITabelaPrecoProdutosValoresComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new TabelaPrecoProdutoValoresRelatorioComunicador();
