/* eslint-disable default-case */
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO, CgNotes } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  AmbienteEnum,
  EnumObterValue,
  ModeloImpressaoNotaFiscalEntradaEnum,
  ObterTipoMercado,
  SituacaoNotaFiscalEntradaEnum,
  TipoPropriaTerceiroNotaFiscalEntradaEnum,
} from '@elogestor/util';
import { GiMoneyStack } from 'react-icons/gi/index.mjs';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { MdCancel, MdOutlineAttachEmail } from 'react-icons/md/index.mjs';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { IoCalculator } from 'react-icons/io5/index.mjs';
import { FiFile } from 'react-icons/fi/index.mjs';
import { useNavigate } from 'react-router-dom';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoNotaFiscalEntradaEtiqueta from './Impressao/Template/NotaFiscalEntradaEtiquetaImpressao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import NotaFiscalEntradaA4ImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Impressao/NotaFiscalEntradaA4Impressao/NotaFiscalEntradaA4ImpressaoComunicador';
import NotaFiscalEntradaEtiquetaImpressaoComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Impressao/NotaFiscalEntradaEtiquetaImpressao/NotaFiscalEntradaEtiquetaImpressaoComunicador';
import { UseNotaFiscalEntradaImportacaoPedidoCompra } from '../../../NotaFiscalEntradaImportacaoPedidoCompraHook';
import CancelarHook from './Cancelar/Hooks';
import CancelarDetalhe from './Cancelar/Detalhe';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import NotaFiscalEntradaTransmitirComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaTransmitirComunicador';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import ImpressaoNotaFiscal from '../../../../../DocumentosEletronicos/NFe/Impressao/index';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import ImpostosCreditosHook from './ImpostosCreditos/Hooks';
import ImpostosCreditosDetalhe from './ImpostosCreditos/Detalhe';
import NotaFiscalEntradaExportarXmlComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaExportarXmlComunicador';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import NotaFiscalEntradaUploadPdfComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaUploadPdfComunicador';
import VisualizarPDF from '../../../../../../Util/Relatorios/VisualizarPDF';
import CartaCorrecaoHook from './CartaCorrecao/Hook';
import CartaCorrecaoLista from './CartaCorrecao/Lista';
import NotaFiscalEntradaObterItensImpressaoEtiquetaComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterItensImpressaoEtiquetaComunicador';
import NotaFiscalEntradaConsultarProtocoloComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaConsultarProtocoloComunicador';
import DocumentosReferenciadosHook from './DocumentosReferenciados/Hook';
import DocumentosReferenciadosLista from './DocumentosReferenciados/Lista';
import { UseAuth } from '../../../../../../Hooks/Auth';
import { UseNotaFiscalSaidaImportacaoNotaFiscalEntrada } from '../../../../../Comercial/Vendas/NotaFiscalSaidaImportacaoNotaFiscalEntradaHook';
import NotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/NotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicador';
import CustosCompraHook from './CustosCompra/Hooks';
import CustosCompraListaDetalhe from './CustosCompra/Detalhe';
import TratarValidadeCertificado from '../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import { UseNotaFiscalEntradaImportacaoNotaFiscalSaida } from '../../../NotaFiscalEntradaImportacaoNotaFiscalSaidaHook';
import PedidosComprasVinculadosLista from './PedidosComprasVinculados/Lista';

interface IAcoesNotaFiscalEntrada {
  permissao?: IPermissao;
}

const AcoesNotaFiscalEntrada: React.FC<IAcoesNotaFiscalEntrada> = ({
  permissao,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    handleValidar: handleValidarForm,
  } = UseForm();
  const parametros = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const { empresa } = UseAuth();
  const navigate = useNavigate();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [showFormModalCancelar, setShowFormModalCancelar] = useState(false);
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);
  const [showImpostosCreditos, setShowImpostosCreditos] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [showCartaCorrecao, setShowCartaCorrecao] = useState(false);
  const [showDocumentosReferenciados, setShowDocumentosReferenciados] =
    useState(false);
  const [showCustosCompra, setShowCustosCompra] = useState(false);

  const [showPedidosComprasVinculados, setShowPedidosComprasVinculados] =
    useState(false);

  const [transmitirNotaFiscal, setTransmitirNotaFiscal] = useState(false);

  const {
    setTitulo: setTituloNotaFiscalEntradaImportacaoPedidoCompra,
    abrirNotaFiscalEntradaImportacaoPedidoCompra,
  } = UseNotaFiscalEntradaImportacaoPedidoCompra();

  const {
    setTitulo: setTituloNotaFiscalSaidaImportacaoNotaFiscalEntrada,
    abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada,
  } = UseNotaFiscalSaidaImportacaoNotaFiscalEntrada();

  const {
    setTitulo: setTituloNotaFiscalEntradaImportacaoNotaFiscalSaida,
    abrirNotaFiscalEntradaImportacaoNotaFiscalSaida,
  } = UseNotaFiscalEntradaImportacaoNotaFiscalSaida();

  const situacaoNotaFiscalEntrada = formRef.current?.getFieldValue('situacao');
  const idPessoa = formRef.current?.getFieldValue(
    'notaFiscalEntradaPessoa.idPessoa'
  );
  const numero = formRef.current?.getFieldValue('numero');
  const tipoNotaFiscalEntrada = formRef.current?.getFieldValue(
    'tipoNotaFiscalEntrada'
  );

  const idPrincipal = formRef.current?.getFieldValue('id');
  const listaNotaFiscalEntradaItem = formRef.current?.getFieldValue(
    'listaNotaFiscalEntradaItem'
  );

  const possuiPedidosVinculados = formRef.current?.getFieldValue(
    'possuiPedidosVinculados'
  );

  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');

  const tipoEntrada = formRef.current?.getFieldValue('tipoNotaFiscalEntrada');

  const handleValidar = useCallback(
    async (
      situacaoNotaFiscalEntradaValidar?: SituacaoNotaFiscalEntradaEnum
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (
          situacaoNotaFiscalEntradaValidar &&
          situacaoNotaFiscalEntradaValidar !==
            SituacaoNotaFiscalEntradaEnum.naoTransmitida
        )
          return true;

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef]
  );

  const handleSalvar = useCallback(
    async (
      situacaoNotaFiscalEntradaValidar?: SituacaoNotaFiscalEntradaEnum
    ): Promise<boolean> => {
      try {
        const data: any = formRef.current?.getData();

        if (!(await handleValidarForm(data))) {
          setLoading(false);
          return false;
        }

        if (!handleValidar(situacaoNotaFiscalEntradaValidar)) return false;

        if (formRef.current?.validarSeAlterou()) {
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar, handleValidarForm, setLoading]
  );

  const handleVisualizarObterBlobPdf = useCallback(
    async (
      visualizarPdf: Boolean
    ): Promise<{
      blobPdf: Blob | undefined;
      pdfSalvo: boolean;
    }> => {
      const idRegistro = getIdDetalheRegistro() || '';

      let blobPdf: Blob | undefined;
      let pdfSalvo = true;

      switch (parametros.ModeloParaImpressaoNotaFiscalEntrada) {
        case ModeloImpressaoNotaFiscalEntradaEnum.a4:
          {
            const response = await NotaFiscalEntradaA4ImpressaoComunicador.show(
              { id: idRegistro }
            );
            if (response.pdf) {
              if (visualizarPdf) {
                VisualizarPDF({ dados: response.pdf });
              }

              blobPdf = response.pdf;
              pdfSalvo = true;
            } else {
              if (visualizarPdf) {
                await ImpressaoNotaFiscal.handleVisualizarPdf(
                  response.impressao
                );
              }

              blobPdf = await ImpressaoNotaFiscal.handleObterBlobPdf(
                response.impressao
              );
              pdfSalvo = false;
            }
          }
          break;

        case ModeloImpressaoNotaFiscalEntradaEnum.etiqueta:
          {
            const response =
              await NotaFiscalEntradaEtiquetaImpressaoComunicador.show({
                id: idRegistro,
              });

            if (response.pdf) {
              if (visualizarPdf) {
                VisualizarPDF({ dados: response.pdf });
              }

              blobPdf = response.pdf;
              pdfSalvo = true;
            } else {
              if (visualizarPdf) {
                await ImpressaoNotaFiscalEntradaEtiqueta.handleVisualizarPdf(
                  response.impressao
                );
              }

              blobPdf =
                await ImpressaoNotaFiscalEntradaEtiqueta.handleObterBlobPdf(
                  response.impressao
                );
              pdfSalvo = false;
            }
          }
          break;
      }

      return {
        blobPdf,
        pdfSalvo,
      };
    },
    [getIdDetalheRegistro, parametros.ModeloParaImpressaoNotaFiscalEntrada]
  );

  const handleVisualizarImpressao = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoNotaFiscalEntrada))) {
        return;
      }

      setLoading(true);

      await handleVisualizarObterBlobPdf(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleSalvar,
    handleVisualizarObterBlobPdf,
    setLoading,
    situacaoNotaFiscalEntrada,
  ]);

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoNotaFiscalEntrada))) {
        return;
      }

      setLoading(true);

      const dadosPdf = await handleVisualizarObterBlobPdf(true);
      if (dadosPdf.blobPdf && !dadosPdf.pdfSalvo) {
        const chaveAcesso = formRef.current?.getFieldValue(
          'transmissao.chaveAcesso'
        );
        const ambiente = formRef.current?.getFieldValue('transmissao.ambiente');

        const ambienteFormatado = EnumObterValue(AmbienteEnum, ambiente);
        const situacaoNotaFiscalEntradaFormatado = EnumObterValue(
          SituacaoNotaFiscalEntradaEnum,
          situacaoNotaFiscalEntrada
        );
        const tipoNotaFiscalEntradaFormatado = EnumObterValue(
          TipoPropriaTerceiroNotaFiscalEntradaEnum,
          tipoNotaFiscalEntrada
        );

        const formData = new FormData();
        formData.append(
          'bloblPdf',
          dadosPdf.blobPdf as any,
          `${chaveAcesso}#ambiente:${ambienteFormatado}#situacao:${situacaoNotaFiscalEntradaFormatado}#tipoNotaFiscalEntrada:${tipoNotaFiscalEntradaFormatado}`
        );

        await NotaFiscalEntradaUploadPdfComunicador.update({
          params: formData,
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleSalvar,
    handleVisualizarObterBlobPdf,
    setLoading,
    situacaoNotaFiscalEntrada,
    tipoNotaFiscalEntrada,
  ]);

  const handleExportarXml = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const listaResponse = await NotaFiscalEntradaExportarXmlComunicador.show({
        id: idRegistro,
      });

      for (let i = 0; i < listaResponse.length; i++) {
        const response = listaResponse[i];

        await ImpressaoNotaFiscal.handleExportarXml(
          response.xml,
          response.nomeArquivo
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowEnviarEmail(true);
  }, [handleSalvar]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const idRegistro = getIdDetalheRegistro() || '';

      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: true,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: true,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      // #region Anexos

      const listaAnexo: any[] = [];

      const anexo = await handleVisualizarObterBlobPdf(false);
      listaAnexo.push({
        nomeArquivo: `Nota Fiscal (Entrada) ${numero}.pdf`,
        file: anexo.blobPdf,
        permitirExcluir: false,
      });

      const listaResponseXml =
        await NotaFiscalEntradaExportarXmlComunicador.show({ id: idRegistro });

      for (let i = 0; i < listaResponseXml.length; i++) {
        const responseXml = listaResponseXml[i];

        const utf8Content = new TextEncoder().encode(responseXml.xml as any);
        const blobXml = new Blob([utf8Content], {
          type: 'text/xml;charset=UTF-8',
        });

        listaAnexo.push({
          nomeArquivo: `${responseXml.nomeArquivo}.xml`,
          file: blobXml,
          permitirExcluir: false,
        });
      }

      // #endregion Anexos

      await formRefEmail.current?.setDataInicial({
        assunto: `Nota Fiscal (Entrada) número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo,
      });
    },
    [getIdDetalheRegistro, handleVisualizarObterBlobPdf, idPessoa, numero]
  );

  // #endregion Email

  const handleTransmitirNotaFiscal = useCallback(async () => {
    try {
      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente transmitir essa Nota Fiscal de Entrada?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response = await NotaFiscalEntradaTransmitirComunicador.update({
        id: idRegistro,
      });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    setLoading,
  ]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();
    await handleSubmit(data);

    setShowFinanceiro(false);

    if (transmitirNotaFiscal) {
      await handleTransmitirNotaFiscal();
      setTransmitirNotaFiscal(false);
    }
  }, [formRef, handleSubmit, handleTransmitirNotaFiscal, transmitirNotaFiscal]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleTransmitir = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setTransmitirNotaFiscal(true);
        return;
      }

      // #endregion Financeiro

      await handleTransmitirNotaFiscal();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleSalvar,
    handleTransmitirNotaFiscal,
    setLoading,
    valorFinanceiro,
  ]);

  const handleImportarPedidosCompra = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalEntradaImportacaoPedidoCompra(
        'Importar Pedido de Compra'
      );
      const response = await abrirNotaFiscalEntradaImportacaoPedidoCompra({
        idPessoa: String(idPessoa),
        idNotaFiscalEntrada: idRegistro,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalEntradaImportacaoPedidoCompra,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloNotaFiscalEntradaImportacaoPedidoCompra,
  ]);

  const handleGerarNotaFiscalSaida = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      const pessoaPadraoTransferencia =
        await NotaFiscalEntradaObterPessoaPadraoTransferenciaNotaFiscalSaidaComunicador.show(
          { id: idRegistro }
        );

      const finalidadeEmissao =
        formRef.current?.getFieldValue('finalidadeEmissao');
      const indicadorPresenca =
        formRef.current?.getFieldValue('indicadorPresenca');

      const tipoMercado = ObterTipoMercado({
        ufEstadoOrigem: empresa.uf,
        ufEstadoDestino:
          pessoaPadraoTransferencia.pessoaEnderecoPrincipal.cidade.estado.sigla,
        indicadorPresenca,
      });

      setTituloNotaFiscalSaidaImportacaoNotaFiscalEntrada(
        'Gerar Nota Fiscal de Saída'
      );
      const response = await abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada({
        idPessoa: pessoaPadraoTransferencia.id,
        pessoa: pessoaPadraoTransferencia,
        idNotaFiscalEntrada: idRegistro,
        indicadorPresenca,
        tipoMercado,
        finalidadeEmissao,
      });

      if (response) {
        navigate({
          pathname: `/comercial/vendas/nota-fiscal-saida/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaImportacaoNotaFiscalEntrada,
    empresa.uf,
    formRef,
    getIdDetalheRegistro,
    handleSalvar,
    navigate,
    setLoading,
    setTituloNotaFiscalSaidaImportacaoNotaFiscalEntrada,
  ]);

  const handleImportarNotaFiscalSaida = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const finalidadeEmissao =
        formRef.current?.getFieldValue('finalidadeEmissao');
      const indicadorPresenca =
        formRef.current?.getFieldValue('indicadorPresenca');
      const ufPessoa =
        formRef.current?.getFieldValue(
          'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
        ) || null;
      const ufPessoaEntrega =
        formRef.current?.getFieldValue(
          'notaFiscalEntradaPessoaEnderecoEntrega.cidade.estado.sigla'
        ) || null;

      const tipoMercado = ObterTipoMercado({
        ufEstadoOrigem: empresa.uf,
        ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
        indicadorPresenca,
      });

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalEntradaImportacaoNotaFiscalSaida(
        'Importar Nota Fiscal de Saida'
      );
      const response = await abrirNotaFiscalEntradaImportacaoNotaFiscalSaida({
        idPessoa: String(idPessoa),
        idNotaFiscalEntrada: idRegistro,
        indicadorPresenca,
        tipoMercado,
        finalidadeEmissao,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalEntradaImportacaoNotaFiscalSaida,
    empresa.uf,
    formRef,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloNotaFiscalEntradaImportacaoNotaFiscalSaida,
  ]);

  // #region Carta correção

  const handleCartaCorrecao = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowCartaCorrecao(true);
  }, [handleSalvar]);

  const handleCartaCorrecaoFecharFormModal = useCallback(async () => {
    setShowCartaCorrecao(false);

    handleCarregarDados();
  }, [handleCarregarDados]);

  // #endregion Carta Correção

  // #region Cancelar

  const handleCancelar = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFormModalCancelar(true);
  }, [handleSalvar]);

  const handleCancelarSalvarFormModal = useCallback(async () => {
    setShowFormModalCancelar(false);
    handleCarregarDados();

    await handleImprimir();
  }, [handleCarregarDados, handleImprimir]);

  const handleCancelarFecharFormModal = useCallback(async () => {
    setShowFormModalCancelar(false);
  }, []);

  // #endregion Cancelar

  // #region Impostos e Créditos

  const handleImpostosCreditorAbrirFormModal = useCallback(async () => {
    setShowImpostosCreditos(true);
    const index = listaNotaFiscalEntradaItem.length - 1;

    setIdDetalheRegistro(listaNotaFiscalEntradaItem[index].id);
  }, [listaNotaFiscalEntradaItem, setIdDetalheRegistro]);

  const handleImpostosCreditosSalvarFormModal = useCallback(async () => {
    setShowImpostosCreditos(false);
    setIdDetalheRegistro(idPrincipal);
    handleCarregarDados();
  }, [handleCarregarDados, idPrincipal, setIdDetalheRegistro]);

  const handleImpostosCreditosFecharFormModal = useCallback(async () => {
    setShowImpostosCreditos(false);
    setIdDetalheRegistro(idPrincipal);
  }, [idPrincipal, setIdDetalheRegistro]);

  // #endregion Impostos e Créditos

  const handleConsultarProtocolo = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      await TratarValidadeCertificado();

      const response =
        await NotaFiscalEntradaConsultarProtocoloComunicador.update({
          id: idRegistro,
        });

      if (response.mensagemErro) {
        ToastErro(response.mensagemErro, { autoClose: 4000 });
      } else {
        ToastSucesso(response.mensagem);
        await handleImprimir();
      }

      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    getIdDetalheRegistro,
    handleCarregarDados,
    handleImprimir,
    handleSalvar,
    setLoading,
  ]);

  const handleImprimirEtiquetasProdutos = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response =
        await NotaFiscalEntradaObterItensImpressaoEtiquetaComunicador.show({
          id: idRegistro,
        });

      window.open('/suprimentos/produtos/impressao-etiquetas-produtos');
      localStorage.setItem('@EloGestorle:etiquetas', JSON.stringify(response));

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  const handlePrecoCustoProdutos = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      window.open('/suprimentos/produtos/atualizacao-preco-custo-produto');

      localStorage.setItem(
        '@EloGestorle:pesquisaAvancada',
        JSON.stringify({
          numeroNota: formRef.current?.getFieldValue('numero'),
          idNotaFiscalEntrada: formRef.current?.getFieldValue('id'),
        })
      );

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, handleSalvar, setLoading]);

  // #region Documentos Referenciados

  const handleVisualizarDocReferenciados = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowDocumentosReferenciados(true);
  }, [handleSalvar]);

  const handleDocReferenciadosFecharFormModal = useCallback(async () => {
    setShowDocumentosReferenciados(false);
  }, []);

  // #endregion Documentos Referenciados

  // #region Custos Compra

  const handleCustosCompraAbrirFormModal = useCallback(async () => {
    setShowCustosCompra(true);
  }, []);

  const handleCustosCompraSalvarFormModal = useCallback(async () => {
    setShowCustosCompra(false);
    setIdDetalheRegistro(idPrincipal);
    handleCarregarDados();
  }, [handleCarregarDados, idPrincipal, setIdDetalheRegistro]);

  const handleCustosCompraFecharFormModal = useCallback(async () => {
    setShowCustosCompra(false);
    setIdDetalheRegistro(idPrincipal);
  }, [idPrincipal, setIdDetalheRegistro]);

  // #endregion Custos Compra

  // #region Pedidos Compras Vinculados

  const handlePedidosComprasVinculadosAbrirModal = useCallback(async () => {
    setShowPedidosComprasVinculados(true);
  }, []);

  const handlePedidosComprasVinculadosFecharModal = useCallback(async () => {
    setShowPedidosComprasVinculados(false);
  }, []);

  // #endregion Pedidos Compras Viculados

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            (situacaoNotaFiscalEntrada !==
              SituacaoNotaFiscalEntradaEnum.naoTransmitida &&
              situacaoNotaFiscalEntrada !==
                SituacaoNotaFiscalEntradaEnum.emTransmissao &&
              situacaoNotaFiscalEntrada !==
                SituacaoNotaFiscalEntradaEnum.rejeitada)
          }
          onClick={handleVisualizarImpressao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Visualizar Impressão
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.naoTransmitida ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.emTransmissao ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.rejeitada
          }
          onClick={handleImprimir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !listaNotaFiscalEntradaItem ||
            listaNotaFiscalEntradaItem.length === 0
          }
          onClick={handleExportarXml}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <FiFile style={{ marginRight: 5 }} />
            Exportar XML (Download)
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.naoTransmitida ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.emTransmissao ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.rejeitada
          }
          onClick={handleEnviarPorEmail}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.autorizada ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.cancelada ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.denegada ||
            tipoNotaFiscalEntrada ===
              TipoPropriaTerceiroNotaFiscalEntradaEnum.terceiros
          }
          onClick={handleImportarPedidosCompra}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Importar Pedidos de Compra
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleGerarNotaFiscalSaida}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Ret./Dev. (Gerar Nota de Saída)
          </span>
        </Dropdown.Item>

        {tipoNotaFiscalEntrada ===
          TipoPropriaTerceiroNotaFiscalEntradaEnum.propria && (
          <Dropdown.Item
            disabled={
              !permissao?.altera ||
              loading ||
              (situacaoNotaFiscalEntrada !==
                SituacaoNotaFiscalEntradaEnum.naoTransmitida &&
                situacaoNotaFiscalEntrada !==
                  SituacaoNotaFiscalEntradaEnum.emTransmissao &&
                situacaoNotaFiscalEntrada !==
                  SituacaoNotaFiscalEntradaEnum.rejeitada)
            }
            onClick={handleImportarNotaFiscalSaida}
          >
            <span style={{ display: 'flex', fontSize: 16 }}>
              <BiDetail style={{ marginRight: 5 }} />
              Imp./Dev. de Notas de Saída
            </span>
          </Dropdown.Item>
        )}

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.autorizada ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.denegada ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.cancelada
          }
          onClick={handleTransmitir}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Transmitir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            !listaNotaFiscalEntradaItem ||
            listaNotaFiscalEntradaItem.length === 0
          }
          onClick={handleImpostosCreditorAbrirFormModal}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <IoCalculator style={{ marginRight: 5 }} />
            Impostos / Créditos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            (situacaoNotaFiscalEntrada !==
              SituacaoNotaFiscalEntradaEnum.autorizada &&
              situacaoNotaFiscalEntrada !==
                SituacaoNotaFiscalEntradaEnum.cancelada) ||
            tipoNotaFiscalEntrada ===
              TipoPropriaTerceiroNotaFiscalEntradaEnum.terceiros
          }
          onClick={handleCartaCorrecao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <MdOutlineAttachEmail style={{ marginRight: 5 }} />
            Carta de Correção
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.naoTransmitida ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.emTransmissao ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.rejeitada ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.cancelada ||
            situacaoNotaFiscalEntrada === SituacaoNotaFiscalEntradaEnum.denegada
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoNotaFiscalEntrada ===
              SituacaoNotaFiscalEntradaEnum.denegada ||
            tipoEntrada === TipoPropriaTerceiroNotaFiscalEntradaEnum.terceiros
          }
          onClick={handleConsultarProtocolo}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Consultar Protocolo NF-e
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={handleImprimirEtiquetasProdutos}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir Etiquetas Produtos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={handleVisualizarDocReferenciados}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Documentos Referenciados
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={handleCustosCompraAbrirFormModal}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Custos da Compra
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handlePrecoCustoProdutos}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Preço e Custo dos Produtos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading ||
            !listaNotaFiscalEntradaItem ||
            listaNotaFiscalEntradaItem.length === 0 ||
            !possuiPedidosVinculados
          }
          onClick={handlePedidosComprasVinculadosAbrirModal}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CgNotes style={{ marginRight: 5 }} />
            Pedidos de Compra Vinculados
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showFormModalCancelar && (
        <CancelarHook>
          <CancelarDetalhe
            onSalvarFormModal={handleCancelarSalvarFormModal}
            onFecharFormModal={handleCancelarFecharFormModal}
          />
        </CancelarHook>
      )}

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Nota Fiscal (Entrada) por E-mail"
          mensagemPadrao={`Segue em anexo a Nota Fiscal (Entrada) número: ${numero}`}
          direcionarDocumentosFiscais
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showImpostosCreditos && (
        <ImpostosCreditosHook>
          <ImpostosCreditosDetalhe
            onSalvarFormModal={handleImpostosCreditosSalvarFormModal}
            onFecharFormModal={handleImpostosCreditosFecharFormModal}
          />
        </ImpostosCreditosHook>
      )}

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}

      {showCartaCorrecao && (
        <CartaCorrecaoHook>
          <CartaCorrecaoLista
            onFecharFormModal={handleCartaCorrecaoFecharFormModal}
          />
        </CartaCorrecaoHook>
      )}

      {showDocumentosReferenciados && (
        <DocumentosReferenciadosHook>
          <DocumentosReferenciadosLista
            onFecharFormModal={handleDocReferenciadosFecharFormModal}
          />
        </DocumentosReferenciadosHook>
      )}

      {showCustosCompra && (
        <CustosCompraHook>
          <CustosCompraListaDetalhe
            onSalvarFormModal={handleCustosCompraSalvarFormModal}
            onFecharFormModal={handleCustosCompraFecharFormModal}
          />
        </CustosCompraHook>
      )}

      {showPedidosComprasVinculados && (
        <PedidosComprasVinculadosLista
          onFecharFormModal={handlePedidosComprasVinculadosFecharModal}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default AcoesNotaFiscalEntrada;
