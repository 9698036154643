import api from '../../../../../Comum/Services/Api';

const rota =
  'suprimentos/relatorio/estoques/estoque=resumo-custo-por-tipo-estoque-sped';

interface IEstoqueResumoCustoPorTipoEstoqueSpedRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class EstoqueResumoCustoPorTipoEstoqueSpedRelatorioComunicador {
  public async index(
    params: IEstoqueResumoCustoPorTipoEstoqueSpedRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new EstoqueResumoCustoPorTipoEstoqueSpedRelatorioComunicador();
