import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/vendas/frente-caixa-pdv/gerar-nota-fiscal-saida-vale-presente';

interface IFrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicadorStore {
  params: any;
}

class FrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicador {
  public async store(
    params: IFrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }
}

export default new FrenteCaixaPdvGerarNotaFiscalSaidaValePresenteComunicador();
