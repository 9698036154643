import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import { MdCancel } from 'react-icons/md/index.mjs';
import { HiUpload } from 'react-icons/hi/index.mjs';
import Divisor from '../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../Componentes/JanelaDetalhe';
import Textarea from '../../../../../../Componentes/Inputs/Textarea';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';

interface IPessoaContatoModal {
  onSalvarFormModal(justificativa: string): void;
  onFecharFormModal(): void;
}

const OperacaoNaoRealizada: React.FC<IPessoaContatoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);
  const inputRefJustificativa = useRef<HTMLInputElement>(null);

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        justificativa: Yup.mixed()
          .nullable()
          .test({
            message: 'Justificativa é obrigatório!',
            test: () => {
              return !!data.justificativa;
            },
          })
          .test({
            message: 'Justificativa deve ter no mínimo 15 caracteres!',
            test: () => {
              return !(data.justificativa && data.justificativa.length < 15);
            },
          }),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRef.current?.getData();
    if (!(await handleValidar(data))) {
      return;
    }

    const justificativa = formRef.current?.getFieldValue('justificativa');

    if (onSalvarFormModal) onSalvarFormModal(justificativa);
  }, [handleValidar, onSalvarFormModal]);

  useEffect(() => {
    if (inputRefJustificativa.current) inputRefJustificativa.current.focus();
  }, []);

  return (
    <JanelaDetalhe
      titulo="Justificativa Operação não Realizada"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <Modal.Body style={{ maxHeight: 500 }}>
        <Container>
          <FormCia ref={formRef}>
            <Divisor>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Textarea
                    ref={inputRefJustificativa}
                    label="Justificativa"
                    name="justificativa"
                    placeholder="Justificativa"
                    minLength={15}
                    maxLength={255}
                    style={{ minHeight: 150 }}
                  />
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
            >
              <span
                style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}
              >
                <MdCancel />
                <span style={{ marginLeft: 10 }}>Cancelar</span>
              </span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                marginRight: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
            >
              <HiUpload />
              <span style={{ marginLeft: 10 }}>Transmitir</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default OperacaoNaoRealizada;
