import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import TrayTab from './TrayTab';
import GeralTab from './GeralTab';
import Simplo7Tab from './Simplo7Tab';

const EcommerceTab: React.FC = () => {
  const [tabSelecionada, setTabSelecionada] = useState('geral');

  return (
    <div>
      <Tabs
        id={v4()}
        defaultActiveKey="geral"
        activeKey={tabSelecionada}
        onSelect={(k) => setTabSelecionada(k || '')}
      >
        <Tab eventKey="geral" title="Geral">
          <GeralTab />
        </Tab>
        <Tab eventKey="tray" title="Tray">
          <TrayTab />
        </Tab>
        <Tab eventKey="simplo7" title="Simplo7">
          <Simplo7Tab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default EcommerceTab;
