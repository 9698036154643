import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {
  DiferencaDiasEntreDatas,
  FormatarDataHoraParaPtBr,
  ILiquidacaoContasEmLoteLista,
  JurosCalculoPorDentro,
  JurosSimples,
  TipoContaEnum,
} from '@elogestor/util';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { v4 } from 'uuid';
import { BiSave } from 'react-icons/bi/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import JanelaDetalhe from '../../../../../../../Componentes/JanelaDetalhe';
import { UseConfirmacao } from '../../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import { UseLiquidacaoContasEmLoteLista } from '../../../Hooks/LiquidacaoContasEmLoteListaHook';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import InputInteiro from '../../../../../../../Componentes/Inputs/InputInteiro';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteFormaPagamento from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteFormaPagamento';
import InputAutoCompletePortador from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompletePortador';
import InputAutoCompleteContaTipoAdiantamentoEnum from '../../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteContaTipoAdiantamentoEnum';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import InputTabelaDecimal, {
  IOnBlurCiaInputTabelaDecimalEvent,
} from '../../../../../../../Componentes/Inputs/InputTabela/InputTabelaDecimal';
import InputHidden from '../../../../../../../Componentes/Inputs/InputHidden';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import LiquidacaoContasEmLoteComunicador from '../../../../../../../Comunicador/Financeiro/Movimentacoes/LiquidacaoContasEmLote/Comunicador/LiquidacaoContasEmLoteComunicador';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import LoadingDiv from '../../../../../../../Componentes/LoadingDiv';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseContaListaCategoria } from '../../../../Conta/Detalhe/Hooks/ContaListaCategoriaHook';
import InputSwitch from '../../../../../../../Componentes/Inputs/InputSwitch';
import { UseParametros } from '../../../../../../../Hooks/ParametrosHook';
import { IContaTipoAdiantamento } from '../../../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagContaTipoAdiantamentoEnum';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';

interface IFinanceiroModal {
  onFecharFormModal(): void;
  onSalvarFormModal(): void;
}

// eslint-disable-next-line no-shadow
enum FormaCalculaJurosEnum {
  jurosSimples = 'Juros Simples',
  jurosPorDentro = 'Juros Por Dentro',
  ambos = 'Ambos',
}

const FinanceiroDetalhe: React.FC<IFinanceiroModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { abrirJanela } = UseConfirmacao();
  const parametros = UseParametros();
  const formRef = useRef<IFormCiaHandles>(null);

  const { listaValor } = UseLiquidacaoContasEmLoteLista();
  const {
    calcularValorTotalCategoriaParaLiquidacao,
    calcularValorCampoCategoriaParcela,
    calcularRateioCategoriasNaoLiquidada,
  } = UseContaListaCategoria();

  const [listaDados, setListaDados] = useState<ILiquidacaoContasEmLoteLista[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [disableCampos, setDisableCampos] = useState(false);
  const listaConteudoRef = useRef<HTMLInputElement[]>([]);

  const idPessoa = useMemo(() => {
    if (listaDados.length === 0) return '';

    const idPessoaPrincipal = listaDados[0]?.conta?.pessoa?.id || '';

    const mesmaPessoa = listaDados.every(
      (dados) => (dados.conta?.pessoa?.id || '') === idPessoaPrincipal
    );

    return mesmaPessoa ? idPessoaPrincipal : '';
  }, [listaDados]);

  const handleClickVoltar = useCallback(async (): Promise<any> => {
    const resposta = await abrirJanela({
      titulo: <h2>Confirmação</h2>,
      mensagem: (
        <span style={{ fontSize: 20 }}>
          Deseja fechar o Financeiro sem Salvar?
        </span>
      ),
      cancelar: 'Não',
      confimar: 'Sim',
    });

    if (!resposta) return;
    if (onFecharFormModal) onFecharFormModal();
    setListaDados([]);
  }, [abrirJanela, onFecharFormModal]);

  const handleValidar = useCallback((data: any): boolean => {
    const listaMensagemErro: string[] = [];
    if (
      Number(data.valorLiquidacao) >
      Number(data.valorTotalParcelasEJurosSelecionadas)
    ) {
      formRef.current?.setFieldError(
        'valorLiquidacao',
        'Valor Liquidacao não pode ser maior que o Valor Total das Parcelas!'
      );
      listaMensagemErro.push(
        'Valor Liquidacao não pode ser maior que o Valor Total das Parcelas!'
      );
      formRef.current?.setFieldValue('valorLiquidacao', 0);
    }

    if (Number(data.valorLiquidacao) === 0 && !data.contaAdiantamento) {
      formRef.current?.setFieldError(
        'valorLiquidacao',
        'Valor Liquidacao Deve ser Maior que Zero!'
      );
      listaMensagemErro.push('Valor Liquidacao Deve ser Maior que Zero!');
    }

    if (!data.considerarDataVencimento && !data.dataLiquidacao) {
      formRef.current?.setFieldError(
        'dataLiquidacao',
        'Data Liquidação é Obrigatória!'
      );

      listaMensagemErro.push('Data Liquidação é Obrigatória!');
    }

    if (!data.considerarDataVencimento && !data.dataContabil) {
      formRef.current?.setFieldError(
        'dataContabil',
        'Data Contábil é Obrigatória!'
      );

      listaMensagemErro.push('Data Contábil é Obrigatória!');
    }

    if (!data.idFormaPagamento && !data.contaAdiantamento) {
      formRef.current?.setFieldError(
        'idFormaPagamento',
        'Forma de Pagamento é Obrigatória!'
      );

      listaMensagemErro.push('Forma de Pagamento é Obrigatória!');
    }

    if (!data.idPortador && !data.contaAdiantamento) {
      formRef.current?.setFieldError('idPortador', 'Portador é Obrigatório!');

      listaMensagemErro.push('Portador é Obrigatório!');
    }

    if (data.listaLiquidacaoContasEmLote) {
      data.listaLiquidacaoContasEmLote.forEach((item: any) => {
        if (String(item.valorTotalAberto).ConverterParaNumber() < 0) {
          listaMensagemErro.push(
            `Valor Total Aberto da Parcela ${item.numeroDocumento} deve ser maior que Zero!`
          );
        }
      });
    }

    if (listaMensagemErro.length > 0) {
      TratarErros({ listaMensagem: listaMensagemErro });
      return false;
    }

    return true;
  }, []);

  const handleClickSalvar = useCallback(
    async (data: any): Promise<void> => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const dados = formRef.current?.getDataDuplicar();
        if (dados) {
          dados.listaLiquidacaoContasEmLote = listaDados;
          dados.idPortador = dados.portador ? dados.portador.id : null;
          dados.idFormaPagamento = dados.formaPagamento
            ? dados.formaPagamento.id
            : null;
          dados.idContaAdiantamento = dados.contaAdiantamento
            ? dados.contaAdiantamento.id
            : null;
        }

        if (dados.listaLiquidacaoContasEmLote) {
          for (let i = 0; i < dados.listaLiquidacaoContasEmLote.length; i++) {
            const dado = dados.listaLiquidacaoContasEmLote[i];

            if (
              dado.listaContaParcelaCategoria &&
              dado.listaContaParcelaCategoria.length > 0
            ) {
              const valoresCategoria = await calcularValorCampoCategoriaParcela(
                dado.listaContaParcelaCategoria
              );

              const listaMovimentoPortadorCategoria: any[] = [];

              dado.valorGeral = dado.valorParcela;

              dado.listaContaParcelaCategoria.map(async (item: any) => {
                const valorCategoria =
                  await calcularValorTotalCategoriaParaLiquidacao(
                    item,
                    dado,
                    valoresCategoria
                  );

                if (valorCategoria > 0) {
                  const categoriaFiltrada = dado.conta.listaContaCategoria.find(
                    (it: any) =>
                      it.idCategoria === item.idCategoria &&
                      it.tipoLancamento === item.tipoLancamento
                  );

                  listaMovimentoPortadorCategoria.push({
                    ...item,
                    id: undefined,
                    valor: valorCategoria,
                    percentualCategoria:
                      (valorCategoria / Number(categoriaFiltrada.valor)) * 100,
                  });
                }
              });

              dado.listaMovimentoPortadorCategoria =
                listaMovimentoPortadorCategoria;

              calcularRateioCategoriasNaoLiquidada(
                dado,
                listaMovimentoPortadorCategoria
              );
            }
          }
        }

        if (!handleValidar(dados)) {
          setLoading(false);
          return;
        }
        await LiquidacaoContasEmLoteComunicador.store({
          params: dados,
        });

        if (onSalvarFormModal) onSalvarFormModal();
        setListaDados([]);
        setLoading(false);
        ToastSucesso('Liquidação Salva!');
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [
      calcularRateioCategoriasNaoLiquidada,
      calcularValorCampoCategoriaParcela,
      calcularValorTotalCategoriaParaLiquidacao,
      handleValidar,
      listaDados,
      onSalvarFormModal,
    ]
  );

  const handleValidaSeCalculaJuros = useCallback(
    async (dataVencimento: Date) => {
      const jurosParametro = Number(
        parametros.JurosAoMesPadraoParaContasEmAtraso
      );

      const dataLiquidacao = formRef.current?.getFieldValue('dataLiquidacao');

      let utcFront = new Date().toTimeString().slice(12, 17);
      utcFront = utcFront.Insert(3, ':');

      const diasEmAtraso = DiferencaDiasEntreDatas(
        dataVencimento,
        dataLiquidacao,
        utcFront
      );

      return { diasEmAtraso, jurosParametro };
    },
    [parametros.JurosAoMesPadraoParaContasEmAtraso]
  );

  const handleCalculaJurosLiquidacao = useCallback(
    async (
      formaCalculoJuros: FormaCalculaJurosEnum,
      listaValores: ILiquidacaoContasEmLoteLista[] = listaDados
    ) => {
      let valorLiquidacao = formRef.current.getFieldValue('valorLiquidacao');
      const consideraDataVencimento = formRef.current.getFieldValue(
        'considerarDataVencimento'
      );

      // Trecho para quando houver um adiantamento selecionado
      const adiantamentoSelecionado =
        formRef.current.getFieldValueNomeObjeto('contaAdiantamento');

      let valorAdiantamentoSelecionado = 0;
      if (adiantamentoSelecionado) {
        valorAdiantamentoSelecionado = Number(
          adiantamentoSelecionado.valorDisponivelAdiantamento
        );
      }

      for (let i = 0; i < listaValores.length; i++) {
        const dadoAtual = listaValores[i];
        let jurosCalculados = 0;
        let valorLiquidacaoUtilizado = 0;
        let somatorioJurosExistentes = 0;
        let somatorioMovimentosExistentes = 0;

        // Se o item atual já possuir alguma parcela, então incremente as variaveis de somatorio para contabilizar corretamente no final
        if (dadoAtual.listaContaParcelaLiquidacao.length > 0) {
          dadoAtual.listaContaParcelaLiquidacao.forEach((item) => {
            somatorioJurosExistentes += Number(item.valorJurosSoma).Arredondar(
              2
            );
            somatorioMovimentosExistentes += Number(item.valor).Arredondar(2);
          });
        }

        if (adiantamentoSelecionado) {
          // Trecho exclusivo para quando houver adiantamento selecionado
          if (
            valorAdiantamentoSelecionado > dadoAtual.valorTotalAbertoOriginal
          ) {
            dadoAtual.valorTotalMovimentos =
              Number(dadoAtual.valorTotalAbertoOriginal) +
              somatorioMovimentosExistentes;
            dadoAtual.valorLiquidacao = Number(
              dadoAtual.valorTotalAbertoOriginal
            );
            dadoAtual.valorTotalAberto = 0;
          } else if (valorAdiantamentoSelecionado > 0) {
            dadoAtual.valorTotalMovimentos =
              valorAdiantamentoSelecionado + somatorioMovimentosExistentes;
            dadoAtual.valorLiquidacao = valorAdiantamentoSelecionado;
            dadoAtual.valorTotalAberto = Number(
              Number(
                dadoAtual.valorTotalParcela - valorAdiantamentoSelecionado
              ).Arredondar() - somatorioMovimentosExistentes
            ).Arredondar();
          } else if (valorAdiantamentoSelecionado <= 0) {
            dadoAtual.valorTotalMovimentos = somatorioMovimentosExistentes;
            dadoAtual.valorLiquidacao = 0;
            dadoAtual.valorTotalAberto = Number(
              Number(dadoAtual.valorTotalParcela) -
                somatorioMovimentosExistentes
            ).Arredondar();
          }

          valorAdiantamentoSelecionado = Number(
            valorAdiantamentoSelecionado - dadoAtual.valorLiquidacao
          ).Arredondar();
        } else if (!consideraDataVencimento) {
          // Se não considerar a Data de Vencimento, então faz os calculos para obter os juros
          if (!dadoAtual.formaCalculoJurosManual) {
            const { diasEmAtraso, jurosParametro } =
              await handleValidaSeCalculaJuros(dadoAtual.dataVencimento);

            if (diasEmAtraso > 0 && jurosParametro > 0) {
              switch (formaCalculoJuros) {
                case FormaCalculaJurosEnum.jurosSimples:
                  jurosCalculados = JurosSimples({
                    valorPagar: dadoAtual.valorTotalAbertoOriginal,
                    diasAtraso: diasEmAtraso,
                    taxaMensal: jurosParametro,
                  });
                  valorLiquidacaoUtilizado = dadoAtual.valorTotalAbertoOriginal;
                  break;
                case FormaCalculaJurosEnum.jurosPorDentro:
                  jurosCalculados = JurosCalculoPorDentro({
                    valorPagar: dadoAtual.valorTotalAbertoOriginal,
                    diasAtraso: diasEmAtraso,
                    taxaMensal: jurosParametro,
                  });
                  valorLiquidacaoUtilizado = dadoAtual.valorTotalAbertoOriginal;
                  break;
                default:
                  if (valorLiquidacao > 0) {
                    jurosCalculados = JurosSimples({
                      valorPagar: dadoAtual.valorTotalAbertoOriginal,
                      diasAtraso: diasEmAtraso,
                      taxaMensal: jurosParametro,
                    });
                    valorLiquidacaoUtilizado =
                      dadoAtual.valorTotalAbertoOriginal;
                    if (valorLiquidacao <= dadoAtual.valorTotalAbertoOriginal) {
                      jurosCalculados = JurosCalculoPorDentro({
                        valorPagar: valorLiquidacao,
                        diasAtraso: diasEmAtraso,
                        taxaMensal: jurosParametro,
                      });
                      valorLiquidacaoUtilizado = valorLiquidacao;
                    }
                  }
                  break;
              }
              dadoAtual.valorJurosSoma = Number(jurosCalculados).Arredondar(2);
            } else {
              dadoAtual.valorJurosSoma = 0;
              if (formaCalculoJuros === FormaCalculaJurosEnum.jurosSimples) {
                valorLiquidacaoUtilizado = Number(
                  dadoAtual.valorTotalAbertoOriginal
                ).Arredondar(2);
              } else {
                valorLiquidacaoUtilizado =
                  valorLiquidacao > Number(dadoAtual.valorTotalAbertoOriginal)
                    ? Number(dadoAtual.valorTotalAbertoOriginal).Arredondar(2)
                    : valorLiquidacao;
              }
            }
            // Definir o valor da liquidacao do item atual para fazer os calculos corretamente - Revisar codigo duplicado...
            if (formaCalculoJuros === FormaCalculaJurosEnum.jurosPorDentro) {
              dadoAtual.valorLiquidacao = Number(
                valorLiquidacaoUtilizado
              ).Arredondar(2);
            } else if (formaCalculoJuros === FormaCalculaJurosEnum.ambos) {
              if (valorLiquidacao <= dadoAtual.valorTotalAbertoOriginal) {
                dadoAtual.valorLiquidacao = Number(
                  valorLiquidacaoUtilizado
                ).Arredondar(2);
              } else {
                dadoAtual.valorLiquidacao =
                  Number(valorLiquidacaoUtilizado).Arredondar(2) +
                  dadoAtual.valorJurosSoma;
              }
            } else {
              dadoAtual.valorLiquidacao =
                Number(valorLiquidacaoUtilizado).Arredondar(2) +
                dadoAtual.valorJurosSoma;
            }
          } else {
            // Se os juros for calculado manualmente
            dadoAtual.valorLiquidacao =
              formaCalculoJuros === FormaCalculaJurosEnum.jurosSimples ||
              valorLiquidacao > dadoAtual.valorTotalAbertoOriginal
                ? Number(dadoAtual.valorTotalAbertoOriginal) +
                  dadoAtual.valorJurosSoma
                : valorLiquidacao.Arredondar();

            // Zera o valor dos juros quando não possui valor liquidacao suficiente para distribuir entre as parcelas
            if (dadoAtual.valorLiquidacao <= 0) {
              dadoAtual.valorJurosSoma = 0;
            }
          }

          // Decrementa o valor da liquidacao para manter o controle da distribuicao dos valores corretamente
          valorLiquidacao =
            Number(valorLiquidacao).Arredondar(2) - dadoAtual.valorLiquidacao;

          // Atribui os valores para cada coluna da tabela, com todas as variaveis preenchidas durante o código
          dadoAtual.valorTotalParcela =
            Number(dadoAtual.valorParcela).Arredondar(2) +
            somatorioJurosExistentes +
            dadoAtual.valorJurosSoma;

          dadoAtual.valorTotalMovimentos = Number(
            somatorioMovimentosExistentes +
              Number(dadoAtual.valorLiquidacao).Arredondar(2)
          ).Arredondar();

          dadoAtual.valorTotalAberto = (
            Number(
              dadoAtual.valorTotalParcela - dadoAtual.valorLiquidacao
            ).Arredondar() - somatorioMovimentosExistentes
          ).Arredondar();
        } else {
          // Se considerar Data de Vencimento, então faz um calculo normal sem juros
          if (valorLiquidacao > dadoAtual.valorTotalAbertoOriginal) {
            dadoAtual.valorTotalMovimentos =
              Number(dadoAtual.valorTotalAbertoOriginal) +
              somatorioMovimentosExistentes;
            dadoAtual.valorLiquidacao = Number(
              dadoAtual.valorTotalAbertoOriginal
            );
            dadoAtual.valorTotalAberto = 0;
          } else if (valorLiquidacao > 0) {
            dadoAtual.valorTotalMovimentos =
              valorLiquidacao + somatorioMovimentosExistentes;
            dadoAtual.valorLiquidacao = valorLiquidacao;
            dadoAtual.valorTotalAberto = Number(
              Number(
                dadoAtual.valorTotalParcela - valorLiquidacao
              ).Arredondar() - somatorioMovimentosExistentes
            ).Arredondar();
          } else if (valorAdiantamentoSelecionado <= 0) {
            dadoAtual.valorTotalMovimentos = somatorioMovimentosExistentes;
            dadoAtual.valorLiquidacao = 0;
            dadoAtual.valorTotalAberto = Number(
              Number(dadoAtual.valorTotalParcela) -
                somatorioMovimentosExistentes
            ).Arredondar();
          }
          valorLiquidacao =
            Number(valorLiquidacao).Arredondar(2) - dadoAtual.valorLiquidacao;
        }
      }
      setListaDados([...listaValores]);
    },
    [handleValidaSeCalculaJuros, listaDados]
  );

  const handleCalculaValoresTotais = useCallback(
    async (listaValores: ILiquidacaoContasEmLoteLista[] = listaDados) => {
      let valorTotalParcelas = 0;
      let valorTotalJuros = 0;
      let valorTotalParcelasEJuros = 0;

      for (let i = 0; i < listaValores.length; i++) {
        const dadoAtual = listaValores[i];
        valorTotalParcelas += Number(dadoAtual.valorTotalAbertoOriginal);
        valorTotalJuros += Number(dadoAtual.valorJurosSoma);
      }
      valorTotalParcelasEJuros = valorTotalParcelas + valorTotalJuros;

      formRef.current.setFieldValue(
        'valorTotalParcelasSelecionadas',
        valorTotalParcelas
      );
      formRef.current.setFieldValue(
        'valorTotalJurosParcelasSelecionadas',
        valorTotalJuros
      );
      formRef.current.setFieldValue(
        'valorTotalParcelasEJurosSelecionadas',
        valorTotalParcelasEJuros
      );

      const valorLiquidacao = Number(
        formRef.current.getFieldValue('valorLiquidacao')
      );
      if (valorLiquidacao > valorTotalParcelasEJuros)
        formRef.current.setFieldValue(
          'valorLiquidacao',
          valorTotalParcelasEJuros
        );
    },
    [listaDados]
  );

  const handleCalcularValorLista = useCallback(
    async (
      valorLiquidacao: number,
      valorLiquidacaoAnterior: number,
      index: number
    ) => {
      const dadoAtual = listaDados[index];

      const diferenca = (
        Number(valorLiquidacao) - Number(valorLiquidacaoAnterior)
      ).Arredondar();

      dadoAtual.valorTotalMovimentos =
        Number(dadoAtual.valorTotalMovimentos) + diferenca;

      if (diferenca > 0) {
        dadoAtual.valorTotalAberto -= diferenca;
      } else {
        dadoAtual.valorTotalAberto += diferenca * -1;
      }

      let somatorioLiquidacaoItens = 0;
      listaDados.forEach((item) => {
        somatorioLiquidacaoItens +=
          Number(item.valorTotalAbertoOriginal) + Number(item.valorJurosSoma);
      });

      let valorTotalLiquidacao =
        formRef.current?.getFieldValue('valorLiquidacao');

      valorTotalLiquidacao += diferenca;

      if (
        valorTotalLiquidacao.Arredondar() >
        somatorioLiquidacaoItens.Arredondar()
      ) {
        formRef.current?.setFieldValue(
          'valorLiquidacao',
          somatorioLiquidacaoItens
        );
      } else
        formRef.current?.setFieldValue('valorLiquidacao', valorTotalLiquidacao);

      setListaDados([...listaDados]);
    },
    [listaDados]
  );

  const handleDesabilitarHabilitarCamposData = useCallback(async () => {
    const considerarDataVencimento = formRef.current?.getFieldValue(
      'considerarDataVencimento'
    );
    const listaCampos = ['dataLiquidacao', 'dataContabil'];
    listaCampos.forEach((campo) =>
      formRef.current?.setFieldDisabled(campo, considerarDataVencimento)
    );

    const adiantamentoSelecionado =
      formRef.current.getFieldValueNomeObjeto('contaAdiantamento');

    if (!adiantamentoSelecionado) {
      if (considerarDataVencimento) {
        setDisableCampos(true);
        listaCampos.forEach((campo) => {
          formRef.current?.clearField(campo);
        });
        listaDados.forEach((item) => {
          item.valorTotalParcela -= item.valorJurosSoma;
          item.valorTotalMovimentos -= item.valorJurosSoma;
          item.valorLiquidacao -= item.valorJurosSoma;
          item.valorJurosSoma = 0;
        });
      } else {
        setDisableCampos(false);
        listaDados.forEach((item) => {
          if (item.formaCalculoJurosManual) item.valorJurosSoma = 0;
        });
        await handleCalculaJurosLiquidacao(FormaCalculaJurosEnum.ambos);
      }

      setListaDados([...listaDados]);

      await handleCalculaValoresTotais();
    }
  }, [handleCalculaJurosLiquidacao, handleCalculaValoresTotais, listaDados]);

  const handleJurosAlteradoManualmente = useCallback(
    async (ev: IOnBlurCiaInputTabelaDecimalEvent, index: number) => {
      // Calcula a diferença do valor do juros inserido manual
      const valorJurosAtual = String(ev.valor).ConverterParaNumber();
      const valorJurosAnterior = String(
        ev.valorAnteriorOnBlur
      ).ConverterParaNumber();
      const diferencaJuros = valorJurosAtual - valorJurosAnterior;

      // Atualiza o juros somente daquele indice que foi alterado
      listaDados[index].valorJurosSoma = valorJurosAtual;
      listaDados[index].valorLiquidacao += diferencaJuros;

      setListaDados([...listaDados]);

      const valorLiquidacao =
        Number(formRef.current.getFieldValue('valorLiquidacao')) +
        diferencaJuros;
      formRef.current.setFieldValue('valorLiquidacao', valorLiquidacao);
      await handleCalculaJurosLiquidacao(FormaCalculaJurosEnum.ambos);
      await handleCalculaValoresTotais();
    },
    [handleCalculaJurosLiquidacao, handleCalculaValoresTotais, listaDados]
  );

  const handleValorLiquidacaoComAdiantamento = useCallback(
    (
      valorLiquidacaoAnterior: number,
      index: number,
      adiantamentoSelecionado: any
    ) => {
      formRef.current?.setFieldValue('valorLiquidacao', 0);

      const somatorioLiquidacao = listaDados.reduce(
        (acc: number, item: any) => {
          acc += Number(item.valorLiquidacao);
          return acc;
        },
        0
      );

      if (
        somatorioLiquidacao >
        adiantamentoSelecionado.valorDisponivelAdiantamento
      ) {
        // Se a somatório for maior que o valor disponivel do adiantamento, então retorna a liquidação atual para o valor anterior
        listaDados[index].valorLiquidacao = valorLiquidacaoAnterior;
        setListaDados([...listaDados]);
        return ToastErro(
          `A soma dos valores das liquidações não pode ser maior que o valor do adiantamento selecionado`
        );
      }

      for (let i = 0; i < listaDados.length; i++) {
        const dadoAtual = listaDados[i];
        let somatorioMovimentosExistentes = 0;

        // Se o item atual já possuir alguma parcela, então incremente as variaveis de somatorio para contabilizar corretamente no final
        if (dadoAtual.listaContaParcelaLiquidacao.length > 0) {
          dadoAtual.listaContaParcelaLiquidacao.forEach((item) => {
            somatorioMovimentosExistentes += Number(item.valor).Arredondar(2);
          });
        }

        if (dadoAtual.valorLiquidacao >= dadoAtual.valorTotalAbertoOriginal) {
          dadoAtual.valorTotalMovimentos =
            somatorioMovimentosExistentes +
            Number(dadoAtual.valorTotalAbertoOriginal);
          dadoAtual.valorLiquidacao = Number(
            dadoAtual.valorTotalAbertoOriginal
          );
          dadoAtual.valorTotalAberto = 0;
        } else if (
          dadoAtual.valorLiquidacao < dadoAtual.valorTotalAbertoOriginal
        ) {
          const diferenca =
            Number(dadoAtual.valorTotalAbertoOriginal) -
            Number(dadoAtual.valorLiquidacao);

          dadoAtual.valorTotalAberto = diferenca;
          dadoAtual.valorTotalMovimentos =
            somatorioMovimentosExistentes + Number(dadoAtual.valorLiquidacao);
        }
      }

      setListaDados([...listaDados]);

      return null;
    },
    [listaDados]
  );

  const handleCalculaLiquidacaoComAdiantamento = useCallback(
    async (itemAtual: IContaTipoAdiantamento) => {
      if (itemAtual) {
        // Ajustar campos não obrigatórios
        formRef.current?.setFieldError('idFormaPagamento', '');
        formRef.current?.setFieldValue('formaPagamento', null);
        formRef.current?.setFieldError('idPortador', '');
        formRef.current?.setFieldValue('portador', null);

        listaDados.forEach((item) => {
          item.valorTotalParcela -= item.valorJurosSoma;
          item.valorTotalMovimentos -= item.valorJurosSoma;
          item.valorLiquidacao -= item.valorJurosSoma;
          item.valorJurosSoma = 0;
        });
        setDisableCampos(true);
        formRef.current?.setFieldDisabled('valorLiquidacao', true);
        formRef.current.setFieldValue('valorLiquidacao', 0);
      } else {
        setDisableCampos(false);
        formRef.current?.setFieldDisabled('valorLiquidacao', false);
      }

      await handleCalculaJurosLiquidacao(FormaCalculaJurosEnum.jurosSimples);
      await handleCalculaValoresTotais();

      const valorFinalLiquidacao = itemAtual
        ? 0
        : formRef.current.getFieldValue('valorTotalParcelasEJurosSelecionadas');
      formRef.current.setFieldValue('valorLiquidacao', valorFinalLiquidacao);
    },
    [handleCalculaJurosLiquidacao, handleCalculaValoresTotais, listaDados]
  );

  const handleSugerirDataContabil = useCallback(async () => {
    const dataLiquidacao = formRef.current?.getFieldValue('dataLiquidacao');
    formRef.current?.setFieldValue('dataContabil', dataLiquidacao);
  }, []);

  const handleCarregarDados = useCallback(async () => {
    const listaDadosSemReferencia = JSON.parse(
      JSON.stringify(listaValor)
    ) as ILiquidacaoContasEmLoteLista[];

    const listaValoresFiltrados = listaDadosSemReferencia.filter((item) => {
      return item.selecionado;
    });

    let somatorioJurosExistentes = 0;
    let somatorioMovimentosExistentes = 0;
    let diferencaJurosExistente = 0;
    let somatorioValorLiquidacaoExistente = 0;

    listaValoresFiltrados.forEach((item) => {
      item.valorTotalAbertoOriginal = item.valorTotalAberto;
      // Se o item atual já possuir alguma parcela, então incremente as variaveis de somatorio para contabilizar corretamente no final
      if (item.listaContaParcelaLiquidacao.length > 0) {
        item.listaContaParcelaLiquidacao.forEach((itemParcela) => {
          somatorioJurosExistentes += Number(
            itemParcela.valorJurosSoma
          ).Arredondar(2);
          somatorioMovimentosExistentes += Number(itemParcela.valor).Arredondar(
            2
          );
        });
      }

      // Trecho abaixo para quando a conta possuir juros inseridos manualmente lá na propria conta, ele considera a parcela atual como juros manual.
      diferencaJurosExistente =
        Number(item.valorJurosSoma) - somatorioJurosExistentes;

      if (diferencaJurosExistente > 0) {
        item.formaCalculoJurosManual = true;
        item.valorJurosSoma = diferencaJurosExistente.Arredondar();
        item.valorTotalAbertoOriginal =
          Number(item.valorTotalAbertoOriginal) - item.valorJurosSoma;
        somatorioValorLiquidacaoExistente +=
          item.valorTotalAbertoOriginal + item.valorJurosSoma;
      }
    });

    // Setar dados iniciais para fazer os calculos seguintes
    formRef.current?.setDataInicial({
      dataLiquidacao: new Date(),
      dataContabil: new Date(),
      considerarDataVencimento: false,
      quantidadeParcelasSelecionadas: listaValoresFiltrados.length,
      valorLiquidacao: somatorioValorLiquidacaoExistente,
    });

    // Calcular primeiramente os juros com os valores dos itens selecionados e a data setada acima e depois calcular os valores totais
    await handleCalculaJurosLiquidacao(
      FormaCalculaJurosEnum.jurosSimples,
      listaValoresFiltrados
    );
    await handleCalculaValoresTotais(listaValoresFiltrados);

    const valorTotalInicial = formRef.current.getFieldValue(
      'valorTotalParcelasEJurosSelecionadas'
    );
    formRef.current.setFieldValue('valorLiquidacao', valorTotalInicial);
  }, [handleCalculaJurosLiquidacao, handleCalculaValoresTotais, listaValor]);

  useEffect(() => {
    handleCarregarDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JanelaDetalhe
      titulo="Liquidação das Parcelas"
      tamanho="xl"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRef}>
            <Divisor>
              <InputHidden name="alterouLista" />
              <Row>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputDecimal
                    name="valorTotalParcelasSelecionadas"
                    label="Val. Total Parcelas"
                    disabled
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputDecimal
                    name="valorTotalJurosParcelasSelecionadas"
                    label="Val. Total Juros"
                    disabled
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputDecimal
                    name="valorTotalParcelasEJurosSelecionadas"
                    label="Val. Total (Parc. + Juros)"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={2} lg={4} md={6} sm={6}>
                  <InputDate
                    name="dataLiquidacao"
                    label="Data Liquidação"
                    onBlurCia={async () => {
                      handleSugerirDataContabil();
                      await handleCalculaJurosLiquidacao(
                        FormaCalculaJurosEnum.jurosSimples
                      );
                      await handleCalculaValoresTotais();
                      formRef.current.setFieldValue(
                        'valorLiquidacao',
                        formRef.current.getFieldValue(
                          'valorTotalParcelasEJurosSelecionadas'
                        )
                      );
                    }}
                  />
                </Col>
                <Col xl={2} lg={4} md={6} sm={6}>
                  <InputDate name="dataContabil" label="Data Contábil" />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputSwitch
                    name="considerarDataVencimento"
                    label="Considerar Data Vencimento"
                    ativo="Sim"
                    inativo="Não"
                    onChange={async () => {
                      await handleDesabilitarHabilitarCamposData();
                      let somaTeste = 0;
                      listaDados.forEach((item) => {
                        somaTeste += Number(item.valorLiquidacao);
                      });
                      formRef.current.setFieldValue(
                        'valorLiquidacao',
                        somaTeste
                      );
                    }}
                  />
                </Col>
                <Col xl={4} lg={4} md={6} sm={6}>
                  <InputDecimal
                    name="valorLiquidacao"
                    label="Valor Liquidação"
                    onBlurCia={async (ev, props) => {
                      if (
                        ev.target.value.ConverterParaNumber() !==
                        props.valorAnteriorOnBlur.ConverterParaNumber()
                      ) {
                        await handleCalculaJurosLiquidacao(
                          FormaCalculaJurosEnum.ambos
                        );
                        await handleCalculaValoresTotais();
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputAutoCompleteFormaPagamento
                    name="idFormaPagamento"
                    label="Forma de Pagamento"
                    nomeObjeto="formaPagamento"
                    disabled={disableCampos}
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputAutoCompletePortador
                    name="idPortador"
                    label="Portador"
                    nomeObjeto="portador"
                    disabled={disableCampos}
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputAutoCompleteContaTipoAdiantamentoEnum
                    idPessoa={idPessoa}
                    nomeObjeto="contaAdiantamento"
                    name="idContaAdiantamento"
                    label="Adiantamento"
                    placeholder="Adiantamento"
                    onChangeItemAtual={(ev) => {
                      handleCalculaLiquidacaoComAdiantamento(ev.itemAtual);
                    }}
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={6}>
                  <InputInteiro
                    name="quantidadeParcelasSelecionadas"
                    label="Quant. de Parcelas Selecionadas"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <Tabela style={{ overflow: 'auto' }}>
                    <Table striped hover bordered variant="light">
                      <thead>
                        <tr>
                          <th className="lista-texto" style={{ width: '9%' }}>
                            <span>Tipo</span>
                          </th>
                          <th className="lista-valor" style={{ width: '6%' }}>
                            <span>Número</span>
                          </th>
                          <th className="lista-valor" style={{ width: '6%' }}>
                            <span>Parcela</span>
                          </th>
                          <th className="lista-data" style={{ width: '9%' }}>
                            <span>Data Hora Emissao</span>
                          </th>
                          <th className="lista-data" style={{ width: '9%' }}>
                            <span>Data Vencimento</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Parcela</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Aberto Orig.</span>
                          </th>
                          <th className="lista-valor" style={{ width: '11%' }}>
                            <span>Val. Juros</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Tot. Parcela</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Tot. Mov.</span>
                          </th>
                          <th className="lista-valor" style={{ width: '10%' }}>
                            <span>Val. Liquid.</span>
                          </th>
                          <th className="lista-valor" style={{ width: '9%' }}>
                            <span>Val. Tot. Aberto</span>
                          </th>
                          <th className="lista-valor" style={{ width: '12%' }}>
                            <span>Cliente/Fornecedor</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {listaDados.map((item, index) => {
                          const listaCodigoNomeRazaoSocial: string[] = [];

                          item.conta.listaContaParcelaReversao?.map(
                            // eslint-disable-next-line array-callback-return
                            (data: any) => {
                              const valor = `${data.contaParcelaOrigem.conta.pessoa.codigo} - ${data.contaParcelaOrigem.conta.pessoa.nomeRazaoSocial}`;

                              if (!listaCodigoNomeRazaoSocial.includes(valor)) {
                                listaCodigoNomeRazaoSocial.push(valor);
                              }
                            }
                          );

                          return (
                            <tr key={v4()}>
                              <td
                                className="lista-texto"
                                style={{
                                  color:
                                    item.conta.tipo === TipoContaEnum.receber
                                      ? 'green'
                                      : 'red',
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.conta.tipo}
                              </td>
                              <td className="lista-valor">
                                {item.numeroDocumento}
                              </td>
                              <td className="lista-texto">
                                {`${item.sequencia}/${item.conta.quantidadeParcelas}`}
                              </td>
                              <td className="lista-texto">
                                {FormatarDataHoraParaPtBr(
                                  item.conta.dataHoraEmissao
                                )}
                              </td>
                              <td className="lista-texto">
                                {FormatarDataHoraParaPtBr(item.dataVencimento)}
                              </td>
                              <td className="lista-valor">
                                {Number(item.valorParcela).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalAbertoOriginal
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                <InputTabelaDecimal
                                  name="valorJurosSoma"
                                  obterRef={(instance) => {
                                    if (
                                      instance.current &&
                                      listaConteudoRef.current
                                    ) {
                                      listaConteudoRef.current[index] =
                                        instance.current;
                                    }
                                  }}
                                  casasDecimais={4}
                                  valorPadrao={
                                    item.valorJurosSoma &&
                                    item.valorJurosSoma >= 0
                                      ? item.valorJurosSoma
                                      : 0
                                  }
                                  onChange={() => {
                                    item.formaCalculoJurosManual = true;
                                  }}
                                  onBlurCia={async (ev) => {
                                    handleJurosAlteradoManualmente(ev, index);
                                  }}
                                  disabled={disableCampos}
                                />
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalParcela
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalMovimentos
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                <InputTabelaDecimal
                                  valorPadrao={
                                    item.valorLiquidacao &&
                                    item.valorLiquidacao >= 0
                                      ? item.valorLiquidacao
                                      : 0
                                  }
                                  onBlurCia={async (ev) => {
                                    const adiantamentoSelecionado =
                                      formRef.current.getFieldValueNomeObjeto(
                                        'contaAdiantamento'
                                      );
                                    if (adiantamentoSelecionado)
                                      handleValorLiquidacaoComAdiantamento(
                                        String(
                                          ev.valorAnteriorOnBlur
                                        ).ConverterParaNumber(),
                                        index,
                                        adiantamentoSelecionado
                                      );
                                    else {
                                      await handleCalcularValorLista(
                                        String(ev.valor).ConverterParaNumber(),
                                        String(
                                          ev.valorAnteriorOnBlur
                                        ).ConverterParaNumber(),
                                        index
                                      );
                                      await handleCalculaJurosLiquidacao(
                                        FormaCalculaJurosEnum.ambos
                                      );
                                    }
                                    await handleCalculaValoresTotais();
                                  }}
                                  onChange={(ev) => {
                                    item.valorLiquidacao = String(
                                      ev.currentTarget.value
                                    ).ConverterParaNumber();
                                  }}
                                  name="valorLiquidacao"
                                  obterRef={(instance) => {
                                    if (
                                      instance.current &&
                                      listaConteudoRef.current
                                    ) {
                                      listaConteudoRef.current[index] =
                                        instance.current;
                                    }
                                  }}
                                  casasDecimais={4}
                                />
                              </td>
                              <td className="lista-valor">
                                {Number(
                                  item.valorTotalAberto
                                ).FormatarParaPtBr()}
                              </td>
                              <td className="lista-valor">
                                {item.conta.pessoa
                                  ? `${item.conta.pessoa.codigo}-${item.conta.pessoa.nomeRazaoSocial}`
                                  : ''}
                              </td>
                              <td className="lista-valor">
                                {listaCodigoNomeRazaoSocial.length > 0
                                  ? listaCodigoNomeRazaoSocial.map((valor) => {
                                      return (
                                        <div key={valor}>{`${valor};`}</div>
                                      );
                                    })
                                  : ''}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tabela>
                </Col>
              </Row>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleClickVoltar}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn-padrao btn-verde btn-adicionar"
              type="button"
              onClick={handleClickSalvar}
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>Salvar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default FinanceiroDetalhe;
