import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { BooleanEnum, FormatarEnum, TipoChequeEnum } from '@elogestor/util';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import Textarea from '../../../../../Componentes/Inputs/Textarea';

import { IInputIntervaloValoresRef } from '../../../../../Componentes/Inputs/InputIntervaloValores';
import InputSwitch from '../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteTagBanco from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagBanco';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputDate from '../../../../../Componentes/Inputs/InputDate';
import Select from '../../../../../Componentes/Select';
import { SimNao } from '../../../../Configuracao/Parametro/Detalhe/Tipos';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  const [labelTipoCheque, setLabelTipoCheque] = useState('Recebido de');

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const numero = parametros.pesquisaAvancada?.numero;
      if (numero) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Número',
          valor: numero,
        });
      }

      const dataEmissao = parametros.pesquisaAvancada?.dataEmissao;
      if (dataEmissao) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data de Emissão',
          valor: dataEmissao,
        });
      }

      const dataRecebimentoOuPagamento =
        parametros.pesquisaAvancada?.dataRecebimentoOuPagamento;
      if (dataRecebimentoOuPagamento) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Data de Recebimento ou Pagamento',
          valor: dataRecebimentoOuPagamento,
        });
      }

      const dataPreDatado = parametros.pesquisaAvancada?.dataPreDatado;
      if (dataPreDatado) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Pré-datado',
          valor: dataPreDatado,
        });
      }

      const tipo = parametros.pesquisaAvancada?.tipo;
      if (tipo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo',
          valor: tipo,
        });
      }

      const valor = parametros.pesquisaAvancada?.valor;
      if (valor) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Valor',
          valor,
        });
      }

      const emissor = parametros.pesquisaAvancada?.emissor;
      if (emissor) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Emissor',
          valor: emissor,
        });
      }

      const emitido = parametros.pesquisaAvancada?.listaPessoas;
      if (emitido) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Emitido',
          valor: emitido.join(', '),
        });
      }

      const bancos = parametros.pesquisaAvancada?.listaBancos;
      if (bancos) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Banco',
          valor: bancos.join(', '),
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Row className="mb-1">
                <Col lg={3} sm={12}>
                  <InputInteiroNulavel name="numero" label="Número" />
                </Col>

                <Col lg={3} sm={12}>
                  <InputDate name="dataEmissao" label="Data Emissão" />
                </Col>

                <Col lg={3} sm={12}>
                  <InputDate
                    name="dataRecebimentoOuPagamento"
                    label="Data Recebimento ou Pagamento"
                  />
                </Col>

                <Col lg={3} sm={12}>
                  <InputDate name="dataPreDatado" label="Pré-datado" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={6} sm={12}>
                  <Select
                    name="tipo"
                    label="Tipo"
                    options={FormatarEnum({
                      enumObj: TipoChequeEnum,
                    })}
                    onChange={(event) => {
                      if (event) {
                        const { value } = event.target;

                        if (value === TipoChequeEnum.receber) {
                          setLabelTipoCheque('Recebido de');
                        } else {
                          setLabelTipoCheque('Emitido para');
                        }
                      }
                    }}
                  />
                </Col>

                <Col lg={6} sm={12}>
                  <Input name="valor" label="Valor" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={12} sm={12}>
                  <Input name="emissor" label="Emissor" />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={12} sm={12}>
                  <InputAutoCompleteTagCliente
                    name="listaPessoas"
                    label={labelTipoCheque}
                    nomeObjeto="pessoa"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={12} sm={12}>
                  <InputAutoCompleteTagBanco
                    label="Bancos"
                    name="listaBancos"
                    nomeObjeto="banco"
                  />
                </Col>
              </Row>

              <Row className="mb-1">
                <Col lg={2}>
                  <Select
                    options={FormatarEnum({
                      enumObj: BooleanEnum,
                      nullavel: true,
                    })}
                    name="compensado"
                    label="Compensado"
                  />
                </Col>
              </Row>

              {/* <Row className="mb-1">
                  <Textarea
                    name="observacoes"
                    label="Observações"
                    maxLength={500}
                  />
                </Row> */}
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
