import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import * as Yup from 'yup';
import {
  FormatarEnum,
  IContasReceberPagarFiltroRelatorioFrontend,
  TipoContaEnum,
} from '@elogestor/util';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../../Componentes/SubHeaderGeral';
import { Container, DivisorRelatorio, OpcoesRelatorio } from './styles';
import InputRadio from '../../../../../Componentes/Inputs/InputRadio';
import BtnVoltar from '../../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../../Componentes/SubHeaderGeral/ContainerButtons';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import InputPeriodoDataHora, {
  IInputPeriodoDataHoraRef,
} from '../../../../../Componentes/Inputs/InputPeriodoDataHora';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import Select from '../../../../../Componentes/Select';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import InputAutoCompleteTagPortador from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagPortador';
import InputAutoCompleteTagFormaPagamento from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import InputAutoCompleteTagBandeiraCartao from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagBandeiraCartao';
import InputAutoCompleteTagSituacaoContaEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagSituacaoContaEnum';
import InputAutoCompleteTagCategoria from '../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagCategoria';
import InputAutoCompleteTagEmpresa from '../../../../../Componentes/Inputs/AutoCompleteTag/Configuracao/InputAutoCompleteTagEmpresa';
import { UseAuth } from '../../../../../Hooks/Auth';
import InputPeriodoData from '../../../../../Componentes/Inputs/InputPeriodoData';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import InputDate from '../../../../../Componentes/Inputs/InputDate';
import InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum';
import { IInputAutoCompleteClienteRef } from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteCliente';
import { IInputAutoCompleteFornecedorRef } from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteFornecedor';
import InputAutoCompleteTagCliente from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagCliente';
import InputAutoCompleteTagFornecedor from '../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagFornecedor';

interface IMovimentacoesContasReceberPagarRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const MovimentacoesContasReceberPagar: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();
  const { empresa } = UseAuth();

  const formRef = useRef<IFormCiaHandles>(null);
  const periodoDataHoraEmissaoRef = useRef<IInputPeriodoDataHoraRef>(null);

  const [loading, setLoading] = useState(false);

  const inputAutoCompleteClienteRef =
    useRef<IInputAutoCompleteClienteRef>(null);

  const inputAutoCompleteFornecedorRef =
    useRef<IInputAutoCompleteFornecedorRef>(null);

  const [listaIdEmpresa, setListaIdEmpresa] = useState<string[]>([]);

  const [isTipoContaReceber, setIsTipoContaReceber] = useState(true);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] = useState(
    'ContasReceberPagarParcelasPorEmissao'
  );
  const handleValidarDataHora = useCallback(
    async (data: any): Promise<boolean> => {
      try {
        const schema = Yup.object().shape({
          dataHoraEmissaoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora inicial',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data inicial',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataInicial() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraInicial()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informa a Data hora e Emissão Inicial!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelasPorEmissao' && !value
                );
              },
            }),

          dataHoraEmissaoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe a Hora final',
              test: () => {
                if (
                  !!periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informe a Data final',
              test: () => {
                if (
                  !periodoDataHoraEmissaoRef.current?.getDataFinal() &&
                  !!periodoDataHoraEmissaoRef.current?.getHoraFinal()
                ) {
                  return false;
                }
                return true;
              },
            })
            .test({
              message: 'Informa a Data hora e Emissão Final!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelasPorEmissao' && !value
                );
              },
            }),

          dataVencimentoInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe Data Vencimento Inicial!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelaPorVencimento' && !value
                );
              },
            }),
          dataVencimentoFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe Data Vencimento Final!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelaPorVencimento' && !value
                );
              },
            }),
          dataInicial: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe Data Inicial!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelaPorLiquidacaoContabil' && !value
                );
              },
            }),
          dataFinal: Yup.mixed()
            .nullable()
            .test({
              message: 'Informe Data Final!',
              test: (value: any) => {
                return !(
                  inputOpcaoRelatorio ===
                    'ContasReceberPagarParcelaPorLiquidacaoContabil' && !value
                );
              },
            }),
        });

        await schema.validate(data, { abortEarly: false });
        return true;
      } catch (error) {
        const errors = GetValidationErrors(error);
        formRef.current?.setErrors(errors);
        return false;
      }
    },
    [inputOpcaoRelatorio]
  );

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IMovimentacoesContasReceberPagarRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IContasReceberPagarFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IMovimentacoesContasReceberPagarRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IContasReceberPagarFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IMovimentacoesContasReceberPagarRelatorioDados;

      const pesquisaValida = await handleValidarDataHora(dados);
      if (!pesquisaValida) {
        setLoading(false);
        return;
      }

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dados as IContasReceberPagarFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidarDataHora, redirecionar]);

  useEffect(() => {
    const dataFinal = new Date().UltimaHoraDia();
    const date = new Date();
    const dataInicial = new Date(date.getFullYear(), date.getMonth(), 1);

    formRef.current?.setDataInicial({
      opcaoRelatorio: 'ContasReceberPagarParcelasPorEmissao',
      dataHoraEmissaoInicial: dataInicial,
      dataHoraEmissaoFinal: dataFinal,
      dataVencimentoInicial: dataInicial,
      dataVencimentoFinal: dataFinal,
      dataInicial,
      dataFinal,
      tipoConta: TipoContaEnum.receber,
      tipoData: 'Data Liquidação',
      listaSituacaoParcela: [
        { descricao: 'Aberta' },
        { descricao: 'Parcialmente Liquidada' },
        { descricao: 'Liquidada' },
      ],
      listaTiposLancamento: [{ descricao: 'Conta' }],
      listaEmpresas: [
        { nomeRazaoSocial: empresa.nomeRazaoSocial, id: empresa.id },
      ],
    });
  }, [empresa.id, empresa.nomeRazaoSocial, formRef]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Contas (Receber/Pagar)
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Por Emissão',
                        value: 'ContasReceberPagarParcelasPorEmissao',
                      },
                      {
                        title: 'Por Cliente/Fornec. e Emissão',
                        value:
                          'ContasReceberPagarParcelasPorClienteFornecedorEmissao',
                      },
                      {
                        title:
                          'Por Cliente/Fornec. e Emissão com Data Liquidacao',
                        value:
                          'ContasReceberPagarParcelasPorClienteFornecedorEmissaoLiquidacao',
                      },
                      {
                        title: 'Por Vencimento',
                        value: 'ContasReceberPagarParcelasPorVencimento',
                      },
                      {
                        title: 'Por Vencimento com Portador',
                        value:
                          'ContasReceberPagarParcelasPorVencimentoPortador',
                      },
                      {
                        title: 'Por Cliente/Fornec. e Vencimento',
                        value:
                          'ContasReceberPagarParcelasPorClienteFornecedorVencimento',
                      },
                      {
                        title:
                          'Por Cliente/Fornec. e Vencimento com Data Liquidacao',
                        value:
                          'ContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacao',
                      },
                      {
                        title: 'Por Liquidação ou Contábil',
                        value:
                          'ContasReceberPagarParcelasPorLiquidacaoContabil',
                      },
                      {
                        title:
                          'Por Liquidação ou Contábil com Juros, Descontos e Despesas',
                        value:
                          'ContasReceberPagarPorLiquidacaoContabilComJurosRelatorio',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.currentTarget.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorEmissao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorEmissaoLiquidacao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorEmissao'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputPeriodoDataHora
                      labelDataHoraInicial="Data Emissão Inicial"
                      labelDataHoraFinal="Data Emissão Final"
                      nomeDataHoraInicial="dataHoraEmissaoInicial"
                      nomeDataHoraFinal="dataHoraEmissaoFinal"
                      ref={periodoDataHoraEmissaoRef}
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorVencimento' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorVencimentoPortador' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorVencimento' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacao'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputPeriodoData
                      labelDataInicial="Data Vencimento Inicial"
                      labelDataFinal="Data Vencimento Final"
                      nomeDataInicial="dataVencimentoInicial"
                      nomeDataFinal="dataVencimentoFinal"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorLiquidacaoContabil' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarPorLiquidacaoContabilComJurosRelatorio'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={12} sm={12}>
                    <InputPeriodoData
                      labelDataInicial="Data Inicial"
                      labelDataFinal="Data Final"
                      nomeDataInicial="dataInicial"
                      nomeDataFinal="dataFinal"
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorVencimento' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorVencimentoPortador' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorVencimento' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorEmissao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorEmissaoLiquidacao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorEmissao' /* ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarPorLiquidacaoContabilComJurosRelatorio' */
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={6} sm={8}>
                    <InputDate
                      label="Data Final Posição Liquidacao"
                      name="dataFinalPosicaoLiquidacao"
                    />
                  </Col>
                  <Col xl={6} lg={8}>
                    <InputAutoCompleteTagSituacaoContaEnum
                      label="Situação"
                      name="listaSituacaoParcela"
                    />
                  </Col>
                </Row>

                <Row
                  style={{
                    display:
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorVencimento' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorVencimentoPortador' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorVencimento' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorEmissao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorEmissaoLiquidacao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorVencimentoLiquidacao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarPorLiquidacaoContabilComJurosRelatorio' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorClienteFornecedorEmissao' ||
                      inputOpcaoRelatorio ===
                        'ContasReceberPagarParcelasPorLiquidacaoContabil'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <Col xl={6} sm={4}>
                    <Select
                      name="tipoData"
                      label="Tipo de Data"
                      options={[
                        {
                          title: 'Data Liquidação',
                          value: 'Data Liquidação',
                        },
                        {
                          title: 'Data Contábil',
                          value: 'Data Contábil',
                        },
                      ]}
                    />
                  </Col>
                  <Col xl={6} lg={4}>
                    <Select
                      label="Tipo"
                      name="tipoConta"
                      options={FormatarEnum({
                        enumObj: TipoContaEnum,
                      })}
                      onChange={(event) => {
                        setIsTipoContaReceber(
                          event.target.value === TipoContaEnum.receber
                        );
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    {isTipoContaReceber ? (
                      <InputAutoCompleteTagCliente
                        label="Clientes / Fornecedores"
                        placeholder="Clientes / Fornecedores"
                        name="listaClienteFornecedor"
                      />
                    ) : (
                      <InputAutoCompleteTagFornecedor
                        label="Clientes / Fornecedores"
                        placeholder="Clientes / Fornecedores"
                        name="listaClienteFornecedor"
                      />
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Parcela)"
                      name="listaPortadoresParcela"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagPortador
                      label="Portadores (Liquidação)"
                      name="listaPortadoresLiquidacao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFormaPagamento
                      label="Formas de Pagamento (Parcela)"
                      name="listaFormasPagamentoParcela"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagFormaPagamento
                      label="Formas de Pagamento (Liquidação)"
                      name="listaFormasPagamentoLiquidacao"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagBandeiraCartao
                      label="Cartões"
                      name="listaCartoes"
                    />
                  </Col>
                  <Col xl={6} md={6} sm={6} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagCategoria
                      label="Categorias"
                      name="listaCategorias"
                      listaIdEmpresa={listaIdEmpresa}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagTipoContaPrevisaoAdiantamentoEnum
                      label="Tipos Lançamento"
                      name="listaTiposLancamento"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} md={12} sm={12} style={{ marginTop: 10 }}>
                    <InputAutoCompleteTagEmpresa
                      label="Empresas"
                      name="listaEmpresas"
                      onChangeListaItemAtual={(ev) => {
                        if (ev.listaItemAtual) {
                          if (ev.listaItemAtual.length === 0) {
                            setListaIdEmpresa([]);
                          } else {
                            ev.listaItemAtual.forEach((it) =>
                              setListaIdEmpresa((prevState) => {
                                const lista = prevState.filter(
                                  (item) => item !== it.id
                                );
                                lista.push(it.id);

                                return lista;
                              })
                            );
                          }
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default MovimentacoesContasReceberPagar;
