"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var PlataformaIntegracaoEnum;
(function (PlataformaIntegracaoEnum) {
    PlataformaIntegracaoEnum["tray"] = "Tray";
    PlataformaIntegracaoEnum["wake"] = "Wake";
    PlataformaIntegracaoEnum["fw7"] = "FW7";
    PlataformaIntegracaoEnum["simplo7"] = "Simplo7";
})(PlataformaIntegracaoEnum || (PlataformaIntegracaoEnum = {}));
exports.default = PlataformaIntegracaoEnum;
