import api from '../../../../../Comum/Services/Api';

const rota =
  'fiscal/tributacao/regra-escolha-aliquota/obter-conforme-criterios';

interface IRegraEscolhaAliquotaObterConformeCriteriosComunicadorShow {
  params: {
    dataPesquisaVigencia: Date;
    idProduto: string;
    idPessoa: string;
    destinado: string;
    idTipoNota?: string;
    idTipoNotaMotivo?: string;
    idCodigoSituacaoTributaria?: string;

    calcularIcms?: boolean;
    calcularSt?: boolean;
    calcularIpi?: boolean;
    calcularPisCofins?: boolean;
    calcularPisCofinsRetido?: boolean;
    calcularCreditoIcmsSn?: boolean;

    utilizaIcmsOperacaoInterestadual: boolean;
    calcularIcmsOperacaoInterestadual: boolean;
    idOrigemIcms?: string;
    calcularFunrural?: boolean;
    calcularDesoneracaoIcms?: boolean;
  };
}

class RegraEscolhaAliquotaObterConformeCriteriosComunicador {
  public async show(
    params: IRegraEscolhaAliquotaObterConformeCriteriosComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}`, { params: params.params });

    return response.data;
  }
}

export default new RegraEscolhaAliquotaObterConformeCriteriosComunicador();
