/* eslint-disable no-nested-ternary */
import {
  AbrirObservacaoClienteEnum,
  AppErro,
  CalculoBaseComissao,
  ConverterParaNumberOrNull,
  DestinadoEnum,
  IItensRateioImpostoCalculoPorCstRetorno,
  INotaFiscalSaidaComercialRepresentanteLista,
  ItensRateioImpostoCalculoPorCst,
  RateioComCasaDecimalCalculo,
  TipoMercadoEnum,
  ValorTotalDocumentoCalculo,
} from '@elogestor/util';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaItem } from './ListaItemContext';
import SelecaoEnderecoPessoaDetalhe from '../PessoaTab/SelecaoEnderecoPessoaDetalhe';
import IEnderecoPessoa from '../PessoaTab/SelecaoEnderecoPessoaDetalhe/Interface/IEnderecoPessoa';
import ObterNotaFiscalSaidaItensParaCalculoRateiosComunicador from '../../../../../../Comunicador/Comercial/Vendas/NotaFiscalSaida/Comunicador/ObterNotaFiscalSaidaItensParaCalculoRateiosComunicador';
import PessoaObterDadosCadastroComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosCadastroComunicador';
import RegraCalculoComissaoObterConformeCriteriosComunicador from '../../../../../../Comunicador/Comercial/Comissao/Comunicador/RegraCalculoComissaoObterConformeCriteriosComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PessoaObterDadosPadraoComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosPadraoComunicador';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import AbrirObservacaoCliente from '../../../../Pessoas/PessoaClienteObservacao/AbrirObservacaoCliente';

interface INotaFiscalSaidaContext {
  validador: boolean;
  setFormaRateioValorFrete(value: boolean): void;
  setFormaRateioValorSeguro(value: boolean): void;
  setFormaRateioValorOutrasDespesas(value: boolean): void;
  setFormaRateioValorDesconto(value: boolean): void;
  atualizarRateiosListaItem(): void;
  atualizarDadosClienteConformeCadastro(): void;
  obterDadosPadraoCliente(): void;

  listaRepresentante: INotaFiscalSaidaComercialRepresentanteLista[];
  setListaRepresentante: React.Dispatch<
    React.SetStateAction<INotaFiscalSaidaComercialRepresentanteLista[]>
  >;
}

const NotaFiscalSaidaContext = createContext<INotaFiscalSaidaContext>(
  {} as INotaFiscalSaidaContext
);

const NotaFiscalSaidaHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaItem } = UseListaItem();
  const { formRef, getIdDetalheRegistro, setLoading, loading } = UseForm();
  const { abrirJanela } = UseAlerta();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [showModalSelecaoEndereco, setShowModalSelecaoEndereco] =
    useState(false);
  const [listaEndereco, setListaEndereco] = useState<IEnderecoPessoa[]>([]);
  const [listaRepresentante, setListaRepresentante] = useState<
    INotaFiscalSaidaComercialRepresentanteLista[]
  >([]);
  const [showObservacaoCliente, setShowObservacaoCliente] = useState(false);
  const [observacaoCliente, setObservacaoCliente] = useState({
    idCliente: '',
    observacoes: '',
  });
  const parametros = UseParametros();

  const handleSetFormaRateioValorFrete = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('freteFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorSeguro = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('seguroFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorOutrasDespesas = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue(
        'outrasDespesasFormaCalculoPorRateio',
        value
      );
    },
    [formRef]
  );

  const handleSetFormaRateioValorDesconto = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('descontoFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleAtualizarTotais = useCallback(() => {
    let totalProdutos = 0;
    let totalBruto = 0;

    if (listaItem.length === 0) {
      const valorFrete = formRef.current?.getFieldValue('valorFrete');
      const valorSeguro = formRef.current?.getFieldValue('valorSeguro');
      const valorOutrasDespesas = formRef.current?.getFieldValue(
        'valorOutrasDespesas'
      );
      const valorDesconto = formRef.current?.getFieldValue('valorDesconto');

      const valorTotal =
        valorFrete + valorSeguro + valorOutrasDespesas - valorDesconto;

      totalProdutos = valorTotal;
      totalBruto = valorTotal;
    } else {
      [totalProdutos, totalBruto] = listaItem.reduce(
        (acumulador, item) => {
          acumulador[0] += Number(item.valorTotalProduto) || 0;
          acumulador[1] += Number(item.valorTotalBruto) || 0;
          return acumulador;
        },
        [0, 0]
      );
    }

    formRef.current?.setFieldValue('valorTotalProdutos', totalProdutos);
    formRef.current?.setFieldValue('valorTotalBruto', totalBruto);
  }, [formRef, listaItem]);

  const handleAtualizarRateiosListaItem = useCallback(async () => {
    if (!idDetalheRegistro) return;

    try {
      const response =
        await ObterNotaFiscalSaidaItensParaCalculoRateiosComunicador.index({
          id: idDetalheRegistro,
        });

      if (
        !response.listaNotaFiscalSaidaItem ||
        response.listaNotaFiscalSaidaItem.length !== listaItem.length
      ) {
        throw new AppErro({
          mensagem: 'A nota fiscal de saída esta desatualizada!',
        });
      }

      const valorFreteAtual = formRef.current?.getFieldValue('valorFrete') ?? 0;
      const valorSeguroAtual =
        formRef.current?.getFieldValue('valorSeguro') ?? 0;
      const valorOutrasDespesasAtual =
        formRef.current?.getFieldValue('valorOutrasDespesas') ?? 0;
      const valorDescontoAtual =
        formRef.current?.getFieldValue('valorDesconto') ?? 0;

      if (listaItem.length === 0) {
        const valorTotal =
          valorFreteAtual +
          valorSeguroAtual +
          valorOutrasDespesasAtual -
          valorDescontoAtual;

        formRef.current?.setFieldValue('valorTotalProdutos', valorTotal);
        formRef.current?.setFieldValue('valorTotalBruto', valorTotal);
        return;
      }

      const possuiItemGerarFaturamento = listaItem.some(
        (item) => item.notaFiscalSaidaItemComercial?.gerarFaturamento
      );

      const listaItemValorTotalProduto = listaItem.map((item) => {
        if (item.notaFiscalSaidaItemComercial?.gerarFaturamento)
          return Number(item.valorTotalProduto);

        return 0;
      });

      const listaValorFrete = RateioComCasaDecimalCalculo({
        valor: valorFreteAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorSeguro = RateioComCasaDecimalCalculo({
        valor: valorSeguroAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorOutrasDespesas = RateioComCasaDecimalCalculo({
        valor: valorOutrasDespesasAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorDesconto = RateioComCasaDecimalCalculo({
        valor: valorDescontoAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaItensRateio =
        response.listaNotaFiscalSaidaItem?.map((item, index) => {
          return {
            id: item.id,
            gerarFaturamento:
              item.notaFiscalSaidaItemComercial?.gerarFaturamento,
            valorFreteItem:
              (possuiItemGerarFaturamento &&
                item.notaFiscalSaidaItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorFrete[index]
                : item.valorFrete
                  ? Number(item.valorFrete)
                  : null,
            valorSeguroItem:
              (possuiItemGerarFaturamento &&
                item.notaFiscalSaidaItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorSeguro[index]
                : item.valorSeguro
                  ? Number(item.valorSeguro)
                  : null,
            valorOutrasDespesasItem:
              (possuiItemGerarFaturamento &&
                item.notaFiscalSaidaItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorOutrasDespesas[index]
                : item.valorOutrasDespesas
                  ? Number(item.valorOutrasDespesas)
                  : null,
            valorDescontoItem:
              (possuiItemGerarFaturamento &&
                item.notaFiscalSaidaItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorDesconto[index]
                : item.valorDesconto
                  ? Number(item.valorDesconto)
                  : null,
            quantidadeItem: ConverterParaNumberOrNull(item.quantidade),
            valorUnitarioItem: ConverterParaNumberOrNull(item.valorUnitario),

            icms: {
              valorIpiItem: null,
              destinadoItem: item.destinado as DestinadoEnum,

              cstCsosn: item.notaFiscalSaidaItemImpostoIcms?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalSaidaItemImpostoIcms?.situacaoTributaria
                    ?.codigo
                : '',
              tipoMercado: item.operacaoFiscal?.tipoMercado as TipoMercadoEnum,

              // #region ICMS

              calcularIcms: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularIcms
              ),
              modalidadeBaseCalculoIcms: item.notaFiscalSaidaItemImpostoIcms
                ?.modalidadeBaseCalculoIcms
                ? item.notaFiscalSaidaItemImpostoIcms?.modalidadeBaseCalculoIcms
                : null,
              aliquotaInterestadualIcms: null,

              percentualReducaoBaseCalculoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcms
              ),
              baseCalculoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoIcms
              ),
              aliquotaIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaIcms
              ),
              valorIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorIcms
              ),

              baseCalculoFcpIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoFcpIcms
              ),
              aliquotaFcpIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaFcpIcms
              ),
              valorFcpIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorFcpIcms
              ),

              percentualDiferimentoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.percentualDiferimentoIcms
              ),
              valorSemDiferimentoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorSemDiferimentoIcms
              ),
              valorDiferidoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorDiferidoIcms
              ),

              valorUnitarioPautaIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorUnitarioPautaIcms
              ),
              valorTotalPautaIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorTotalPautaIcms
              ),

              calcularIcmsEfetivo: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularIcmsEfetivo
              ),
              percentualReducaoBaseCalculoIcmsEfetivo:
                ConverterParaNumberOrNull(
                  item.notaFiscalSaidaItemImpostoIcms
                    ?.percentualReducaoBaseCalculoIcmsEfetivo
                ),
              baseCalculoIcmsEfetivo: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoIcmsEfetivo
              ),
              aliquotaIcmsEfetivo: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaIcmsEfetivo
              ),
              valorIcmsEfetivo: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorIcmsEfetivo
              ),

              calcularIcmsSubstituto: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularIcmsSubstituto
              ),
              valorUnitarioIcmsSubstituto: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorUnitarioIcmsSubstituto
              ),
              valorIcmsSubstituto: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorIcmsSubstituto
              ),

              motivoDesoneracaoIcms: item.notaFiscalSaidaItemImpostoIcms
                ?.motivoDesoneracaoIcms
                ? item.notaFiscalSaidaItemImpostoIcms?.motivoDesoneracaoIcms
                : null,
              calcularDesoneracaoIcms: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularDesoneracaoIcms
              ),
              baseCalculoDesoneracaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoDesoneracaoIcms
              ),
              aliquotaDesoneracaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaDesoneracaoIcms
              ),
              valorDesoneracaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorDesoneracaoIcms
              ),

              baseCalculoSemReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoSemReducaoIcms
              ),
              valorSemReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorSemReducaoIcms
              ),
              baseCalculoReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoReducaoIcms
              ),
              valorReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorReducaoIcms
              ),

              // #endregion ICMS

              // #region ICMS ST

              calcularIcmsSt: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularIcmsSt
              ),
              modalidadeBaseCalculoIcmsSt: item.notaFiscalSaidaItemImpostoIcms
                ?.modalidadeBaseCalculoIcmsSt
                ? item.notaFiscalSaidaItemImpostoIcms
                    ?.modalidadeBaseCalculoIcmsSt
                : null,

              percentualMvaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.percentualMvaIcmsSt
              ),
              percentualReducaoMvaIcmsSt: null,
              percentualReducaoBaseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcmsSt
              ),
              aliquotaInterestadualIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaInterestadualIcmsSt
              ),
              aliquotaInternaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaInternaIcmsSt
              ),
              baseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoIcmsSt
              ),
              valorIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorIcmsSt
              ),

              baseCalculoFcpIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoFcpIcmsSt
              ),
              aliquotaFcpIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaFcpIcmsSt
              ),
              valorFcpIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorFcpIcmsSt
              ),

              calcularIcmsStRetido: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularIcmsStRetido
              ),
              baseCalculoIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoIcmsStRetido
              ),
              baseCalculoUnitariaProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms
                  ?.baseCalculoUnitariaProdutoIcmsStRetido
              ),
              aliquotaIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaIcmsStRetido
              ),
              valorIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorIcmsStRetido
              ),
              valorUnitarioProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms
                  ?.valorUnitarioProdutoIcmsStRetido
              ),

              baseCalculoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.baseCalculoFcpIcmsStRetido
              ),
              baseCalculoUnitariaProdutoFcpIcmsStRetido:
                ConverterParaNumberOrNull(
                  item.notaFiscalSaidaItemImpostoIcms
                    ?.baseCalculoUnitariaProdutoFcpIcmsStRetido
                ),
              aliquotaFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.aliquotaFcpIcmsStRetido
              ),
              valorFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorFcpIcmsStRetido
              ),
              valorUnitarioProdutoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms
                  ?.valorUnitarioProdutoFcpIcmsStRetido
              ),

              valorUnitarioPautaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorUnitarioPautaIcmsSt
              ),
              valorTotalPautaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorTotalPautaIcmsSt
              ),

              // #endregion ICMS ST

              // #region ICMS SN

              calcularCreditoIcmsSn: Boolean(
                item.notaFiscalSaidaItemImpostoIcms?.calcularCreditoIcmsSn
              ),
              percentualCreditoIcmsSn: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.percentualCreditoIcmsSn
              ),
              valorCreditoIcmsSn: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIcms?.valorCreditoIcmsSn
              ),

              // #endregion ICMS SN
            },

            ipi: {
              calcular: Boolean(item.notaFiscalSaidaItemImpostoIpi?.calcular),
              tipoCalculo: item.notaFiscalSaidaItemImpostoIpi?.tipoCalculo
                ? item.notaFiscalSaidaItemImpostoIpi?.tipoCalculo
                : null,
              cst: item.notaFiscalSaidaItemImpostoIpi?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalSaidaItemImpostoIpi?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadeIpi: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIpi?.quantidadeUnidade
              ),
              valorUnidadeIpi: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIpi?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIpi?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIpi?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoIpi?.valor
              ),
            },

            pis: {
              calcular: Boolean(item.notaFiscalSaidaItemImpostoPis?.calcular),
              tipoCalculo: item.notaFiscalSaidaItemImpostoPis?.tipoCalculo
                ? item.notaFiscalSaidaItemImpostoPis?.tipoCalculo
                : null,
              cst: item.notaFiscalSaidaItemImpostoPis?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalSaidaItemImpostoPis?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadePis: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.quantidadeUnidade
              ),
              valorUnidadePis: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.valor
              ),

              calcularRetido: Boolean(
                item.notaFiscalSaidaItemImpostoPis?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoPis?.valorRetido
              ),
            },

            cofins: {
              calcular: Boolean(
                item.notaFiscalSaidaItemImpostoCofins?.calcular
              ),
              tipoCalculo: item.notaFiscalSaidaItemImpostoCofins?.tipoCalculo
                ? item.notaFiscalSaidaItemImpostoCofins?.tipoCalculo
                : null,
              cst: item.notaFiscalSaidaItemImpostoCofins?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalSaidaItemImpostoCofins?.situacaoTributaria
                    ?.codigo
                : '',
              quantidadeUnidadeCofins: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.quantidadeUnidade
              ),
              valorUnidadeCofins: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.valor
              ),

              calcularRetido: Boolean(
                item.notaFiscalSaidaItemImpostoCofins?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.notaFiscalSaidaItemImpostoCofins?.valorRetido
              ),
            },
          };
        }) || [];

      const listaItemRateioComImpostos =
        ItensRateioImpostoCalculoPorCst(listaItensRateio);

      const listaItensImpostoAproximado: any[] = [];

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];
        const itemRateio = listaItensRateio[i];
        const itemRateioComImpostos = listaItemRateioComImpostos[i];

        const valorTotalBrutoItem = ValorTotalDocumentoCalculo({
          valorSubtotalItem: Number(item.valorTotalProduto),
          valorFreteItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorFreteItem)
              : item.valorFrete
                ? Number(item.valorFrete)
                : 0,
          valorSeguroItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorSeguroItem)
              : item.valorSeguro
                ? Number(item.valorSeguro)
                : 0,
          valorDescontoItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorDescontoItem)
              : item.valorDesconto
                ? Number(item.valorDesconto)
                : 0,
          valorOutrasDespesasItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorOutrasDespesasItem)
              : item.valorOutrasDespesas
                ? Number(item.valorOutrasDespesas)
                : 0,
          valorIcmsSt: itemRateioComImpostos.icms
            ? Number(itemRateioComImpostos.icms?.valorIcmsSt)
            : 0,
          valorFcpIcmsSt: itemRateioComImpostos.icms
            ? Number(itemRateioComImpostos.icms?.valorFcpIcmsSt)
            : 0,
          valorIpi: itemRateioComImpostos.ipi
            ? Number(itemRateioComImpostos.ipi?.valor)
            : 0,

          valorImpostoImportacao: null,
          valorServicos: null,

          valorDesoneracaoIcmsDeduzir: item.notaFiscalSaidaItemImpostoIcms
            ?.deduzirDesoneracaoIcms
            ? Number(item.notaFiscalSaidaItemImpostoIcms?.valorDesoneracaoIcms)
            : null,
        });

        listaItem[i].valorTotalBruto = valorTotalBrutoItem;

        if (
          response.listaNotaFiscalSaidaItem[i]
            .notaFiscalSaidaItemImpostoAproximado?.calcularImpostoAproximado
        ) {
          listaItensImpostoAproximado.push({
            valorFederal:
              valorTotalBrutoItem *
              (Number(
                response.listaNotaFiscalSaidaItem[i]
                  .notaFiscalSaidaItemImpostoAproximado?.aliquotaFederal
              ) /
                100),
            valorEstadual:
              valorTotalBrutoItem *
              (Number(
                response.listaNotaFiscalSaidaItem[i]
                  .notaFiscalSaidaItemImpostoAproximado?.aliquotaEstadual
              ) /
                100),
            valorMunicipal:
              valorTotalBrutoItem *
              (Number(
                response.listaNotaFiscalSaidaItem[i]
                  .notaFiscalSaidaItemImpostoAproximado?.aliquotaMunicipal
              ) /
                100),
          });
        }
      }

      // #region ICMS

      const valorTotalBaseCalculoIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcms);
          return acumulador;
        },
        0
      );

      const valorTotalIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcms);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcms);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcms',
        valorTotalBaseCalculoIcms
      );
      formRef.current?.setFieldValue('valorTotalIcms', valorTotalIcms);
      formRef.current?.setFieldValue('valorTotalFcpIcms', valorTotalFcpIcms);

      // #endregion ICMS

      // #region ICMS ST

      const valorTotalBaseCalculoIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcmsSt);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcmsSt',
        valorTotalBaseCalculoIcmsSt
      );
      formRef.current?.setFieldValue('valorTotalIcmsSt', valorTotalIcmsSt);
      formRef.current?.setFieldValue(
        'valorTotalFcpIcmsSt',
        valorTotalFcpIcmsSt
      );

      // #endregion ICMS ST

      // #region IPI

      const valorTotalBaseCalculoIpi = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalIpi = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIpi',
        valorTotalBaseCalculoIpi
      );
      formRef.current?.setFieldValue('valorTotalIpi', valorTotalIpi);

      // #endregion IPI

      // #region PIS/COFINS

      const valorTotalBaseCalculoPis = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalPis = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPis',
        valorTotalBaseCalculoPis
      );
      formRef.current?.setFieldValue('valorTotalPis', valorTotalPis);

      const valorTotalBaseCalculoCofins = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalCofins = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofins',
        valorTotalBaseCalculoCofins
      );
      formRef.current?.setFieldValue('valorTotalCofins', valorTotalCofins);

      // #endregion PIS

      // #region PIS/COFINS Retido

      const valorTotalBaseCalculoPisRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculoRetido);
          return acumulador;
        },
        0
      );

      const valorTotalPisRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPisRetido',
        valorTotalBaseCalculoPisRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalPisRetido',
        valorTotalPisRetido
      );

      const valorTotalBaseCalculoCofinsRetido =
        listaItemRateioComImpostos.reduce(
          (
            acumulador: number,
            item: IItensRateioImpostoCalculoPorCstRetorno
          ) => {
            acumulador += Number(item.cofins?.baseCalculoRetido);
            return acumulador;
          },
          0
        );

      const valorTotalCofinsRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofinsRetido',
        valorTotalBaseCalculoCofinsRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalCofinsRetido',
        valorTotalCofinsRetido
      );

      // #endregion COFINS

      // #region Funrural

      const valorFunrural = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.funrural?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue('valorFunrural', valorFunrural);

      // #endregion Funrural

      // #region Imposto Aproximado

      const valorTotalFederalImpostoAproximado =
        listaItensImpostoAproximado.reduce((acumulador: number, item: any) => {
          acumulador += Number(item.valorFederal);
          return acumulador;
        }, 0);

      const valorTotalEstadualImpostoAproximado =
        listaItensImpostoAproximado.reduce((acumulador: number, item: any) => {
          acumulador += Number(item.valorEstadual);
          return acumulador;
        }, 0);

      const valorTotalMunicipalImpostoAproximado =
        listaItensImpostoAproximado.reduce((acumulador: number, item: any) => {
          acumulador += Number(item.valorMunicipal);
          return acumulador;
        }, 0);

      formRef.current?.setFieldValue(
        'valorTotalFederalImpostoAproximado',
        valorTotalFederalImpostoAproximado
      );

      formRef.current?.setFieldValue(
        'valorTotalEstadualImpostoAproximado',
        valorTotalEstadualImpostoAproximado
      );

      formRef.current?.setFieldValue(
        'valorTotalMunicipalImpostoAproximado',
        valorTotalMunicipalImpostoAproximado
      );

      // #endregion Imposto Aproximado

      // #region Item Comercial Representante

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];
        if (
          item.notaFiscalSaidaItemComercial &&
          item.notaFiscalSaidaItemComercial.gerarComissao &&
          item.notaFiscalSaidaItemComercial
            .listaNotaFiscalSaidaItemComercialRepresentante
        ) {
          const baseCalculo = CalculoBaseComissao({
            valorTotalBruto: item.valorTotalBruto,
            valorImpostoIcms: item.notaFiscalSaidaItemImpostoIcms
              ?.valorIcmsStEValorFcpIcmsSt
              ? item.notaFiscalSaidaItemImpostoIcms?.valorIcmsStEValorFcpIcmsSt
              : 0,
            valorImpostoIpi: item.notaFiscalSaidaItemImpostoIpi?.valor
              ? item.notaFiscalSaidaItemImpostoIpi?.valor
              : 0,
            valorFrete: valorFreteAtual,
            valorOutrasDespesas: valorSeguroAtual,
            valorSeguro: valorSeguroAtual,
          });

          for (
            let j = 0;
            j <
            item.notaFiscalSaidaItemComercial
              ?.listaNotaFiscalSaidaItemComercialRepresentante?.length;
            j++
          ) {
            const valor =
              item.notaFiscalSaidaItemComercial
                ?.listaNotaFiscalSaidaItemComercialRepresentante[j];
            valor.baseCalculo = baseCalculo;
            valor.valor = baseCalculo * (Number(valor.aliquota) / 100);
          }
        }
      }

      // #endregion Item Comercial Representante

      // #region Comercial Representante

      const listaComercialRepresentante = formRef.current?.getFieldValue(
        'notaFiscalSaidaComercial.listaNotaFiscalSaidaComercialRepresentante'
      );

      if (
        listaComercialRepresentante &&
        listaComercialRepresentante.length > 0
      ) {
        const idPessoa = formRef.current?.getFieldValue(
          'notaFiscalSaidaPessoa.idPessoa'
        );
        const dataHoraEmissao =
          formRef.current?.getFieldValue('dataHoraEmissao');

        listaComercialRepresentante.map(async (comercialRepresentante: any) => {
          let baseCalculo = 0;
          let valor = 0;

          if (listaItem && listaItem.length > 0) {
            await Promise.all(
              listaItem.map(async (item) => {
                if (
                  item &&
                  item.notaFiscalSaidaItemComercial &&
                  item.notaFiscalSaidaItemComercial
                    .listaNotaFiscalSaidaItemComercialRepresentante
                ) {
                  item.notaFiscalSaidaItemComercial?.listaNotaFiscalSaidaItemComercialRepresentante.map(
                    async (itemComercialRepresentante) => {
                      if (
                        comercialRepresentante.idPessoaRepresentante ===
                          itemComercialRepresentante.idPessoaRepresentante &&
                        comercialRepresentante.formaCalculoPorRegra ===
                          itemComercialRepresentante.formaCalculoPorRegra
                      ) {
                        baseCalculo += Number(
                          itemComercialRepresentante.baseCalculo
                        );
                        valor += Number(itemComercialRepresentante.valor);
                      }
                    }
                  );
                }

                if (!comercialRepresentante.id) {
                  const baseCalculoRepresentate = CalculoBaseComissao({
                    valorTotalBruto: item.valorTotalBruto,
                    valorImpostoIcms: item.notaFiscalSaidaItemImpostoIcms
                      ?.valorIcmsStEValorFcpIcmsSt
                      ? item.notaFiscalSaidaItemImpostoIcms
                          ?.valorIcmsStEValorFcpIcmsSt
                      : 0,
                    valorImpostoIpi: item.notaFiscalSaidaItemImpostoIpi?.valor
                      ? item.notaFiscalSaidaItemImpostoIpi?.valor
                      : 0,
                    valorFrete: valorFreteAtual,
                    valorOutrasDespesas: valorOutrasDespesasAtual,
                    valorSeguro: valorSeguroAtual,
                  });

                  baseCalculo += baseCalculoRepresentate;

                  const regraCalculoComissao =
                    await RegraCalculoComissaoObterConformeCriteriosComunicador.show(
                      {
                        params: {
                          idPessoa,
                          idPessoaRepresentante:
                            comercialRepresentante.idPessoaRepresentante,
                          idProduto: item.idProduto,
                          idGrupoProdutoServico: item.produto
                            ? item.produto.idGrupoProdutoServico
                            : null,
                          dataPesquisaVigencia: new Date(dataHoraEmissao),
                          percentualDesconto: 0,
                        },
                      }
                    );

                  valor +=
                    baseCalculoRepresentate *
                    (Number(
                      regraCalculoComissao.regraCalculoComissao
                        .percentualComissao
                    ) /
                      100);
                }
              })
            );
          }

          const aliquota = valor / (baseCalculo === 0 ? 1 : baseCalculo);
          comercialRepresentante.baseCalculo = baseCalculo;
          comercialRepresentante.valor = valor;

          if (comercialRepresentante.formaCalculoPorRegra) {
            comercialRepresentante.aliquota = aliquota * 100;
          }
        });
      }

      // #endregion Comercial Representante

      handleAtualizarTotais();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  }, [formRef, handleAtualizarTotais, idDetalheRegistro, listaItem]);

  useEffect(() => {
    handleAtualizarTotais();
  }, [formRef, handleAtualizarTotais]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaNotaFiscalSaidaItem = formRef.current?.getFieldValue(
      'listaNotaFiscalSaidaItem'
    );

    if (listaNotaFiscalSaidaItem && listaNotaFiscalSaidaItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração da UF do Cliente, as informações tributárias e
            valores podem estar incorretos. Salve para atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  const handleAtualizarDadosClienteConformeCadastro = useCallback(async () => {
    try {
      const pessoa = formRef.current?.getFieldValueNomeObjeto(
        'notaFiscalSaidaPessoa.pessoa'
      );
      const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
        'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla'
      );

      if (!pessoa) return;

      setLoading(true);

      const response = await PessoaObterDadosCadastroComunicador.show({
        id: pessoa.id,
      });

      await formRef.current?.setSemExecutarEvento({
        notaFiscalSaidaPessoa: {
          cnpjCpfVirtual: response.cnpjCpfVirtual,
          inscricaoEstadual: response.inscricaoEstadual,
          inscricaoSuframa: response.inscricaoSuframa,
          documentoIdentificacaoEstrangeiro:
            response.documentoIdentificacaoEstrangeiro,
          nomeRazaoSocial: response.nomeRazaoSocial,
          nomeFantasia: response.nomeFantasia,
        },
      });

      if (response.pessoaEnderecoEntrega.outroEnderecoEntrega) {
        await formRef.current?.setSemExecutarEvento({
          notaFiscalSaidaPessoaEnderecoEntrega: {
            cnpjCpfVirtual: response.pessoaEnderecoEntrega.cnpjCpfVirtual,
            inscricaoEstadual: response.pessoaEnderecoEntrega.inscricaoEstadual,
            nomeRazaoSocial: response.pessoaEnderecoEntrega.nomeRazaoSocial,
            outroEnderecoEntrega:
              response.pessoaEnderecoEntrega.outroEnderecoEntrega,
            cep: response.pessoaEnderecoEntrega.cep,
            cidade: response.pessoaEnderecoEntrega.cidade,
            logradouro: response.pessoaEnderecoEntrega.logradouro,
            numero: response.pessoaEnderecoEntrega.numero,
            bairro: response.pessoaEnderecoEntrega.bairro,
            complemento: response.pessoaEnderecoEntrega.complemento,
          },
        });
      } else {
        await formRef.current?.setSemExecutarEvento({
          notaFiscalSaidaPessoaEnderecoEntrega: {
            cnpjCpfVirtual: null,
            inscricaoEstadual: null,
            nomeRazaoSocial: null,
            outroEnderecoEntrega: null,
            cep: null,
            cidade: null,
            logradouro: null,
            numero: null,
            bairro: null,
            complemento: null,
          },
        });
      }

      if (response.listaPessoaEndereco.length > 1) {
        setShowModalSelecaoEndereco(true);
        setListaEndereco(response.listaPessoaEndereco);
      } else if (response.listaPessoaEndereco.length === 1) {
        await formRef.current?.setSemExecutarEvento({
          notaFiscalSaidaPessoaEndereco: {
            cep: response.listaPessoaEndereco[0].cep,
            cidade: response.listaPessoaEndereco[0].cidade,
            logradouro: response.listaPessoaEndereco[0].logradouro,
            numero: response.listaPessoaEndereco[0].numero,
            bairro: response.listaPessoaEndereco[0].bairro,
            complemento: response.listaPessoaEndereco[0].complemento,
          },
        });

        const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
          'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla'
        );

        if (pessoaUf !== pessoaUfNovo) {
          await handleExibirMensagemAtualizacao();
        }
      }

      if (response.pessoaCliente) {
        const listaNotaFiscalSaidaComercialRepresentante =
          formRef.current?.getFieldValue(
            'notaFiscalSaidaComercial.listaNotaFiscalSaidaComercialRepresentante'
          );
        if (
          response.pessoaCliente.listaRepresentante &&
          response.pessoaCliente.listaRepresentante.length > 0
        ) {
          response.pessoaCliente.listaRepresentante.forEach((valor: any) => {
            const valoresInserir = {
              pessoaRepresentante: {
                id: valor.pessoaRepresentante.id,
                nomeRazaoSocial: valor.pessoaRepresentante.nomeRazaoSocial,
              },
              idPessoaRepresentante: valor.pessoaRepresentante.id,
              baseCalculo: 0,
              aliquota: 0,
              valor: 0,
              formaCalculoPorRegra: true,
            };
            const representanteExiste =
              listaNotaFiscalSaidaComercialRepresentante.find(
                (e: any) =>
                  e.idPessoaRepresentante === valor.pessoaRepresentante.id
              );

            if (!representanteExiste) {
              listaNotaFiscalSaidaComercialRepresentante.push(valoresInserir);
            }
          });
        } else {
          formRef.current?.clearField(
            'notaFiscalSaidaComercial.listaNotaFiscalSaidaComercialRepresentante'
          );
        }

        if (
          listaNotaFiscalSaidaComercialRepresentante &&
          listaNotaFiscalSaidaComercialRepresentante.length > 0
        ) {
          listaNotaFiscalSaidaComercialRepresentante.forEach((val: any) => {
            if (!val.formaCalculoPorRegra) {
              listaNotaFiscalSaidaComercialRepresentante.push(val);
            }
          });
        }

        formRef.current?.setFieldValue(
          'notaFiscalSaidaComercial.listaNotaFiscalSaidaComercialRepresentante',
          listaNotaFiscalSaidaComercialRepresentante
        );
      }

      if (
        parametros.AbrirTelaComObservacoesDoClienteNotaFiscalSaida ===
          AbrirObservacaoClienteEnum.sempreAbrir ||
        (parametros.AbrirTelaComObservacoesDoClienteNotaFiscalSaida ===
          AbrirObservacaoClienteEnum.somenteAbrirQuandoTiverObs &&
          response.observacoes !== '' &&
          response.observacoes !== null)
      ) {
        setObservacaoCliente({
          idCliente: pessoa.id,
          observacoes: response.observacoes,
        });
        setShowObservacaoCliente(true);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleExibirMensagemAtualizacao,
    parametros.AbrirTelaComObservacoesDoClienteNotaFiscalSaida,
    setLoading,
  ]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    try {
      const pessoa = formRef.current?.getFieldValueNomeObjeto(
        'notaFiscalSaidaPessoa.pessoa'
      );

      if (!pessoa) return;

      setLoading(true);

      const response = await PessoaObterDadosPadraoComunicador.show({
        id: pessoa.id,
      });

      if (response.modalidadeFrete) {
        await formRef.current?.setFieldValue(
          'notaFiscalSaidaTransporteTransportadora.modalidadeFrete',
          response.modalidadeFrete
        );
      }

      if (response.pessoaTransportadora) {
        await formRef.current?.setFieldValue(
          'notaFiscalSaidaTransporteTransportadora.pessoaTransportadora',
          response.pessoaTransportadora
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading]);

  const handleOnSelecionarEndereco = useCallback(
    async (enderecoSelecionado: IEnderecoPessoa) => {
      const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
        'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla'
      );

      if (enderecoSelecionado) {
        await formRef.current?.setSemExecutarEvento({
          notaFiscalSaidaPessoaEndereco: {
            cep: enderecoSelecionado.cep,
            cidade: enderecoSelecionado.cidade,
            logradouro: enderecoSelecionado.logradouro,
            numero: enderecoSelecionado.numero,
            bairro: enderecoSelecionado.bairro,
            complemento: enderecoSelecionado.complemento,
          },
        });

        const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
          'notaFiscalSaidaPessoaEndereco.cidade.estado.sigla'
        );

        if (pessoaUf !== pessoaUfNovo) {
          await handleExibirMensagemAtualizacao();
        }
      }
    },
    [formRef, handleExibirMensagemAtualizacao]
  );

  return (
    <NotaFiscalSaidaContext.Provider
      value={{
        setFormaRateioValorFrete: handleSetFormaRateioValorFrete,
        setFormaRateioValorSeguro: handleSetFormaRateioValorSeguro,
        setFormaRateioValorOutrasDespesas:
          handleSetFormaRateioValorOutrasDespesas,
        setFormaRateioValorDesconto: handleSetFormaRateioValorDesconto,
        atualizarRateiosListaItem: handleAtualizarRateiosListaItem,
        atualizarDadosClienteConformeCadastro:
          handleAtualizarDadosClienteConformeCadastro,
        obterDadosPadraoCliente: handleObterDadosPadraoCliente,
        listaRepresentante,
        setListaRepresentante,
        validador: true,
      }}
    >
      {children}
      {showModalSelecaoEndereco && (
        <SelecaoEnderecoPessoaDetalhe
          listaEndereco={listaEndereco}
          onSelecionarEndereco={(item) => {
            handleOnSelecionarEndereco(item);
          }}
          onFecharFormModal={() => {
            setShowModalSelecaoEndereco(false);
          }}
        />
      )}

      {showObservacaoCliente && (
        <AbrirObservacaoCliente
          onSalvarFormModal={() => {
            setShowObservacaoCliente(false);
          }}
          onFecharFormModal={() => {
            setShowObservacaoCliente(false);
          }}
          loading={loading}
          setLoading={setLoading}
          cliente={observacaoCliente}
        />
      )}
    </NotaFiscalSaidaContext.Provider>
  );
};

function UseNotaFiscalSaida(): Omit<INotaFiscalSaidaContext, 'validador'> {
  const context = useContext(NotaFiscalSaidaContext);

  if (!context.validador) {
    throw new Error(
      'UseNotaFiscalSaida deve ser usado com um NotaFiscalSaidaProvider'
    );
  }

  return context;
}

export { NotaFiscalSaidaHook, UseNotaFiscalSaida };
