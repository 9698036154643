import api from '../../../../../Comum/Services/Api';

const rota =
  '/financeiro/movimentacoes/contas-parcela-reversao/dentro-periodo-fechamento';

interface IContaParcelaReversaoDentroFechamentoPeriodoComunicadorShow {
  params?: any;
}

class ContaParcelaReversaoDentroFechamentoPeriodoComunicador {
  public async show(
    params: IContaParcelaReversaoDentroFechamentoPeriodoComunicadorShow
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });
    return response.data;
  }
}

export default new ContaParcelaReversaoDentroFechamentoPeriodoComunicador();
