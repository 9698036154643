import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { v4 } from 'uuid';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import SplitAcoes from '../../SubHeaderGeral/SplitAcoes';

interface IProps {
  onClick?: (valor: string) => void;
  exibirNumeroNota?: boolean;
  exibirPercentualMulta?: boolean;
  exibirValorJurosCalculado?: boolean;
  exibirValorMultaCalculado?: boolean;
  exibirPrazoNegativacao?: boolean;
  exibirPrazoProtesto?: boolean;
}

const BntVariaveisBoletoButton: React.FC<IProps> = ({
  onClick,
  exibirNumeroNota = true,
  exibirPercentualMulta = true,
  exibirValorJurosCalculado = true,
  exibirValorMultaCalculado = true,
  exibirPrazoNegativacao = true,
  exibirPrazoProtesto = true,
}) => {
  const handleClick = useCallback(
    (valor: string) => {
      if (onClick) onClick(valor);
    },
    [onClick]
  );

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'end',
        marginTop: -5,
      }}
    >
      <SplitAcoes
        id={v4()}
        className="btn-group"
        title={
          <div>
            <IoMdAddCircleOutline />
            <span style={{ marginLeft: 10, marginRight: 10 }}>
              Adicionar Variável
            </span>
          </div>
        }
      >
        <Dropdown.Item
          onClick={() => handleClick('<#NUMERONOTA#>')}
          style={{ display: exibirNumeroNota ? '' : 'none' }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>Número da Nota</span>
        </Dropdown.Item>

        {/* <Dropdown.Item
          onClick={() => handleClick('<#PERCENTUALMULTA#>')}
          style={{ display: exibirPercentualMulta ? '' : 'none' }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Percentual da Multa
          </span>
        </Dropdown.Item> */}

        <Dropdown.Item
          onClick={() => handleClick('<#VALORJUROSCALCULADO#>')}
          style={{ display: exibirValorJurosCalculado ? '' : 'none' }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor do Juros Calculado
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#VALORMULTACALCULADO#>')}
          style={{ display: exibirValorMultaCalculado ? '' : 'none' }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Valor Multa Calculado
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#PRAZONEGATIVACAO#>')}
          style={{ display: exibirPrazoNegativacao ? '' : 'none' }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Prazo de Negativação
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleClick('<#PRAZOPROTESTO#>')}
          style={{ display: exibirPrazoProtesto ? '' : 'none' }}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            Prazo de Protesto
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </div>
  );
};

export default BntVariaveisBoletoButton;
