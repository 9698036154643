import {
  AppErro,
  ConverterParaNumberOrNull,
  DestinadoEnum,
  IItensRateioImpostoCalculoPorCstRetorno,
  ItensRateioImpostoCalculoPorCst,
  RateioComCasaDecimalCalculo,
  TipoMercadoEnum,
  ValorTotalDocumentoCalculo,
} from '@elogestor/util';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaItem } from './ListaItemContext';
import SelecaoEnderecoPessoaDetalhe from '../PessoaTab/SelecaoEnderecoPessoaDetalhe';
import IEnderecoPessoa from '../PessoaTab/SelecaoEnderecoPessoaDetalhe/Interface/IEnderecoPessoa';
import ObterNotaFiscalEntradaItensParaCalculoRateiosComunicador from '../../../../../../Comunicador/Suprimentos/Compras/NotaFiscalEntrada/Comunicador/ObterNotaFiscalEntradaItensParaCalculoRateiosComunicador';
import PessoaObterDadosCadastroComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosCadastroComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PessoaObterDadosPadraoComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosPadraoComunicador';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';

interface INotaFiscalEntradaContext {
  validador: boolean;
  setFormaRateioValorFrete(value: boolean): void;
  setFormaRateioValorSeguro(value: boolean): void;
  setFormaRateioValorOutrasDespesas(value: boolean): void;
  setFormaRateioValorDesconto(value: boolean): void;
  atualizarRateiosListaItem(): void;
  atualizarDadosFornecedorConformeCadastro(): void;
  obterDadosPadraoCliente(): void;
}

const NotaFiscalEntradaContext = createContext<INotaFiscalEntradaContext>(
  {} as INotaFiscalEntradaContext
);

const NotaFiscalEntradaHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaItem } = UseListaItem();
  const { formRef, getIdDetalheRegistro, setLoading } = UseForm();
  const { abrirJanela } = UseAlerta();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [showModalSelecaoEndereco, setShowModalSelecaoEndereco] =
    useState(false);
  const [listaEndereco, setListaEndereco] = useState<IEnderecoPessoa[]>([]);

  const handleSetFormaRateioValorFrete = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('freteFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorSeguro = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('seguroFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorOutrasDespesas = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue(
        'outrasDespesasFormaCalculoPorRateio',
        value
      );
    },
    [formRef]
  );

  const handleSetFormaRateioValorDesconto = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('descontoFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleAtualizarTotais = useCallback(() => {
    let totalProdutos = 0;
    let totalBruto = 0;
    if (listaItem.length === 0) {
      const valorFrete = formRef.current?.getFieldValue('valorFrete');
      const valorSeguro = formRef.current?.getFieldValue('valorSeguro');
      const valorOutrasDespesas = formRef.current?.getFieldValue(
        'valorOutrasDespesas'
      );
      const valorDesconto = formRef.current?.getFieldValue('valorDesconto');

      const valorTotal =
        valorFrete + valorSeguro + valorOutrasDespesas - valorDesconto;

      totalProdutos = valorTotal;
      totalBruto = valorTotal;
    } else {
      [totalProdutos, totalBruto] = listaItem.reduce(
        (acumulador, item) => {
          acumulador[0] += Number(item.valorTotalProduto) || 0;
          acumulador[1] += Number(item.valorTotalBruto) || 0;
          return acumulador;
        },
        [0, 0]
      );
    }

    formRef.current?.setFieldValue('valorTotalProdutos', totalProdutos);
    formRef.current?.setFieldValue('valorTotalBruto', totalBruto);
  }, [formRef, listaItem]);

  const handleAtualizarRateiosListaItem = useCallback(async () => {
    if (!idDetalheRegistro) return;

    try {
      const response =
        await ObterNotaFiscalEntradaItensParaCalculoRateiosComunicador.index({
          id: idDetalheRegistro,
        });

      if (
        !response.listaNotaFiscalEntradaItem ||
        response.listaNotaFiscalEntradaItem.length !== listaItem.length
      ) {
        throw new AppErro({
          mensagem: 'A nota fiscal de entrada esta desatualizada!',
        });
      }

      const valorFreteAtual = formRef.current?.getFieldValue('valorFrete') ?? 0;
      const valorSeguroAtual =
        formRef.current?.getFieldValue('valorSeguro') ?? 0;
      const valorOutrasDespesasAtual =
        formRef.current?.getFieldValue('valorOutrasDespesas') ?? 0;
      const valorDescontoAtual =
        formRef.current?.getFieldValue('valorDesconto') ?? 0;

      if (listaItem.length === 0) {
        const valorTotal =
          valorFreteAtual +
          valorSeguroAtual +
          valorOutrasDespesasAtual -
          valorDescontoAtual;

        formRef.current?.setFieldValue('valorTotalProdutos', valorTotal);
        formRef.current?.setFieldValue('valorTotalBruto', valorTotal);
        return;
      }

      const listaItemValorTotalProduto =
        listaItem.map((item) => {
          return Number(item.valorTotalProduto);
        }) || [];

      const listaValorFrete = RateioComCasaDecimalCalculo({
        valor: valorFreteAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorSeguro = RateioComCasaDecimalCalculo({
        valor: valorSeguroAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorOutrasDespesas = RateioComCasaDecimalCalculo({
        valor: valorOutrasDespesasAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorDesconto = RateioComCasaDecimalCalculo({
        valor: valorDescontoAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaItensRateio =
        response.listaNotaFiscalEntradaItem?.map((item, index) => {
          return {
            valorFreteItem: listaValorFrete[index],
            valorSeguroItem: listaValorSeguro[index],
            valorOutrasDespesasItem: listaValorOutrasDespesas[index],
            valorDescontoItem: listaValorDesconto[index],
            quantidadeItem: ConverterParaNumberOrNull(item.quantidade),
            valorUnitarioItem: ConverterParaNumberOrNull(item.valorUnitario),

            icms: {
              valorIpiItem: null,
              destinadoItem: item.destinado as DestinadoEnum,

              cstCsosn: item.notaFiscalEntradaItemImpostoIcms
                ?.situacaoTributaria?.codigo
                ? item.notaFiscalEntradaItemImpostoIcms?.situacaoTributaria
                    ?.codigo
                : '',
              tipoMercado: item.operacaoFiscal?.tipoMercado as TipoMercadoEnum,

              // #region ICMS

              calcularIcms: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularIcms
              ),
              modalidadeBaseCalculoIcms: item.notaFiscalEntradaItemImpostoIcms
                ?.modalidadeBaseCalculoIcms
                ? item.notaFiscalEntradaItemImpostoIcms
                    ?.modalidadeBaseCalculoIcms
                : null,
              aliquotaInterestadualIcms: null,

              percentualReducaoBaseCalculoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcms
              ),
              baseCalculoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoIcms
              ),
              aliquotaIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaIcms
              ),
              valorIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorIcms
              ),

              baseCalculoFcpIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoFcpIcms
              ),
              aliquotaFcpIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaFcpIcms
              ),
              valorFcpIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorFcpIcms
              ),

              percentualDiferimentoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.percentualDiferimentoIcms
              ),
              valorSemDiferimentoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorSemDiferimentoIcms
              ),
              valorDiferidoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorDiferidoIcms
              ),

              valorUnitarioPautaIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorUnitarioPautaIcms
              ),
              valorTotalPautaIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorTotalPautaIcms
              ),

              calcularIcmsEfetivo: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularIcmsEfetivo
              ),
              percentualReducaoBaseCalculoIcmsEfetivo:
                ConverterParaNumberOrNull(
                  item.notaFiscalEntradaItemImpostoIcms
                    ?.percentualReducaoBaseCalculoIcmsEfetivo
                ),
              baseCalculoIcmsEfetivo: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoIcmsEfetivo
              ),
              aliquotaIcmsEfetivo: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaIcmsEfetivo
              ),
              valorIcmsEfetivo: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorIcmsEfetivo
              ),

              calcularIcmsSubstituto: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularIcmsSubstituto
              ),
              valorUnitarioIcmsSubstituto: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.valorUnitarioIcmsSubstituto
              ),
              valorIcmsSubstituto: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorIcmsSubstituto
              ),

              motivoDesoneracaoIcms: item.notaFiscalEntradaItemImpostoIcms
                ?.motivoDesoneracaoIcms
                ? item.notaFiscalEntradaItemImpostoIcms?.motivoDesoneracaoIcms
                : null,
              calcularDesoneracaoIcms: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularDesoneracaoIcms
              ),
              baseCalculoDesoneracaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.baseCalculoDesoneracaoIcms
              ),
              aliquotaDesoneracaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaDesoneracaoIcms
              ),
              valorDesoneracaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorDesoneracaoIcms
              ),

              baseCalculoSemReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoSemReducaoIcms
              ),
              valorSemReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorSemReducaoIcms
              ),
              baseCalculoReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoReducaoIcms
              ),
              valorReducaoIcms: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorReducaoIcms
              ),

              // #endregion ICMS

              // #region ICMS ST

              calcularIcmsSt: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularIcmsSt
              ),
              modalidadeBaseCalculoIcmsSt: item.notaFiscalEntradaItemImpostoIcms
                ?.modalidadeBaseCalculoIcmsSt
                ? item.notaFiscalEntradaItemImpostoIcms
                    ?.modalidadeBaseCalculoIcmsSt
                : null,

              percentualMvaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.percentualMvaIcmsSt
              ),
              percentualReducaoMvaIcmsSt: null,
              percentualReducaoBaseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcmsSt
              ),
              aliquotaInterestadualIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.aliquotaInterestadualIcmsSt
              ),
              aliquotaInternaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaInternaIcmsSt
              ),
              baseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoIcmsSt
              ),
              valorIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorIcmsSt
              ),

              baseCalculoFcpIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoFcpIcmsSt
              ),
              aliquotaFcpIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaFcpIcmsSt
              ),
              valorFcpIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorFcpIcmsSt
              ),

              calcularIcmsStRetido: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularIcmsStRetido
              ),
              baseCalculoIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.baseCalculoIcmsStRetido
              ),
              baseCalculoUnitariaProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.baseCalculoUnitariaProdutoIcmsStRetido
              ),
              aliquotaIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaIcmsStRetido
              ),
              valorIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorIcmsStRetido
              ),
              valorUnitarioProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.valorUnitarioProdutoIcmsStRetido
              ),

              baseCalculoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.baseCalculoFcpIcmsStRetido
              ),
              baseCalculoUnitariaProdutoFcpIcmsStRetido:
                ConverterParaNumberOrNull(
                  item.notaFiscalEntradaItemImpostoIcms
                    ?.baseCalculoUnitariaProdutoFcpIcmsStRetido
                ),
              aliquotaFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.aliquotaFcpIcmsStRetido
              ),
              valorFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorFcpIcmsStRetido
              ),
              valorUnitarioProdutoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms
                  ?.valorUnitarioProdutoFcpIcmsStRetido
              ),

              valorUnitarioPautaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorUnitarioPautaIcmsSt
              ),
              valorTotalPautaIcmsSt: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorTotalPautaIcmsSt
              ),

              // #endregion ICMS ST

              // #region ICMS SN

              calcularCreditoIcmsSn: Boolean(
                item.notaFiscalEntradaItemImpostoIcms?.calcularCreditoIcmsSn
              ),
              percentualCreditoIcmsSn: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.percentualCreditoIcmsSn
              ),
              valorCreditoIcmsSn: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIcms?.valorCreditoIcmsSn
              ),

              // #endregion ICMS SN
            },

            ipi: {
              calcular: Boolean(item.notaFiscalEntradaItemImpostoIpi?.calcular),
              tipoCalculo: item.notaFiscalEntradaItemImpostoIpi?.tipoCalculo
                ? item.notaFiscalEntradaItemImpostoIpi?.tipoCalculo
                : null,
              cst: item.notaFiscalEntradaItemImpostoIpi?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalEntradaItemImpostoIpi?.situacaoTributaria
                    ?.codigo
                : '',
              quantidadeUnidadeIpi: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIpi?.quantidadeUnidade
              ),
              valorUnidadeIpi: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIpi?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIpi?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIpi?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoIpi?.valor
              ),
            },

            pis: {
              calcular: Boolean(item.notaFiscalEntradaItemImpostoPis?.calcular),
              tipoCalculo: item.notaFiscalEntradaItemImpostoPis?.tipoCalculo
                ? item.notaFiscalEntradaItemImpostoPis?.tipoCalculo
                : null,
              cst: item.notaFiscalEntradaItemImpostoPis?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalEntradaItemImpostoPis?.situacaoTributaria
                    ?.codigo
                : '',
              quantidadeUnidadePis: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.quantidadeUnidade
              ),
              valorUnidadePis: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.valor
              ),

              calcularRetido: Boolean(
                item.notaFiscalEntradaItemImpostoPis?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoPis?.valorRetido
              ),
            },

            cofins: {
              calcular: Boolean(
                item.notaFiscalEntradaItemImpostoCofins?.calcular
              ),
              tipoCalculo: item.notaFiscalEntradaItemImpostoCofins?.tipoCalculo
                ? item.notaFiscalEntradaItemImpostoCofins?.tipoCalculo
                : null,
              cst: item.notaFiscalEntradaItemImpostoCofins?.situacaoTributaria
                ?.codigo
                ? item.notaFiscalEntradaItemImpostoCofins?.situacaoTributaria
                    ?.codigo
                : '',
              quantidadeUnidadeCofins: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.quantidadeUnidade
              ),
              valorUnidadeCofins: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.valor
              ),

              calcularRetido: Boolean(
                item.notaFiscalEntradaItemImpostoCofins?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.notaFiscalEntradaItemImpostoCofins?.valorRetido
              ),
            },
          };
        }) || [];

      const resultado = ItensRateioImpostoCalculoPorCst(listaItensRateio);
      for (let i = 0; i < listaItem.length; i++) {
        const valorTotalBrutoItem = ValorTotalDocumentoCalculo({
          valorSubtotalItem: Number(listaItem[i].valorTotalProduto),
          valorDescontoItem: Number(listaItensRateio[i].valorDescontoItem),
          valorFreteItem: Number(listaItensRateio[i].valorFreteItem),
          valorSeguroItem: Number(listaItensRateio[i].valorSeguroItem),
          valorOutrasDespesasItem: Number(
            listaItensRateio[i].valorOutrasDespesasItem
          ),
          valorIcmsSt: resultado[i].icms
            ? Number(resultado[i].icms?.valorIcmsSt)
            : 0,
          valorFcpIcmsSt: resultado[i].icms
            ? Number(resultado[i].icms?.valorFcpIcmsSt)
            : 0,
          valorIpi: resultado[i].ipi ? Number(resultado[i].ipi?.valor) : 0,
          valorImpostoImportacao: null,
          valorServicos: null,

          valorDesoneracaoIcmsDeduzir: listaItem[i]
            .notaFiscalEntradaItemImpostoIcms?.deduzirDesoneracaoIcms
            ? Number(
                listaItem[i].notaFiscalEntradaItemImpostoIcms
                  ?.valorDesoneracaoIcms
              )
            : null,
        });
        listaItem[i].valorTotalBruto = valorTotalBrutoItem;
      }

      // #region ICMS

      const valorTotalBaseCalculoIcms = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcms);
          return acumulador;
        },
        0
      );

      const valorTotalIcms = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcms);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcms = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcms);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcms',
        valorTotalBaseCalculoIcms
      );
      formRef.current?.setFieldValue('valorTotalIcms', valorTotalIcms);
      formRef.current?.setFieldValue('valorTotalFcpIcms', valorTotalFcpIcms);

      // #endregion ICMS

      // #region ICMS ST

      const valorTotalBaseCalculoIcmsSt = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalIcmsSt = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcmsSt = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcmsSt);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcmsSt',
        valorTotalBaseCalculoIcmsSt
      );
      formRef.current?.setFieldValue('valorTotalIcmsSt', valorTotalIcmsSt);
      formRef.current?.setFieldValue(
        'valorTotalFcpIcmsSt',
        valorTotalFcpIcmsSt
      );

      // #endregion ICMS ST

      // #region IPI

      const valorTotalBaseCalculoIpi = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalIpi = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIpi',
        valorTotalBaseCalculoIpi
      );
      formRef.current?.setFieldValue('valorTotalIpi', valorTotalIpi);

      // #endregion IPI

      // #region PIS/COFINS

      const valorTotalBaseCalculoPis = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalPis = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPis',
        valorTotalBaseCalculoPis
      );
      formRef.current?.setFieldValue('valorTotalPis', valorTotalPis);

      const valorTotalBaseCalculoCofins = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalCofins = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofins',
        valorTotalBaseCalculoCofins
      );
      formRef.current?.setFieldValue('valorTotalCofins', valorTotalCofins);

      // #endregion PIS/COFINS

      // #region PIS/COFINS Retido

      const valorTotalBaseCalculoPisRetido = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculoRetido);
          return acumulador;
        },
        0
      );

      const valorTotalPisRetido = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPisRetido',
        valorTotalBaseCalculoPisRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalPisRetido',
        valorTotalPisRetido
      );

      const valorTotalBaseCalculoCofinsRetido = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.baseCalculoRetido);
          return acumulador;
        },
        0
      );

      const valorTotalCofinsRetido = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofinsRetido',
        valorTotalBaseCalculoCofinsRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalCofinsRetido',
        valorTotalCofinsRetido
      );

      // #endregion PIS/COFINS Retido

      // #region Funrural

      const valorFunrural = resultado.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.funrural?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue('valorFunrural', valorFunrural);

      // #endregion Funrural

      handleAtualizarTotais();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  }, [formRef, handleAtualizarTotais, idDetalheRegistro, listaItem]);

  useEffect(() => {
    handleAtualizarTotais();
  }, [formRef, handleAtualizarTotais]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaNotaFiscalEntradaItem = formRef.current?.getFieldValue(
      'listaNotaFiscalEntradaItem'
    );

    if (listaNotaFiscalEntradaItem && listaNotaFiscalEntradaItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração da UF do Fornecedor, as informações tributárias e
            valores podem estar incorretos. Salve para atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  const handleAtualizarDadosFornecedorConformeCadastro =
    useCallback(async () => {
      try {
        const pessoa = formRef.current?.getFieldValueNomeObjeto(
          'notaFiscalEntradaPessoa.pessoa'
        );
        const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
          'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
        );

        if (!pessoa) return;

        setLoading(true);

        const response = await PessoaObterDadosCadastroComunicador.show({
          id: pessoa.id,
        });

        await formRef.current?.setSemExecutarEvento({
          notaFiscalEntradaPessoa: {
            cnpjCpfVirtual: response.cnpjCpfVirtual,
            inscricaoEstadual: response.inscricaoEstadual,
            inscricaoSuframa: response.inscricaoSuframa,
            documentoIdentificacaoEstrangeiro:
              response.documentoIdentificacaoEstrangeiro,
            nomeRazaoSocial: response.nomeRazaoSocial,
            nomeFantasia: response.nomeFantasia,
          },
        });

        if (response.pessoaEnderecoEntrega.outroEnderecoEntrega) {
          await formRef.current?.setSemExecutarEvento({
            notaFiscalEntradaPessoaEnderecoEntrega: {
              cnpjCpfVirtual: response.pessoaEnderecoEntrega.cnpjCpfVirtual,
              inscricaoEstadual:
                response.pessoaEnderecoEntrega.inscricaoEstadual,
              nomeRazaoSocial: response.pessoaEnderecoEntrega.nomeRazaoSocial,
              outroEnderecoEntrega:
                response.pessoaEnderecoEntrega.outroEnderecoEntrega,
              cep: response.pessoaEnderecoEntrega.cep,
              cidade: response.pessoaEnderecoEntrega.cidade,
              logradouro: response.pessoaEnderecoEntrega.logradouro,
              numero: response.pessoaEnderecoEntrega.numero,
              bairro: response.pessoaEnderecoEntrega.bairro,
              complemento: response.pessoaEnderecoEntrega.complemento,
            },
          });
        } else {
          await formRef.current?.setSemExecutarEvento({
            notaFiscalEntradaPessoaEnderecoEntrega: {
              cnpjCpfVirtual: null,
              inscricaoEstadual: null,
              nomeRazaoSocial: null,
              outroEnderecoEntrega: null,
              cep: null,
              cidade: null,
              logradouro: null,
              numero: null,
              bairro: null,
              complemento: null,
            },
          });
        }

        if (response.listaPessoaEndereco.length > 1) {
          setShowModalSelecaoEndereco(true);
          setListaEndereco(response.listaPessoaEndereco);
        } else if (response.listaPessoaEndereco.length === 1) {
          await formRef.current?.setSemExecutarEvento({
            notaFiscalEntradaPessoaEndereco: {
              cep: response.listaPessoaEndereco[0].cep,
              cidade: response.listaPessoaEndereco[0].cidade,
              logradouro: response.listaPessoaEndereco[0].logradouro,
              numero: response.listaPessoaEndereco[0].numero,
              bairro: response.listaPessoaEndereco[0].bairro,
              complemento: response.listaPessoaEndereco[0].complemento,
            },
          });

          const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
            'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
          );

          if (pessoaUf !== pessoaUfNovo) {
            await handleExibirMensagemAtualizacao();
          }
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [formRef, handleExibirMensagemAtualizacao, setLoading]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    try {
      const pessoa = formRef.current?.getFieldValueNomeObjeto(
        'notaFiscalEntradaPessoa.pessoa'
      );

      if (!pessoa) return;

      setLoading(true);

      const response = await PessoaObterDadosPadraoComunicador.show({
        id: pessoa.id,
      });

      if (response.modalidadeFrete) {
        await formRef.current?.setFieldValue(
          'notaFiscalEntradaTransporteTransportadora.modalidadeFrete',
          response.modalidadeFrete
        );
      }

      if (response.pessoaTransportadora) {
        await formRef.current?.setFieldValue(
          'notaFiscalEntradaTransporteTransportadora.pessoaTransportadora',
          response.pessoaTransportadora
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading]);

  const handleOnSelecionarEndereco = useCallback(
    async (enderecoSelecionado: IEnderecoPessoa) => {
      if (enderecoSelecionado) {
        const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
          'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
        );

        await formRef.current?.setSemExecutarEvento({
          notaFiscalEntradaPessoaEndereco: {
            cep: enderecoSelecionado.cep,
            cidade: enderecoSelecionado.cidade,
            logradouro: enderecoSelecionado.logradouro,
            numero: enderecoSelecionado.numero,
            bairro: enderecoSelecionado.bairro,
            complemento: enderecoSelecionado.complemento,
          },
        });

        const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
          'notaFiscalEntradaPessoaEndereco.cidade.estado.sigla'
        );

        if (pessoaUf !== pessoaUfNovo) {
          await handleExibirMensagemAtualizacao();
        }
      }
    },
    [formRef, handleExibirMensagemAtualizacao]
  );

  return (
    <NotaFiscalEntradaContext.Provider
      value={{
        setFormaRateioValorFrete: handleSetFormaRateioValorFrete,
        setFormaRateioValorSeguro: handleSetFormaRateioValorSeguro,
        setFormaRateioValorOutrasDespesas:
          handleSetFormaRateioValorOutrasDespesas,
        setFormaRateioValorDesconto: handleSetFormaRateioValorDesconto,
        atualizarRateiosListaItem: handleAtualizarRateiosListaItem,
        atualizarDadosFornecedorConformeCadastro:
          handleAtualizarDadosFornecedorConformeCadastro,
        obterDadosPadraoCliente: handleObterDadosPadraoCliente,
        validador: true,
      }}
    >
      {children}
      {showModalSelecaoEndereco && (
        <SelecaoEnderecoPessoaDetalhe
          listaEndereco={listaEndereco}
          onSelecionarEndereco={(item) => {
            handleOnSelecionarEndereco(item);
          }}
          onFecharFormModal={() => {
            setShowModalSelecaoEndereco(false);
          }}
        />
      )}
    </NotaFiscalEntradaContext.Provider>
  );
};

function UseNotaFiscalEntrada(): Omit<INotaFiscalEntradaContext, 'validador'> {
  const context = useContext(NotaFiscalEntradaContext);

  if (!context.validador) {
    throw new Error(
      'UseNotaFiscalEntrada deve ser usado com um NotaFiscalEntradaProvider'
    );
  }

  return context;
}

export { NotaFiscalEntradaHook, UseNotaFiscalEntrada };
