/* eslint-disable no-console */
import { AppErro, SepararPalavrasPorLetraMaiuscula } from '@elogestor/util';
import ToastErro from '../Toasts/ToastErro';
import IOpcoes from './Interface/IOpcoes';
import ErroComunicador from '../../Comunicador/Geral/Erro/Comunicador/ErroComunicador';

async function BlobToString(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsText(blob);
  });
}

const TratarMensagem = (mensagem: string): string => {
  if (mensagem && mensagem.includes('violates foreign key constraint')) {
    let nomeTabela = mensagem.substring(
      mensagem.indexOf('" on table "') + 12,
      mensagem.length - 1
    );

    nomeTabela = nomeTabela
      .replace('comercial', '')
      .replace('configuracao', '')
      .replace('contabil', '')
      .replace('financeiro', '')
      .replace('fiscal', '')
      .replace('geral', '')
      .replace('manufatura', '')
      .replace('suprimentos', '')
      .replace('transporte', '');

    nomeTabela = SepararPalavrasPorLetraMaiuscula(nomeTabela);

    mensagem = `Não é permitido excluir, pois o registro está em uso na tabela "${nomeTabela}"!`;
  }

  return mensagem;
};

const TratarErroServidor = async (erro: any): Promise<void> => {
  console.log('Erro no servidor: ', erro);
  ToastErro('Erro no servidor!');

  await ErroComunicador.store({
    params: { erro, erroFront: true },
  });
};

const TratarAppErro = (erro: AppErro): void => {
  if (erro.listaMensagem) {
    for (let i = 0; i < erro.listaMensagem.length; i++) {
      let mensagem = erro.listaMensagem[i];
      mensagem = TratarMensagem(mensagem);

      ToastErro(mensagem, { autoClose: 4000 + i * 1000 });
    }
  }

  if (erro.mensagem) {
    const mensagem = TratarMensagem(erro.mensagem);
    ToastErro(mensagem);
  }

  if (!erro.listaMensagem && !erro.mensagem) {
    if (erro) {
      ToastErro(JSON.stringify(erro));
    } else {
      TratarErroServidor(erro);
    }
  }

  if (
    (erro as any).inner &&
    (erro as any).inner.length > 0 &&
    !erro.listaMensagem
  ) {
    for (let i = 0; i < (erro as any).inner.length; i++) {
      let mensagem = (erro as any).inner[i].message;
      mensagem = TratarMensagem(mensagem);

      ToastErro(mensagem, { autoClose: 4000 + i * 1000 });
    }
  }
};

export default async function TratarErros(
  erro: any,
  opcoes: IOpcoes = {}
): Promise<void> {
  const { redirecionar = true } = opcoes;

  if (erro) {
    if (erro.response && erro.response) {
      if (erro.response.data instanceof Blob) {
        const data = await BlobToString(erro.response.data);
        const erroData = JSON.parse(data.toString());

        TratarAppErro(erroData);
      } else {
        if (!!erro.response?.redirecionar && redirecionar) {
          window.location.href = '/nao-autorizado';
        }

        TratarAppErro(erro.response.data);
      }
    } else if (erro instanceof AppErro) {
      TratarAppErro(erro);
    } else if (erro.listaMensagem || erro.mensagem) {
      TratarAppErro(erro);
    }
    // else if (erro.message) {
    //   ToastErro(erro.message);
    // }
    else {
      TratarErroServidor(erro);
    }
  } else {
    TratarErroServidor(erro);
  }
}
