import React, { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { HiOutlineDocumentDownload } from 'react-icons/hi/index.mjs';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import {
  AppErro,
  SituacaoConhecimentoTransporteEnum,
  SituacaoManifestoTransporteEnum,
  SituacaoNotaFiscalEntradaEnum,
  SituacaoNotaFiscalSaidaEnum,
} from '@elogestor/util';
import JSZip from 'jszip';
import PDFMerger from 'pdf-merger-js';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import { IListaItens } from '..';
import ManifestoTransporteTransmitirComunicador from '../../../../../../Comunicador/Transporte/ManifestoTransporte/Comunicador/ManifestoTransporteTransmitirComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ToastAviso from '../../../../../../Util/Toasts/ToastAviso';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import TratarValidadeCertificado from '../../../../../../Util/Configuracao/Certificado/TratarValidadeCertificado';
import ManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/ManutencaoDocumentosFiscais/Comunicador/ManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicador';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ManutencaoDocumentosFiscaisBaixarPdfComunicador from '../../../../../../Comunicador/Fiscal/DocumentosEletronicos/ManutencaoDocumentosFiscais/Comunicador/ManutencaoDocumentosFiscaisBaixarPdfComunicador';
import VisualizarPDF from '../../../../../../Util/Relatorios/VisualizarPDF';

interface IAcoesManutencaoDocumentosFiscais {
  obterItensSelecionados: () => IListaItens[];
  loadingListagem: boolean;
}

const AcoesManutencaoDocumentosFiscais: React.FC<
  IAcoesManutencaoDocumentosFiscais
> = ({ obterItensSelecionados, loadingListagem }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const [loading, setLoading] = useState(false);

  const validarItensSelecionados = useCallback(
    (lista: IListaItens[], options?: { [key: string]: boolean }): boolean => {
      if (!lista || lista.length === 0) {
        ToastAviso('Nenhum arquivo selecionado!');
        return false;
      }

      const situacoesDiferentesTransmitida = lista.some(
        (it) =>
          (it.conhecimentoTransporte &&
            it.conhecimentoTransporte?.situacaoAutorizacao !==
              SituacaoConhecimentoTransporteEnum.autorizado) ||
          (it.manifestoTransporte &&
            it.manifestoTransporte?.situacaoAutorizacao !==
              SituacaoManifestoTransporteEnum.autorizado) ||
          (it.notaFiscalConsumidorPropria &&
            it.notaFiscalConsumidorPropria?.situacaoAutorizacao !==
              SituacaoNotaFiscalSaidaEnum.autorizada) ||
          (it.notaFiscalPropriaTerceiro &&
            it.notaFiscalPropriaTerceiro?.situacaoAutorizacao !==
              SituacaoNotaFiscalEntradaEnum.autorizada)
      );
      const situacoesRejeitadoNaoTransmitido = lista.some(
        (it) =>
          (it.conhecimentoTransporte &&
            it.conhecimentoTransporte?.situacaoAutorizacao ===
              SituacaoConhecimentoTransporteEnum.naoTransmitido) ||
          it.conhecimentoTransporte?.situacaoAutorizacao ===
            SituacaoConhecimentoTransporteEnum.rejeitado ||
          (it.manifestoTransporte &&
            it.manifestoTransporte?.situacaoAutorizacao ===
              SituacaoManifestoTransporteEnum.naoTransmitido) ||
          it.manifestoTransporte?.situacaoAutorizacao ===
            SituacaoManifestoTransporteEnum.rejeitado ||
          (it.notaFiscalConsumidorPropria &&
            it.notaFiscalConsumidorPropria?.situacaoAutorizacao ===
              SituacaoNotaFiscalSaidaEnum.rejeitada) ||
          it.notaFiscalConsumidorPropria?.situacaoAutorizacao ===
            SituacaoNotaFiscalSaidaEnum.naoTransmitida ||
          (it.notaFiscalPropriaTerceiro &&
            it.notaFiscalPropriaTerceiro?.situacaoAutorizacao ===
              SituacaoNotaFiscalEntradaEnum.naoTransmitida) ||
          it.notaFiscalPropriaTerceiro?.situacaoAutorizacao ===
            SituacaoNotaFiscalEntradaEnum.rejeitada ||
          (it.notaFiscalSaidaPropria &&
            it.notaFiscalSaidaPropria?.situacaoAutorizacao ===
              SituacaoNotaFiscalSaidaEnum.naoTransmitida) ||
          it.notaFiscalSaidaPropria?.situacaoAutorizacao ===
            SituacaoNotaFiscalSaidaEnum.rejeitada
      );

      if (situacoesDiferentesTransmitida && options && options.isTransmitir) {
        ToastAviso('Selecione apenas arquvios que foram Transmitidos!');
        return false;
      }

      if (situacoesRejeitadoNaoTransmitido) {
        ToastAviso(
          'Selecione apenas arquivos que foram transmitidos e que não foram rejeitados'
        );
        return false;
      }
      return true;
    },
    []
  );

  const handleVisualizarPdfPaginaUnica = useCallback(async () => {
    try {
      setLoading(true);

      const itens = obterItensSelecionados();

      if (!validarItensSelecionados(itens)) {
        setLoading(false);
        return;
      }

      const listaIdDocumentosFiscais = itens.map((item) => item.id);
      const { tipoDocumento } = itens[0];

      const response =
        await ManutencaoDocumentosFiscaisBaixarPdfComunicador.show({
          params: {
            listaIdDocumentosFiscais,
            tipoDocumento,
          },
        });

      if (response) {
        const merger = new PDFMerger();

        for (let index = 0; index < response.length; index++) {
          const impressao = response[index];
          const buffer = Buffer.from(impressao, 'base64');
          await merger.add(buffer);
        }

        const mergedPdf = await merger.saveAsBuffer();

        VisualizarPDF({ dados: { data: mergedPdf } });
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [obterItensSelecionados, validarItensSelecionados]);

  const handleBaixarPdfXml = useCallback(
    async (baixarPdf: boolean, baixarXml: boolean) => {
      try {
        setLoading(true);

        const itens = obterItensSelecionados();
        const zip = new JSZip();

        if (!validarItensSelecionados(itens)) {
          setLoading(false);
          return;
        }

        const listaIdDocumentosFiscais = itens.map((item) => item.id);
        const { tipoDocumento } = itens[0];

        const response =
          await ManutencaoDocumentosFiscaisBaixarPdfXmlCompactadoComunicador.show(
            {
              params: {
                listaIdDocumentosFiscais,
                tipoDocumento,
                baixarPdf,
                baixarXml,
              },
            }
          );
        zip.file(`${tipoDocumento}.zip`, response, {
          binary: true,
        });

        const listaResponse: any[] = [];

        if (Object.keys(zip.files).length > 0) {
          const arquivoZip = await zip.generateAsync({ type: 'blob' });
          listaResponse.push(arquivoZip);

          const url = window.URL.createObjectURL(new Blob(listaResponse));

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `DocumentosFiscais.zip`);

          link.click();

          ToastSucesso('Arquivo Baixado com Sucesso!');
          setLoading(false);
        } else {
          ToastInfo('Nenhum Documento Encontrado no Período Selecionado!');
          setLoading(false);
        }
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    },
    [obterItensSelecionados, validarItensSelecionados]
  );

  const handleTransmitir = useCallback(async () => {
    try {
      setLoading(true);

      const itens = obterItensSelecionados();
      if (!validarItensSelecionados(itens, { isTransmitir: true })) {
        setLoading(false);
        return;
      }

      const listaPromise = itens.map(async (item) => {
        if (item.manifestoTransporte) {
          await TratarValidadeCertificado();

          await ManifestoTransporteTransmitirComunicador.update({
            id: item.id,
          }).catch((error) => {
            const itemErro = { ...item };
            error.itemErro = itemErro;
            throw error;
          });
        }
      });

      const listaResultados = await Promise.allSettled(listaPromise);

      const listaErros: string[] = [];
      listaResultados.forEach((resultado) => {
        if (resultado.status !== 'rejected') return;

        const itemErro = { ...resultado.reason.itemErro };
        const erros = resultado.reason.response.data.listaMensagem.join('\n');

        if (itemErro.manifestoTransporte) {
          const { numero, serie } = itemErro.manifestoTransporte;
          const itemMensagem = `Documento Nº ${numero}; Série ${serie.codigo} - ${serie.descricao};\n`;

          listaErros.push(itemMensagem + erros);
        }
      });

      if (listaErros.length > 0) {
        throw new AppErro({ listaMensagem: [...listaErros] });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [obterItensSelecionados, validarItensSelecionados]);

  return (
    <>
      <LoadingDiv isLoading={loading} />

      <SplitAcoes
        id={v4()}
        className="btn-group"
        disabled={loadingListagem}
        title={
          <>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && <span style={{ margin: '0px 10px' }}>Ações</span>}
            </TextoLoading>
          </>
        }
      >
        <Dropdown.Item
          disabled={loading}
          onClick={handleVisualizarPdfPaginaUnica}
        >
          <span style={{ display: 'flex' }}>
            <ImFilePdf style={{ marginRight: 5 }} />
            Visualizar selecionados em um único PDF
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading}
          onClick={() => {
            handleBaixarPdfXml(false, true);
          }}
        >
          <span style={{ display: 'flex' }}>
            <HiOutlineDocumentDownload style={{ marginRight: 5 }} />
            Baixar selecionados em XML
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => {
            handleBaixarPdfXml(true, false);
          }}
        >
          <span style={{ display: 'flex' }}>
            <ImFilePdf style={{ marginRight: 5 }} />
            Baixar selecionados em PDF
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => {
            handleBaixarPdfXml(true, true);
          }}
        >
          <span style={{ display: 'flex' }}>
            <HiOutlineDocumentDownload style={{ marginRight: 5 }} />
            Baixar selecionados em XML e PDF
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleTransmitir}>
          <span style={{ display: 'flex' }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Transmitir Selecionados
          </span>
        </Dropdown.Item>
      </SplitAcoes>
    </>
  );
};

export default AcoesManutencaoDocumentosFiscais;
