import { IEmpresaFw7ValoresInserir } from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { toast } from 'react-toastify';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import EmpresaFw7Comunicador from '../../../../../Comunicador/Configuracao/Integracao/Fw7/Comunicador/EmpresaFw7Comunicador';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      /*  const idEditar = getIdDetalheRegistro(); */
      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IEmpresaFw7ValoresInserir
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              /* if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              } */
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        const response = await EmpresaFw7Comunicador.show();

        await formRef.current?.setDataInicial({
          companyName: response?.companyName,
          companyToken: response?.companyToken,
          tempoSincronizarProduto: response?.tempoSincronizarProduto,
          tempoSincronizarPessoa: response?.tempoSincronizarPessoa,
          tempoSincronizarPedido: response?.tempoSincronizarPedido,
        });

        /* setIdDetalheRegistro(response.id); */

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else {
            await SetarDadosBackend();
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    [handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    return true;
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const response = await EmpresaFw7Comunicador.store({
          params: data,
        });

        if (!response) {
          toast.error('Erro ao salvar');
          return { id: '', erro: true };
        }

        await formRef.current?.atualizarDataInicial();

        ToastSucesso('Registro Salvo!');

        setLoading(false);

        return { id: '', erro: false };
      } catch (error) {
        TratarErros(error, { redirecionar });
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [redirecionar]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
