import api from '../../../../../Comum/Services/Api';

const rota = '/financeiro/movimentacoes/controle-cheques';

interface IControleChequesComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IControleChequesComunicadorShow {
  id: string;
}

interface IControleChequesComunicadorStore {
  params: any;
}

interface IControleChequesComunicadorUpdate {
  id: string;
  params: any;
}

interface IControleChequesComunicadorDeletar {
  id: string;
}

class ControleChequesComunicador {
  public async index(params: IControleChequesComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IControleChequesComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IControleChequesComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(params: IControleChequesComunicadorUpdate): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IControleChequesComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ControleChequesComunicador();
