const EstoqueResumoCustoPorTipoEstoqueSpedRelatorioPlanilha = [
  {
    titulo: 'Tipo SPED',
    valor: 'chave',
  },
  {
    titulo: 'Cód SPED',
    valor: 'listaEstoque.codigoSped',
  },

  {
    titulo: 'Descrição',
    valor: 'listaEstoque.tipoSped',
  },

  {
    titulo: 'Valor Custo Estoque ICMS IPI',
    valor: 'listaEstoque.valorCustoEstoqueICMSIPI',
  },
  {
    titulo: 'Valor Custo Estoque IR',
    valor: 'listaEstoque.valorCustoEstoqueIR',
  },
];

export default EstoqueResumoCustoPorTipoEstoqueSpedRelatorioPlanilha;
