/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import {
  BlocoHFormaGeracaoValoresEnum,
  BooleanEnum,
  FormatarEnum,
  IEstoqueFiltroRelatorioFrontend,
  IndicadorTipoEstoqueEnum,
  RegimeTributarioCustoEnum,
  RegimeTributarioEnum,
  TipoBuscaCodigoDescricaoEnum,
  TipoCustoEnum,
  TipoOrdenacaoEnum,
} from '@elogestor/util';
import { RiFileExcel2Line } from 'react-icons/ri/index.mjs';
import { SiMicrosoftexcel } from 'react-icons/si/index.mjs';
import { ImFilePdf } from 'react-icons/im/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import SubHeaderGeral from '../../../../Componentes/SubHeaderGeral';
import {
  Container,
  DivisorRelatorio,
  CheckboxContainer,
  OpcoesRelatorio,
} from './styles';
import BtnVoltar from '../../../../Componentes/SubHeaderGeral/BtnVoltar';
import ContainerButtons from '../../../../Componentes/SubHeaderGeral/ContainerButtons';
import Input from '../../../../Componentes/Inputs/Input';
import GetValidationErrors from '../../../../Util/Erro/GetValidationErrors';
import InputRadio from '../../../../Componentes/Inputs/InputRadio';
import Select from '../../../../Componentes/Select';
import * as ListaTemplate from './Template';
import { UseReactSizeMeBodyHook } from '../../../../Hooks/ReactSizeMeBodyHook';
import { IOnChangeListaItemAtualEvent } from '../../../../Componentes/Inputs/AutoCompleteTag/AutoCompleteTagBase';
import InputAutoCompleteTagGrupoProdutoServico from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagGrupoProdutoServico';
import InputAutoCompleteTagProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagProduto';
import InputDateTime from '../../../../Componentes/Inputs/InputDateTime';
import InputCheckbox from '../../../../Componentes/Inputs/InputCheckbox';
import InputAutoCompleteTagLocalEstoque from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagLocalEstoque';
import InputAutoCompleteTagLote from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagLote';
import TextoLoading from '../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../Componentes/LoadingDiv';
import { UseRedirecionar } from '../../../../Hooks/RedirecionarContext';
import TratarErros from '../../../../Util/Erro/TratarErros';
import EmpresaRegimeTributarioVigenteComunicador from '../../../../Comunicador/Configuracao/Empresa/Comunicador/EmpresaRegimeTributarioVigenteComunicador';
import InputAutoCompleteTagPessoa from '../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagSpedTipoProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSpedTipoProduto';
import InputAutoCompleteTagMarcaProduto from '../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagMarcaProduto';
import InputAutoCompleteTagEstoqueIndicadorTipoEnum from '../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagEstoqueIndicadorTipoEnum';

interface IEstoqueRelatorioDados {
  opcaoRelatorio: keyof typeof ListaTemplate;
}

const EstoqueRelatorio: React.FC = () => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { redirecionar } = UseRedirecionar();

  const formRef = useRef<IFormCiaHandles>(null);

  const [loading, setLoading] = useState(false);

  const [inputOpcaoRelatorio, setInputOpcaoRelatorio] = useState(
    'EstoqueSaldoProprioEmPoderProprio'
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        codigo: Yup.string().nullable().max(60, 'Código inválido'),
        descricao: Yup.string().nullable().max(120, 'Descrição inválida'),
        tipoBuscaCodigoDescricao:
          Yup.string() /* .required('Tipo de Busca não pode ser nulo!') */,
        tipoOrdenacao: Yup.string().nullable(),
      });

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleExportCSV = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IEstoqueRelatorioDados;

      handleValidar(dados);

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportCSV(
          dados as IEstoqueFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleExportPlanilha = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IEstoqueRelatorioDados;

      handleValidar(dados);

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleExportPlanilha(
          dados as IEstoqueFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleVisualizarPdf = useCallback(async () => {
    try {
      setLoading(true);

      const { opcaoRelatorio, ...dados } =
        formRef.current?.getData() as IEstoqueRelatorioDados;

      handleValidar(dados);

      const listaProduto =
        formRef.current?.getFieldValueNomeObjeto('listaProduto');

      const listaGrupoProdutoServico = formRef.current?.getFieldValueNomeObjeto(
        'listaGrupoProdutoServico'
      );

      const listaLocalEstoque =
        formRef.current?.getFieldValueNomeObjeto('listaLocalEstoque');

      const listaLote = formRef.current?.getFieldValueNomeObjeto('listaLote');

      const dadosPesquisa = {
        ...dados,
        listaProduto,
        listaGrupoProdutoServico,
        listaLocalEstoque,
        listaLote,
      };

      if (opcaoRelatorio) {
        const template = ListaTemplate[opcaoRelatorio];
        await template.handleVisualizarPdf(
          dadosPesquisa as IEstoqueFiltroRelatorioFrontend
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      TratarErros(error, { redirecionar });
    }
  }, [handleValidar, redirecionar]);

  const handleChangeProduto = useCallback(
    ({ listaItemAtual }: IOnChangeListaItemAtualEvent) => {
      if (listaItemAtual && listaItemAtual.length > 0) {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.clearField(chave);
        });

        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, true);
        });
      } else {
        ['codigo', 'descricao', 'tipoBuscaCodigoDescricao'].forEach((chave) => {
          formRef.current?.setFieldDisabled(chave, false);
        });
      }
    },
    []
  );

  const handleCarregarDados = useCallback(async () => {
    const regimeTributario =
      await EmpresaRegimeTributarioVigenteComunicador.index({
        params: {
          dataFinalVigencia: new Date(),
        },
      });
    const regimeTributarioDescricao =
      regimeTributario.regimeTributario ===
        RegimeTributarioEnum.simplesNacionalEmpresaPequenoPorte ||
      regimeTributario.regimeTributario ===
        RegimeTributarioEnum.simplesNacionalExcessoSublimiteReceitaBruta
        ? RegimeTributarioCustoEnum.simplesNacional
        : regimeTributario.regimeTributario ===
            RegimeTributarioEnum.lucroPresumidoRegimeNormal
          ? RegimeTributarioCustoEnum.lucroPresumido
          : RegimeTributarioCustoEnum.lucroReal;

    formRef.current?.setFieldValue('tipoCusto', TipoCustoEnum.ultimaCompra);
    formRef.current?.setFieldValue(
      'regimeTributario',
      regimeTributarioDescricao
    );
  }, []);

  useEffect(() => {
    formRef.current?.setDataInicial({
      tipoBuscaCodigoDescricao: TipoBuscaCodigoDescricaoEnum.contem,
      tipoOrdenacao: TipoOrdenacaoEnum.descricao,
      spedFiscalFormatoGeracaoValoresH20:
        BlocoHFormaGeracaoValoresEnum.cstCompraValoresCompraeZeradosAliqOrigCST00,
      opcaoRelatorio: 'EstoqueSaldoProprioEmPoderProprio',
      produtoAtivo: true,
    });
  }, []);

  useEffect(() => {
    if (
      inputOpcaoRelatorio === 'EstoqueSaldoProprioEmPoderProprioMinimoMaximo'
    ) {
      formRef.current?.setFieldValue('somenteProdutosEstoqueMinimo', true);
      formRef.current?.setFieldValue('somenteProdutosComSaldo', false);
      formRef.current?.setFieldValue('reservaComprometimento', false);
    } else if (
      inputOpcaoRelatorio ===
      'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra'
    ) {
      formRef.current?.setFieldValue(
        'somenteProdutosComNecessidadeCompra',
        true
      );
      formRef.current?.setFieldValue('reservaComprometimento', true);
    } else {
      formRef.current?.setFieldValue('somenteProdutosEstoqueMinimo', false);
      formRef.current?.setFieldValue('somenteProdutosComSaldo', true);
      formRef.current?.setFieldValue('reservaComprometimento', true);
    }
  }, [inputOpcaoRelatorio]);

  useEffect(() => {
    if (
      inputOpcaoRelatorio === 'EstoqueSaldoProprioEmPoderProprioComCusto' ||
      inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
      inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceirosComCusto' ||
      inputOpcaoRelatorio === 'EstoqueSaldoComCustoConferenciaBlocoH' ||
      inputOpcaoRelatorio === 'EstoqueSaldoComCustoConferenciaBlocoHComNotas'
    ) {
      handleCarregarDados();
    }
  }, [handleCarregarDados, inputOpcaoRelatorio]);

  useEffect(() => {
    if (inputOpcaoRelatorio === 'EstoqueSaldoComCustoConferenciaBlocoH') {
      const ultimoDiaAnoAnterior = new Date(
        new Date().getFullYear() - 1,
        11,
        31,
        23,
        59,
        59,
        999
      );

      formRef.current?.setFieldValue('data', ultimoDiaAnoAnterior);
    } else {
      formRef.current?.setFieldValue('data', new Date().setHours(23, 59, 59));
    }
  }, [inputOpcaoRelatorio]);

  return (
    <Container>
      <LoadingDiv isLoading={loading} />

      <SubHeaderGeral>
        <div
          style={
            telaPequena
              ? {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <h1 style={telaPequena ? { fontSize: 20 } : {}}>
            Relatórios de Estoques
          </h1>
          {telaPequena && (
            <BtnVoltar style={{ display: 'flex' }}>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
              </TextoLoading>
            </BtnVoltar>
          )}
        </div>

        {!telaPequena && (
          <ContainerButtons style={{ display: 'flex' }}>
            <BtnVoltar>
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </BtnVoltar>

            <Button
              onClick={handleExportCSV}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <RiFileExcel2Line />
                <span style={{ marginLeft: 10 }}>Exportar CSV</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleExportPlanilha}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <SiMicrosoftexcel />
                <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
              </TextoLoading>
            </Button>

            <Button
              onClick={handleVisualizarPdf}
              type="button"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <TextoLoading loading={loading}>
                <ImFilePdf />
                <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
              </TextoLoading>
            </Button>
          </ContainerButtons>
        )}
      </SubHeaderGeral>

      <DivisorRelatorio>
        <FormCia ref={formRef}>
          <div>
            <Row>
              <Col xl={3} lg={4} md={12}>
                <h6 style={{ fontWeight: 'bold' }}>Estoque Físico</h6>
                <OpcoesRelatorio>
                  <InputRadio
                    name="opcaoRelatorio"
                    options={[
                      {
                        title: 'Saldo Próprio em Poder Próprio',
                        value: 'EstoqueSaldoProprioEmPoderProprio',
                      },
                      {
                        title: 'Saldo Próprio em Poder Próprio por Grupo',
                        value: 'EstoqueSaldoProprioEmPoderProprioPorGrupo',
                      },
                      {
                        title: 'Saldo Próprio e Terceiros',
                        value: 'EstoqueSaldoProprioTerceiro',
                      },
                      {
                        title:
                          'Saldo Próprio e Terceiros por Local de Estoque e Lote',
                        value: 'EstoqueSaldoProprioTerceiroPorLocalEstoqueLote',
                      },
                      {
                        title: 'Saldo Próprio e Terceiros por Local de Estoque',
                        value: 'EstoqueSaldoProprioTerceiroPorLocalEstoque',
                      },
                      {
                        title: 'Saldo Próprio e Terceiros com Lotes',
                        value: 'EstoqueSaldoProprioTerceiroLote',
                      },
                      {
                        title: 'Extrato por Produto e Lote',
                        value: 'ExtratoPorProdutoLote',
                      },
                      {
                        title: 'Extrato por Produto e Local de Estoque',
                        value: 'ExtratoPorProdutoLocalEstoque',
                      },
                      {
                        title: 'Extrato por Produto',
                        value: 'ExtratoPorProduto',
                      },
                      {
                        title:
                          'Saldo Próprio em Poder Próprio com mínimo e máximo',
                        value: 'EstoqueSaldoProprioEmPoderProprioMinimoMaximo',
                      },
                      {
                        title:
                          'Saldo Próprio em Poder Próprio C/ Necessidade de Compra',
                        value:
                          'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra',
                      },

                      {
                        title: 'Saldo Próprio em Poder Próprio - Com Custo',
                        value: 'EstoqueSaldoProprioEmPoderProprioComCusto',
                      },
                      {
                        title: 'Saldo Próprio e Terceiros - Com Custo',
                        value: 'EstoqueSaldoProprioTerceirosComCusto',
                      },
                      {
                        title: 'Extratos de Movimentos - Com Custo',
                        value: 'ExtratoDeMovimentosComCusto',
                      },
                      {
                        title: 'Saldo Com Custo - Conferência Bloco H',
                        value: 'EstoqueSaldoComCustoConferenciaBlocoH',
                      },
                      {
                        title:
                          'Saldo Com Custo - Conferência Bloco H com Notas',
                        value: 'EstoqueSaldoComCustoConferenciaBlocoHComNotas',
                      },
                      {
                        title:
                          'Resumo de Custo por Tipo de Estoque e Tipo SPED',
                        value: 'EstoqueResumoCustoPorTipoEstoqueSped',
                      },
                    ]}
                    onChange={(event) => {
                      setInputOpcaoRelatorio(event.target.value);
                    }}
                  />
                </OpcoesRelatorio>
              </Col>

              <Col xl={9} lg={8} md={12}>
                <Row>
                  <Col md={8}>
                    <InputDateTime label="Data" name="data" dataAtualPadrao />
                  </Col>
                </Row>

                {(inputOpcaoRelatorio ===
                  'EstoqueSaldoComCustoConferenciaBlocoH' ||
                  inputOpcaoRelatorio ===
                    'EstoqueResumoCustoPorTipoEstoqueSped') && (
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      style={{ marginTop: 10 }}
                    >
                      <InputAutoCompleteTagSpedTipoProduto
                        label="Tipos de Sped"
                        name="listaSpedTipoProduto"
                        listaDescricaoParaRemover={[
                          'Subproduto',
                          'Material de Uso e Consumo',
                          'Ativo Imobilizado',
                          'Serviços',
                          'Outras',
                        ]}
                      />
                    </Col>
                  </Row>
                )}

                {(inputOpcaoRelatorio ===
                  'EstoqueSaldoProprioEmPoderProprioComCusto' ||
                  inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
                  inputOpcaoRelatorio ===
                    'EstoqueSaldoProprioTerceirosComCusto') && (
                  <Row>
                    <Col xl={3} lg={4} md={4} sm={4} style={{ marginTop: 10 }}>
                      <Select
                        label="Tipo Custo"
                        name="tipoCusto"
                        required
                        options={FormatarEnum({
                          enumObj: TipoCustoEnum,
                        })}
                      />
                    </Col>
                  </Row>
                )}

                {inputOpcaoRelatorio ===
                  'EstoqueResumoCustoPorTipoEstoqueSped' && (
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      style={{ marginTop: 10 }}
                    >
                      <InputAutoCompleteTagEstoqueIndicadorTipoEnum
                        label="Tipos de Estoque"
                        nomeObjeto="tipoEstoque"
                        name="listaTipoEstoque"
                        listaDescricaoParaRemover={[
                          'Próp. em poder de terc. sem ret. prop.',
                          'Próp. em poder de terc. sem trans. adq.',
                        ]}
                      />
                    </Col>
                  </Row>
                )}

                {(inputOpcaoRelatorio ===
                  'EstoqueSaldoProprioEmPoderProprioComCusto' ||
                  inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
                  inputOpcaoRelatorio ===
                    'EstoqueSaldoProprioTerceirosComCusto' ||
                  inputOpcaoRelatorio ===
                    'EstoqueSaldoComCustoConferenciaBlocoH' ||
                  inputOpcaoRelatorio ===
                    'EstoqueResumoCustoPorTipoEstoqueSped' ||
                  inputOpcaoRelatorio ===
                    'EstoqueSaldoComCustoConferenciaBlocoHComNotas') && (
                  <Col xl={9} lg={8} md={8} sm={8} style={{ marginTop: 10 }}>
                    <Select
                      label="Regime Tributário"
                      name="regimeTributario"
                      options={FormatarEnum({
                        enumObj: RegimeTributarioCustoEnum,
                      })}
                    />
                  </Col>
                )}

                <Row style={{ marginTop: 10 }}>
                  {
                    /* inputOpcaoRelatorio !==
                    'EstoqueResumoCustoPorTipoEstoqueSped' &&  */ <Col
                      xl={10}
                      lg={10}
                      sm={10}
                    >
                      <InputAutoCompleteTagProduto
                        label="Produtos"
                        name="listaProduto"
                        onChangeListaItemAtual={handleChangeProduto}
                      />
                    </Col>
                  }

                  {inputOpcaoRelatorio !==
                    'EstoqueResumoCustoPorTipoEstoqueSped' && (
                    <Col xl={2} lg={2} sm={2}>
                      <Select
                        name="produtoAtivo"
                        label="Ativo"
                        options={FormatarEnum({
                          enumObj: BooleanEnum,
                          todos: true,
                        })}
                      />
                    </Col>
                  )}
                </Row>

                {inputOpcaoRelatorio !==
                  'EstoqueResumoCustoPorTipoEstoqueSped' && (
                  <Row>
                    <Col lg={3} md={12} sm={12} style={{ marginTop: 10 }}>
                      <Input
                        label="Código"
                        name="codigo"
                        placeholder="Código"
                        maxLength={60}
                      />
                    </Col>
                    <Col lg={5} md={12} sm={12} style={{ marginTop: 10 }}>
                      <Input
                        label="Descrição"
                        name="descricao"
                        placeholder="Descrição"
                        maxLength={120}
                      />
                    </Col>
                    <Col lg={4} md={12} sm={12} style={{ marginTop: 10 }}>
                      <Select
                        label="Filtro Código/Descrição"
                        name="tipoBuscaCodigoDescricao"
                        options={FormatarEnum({
                          enumObj: TipoBuscaCodigoDescricaoEnum,
                        })}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  {(inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiroLote' ||
                    inputOpcaoRelatorio === 'ExtratoPorProdutoLote' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoqueLote' ||
                    inputOpcaoRelatorio ===
                      'ExtratoPorProdutoLocalEstoque') && (
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagLote
                        label="Lotes"
                        name="listaLote"
                      />
                    </Col>
                  )}

                  {(inputOpcaoRelatorio ===
                    'EstoqueSaldoProprioEmPoderProprio' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioPorGrupo' ||
                    inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiro' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoqueLote' ||
                    inputOpcaoRelatorio === 'ExtratoPorProdutoLote' ||
                    inputOpcaoRelatorio === 'ExtratoPorProdutoLocalEstoque' ||
                    inputOpcaoRelatorio === 'ExtratoPorProduto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoque' ||
                    inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiroLote' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioMinimoMaximo' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComCusto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceirosComCusto' ||
                    inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoComCustoConferenciaBlocoH' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoComCustoConferenciaBlocoHComNotas') && (
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagGrupoProdutoServico
                        label="Grupos"
                        name="listaGrupoProdutoServico"
                        utilizavelProdutoServico
                      />
                    </Col>
                  )}

                  {(inputOpcaoRelatorio ===
                    'EstoqueSaldoProprioEmPoderProprio' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioPorGrupo' ||
                    inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiro' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoqueLote' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoque' ||
                    inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiroLote' ||
                    inputOpcaoRelatorio === 'ExtratoPorProdutoLote' ||
                    inputOpcaoRelatorio === 'ExtratoPorProdutoLocalEstoque' ||
                    inputOpcaoRelatorio === 'ExtratoPorProduto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioMinimoMaximo' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComCusto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceirosComCusto' ||
                    inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoComCustoConferenciaBlocoHComNotas') && (
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagLocalEstoque
                        label="Locais de Estoque"
                        name="listaLocalEstoque"
                      />
                    </Col>
                  )}

                  {(inputOpcaoRelatorio ===
                    'EstoqueSaldoComCustoConferenciaBlocoH' ||
                    inputOpcaoRelatorio.includes('Terceiro') ||
                    (inputOpcaoRelatorio.includes('Extrato') &&
                      !inputOpcaoRelatorio.includes('Movimento'))) && (
                    <Col lg={12} md={12} sm={12} style={{ marginTop: 10 }}>
                      <InputAutoCompleteTagPessoa
                        label="Pessoas"
                        name="listaPessoa"
                      />
                    </Col>
                  )}
                </Row>

                {inputOpcaoRelatorio !==
                  'EstoqueResumoCustoPorTipoEstoqueSped' && (
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ marginTop: 10 }}
                  >
                    <InputAutoCompleteTagMarcaProduto
                      label="Marcas de Produto"
                      name="listaMarcaProduto"
                    />
                  </Col>
                )}

                {inputOpcaoRelatorio ===
                  'EstoqueSaldoComCustoConferenciaBlocoHComNotas' && (
                  <Row style={{ marginTop: 10 }}>
                    <Col lg={6} md={4} sm={6}>
                      <Select
                        label="Formato Geraçäo Valores H20"
                        name="formatoGeracaoValoresH20"
                        options={FormatarEnum({
                          enumObj: BlocoHFormaGeracaoValoresEnum,
                        })}
                      />
                    </Col>
                  </Row>
                )}

                <Row
                  style={{
                    alignItems: `${
                      inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioMinimoMaximo'
                        ? 'flex-start'
                        : 'flex-end'
                    }`,
                    marginTop: 30,
                  }}
                >
                  {(inputOpcaoRelatorio ===
                    'EstoqueSaldoProprioEmPoderProprio' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioPorGrupo' ||
                    inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiro' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoqueLote' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceiroPorLocalEstoque' ||
                    inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiroLote' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioMinimoMaximo' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComCusto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioTerceirosComCusto' ||
                    inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoComCustoConferenciaBlocoH' ||
                    inputOpcaoRelatorio ===
                      'EstoqueSaldoComCustoConferenciaBlocoHComNotas') && (
                    <Col xl={3} lg={3} md={6} sm={12} style={{ marginTop: 10 }}>
                      <Select
                        label="Ordenação"
                        name="tipoOrdenacao"
                        options={FormatarEnum({
                          enumObj: TipoOrdenacaoEnum,
                        })}
                      />
                    </Col>
                  )}

                  <Col
                    xl={5}
                    lg={9}
                    md={6}
                    sm={12}
                    className="div-checkbox"
                    style={{ marginTop: 10 }}
                  >
                    {(inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprio' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoProprioEmPoderProprioPorGrupo' ||
                      inputOpcaoRelatorio === 'EstoqueSaldoProprioTerceiro' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoProprioTerceiroPorLocalEstoqueLote' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoProprioTerceiroLote' ||
                      inputOpcaoRelatorio === 'ExtratoPorProdutoLote' ||
                      inputOpcaoRelatorio === 'ExtratoPorProdutoLocalEstoque' ||
                      inputOpcaoRelatorio === 'ExtratoPorProduto' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoProprioEmPoderProprioMinimoMaximo' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoProprioEmPoderProprioComCusto' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoProprioTerceirosComCusto' ||
                      inputOpcaoRelatorio === 'ExtratoDeMovimentosComCusto' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoComCustoConferenciaBlocoH' ||
                      inputOpcaoRelatorio ===
                        'EstoqueSaldoComCustoConferenciaBlocoHComNotas') && (
                      <>
                        <CheckboxContainer>
                          <InputCheckbox
                            label="Somente Produtos com Saldo"
                            name="somenteProdutosComSaldo"
                          />
                        </CheckboxContainer>
                      </>
                    )}

                    {inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioComNecessidadeCompra' && (
                      <>
                        <CheckboxContainer>
                          <InputCheckbox
                            label="Somente Produtos com Necessidade de Compra"
                            name="somenteProdutosComNecessidadeCompra"
                          />
                        </CheckboxContainer>
                      </>
                    )}

                    {inputOpcaoRelatorio ===
                      'EstoqueSaldoProprioEmPoderProprioMinimoMaximo' && (
                      <>
                        <CheckboxContainer>
                          <InputCheckbox
                            label="Somente Produtos que atingiram o estoque Mínimo"
                            name="somenteProdutosEstoqueMinimo"
                          />
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <InputCheckbox
                            label="Somente Produtos que atingiram o estoque Máximo"
                            name="somenteProdutosEstoqueMaximo"
                          />
                        </CheckboxContainer>
                      </>
                    )}
                  </Col>

                  <Col xl={3} lg={3} md={6} sm={12}>
                    {inputOpcaoRelatorio !== 'ExtratoDeMovimentosComCusto' && (
                      <>
                        <CheckboxContainer>
                          <InputCheckbox
                            label="Itens com Reserva (Comprometimento)"
                            name="reservaComprometimento"
                          />
                        </CheckboxContainer>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </FormCia>
      </DivisorRelatorio>

      {telaPequena && (
        <ContainerButtons
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <Button
            onClick={handleExportCSV}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <RiFileExcel2Line />
              <span style={{ marginLeft: 10 }}>Exportar CSV</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleExportPlanilha}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <SiMicrosoftexcel />
              <span style={{ marginLeft: 10 }}>Exp. Planilha</span>
            </TextoLoading>
          </Button>

          <Button
            onClick={handleVisualizarPdf}
            type="button"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TextoLoading loading={loading}>
              <ImFilePdf />
              <span style={{ marginLeft: 10 }}>Visualizar PDF</span>
            </TextoLoading>
          </Button>
        </ContainerButtons>
      )}
    </Container>
  );
};

export default EstoqueRelatorio;
