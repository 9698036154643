/* eslint-disable array-callback-return */
import {
  AberturaFechamentoCaixaEnum,
  IContaParcelaValoresInserir,
  IContaValoresInserir,
  IFrenteCaixaPdvFinalizarVendaPagamentoLista,
  IFrenteCaixaPdvFinalizarVendaParcelaLista,
  IFrenteCaixaPdvFinalizarVendaValoresInserir,
  IFrenteCaixaPdvItemLista,
  IFrenteCaixaPdvRepresentanteLista,
  INotaFiscalSaidaComercialRepresentanteValoresInserir,
  INotaFiscalSaidaItemValoresInserir,
  IPedidoVendaComercialRepresentanteValoresInserir,
  IPedidoVendaItemValoresInserir,
} from '@elogestor/util';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { useNavigate } from 'react-router-dom';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import GetValidationErrors from '../../../../../../Util/Erro/GetValidationErrors';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import { UseRedirecionar } from '../../../../../../Hooks/RedirecionarContext';
import FrenteCaixaPdvObterNotaFiscalSaidaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterNotaFiscalSaidaComunicador';
import { UseFrenteCaixaPdv } from './FrenteCaixaPdvHook';
import FrenteCaixaPdvObterPedidoVendaComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterPedidoVendaComunicador';
import TerminalCaixaPossuiIntegracaoTefComunicador from '../../../../../../Comunicador/Comercial/Vendas/TerminalCaixa/Comunicador/TerminalCaixaPossuiIntegracaoTefComunicador';
import FrenteCaixaPdvObterRetiradaConsignadoComunicador from '../../../../../../Comunicador/Comercial/Vendas/FrenteCaixaPdv/Comunicador/FrenteCaixaPdvObterRetiradaConsignadoComunicador';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseSelecaoCaixa } from './SelecaoCaixaHook';
import AberturaFechamentoCaixaComunicador from '../../../../../../Comunicador/Comercial/Vendas/AberturaFechamentoCaixa/Comunicador/AberturaFechamentoCaixaComunicador';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { redirecionar } = UseRedirecionar();
  const {
    setListaItem,
    setItemSelecionado,
    idNotaFiscalSaida,
    idPreVenda,
    listaIdItemConsignado,
    setPossuiTerminalCaixaComIntegracaoTef,
  } = UseFrenteCaixaPdv();
  const parametros = UseParametros();
  const navigate = useNavigate();
  const { selecionarCaixa } = UseSelecaoCaixa();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);
  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosDuplicados,
        dadosRecuperados,
        dadosPadrao,
        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      const idEditar = getIdDetalheRegistro();

      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IFrenteCaixaPdvFinalizarVendaValoresInserir
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;

              if (!idEditar) {
                const element = dadosObrigatorios[key];
                formRef.current?.setFieldValorInicial(key, element);
              }
            }
          });
        }
      }

      async function SetarDadosDuplicados(): Promise<void> {
        const dados = {
          ...dadosDuplicados,
        };

        await formRef.current?.setDataDuplicar(dados);
        SetarDadosObrigatorios();
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();
        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        if (!idNotaFiscalSaida && !idPreVenda && !listaIdItemConsignado) return;

        if (idNotaFiscalSaida) {
          const response =
            await FrenteCaixaPdvObterNotaFiscalSaidaComunicador.show({
              id: idNotaFiscalSaida,
            });

          const listaRepresentanteFormatada =
            response.notaFiscalSaidaComercial.listaNotaFiscalSaidaComercialRepresentante.map(
              (
                representante: INotaFiscalSaidaComercialRepresentanteValoresInserir
              ) => {
                const retorno: IFrenteCaixaPdvRepresentanteLista = {
                  id: String(representante.pessoaRepresentante?.id),
                  codigo: String(representante.pessoaRepresentante?.codigo),
                  nomeRazaoSocial: String(
                    representante.pessoaRepresentante?.nomeRazaoSocial
                  ),
                };

                return retorno;
              }
            );

          const listaItemFormatada = response.listaNotaFiscalSaidaItem.map(
            (item: INotaFiscalSaidaItemValoresInserir) => {
              const retorno: IFrenteCaixaPdvItemLista = {
                id: item.id,
                sequencia: item.ordem,
                produto: {
                  id: String(item.produto?.id),
                  codigo: String(item.produto?.codigo),
                  descricao: String(item.produto?.descricao),
                  produtoEstoque: {
                    unidadeMedida: {
                      id: String(item.unidadeMedida?.id),
                      sigla: String(item.unidadeMedida?.sigla),
                      descricao: String(item.unidadeMedida?.descricao),
                    },
                  },
                },

                quantidade: Number(item.quantidade),
                valorUnitario: Number(item.valorUnitario),
                subTotal: Number(item.valorTotalProduto),
                percentualDesconto: Number(0),
                valorDesconto: Number(item.valorDesconto),
                percentualAcrescimo: Number(0),
                valorAcrescimo: Number(item.valorOutrasDespesas),
                valorTotalItem: Number(item.valorTotalBruto),

                regraEscolhaTabelaPreco: item.notaFiscalSaidaItemComercial
                  ?.regraEscolhaTabelaPreco as any,
                valorUnitarioTabelaPreco:
                  item.notaFiscalSaidaItemComercial?.valorUnitarioTabelaPreco,
                utilizarValorTabelaPreco: Boolean(
                  item.notaFiscalSaidaItemComercial?.utilizarValorTabelaPreco
                ),

                localEstoque:
                  item.notaFiscalSaidaItemEstoque
                    ?.listaNotaFiscalSaidaItemEstoqueLocalEstoque &&
                  item.notaFiscalSaidaItemEstoque
                    ?.listaNotaFiscalSaidaItemEstoqueLocalEstoque.length > 0 &&
                  item.notaFiscalSaidaItemEstoque
                    ?.listaNotaFiscalSaidaItemEstoqueLocalEstoque[0]
                    .localEstoque
                    ? {
                        id: String(
                          item.notaFiscalSaidaItemEstoque
                            .listaNotaFiscalSaidaItemEstoqueLocalEstoque[0]
                            .localEstoque.id
                        ),
                        descricao:
                          item.notaFiscalSaidaItemEstoque
                            .listaNotaFiscalSaidaItemEstoqueLocalEstoque[0]
                            .localEstoque.descricao,
                        disponivelParaVenda:
                          item.notaFiscalSaidaItemEstoque
                            .listaNotaFiscalSaidaItemEstoqueLocalEstoque[0]
                            .localEstoque?.disponivelParaVenda,
                      }
                    : undefined,
                lote:
                  item.notaFiscalSaidaItemEstoque
                    ?.listaNotaFiscalSaidaItemEstoqueLocalEstoque &&
                  item.notaFiscalSaidaItemEstoque
                    ?.listaNotaFiscalSaidaItemEstoqueLocalEstoque.length > 0 &&
                  item.notaFiscalSaidaItemEstoque
                    ?.listaNotaFiscalSaidaItemEstoqueLocalEstoque[0].lote
                    ? {
                        id: String(
                          item.notaFiscalSaidaItemEstoque
                            .listaNotaFiscalSaidaItemEstoqueLocalEstoque[0].lote
                            .id
                        ),
                        codigo:
                          item.notaFiscalSaidaItemEstoque
                            .listaNotaFiscalSaidaItemEstoqueLocalEstoque[0].lote
                            .codigo,
                      }
                    : undefined,

                informacoesAdicionaisProduto: String(
                  item.informacoesAdicionaisProduto
                ),
              };

              return retorno;
            }
          );

          const itemSelecionadoFormatado = listaItemFormatada[0];

          const listaPagamentoSalvoFormatado: IFrenteCaixaPdvFinalizarVendaPagamentoLista[] =
            [];
          if (response.listaConta && response.listaConta.length > 0) {
            for (
              let i = 0;
              i < response.listaConta[0].listaContaParcela.length;
              i++
            ) {
              const contaParcela = response.listaConta[0].listaContaParcela[i];

              if (
                contaParcela.listaContaParcelaLiquidacao &&
                contaParcela.listaContaParcelaLiquidacao.length > 0
              ) {
                for (
                  let j = 0;
                  j < contaParcela.listaContaParcelaLiquidacao.length;
                  j++
                ) {
                  const liquidacao =
                    contaParcela.listaContaParcelaLiquidacao[j];

                  const pagamentoSalvoExistente =
                    listaPagamentoSalvoFormatado.find(
                      (e) =>
                        e.formaPagamento?.id ===
                          liquidacao.formaPagamento?.id &&
                        ((!e.portador && !liquidacao.portador) ||
                          (e.portador &&
                            e.portador?.id === liquidacao.portador?.id)) &&
                        ((!e.bandeiraCartao && !contaParcela.bandeiraCartao) ||
                          (e.bandeiraCartao &&
                            e.bandeiraCartao?.id ===
                              contaParcela.bandeiraCartao?.id)) &&
                        ((!e.idPagamentoTef &&
                          !liquidacao?.movimentoPortadorTef?.idPagamentoTef) ||
                          e.idPagamentoTef ===
                            liquidacao?.movimentoPortadorTef?.idPagamentoTef)
                    );

                  if (pagamentoSalvoExistente) {
                    pagamentoSalvoExistente.valor += Number(liquidacao.valor);
                    pagamentoSalvoExistente.quantidadeParcelas += 1;
                    pagamentoSalvoExistente.listaParcelas.push({
                      sequencia:
                        pagamentoSalvoExistente.listaParcelas.length + 1,
                      quantidadeParcelas:
                        pagamentoSalvoExistente.listaParcelas.length + 1,
                      dataVencimento: liquidacao.dataVencimento,
                      valor: Number(liquidacao.valor),
                    });
                    pagamentoSalvoExistente.listaContaAdiantamento.push({
                      idContaParcela: contaParcela.id,
                      ...liquidacao.contaAdiantamento,
                    });
                  } else {
                    const pagamentoSalvo: IFrenteCaixaPdvFinalizarVendaPagamentoLista =
                      {
                        idPagamentoTef:
                          liquidacao.movimentoPortadorTef?.idPagamentoTef,
                        idTef: liquidacao.movimentoPortadorTef?.idTef,
                        situacaoPagamento:
                          liquidacao.movimentoPortadorTef?.statusTef,
                        formaPagamento: liquidacao.formaPagamento,
                        dataHoraEmissao: response.listaConta[0].dataHoraEmissao,
                        valor: Number(liquidacao.valor),
                        quantidadeParcelas: 1,
                        dataPrimeiraParcela: new Date(),
                        valorPrimeiraParcela: 0,
                        bandeiraCartao: contaParcela.bandeiraCartao,
                        portador: liquidacao.portador,

                        listaParcelas: [
                          {
                            sequencia: 1,
                            quantidadeParcelas: 1,
                            dataVencimento: contaParcela.dataVencimento,
                            valor: Number(liquidacao.valor),
                          },
                        ],
                        listaContaAdiantamento: [
                          {
                            idContaParcela: contaParcela.id,
                            ...liquidacao.contaAdiantamento,
                          },
                        ],
                      };

                    listaPagamentoSalvoFormatado.push(pagamentoSalvo);
                  }
                }
              } else {
                const pagamentoSalvoExistente =
                  listaPagamentoSalvoFormatado.find(
                    (e) =>
                      e.formaPagamento.id === contaParcela.formaPagamento.id &&
                      ((!e.portador && !contaParcela.portador) ||
                        (e.portador &&
                          e.portador.id === contaParcela.portador.id)) &&
                      ((!e.bandeiraCartao && !contaParcela.bandeiraCartao) ||
                        (e.bandeiraCartao &&
                          e.bandeiraCartao.id ===
                            contaParcela.bandeiraCartao.id))
                  );

                if (pagamentoSalvoExistente) {
                  pagamentoSalvoExistente.valor += Number(contaParcela.valor);
                  pagamentoSalvoExistente.quantidadeParcelas += 1;
                  pagamentoSalvoExistente.listaParcelas.push({
                    sequencia: pagamentoSalvoExistente.listaParcelas.length + 1,
                    quantidadeParcelas:
                      pagamentoSalvoExistente.listaParcelas.length + 1,
                    dataVencimento: contaParcela.dataVencimento,
                    valor: contaParcela.valor,
                  });
                } else {
                  const pagamentoSalvo: IFrenteCaixaPdvFinalizarVendaPagamentoLista =
                    {
                      formaPagamento: contaParcela.formaPagamento,
                      dataHoraEmissao: response.listaConta[0].dataHoraEmissao,
                      valor: Number(contaParcela.valorTotalParcela),
                      quantidadeParcelas: 1,
                      dataPrimeiraParcela: new Date(),
                      valorPrimeiraParcela: 0,
                      bandeiraCartao: contaParcela.bandeiraCartao,
                      portador: contaParcela.portador,

                      listaParcelas: [
                        {
                          sequencia: 1,
                          quantidadeParcelas: 1,
                          dataVencimento: contaParcela.dataVencimento,
                          valor: contaParcela.valorTotalParcela,
                        },
                      ],
                    };

                  listaPagamentoSalvoFormatado.push(pagamentoSalvo);
                }
              }
            }
          }

          await formRef.current?.setDataInicialSemExecutarEvento({
            financeiroConferido: response.financeiroConferido,
            numero: response.numero,
            serieCodigo: response.serie.codigo,
            dataHoraEmissao: response.dataHoraEmissao,
            situacao: response.situacao,
            chaveAcesso: response.chaveAcesso,

            descontoFormaCalculoPorRateio:
              response.descontoFormaCalculoPorRateio,
            outrasDespesasFormaCalculoPorRateio:
              response.outrasDespesasFormaCalculoPorRateio,
            percentualDescontoGeral:
              (Number(response.valorDesconto) * 100) /
              Number(response.valorTotalProdutos),
            percentualAcrescimoGeral:
              (Number(response.valorOutrasDespesas) * 100) /
              Number(response.valorTotalProdutos),
            listaRepresentante: listaRepresentanteFormatada,

            cnpjCpf: response.notaFiscalSaidaPessoa.cnpjCpfVirtual,
            cliente: response.notaFiscalSaidaPessoa.pessoa,
            cnpjCpfNoDocumentoFiscal:
              response.notaFiscalSaidaPessoa.cnpjCpfNoDocumentoFiscal,

            quantidade: itemSelecionadoFormatado.quantidade,
            valorUnitario: itemSelecionadoFormatado.valorUnitario,
            subTotal: itemSelecionadoFormatado.subTotal,
            regraEscolhaTabelaPreco:
              itemSelecionadoFormatado.regraEscolhaTabelaPreco,

            valorSubTotalGeral: Number(response.valorTotalProdutos),
            valorDescontoGeral: Number(response.valorDesconto),
            valorAcrescimoGeral: Number(response.valorOutrasDespesas),
            valorTotalGeral: Number(response.valorTotalBruto),

            listaPagamentoSalvo: listaPagamentoSalvoFormatado,
          });

          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'quantidade',
            itemSelecionadoFormatado.quantidade
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'valorUnitario',
            itemSelecionadoFormatado.valorUnitario
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'subTotal',
            itemSelecionadoFormatado.subTotal
          );

          setListaItem(listaItemFormatada);
          setItemSelecionado(itemSelecionadoFormatado);
        }

        if (!idNotaFiscalSaida && idPreVenda) {
          const response = await FrenteCaixaPdvObterPedidoVendaComunicador.show(
            { id: idPreVenda }
          );

          const listaRepresentanteFormatada =
            response.pedidoVendaComercial.listaPedidoVendaComercialRepresentante.map(
              (
                representante: IPedidoVendaComercialRepresentanteValoresInserir
              ) => {
                const retorno: IFrenteCaixaPdvRepresentanteLista = {
                  id: String(representante.pessoaRepresentante?.id),
                  codigo: String(representante.pessoaRepresentante?.codigo),
                  nomeRazaoSocial: String(
                    representante.pessoaRepresentante?.nomeRazaoSocial
                  ),
                };

                return retorno;
              }
            );

          const listaItemFormatada = response.listaPedidoVendaItem.map(
            (item: IPedidoVendaItemValoresInserir) => {
              const retorno: IFrenteCaixaPdvItemLista = {
                id: item.id,
                sequencia: item.ordem,
                produto: {
                  id: String(item.produto?.id),
                  codigo: String(item.produto?.codigo),
                  descricao: String(item.produto?.descricao),
                  produtoEstoque: {
                    unidadeMedida: {
                      id: String(item.unidadeMedida?.id),
                      sigla: String(item.unidadeMedida?.sigla),
                      descricao: String(item.unidadeMedida?.descricao),
                    },
                  },
                },

                quantidade: Number(item.quantidade),
                valorUnitario: Number(item.valorUnitario),
                subTotal: Number(item.valorTotalProduto),
                percentualDesconto: Number(0),
                valorDesconto: Number(item.valorDesconto),
                percentualAcrescimo: Number(0),
                valorAcrescimo: Number(item.valorOutrasDespesas),
                valorTotalItem: Number(item.valorTotalBruto),

                regraEscolhaTabelaPreco: item.pedidoVendaItemComercial
                  ?.regraEscolhaTabelaPreco as any,
                valorUnitarioTabelaPreco:
                  item.pedidoVendaItemComercial?.valorUnitarioTabelaPreco,
                utilizarValorTabelaPreco: Boolean(
                  item.pedidoVendaItemComercial?.utilizarValorTabelaPreco
                ),

                localEstoque:
                  item.pedidoVendaItemEstoque
                    ?.listaPedidoVendaItemEstoqueLocalEstoque &&
                  item.pedidoVendaItemEstoque
                    ?.listaPedidoVendaItemEstoqueLocalEstoque.length > 0 &&
                  item.pedidoVendaItemEstoque
                    ?.listaPedidoVendaItemEstoqueLocalEstoque[0].localEstoque
                    ? {
                        id: String(
                          item.pedidoVendaItemEstoque
                            .listaPedidoVendaItemEstoqueLocalEstoque[0]
                            .localEstoque.id
                        ),
                        descricao:
                          item.pedidoVendaItemEstoque
                            .listaPedidoVendaItemEstoqueLocalEstoque[0]
                            .localEstoque.descricao,
                        disponivelParaVenda:
                          item.pedidoVendaItemEstoque
                            .listaPedidoVendaItemEstoqueLocalEstoque[0]
                            .localEstoque?.disponivelParaVenda,
                      }
                    : undefined,
                lote:
                  item.pedidoVendaItemEstoque
                    ?.listaPedidoVendaItemEstoqueLocalEstoque &&
                  item.pedidoVendaItemEstoque
                    ?.listaPedidoVendaItemEstoqueLocalEstoque.length > 0 &&
                  item.pedidoVendaItemEstoque
                    ?.listaPedidoVendaItemEstoqueLocalEstoque[0].lote
                    ? {
                        id: String(
                          item.pedidoVendaItemEstoque
                            .listaPedidoVendaItemEstoqueLocalEstoque[0].lote.id
                        ),
                        codigo:
                          item.pedidoVendaItemEstoque
                            .listaPedidoVendaItemEstoqueLocalEstoque[0].lote
                            .codigo,
                      }
                    : undefined,

                informacoesAdicionaisProduto: String(
                  item.informacoesAdicionaisProduto
                ),
              };

              return retorno;
            }
          );

          const itemSelecionadoFormatado = listaItemFormatada[0];

          const listaPagamentoFormatado: IFrenteCaixaPdvFinalizarVendaPagamentoLista[] =
            [];

          if (response.listaConta && response.listaConta.length > 0) {
            const conta = response.listaConta[0] as IContaValoresInserir;

            if (conta.listaContaParcela && conta.listaContaParcela.length > 0) {
              conta.listaContaParcela
                .filter(
                  (contaParcela: IContaParcelaValoresInserir) =>
                    contaParcela.formaPagamento
                )
                .map((contaParcela: IContaParcelaValoresInserir) => {
                  let contaPagamentoFormaPagamento =
                    listaPagamentoFormatado.find(
                      (contaPagamento) =>
                        contaPagamento.formaPagamento.id ===
                        contaParcela.formaPagamento?.id
                    );

                  if (!contaPagamentoFormaPagamento) {
                    contaPagamentoFormaPagamento = {
                      formaPagamento: contaParcela.formaPagamento as any,
                      dataHoraEmissao: conta.dataHoraEmissao,
                      valor: 0,
                      quantidadeParcelas: 0,
                      dataPrimeiraParcela: new Date(
                        contaParcela.dataVencimento
                      ),
                      valorPrimeiraParcela: 0,
                      bandeiraCartao: contaParcela.bandeiraCartao
                        ? {
                            id: String(contaParcela.bandeiraCartao.id),
                            descricao: String(
                              contaParcela.bandeiraCartao.descricao
                            ),
                          }
                        : undefined,
                      condicaoPagamento: conta.condicaoPagamento
                        ? conta.condicaoPagamento
                        : undefined,
                      portador: contaParcela.portador
                        ? {
                            id: String(contaParcela.portador.id),
                            descricao: String(contaParcela.portador.descricao),
                          }
                        : undefined,

                      listaParcelas: [],
                    };

                    listaPagamentoFormatado.push(contaPagamentoFormaPagamento);
                  }

                  contaPagamentoFormaPagamento.valor += Number(
                    contaParcela.valorTotalParcela
                  );

                  const parcela: IFrenteCaixaPdvFinalizarVendaParcelaLista = {
                    sequencia: 0,
                    quantidadeParcelas:
                      contaPagamentoFormaPagamento.quantidadeParcelas,
                    dataVencimento: new Date(contaParcela.dataVencimento),
                    valor: contaParcela.valorTotalParcela,
                  };

                  contaPagamentoFormaPagamento.listaParcelas.push(parcela);
                });
            }

            for (let i = 0; i < listaPagamentoFormatado.length; i++) {
              const pagamentoFormatado = listaPagamentoFormatado[i];

              pagamentoFormatado.quantidadeParcelas =
                pagamentoFormatado.listaParcelas.length;

              for (
                let j = 0;
                j < pagamentoFormatado.listaParcelas.length;
                j++
              ) {
                const parcela = pagamentoFormatado.listaParcelas[j];

                parcela.sequencia = j + 1;
                parcela.quantidadeParcelas =
                  pagamentoFormatado.listaParcelas.length;
              }
            }
          }

          await formRef.current?.setDataInicial({
            numero: response.numero,
            dataHoraEmissao: response.dataHoraEmissao,
            situacao: response.situacaoPedidoVendaNotaFiscalSaida,

            descontoFormaCalculoPorRateio:
              response.descontoFormaCalculoPorRateio,
            outrasDespesasFormaCalculoPorRateio:
              response.outrasDespesasFormaCalculoPorRateio,
            percentualDescontoGeral:
              (Number(response.valorDesconto) * 100) /
              Number(response.valorTotalProdutos),
            percentualAcrescimoGeral:
              (Number(response.valorOutrasDespesas) * 100) /
              Number(response.valorTotalProdutos),
            listaRepresentante: listaRepresentanteFormatada,
            listaPagamentoSalvo: listaPagamentoFormatado,

            cnpjCpf: response.pedidoVendaPessoa.cnpjCpfVirtual,
            cliente: response.pedidoVendaPessoa.pessoa,
            cnpjCpfNoDocumentoFiscal:
              response.pedidoVendaPessoa.cnpjCpfNoDocumentoFiscal,

            quantidade: itemSelecionadoFormatado.quantidade,
            valorUnitario: itemSelecionadoFormatado.valorUnitario,
            subTotal: itemSelecionadoFormatado.subTotal,
            regraEscolhaTabelaPreco:
              itemSelecionadoFormatado.regraEscolhaTabelaPreco,

            valorSubTotalGeral: Number(response.valorTotalProdutos),
            valorDescontoGeral: Number(response.valorDesconto),
            valorAcrescimoGeral: Number(response.valorOutrasDespesas),
            valorTotalGeral: Number(response.valorTotalBruto),
          });

          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'quantidade',
            itemSelecionadoFormatado.quantidade
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'valorUnitario',
            itemSelecionadoFormatado.valorUnitario
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'subTotal',
            itemSelecionadoFormatado.subTotal
          );

          setListaItem(listaItemFormatada);
          setItemSelecionado(itemSelecionadoFormatado);
        }

        if (
          !idNotaFiscalSaida &&
          listaIdItemConsignado &&
          listaIdItemConsignado.length > 0
        ) {
          const response =
            await FrenteCaixaPdvObterRetiradaConsignadoComunicador.show({
              params: {
                listaIdItemConsignado,
              },
            });

          const itemSelecionadoFormatado = response.listaPedidoVendaItem[0];

          const listaItemFormatada = response.listaPedidoVendaItem.map(
            (item: any) => {
              const retorno = {
                ...item,
                quantidade: Number(item.quantidade),
                valorUnitario: Number(item.valorUnitario),
                subTotal: Number(item.subTotal),
                percentualDesconto: Number(item.percentualDesconto),
                valorDesconto: Number(item.valorDesconto),
                percentualAcrescimo: Number(item.percentualAcrescimo),
                valorAcrescimo: Number(item.valorAcrescimo),
                valorTotalItem: Number(item.valorTotalItem),

                valorUnitarioTabelaPreco: Number(item.valorUnitarioTabelaPreco),
              };

              return retorno;
            }
          );

          await formRef.current?.setDataInicial({
            ...response,
            numero: response.numero,

            percentualDescontoGeral: Number(response.percentualDescontoGeral),
            percentualAcrescimoGeral: Number(response.percentualAcrescimoGeral),

            quantidade: Number(itemSelecionadoFormatado.quantidade),
            valorUnitario: Number(itemSelecionadoFormatado.valorUnitario),
            subTotal: Number(itemSelecionadoFormatado.subTotal),
            regraEscolhaTabelaPreco:
              itemSelecionadoFormatado.regraEscolhaTabelaPreco,

            valorSubTotalGeral: Number(response.valorSubTotalGeral),
            valorDescontoGeral: Number(response.valorDescontoGeral),
            valorAcrescimoGeral: Number(response.valorAcrescimoGeral),
            valorTotalGeral: Number(response.valorTotalGeral),
          });

          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'quantidade',
            itemSelecionadoFormatado.quantidade
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'valorUnitario',
            itemSelecionadoFormatado.valorUnitario
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            'subTotal',
            itemSelecionadoFormatado.subTotal
          );

          setListaItem(listaItemFormatada);
          setItemSelecionado(itemSelecionadoFormatado);
        }

        SetarDadosObrigatorios();
      }

      async function SetarDadosPadrao(): Promise<void> {
        await formRef.current?.setDataInicial({
          valorSubTotalGeral: 0,
          valorDescontoGeral: 0,
          valorAcrescimoGeral: 0,
          valorTotalGeral: 0,
          descontoFormaCalculoPorRateio: true,
          outrasDespesasFormaCalculoPorRateio: true,

          listaRepresentante: [],

          ...dadosPadrao,
        });

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosDuplicados) {
            await SetarDadosDuplicados();
          } else if (dadosRecuperados) {
            await SetarDadosRecuperados();
          } else if (idNotaFiscalSaida || idPreVenda || listaIdItemConsignado) {
            await SetarDadosBackend();
          } else {
            await SetarDadosPadrao();
          }

          if (
            !parametros.AberturaFechamentoCaixa ||
            parametros.AberturaFechamentoCaixa ===
              AberturaFechamentoCaixaEnum.naoObrigaAberturaFechamentoCaixa
          ) {
            setPossuiTerminalCaixaComIntegracaoTef(false);
          } else {
            const storage = localStorage.getItem('@EloGestorle:turno');
            let turno = JSON.parse(String(storage));

            if (turno) {
              try {
                const responseTurno =
                  await AberturaFechamentoCaixaComunicador.show({
                    id: turno.id,
                  });

                if (!responseTurno) {
                  localStorage.removeItem('@EloGestorle:turno');
                  turno = undefined;
                }
              } catch (error) {
                localStorage.removeItem('@EloGestorle:turno');
                turno = undefined;
              }
            }

            if (
              turno &&
              (((parametros.AberturaFechamentoCaixa ===
                AberturaFechamentoCaixaEnum.caixaFechadoUmaVezDia ||
                parametros.AberturaFechamentoCaixa ===
                  AberturaFechamentoCaixaEnum.caixaFechadoDuasVezesDia ||
                parametros.AberturaFechamentoCaixa ===
                  AberturaFechamentoCaixaEnum.caixaFechadoTresVezesDia) &&
                new Date(turno.dataHoraAbertura) >=
                  new Date().PrimeiraHoraDia()) ||
                parametros.AberturaFechamentoCaixa ===
                  AberturaFechamentoCaixaEnum.aberturaFechamentoIndeterminado)
            ) {
              formRef.current?.setFieldValue(
                'idAberturaFechamentoCaixa',
                turno.id
              );
              formRef.current?.setFieldValue(
                'terminalCaixa',
                turno.terminalCaixa
              );
              formRef.current?.setFieldValue(
                'usuarioAbertura',
                turno.usuarioAbertura
              );

              const response =
                await TerminalCaixaPossuiIntegracaoTefComunicador.show({
                  id: turno.terminalCaixa.id,
                });

              if (response) {
                setPossuiTerminalCaixaComIntegracaoTef(true);
              } else {
                setPossuiTerminalCaixaComIntegracaoTef(false);
              }
            } else {
              let dataHoraAbertura: Date | undefined;

              if (
                parametros.AberturaFechamentoCaixa ===
                  AberturaFechamentoCaixaEnum.caixaFechadoUmaVezDia ||
                parametros.AberturaFechamentoCaixa ===
                  AberturaFechamentoCaixaEnum.caixaFechadoDuasVezesDia ||
                parametros.AberturaFechamentoCaixa ===
                  AberturaFechamentoCaixaEnum.caixaFechadoTresVezesDia
              ) {
                dataHoraAbertura = new Date().PrimeiraHoraDia();
              }

              const responseSelecaoCaixa = await selecionarCaixa({
                dataHoraAbertura,
              });

              if (responseSelecaoCaixa) {
                const storageSelecionado =
                  localStorage.getItem('@EloGestorle:turno');
                const turnoSelecionado = JSON.parse(String(storageSelecionado));

                formRef.current?.setFieldValue(
                  'idAberturaFechamentoCaixa',
                  turnoSelecionado.id
                );
                formRef.current?.setFieldValue(
                  'terminalCaixa',
                  turnoSelecionado.terminalCaixa
                );
                formRef.current?.setFieldValue(
                  'usuarioAbertura',
                  turnoSelecionado.usuarioAbertura
                );

                const response =
                  await TerminalCaixaPossuiIntegracaoTefComunicador.show({
                    id: turnoSelecionado.terminalCaixa.id,
                  });

                if (response) {
                  setPossuiTerminalCaixaComIntegracaoTef(true);
                } else {
                  setPossuiTerminalCaixaComIntegracaoTef(false);
                }
              } else {
                navigate('/home', { replace: true });
              }
            }
          }

          setTerminouCarregarDados(true);
          handleSetarFocus();

          refresh();
          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });
          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getIdDetalheRegistro,
      handleSetarFocus,
      idNotaFiscalSaida,
      idPreVenda,
      listaIdItemConsignado,
      navigate,
      parametros.AberturaFechamentoCaixa,
      redirecionar,
      refresh,
      selecionarCaixa,
      setItemSelecionado,
      setListaItem,
      setPossuiTerminalCaixaComIntegracaoTef,
    ]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({});

      await schema.validate(data, { abortEarly: false });
      return true;
    } catch (error) {
      const errors = GetValidationErrors(error as any);
      formRef.current?.setErrors(errors);
      return false;
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any): Promise<ISubmitProps> => {
      try {
        setLoading(true);

        const id = getIdDetalheRegistro() || '';
        if (!(await handleValidar(data))) {
          setLoading(false);
          return { id, erro: true };
        }

        await formRef.current?.atualizarDataInicial();
        setLoading(false);
        return { id, erro: false };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { id: '', erro: true };
      }
    },
    [getIdDetalheRegistro, handleValidar]
  );

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
