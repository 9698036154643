import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import { isAfter, parseISO } from 'date-fns';
import { RiDeleteBin6Line } from 'react-icons/ri/index.mjs';
import { PiCertificateBold } from 'react-icons/pi/index.mjs';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import InputCnpjCpf, {
  IOnBlurInputCnpjCpfValidoEvent,
} from '../../../../../Componentes/Inputs/InputCnpjCpf';
import InputCep from '../../../../../Componentes/Inputs/InputCep';
import InputAutoCompleteCidadeUf, {
  IOnChangeItemAtualAutoCompleteCidadeEvent,
} from '../../../../../Componentes/Inputs/AutoComplete/Geral/InputAutoCompleteCidade';
import InputVisualSemValor from '../../../../../Componentes/Inputs/InputVisualSemValor';
import InputInscricaoEstadual from '../../../../../Componentes/Inputs/InputInscricaoEstadual';
import InputTelefone from '../../../../../Componentes/Inputs/TagsInput/InputTelefone';
import InputEmailTag from '../../../../../Componentes/Inputs/TagsInput/InputEmailTag';
import PesquisaEnderecoCepDetalhe from '../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe';
import IEndereco from '../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IEndereco';
import IPesquisaEnderecoCepDetalheRef from '../../../../../Componentes/Inputs/InputCep/PesquisaEnderecoCepDetalhe/Interface/IPesquisaEnderecoCepDetalheRef';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPesquisaPessoaCnpjCpfDetalheRef from '../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPesquisaPessoaCnpjCpfDetalheRef';
import IPessoa from '../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe/Interface/IPessoa';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import { IModalPessoaRef } from '../../../../Comercial/Pessoas/Pessoa/Detalhe/GeralTab/ListaPessoasRepetidas';
import PesquisaPessoaCnpjCpfDetalhe from '../../../../../Componentes/Inputs/InputCnpjCpf/PesquisaPessoaCnpjCpfDetalhe';
import InputLabel from '../../../../../Componentes/Inputs/InputLabel';
import Button from '../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import EmpresaCertificadoDetalhe from './CertificadoDetalhe/Detalhe';
import UseFuncoesPadrao from '../../../../../Componentes/Detalhe/Hooks/UseFuncoesPadrao';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import EmpresaCertificadoHook from './CertificadoDetalhe/Hooks';
import PessoaObterPorCnpjCpfComunicador from '../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterPorCnpjCpfComunicador';
import EmpresaCertificadoComunicador from '../../../../../Comunicador/Configuracao/Empresa/Certificado/Comunicador/EmpresaCertificadoComunicador';
import { UseAutoComplete } from '../../../../../Componentes/Inputs/AutoComplete/AutoCompleteBase/Hooks/AutoCompleteHook';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputSenha from '../../../../../Componentes/Inputs/InputSenha';

const GeralTab: React.FC = () => {
  const {
    getIdDetalheRegistro,
    formRef,
    refresh,
    loading,
    setLoading,
    handleCarregarDados,
    inputRefFocus,
  } = UseForm();
  const idDetalheRegistro = getIdDetalheRegistro();
  const { isJanelaAutoComplete } = UseAutoComplete();
  const { abrirJanela } = UseConfirmacao();
  const { permissoes } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const { handleSalvarContinuar } = UseFuncoesPadrao(permissao);

  const janelaCepRef = useRef<IPesquisaEnderecoCepDetalheRef>(null);
  const janelaCnpjCpfRef = useRef<IPesquisaPessoaCnpjCpfDetalheRef>(null);

  const isCpf = formRef.current?.getFieldValue('cnpjCpfVirtual').length !== 14;

  const modalPessoaRef = useRef<IModalPessoaRef>(null);

  const [uf, setUf] = useState('');

  useEffect(() => {
    if (!isCpf) {
      formRef.current?.setFieldValue('motorista', false);
      formRef.current?.setFieldValue('tecnico', false);
    }
  }, [formRef, isCpf]);

  const handlePesquisarCnpjCpf = useCallback(async (): Promise<void> => {
    const dados = formRef.current?.getDataPesquisa();
    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'empresaEndereco.cidade'
    );

    await janelaCnpjCpfRef.current?.pesquisarCnpjCpf({
      cnpjCpf: dados.cnpjCpfVirtual,
      inscricaoEstadual: dados.inscricaoEstadual,
      nomeRazaoSocial: dados.nomeRazaoSocial,
      nomeFantasia: dados.nomeFantasia,
      cep: dados.empresaEndereco.cep,

      cidade: {
        id: cidade?.id || '',
        nome: cidade?.nome,
        estado: {
          sigla: cidade?.estado.sigla,
        },
      },
      logradouro: dados.empresaEndereco.logradouro,
      bairro: dados.empresaEndereco.bairro,
      complemento: dados.empresaEndereco.complemento,
      numero: dados.empresaEndereco.numero,

      listaTelefone: dados.listaEmpresaTelefone.listaValor,
      listaEmail: dados.listaEmpresaEmail.listaValor,
    });
  }, [formRef]);

  const handleOnSelecionarPessoa = useCallback(
    (pessoa: IPessoa) => {
      formRef.current?.setFieldValue('cnpjCpfVirtual', pessoa.cnpjCpf);
      formRef.current?.setFieldValue('nomeRazaoSocial', pessoa.nomeRazaoSocial);
      formRef.current?.setFieldValue(
        'inscricaoEstadual',
        pessoa.inscricaoEstadual
      );
      formRef.current?.setFieldValue('nomeFantasia', pessoa.nomeFantasia);
      formRef.current?.setFieldValue('empresaEndereco.cep', pessoa.cep);
      formRef.current?.setFieldValue('empresaEndereco.cidade', pessoa.cidade);
      formRef.current?.setFieldValue(
        'empresaEndereco.logradouro',
        pessoa.logradouro
      );
      formRef.current?.setFieldValue('empresaEndereco.bairro', pessoa.bairro);
      formRef.current?.setFieldValue(
        'empresaEndereco.complemento',
        pessoa.complemento
      );
      formRef.current?.setFieldValue('empresaEndereco.numero', pessoa.numero);
      formRef.current?.setFieldValue(
        'listaEmpresaTelefone',
        pessoa.listaTelefone
      );
      formRef.current?.setFieldValue('listaEmpresaEmail', pessoa.listaEmail);
    },
    [formRef]
  );

  const handleChangeCnpjCpf = useCallback(
    async (event: IOnBlurInputCnpjCpfValidoEvent) => {
      try {
        if (!event.isZero && event.mudou && event.valor) {
          const response = await PessoaObterPorCnpjCpfComunicador.index({
            params: {
              cnpjCpf: event.valor,
              listaIdRemover: idDetalheRegistro ? [idDetalheRegistro] : [],
            },
          });

          modalPessoaRef.current?.alterarListaPessoa(response);
        }
      } catch (error) {
        TratarErros(error);
      }
    },
    [idDetalheRegistro]
  );

  const handlePesquisarCep = useCallback(async (): Promise<void> => {
    const dados = formRef.current?.getDataPesquisa();

    const cidade = formRef.current?.getFieldValueNomeObjeto(
      'empresaEndereco.cidade'
    );

    await janelaCepRef.current?.pesquisarCep({
      cep: dados.empresaEndereco.cep,
      logradouro: dados.empresaEndereco.logradouro,
      bairro: dados.empresaEndereco.bairro,
      idCidade: cidade?.id || '',
      nomeCidade: cidade?.nome || '',
      siglaEstado: cidade?.estado?.sigla || '',
    });
  }, [formRef]);

  const handleSelecionarEndereco = useCallback(
    (endereco: IEndereco) => {
      formRef.current?.setFieldValue(
        'empresaEndereco.logradouro',
        endereco.logradouro
      );
      formRef.current?.setFieldValue('empresaEndereco.bairro', endereco.bairro);
      formRef.current?.setFieldValue('empresaEndereco.cidade', endereco.cidade);
    },
    [formRef]
  );

  const handleChangeCidade = useCallback(
    (event: IOnChangeItemAtualAutoCompleteCidadeEvent) => {
      setUf(event.itemAtual?.estado.sigla || '');

      formRef.current?.setFieldValue(
        'empresaEndereco.cidade.estado.sigla',
        event.itemAtual?.estado.sigla || ''
      );
    },
    [formRef]
  );

  // #region Certificado

  const possuiCertificado = formRef.current?.getFieldValue(
    'empresaCertificado.emitidoPara'
  );

  const certificadoVencido = formRef.current?.getFieldValue(
    'empresaCertificado.dataHoraValidadeFinal'
  )
    ? !isAfter(
        parseISO(
          formRef.current?.getFieldValue(
            'empresaCertificado.dataHoraValidadeFinal'
          )
        ),
        new Date()
      )
    : false;

  const [showFormModalCertificado, setShowFormModalCertificado] =
    useState(false);

  const handleNovoCertificado = useCallback(async () => {
    const { erro } = await handleSalvarContinuar(isJanelaAutoComplete);
    if (erro) return;

    setShowFormModalCertificado(true);
  }, [handleSalvarContinuar, isJanelaAutoComplete]);

  const handleSalvarFormModal = useCallback((): void => {
    setShowFormModalCertificado(false);
    handleCarregarDados();
  }, [handleCarregarDados]);

  const handleFecharFormModal = useCallback((): void => {
    setShowFormModalCertificado(false);
  }, []);

  const handleExcluirCertificado = useCallback(async () => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>Deseja excluir o certificado?</span>
        ),
      });

      if (resposta) {
        setLoading(true);

        const response = await EmpresaCertificadoComunicador.delete();

        setLoading(false);
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, handleCarregarDados, setLoading]);

  // #endregion Certificado

  return (
    <div>
      <Divisor>
        <Row>
          <Col xl={3} md={12}>
            <InputCnpjCpf
              ref={inputRefFocus}
              label="CNPJ/CPF"
              name="cnpjCpfVirtual"
              placeholder="CNPJ/CPF"
              onBlurValido={async (event) => {
                refresh();
                await handleChangeCnpjCpf(event);
              }}
              onPesquisarCnpjCpf={handlePesquisarCnpjCpf}
              loading={loading}
            />
            <PesquisaPessoaCnpjCpfDetalhe
              handleOnSelecionarPessoa={handleOnSelecionarPessoa}
              ref={janelaCnpjCpfRef}
            />
          </Col>
          <Col xl={3} md={12}>
            <InputInscricaoEstadual
              label="Inscrição Estadual"
              name="inscricaoEstadual"
              placeholder="Inscrição Estadual"
              maxLength={14}
              uf={uf}
              onChangeValue={async ({ valido, value }) => {
                if (valido) {
                  const icmsRef =
                    formRef.current?.getFieldRef('contribuinteIcms');
                  if (icmsRef && value) {
                    icmsRef.checked = true;
                  }
                }
              }}
            />
          </Col>
          <Col xl={3} md={12}>
            <Input
              label="Inscricao Municipal"
              name="inscricaoMunicipal"
              placeholder="Inscricao Municipal"
              maxLength={15}
            />
          </Col>
          <Col xl={3} md={12}>
            <Input
              label="CNAE Principal"
              name="cnaePrincipal"
              placeholder="CNAE Principal"
              maxLength={7}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={4} md={12}>
            <Input
              label="Razão Social/Nome"
              name="nomeRazaoSocial"
              placeholder="Razão Social/Nome"
              maxLength={60}
            />
          </Col>
          <Col xl={4} md={12}>
            <Input
              label="Nome Fantasia"
              name="nomeFantasia"
              placeholder="Nome Fantasia"
              maxLength={60}
            />
          </Col>
          <Col xl={4} md={12}>
            <Input
              label="Nome Sistema"
              name="nomeSistema"
              placeholder="Nome Sistema"
              maxLength={60}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row>
          <Col xl={3} md={12}>
            <InputCep
              name="empresaEndereco.cep"
              label="CEP"
              onPesquisarCep={handlePesquisarCep}
              loading={loading}
            />
            <PesquisaEnderecoCepDetalhe
              handleOnSelecionarEndereco={handleSelecionarEndereco}
              ref={janelaCepRef}
            />
          </Col>
          <Col xl={7} md={12}>
            <InputAutoCompleteCidadeUf
              label="Cidade"
              placeholder="Cidade"
              name="empresaEndereco.idCidade"
              nomeObjeto="empresaEndereco.cidade"
              onChangeItemAtual={handleChangeCidade}
            />
          </Col>
          <Col xl={2} md={12}>
            <InputVisualSemValor
              name="empresaEndereco.cidade.estado.sigla"
              label="UF"
              disabled
              type="text"
              placeholder="UF"
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} md={12}>
            <Input
              label="Logradouro"
              name="empresaEndereco.logradouro"
              placeholder="Logradouro"
              maxLength={60}
            />
          </Col>
          <Col xl={2} md={12}>
            <Input
              label="Número"
              name="empresaEndereco.numero"
              placeholder="Número"
              maxLength={60}
            />
          </Col>
          <Col xl={4} md={12}>
            <Input
              label="Bairro"
              name="empresaEndereco.bairro"
              type="text"
              placeholder="Bairro"
              maxLength={60}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={6} md={12}>
            <Input
              label="Complemento"
              name="empresaEndereco.complemento"
              type="text"
              placeholder="Complemento"
              maxLength={60}
            />
          </Col>
          <Col xl={6} md={12}>
            <Input
              label="Site"
              name="empresaEndereco.site"
              type="text"
              placeholder="Site"
              maxLength={60}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row>
          <Col md={6} sm={12}>
            <InputTelefone label="Telefones" name="listaEmpresaTelefone" />
          </Col>
          <Col md={6} sm={12}>
            <InputEmailTag label="E-mails" name="listaEmpresaEmail" />
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Row>
          <Col lg={6}>
            <div style={{ width: 260, display: 'flex' }}>
              <button
                type="button"
                style={{
                  width: 201,
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="btn-padrao btn-verde btn-adicionar"
                onClick={handleNovoCertificado}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <PiCertificateBold />
                  <span style={{ marginLeft: 10 }}>Novo Certificado</span>
                </TextoLoading>
              </button>

              <Button
                type="button"
                style={{ marginLeft: 5, height: 35 }}
                onClick={handleExcluirCertificado}
                disabled={loading}
              >
                <TextoLoading loading={loading}>
                  <RiDeleteBin6Line />
                </TextoLoading>
              </Button>
            </div>

            <div
              style={{
                display: possuiCertificado ? 'block' : 'none',
                marginBottom: 10,
              }}
            >
              <div style={{ display: 'flex' }}>
                <span style={{ marginBottom: 5, marginTop: 5 }}>
                  Seu Certificado:
                </span>
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex' }}>
                <span style={{ marginRight: 5 }}>Emitido Para:</span>
                <InputLabel name="empresaCertificado.emitidoPara" label="" />
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex' }}>
                <span style={{ marginRight: 5 }}>Emitido Por:</span>
                <InputLabel name="empresaCertificado.emitidoPor" label="" />
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex' }}>
                <span style={{ marginRight: 5 }}>Valido de</span>
                <InputLabel
                  name="empresaCertificado.dataHoraValidadeInicial"
                  label=""
                  formatarValor={(value) => {
                    return FormatarDataHoraCompletaParaPtBr(value);
                  }}
                />
                <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                <InputLabel
                  name="empresaCertificado.dataHoraValidadeFinal"
                  label=""
                  formatarValor={(value) => {
                    return FormatarDataHoraCompletaParaPtBr(value);
                  }}
                />
              </div>

              <div
                style={{
                  display: !certificadoVencido ? 'block' : 'none',
                  fontWeight: 'bold',
                  color: 'green',
                }}
              >
                ESTE CERTIFICADO ESTÁ VÁLIDO
              </div>

              <div
                style={{
                  display: certificadoVencido ? 'block' : 'none',
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                ESTE CERTIFICADO ESTÁ VENCIDO
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Row>
              <Col xl={4}>
                <InputInteiroNulavel
                  label="Id"
                  name="idTokenCscProducao"
                  type="text"
                />
              </Col>
              <Col xl={8}>
                <Input
                  label="Token CSC - Produção para NFC-e"
                  name="tokenCscProducao"
                  type="text"
                  maxLength={50}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={4}>
                <InputInteiroNulavel
                  label="Id"
                  name="idTokenCscHomologacao"
                  type="text"
                />
              </Col>
              <Col xl={8}>
                <Input
                  label="Token CSC - Homologação para NFC-e"
                  name="tokenCscHomologacao"
                  type="text"
                  maxLength={50}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4} md={4} sm={12}>
                <input
                  type="password"
                  style={{ opacity: 0, position: 'absolute', width: 10 }}
                />
                <InputSenha
                  autoComplete="off"
                  label="Senha ControlPay"
                  name="senhaControlPay"
                  placeholder="Senha ControlPay"
                  maxLength={50}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Divisor>

      {showFormModalCertificado && (
        <EmpresaCertificadoHook>
          <EmpresaCertificadoDetalhe
            onSalvarFormModal={handleSalvarFormModal}
            onFecharFormModal={handleFecharFormModal}
          />
        </EmpresaCertificadoHook>
      )}
    </div>
  );
};

export default GeralTab;
