import React, { useEffect, useCallback } from 'react';
import { Modal, Button, Container, Col, Row } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { FormatarDataParaPtBr, TipoCustoEnum } from '@elogestor/util';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { UseRecuperarFormulario } from '../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import InputDecimalNulavel from '../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import CustoCompraLista from '../Lista';

interface ICustosCompraListaModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const CustosCompraListaDetalhe: React.FC<ICustosCompraListaModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasNotaFiscalEntrada: permissao } = permissoes;
  const {
    formRefDetalhe,
    formRefLista,
    loading,
    handleCarregarDados,
    handleSubmit,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();

  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getDataRecuperarFormulario();

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleFecharModal = useCallback(async () => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
      onFecharFormModal();
    } else {
      onFecharFormModal();
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, onFecharFormModal]);

  const handleCalcularValoresDespesasItens = useCallback(() => {
    const valorOutrasDespesas = formRefDetalhe.current?.getFieldValue(
      'valorOutrasDespesas'
    );

    const valorTotalProdutos =
      formRefDetalhe.current?.getFieldValue('valorTotalProdutos');

    const listaNotaFiscalEntradaItem = formRefDetalhe.current?.getFieldValue(
      'listaNotaFiscalEntradaItem'
    );

    listaNotaFiscalEntradaItem.forEach((item: any, index: number) => {
      const percentual = valorTotalProdutos
        ? Number(item.valorTotalProduto) / Number(valorTotalProdutos)
        : 0;

      item.listaNotaFiscalEntradaItemCusto.forEach(
        (custo: any, custoIndex: number) => {
          if (
            custo.tipoCusto === TipoCustoEnum.ultimaCompra ||
            custo.tipoCusto === TipoCustoEnum.valorCustoMedio
          ) {
            listaNotaFiscalEntradaItem[index].listaNotaFiscalEntradaItemCusto[
              custoIndex
            ].valorOutrasDespesas = Number(
              percentual * Number(valorOutrasDespesas)
            );
            formRefLista.current?.setFieldValue(
              `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrasDespesas`,
              Number(percentual * Number(valorOutrasDespesas))
            );
          }
        }
      );
    });

    refresh();
  }, [formRefDetalhe, formRefLista, refresh]);

  const handleCalcularValoresOutrosDescontosItens = useCallback(() => {
    const valorOutrosDescontos = formRefDetalhe.current?.getFieldValue(
      'valorOutrosDescontos'
    );

    const valorTotalProdutos =
      formRefDetalhe.current?.getFieldValue('valorTotalProdutos');

    const listaNotaFiscalEntradaItem = formRefDetalhe.current?.getFieldValue(
      'listaNotaFiscalEntradaItem'
    );

    listaNotaFiscalEntradaItem.forEach((item: any, index: number) => {
      const percentual = valorTotalProdutos
        ? Number(item.valorTotalProduto) / Number(valorTotalProdutos)
        : 0;

      item.listaNotaFiscalEntradaItemCusto.forEach(
        (custo: any, custoIndex: number) => {
          if (
            custo.tipoCusto === TipoCustoEnum.ultimaCompra ||
            custo.tipoCusto === TipoCustoEnum.valorCustoMedio
          ) {
            listaNotaFiscalEntradaItem[index].listaNotaFiscalEntradaItemCusto[
              custoIndex
            ].valorOutrosDescontos = Number(
              percentual * Number(valorOutrosDescontos)
            );
            formRefLista.current?.setFieldValue(
              `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrosDescontos`,
              Number(percentual * Number(valorOutrosDescontos))
            );
          }
        }
      );
    });

    refresh();
  }, [formRefDetalhe, formRefLista, refresh]);

  const handleCalcularValoresOutrasDespesasContabeisItens = useCallback(() => {
    const valorOutrasDespesasContabeis = formRefDetalhe.current?.getFieldValue(
      'valorOutrasDespesasContabeis'
    );

    const valorTotalProdutos =
      formRefDetalhe.current?.getFieldValue('valorTotalProdutos');

    const listaNotaFiscalEntradaItem = formRefDetalhe.current?.getFieldValue(
      'listaNotaFiscalEntradaItem'
    );

    listaNotaFiscalEntradaItem.forEach((item: any, index: number) => {
      const percentual = valorTotalProdutos
        ? Number(item.valorTotalProduto) / Number(valorTotalProdutos)
        : 0;

      item.listaNotaFiscalEntradaItemCusto.forEach(
        (_: any, custoIndex: number) => {
          listaNotaFiscalEntradaItem[index].listaNotaFiscalEntradaItemCusto[
            custoIndex
          ].valorOutrasDespesasContabeis = Number(
            percentual * Number(valorOutrasDespesasContabeis)
          );
          formRefLista.current?.setFieldValue(
            `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrasDespesasContabeis`,
            Number(percentual * Number(valorOutrasDespesasContabeis))
          );
        }
      );
    });

    refresh();
  }, [formRefDetalhe, formRefLista, refresh]);

  const handleCalcularValoresOutrosDescontosContabeisItens = useCallback(() => {
    const valorOutrosDescontosContabeis = formRefDetalhe.current?.getFieldValue(
      'valorOutrosDescontosContabeis'
    );

    const valorTotalProdutos =
      formRefDetalhe.current?.getFieldValue('valorTotalProdutos');

    const listaNotaFiscalEntradaItem = formRefDetalhe.current?.getFieldValue(
      'listaNotaFiscalEntradaItem'
    );

    listaNotaFiscalEntradaItem.forEach((item: any, index: number) => {
      const percentual = valorTotalProdutos
        ? Number(item.valorTotalProduto) / Number(valorTotalProdutos)
        : 0;

      item.listaNotaFiscalEntradaItemCusto.forEach(
        (_: any, custoIndex: number) => {
          listaNotaFiscalEntradaItem[index].listaNotaFiscalEntradaItemCusto[
            custoIndex
          ].valorOutrosDescontosContabeis = Number(
            percentual * Number(valorOutrosDescontosContabeis)
          );
          formRefLista.current?.setFieldValue(
            `listaNotaFiscalEntradaItem[${index}].listaNotaFiscalEntradaItemCusto[${custoIndex}].valorOutrosDescontosContabeis`,
            Number(percentual * Number(valorOutrosDescontosContabeis))
          );
        }
      );
    });

    refresh();
  }, [formRefDetalhe, formRefLista, refresh]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Custos da Compra"
      tamanho="xl"
      onFecharFormModal={handleFecharModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRefDetalhe}>
        <Modal.Body>
          <Container>
            <Divisor>
              <Row>
                <InputHiddenHtml name="idNotaFiscalEntrada" />
                <InputHiddenHtml name="valorTotalProdutos" />

                <Col lg={2} md={6} sm={12}>
                  <InputLabel name="numero" label="Nota" />
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <InputLabel name="serie.codigo" label="Série" />
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <InputLabel
                    name="dataHoraEmissao"
                    label="Data de Emissão"
                    formatarValor={(value) => {
                      return <div>{FormatarDataParaPtBr(value)}</div>;
                    }}
                  />
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <InputLabel
                    name="notaFiscalEntradaPessoa.pessoa"
                    label="Fornecedor"
                    formatarValor={(value) => {
                      return (
                        <div>
                          {value?.codigo} - {value?.nomeRazaoSocial}
                        </div>
                      );
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimalNulavel
                    ref={inputRefFocus}
                    name="valorOutrasDespesas"
                    label="Valor Outras Despesas"
                    placeholder="Valor Outras Despesas"
                    onBlur={() => {
                      handleCalcularValoresDespesasItens();
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimalNulavel
                    name="valorOutrosDescontos"
                    label="Valor Outros Descontos"
                    placeholder="Valor Outras Descontos"
                    onBlur={() => {
                      handleCalcularValoresOutrosDescontosItens();
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimalNulavel
                    name="valorOutrasDespesasContabeis"
                    label="Val. Outr. Desp. Contábeis"
                    placeholder="Val. Outr. Desp. Contábeis"
                    onBlur={() => {
                      handleCalcularValoresOutrasDespesasContabeisItens();
                    }}
                  />
                </Col>

                <Col lg={4} md={6} sm={12}>
                  <InputDecimalNulavel
                    name="valorOutrosDescontosContabeis"
                    label="Val. Outr. Desc. Contábeis"
                    placeholder="Val. Outr. Desc. Contábeis"
                    onBlur={() => {
                      handleCalcularValoresOutrosDescontosContabeisItens();
                    }}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <InputDecimalNulavel
                    name="valorConhecimentoFrete"
                    label="Valor Frete Conhecimento"
                    placeholder="Valor Frete Conhecimento"
                    disabled
                  />
                </Col>
              </Row>
            </Divisor>

            <CustoCompraLista name="listaNotaFiscalEntradaItem" />
          </Container>
        </Modal.Body>
      </FormCia>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharModal}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{ marginLeft: 15, display: 'flex', alignItems: 'center' }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!permissao?.altera || loading}
            >
              <BiSave />
              <span style={{ marginLeft: 10 }}>Salvar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default CustosCompraListaDetalhe;
