import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-atualizar-observacao-cliente';

interface IPessoaAtualizarObservacaoClienteComunicadorUpdate {
  params: {
    idCliente: string;
    observacoes: string;
  };
}

class PessoaAtualizarObservacaoClienteComunicador {
  public async update(
    params: IPessoaAtualizarObservacaoClienteComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(rota, params.params);
    return response.data;
  }
}

export default new PessoaAtualizarObservacaoClienteComunicador();
