import {
  FormatarEnum,
  IEstruturaProdutoValoresAlterar,
  TipoEstoqueEstruturaProdutoEnum,
  TipoPerdaEstruturaEnum,
} from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { AiOutlineDelete } from 'react-icons/ai/index.mjs';
import { IoMdAddCircleOutline } from 'react-icons/io/index.mjs';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import { BtnContainer } from './styles';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import {
  ActionBox,
  ActionItem,
} from '../../../../../../Componentes/TreeView/TreeViewActionButton/styles';
import { IListaItem, UseTreeContext } from '../Hooks/TreeContext';
import { UseLog } from '../../../../../../Componentes/LogModal/Hooks/LogProvider';
import Divisor from '../../../../../../Componentes/Divisor';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import TextoLoadingSalvarContinuar from '../../../../../../Componentes/TextoLoadingSalvarContinuar';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputAutoCompleteUnidadeMedida from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputDecimal from '../../../../../../Componentes/Inputs/InputDecimal';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import Select from '../../../../../../Componentes/Select';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputDate from '../../../../../../Componentes/Inputs/InputDate';
import TreeView from '../../../../../../Componentes/TreeView';
import EstruturaProdutoLogComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Log/Comunicador/EstruturaProdutoLogComunicador';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import EstruturaProdutoDetalheComunicador from '../../../../../../Comunicador/Manufatura/Engenharia/EstruturaProduto/Comunicador/EstruturaProdutoDetalheComunicador';
import IParametroRotaDetalhe from '../../../../../../Componentes/Detalhe/Interface/IParametroRotaDetalhe';
import { UseRecuperarFormulario } from '../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import InputInteiroIncremental from '../../../../../../Componentes/Inputs/InputInteiroIncremental';
import SubheaderEstruturaProduto from './SubheaderEstruturaProduto';
import InputAutoCompleteProdutoServico from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import InputHiddenHtml from '../../../../../../Componentes/Inputs/InputHiddenHtml';
import SelecionarFormulaPdm from './SelecionarFormulaPdm';

type IProps = IMain<IEstruturaProdutoValoresAlterar>;

interface IActionProps {
  handleExcluir: () => Promise<void>;
  handleAdicionarFilho: (event: any) => void;
  handleShowLog: () => Promise<void>;
}

interface IRenderTreeProps {
  item: IListaItem;
  handleClickTitle?: (id?: string) => void;
}

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { ManufaturaEngenhariaEstruturaProduto: permissao } = permissoes;

  const params: IParametroRotaDetalhe = useParams();

  const {
    listaDados,
    buscarDados,
    setIdShowItem,
    setIdItemPai,
    idItemPai,
    excluirItem,
    estruturaSelecionada,
    setEstruturaSelecionada,
    ordemMaxima,
    setOrdemMaxima,
    encontrarLimiteOrdem,
    idSelecionado,
    setIdSelecionado,
  } = UseTreeContext();

  const { abrirJanela: abrirJanelaLog } = UseLog();
  const { abrirRecuperarFormulario } = UseRecuperarFormulario();
  const navigate = useNavigate();
  const rotas = UseRota();

  const [showFormula, setShowFormula] = useState<boolean>(false);
  const [mostrarToolTip, setMostrarToolTip] = useState<boolean>(false);

  const [tipoPerda, setTipoPerda] = useState<string>(
    TipoPerdaEstruturaEnum.percentual
  );
  const [tipoPerdaSetup, setTipoPerdaSetup] = useState<string>(
    TipoPerdaEstruturaEnum.quantidade
  );

  const listaIdProdutoPaiRemover: string[] = [];
  if (params.id) {
    listaIdProdutoPaiRemover.push(params.id);
  }

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    refresh,
    inputRefFocus,
  } = UseForm();

  const desabilitarCampos = !formRef.current?.getFieldValue('idProdutoPai');
  const formula = formRef.current?.getFieldValue('formula');
  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data: any = formRef.current?.getDataDuplicar();
    data.idProdutoFilho = data.produtoFilho ? data.produtoFilho.id : null;
    data.idProdutoPai = data.produtoPai ? data.produtoPai.id : null;

    const idProdutoPaiRoot = listaDados.length
      ? listaDados[0].idProdutoPai
      : data.idProdutoPai;

    const { erro, id } = await handleSubmit(data);

    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(`${rotas.detalhes}/${idProdutoPaiRoot}`);
      await buscarDados(idProdutoPaiRoot);
    }
  }, [
    buscarDados,
    formRef,
    handleSubmit,
    listaDados,
    navigate,
    onClickSalvar,
    rotas.detalhes,
  ]);

  const handleCarregarProduto = useCallback(
    async (item: any) => {
      if (item) {
        formRef.current?.setFieldValue('produtoPaiDois', item);
        setIdItemPai(item.id);

        const listaRegistro = await buscarDados(item.id);
        if (listaRegistro && listaRegistro.length > 0) {
          if (listaRegistro[0].filhos) {
            const ordemMaximaBuscaDados =
              encontrarLimiteOrdem({
                item: listaRegistro[0].filhos[
                  listaRegistro[0].filhos.length - 1
                ],
                listaEstrutura: listaRegistro,
              }) + 1;
            setOrdemMaxima(ordemMaximaBuscaDados);
            formRef.current?.setFieldValue('ordem', ordemMaximaBuscaDados);
          }
        }
      }
    },
    [buscarDados, encontrarLimiteOrdem, formRef, setIdItemPai, setOrdemMaxima]
  );

  const handleCarregarUnidadeMedida = useCallback(
    (event?: any) => {
      if (
        event.itemAtual &&
        event.itemAtual.produtoEstoque &&
        event.itemAtual.produtoEstoque.unidadeMedida
      ) {
        formRef.current?.setFieldValue(
          'unidadeMedida',
          event.itemAtual.produtoEstoque.unidadeMedida
        );
      }
    },
    [formRef]
  );

  const handleClickAdicionarFilho = useCallback(
    async (item: any) => {
      setIdSelecionado(item.id);
      setEstruturaSelecionada(item.descricao);
      const response = await EstruturaProdutoDetalheComunicador.show({
        id: item.id,
      });
      if (formRef.current?.validarSeAlterou()) {
        const resposta = await abrirRecuperarFormulario({});
        if (resposta) return;
      }

      if (item.idProdutoFilho) {
        await formRef.current?.setDataInicialSemExecutarEvento({
          produtoPai: response.produtoPai,
          produtoPaiDois: response.produtoPai,

          tipoPerda: TipoPerdaEstruturaEnum.percentual,
          tipoPerdaSetup: TipoPerdaEstruturaEnum.quantidade,
          quantidade: 1,
          fantasma: false,

          ordem: item.filhos.length + 1,
          tipoEstoquePreferencial:
            TipoEstoqueEstruturaProdutoEnum.proprioEmPoderProprio,
        });
      } else {
        await formRef.current?.setDataInicialSemExecutarEvento({
          produtoPai: response.produtoFilho,
          produtoPaiDois: response.produtoFilho,

          tipoPerda: TipoPerdaEstruturaEnum.percentual,
          tipoPerdaSetup: TipoPerdaEstruturaEnum.quantidade,
          quantidade: 1,
          fantasma: false,

          ordem: item.filhos.length + 1,
          tipoEstoquePreferencial:
            TipoEstoqueEstruturaProdutoEnum.proprioEmPoderProprio,
        });
      }
      setIdItemPai(item.idProdutoPai);
    },
    [
      abrirRecuperarFormulario,
      formRef,
      setEstruturaSelecionada,
      setIdItemPai,
      setIdSelecionado,
    ]
  );

  if (idItemPai) {
    listaIdProdutoPaiRemover.push(idItemPai);
  }

  const Action: React.FC<IActionProps> = ({
    handleExcluir,
    handleAdicionarFilho,
    handleShowLog,
  }) => {
    return (
      <ActionBox>
        <ActionItem onClick={handleAdicionarFilho}>
          <span style={{ display: 'flex', fontSize: 16, fontWeight: 'normal' }}>
            <IoMdAddCircleOutline style={{ marginRight: 5 }} />
            Adicionar Filho
          </span>
        </ActionItem>
        <ActionItem onClick={handleExcluir}>
          <span style={{ display: 'flex', fontSize: 16, fontWeight: 'normal' }}>
            <AiOutlineDelete style={{ marginRight: 5 }} />
            Excluir
          </span>
        </ActionItem>
        <ActionItem onClick={handleShowLog}>
          <span style={{ display: 'flex', fontSize: 16, fontWeight: 'normal' }}>
            <CgPlayListSearch style={{ marginRight: 5 }} />
            Logs
          </span>
        </ActionItem>
      </ActionBox>
    );
  };

  const RenderTreeItem: React.FC<IRenderTreeProps> = ({ item }) => {
    return (
      <div
        style={{
          fontWeight:
            estruturaSelecionada === item.descricao && item.id === idSelecionado
              ? 'bold'
              : 'normal',
          pointerEvents: params.id === item.idProdutoPai ? 'none' : 'auto',
        }}
      >
        <TreeView
          title={`${item.codigo} - ${item.descricao}`}
          open
          actions={
            <Action
              handleExcluir={async () => {
                await excluirItem({
                  item,
                  hasFilhos: !!item.filhos?.length,
                });
              }}
              handleAdicionarFilho={() => {
                handleClickAdicionarFilho(item);
              }}
              handleShowLog={async () => {
                try {
                  abrirJanelaLog(async (funcao) => {
                    const response = await EstruturaProdutoLogComunicador.show({
                      params: funcao,
                      id: item.id,
                    });

                    return response;
                  });
                } catch (error) {
                  TratarErros(error);
                }
              }}
            />
          }
          onClickTitle={async () => {
            if (formRef.current?.validarSeAlterou()) {
              const resposta = await abrirRecuperarFormulario({});
              if (resposta) return;
            }
            setIdShowItem({ id: item.id });
            setIdSelecionado(item.id);
            setEstruturaSelecionada(item.descricao);
            setOrdemMaxima(
              encontrarLimiteOrdem({ item, listaEstrutura: listaDados })
            );
            item.isOpen = true;
            setIdItemPai('');
          }}
        >
          {item.filhos && item.filhos.length !== 0 && (
            <>
              {item.filhos.map((registro) => (
                <RenderTreeItem item={registro} key={registro.id} />
              ))}
            </>
          )}
        </TreeView>
      </div>
    );
  };

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />
      <SubheaderEstruturaProduto />

      <InputHiddenHtml name="formula" />
      {showFormula && (
        <SelecionarFormulaPdm
          onFecharModal={() => {
            setShowFormula(false);
            refresh();
          }}
        />
      )}

      <div>
        <Divisor>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteProduto
                ref={inputRefFocus}
                label="Produto"
                placeholder="Produto"
                name="idProdutoPai"
                controlaEstoque
                nomeObjeto="produtoPai"
                onChangeItemAtual={(event) => {
                  if (event.itemAtual) handleCarregarProduto(event.itemAtual);
                  refresh();
                }}
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor className="tree-container">
          <div>
            {listaDados.length ? (
              listaDados.map((item) => (
                <RenderTreeItem item={item} key={item.id} />
              ))
            ) : (
              <h6 style={{ textAlign: 'center', marginTop: '18px' }}>
                Nenhuma Estrutura de Produto foi Encontrada!
              </h6>
            )}
          </div>
        </Divisor>

        <Divisor>
          <Row>
            <Col lg={8} md={12} sm={12}>
              <InputAutoCompleteProdutoServico
                label="Produto (Filho)"
                placeholder="Produto (Filho)"
                name="idProdutoFilho"
                nomeObjeto="produtoFilho"
                controlaEstoque
                disabled={desabilitarCampos}
                onChangeItemAtual={(event) => {
                  handleCarregarUnidadeMedida(event);
                  if (!event.itemAtual) {
                    formRef.current?.setFieldValue('unidadeMedida', null);
                  }
                }}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <InputAutoCompleteUnidadeMedida
                label="Unidade"
                placeholder="Unidade"
                name="idUnidadeMedida"
                nomeObjeto="unidadeMedida"
                permitirAdicionar={false}
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={4} sm={6}>
              <div style={{ display: 'flex' }}>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id={v4()}>
                      Formula Ativa, remova a formula para editar a quantidade!
                    </Tooltip>
                  }
                  show={mostrarToolTip}
                >
                  <div
                    style={{ width: '100%' }}
                    onMouseEnter={() => {
                      if (formula) setMostrarToolTip(true);
                    }}
                    onMouseLeave={() => setMostrarToolTip(false)}
                  >
                    <InputDecimal
                      label="Quantidade"
                      name="quantidade"
                      casasDecimais={10}
                      casasInteiras={21}
                      disabled={desabilitarCampos || !!formula}
                    />
                  </div>
                </OverlayTrigger>
                <Button
                  onClick={() => {
                    setShowFormula(true);
                  }}
                  disabled={!formRef.current?.getFieldValue('idProdutoPai')}
                  style={{
                    height: '35px',
                    alignSelf: 'end',
                    marginBottom: '5px',
                    marginLeft: '10px',
                    background: `${formula ? '#483D8B	' : '#007bff'}`,
                    borderColor: `${formula ? '#483D8B	' : '#007bff'}`,
                  }}
                >
                  F
                </Button>
              </div>
            </Col>
            <Col lg={4} md={4} sm={6}>
              <InputSwitch
                label="Fantasma"
                name="fantasma"
                ativo="Sim"
                inativo="Não"
                disabled={desabilitarCampos}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={4} sm={6}>
              <Select
                label="Tipo Perda"
                name="tipoPerda"
                options={FormatarEnum({
                  enumObj: TipoPerdaEstruturaEnum,
                })}
                onChange={(event) => {
                  setTipoPerda(event.currentTarget.value);
                }}
                disabled={desabilitarCampos}
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              style={{
                display:
                  tipoPerda === TipoPerdaEstruturaEnum.quantidade
                    ? 'flex'
                    : 'none',
              }}
            >
              <InputDecimalNulavel
                name="quantidadePerda"
                placeholder="Quantidade Perda"
                label="Quantidade Perda"
                casasDecimais={10}
                casasInteiras={21}
                disabled={desabilitarCampos}
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              style={{
                display:
                  tipoPerda === TipoPerdaEstruturaEnum.percentual
                    ? 'flex'
                    : 'none',
              }}
            >
              <InputDecimalNulavel
                name="percentualPerda"
                placeholder="Percentual Perda"
                label="Percentual Perda"
                casasDecimais={2}
                casasInteiras={5}
                disabled={desabilitarCampos}
              />
            </Col>
            <Col lg={3} md={4} sm={6}>
              <Select
                label="Tipo Perda Setup"
                name="tipoPerdaSetup"
                options={FormatarEnum({
                  enumObj: TipoPerdaEstruturaEnum,
                })}
                onChange={(event) => {
                  setTipoPerdaSetup(event.currentTarget.value);
                }}
                disabled={desabilitarCampos}
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              style={{
                display:
                  tipoPerdaSetup === TipoPerdaEstruturaEnum.quantidade
                    ? 'flex'
                    : 'none',
              }}
            >
              <InputDecimalNulavel
                name="quantidadePerdaSetup"
                placeholder="Quantidade Perda Setup"
                label="Quantidade Perda Setup"
                casasDecimais={10}
                casasInteiras={21}
                disabled={desabilitarCampos}
              />
            </Col>
            <Col
              lg={3}
              md={4}
              sm={6}
              style={{
                display:
                  tipoPerdaSetup === TipoPerdaEstruturaEnum.percentual
                    ? 'flex'
                    : 'none',
              }}
            >
              <InputDecimalNulavel
                name="percentualPerdaSetup"
                placeholder="Percentual Perda Setup"
                label="Percentual Perda Setup"
                casasDecimais={2}
                casasInteiras={5}
                disabled={desabilitarCampos}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={3} md={4} sm={6}>
              <InputDate
                name="dataInicial"
                label="Data Vigência Inicial"
                disabled={desabilitarCampos}
              />
            </Col>
            <Col lg={3} md={4} sm={6}>
              <InputDate
                name="dataFinal"
                label="Data Vigência Final"
                disabled={desabilitarCampos}
              />
            </Col>

            <Col lg={2} md={4} sm={6}>
              <InputInteiroIncremental
                label="Ordem"
                name="ordem"
                placeholder="Ordem"
                min={1}
                max={ordemMaxima}
                disabled={desabilitarCampos}
              />
            </Col>
            <Col lg={3} md={4} sm={6}>
              <Select
                label="Tipo de Estoque Preferencial"
                name="tipoEstoquePreferencial"
                options={FormatarEnum({
                  enumObj: TipoEstoqueEstruturaProdutoEnum,
                })}
                disabled={desabilitarCampos}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} sm={12}>
              <InputAutoCompleteProduto
                label="Produto (Pai)"
                placeholder="Produto (Pai)"
                name="idProdutoPaiDois"
                nomeObjeto="produtoPaiDois"
                disabled
              />
            </Col>
          </Row>
        </Divisor>
      </div>
      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvarContinuar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
