import api from '../../../../../../Comum/Services/Api';

const rota = 'comercial/relatorio/vendas/pedido-venda/pedido-venda-por-cidade';

interface IPedidoVendaPorCidadeRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class PedidoVendaPorCidadeRelatorioComunicador {
  public async index(
    params: IPedidoVendaPorCidadeRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new PedidoVendaPorCidadeRelatorioComunicador();
