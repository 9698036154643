import {
  FormatarEnum,
  IsSimplesNacional,
  ModalidadeBaseCalculoIcmsEnum,
  MotivoDesoneracaoIcmsEnum,
} from '@elogestor/util';
import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../../../../../../Componentes/Divisor';
import InputAutoCompleteCodigoSituacaoTributariaIcms from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCodigoSituacaoTributariaIcms';
import InputAutoCompleteOrigemIcms from '../../../../../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOrigemIcms';
import InputCheckbox from '../../../../../../../../../../Componentes/Inputs/InputCheckbox';
import InputDecimalNulavel from '../../../../../../../../../../Componentes/Inputs/InputDecimalNulavel';
import Select from '../../../../../../../../../../Componentes/Select';
import { UseItemIcmsTab } from '../../../Hook/ItemIcmsTabHook';
import { UseItemTab } from '../../../Hook/ItemTabHook';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseRegimeTributario } from '../../../../../../../../../Configuracao/Empresa/Detalhe/TributacaoTab/ListaDetalheVigenciaRegimeTributario/Hooks/RegimeTributarioHook';

const ItemImpostoIcmsTab: React.FC = () => {
  const { refresh, formRefDetalhe } = UseListaDetalheForm();
  const { camposEscondidosIcms, camposBloqueadosIcms } = UseItemIcmsTab();
  const { calcularImpostosPorCst, obterConfiguracaoTributaria } = UseItemTab();

  const { regimeTributario } = UseRegimeTributario();
  const [isEmitenteSimplesNacional, setIsEmitenteSimplesNacional] =
    useState(false);

  useEffect(() => {
    if (regimeTributario) {
      if (IsSimplesNacional(regimeTributario)) {
        setIsEmitenteSimplesNacional(true);
      } else {
        setIsEmitenteSimplesNacional(false);
      }
    }
  }, [isEmitenteSimplesNacional, regimeTributario]);

  const handleCalcularImpostosPorCst = useCallback(() => {
    calcularImpostosPorCst();
  }, [calcularImpostosPorCst]);

  const handleObterConfiguracaoTributaria = useCallback(() => {
    obterConfiguracaoTributaria();
  }, [obterConfiguracaoTributaria]);

  const handleAlterarModalidadeBaseCalculo = useCallback(() => {
    const calcular = formRefDetalhe.current?.getFieldValue(
      'pedidoCompraItemImpostoIcms.calcularIcms'
    );

    const aliquota = formRefDetalhe.current?.getFieldValue(
      'pedidoCompraItemImpostoIcms.aliquotaIcms'
    );

    const modalidadeBaseCalculo = formRefDetalhe.current?.getFieldValue(
      'pedidoCompraItemImpostoIcms.modalidadeBaseCalculoIcms'
    );

    if (calcular && aliquota && !modalidadeBaseCalculo) {
      formRefDetalhe.current?.setFieldValue(
        'pedidoCompraItemImpostoIcms.modalidadeBaseCalculoIcms',
        ModalidadeBaseCalculoIcmsEnum.valorOperacao
      );
    }
  }, [formRefDetalhe]);

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteOrigemIcms
              label="Origem"
              name="pedidoCompraItemImpostoIcms.idOrigemIcms"
              placeholder="Origem"
              nomeObjeto="pedidoCompraItemImpostoIcms.origemIcms"
              onBlurInput={handleObterConfiguracaoTributaria}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12}>
            <InputAutoCompleteCodigoSituacaoTributariaIcms
              label="CSOSN ou CST"
              name="pedidoCompraItemImpostoIcms.idSituacaoTributaria"
              placeholder="CSOSN ou CST"
              nomeObjeto="pedidoCompraItemImpostoIcms.situacaoTributaria"
              icms={!isEmitenteSimplesNacional}
              icmsSimplesNacional={isEmitenteSimplesNacional}
              onChangeItemAtualAposCarregarSemClear={() => {
                refresh();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcms}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularIcms}
                label="Calcular ICMS"
                name="pedidoCompraItemImpostoIcms.calcularIcms"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) {
                    handleAlterarModalidadeBaseCalculo();
                    handleCalcularImpostosPorCst();
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={6}
            md={12}
            sm={12}
            hidden={camposEscondidosIcms.modalidadeBaseCalculoIcms}
          >
            <Select
              disabled={camposBloqueadosIcms.modalidadeBaseCalculoIcms}
              label="Modalidade da Base de Cálculo"
              name="pedidoCompraItemImpostoIcms.modalidadeBaseCalculoIcms"
              options={FormatarEnum({
                enumObj: ModalidadeBaseCalculoIcmsEnum,
                nullavel: true,
              })}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualReducaoBaseCalculoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualReducaoBaseCalculoIcms}
              label="Percentual Redução Base"
              name="pedidoCompraItemImpostoIcms.percentualReducaoBaseCalculoIcms"
              placeholder="Percentual Redução Base"
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcms}
              label="Base de Cálculo"
              name="pedidoCompraItemImpostoIcms.baseCalculoIcms"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.aliquotaIcms}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcms}
              label="Alíquota"
              name="pedidoCompraItemImpostoIcms.aliquotaIcms"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={() => {
                handleAlterarModalidadeBaseCalculo();
                handleCalcularImpostosPorCst();
              }}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorIcms}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcms}
              label="Valor Total"
              name="pedidoCompraItemImpostoIcms.valorIcms"
              placeholder="Valor Total"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.valorSemDiferimentoIcms &&
          camposEscondidosIcms.percentualDiferimentoIcms &&
          camposEscondidosIcms.valorDiferidoIcms
        }
      >
        <h5>Diferimento</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorSemDiferimentoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorSemDiferimentoIcms}
              label="Valor Sem Diferimento"
              name="pedidoCompraItemImpostoIcms.valorSemDiferimentoIcms"
              placeholder="Valor Sem Diferimento"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualDiferimentoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualDiferimentoIcms}
              label="Percentual Diferimento"
              name="pedidoCompraItemImpostoIcms.percentualDiferimentoIcms"
              placeholder="Percentual Diferimento"
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorDiferidoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorDiferidoIcms}
              label="Valor Diferido"
              name="pedidoCompraItemImpostoIcms.valorDiferidoIcms"
              placeholder="Valor Diferido"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.valorUnitarioPautaIcms &&
          camposEscondidosIcms.valorTotalPautaIcms
        }
      >
        <h5>Pauta</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorUnitarioPautaIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorUnitarioPautaIcms}
              label="Valor Unitário"
              name="pedidoCompraItemImpostoIcms.valorUnitarioPautaIcms"
              placeholder="Valor Unitário"
              casasInteiras={8}
              casasDecimais={10}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorTotalPautaIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorTotalPautaIcms}
              label="Total"
              name="pedidoCompraItemImpostoIcms.valorTotalPautaIcms"
              placeholder="Total"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.baseCalculoFcpIcms &&
          camposEscondidosIcms.aliquotaFcpIcms &&
          camposEscondidosIcms.valorFcpIcms &&
          camposEscondidosIcms.valorIcmsEValorFcpIcms
        }
      >
        <h5>Fundo de Combate a Pobreza</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoFcpIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoFcpIcms}
              label="Base de Cálculo"
              name="pedidoCompraItemImpostoIcms.baseCalculoFcpIcms"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaFcpIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaFcpIcms}
              label="Alíquota"
              name="pedidoCompraItemImpostoIcms.aliquotaFcpIcms"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col lg={3} md={6} sm={12} hidden={camposEscondidosIcms.valorFcpIcms}>
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorFcpIcms}
              label="Valor"
              name="pedidoCompraItemImpostoIcms.valorFcpIcms"
              placeholder="Valor"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsEValorFcpIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsEValorFcpIcms}
              label="Valor e Valor ICMS"
              name="pedidoCompraItemImpostoIcms.valorIcmsEValorFcpIcms"
              placeholder="Valor e Valor ICMS"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsEfetivo &&
          camposEscondidosIcms.percentualReducaoBaseCalculoIcmsEfetivo &&
          camposEscondidosIcms.baseCalculoIcmsEfetivo &&
          camposEscondidosIcms.aliquotaIcmsEfetivo &&
          camposEscondidosIcms.valorIcmsEfetivo
        }
      >
        <h5>Efetivo</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcmsEfetivo}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularIcmsEfetivo}
                label="Calcular ICMS Efetivo"
                name="pedidoCompraItemImpostoIcms.calcularIcmsEfetivo"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={
              camposEscondidosIcms.percentualReducaoBaseCalculoIcmsEfetivo
            }
          >
            <InputDecimalNulavel
              disabled={
                camposBloqueadosIcms.percentualReducaoBaseCalculoIcmsEfetivo
              }
              label="Percentual Redução Base"
              name="pedidoCompraItemImpostoIcms.percentualReducaoBaseCalculoIcmsEfetivo"
              placeholder="Percentual Redução Base"
              casasInteiras={5}
              casasDecimais={6}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoIcmsEfetivo}
              label="Base de Cálculo"
              name="pedidoCompraItemImpostoIcms.baseCalculoIcmsEfetivo"
              placeholder="Base de Cálculo"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaIcmsEfetivo}
              label="Alíquota"
              name="pedidoCompraItemImpostoIcms.aliquotaIcmsEfetivo"
              placeholder="Alíquota"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>

          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsEfetivo}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsEfetivo}
              label="Valor ICMS Efetivo"
              name="pedidoCompraItemImpostoIcms.valorIcmsEfetivo"
              placeholder="Valor ICMS Efetivo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularIcmsSubstituto &&
          camposEscondidosIcms.valorUnitarioIcmsSubstituto &&
          camposEscondidosIcms.valorIcmsSubstituto
        }
      >
        <h5>Substituto</h5>
        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularIcmsSubstituto}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularIcmsSubstituto}
                label="Calcular ICMS Substituto"
                name="pedidoCompraItemImpostoIcms.calcularIcmsSubstituto"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorUnitarioIcmsSubstituto}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorUnitarioIcmsSubstituto}
              label="Valor Unitário ICMS Substituto"
              name="pedidoCompraItemImpostoIcms.valorUnitarioIcmsSubstituto"
              placeholder="Valor Unitário ICMS Substituto"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorIcmsSubstituto}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorIcmsSubstituto}
              label="Valor ICMS Substituto"
              name="pedidoCompraItemImpostoIcms.valorIcmsSubstituto"
              placeholder="Valor ICMS Substituto"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularCreditoIcmsSn &&
          camposEscondidosIcms.percentualCreditoIcmsSn &&
          camposEscondidosIcms.valorCreditoIcmsSn
        }
      >
        <Col
          lg={6}
          md={12}
          sm={12}
          className="div-checkbox"
          hidden={camposEscondidosIcms.calcularCreditoIcmsSn}
        >
          <div className="checkbox-container">
            <InputCheckbox
              disabled={camposBloqueadosIcms.calcularCreditoIcmsSn}
              label="Calcular Aproveitamento Crédito ICMS SN."
              name="pedidoCompraItemImpostoIcms.calcularCreditoIcmsSn"
              onChange={(event) => {
                refresh();
                if (event.target.checked) handleCalcularImpostosPorCst();
              }}
            />
          </div>
        </Col>

        <Row>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.percentualCreditoIcmsSn}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.percentualCreditoIcmsSn}
              label="Percentual de Crédito"
              name="pedidoCompraItemImpostoIcms.percentualCreditoIcmsSn"
              placeholder="Base de Cálculo"
              casasInteiras={3}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
          <Col
            lg={3}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorCreditoIcmsSn}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorCreditoIcmsSn}
              label="Valor de Crédito"
              name="pedidoCompraItemImpostoIcms.valorCreditoIcmsSn"
              placeholder="Valor de Crédito"
              casasInteiras={13}
              casasDecimais={2}
              onChange={handleCalcularImpostosPorCst}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor
        hidden={
          camposEscondidosIcms.calcularDesoneracaoIcms &&
          camposEscondidosIcms.deduzirDesoneracaoIcms &&
          camposEscondidosIcms.motivoDesoneracaoIcms &&
          camposEscondidosIcms.baseCalculoDesoneracaoIcms &&
          camposEscondidosIcms.aliquotaDesoneracaoIcms &&
          camposEscondidosIcms.valorDesoneracaoIcms
        }
      >
        <h5>Desoneração</h5>
        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.motivoDesoneracaoIcms}
          >
            <Select
              disabled={camposBloqueadosIcms.motivoDesoneracaoIcms}
              label="Motivo Desoneração"
              name="pedidoCompraItemImpostoIcms.motivoDesoneracaoIcms"
              options={FormatarEnum({
                enumObj: MotivoDesoneracaoIcmsEnum,
                nullavel: true,
              })}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.calcularDesoneracaoIcms}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.calcularDesoneracaoIcms}
                label="Calcular Desoneração ICMS"
                name="pedidoCompraItemImpostoIcms.calcularDesoneracaoIcms"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            className="div-checkbox"
            hidden={camposEscondidosIcms.deduzirDesoneracaoIcms}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <div className="checkbox-container">
              <InputCheckbox
                disabled={camposBloqueadosIcms.deduzirDesoneracaoIcms}
                label="Deduzir Desoneração ICMS"
                name="pedidoCompraItemImpostoIcms.deduzirDesoneracaoIcms"
                onChange={(event) => {
                  refresh();
                  if (event.target.checked) handleCalcularImpostosPorCst();
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            lg={4}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.baseCalculoDesoneracaoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.baseCalculoDesoneracaoIcms}
              label="Base Calculo"
              name="pedidoCompraItemImpostoIcms.baseCalculoDesoneracaoIcms"
              placeholder="Base Calculo"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.aliquotaDesoneracaoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.aliquotaDesoneracaoIcms}
              label="Alíquota"
              name="pedidoCompraItemImpostoIcms.aliquotaDesoneracaoIcms"
              placeholder="Alíquota"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
          <Col
            lg={4}
            md={6}
            sm={12}
            hidden={camposEscondidosIcms.valorDesoneracaoIcms}
          >
            <InputDecimalNulavel
              disabled={camposBloqueadosIcms.valorDesoneracaoIcms}
              label="Valor Desoneração"
              name="pedidoCompraItemImpostoIcms.valorDesoneracaoIcms"
              placeholder="Valor Desoneração"
              casasInteiras={13}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default ItemImpostoIcmsTab;
