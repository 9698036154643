import React, { useCallback, useState } from 'react';
import {
  FormatarCnpj,
  FormatarDataParaPtBrSemTimeZone,
  IRegraEscolhaCstLista,
  RegraEscolhaTipoEnum,
} from '@elogestor/util';
import { Dropdown } from 'react-bootstrap';
import { CgPlayListSearch } from 'react-icons/cg/index.mjs';
import IPesquisa from '../../../../../Componentes/Lista/Interface/IPesquisa';
import ITh from '../../../../../Componentes/Lista/Interface/ITh';
import PesquisaAvancada from './PesquisaAvancada';
import ListaProvider from '../../../../../Componentes/Lista/ListaProvider';
import Acoes from '../../../../../Componentes/Lista/Acoes';
import { Container } from './styles';
import SubHeader from '../../../../../Componentes/Lista/SubHeader';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ListaComTrDraggable from '../../../../../Componentes/ListaComTrDraggable';
import { UseLog } from '../../../../../Componentes/LogModal/Hooks/LogProvider';
import PersonalizacaoListaCamposComunicador from '../../../../../Comunicador/Configuracao/PersonalizacaoListaRotas/Campos/Comunicador/PersonalizacaoListaCamposComunicador';
import RegraEscolhaCstComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCst/Comunicador/RegraEscolhaCstComunicador';
import RegraEscolhaCstOrdem from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCst/Comunicador/RegraEscolhaCstOrdem';
import RegraEscolhaCstLogComunicador from '../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaCst/Log/Comunicador/RegraEscolhaCstLogComunicador';

const RegraEscolhaCstLista: React.FC = () => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { abrirJanela: abrirJanelaLog } = UseLog();

  const [loading, setLoading] = useState(false);

  const handlePesquisarDados = useCallback(
    async ({
      pesquisaAvancada,
      order,
      descAsc,
      limite,
      pagina,
      textoPesquisa,
    }: IPesquisa) => {
      try {
        setLoading(true);

        const response = await RegraEscolhaCstComunicador.index({
          params: {
            pesquisaAvancada,
            order,
            descAsc,
            limite,
            pagina,
            textoPesquisa,
          },
        });

        const dados = response.dados[0].map((data: IRegraEscolhaCstLista) => {
          return {
            id: data.id,

            'listaRegraEscolhaCstRegimeTributarioEmitente.regimeTributario': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstRegimeTributarioEmitente
                  ? data.listaRegraEscolhaCstRegimeTributarioEmitente.map(
                      (valor) => {
                        return (
                          <div key={valor.regimeTributario}>
                            {`${valor.regimeTributario};`}
                          </div>
                        );
                      }
                    )
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstDestinado.destinado': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstDestinado
                  ? data.listaRegraEscolhaCstDestinado.map((valor) => {
                      return (
                        <div key={valor.destinado}>{`${valor.destinado};`}</div>
                      );
                    })
                  : ''}
              </div>
            ),

            quantidadeCriterios: (
              <div className="lista-valor">{data.quantidadeCriterios}</div>
            ),

            temMva:
              data.temMva != null ? (
                <div
                  className="lista-booleano"
                  style={{ color: data.temMva ? 'green' : 'red' }}
                >
                  {data.temMva ? 'Sim' : 'Não'}
                </div>
              ) : (
                ''
              ),
            temCest:
              data.temCest != null ? (
                <div
                  className="lista-booleano"
                  style={{ color: data.temCest ? 'green' : 'red' }}
                >
                  {data.temCest ? 'Sim' : 'Não'}
                </div>
              ) : (
                ''
              ),
            sujeitoASt:
              data.sujeitoASt != null ? (
                <div
                  className="lista-booleano"
                  style={{ color: data.sujeitoASt ? 'green' : 'red' }}
                >
                  {data.sujeitoASt ? 'Sim' : 'Não'}
                </div>
              ) : (
                ''
              ),
            contribuinte:
              data.contribuinte != null ? (
                <div
                  className="lista-booleano"
                  style={{ color: data.contribuinte ? 'green' : 'red' }}
                >
                  {data.contribuinte ? 'Sim' : 'Não'}
                </div>
              ) : (
                ''
              ),
            temCodigoAnp:
              data.temCodigoAnp != null ? (
                <div
                  className="lista-booleano"
                  style={{ color: data.temCodigoAnp ? 'green' : 'red' }}
                >
                  {data.temCodigoAnp ? 'Sim' : 'Não'}
                </div>
              ) : (
                ''
              ),
            temIpiAliquotaZero:
              data.temIpiAliquotaZero != null ? (
                <div
                  className="lista-booleano"
                  style={{ color: data.temIpiAliquotaZero ? 'green' : 'red' }}
                >
                  {data.temIpiAliquotaZero ? 'Sim' : 'Não'}
                </div>
              ) : (
                ''
              ),
            tipo: <div className="lista-texto">{data.tipo}</div>,
            cnpjContabilidade: (
              <div className="lista-texto">
                {FormatarCnpj(String(data.cnpjContabilidade))}
              </div>
            ),
            dataInicioVigencia: (
              <div className="lista-data">
                {FormatarDataParaPtBrSemTimeZone(data.dataInicioVigencia || '')}
              </div>
            ),
            dataFinalVigencia: (
              <div className="lista-data">
                {FormatarDataParaPtBrSemTimeZone(data.dataFinalVigencia || '')}
              </div>
            ),

            'tipoNota.descricao': (
              <div className="lista-texto">
                {data.tipoNota?.descricao || ''}
              </div>
            ),
            'tipoNotaMotivo.descricao': (
              <div className="lista-texto">
                {data.tipoNotaMotivo?.descricao || ''}
              </div>
            ),

            'listaRegraEscolhaCstOperacaoFiscal.cfop': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstOperacaoFiscal
                  ? data.listaRegraEscolhaCstOperacaoFiscal.map((valor) => {
                      return (
                        <div key={valor.operacaoFiscal.cfop}>
                          {`${valor.operacaoFiscal.cfop};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstSped.spedTipoProduto.codigoDescricao': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstSped
                  ? data.listaRegraEscolhaCstSped.map((valor) => {
                      return (
                        <div key={valor.spedTipoProduto.codigo}>
                          {`${valor.spedTipoProduto.codigo} - ${valor.spedTipoProduto.descricao};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstNcm.codigo': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstNcm
                  ? data.listaRegraEscolhaCstNcm.map((valor) => {
                      return (
                        <div key={valor.ncm.codigo}>
                          {`${valor.ncm.codigo};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstCest.codigo': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstCest
                  ? data.listaRegraEscolhaCstCest.map((valor) => {
                      return (
                        <div key={valor.cest.codigo}>
                          {`${valor.cest.codigo};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstProduto.produto.codigoDescricao': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstProduto
                  ? data.listaRegraEscolhaCstProduto.map((valor) => {
                      return (
                        <div key={valor.produto.codigo}>
                          {`${valor.produto.codigo} - ${valor.produto.descricao};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstPessoa.pessoa.codigoNomeRazaoSocial': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstPessoa
                  ? data.listaRegraEscolhaCstPessoa.map((valor) => {
                      return (
                        <div key={valor.pessoa.codigo}>
                          {`${valor.pessoa.codigo} - ${valor.pessoa.nomeRazaoSocial};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstRamoAtividadeProfissao.descricao': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstRamoAtividadeProfissao
                  ? data.listaRegraEscolhaCstRamoAtividadeProfissao.map(
                      (valor) => {
                        return (
                          <div key={valor.ramoAtividadeProfissao.descricao}>
                            {`${valor.ramoAtividadeProfissao.descricao};`}
                          </div>
                        );
                      }
                    )
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstEstado.estado.sigla': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstEstado
                  ? data.listaRegraEscolhaCstEstado.map((valor) => {
                      return (
                        <div key={valor.estado.sigla}>
                          {`${valor.estado.sigla};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstCidade.cidade.nome': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstCidade
                  ? data.listaRegraEscolhaCstCidade.map((valor) => {
                      return (
                        <div key={valor.cidade.nome}>
                          {`${valor.cidade.nome};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstModeloDocumento.modeloDocumento.codigoDescricao':
              (
                <div className="lista-texto">
                  {data.listaRegraEscolhaCstModeloDocumento
                    ? data.listaRegraEscolhaCstModeloDocumento.map((valor) => {
                        return (
                          <div key={valor.modeloDocumento.codigo}>
                            {`${valor.modeloDocumento.codigo} - ${valor.modeloDocumento.descricao};`}
                          </div>
                        );
                      })
                    : ''}
                </div>
              ),

            'listaRegraEscolhaCstSerie.serie.codigoDescricao': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstSerie
                  ? data.listaRegraEscolhaCstSerie.map((valor) => {
                      return (
                        <div key={valor.serie.codigo}>
                          {`${valor.serie.codigo} - ${valor.serie.descricao};`}
                        </div>
                      );
                    })
                  : ''}
              </div>
            ),

            'listaRegraEscolhaCstEstadoEmpresaLogada.estado.sigla': (
              <div className="lista-texto">
                {data.listaRegraEscolhaCstEstadoEmpresaLogada
                  ? data.listaRegraEscolhaCstEstadoEmpresaLogada.map(
                      (valor) => {
                        return (
                          <div key={valor.estado.sigla}>
                            {`${valor.estado.sigla};`}
                          </div>
                        );
                      }
                    )
                  : ''}
              </div>
            ),

            'regraEscolhaCstResultado.codigoSituacaoTributariaIcms.codigoDescricao':
              (
                <div className="lista-texto">
                  {data.regraEscolhaCstResultado?.codigoSituacaoTributariaIcms
                    ? `${data.regraEscolhaCstResultado.codigoSituacaoTributariaIcms.codigo} - ${data.regraEscolhaCstResultado.codigoSituacaoTributariaIcms.descricao}`
                    : ''}
                </div>
              ),

            'regraEscolhaCstResultado.calcularIcms': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularIcms
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularIcms ? 'Sim' : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.calcularCreditoIcmsSn': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularCreditoIcmsSn
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularCreditoIcmsSn
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.calcularSt': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularSt
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularSt ? 'Sim' : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.utilizaIcmsOperacaoInterestadual': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado
                    ?.utilizaIcmsOperacaoInterestadual
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.utilizaIcmsOperacaoInterestadual
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.calcularIcmsOperacaoInterestadual': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado
                    ?.calcularIcmsOperacaoInterestadual
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado
                  ?.calcularIcmsOperacaoInterestadual
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.calcularIcmsStRetido': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularIcmsStRetido
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularIcmsStRetido
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.calcularIcmsEfetivo': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularIcmsEfetivo
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularIcmsEfetivo
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.calcularIcmsSubstituto': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularIcmsSubstituto
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularIcmsSubstituto
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.modalidadeBaseCalculoIcms': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.modalidadeBaseCalculoIcms || ''}
              </div>
            ),
            'regraEscolhaCstResultado.modalidadeBaseCalculoIcmsSt': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.modalidadeBaseCalculoIcmsSt ||
                  ''}
              </div>
            ),
            'regraEscolhaCstResultado.motivoDesoneracaoIcms': (
              <div
                className="lista-texto"
                key={data.regraEscolhaCstResultado?.motivoDesoneracaoIcms}
              >
                {data.regraEscolhaCstResultado?.motivoDesoneracaoIcms || ''}
              </div>
            ),

            'regraEscolhaCstResultado.calcularDesoneracaoIcms': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularDesoneracaoIcms
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularDesoneracaoIcms
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),
            'regraEscolhaCstResultado.deduzirDesoneracaoIcms': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.deduzirDesoneracaoIcms
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.deduzirDesoneracaoIcms
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.codigoSituacaoTributariaIpi.codigoDescricao':
              (
                <div className="lista-texto">
                  {data.regraEscolhaCstResultado?.codigoSituacaoTributariaIpi
                    ? `${data.regraEscolhaCstResultado.codigoSituacaoTributariaIpi.codigo} - ${data.regraEscolhaCstResultado.codigoSituacaoTributariaIpi.descricao}`
                    : ''}
                </div>
              ),

            'regraEscolhaCstResultado.ipiCodigoEnquadramentoLegal.codigoDescricao':
              (
                <div className="lista-texto">
                  {data.regraEscolhaCstResultado?.ipiCodigoEnquadramentoLegal
                    ? `${data.regraEscolhaCstResultado.ipiCodigoEnquadramentoLegal.codigo} - ${data.regraEscolhaCstResultado.ipiCodigoEnquadramentoLegal.descricao}`
                    : ''}
                </div>
              ),

            'regraEscolhaCstResultado.calcularIpi': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularIpi
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularIpi ? 'Sim' : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.codigoSituacaoTributariaPisCofins.codigoDescricao':
              (
                <div className="lista-texto">
                  {data.regraEscolhaCstResultado
                    ?.codigoSituacaoTributariaPisCofins
                    ? `${data.regraEscolhaCstResultado.codigoSituacaoTributariaPisCofins.codigo} - ${data.regraEscolhaCstResultado.codigoSituacaoTributariaPisCofins.descricao}`
                    : ''}
                </div>
              ),

            'regraEscolhaCstResultado.calcularPisCofins': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularPisCofins
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularPisCofins
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),
            'regraEscolhaCstResultado.calcularPisCofinsRetido': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularPisCofinsRetido
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularPisCofinsRetido
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),
            'regraEscolhaCstResultado.calcularFunrural': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado?.calcularFunrural
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularFunrural
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),
            'regraEscolhaCstResultado.calcularImpostoAproximado': (
              <div
                className="lista-booleano"
                style={{
                  color: data.regraEscolhaCstResultado
                    ?.calcularImpostoAproximado
                    ? 'green'
                    : 'red',
                }}
              >
                {data.regraEscolhaCstResultado?.calcularImpostoAproximado
                  ? 'Sim'
                  : 'Não'}
              </div>
            ),

            'regraEscolhaCstResultado.codigoBeneficiarioFiscalSped': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.codigoBeneficiarioFiscalSped}
              </div>
            ),
            'regraEscolhaCstResultado.baseLegalIcms': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.baseLegalIcms}
              </div>
            ),
            'regraEscolhaCstResultado.baseLegalIpi': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.baseLegalIpi}
              </div>
            ),
            'regraEscolhaCstResultado.outraBaseLegal': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.outraBaseLegal}
              </div>
            ),
            'regraEscolhaCstResultado.informacoesContribuinte': (
              <div className="lista-texto">
                {data.regraEscolhaCstResultado?.informacoesContribuinte}
              </div>
            ),

            'listaRegraEscolhaCstEmpresa.ativo': (
              <div
                className="lista-booleano"
                style={{
                  color: data.listaRegraEscolhaCstEmpresa[0]?.ativo
                    ? 'green'
                    : 'red',
                }}
              >
                {data.listaRegraEscolhaCstEmpresa[0]?.ativo ? 'Sim' : 'Não'}
              </div>
            ),
            acoes: (
              <div
                className="lista-acoes"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Acoes
                  deletarFuncao={async () => {
                    try {
                      setLoading(true);

                      await RegraEscolhaCstComunicador.delete({
                        id: data.id,
                      });

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  buscarLogs={async (params: any) => {
                    try {
                      setLoading(true);

                      const responseLog = RegraEscolhaCstLogComunicador.show({
                        id: data.id,
                        params,
                      });

                      setLoading(false);
                      return responseLog;
                    } catch (error) {
                      setLoading(false);
                      throw error;
                    }
                  }}
                  disableDeletar={
                    !permissoes.FiscalTributacaoRegraEscolhaCst?.exclui ||
                    !(
                      data.tipo === RegraEscolhaTipoEnum.padrao &&
                      tipoUsuario !== 'Elo Gestor'
                    )
                  }
                />
              </div>
            ),
          };
        });

        setLoading(false);
        return { dados, totalPaginas: response.dados[1] };
      } catch (error) {
        TratarErros(error);
        setLoading(false);
        return { dados: [], totalPaginas: 0 };
      }
    },
    [permissoes.FiscalTributacaoRegraEscolhaCst?.exclui, tipoUsuario]
  );

  const handlePesquisarListaTh = useCallback(async () => {
    try {
      const response = await PersonalizacaoListaCamposComunicador.index({
        params: { nomeTabela: 'fiscalRegraEscolhaCst' },
      });

      const listaTh: ITh[] = [
        ...response.campos,
        {
          id: 'acao-key',
          arrastavel: false,
          nomeCampo: 'acoes',
          ordenar: false,
          tamanho: null,
          titulo: 'Ações',
          visivel: true,
          style: { width: 75 },
        },
      ];

      return listaTh;
    } catch (error) {
      TratarErros(error);
      return [];
    }
  }, []);

  const handleAtualizarOrdem = useCallback(async (lista: any) => {
    await RegraEscolhaCstOrdem.update({
      params: {
        listaOrdem: lista,
      },
    });
  }, []);

  return (
    <Container>
      <ListaProvider>
        <SubHeader
          titulo="Regra de Escolha da CST"
          permissao={permissoes.FiscalTributacaoRegraEscolhaCst}
          configuracaoItens={
            <>
              <Dropdown.Item
                onClick={() => {
                  abrirJanelaLog(async (params) => {
                    return RegraEscolhaCstLogComunicador.index({ params });
                  });
                }}
              >
                <span style={{ display: 'flex', fontSize: 16 }}>
                  <CgPlayListSearch style={{ marginRight: 5 }} />
                  Logs
                </span>
              </Dropdown.Item>
            </>
          }
        />
        <PesquisaAvancada />

        <ListaComTrDraggable
          pesquisarDados={handlePesquisarDados}
          pesquisarListaTh={handlePesquisarListaTh}
          onAtualizarOrdem={handleAtualizarOrdem}
          paginacaoPadrao={1000}
          opcao1000
          loading={loading}
        />
      </ListaProvider>
    </Container>
  );
};

export default RegraEscolhaCstLista;
