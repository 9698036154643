"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var DiaSemanaEnum;
(function (DiaSemanaEnum) {
    DiaSemanaEnum["domingo"] = "Domingo";
    DiaSemanaEnum["segundaFeira"] = "Segunda-Feira";
    DiaSemanaEnum["tercaFeira"] = "Ter\u00E7a-Feira";
    DiaSemanaEnum["quartaFeira"] = "Quarta-Feira";
    DiaSemanaEnum["quintaFeira"] = "Quinta-Feira";
    DiaSemanaEnum["sextaFeira"] = "Sexta-Feira";
    DiaSemanaEnum["sabado"] = "S\u00E1bado";
})(DiaSemanaEnum || (DiaSemanaEnum = {}));
exports.default = DiaSemanaEnum;
