/* eslint-disable array-callback-return */
/* eslint-disable no-continue */
/* eslint-disable default-case */
import React, { useEffect, useCallback, useRef } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import JanelaDetalhe from '../../../../../Componentes/JanelaDetalhe';
import Divisor from '../../../../../Componentes/Divisor';
import Textarea from '../../../../../Componentes/Inputs/Textarea';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import { BtnContainer } from '../../Pessoa/Detalhe/Main/styles';
import { UseRecuperarFormulario } from '../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import PessoaAtualizarObservacaoClienteComunicador from '../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaAtualizarObservacaoClienteComunicador';

interface IObservacaoCliente {
  cliente: { idCliente: string; observacoes: string };
  loading: boolean;
  setLoading(loading: boolean): void;
  onFecharFormModal(): void;
  onSalvarFormModal(): void;
}

const AbrirObservacaoCliente: React.FC<IObservacaoCliente> = ({
  onFecharFormModal,
  onSalvarFormModal,
  cliente,
  loading,
  setLoading,
}) => {
  const formRef = useRef<IFormCiaHandles>(null);

  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const handleCarregarDados = useCallback(async (): Promise<void> => {
    await formRef.current?.setDataInicial({
      observacoes: cliente.observacoes,
    });
  }, [cliente.observacoes]);

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const novaObservacao = formRef.current.getFieldValue('observacoes');

      await PessoaAtualizarObservacaoClienteComunicador.update({
        params: {
          idCliente: cliente.idCliente,
          observacoes: novaObservacao,
        },
      });

      if (onSalvarFormModal) onSalvarFormModal();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [cliente.idCliente, onSalvarFormModal, setLoading]);

  const handleClickVoltar = useCallback(async (): Promise<void> => {
    if (formRef.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});
      if (resposta) {
        return;
      }
    }

    if (onFecharFormModal) onFecharFormModal();
  }, [abrirRecuperarFormulario, onFecharFormModal]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Observação"
      tamanho="lg"
      onFecharFormModal={handleClickVoltar}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <FormCia ref={formRef}>
        <Modal.Body>
          <Divisor>
            <Row>
              <Col lg={12} md={6} sm={12}>
                <Textarea
                  style={{ minHeight: 150 }}
                  label="Observação do Cliente"
                  name="observacoes"
                  maxLength={10000}
                  onChange={(event) => {
                    const quantidadeDeLinhas =
                      event.target.value.split('\n').length;
                    const height = `${20 + 15 * quantidadeDeLinhas}px`;
                    event.target.style.height = height;
                  }}
                />
              </Col>
            </Row>
          </Divisor>
        </Modal.Body>

        <Modal.Footer>
          <BtnContainer>
            <button
              type="button"
              onClick={handleClickSalvar}
              className="btn-padrao btn-verde"
            >
              <TextoLoadingSalvar loading={loading} />
            </button>
          </BtnContainer>
        </Modal.Footer>
      </FormCia>
    </JanelaDetalhe>
  );
};

export default AbrirObservacaoCliente;
