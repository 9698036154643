import api from '../../../../../Comum/Services/Api';

const rota = 'suprimentos/produtos/produto/produto-possui-estrutura';

interface IProdutoPossuiEstruturaComunicadorShow {
  id: string;
}

class ProdutoPossuiEstruturaComunicador {
  public async show(
    params: IProdutoPossuiEstruturaComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new ProdutoPossuiEstruturaComunicador();
