import { IEmpresaValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import GeralTab from '../GeralTab';
import TributacaoTab from '../TributacaoTab';
import { BtnContainer } from './styles';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import LogotipoTab from '../LogotipoTab';
import LoadingDiv from '../../../../../Componentes/LoadingDiv';
import SubstitutoTributarioTab from '../SubstitutoTributarioTab';
import SisdevinTab from '../SisdevinTab';
import ImpostoCustoTab from '../ImpostoCustoTab';
import ParametroTab from '../ParametroTab';

type IProps = IMain<IEmpresaValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes, tipoUsuario } = UsePermissoes();
  const { ConfiguracaoEmpresaDetalhe: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosObrigatorios,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <Tabs id={v4()} defaultActiveKey="geral">
        <Tab eventKey="geral" title="Geral">
          <GeralTab />
        </Tab>

        <Tab eventKey="tributacao" title="Tributação">
          <TributacaoTab />
        </Tab>

        <Tab eventKey="impostoCusto" title="Imposto p/ Custo">
          <ImpostoCustoTab />
        </Tab>

        <Tab eventKey="logotipo" title="Logotipo">
          <LogotipoTab />
        </Tab>

        <Tab eventKey="substitutoTributario" title="Subst. Tributário">
          <SubstitutoTributarioTab />
        </Tab>

        <Tab eventKey="sisdevin" title="Sisdevin">
          <SisdevinTab />
        </Tab>

        <Tab
          style={{ display: tipoUsuario === 'Elo Gestor' ? 'block' : 'none' }}
          eventKey="parametros"
          title="Parâmetros"
        >
          <ParametroTab />
        </Tab>
      </Tabs>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
