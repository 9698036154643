/* eslint-disable default-case */
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  AppErro,
  ModeloImpressaoOrcamentoEnum,
  SituacaoOrcamentoEnum,
  SituacaoOrcamentoOrdemServicoEnum,
  SituacaoOrcamentoPedidoVendaEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend, BiXCircle } from 'react-icons/bi/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { GiMoneyStack } from 'react-icons/gi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoOrcamentoRetratoSemFotoProduto from './Impressao/Template/OrcamentoRetratoSemFotoProdutoImpressao';
import ImpressaoOrcamentoPaisagemSemFotoProduto from './Impressao/Template/OrcamentoPaisagemSemFotoProdutoImpressao';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { UseOrcamentoAtualizarSituacao } from '../Hook/AtualizarSitucaoHook';
import OrcamentoRetratoSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoRetratoSemFotoProdutoImpressao/OrcamentoRetratoSemFotoProdutoImpressaoComunicador';
import OrcamentoPaisagemSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoPaisagemSemFotoProdutoImpressao/OrcamentoPaisagemSemFotoProdutoImpressaoComunicador';
import OrcamentoAprovarComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoAprovarComunicador';
import OrcamentoRejeitarComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoRejeitarComunicador';
import OrcamentoCancelarComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/OrcamentoCancelarComunicador';
import { UseOrcamento } from '../Hook/OrcamentoHook';
import { UsePedidoVendaImportacaoOrcamento } from '../../../PedidoVendaImportacaoOrcamentoHook';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import OrcamentoPaisagemComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoPaisagemComFotoProdutoImpressao/OrcamentoPaisagemComFotoProdutoImpressaoComunicador';
import ImpressaoOrcamentoPaisagemComFotoProduto from './Impressao/Template/OrcamentoPaisagemComFotoProdutoImpressao';
import ImpressaoOrcamentoRetratoComFotoProduto from './Impressao/Template/OrcamentoRetratoComFotoProdutoImpressao';
import OrcamentoRetratoComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoRetratoComFotoProdutoImpressao/OrcamentoRetratoComFotoProdutoImpressaoComunicador';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import OrcamentoRetratoComSemValoresItensImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoRetratoComSemValoresItensImpressao/OrcamentoRetratoComSemValoresItensImpressaoComunicador';
import ImpressaoOrcamentoRetratoComSemValoresItens from './Impressao/Template/OrcamentoRetratoComSemValoresItensImpressao';
import ImpressaoOrcamentoPaisagemComNcmCst from './Impressao/Template/OrcamentoPaisagemComNcmCstImpressao';
import { UseOrdemServicoImportacaoOrcamento } from '../../../../../Servicos/OrdensServicos/OrdemServicoImportacaoOrcamentoHook';
import OrcamentoPaisagemComNcmCstImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoPaisagemComNcmCstImpressao/OrcamentoPaisagemComNcmCstImpressaoComunicador';
import OrcamentoRetratoComFinanceiroTransporteImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoRetratoComFinanceiroTransporteImpressao/OrcamentoRetratoComFinanceiroTransporteImpressaoComunicador';
import ImpressaoOrcamentoRetratoComFinanceiroTransporteImpressao from './Impressao/Template/OrcamentoRetratoComFinanceiroTransporteImpressao';
import OrcamentoPaisagemComFinanceiroPrevisaoEntregaComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoPaisagemComFinanceiroPrevisaoEntrega/OrcamentoPaisagemComFinanceiroPrevisaoEntregaComunicador';
import ImpressaoOrcamentoPaisagemComFinanceiroPrevisaoEntregaImpressao from './Impressao/Template/OrcamentoPaisagemComFinanceiroPrevisaoEntregaImpressao';
import ImpressaoOrcamentoPaisagemComFinanceiroPrevisaoEntregaRepImpressao from './Impressao/Template/OrcamentoPaisagemComFinanceiroPrevisaoEntregaRepImpressao';
import OrcamentoPaisagemComFinanceiroPrevisaoEntregaRepComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/OrcamentoPaisagemComFinanceiroPrevisaoEntregaRep/OrcamentoPaisagemComFinanceiroPrevisaoEntregaRepComunicador';
import OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Impressao/OrcamentoRetratoComPrevisaoEntregaEmDiasImpressao/OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoComunicador';
import ImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias from './Impressao/Template/OrcamentoRetratoComPrevisaoEntregaEmDiasImpressao';

interface IAcoesOrcamento {
  permissao?: IPermissao;
}

const AcoesOrcamento: React.FC<IAcoesOrcamento> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();
  const parametros = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();

  const { abrirOrcamentoAtualizarSituacao, setTitulo } =
    UseOrcamentoAtualizarSituacao();

  const {
    setTitulo: setTituloPedidoVendaImportacaoOrcamento,
    abrirPedidoVendaImportacaoOrcamento,
  } = UsePedidoVendaImportacaoOrcamento();

  const {
    setTitulo: setTituloOrdemServicoImportacaoOrcamento,
    abrirOrdemServicoImportacaoOrcamento,
  } = UseOrdemServicoImportacaoOrcamento();

  const { listaRepresentante } = UseOrcamento();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [showEnviarEmail, setShowEnviarEmail] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState(false);

  const [imprimirFinanceiro, setImprimirFinanceiro] = useState(false);
  const [enviarPorEmailFinanceiro, setEnviarPorEmailFinanceiro] =
    useState(false);
  const [gerarPedidoVendaFinanceiro, setGerarPedidoVendaFinanceiro] =
    useState(false);
  const [gerarOrdemServicoFinanceiro, setGerarOrdemServicoFinanceiro] =
    useState(false);

  const situacaoOrcamento = formRef.current?.getFieldValue('situacao');
  const situacaoOrcamentoPedidoVenda = formRef.current?.getFieldValue(
    'situacaoOrcamentoPedidoVenda'
  );
  const situacaoOrcamentoOrdemServico = formRef.current?.getFieldValue(
    'situacaoOrcamentoOrdemServico'
  );

  const listaOrcamentoItem =
    formRef.current?.getFieldValue('listaOrcamentoItem') || [];

  const idPessoa = formRef.current?.getFieldValue('orcamentoPessoa.idPessoa');

  const numero = formRef.current?.getFieldValue('numero');
  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');

  const handleValidar = useCallback(
    async (
      situacaoOrcamentoValidar?: SituacaoOrcamentoEnum
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (
          situacaoOrcamentoValidar &&
          situacaoOrcamentoValidar !== SituacaoOrcamentoEnum.pendente
        )
          return true;

        if (parametros.RepresentanteObrigatorioOrcamento) {
          if (listaRepresentante.length < 1) {
            throw new AppErro({
              mensagem: 'No mínimo um Representante é obrigatório!',
            });
          }
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, listaRepresentante.length, parametros]
  );

  const handleSalvar = useCallback(
    async (
      situacaoOrcamentoValidar?: SituacaoOrcamentoEnum
    ): Promise<boolean> => {
      try {
        if (!handleValidar(situacaoOrcamentoValidar)) return false;

        if (formRef.current?.validarSeAlterou()) {
          const data = formRef.current?.getData();
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar]
  );

  const handleVisualizarOuObterBlob = useCallback(
    async (visualizarPdf: boolean): Promise<Blob | undefined> => {
      const idRegistro = getIdDetalheRegistro() || '';

      if (listaOrcamentoItem.length === 0) {
        ToastInfo('Informe pelo menos um item para realizar a impressão!');
      } else {
        switch (parametros.ModeloParaImpressaoOrcamento) {
          case ModeloImpressaoOrcamentoEnum.retratoSemFotoProduto:
            {
              const response =
                await OrcamentoRetratoSemFotoProdutoImpressaoComunicador.show({
                  id: idRegistro,
                });

              if (visualizarPdf) {
                await ImpressaoOrcamentoRetratoSemFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoOrcamentoRetratoSemFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoOrcamentoEnum.paisagemSemFotoProduto: {
            const response =
              await OrcamentoPaisagemSemFotoProdutoImpressaoComunicador.show({
                id: idRegistro,
              });

            if (visualizarPdf) {
              await ImpressaoOrcamentoPaisagemSemFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoPaisagemSemFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrcamentoEnum.paisagemComFotoProduto: {
            const response =
              await OrcamentoPaisagemComFotoProdutoImpressaoComunicador.show({
                id: idRegistro,
              });

            if (visualizarPdf) {
              await ImpressaoOrcamentoPaisagemComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoPaisagemComFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrcamentoEnum.retratoComFotoProduto: {
            const response =
              await OrcamentoRetratoComFotoProdutoImpressaoComunicador.show({
                id: idRegistro,
              });

            if (visualizarPdf) {
              await ImpressaoOrcamentoRetratoComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoRetratoComFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrcamentoEnum.retratoComSemValoresItens: {
            const response =
              await OrcamentoRetratoComSemValoresItensImpressaoComunicador.show(
                { id: idRegistro }
              );

            const resposta = await abrirJanela({
              titulo: <h2>Impressão Orçamento</h2>,

              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Imprimir o Orçamento com Valores nos Itens?
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (visualizarPdf) {
              await ImpressaoOrcamentoRetratoComSemValoresItens.handleVisualizarPdf(
                resposta,
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoRetratoComSemValoresItens.handleObterBlobPdf(
                  resposta,
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrcamentoEnum.paisagemComNcmCst: {
            const response =
              await OrcamentoPaisagemComNcmCstImpressaoComunicador.show({
                id: idRegistro,
              });

            if (visualizarPdf) {
              await ImpressaoOrcamentoPaisagemComNcmCst.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoPaisagemComNcmCst.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrcamentoEnum.retratoComFinanceiroTransporte: {
            const response =
              await OrcamentoRetratoComFinanceiroTransporteImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoOrcamentoRetratoComFinanceiroTransporteImpressao.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoRetratoComFinanceiroTransporteImpressao.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
          case ModeloImpressaoOrcamentoEnum.paisagemComFinanceiroPrevisaoEntrega: {
            const response =
              await OrcamentoPaisagemComFinanceiroPrevisaoEntregaComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoOrcamentoPaisagemComFinanceiroPrevisaoEntregaImpressao.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoPaisagemComFinanceiroPrevisaoEntregaImpressao.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
          case ModeloImpressaoOrcamentoEnum.paisagemComFinanceiroPrevisaoEntregaRepresentante: {
            const response =
              await OrcamentoPaisagemComFinanceiroPrevisaoEntregaRepComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoOrcamentoPaisagemComFinanceiroPrevisaoEntregaRepImpressao.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoPaisagemComFinanceiroPrevisaoEntregaRepImpressao.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
          case ModeloImpressaoOrcamentoEnum.retratoComPrevisaoEntregaEmDias: {
            const response =
              await OrcamentoRetratoComPrevisaoEntregaEmDiasImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrcamentoRetratoComPrevisaoEntregaEmDias.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }
        }
      }
      return undefined;
    },
    [
      abrirJanela,
      getIdDetalheRegistro,
      listaOrcamentoItem.length,
      parametros.ModeloParaImpressaoOrcamento,
    ]
  );

  const handleImprimirFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      await handleVisualizarOuObterBlob(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleVisualizarOuObterBlob, setLoading]);

  const handleEnviarPorEmailFinanceiro = useCallback(async () => {
    setShowEnviarEmail(true);
  }, []);

  const handleGerarPedidoVendaFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloPedidoVendaImportacaoOrcamento('Gerar Pedido de Venda');
      const response = await abrirPedidoVendaImportacaoOrcamento({
        idPessoa: String(idPessoa),
        idOrcamento: idRegistro,
      });

      if (response) {
        navigate({
          pathname: `/comercial/vendas/pedido-venda/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirPedidoVendaImportacaoOrcamento,
    getIdDetalheRegistro,
    idPessoa,
    navigate,
    setLoading,
    setTituloPedidoVendaImportacaoOrcamento,
  ]);

  const handleGerarOrdemServicoFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloOrdemServicoImportacaoOrcamento('Gerar Ordem de Serviço');
      const response = await abrirOrdemServicoImportacaoOrcamento({
        idPessoa: String(idPessoa),
        idOrcamento: idRegistro,
      });

      if (response) {
        navigate({
          pathname: `/servico/ordens-servicos/ordem-servico/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirOrdemServicoImportacaoOrcamento,
    getIdDetalheRegistro,
    idPessoa,
    navigate,
    setLoading,
    setTituloOrdemServicoImportacaoOrcamento,
  ]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();

    await handleSubmit(data);

    setShowFinanceiro(false);

    if (imprimirFinanceiro) {
      await handleImprimirFinanceiro();
      setImprimirFinanceiro(false);
    }

    if (enviarPorEmailFinanceiro) {
      await handleEnviarPorEmailFinanceiro();
      setEnviarPorEmailFinanceiro(false);
    }

    if (gerarPedidoVendaFinanceiro) {
      await handleGerarPedidoVendaFinanceiro();
      setGerarPedidoVendaFinanceiro(false);
    }
  }, [
    enviarPorEmailFinanceiro,
    formRef,
    gerarPedidoVendaFinanceiro,
    handleEnviarPorEmailFinanceiro,
    handleGerarPedidoVendaFinanceiro,
    handleImprimirFinanceiro,
    handleSubmit,
    imprimirFinanceiro,
  ]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoOrcamento))) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setImprimirFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleImprimirFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleImprimirFinanceiro,
    handleSalvar,
    setLoading,
    situacaoOrcamento,
    valorFinanceiro,
  ]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    // #region Financeiro

    const financeiroConferido = formRef.current?.getFieldValue(
      'financeiroConferido'
    );

    if (financeiroConferido && valorFinanceiro > 0) {
      await handleFinanceiro();
      setLoading(false);
      setEnviarPorEmailFinanceiro(true);
      return;
    }

    // #endregion Financeiro

    await handleEnviarPorEmailFinanceiro();
  }, [
    formRef,
    handleEnviarPorEmailFinanceiro,
    handleFinanceiro,
    handleSalvar,
    setLoading,
    valorFinanceiro,
  ]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: true,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: false,
          direcionarOrcamento: true,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      const anexo = await handleVisualizarOuObterBlob(false);

      await formRefEmail.current?.setDataInicial({
        assunto: `Orçamento número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo: [
          {
            nomeArquivo: `Orcamento ${numero}.pdf`,
            file: anexo,
            permitirExcluir: false,
          },
        ],
      });
    },
    [handleVisualizarOuObterBlob, idPessoa, numero]
  );

  // #endregion Email

  const handleAprovar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTitulo('Selecione os Itens a serem Aprovados');
      const atualizarSituacao =
        await abrirOrcamentoAtualizarSituacao(idRegistro);

      if (!atualizarSituacao) {
        setLoading(false);
        return;
      }

      const listaIdItem = atualizarSituacao.listaValorSelecionado.map(
        (item) => {
          return item.id;
        }
      );

      let rejeitarNaoSelecionador = false;

      if (listaIdItem.length !== atualizarSituacao.listaValor.length) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          confimar: 'Sim',
          cancelar: 'Não',
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja rejeitar os itens não aprovados?
            </span>
          ),
        });

        rejeitarNaoSelecionador = resposta;
      }

      const response = await OrcamentoAprovarComunicador.update({
        id: idRegistro,
        params: { listaIdItem, rejeitarNaoSelecionador },
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    abrirOrcamentoAtualizarSituacao,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
    setTitulo,
  ]);

  const handleRejeitar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTitulo('Selecione os Itens a serem Rejeitados');
      const atualizarSituacao =
        await abrirOrcamentoAtualizarSituacao(idRegistro);

      if (!atualizarSituacao) {
        setLoading(false);
        return;
      }

      const listaIdItem = atualizarSituacao.listaValorSelecionado.map(
        (item) => {
          return item.id;
        }
      );

      let aprovarNaoSelecionador = false;

      if (listaIdItem.length !== atualizarSituacao.listaValor.length) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          confimar: 'Sim',
          cancelar: 'Não',
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja aprovar os itens não rejeitados?
            </span>
          ),
        });

        aprovarNaoSelecionador = resposta;
      }

      const response = await OrcamentoRejeitarComunicador.update({
        id: idRegistro,
        params: { listaIdItem, aprovarNaoSelecionador },
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    abrirOrcamentoAtualizarSituacao,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
    setTitulo,
  ]);

  const handleGerarPedidoVenda = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setGerarPedidoVendaFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleGerarPedidoVendaFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleSalvar,
    setLoading,
    formRef,
    valorFinanceiro,
    handleGerarPedidoVendaFinanceiro,
    handleFinanceiro,
  ]);

  const handleGerarOrdemServico = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setGerarOrdemServicoFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleGerarOrdemServicoFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    handleSalvar,
    setLoading,
    formRef,
    valorFinanceiro,
    handleGerarOrdemServicoFinanceiro,
    handleFinanceiro,
  ]);

  const handleCancelar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente cancelar esse Orçamento?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response = await OrcamentoCancelarComunicador.update({
        id: idRegistro,
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleEnviarPorEmail}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoOrcamento === SituacaoOrcamentoEnum.aprovado ||
            listaOrcamentoItem.length === 0
          }
          onClick={handleAprovar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Aprovar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoOrcamento === SituacaoOrcamentoEnum.rejeitado ||
            listaOrcamentoItem.length === 0
          }
          onClick={handleRejeitar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiXCircle style={{ marginRight: 5 }} />
            Rejeitar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoOrcamento === SituacaoOrcamentoEnum.pendente ||
            situacaoOrcamento === SituacaoOrcamentoEnum.rejeitado ||
            situacaoOrcamento === SituacaoOrcamentoEnum.cancelado ||
            situacaoOrcamentoPedidoVenda ===
              SituacaoOrcamentoPedidoVendaEnum.atendido
          }
          onClick={handleGerarPedidoVenda}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Pedido de Venda
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoOrcamento === SituacaoOrcamentoEnum.pendente ||
            situacaoOrcamento === SituacaoOrcamentoEnum.rejeitado ||
            situacaoOrcamento === SituacaoOrcamentoEnum.cancelado ||
            situacaoOrcamentoOrdemServico ===
              SituacaoOrcamentoOrdemServicoEnum.atendido
          }
          onClick={handleGerarOrdemServico}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Ordem de Serviço
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoOrcamento === SituacaoOrcamentoEnum.cancelado
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Orçamento por E-mail"
          mensagemPadrao={`Segue em anexo o Orçamento número: ${numero}`}
          direcionarOrcamento
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}
    </>
  );
};

export default AcoesOrcamento;
