import api from '../../../../../Comum/Services/Api';

interface PessoaObterPessoaRepresentantePorUsuarioComunicadorShow {
  params: {
    comportamentoUsuarioRepresentante?: boolean;
  };
}

const rota = 'comercial/pessoas/pessoa-obter-pessoa-representante-por-usuario';

class PessoaObterPessoaRepresentantePorUsuarioComunicador {
  public async show(
    params?: PessoaObterPessoaRepresentantePorUsuarioComunicadorShow
  ): Promise<any[]> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }
}

export default new PessoaObterPessoaRepresentantePorUsuarioComunicador();
