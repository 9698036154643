import {
  FormatarDataParaPtBr,
  IContaParcelaLiquidacaoLista,
} from '@elogestor/util';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import {
  IoMdRemoveCircleOutline,
  IoMdAddCircleOutline,
} from 'react-icons/io/index.mjs';
import { v4 } from 'uuid';
import { UseListaDetalheForm } from '../../../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseForm } from '../../../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UsePermissoes } from '../../../../../../../../../../Hooks/Permissoes';
import { Tabela } from '../../../../../../../../../../Componentes/Tabela/styles';
import BtnAcoesButton from '../../../../../../../../../../Componentes/Buttons/BtnAcoesButton';
import BtnPadraoButton from '../../../../../../../../../../Componentes/Buttons/BtnPadraoButton';
import TextoLoading from '../../../../../../../../../../Componentes/TextoLoading';
import { UseRecuperarFormulario } from '../../../../../../../../../../Componentes/RecuperarFormulario/HooksRecuperarFormulario';
import Detalhe from '../Detalhe';
import ToastErro from '../../../../../../../../../../Util/Toasts/ToastErro';

interface IListaDetalheGeral {
  name: string;
  indexParcela: number;
}

const ListaDetalheContaParcelaLiquidacao: React.FC<IListaDetalheGeral> = ({
  name,
  indexParcela,
}) => {
  const { fieldName, registerField } = UseFieldCia(name);
  const {
    formRefDetalhe,
    formRefLista,
    loading,
    handleExcluir,
    setIdDetalheRegistro,
    getIdDetalheRegistro,
    handleCarregarDados,
  } = UseListaDetalheForm();
  const formPrincipal = UseForm();
  const { permissoes } = UsePermissoes();

  const isAdiantamentoAntecipacao =
    formPrincipal.formRef.current?.getFieldValue('isAdiantamentoAntecipacao');

  const idFormaPagamento =
    formRefLista.current?.getFieldValue('idFormaPagamento');
  const idPortador = formRefLista.current?.getFieldValue('idPortador');

  const revertida = formPrincipal.formRef.current?.getFieldValue('revertida');

  const permissao = isAdiantamentoAntecipacao
    ? permissoes.FinanceiroAdiantamentoPrevisao
    : permissoes.FinanceiroMovimentacoesContasReceberPagar;

  const { abrirRecuperarFormulario } = UseRecuperarFormulario();

  const [listaValor, setListaValor] = useState<IContaParcelaLiquidacaoLista[]>(
    []
  );
  const valorRef = useRef<IContaParcelaLiquidacaoLista[]>([]);

  const avista = formRefLista.current?.getFieldValue('avista');
  const [showFormModal, setShowFormModal] = useState(false);

  const handleSalvarFormModal = useCallback(async (): Promise<void> => {
    setIdDetalheRegistro('');
    setShowFormModal(false);
  }, [setIdDetalheRegistro]);

  const handleFecharFormModal = useCallback(async (): Promise<void> => {
    if (formRefDetalhe.current?.validarSeAlterou()) {
      const resposta = await abrirRecuperarFormulario({});

      if (resposta) {
        setShowFormModal(true);
      } else {
        setIdDetalheRegistro('');
        setShowFormModal(false);
      }
    } else {
      setIdDetalheRegistro('');
      setShowFormModal(false);
    }
  }, [abrirRecuperarFormulario, formRefDetalhe, setIdDetalheRegistro]);

  const handleLimparFormModal = useCallback((): void => {
    setIdDetalheRegistro('');
    formPrincipal.handleCarregarDados();
  }, [formPrincipal, setIdDetalheRegistro]);

  const handleAbrirModal = useCallback((): any => {
    const totalAberto = formRefLista.current?.getFieldValue('valorTotalAberto');

    // if (totalAberto <= 0) {
    //   ToastErro(
    //     'Valor Total Aberto deve ser maior que Zero para Adicionar uma Liquidação!'
    //   );

    //   return;
    // }

    setShowFormModal(true);
    setIdDetalheRegistro('');
  }, [formRefLista, setIdDetalheRegistro]);

  const handleEditar = useCallback(
    async (index: string): Promise<void> => {
      setIdDetalheRegistro(index);
      setShowFormModal(true);
      handleCarregarDados();
    },
    [handleCarregarDados, setIdDetalheRegistro]
  );

  const handleRemover = useCallback(
    async (index: string) => {
      const indexAgregado = `${index} ${indexParcela}`;
      await handleExcluir(indexAgregado);
      const listaContaParcela =
        formPrincipal.formRef.current?.getFieldValue('listaContaParcela');
      const listaDados = formRefLista.current?.getFieldValue(
        'listaContaParcelaLiquidacao'
      );

      const valorTotalMovimentos = listaDados
        .reduce((acc: number, item: any) => {
          acc += Number(item.valor);
          return acc;
        }, 0)
        .Arredondar();

      formRefLista.current?.setFieldValue(
        'valorTotalMovimentos',
        valorTotalMovimentos
      );

      listaContaParcela[indexParcela].valorTotalMovimentos =
        valorTotalMovimentos;

      if (valorTotalMovimentos === 0) {
        formRefLista.current?.setFieldValue('situacao', 'Aberta');
        listaContaParcela[indexParcela].situacao = 'Aberta';
      } else {
        formRefLista.current?.setFieldValue(
          'situacao',
          'Parcialmente Liquidada'
        );
        listaContaParcela[indexParcela].situacao = 'Parcialmente Liquidada';
      }
    },
    [formPrincipal.formRef, formRefLista, handleExcluir, indexParcela]
  );

  useEffect(() => {
    valorRef.current = listaValor;
  }, [formPrincipal.formRef, listaValor]);

  useEffect(() => {
    registerField<IContaParcelaLiquidacaoLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 30 }}>
      <Row>
        <Col xl={3} lg={6} sm={6} xs={12} className="bottom">
          <BtnPadraoButton
            type="button"
            className="btn-padrao btn-verde-claro btn-adicionar"
            onClick={handleAbrirModal}
            disabled={
              !permissao?.inclui ||
              formPrincipal.loading ||
              loading ||
              revertida ||
              !idFormaPagamento ||
              !idPortador
            }
          >
            <TextoLoading loading={formPrincipal.loading || loading}>
              <IoMdAddCircleOutline />
              <span style={{ marginLeft: 10 }}>Adicionar Liquidação</span>
            </TextoLoading>
          </BtnPadraoButton>
        </Col>
      </Row>

      <Tabela style={{ overflow: 'auto' }}>
        <Table striped hover bordered variant="light">
          <thead>
            <tr>
              <th className="lista-valor" style={{ width: '20%' }}>
                <span>Valor</span>
              </th>
              <th className="lista-data" style={{ width: '20%' }}>
                <span>Data Liquidação</span>
              </th>
              <th className="lista-data" style={{ width: '20%' }}>
                <span>Data Contábil</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Forma Pagamento</span>
              </th>
              <th className="lista-texto" style={{ width: '20%' }}>
                <span>Portador</span>
              </th>

              <th className="lista-texto">
                <span>Ações</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {listaValor.map((item, index) => {
              return (
                <tr
                  key={v4()}
                  onClick={() => {
                    handleEditar(String(index));
                  }}
                >
                  <td className="lista-valor">
                    {Number(item.valor).FormatarParaPtBr({
                      maximoCasasDecimais: 2,
                      minimoCasasDecimais: 2,
                    })}
                  </td>
                  <td className="lista-data">
                    {FormatarDataParaPtBr(item.data)}
                  </td>
                  <td className="lista-data">
                    {FormatarDataParaPtBr(item.dataContabil)}
                  </td>
                  <td className="lista-texto">
                    {item.formaPagamento && item.formaPagamento.descricao}
                  </td>
                  <td className="lista-texto">
                    {item.portador && item.portador.descricao}
                  </td>

                  <td className="tdButton lista-acoes">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnAcoesButton>
                        <Dropdown.Item
                          onClick={async () => {
                            handleRemover(String(index));
                          }}
                          disabled={
                            !permissao?.exclui ||
                            formPrincipal.loading ||
                            loading ||
                            (listaValor.length === 1 && avista) ||
                            revertida
                          }
                        >
                          <span style={{ display: 'flex', fontSize: 16 }}>
                            <IoMdRemoveCircleOutline
                              style={{ marginRight: 5 }}
                            />
                            Remover
                          </span>
                        </Dropdown.Item>
                      </BtnAcoesButton>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tabela>

      {showFormModal && (
        <Detalhe
          onSalvarFormModal={handleSalvarFormModal}
          onFecharFormModal={handleFecharFormModal}
          onLimparFormModal={handleLimparFormModal}
          indexParcela={indexParcela}
          indexParcelaLiquidacao={getIdDetalheRegistro()}
        />
      )}
    </div>
  );
};

export default ListaDetalheContaParcelaLiquidacao;
