/* eslint-disable no-nested-ternary */
import {
  AppErro,
  DestinadoEnum,
  IOrcamentoComercialRepresentanteLista,
  ItensRateioImpostoCalculoPorCst,
  RateioComCasaDecimalCalculo,
  TipoMercadoEnum,
  ValorTotalDocumentoCalculo,
  CalculoBaseComissao,
  ConverterParaNumberOrNull,
  IItensRateioImpostoCalculoPorCstRetorno,
  AbrirObservacaoClienteEnum,
} from '@elogestor/util';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaItem } from './ListaItemContext';
import SelecaoEnderecoPessoaDetalhe from '../PessoaTab/SelecaoEnderecoPessoaDetalhe';
import IEnderecoPessoa from '../PessoaTab/SelecaoEnderecoPessoaDetalhe/Interface/IEnderecoPessoa';
import ObterOrcamentoItensParaCalculoRateiosComunicador from '../../../../../../Comunicador/Comercial/Vendas/Orcamento/Comunicador/ObterOrcamentoItensParaCalculoRateiosComunicador';
import PessoaObterDadosCadastroComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosCadastroComunicador';
import RegraCalculoComissaoObterConformeCriteriosComunicador from '../../../../../../Comunicador/Comercial/Comissao/Comunicador/RegraCalculoComissaoObterConformeCriteriosComunicador';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import PessoaObterDadosPadraoComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Comunicador/PessoaObterDadosPadraoComunicador';
import { UseAlerta } from '../../../../../../Componentes/Alerta/HooksAlerta';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import AbrirObservacaoCliente from '../../../../Pessoas/PessoaClienteObservacao/AbrirObservacaoCliente';

interface IOrcamentoContext {
  validador: boolean;
  setFormaRateioValorFrete(value: boolean): void;
  setFormaRateioValorSeguro(value: boolean): void;
  setFormaRateioValorOutrasDespesas(value: boolean): void;
  setFormaRateioValorDesconto(value: boolean): void;
  atualizarRateiosListaItem(): void;
  atualizarDadosClienteConformeCadastro(): void;
  obterDadosPadraoCliente(): void;

  listaRepresentante: IOrcamentoComercialRepresentanteLista[];
  setListaRepresentante: React.Dispatch<
    React.SetStateAction<IOrcamentoComercialRepresentanteLista[]>
  >;
}

const OrcamentoContext = createContext<IOrcamentoContext>(
  {} as IOrcamentoContext
);

const OrcamentoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { listaItem } = UseListaItem();
  const { formRef, getIdDetalheRegistro, setLoading, loading } = UseForm();
  const { abrirJanela } = UseAlerta();

  const idDetalheRegistro = getIdDetalheRegistro();
  const [showModalSelecaoEndereco, setShowModalSelecaoEndereco] =
    useState(false);
  const [listaEndereco, setListaEndereco] = useState<IEnderecoPessoa[]>([]);
  const [listaRepresentante, setListaRepresentante] = useState<
    IOrcamentoComercialRepresentanteLista[]
  >([]);
  const [showObservacaoCliente, setShowObservacaoCliente] = useState(false);
  const [observacaoCliente, setObservacaoCliente] = useState({
    idCliente: '',
    observacoes: '',
  });
  const parametros = UseParametros();

  const handleSetFormaRateioValorFrete = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('freteFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorSeguro = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('seguroFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleSetFormaRateioValorOutrasDespesas = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue(
        'outrasDespesasFormaCalculoPorRateio',
        value
      );
    },
    [formRef]
  );

  const handleSetFormaRateioValorDesconto = useCallback(
    (value: boolean) => {
      formRef.current?.setFieldValue('descontoFormaCalculoPorRateio', value);
    },
    [formRef]
  );

  const handleAtualizarTotais = useCallback(() => {
    let totalProdutos = 0;
    let totalBruto = 0;

    if (listaItem.length === 0) {
      const valorFrete = formRef.current?.getFieldValue('valorFrete');
      const valorSeguro = formRef.current?.getFieldValue('valorSeguro');
      const valorOutrasDespesas = formRef.current?.getFieldValue(
        'valorOutrasDespesas'
      );
      const valorDesconto = formRef.current?.getFieldValue('valorDesconto');

      const valorTotal =
        valorFrete + valorSeguro + valorOutrasDespesas - valorDesconto;

      totalProdutos = valorTotal;
      totalBruto = valorTotal;
    } else {
      [totalProdutos, totalBruto] = listaItem.reduce(
        (acumulador, item) => {
          acumulador[0] += Number(item.valorTotalProduto) || 0;
          acumulador[1] += Number(item.valorTotalBruto) || 0;
          return acumulador;
        },
        [0, 0]
      );
    }

    formRef.current?.setFieldValue('valorTotalProdutos', totalProdutos);
    formRef.current?.setFieldValue('valorTotalBruto', totalBruto);
  }, [formRef, listaItem]);

  const handleAtualizarRateiosListaItem = useCallback(async () => {
    if (!idDetalheRegistro) return;

    try {
      const response =
        await ObterOrcamentoItensParaCalculoRateiosComunicador.index({
          id: idDetalheRegistro,
        });

      if (
        !response.listaOrcamentoItem ||
        response.listaOrcamentoItem.length !== listaItem.length
      ) {
        throw new AppErro({ mensagem: 'O orçamento esta desatualizado!' });
      }

      const valorFreteAtual = formRef.current?.getFieldValue('valorFrete') ?? 0;
      const valorSeguroAtual =
        formRef.current?.getFieldValue('valorSeguro') ?? 0;
      const valorOutrasDespesasAtual =
        formRef.current?.getFieldValue('valorOutrasDespesas') ?? 0;
      const valorDescontoAtual =
        formRef.current?.getFieldValue('valorDesconto') ?? 0;

      if (listaItem.length === 0) {
        const valorTotal =
          valorFreteAtual +
          valorSeguroAtual +
          valorOutrasDespesasAtual -
          valorDescontoAtual;

        formRef.current?.setFieldValue('valorTotalProdutos', valorTotal);
        formRef.current?.setFieldValue('valorTotalBruto', valorTotal);
        return;
      }

      const possuiItemGerarFaturamento = listaItem.some(
        (item) => item.orcamentoItemComercial?.gerarFaturamento
      );

      const listaItemValorTotalProduto = listaItem.map((item) => {
        if (item.orcamentoItemComercial?.gerarFaturamento)
          return Number(item.valorTotalProduto);

        return 0;
      });

      const listaValorFrete = RateioComCasaDecimalCalculo({
        valor: valorFreteAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorSeguro = RateioComCasaDecimalCalculo({
        valor: valorSeguroAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorOutrasDespesas = RateioComCasaDecimalCalculo({
        valor: valorOutrasDespesasAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaValorDesconto = RateioComCasaDecimalCalculo({
        valor: valorDescontoAtual,
        valores: listaItemValorTotalProduto,
        casasDecimais: 2,
      });

      const listaItensRateio =
        response.listaOrcamentoItem?.map((item, index) => {
          return {
            id: item.id,
            gerarFaturamento: item.orcamentoItemComercial?.gerarFaturamento,
            valorFreteItem:
              (possuiItemGerarFaturamento &&
                item.orcamentoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorFrete[index]
                : item.valorFrete
                  ? Number(item.valorFrete)
                  : null,
            valorSeguroItem:
              (possuiItemGerarFaturamento &&
                item.orcamentoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorSeguro[index]
                : item.valorSeguro
                  ? Number(item.valorSeguro)
                  : null,
            valorOutrasDespesasItem:
              (possuiItemGerarFaturamento &&
                item.orcamentoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorOutrasDespesas[index]
                : item.valorOutrasDespesas
                  ? Number(item.valorOutrasDespesas)
                  : null,
            valorDescontoItem:
              (possuiItemGerarFaturamento &&
                item.orcamentoItemComercial?.gerarFaturamento) ||
              !possuiItemGerarFaturamento
                ? listaValorDesconto[index]
                : item.valorDesconto
                  ? Number(item.valorDesconto)
                  : null,
            quantidadeItem: ConverterParaNumberOrNull(item.quantidade),
            valorUnitarioItem: ConverterParaNumberOrNull(item.valorUnitario),

            icms: {
              valorIpiItem: null,
              destinadoItem: item.destinado as DestinadoEnum,

              cstCsosn: item.orcamentoItemImpostoIcms?.situacaoTributaria
                ?.codigo
                ? item.orcamentoItemImpostoIcms?.situacaoTributaria?.codigo
                : '',
              tipoMercado: item.operacaoFiscal?.tipoMercado as TipoMercadoEnum,

              // #region ICMS

              calcularIcms: Boolean(
                item.orcamentoItemImpostoIcms?.calcularIcms
              ),
              modalidadeBaseCalculoIcms: item.orcamentoItemImpostoIcms
                ?.modalidadeBaseCalculoIcms
                ? item.orcamentoItemImpostoIcms?.modalidadeBaseCalculoIcms
                : null,
              aliquotaInterestadualIcms: null,

              percentualReducaoBaseCalculoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.percentualReducaoBaseCalculoIcms
              ),
              baseCalculoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoIcms
              ),
              aliquotaIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaIcms
              ),
              valorIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorIcms
              ),

              baseCalculoFcpIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoFcpIcms
              ),
              aliquotaFcpIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaFcpIcms
              ),
              valorFcpIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorFcpIcms
              ),

              percentualDiferimentoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.percentualDiferimentoIcms
              ),
              valorSemDiferimentoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorSemDiferimentoIcms
              ),
              valorDiferidoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorDiferidoIcms
              ),

              valorUnitarioPautaIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorUnitarioPautaIcms
              ),
              valorTotalPautaIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorTotalPautaIcms
              ),

              calcularIcmsEfetivo: Boolean(
                item.orcamentoItemImpostoIcms?.calcularIcmsEfetivo
              ),
              percentualReducaoBaseCalculoIcmsEfetivo:
                ConverterParaNumberOrNull(
                  item.orcamentoItemImpostoIcms
                    ?.percentualReducaoBaseCalculoIcmsEfetivo
                ),
              baseCalculoIcmsEfetivo: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoIcmsEfetivo
              ),
              aliquotaIcmsEfetivo: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaIcmsEfetivo
              ),
              valorIcmsEfetivo: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorIcmsEfetivo
              ),

              calcularIcmsSubstituto: Boolean(
                item.orcamentoItemImpostoIcms?.calcularIcmsSubstituto
              ),
              valorUnitarioIcmsSubstituto: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorUnitarioIcmsSubstituto
              ),
              valorIcmsSubstituto: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorIcmsSubstituto
              ),

              motivoDesoneracaoIcms: item.orcamentoItemImpostoIcms
                ?.motivoDesoneracaoIcms
                ? item.orcamentoItemImpostoIcms?.motivoDesoneracaoIcms
                : null,
              calcularDesoneracaoIcms: Boolean(
                item.orcamentoItemImpostoIcms?.calcularDesoneracaoIcms
              ),
              baseCalculoDesoneracaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoDesoneracaoIcms
              ),
              aliquotaDesoneracaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaDesoneracaoIcms
              ),
              valorDesoneracaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorDesoneracaoIcms
              ),

              baseCalculoSemReducaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoSemReducaoIcms
              ),
              valorSemReducaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorSemReducaoIcms
              ),
              baseCalculoReducaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoReducaoIcms
              ),
              valorReducaoIcms: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorReducaoIcms
              ),

              // #endregion ICMS

              // #region ICMS ST

              calcularIcmsSt: Boolean(
                item.orcamentoItemImpostoIcms?.calcularIcmsSt
              ),
              modalidadeBaseCalculoIcmsSt: item.orcamentoItemImpostoIcms
                ?.modalidadeBaseCalculoIcmsSt
                ? item.orcamentoItemImpostoIcms?.modalidadeBaseCalculoIcmsSt
                : null,

              percentualMvaIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.percentualMvaIcmsSt
              ),
              percentualReducaoMvaIcmsSt: null,
              percentualReducaoBaseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms
                  ?.percentualReducaoBaseCalculoIcmsSt
              ),
              aliquotaInterestadualIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaInterestadualIcmsSt
              ),
              aliquotaInternaIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaInternaIcmsSt
              ),
              baseCalculoIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoIcmsSt
              ),
              valorIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorIcmsSt
              ),

              baseCalculoFcpIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoFcpIcmsSt
              ),
              aliquotaFcpIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaFcpIcmsSt
              ),
              valorFcpIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorFcpIcmsSt
              ),

              calcularIcmsStRetido: Boolean(
                item.orcamentoItemImpostoIcms?.calcularIcmsStRetido
              ),
              baseCalculoIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoIcmsStRetido
              ),
              baseCalculoUnitariaProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms
                  ?.baseCalculoUnitariaProdutoIcmsStRetido
              ),
              aliquotaIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaIcmsStRetido
              ),
              valorIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorIcmsStRetido
              ),
              valorUnitarioProdutoIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorUnitarioProdutoIcmsStRetido
              ),

              baseCalculoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.baseCalculoFcpIcmsStRetido
              ),
              baseCalculoUnitariaProdutoFcpIcmsStRetido:
                ConverterParaNumberOrNull(
                  item.orcamentoItemImpostoIcms
                    ?.baseCalculoUnitariaProdutoFcpIcmsStRetido
                ),
              aliquotaFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.aliquotaFcpIcmsStRetido
              ),
              valorFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorFcpIcmsStRetido
              ),
              valorUnitarioProdutoFcpIcmsStRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms
                  ?.valorUnitarioProdutoFcpIcmsStRetido
              ),

              valorUnitarioPautaIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorUnitarioPautaIcmsSt
              ),
              valorTotalPautaIcmsSt: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorTotalPautaIcmsSt
              ),

              // #endregion ICMS ST

              // #region ICMS SN

              calcularCreditoIcmsSn: Boolean(
                item.orcamentoItemImpostoIcms?.calcularCreditoIcmsSn
              ),
              percentualCreditoIcmsSn: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.percentualCreditoIcmsSn
              ),
              valorCreditoIcmsSn: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIcms?.valorCreditoIcmsSn
              ),

              // #endregion ICMS SN
            },

            ipi: {
              calcular: Boolean(item.orcamentoItemImpostoIpi?.calcular),
              tipoCalculo: item.orcamentoItemImpostoIpi?.tipoCalculo
                ? item.orcamentoItemImpostoIpi?.tipoCalculo
                : null,
              cst: item.orcamentoItemImpostoIpi?.situacaoTributaria?.codigo
                ? item.orcamentoItemImpostoIpi?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadeIpi: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIpi?.quantidadeUnidade
              ),
              valorUnidadeIpi: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIpi?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIpi?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIpi?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoIpi?.valor
              ),
            },

            pis: {
              calcular: Boolean(item.orcamentoItemImpostoPis?.calcular),
              tipoCalculo: item.orcamentoItemImpostoPis?.tipoCalculo
                ? item.orcamentoItemImpostoPis?.tipoCalculo
                : null,
              cst: item.orcamentoItemImpostoPis?.situacaoTributaria?.codigo
                ? item.orcamentoItemImpostoPis?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadePis: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.quantidadeUnidade
              ),
              valorUnidadePis: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.valor
              ),

              calcularRetido: Boolean(
                item.orcamentoItemImpostoPis?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoPis?.valorRetido
              ),
            },

            cofins: {
              calcular: Boolean(item.orcamentoItemImpostoCofins?.calcular),
              tipoCalculo: item.orcamentoItemImpostoCofins?.tipoCalculo
                ? item.orcamentoItemImpostoCofins?.tipoCalculo
                : null,
              cst: item.orcamentoItemImpostoCofins?.situacaoTributaria?.codigo
                ? item.orcamentoItemImpostoCofins?.situacaoTributaria?.codigo
                : '',
              quantidadeUnidadeCofins: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.quantidadeUnidade
              ),
              valorUnidadeCofins: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.valorUnidade
              ),
              baseCalculo: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.baseCalculo
              ),
              aliquota: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.aliquota
              ),
              valor: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.valor
              ),

              calcularRetido: Boolean(
                item.orcamentoItemImpostoCofins?.calcularRetido
              ),
              baseCalculoRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.baseCalculoRetido
              ),
              aliquotaRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.aliquotaRetido
              ),
              valorRetido: ConverterParaNumberOrNull(
                item.orcamentoItemImpostoCofins?.valorRetido
              ),
            },
          };
        }) || [];

      const listaItemRateioComImpostos =
        ItensRateioImpostoCalculoPorCst(listaItensRateio);

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];
        const itemRateio = listaItensRateio[i];
        const itemRateioComImpostos = listaItemRateioComImpostos[i];

        const valorTotalBrutoItem = ValorTotalDocumentoCalculo({
          valorSubtotalItem: Number(listaItem[i].valorTotalProduto),

          valorFreteItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorFreteItem)
              : item.valorFrete
                ? Number(item.valorFrete)
                : 0,
          valorSeguroItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorSeguroItem)
              : item.valorSeguro
                ? Number(item.valorSeguro)
                : 0,
          valorDescontoItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorDescontoItem)
              : item.valorDesconto
                ? Number(item.valorDesconto)
                : 0,
          valorOutrasDespesasItem:
            (possuiItemGerarFaturamento && itemRateio?.gerarFaturamento) ||
            !possuiItemGerarFaturamento
              ? Number(itemRateio?.valorOutrasDespesasItem)
              : item.valorOutrasDespesas
                ? Number(item.valorOutrasDespesas)
                : 0,
          valorIcmsSt: itemRateioComImpostos.icms
            ? Number(itemRateioComImpostos.icms?.valorIcmsSt)
            : 0,
          valorFcpIcmsSt: itemRateioComImpostos.icms
            ? Number(itemRateioComImpostos.icms?.valorFcpIcmsSt)
            : 0,
          valorIpi: itemRateioComImpostos.ipi
            ? Number(itemRateioComImpostos.ipi?.valor)
            : 0,

          valorImpostoImportacao: null,
          valorServicos: null,

          valorDesoneracaoIcmsDeduzir: item.orcamentoItemImpostoIcms
            ?.deduzirDesoneracaoIcms
            ? Number(item.orcamentoItemImpostoIcms?.valorDesoneracaoIcms)
            : null,
        });

        listaItem[i].valorTotalBruto = valorTotalBrutoItem;
      }

      // #region ICMS

      const valorTotalBaseCalculoIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcms);
          return acumulador;
        },
        0
      );

      const valorTotalIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcms);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcms = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcms);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcms',
        valorTotalBaseCalculoIcms
      );
      formRef.current?.setFieldValue('valorTotalIcms', valorTotalIcms);
      formRef.current?.setFieldValue('valorTotalFcpIcms', valorTotalFcpIcms);

      // #endregion ICMS

      // #region ICMS ST

      const valorTotalBaseCalculoIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.baseCalculoIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorIcmsSt);
          return acumulador;
        },
        0
      );

      const valorTotalFcpIcmsSt = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.icms?.valorFcpIcmsSt);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIcmsSt',
        valorTotalBaseCalculoIcmsSt
      );
      formRef.current?.setFieldValue('valorTotalIcmsSt', valorTotalIcmsSt);
      formRef.current?.setFieldValue(
        'valorTotalFcpIcmsSt',
        valorTotalFcpIcmsSt
      );

      // #endregion ICMS ST

      // #region IPI

      const valorTotalBaseCalculoIpi = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalIpi = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.ipi?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoIpi',
        valorTotalBaseCalculoIpi
      );
      formRef.current?.setFieldValue('valorTotalIpi', valorTotalIpi);

      // #endregion IPI

      // #region PIS/COFINS

      const valorTotalBaseCalculoPis = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalPis = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPis',
        valorTotalBaseCalculoPis
      );
      formRef.current?.setFieldValue('valorTotalPis', valorTotalPis);

      const valorTotalBaseCalculoCofins = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.baseCalculo);
          return acumulador;
        },
        0
      );

      const valorTotalCofins = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valor);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofins',
        valorTotalBaseCalculoCofins
      );
      formRef.current?.setFieldValue('valorTotalCofins', valorTotalCofins);

      // #endregion PIS

      // #region PIS/COFINS Retido

      const valorTotalBaseCalculoPisRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.baseCalculoRetido);
          return acumulador;
        },
        0
      );

      const valorTotalPisRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.pis?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoPisRetido',
        valorTotalBaseCalculoPisRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalPisRetido',
        valorTotalPisRetido
      );

      const valorTotalBaseCalculoCofinsRetido =
        listaItemRateioComImpostos.reduce(
          (
            acumulador: number,
            item: IItensRateioImpostoCalculoPorCstRetorno
          ) => {
            acumulador += Number(item.cofins?.baseCalculoRetido);
            return acumulador;
          },
          0
        );

      const valorTotalCofinsRetido = listaItemRateioComImpostos.reduce(
        (acumulador: number, item: IItensRateioImpostoCalculoPorCstRetorno) => {
          acumulador += Number(item.cofins?.valorRetido);
          return acumulador;
        },
        0
      );

      formRef.current?.setFieldValue(
        'valorTotalBaseCalculoCofinsRetido',
        valorTotalBaseCalculoCofinsRetido
      );
      formRef.current?.setFieldValue(
        'valorTotalCofinsRetido',
        valorTotalCofinsRetido
      );

      // #endregion COFINS

      // #region Item Comercial Representante

      for (let i = 0; i < listaItem.length; i++) {
        const item = listaItem[i];
        if (
          item.orcamentoItemComercial &&
          item.orcamentoItemComercial.gerarComissao &&
          item.orcamentoItemComercial.listaOrcamentoItemComercialRepresentante
        ) {
          const baseCalculo = CalculoBaseComissao({
            valorTotalBruto: item.valorTotalBruto,
            valorImpostoIcms: item.orcamentoItemImpostoIcms
              ?.valorIcmsStEValorFcpIcmsSt
              ? item.orcamentoItemImpostoIcms?.valorIcmsStEValorFcpIcmsSt
              : 0,
            valorImpostoIpi: item.orcamentoItemImpostoIpi?.valor
              ? item.orcamentoItemImpostoIpi?.valor
              : 0,
            valorFrete: valorFreteAtual,
            valorOutrasDespesas: valorOutrasDespesasAtual,
            valorSeguro: valorSeguroAtual,
          });

          for (
            let j = 0;
            j <
            item.orcamentoItemComercial
              ?.listaOrcamentoItemComercialRepresentante?.length;
            j++
          ) {
            const valor =
              item.orcamentoItemComercial
                ?.listaOrcamentoItemComercialRepresentante[j];
            valor.baseCalculo = baseCalculo;
            valor.valor = baseCalculo * (Number(valor.aliquota) / 100);
          }
        }
      }

      // #endregion Item Comercial Representante

      // #region Comercial Representante

      const listaComercialRepresentante = formRef.current?.getFieldValue(
        'orcamentoComercial.listaOrcamentoComercialRepresentante'
      );

      if (
        listaComercialRepresentante &&
        listaComercialRepresentante.length > 0
      ) {
        const idPessoa = formRef.current?.getFieldValue(
          'orcamentoPessoa.idPessoa'
        );
        const dataHoraEmissao =
          formRef.current?.getFieldValue('dataHoraEmissao');

        listaComercialRepresentante.map(async (comercialRepresentante: any) => {
          let baseCalculo = 0;
          let valor = 0;

          if (listaItem && listaItem.length > 0) {
            await Promise.all(
              listaItem.map(async (item) => {
                if (
                  item &&
                  item.orcamentoItemComercial &&
                  item.orcamentoItemComercial
                    .listaOrcamentoItemComercialRepresentante
                ) {
                  item.orcamentoItemComercial?.listaOrcamentoItemComercialRepresentante.map(
                    async (itemComercialRepresentante) => {
                      if (
                        comercialRepresentante.idPessoaRepresentante ===
                          itemComercialRepresentante.idPessoaRepresentante &&
                        comercialRepresentante.formaCalculoPorRegra ===
                          itemComercialRepresentante.formaCalculoPorRegra
                      ) {
                        baseCalculo += Number(
                          itemComercialRepresentante.baseCalculo
                        );
                        valor += Number(itemComercialRepresentante.valor);
                      }
                    }
                  );
                }

                if (!comercialRepresentante.id) {
                  const baseCalculoRepresentate = CalculoBaseComissao({
                    valorTotalBruto: item.valorTotalBruto,
                    valorImpostoIcms: item.orcamentoItemImpostoIcms
                      ?.valorIcmsStEValorFcpIcmsSt
                      ? item.orcamentoItemImpostoIcms
                          ?.valorIcmsStEValorFcpIcmsSt
                      : 0,
                    valorImpostoIpi: item.orcamentoItemImpostoIpi?.valor
                      ? item.orcamentoItemImpostoIpi?.valor
                      : 0,
                    valorFrete: valorFreteAtual,
                    valorOutrasDespesas: valorOutrasDespesasAtual,
                    valorSeguro: valorSeguroAtual,
                  });
                  baseCalculo += baseCalculoRepresentate;

                  const regraCalculoComissao =
                    await RegraCalculoComissaoObterConformeCriteriosComunicador.show(
                      {
                        params: {
                          idPessoa,
                          idPessoaRepresentante:
                            comercialRepresentante.idPessoaRepresentante,
                          idProduto: item.idProduto,
                          idGrupoProdutoServico: item.produto
                            ? item.produto.idGrupoProdutoServico
                            : null,
                          dataPesquisaVigencia: new Date(dataHoraEmissao),
                          percentualDesconto: 0,
                        },
                      }
                    );

                  valor +=
                    baseCalculoRepresentate *
                    (Number(
                      regraCalculoComissao.regraCalculoComissao
                        .percentualComissao
                    ) /
                      100);
                }
              })
            );
          }

          const aliquota = valor / (baseCalculo === 0 ? 1 : baseCalculo);
          comercialRepresentante.baseCalculo = baseCalculo;
          comercialRepresentante.valor = valor;

          if (comercialRepresentante.formaCalculoPorRegra) {
            comercialRepresentante.aliquota = aliquota * 100;
          }
        });
      }

      // #endregion Comercial Representante

      handleAtualizarTotais();
    } catch (error) {
      TratarErros(error, { redirecionar: false });
    }
  }, [formRef, handleAtualizarTotais, idDetalheRegistro, listaItem]);

  useEffect(() => {
    handleAtualizarTotais();
  }, [formRef, handleAtualizarTotais]);

  const handleExibirMensagemAtualizacao = useCallback(async () => {
    const listaOrcamentoItem =
      formRef.current?.getFieldValue('listaOrcamentoItem');

    if (listaOrcamentoItem && listaOrcamentoItem.length > 0) {
      await abrirJanela({
        titulo: <h2>Atenção</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Devido a alteração da UF do Cliente, as informações tributárias e
            valores podem estar incorretos. Salve para atualizar os dados!
          </span>
        ),
      });
    }
  }, [abrirJanela, formRef]);

  const handleAtualizarDadosClienteConformeCadastro =
    useCallback(async (): Promise<any> => {
      try {
        const pessoa = formRef.current?.getFieldValueNomeObjeto(
          'orcamentoPessoa.pessoa'
        );
        const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
          'orcamentoPessoaEndereco.cidade.estado.sigla'
        );

        if (!pessoa) return;

        setLoading(true);

        const response = await PessoaObterDadosCadastroComunicador.show({
          id: pessoa.id,
        });

        await formRef.current?.setSemExecutarEvento({
          orcamentoPessoa: {
            cnpjCpfVirtual: response.cnpjCpfVirtual,
            inscricaoEstadual: response.inscricaoEstadual,
            inscricaoSuframa: response.inscricaoSuframa,
            documentoIdentificacaoEstrangeiro:
              response.documentoIdentificacaoEstrangeiro,
            nomeRazaoSocial: response.nomeRazaoSocial,
            nomeFantasia: response.nomeFantasia,
          },
        });

        if (response.pessoaEnderecoEntrega.outroEnderecoEntrega) {
          await formRef.current?.setSemExecutarEvento({
            orcamentoPessoaEnderecoEntrega: {
              cnpjCpfVirtual: response.pessoaEnderecoEntrega.cnpjCpfVirtual,
              inscricaoEstadual:
                response.pessoaEnderecoEntrega.inscricaoEstadual,
              nomeRazaoSocial: response.pessoaEnderecoEntrega.nomeRazaoSocial,
              outroEnderecoEntrega:
                response.pessoaEnderecoEntrega.outroEnderecoEntrega,
              cep: response.pessoaEnderecoEntrega.cep,
              cidade: response.pessoaEnderecoEntrega.cidade,
              logradouro: response.pessoaEnderecoEntrega.logradouro,
              numero: response.pessoaEnderecoEntrega.numero,
              bairro: response.pessoaEnderecoEntrega.bairro,
              complemento: response.pessoaEnderecoEntrega.complemento,
            },
          });
        } else {
          await formRef.current?.setSemExecutarEvento({
            orcamentoPessoaEnderecoEntrega: {
              cnpjCpfVirtual: null,
              inscricaoEstadual: null,
              nomeRazaoSocial: null,
              outroEnderecoEntrega: null,
              cep: null,
              cidade: null,
              logradouro: null,
              numero: null,
              bairro: null,
              complemento: null,
            },
          });
        }

        if (response.listaPessoaEndereco.length > 1) {
          setShowModalSelecaoEndereco(true);
          setListaEndereco(response.listaPessoaEndereco);
        } else if (response.listaPessoaEndereco.length === 1) {
          await formRef.current?.setSemExecutarEvento({
            orcamentoPessoaEndereco: {
              cep: response.listaPessoaEndereco[0].cep,
              cidade: response.listaPessoaEndereco[0].cidade,
              logradouro: response.listaPessoaEndereco[0].logradouro,
              numero: response.listaPessoaEndereco[0].numero,
              bairro: response.listaPessoaEndereco[0].bairro,
              complemento: response.listaPessoaEndereco[0].complemento,
            },
          });

          const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
            'orcamentoPessoaEndereco.cidade.estado.sigla'
          );

          if (pessoaUf !== pessoaUfNovo) {
            await handleExibirMensagemAtualizacao();
          }
        }

        if (response.pessoaCliente) {
          const listaOrcamentoComercialRepresentante =
            formRef.current?.getFieldValue(
              'orcamentoComercial.listaOrcamentoComercialRepresentante'
            );

          if (
            response.pessoaCliente.listaRepresentante &&
            response.pessoaCliente.listaRepresentante.length > 0
          ) {
            response.pessoaCliente.listaRepresentante.forEach((valor: any) => {
              const valoresInserir = {
                pessoaRepresentante: {
                  id: valor.pessoaRepresentante.id,
                  nomeRazaoSocial: valor.pessoaRepresentante.nomeRazaoSocial,
                },
                idPessoaRepresentante: valor.pessoaRepresentante.id,
                baseCalculo: 0,
                aliquota: 0,
                valor: 0,
                formaCalculoPorRegra: true,
              };
              const representanteExiste =
                listaOrcamentoComercialRepresentante.find(
                  (e: any) =>
                    e.idPessoaRepresentante === valor.pessoaRepresentante.id
                );

              if (!representanteExiste) {
                listaOrcamentoComercialRepresentante.push(valoresInserir);
              }
            });
          } else {
            formRef.current?.clearField(
              'orcamentoComercial.listaOrcamentoComercialRepresentante'
            );
          }

          if (
            listaOrcamentoComercialRepresentante &&
            listaOrcamentoComercialRepresentante.length > 0
          ) {
            listaOrcamentoComercialRepresentante.forEach((val: any) => {
              if (!val.formaCalculoPorRegra) {
                listaOrcamentoComercialRepresentante.push(val);
              }
            });
          }

          formRef.current?.setFieldValue(
            'orcamentoComercial.listaOrcamentoComercialRepresentante',
            listaOrcamentoComercialRepresentante
          );
        }

        if (
          parametros.AbrirTelaComObservacoesDoClienteOrcamento ===
            AbrirObservacaoClienteEnum.sempreAbrir ||
          (parametros.AbrirTelaComObservacoesDoClienteOrcamento ===
            AbrirObservacaoClienteEnum.somenteAbrirQuandoTiverObs &&
            response.observacoes !== '' &&
            response.observacoes !== null)
        ) {
          setObservacaoCliente({
            idCliente: pessoa.id,
            observacoes: response.observacoes,
          });
          setShowObservacaoCliente(true);
        }

        setLoading(false);
      } catch (error) {
        TratarErros(error);
        setLoading(false);
      }
    }, [
      formRef,
      handleExibirMensagemAtualizacao,
      parametros.AbrirTelaComObservacoesDoClienteOrcamento,
      setLoading,
    ]);

  const handleObterDadosPadraoCliente = useCallback(async () => {
    try {
      const pessoa = formRef.current?.getFieldValueNomeObjeto(
        'orcamentoPessoa.pessoa'
      );

      if (!pessoa) return;

      setLoading(true);

      const response = await PessoaObterDadosPadraoComunicador.show({
        id: pessoa.id,
      });

      if (response.modalidadeFrete) {
        await formRef.current?.setFieldValue(
          'orcamentoTransporteTransportadora.modalidadeFrete',
          response.modalidadeFrete
        );
      }

      if (response.pessoaTransportadora) {
        await formRef.current?.setFieldValue(
          'orcamentoTransporteTransportadora.pessoaTransportadora',
          response.pessoaTransportadora
        );
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [formRef, setLoading]);

  const handleOnSelecionarEndereco = useCallback(
    async (enderecoSelecionado: IEnderecoPessoa) => {
      const pessoaUf = formRef.current?.getFieldValueNomeObjeto(
        'orcamentoPessoaEndereco.cidade.estado.sigla'
      );

      if (enderecoSelecionado) {
        await formRef.current?.setSemExecutarEvento({
          orcamentoPessoaEndereco: {
            cep: enderecoSelecionado.cep,
            cidade: enderecoSelecionado.cidade,
            logradouro: enderecoSelecionado.logradouro,
            numero: enderecoSelecionado.numero,
            bairro: enderecoSelecionado.bairro,
            complemento: enderecoSelecionado.complemento,
          },
        });

        const pessoaUfNovo = formRef.current?.getFieldValueNomeObjeto(
          'orcamentoPessoaEndereco.cidade.estado.sigla'
        );

        if (pessoaUf !== pessoaUfNovo) {
          await handleExibirMensagemAtualizacao();
        }
      }
    },
    [formRef, handleExibirMensagemAtualizacao]
  );

  return (
    <OrcamentoContext.Provider
      value={{
        setFormaRateioValorFrete: handleSetFormaRateioValorFrete,
        setFormaRateioValorSeguro: handleSetFormaRateioValorSeguro,
        setFormaRateioValorOutrasDespesas:
          handleSetFormaRateioValorOutrasDespesas,
        setFormaRateioValorDesconto: handleSetFormaRateioValorDesconto,
        atualizarRateiosListaItem: handleAtualizarRateiosListaItem,
        atualizarDadosClienteConformeCadastro:
          handleAtualizarDadosClienteConformeCadastro,
        obterDadosPadraoCliente: handleObterDadosPadraoCliente,
        listaRepresentante,
        setListaRepresentante,
        validador: true,
      }}
    >
      {children}
      {showModalSelecaoEndereco && (
        <SelecaoEnderecoPessoaDetalhe
          listaEndereco={listaEndereco}
          onSelecionarEndereco={(item) => {
            handleOnSelecionarEndereco(item);
          }}
          onFecharFormModal={() => {
            setShowModalSelecaoEndereco(false);
          }}
        />
      )}

      {showObservacaoCliente && (
        <AbrirObservacaoCliente
          onSalvarFormModal={() => {
            setShowObservacaoCliente(false);
          }}
          onFecharFormModal={() => {
            setShowObservacaoCliente(false);
          }}
          loading={loading}
          setLoading={setLoading}
          cliente={observacaoCliente}
        />
      )}
    </OrcamentoContext.Provider>
  );
};

function UseOrcamento(): Omit<IOrcamentoContext, 'validador'> {
  const context = useContext(OrcamentoContext);

  if (!context.validador) {
    throw new Error('UseOrcamento deve ser usado com um OrcamentoProvider');
  }

  return context;
}

export { OrcamentoHook, UseOrcamento };
