/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UseFieldCia } from '@elogestor/unformcia';
import { DefaultInput, InputContainer, SpanErro } from '../Styles';
import { UseForm } from '../../Detalhe/Hooks/FormContext';

export interface IOnChangeInputTimeProps {
  timeValorAnterior: string;
}

export interface IInputTimeRef {
  focus(): void;
  setErro(error: string): void;
  value(): string | null;
  getHora(): string | null;
  setHora(valor: string | null): void;
}

interface IInputTimeProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  label?: string | JSX.Element;
  horaAtualPadrao?: boolean;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    props: IOnChangeInputTimeProps
  ) => void | Promise<void>;
}

const InputTime: React.ForwardRefRenderFunction<
  IInputTimeRef,
  IInputTimeProps
> = ({ name, label, horaAtualPadrao, onChange, ...rest }, ref) => {
  const { fieldName, error: erroUnform, registerField } = UseFieldCia(name);
  const { terminouCarregarDados } = UseForm();

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [erro, setErro] = useState(erroUnform);

  const inputTimeRef = useRef<HTMLInputElement>(null);
  const inputTimeValorAnterior = useRef('');

  useEffect(() => {
    inputTimeValorAnterior.current = inputTimeRef.current?.value || '';
  }, [terminouCarregarDados]);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  useEffect(() => {
    if (horaAtualPadrao && inputTimeRef.current) {
      const data = new Date();
      inputTimeRef.current.value = `${data.getHours().toString().padStart(2, '0')}:${data.getMinutes().toString().padStart(2, '0')}`;
    }
  }, [horaAtualPadrao]);

  const handleInputFocus = useCallback(() => {
    inputTimeValorAnterior.current = inputTimeRef.current?.value || '';
    setIsFocused(true);
  }, []);

  const handleInputChangeTime = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setErro('');
      if (onChange)
        onChange(event, {
          timeValorAnterior: inputTimeValorAnterior.current,
        });
    },
    [onChange]
  );

  const handleInputBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      setIsFilled(!!event.target.value);
    },
    []
  );

  const getHora = useCallback(() => {
    if (!inputTimeRef.current?.value) return null;
    return inputTimeRef.current.value;
  }, []);

  const setHora = useCallback((hora: string | null) => {
    if (!inputTimeRef.current) return;
    inputTimeRef.current.value = hora || '';
  }, []);

  const getValor = useCallback(() => {
    if (!inputTimeRef.current?.value) return null;
    return inputTimeRef.current.value;
  }, []);

  useEffect(() => {
    registerField<string | null>({
      name: fieldName,
      getValue: getValor,

      setValue(_, valor = '') {
        if (!valor || !inputTimeRef.current) return;
        inputTimeValorAnterior.current = inputTimeRef.current.value;
        inputTimeRef.current.value = valor;
      },

      setSemExecutarEvento(_, valor = '') {
        if (!valor || !inputTimeRef.current) return;
        inputTimeValorAnterior.current = inputTimeRef.current.value;
        inputTimeRef.current.value = valor;
      },

      clearValue(_, valorInicial = '') {
        this.setValue(_, valorInicial);
      },

      validarSeAlterou(_, valorInicial = null) {
        return this.getValue(_)?.toString() !== valorInicial?.toString();
      },

      setDisabled(valor) {
        if (!inputTimeRef.current) return;
        inputTimeRef.current.disabled = valor;
      },
    });
  }, [fieldName, getValor, registerField]);

  useImperativeHandle(ref, () => ({
    focus() {
      inputTimeRef.current?.focus();
    },

    value() {
      return getValor();
    },

    getHora() {
      return getHora();
    },

    setHora(valor) {
      setHora(valor);
    },

    setErro(error: string) {
      setErro(error);
    },
  }));

  return (
    <InputContainer>
      {label && <label>{label}</label>}
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        <DefaultInput
          $isErrored={!!erro}
          $isFocused={isFocused}
          $isFilled={isFilled}
          type="time"
          onFocus={handleInputFocus}
          onChange={handleInputChangeTime}
          onBlur={handleInputBlur}
          style={{
            width: '100%',
            minWidth: 90,
          }}
          {...rest}
          ref={inputTimeRef}
        />
      </div>
      {erro && <SpanErro>{erro}</SpanErro>}
    </InputContainer>
  );
};

export default forwardRef(InputTime);
