import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/pessoas/pessoa-obter-pessoa-representante-por-cliente';

interface IPessoaObterPessoaRepresentantePorClienteComunicadorShow {
  idPessoaCliente: string;
}

class PessoaObterPessoaRepresentantePorClienteComunicador {
  public async show(
    params: IPessoaObterPessoaRepresentantePorClienteComunicadorShow
  ): Promise<any[]> {
    const response = await api.get(`${rota}/${params.idPessoaCliente}`);
    return response.data;
  }
}

export default new PessoaObterPessoaRepresentantePorClienteComunicador();
