import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/comissoes/comissoes-pedido-venda/comissoes-pedido-venda-por-faturamento';

interface IComissoesPedidoVendaPorFaturamentoRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ComissoesPedidoVendaPorFaturamentoRelatorioComunicador {
  public async index(
    params: IComissoesPedidoVendaPorFaturamentoRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ComissoesPedidoVendaPorFaturamentoRelatorioComunicador();
