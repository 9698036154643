import React from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  BooleanEnum,
  FormatarEnum,
  OrigemValorUnitarioIcmsStRetidoEnum,
} from '@elogestor/util';
import Divisor from '../../../../../../Componentes/Divisor';
import InputDecimalNulavel from '../../../../../../Componentes/Inputs/InputDecimalNulavel';
import InputAutoCompleteSpedTipoProduto from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteSpedTipoProduto';
import InputAutoCompleteSpedGeneroItemServico from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteGeneroItemServico';
import InputAutoCompleteCest from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteCest';
import InputAutoCompleteAnp from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteAnp';
import InputAutoCompleteOrigemIcms from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteOrigemIcms';
import InputAutoCompleteProduto from '../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import InputAutoCompleteNcm from '../../../../../../Componentes/Inputs/AutoComplete/Fiscal/InputAutoCompleteNcm';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import Select from '../../../../../../Componentes/Select';
import Input from '../../../../../../Componentes/Inputs/Input';
import SpedGeneroItemServicoComunicador from '../../../../../../Comunicador/Fiscal/Sped/SpedGeneroItemServico/SpedGeneroItemServicoComunicador';
import InputDecimalNulavelSufixo from '../../../../../../Componentes/Inputs/InputDecimalNulavelSufixo';

const FiscalTab: React.FC = () => {
  const { formRef, getIdDetalheRegistro, refresh } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  const isProduto =
    formRef.current?.getFieldValue('tipoProdutoServico') === 'Produto';

  const getGeneroItemServico = async (ncm: string): Promise<void> => {
    if (ncm) {
      const response = await SpedGeneroItemServicoComunicador.show({
        params: { codigo: ncm.substring(0, 2) },
      });
      if (response) {
        formRef.current?.setFieldValue(
          'produtoFiscal.idSpedGeneroItemServico',
          response.id
        );
        formRef.current?.setFieldValue(
          'produtoFiscal.spedGeneroItemServico',
          response
        );
      }
    }
  };

  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteNcm
              label="NCM (Nomenclatura Comum do Mercosul)"
              name="produtoFiscal.idNcm"
              placeholder="NCM"
              nomeObjeto="produtoFiscal.ncm"
              utilizavelProduto
              onChangeItemAtualAposCarregarSemClear={() => {
                const ncm =
                  formRef.current?.getFieldValueNomeObjeto('produtoFiscal.ncm');

                if (ncm) {
                  getGeneroItemServico(ncm?.codigo);
                }
                refresh();
              }}
              onChangeItemAtual={() => {
                const cest =
                  formRef.current?.getFieldValueNomeObjeto(
                    'produtoFiscal.cest'
                  );
                const ncm =
                  formRef.current?.getFieldValueNomeObjeto('produtoFiscal.ncm');

                if (ncm && cest && cest.listaIdNcm) {
                  if (!cest.listaIdNcm.includes(ncm.id)) {
                    formRef.current?.clearField('produtoFiscal.cest', {
                      setValorInicial: false,
                    });
                  }
                }

                refresh();
              }}
            />
          </Col>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteCest
              label="CEST (Código Especificador da Substituição Tributária)"
              name="produtoFiscal.idCest"
              placeholder="CEST"
              nomeObjeto="produtoFiscal.cest"
              idNcm={formRef.current?.getFieldValue('produtoFiscal.idNcm')}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteOrigemIcms
              label="Origem"
              name="produtoFiscal.idOrigemIcms"
              placeholder="Origem"
              nomeObjeto="produtoFiscal.origemIcms"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <InputAutoCompleteSpedTipoProduto
              label="Tipo SPED"
              name="produtoFiscal.idSpedTipoProduto"
              placeholder="Tipo SPED"
              nomeObjeto="produtoFiscal.spedTipoProduto"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Input
              label="FCI (Ficha de Conteúdo de Importação)"
              name="produtoFiscal.fci"
            />
          </Col>
          <Col lg={6}>
            <InputAutoCompleteSpedGeneroItemServico
              label="Genero"
              name="produtoFiscal.idSpedGeneroItemServico"
              nomeObjeto="produtoFiscal.spedGeneroItemServico"
              placeholder="Genero"
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteProduto
              label="Produto Unitário Tributado"
              name="produtoFiscal.idProdutoTributado"
              placeholder="Produto Unitário Tributado"
              nomeObjeto="produtoFiscal.produtoTributado"
              listaIdParaRemover={idDetalheRegistro ? [idDetalheRegistro] : []}
            />
          </Col>
          <Col lg={3} md={6} sm={12} hidden={!isProduto}>
            <InputDecimalNulavel
              label="Qtde. de Un. do Tributado"
              name="produtoFiscal.quantidadeTributado"
              placeholder="Quantidade"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} hidden={!isProduto}>
            <InputAutoCompleteAnp
              label="Código ANP (Agência Nacional do Petróleo)"
              name="produtoFiscal.idAnp"
              placeholder="Código ANP"
              nomeObjeto="produtoFiscal.anp"
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavelSufixo
              sufixo={'%'}
              label="Percentual Mistura Biodiesel"
              name="produtoFiscal.percentualMisturaBiodiesel"
              placeholder="Percentual Mistura Biodiesel"
              casasInteiras={11}
              casasDecimais={4}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <Select
              label="Sujeito a ST."
              name="produtoFiscal.sujeitoASt"
              options={FormatarEnum({
                enumObj: BooleanEnum,
                nullavel: true,
              })}
              // onChange={handleChangeSujeitoASt}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!isProduto}>
        <h5>ICMS ST. Retido</h5>
        <Row>
          <Col lg={3} md={6} sm={6}>
            <Select
              label="Origem Valores ICMS ST. Retido"
              name="produtoFiscal.origemValorUnitarioIcmsStRetido"
              options={FormatarEnum({
                enumObj: OrigemValorUnitarioIcmsStRetidoEnum,
              })}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Base Cálc. ICMS ST. Retido"
              name="produtoFiscal.baseCalculoIcmsStRetido"
              placeholder="Base Cálc. ICMS ST. Retido"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Alíquota ICMS ST. Retido"
              name="produtoFiscal.aliquotaIcmsStRetido"
              placeholder="Alíquota ICMS ST. Retido"
              casasInteiras={5}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Valor Total ICMS ST. Retido"
              name="produtoFiscal.valorTotalIcmsStRetido"
              placeholder="Valor Total ICMS ST. Retido"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Base Cálc. FCP ICMS ST. Retido"
              name="produtoFiscal.baseCalculoFcpIcmsStRetido"
              placeholder="Base Cálc. FCP ICMS ST. Retido"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Alíquota FCP ICMS ST. Retido"
              name="produtoFiscal.aliquotaFcpIcmsStRetido"
              placeholder="Alíquota FCP ICMS ST. Retido"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Valor FCP ICMS ST. Retido"
              name="produtoFiscal.valorFcpIcmsStRetido"
              placeholder="Valor FCP ICMS ST. Retido"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!isProduto}>
        <h5>ICMS Efetivo</h5>
        <Row>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Alíquota ICMS ST. Efetivo"
              name="produtoFiscal.aliquotaIcmsEfetivo"
              placeholder="Alíquota ICMS ST. Efetivo"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>

      <Divisor hidden={!isProduto}>
        <h5>ICMS Substituto</h5>
        <Row>
          <Col lg={3} md={6} sm={6}>
            <InputDecimalNulavel
              label="Valor ICMS Substituto"
              name="produtoFiscal.valorIcmsSubstituto"
              placeholder="Valor ICMS Substituto"
              casasInteiras={15}
              casasDecimais={2}
            />
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default FiscalTab;
