/* eslint-disable default-case */
import React, { useCallback, useState } from 'react';
import { Dropdown, Modal, Row } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { FaSync } from 'react-icons/fa/index.mjs';
import { BsArrowRight } from 'react-icons/bs/index.mjs';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../Util/Erro/TratarErros';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import EmpresaSimplo7SincronizarProdutosEloComunicador from '../../../../../Comunicador/Configuracao/Integracao/Simplo7/Comunicador/EmpresaSimplo7SincronizarProdutosEloComunicador';
import ToastErro from '../../../../../Util/Toasts/ToastErro';
import EmpresaSimplo7SincronizarEstoqueProdutoComunicador from '../../../../../Comunicador/Configuracao/Integracao/Simplo7/Comunicador/EmpresaSimplo7SincronizarEstoqueProdutoComunicador';
import Divisor from '../../../../../Componentes/Divisor';
import EmpresaSimplo7SincronizarPrecoVendaProdutoComunicador from '../../../../../Comunicador/Configuracao/Integracao/Simplo7/Comunicador/EmpresaSimplo7SincronizarPrecoVendaProdutoComunicador';

interface IAcoesEmpresaSimplo7 {
  permissao?: IPermissao;
}

const AcoesEmpresaSimplo7: React.FC<IAcoesEmpresaSimplo7> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { refresh, formRef, loading, setLoading } = UseForm();
  const { abrirJanela } = UseConfirmacao();
  const [listaErros, setListaErros] = useState<any[]>([]);

  const handleSincronizarProdutosElo = useCallback(async (): Promise<void> => {
    try {
      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja sincronizar os produtos do Simplo7 para o Elo?
          </span>
        ),
        cancelar: 'Não',
        confimar: 'Sim',
      });

      if (resposta) {
        setLoading(true);

        const response =
          await EmpresaSimplo7SincronizarProdutosEloComunicador.store();

        if (response) {
          const { sucessos, erros, code, mensagem } = response;
          if (code === '200') ToastSucesso(mensagem);
          if (erros.length > 0) {
            erros.length > 1 ? setListaErros(erros) : ToastErro(erros[0]);
          }
        }

        setLoading(false);
      }

      refresh();
    } catch (error) {
      setLoading(false);
      TratarErros(error);
    }
  }, [abrirJanela, refresh, setLoading]);

  const handleSincronizarEstoqueProdutoSimplo7 =
    useCallback(async (): Promise<void> => {
      try {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja sincronizar os estoques dos produtos do Elo para o Simplo7?
            </span>
          ),
          cancelar: 'Não',
          confimar: 'Sim',
        });

        if (resposta) {
          setLoading(true);

          const response =
            await EmpresaSimplo7SincronizarEstoqueProdutoComunicador.store();

          if (response) {
            const { sucessos, erros, code, mensagem } = response;
            if (code === '200') ToastSucesso(mensagem);
            if (erros.length > 0) {
              erros.length > 1 ? setListaErros(erros) : ToastErro(erros[0]);
            }
          }

          setLoading(false);
        }

        refresh();
      } catch (error) {
        setLoading(false);
        TratarErros(error);
      }
    }, [abrirJanela, refresh, setLoading]);

  const handleSincronizarPrecoVendaSimplo7 =
    useCallback(async (): Promise<void> => {
      try {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja sincronizar os preços de venda dos produtos do Elo para o
              Simplo7?
            </span>
          ),
          cancelar: 'Não',
          confimar: 'Sim',
        });

        if (resposta) {
          setLoading(true);
          const response =
            await EmpresaSimplo7SincronizarPrecoVendaProdutoComunicador.store();

          if (response) {
            const { sucessos, erros, code, mensagem } = response;
            if (code === '200') ToastSucesso(mensagem);
            if (erros.length > 0) {
              erros.length > 1 ? setListaErros(erros) : ToastErro(erros[0]);
            }
          }
          setLoading(false);
        }

        refresh();
      } catch (error) {
        setLoading(false);
        TratarErros(error);
      }
    }, [abrirJanela, refresh, setLoading]);

  const handleSincronizarProdutosSimplo7 =
    useCallback(async (): Promise<void> => {
      try {
        // const resposta = await abrirJanela({
        //   titulo: <h2>Confirmação</h2>,
        //   mensagem: (
        //     <span style={{ fontSize: 20 }}>
        //       Deseja sincronizar os produtos do Elo para o Simplo7?
        //     </span>
        //   ),
        //   cancelar: 'Não',
        //   confimar: 'Sim',
        // });

        // if (resposta) {
        //   setLoading(true);

        //     const response =
        //       await EmpresaSimplo7SincronizarEstoqueProdutoComunicador.store();

        //     if (response) {
        //       const { sucessos, erros, code, mensagem } = response;
        //       if (code === '200') ToastSucesso(mensagem);
        //       if (erros.length > 0) {
        //         erros.length > 1 ? setListaErros(erros) : ToastErro(erros[0]);
        //       }
        //     }
        //   setLoading(false);
        // }

        refresh();
      } catch (error) {
        setLoading(false);
        TratarErros(error);
      }
    }, [refresh, setLoading]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={
          loading || permissao?.altera === false || permissao?.exclui === false
        }
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleSincronizarProdutosElo}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Produtos Elo
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled onClick={handleSincronizarProdutosSimplo7}>
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Produtos Simplo7
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleSincronizarPrecoVendaSimplo7}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Preços Venda Simplo7
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleSincronizarEstoqueProdutoSimplo7}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <FaSync style={{ marginRight: 5 }} />
            Sinc. Estoque Produto Simplo7
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {listaErros.length > 0 && (
        <Modal
          show
          size="lg"
          onHide={() => setListaErros([])}
          scrollable
          centered
        >
          <Modal.Header
            style={{
              background: '#3397c4',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Modal.Title>Lista de erros</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Divisor>
              {listaErros.map((item, index) => (
                <Row>
                  <div key={index}>
                    <p>
                      <BsArrowRight style={{ color: 'red', marginRight: 6 }} />
                      {item}
                    </p>
                  </div>
                </Row>
              ))}
            </Divisor>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={() => {
                setListaErros([]);
              }}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                OK
              </span>
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AcoesEmpresaSimplo7;
