"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable no-shadow */
var TipoFinalizarEnum;
(function (TipoFinalizarEnum) {
    TipoFinalizarEnum["finalizarVenda"] = "Finalizar Venda";
    TipoFinalizarEnum["gerarPreVenda"] = "Gerar Pr\u00E9 Venda";
    TipoFinalizarEnum["gerarOrcamento"] = "Gerar Or\u00E7amento";
    TipoFinalizarEnum["gerarPedido"] = "Gerar Pedido";
    TipoFinalizarEnum["gerarNotaFiscalSaida"] = "Gerar Nota Fiscal de Sa\u00EDda";
    TipoFinalizarEnum["gerarRetiradaConsignado"] = "Gerar Retirada em Consignado";
})(TipoFinalizarEnum || (TipoFinalizarEnum = {}));
exports.default = TipoFinalizarEnum;
