import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Button, Container, Col, Row, Tabs, Tab } from 'react-bootstrap';
import { v4 } from 'uuid';
import { FormCia } from '@elogestor/unformcia';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { VscSaveAll, VscSaveAs } from 'react-icons/vsc/index.mjs';
import { TipoProdutoServicoEnum } from '@elogestor/util';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import InputAutoCompleteUnidadeMedida from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputInteiro from '../../../../../../../../Componentes/Inputs/InputInteiro';
import InputDecimal, {
  IInputDecimalRef,
} from '../../../../../../../../Componentes/Inputs/InputDecimal';
import InputDateTime from '../../../../../../../../Componentes/Inputs/InputDateTime';
import Divisor from '../../../../../../../../Componentes/Divisor';
import ItemInformacaoAdicionalTab from './ItemInformacaoAdicionalTab/index';
import { UseItemTab } from '../Hook/ItemTabHook';
import ItemOutrosValoresTab from './ItemOutrosValoresTab';
import ItemImpostoTab from './ItemImpostoTab';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import JanelaNavegacao from '../../../../../../../../Componentes/JanelaDetalhe/JanelaNavegacao';
import InputHiddenHtml from '../../../../../../../../Componentes/Inputs/InputHiddenHtml';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputAutoCompleteProdutoServico from '../../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProdutoServico';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ObterOrigemIcmsComunicador from '../../../../../../../../Comunicador/Fiscal/Icms/Origem/ObterOrigemIcmsComunicador/ObterOrigemIcmsComunicador';
import ItemEngenhariaTab from './ItemEngenhariaTab';
import { UseParametros } from '../../../../../../../../Hooks/ParametrosHook';
import ProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicador from '../../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Engenharia/CodigoReferencia/Comunicador/ProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicador';

interface IPedidoCompraItemModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
  onLimparFormModal(): void;
}

const PedidoCompraItemDetalhe: React.FC<IPedidoCompraItemModal> = ({
  onSalvarFormModal,
  onFecharFormModal,
  onLimparFormModal,
}) => {
  const parametros = UseParametros();
  const { permissoes } = UsePermissoes();
  const { SuprimentosComprasPedidoCompra: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
    inputRefFocus,
    handleSetarFocus,
  } = UseListaDetalheForm();

  const {
    calculaValorTotalProduto,
    calcularRateioItem,
    setItemOrdem,
    obterConfiguracaoTributariaConformeProduto,
    calcularQuantidadeTributadoProdutoTributado,
    calcularValorUnitarioTributadoProdutoTributado,
    obterRegraEscolhaAliquota,
    calcularValoresItensEngenhariaPesagem,
    calcularValoresItensEngenhariaMetragem,
    limparConfiguracaoTributaria,
  } = UseItemTab();

  const { telaPequena } = UseReactSizeMeBodyHook();

  const { formRef: formPrincipal } = UseForm();

  const parametro = UseParametros();

  const idDetalheRegistro = getIdDetalheRegistro();

  const subtotalRef = useRef<IInputDecimalRef>(null);
  const debounceChangeRef = useRef<number | null>(null);

  const modeloDocumento =
    formPrincipal.current?.getFieldValueNomeObjeto('modeloDocumento');
  const isServicos = modeloDocumento
    ? String(modeloDocumento.descricao).toUpperCase().includes('SERVIÇOS')
    : false;

  const isServico = modeloDocumento
    ? String(modeloDocumento.descricao).toUpperCase().includes('SERVIÇO')
    : false;

  const somenteServico = isServicos || isServico;

  const [tabSelecionada, setTabSelecionada] = useState('impostos');

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData() as any;

    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleClickSalvarENovo = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    await formRefDetalhe.current?.reset();
    if (onLimparFormModal) onLimparFormModal();

    handleCarregarDados();

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [
    formRefDetalhe,
    handleCarregarDados,
    handleSetarFocus,
    handleSubmit,
    onLimparFormModal,
  ]);

  const handleClickSalvarEContinuar = useCallback(async (): Promise<void> => {
    const data = formRefDetalhe.current?.getData();
    const { erro, id } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    setIdDetalheRegistro(id);

    if (handleSetarFocus) {
      handleSetarFocus();
    }
  }, [formRefDetalhe, handleSetarFocus, handleSubmit, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados, idDetalheRegistro]);

  useEffect(() => {
    if (!idDetalheRegistro) setItemOrdem();
  }, [idDetalheRegistro, setItemOrdem]);

  const handleCalcularValorTotalProdutos =
    useCallback(async (): Promise<void> => {
      calculaValorTotalProduto();
    }, [calculaValorTotalProduto]);

  const handleCalcularItemEngenhariaPesagem =
    useCallback(async (): Promise<void> => {
      calcularValoresItensEngenhariaPesagem();
    }, [calcularValoresItensEngenhariaPesagem]);

  const handleCalcularItemEngenhariaMetragem =
    useCallback(async (): Promise<void> => {
      calcularValoresItensEngenhariaMetragem();
    }, [calcularValoresItensEngenhariaMetragem]);

  const handleCalcularRateioItem = useCallback(() => {
    calcularRateioItem();
  }, [calcularRateioItem]);

  const handleObterConfiguracaoTributariaConformeProduto = useCallback(() => {
    obterConfiguracaoTributariaConformeProduto();
  }, [obterConfiguracaoTributariaConformeProduto]);

  const handleLimparConfiguracaoTributaria = useCallback(() => {
    limparConfiguracaoTributaria();
  }, [limparConfiguracaoTributaria]);

  const handleCalcularQuantidadeTributadoProdutoTributado =
    useCallback(async (): Promise<void> => {
      calcularQuantidadeTributadoProdutoTributado();
    }, [calcularQuantidadeTributadoProdutoTributado]);

  const handleCalcularValorUnitarioTributadoProdutoTributado =
    useCallback(() => {
      calcularValorUnitarioTributadoProdutoTributado();
    }, [calcularValorUnitarioTributadoProdutoTributado]);

  const handleObterRegraEscolhaAliquota =
    useCallback(async (): Promise<void> => {
      obterRegraEscolhaAliquota();
    }, [obterRegraEscolhaAliquota]);

  const handleObterOrigemIcms = useCallback(async () => {
    try {
      const origemIcms = await ObterOrigemIcmsComunicador.index({
        params: {
          codigo: 0,
        },
      });
      if (origemIcms) {
        formRefDetalhe.current?.setFieldValue(
          'pedidoCompraItemImpostoIcms.origemIcms',
          origemIcms
        );
      }
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe]);

  const obterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisa = async (
    id: string
  ): Promise<void> => {
    if (parametro.CarregarCodRefParaPesquisa === false) return;
    const listaProdutoEngenhariaCodigoBarrasReferenciaPesquisa =
      await ProdutoEngenhariaObterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisaComunicador.show(
        {
          id,
        }
      );

    if (
      parametros.CarregarCodRefParaPesquisa &&
      listaProdutoEngenhariaCodigoBarrasReferenciaPesquisa?.length > 0
    ) {
      let somaCodigos = '';

      for (
        let i = 0;
        i < listaProdutoEngenhariaCodigoBarrasReferenciaPesquisa.length;
        i++
      ) {
        const element = listaProdutoEngenhariaCodigoBarrasReferenciaPesquisa[i];

        if (somaCodigos !== '') {
          somaCodigos += ` `;
        }

        somaCodigos += element.codigoReferencia;
      }
      const valorAtual = formRefDetalhe.current?.getFieldValue(
        'informacoesAdicionaisProduto'
      );

      if (valorAtual.includes(somaCodigos)) return;

      if (!somaCodigos) return;

      formRefDetalhe.current?.setFieldValue(
        'informacoesAdicionaisProduto',
        valorAtual !== '' ? `${valorAtual} ${somaCodigos}` : somaCodigos
      );
    }
  };

  const handleObterValoresProdutoEngenharia = useCallback(async () => {
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const produtoEngenharia = produto?.produtoEngenharia;
    const pesoBruto = produtoEngenharia?.pesoBruto;
    const pesoLiquido = produtoEngenharia?.pesoLiquido;
    const quantidadeVolumes =
      produtoEngenharia?.quantidadeVolumes === null &&
      parametros.ConsiderarQuantidadeVolumesUnitarioComo1
        ? 1
        : produtoEngenharia?.quantidadeVolumes;
    const metroQuadrado = produtoEngenharia?.metroQuadrado;
    const metroCubico = produtoEngenharia?.metroCubico;

    formRefDetalhe.current?.setFieldValue(
      'pedidoCompraItemEngenharia.pesoBrutoUnitario',
      pesoBruto
    );

    formRefDetalhe.current?.setFieldValue(
      'pedidoCompraItemEngenharia.pesoLiquidoUnitario',
      pesoLiquido
    );

    formRefDetalhe.current?.setFieldValue(
      'pedidoCompraItemEngenharia.quantidadeVolumesUnitario',
      quantidadeVolumes
    );
    formRefDetalhe.current?.setFieldValue(
      'pedidoCompraItemEngenharia.metroQuadradoUnitario',
      metroQuadrado
    );
    formRefDetalhe.current?.setFieldValue(
      'pedidoCompraItemEngenharia.metroCubicoUnitario',
      metroCubico
    );
  }, [formRefDetalhe, parametros.ConsiderarQuantidadeVolumesUnitarioComo1]);

  return (
    <JanelaDetalhe
      titulo="Item"
      tamanho="xl"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <JanelaNavegacao
                containerStyle={
                  telaPequena
                    ? {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                    : {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        marginBottom: 10,
                      }
                }
              />
            </Col>
          </Row>
          <FormCia ref={formRefDetalhe}>
            <InputHiddenHtml name="situacao" />
            <Divisor>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <InputAutoCompleteProdutoServico
                    ref={inputRefFocus}
                    label="Produto/Serviço"
                    name="idProduto"
                    placeholder="Produto/Serviço"
                    nomeObjeto="produto"
                    onChangeItemAtual={(e) => {
                      if (e?.itemAtual?.id) {
                        obterListaProdutoEngenhariaCodigoBarrasReferenciaPesquisa(
                          e?.itemAtual?.id
                        );
                      }
                    }}
                    filtroProduto={!somenteServico}
                    filtroServico={somenteServico}
                    onChangeItemAtualAposCarregarSemClear={(ev) => {
                      handleLimparConfiguracaoTributaria();
                      handleObterConfiguracaoTributariaConformeProduto();

                      if (
                        ev.itemAtual &&
                        ev.itemAtual.tipoProdutoServico ===
                          TipoProdutoServicoEnum.servico
                      )
                        handleObterOrigemIcms();
                      handleObterValoresProdutoEngenharia();
                    }}
                    permitirConsultar={false}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <InputAutoCompleteUnidadeMedida
                    label="Unidade de Medida"
                    name="idUnidadeMedida"
                    placeholder="Unidade de Medida"
                    nomeObjeto="unidadeMedida"
                    permitirAdicionar={false}
                  />
                </Col>
                <Col lg={2} md={12} sm={12}>
                  <InputInteiro
                    label="Ordem"
                    name="ordem"
                    placeholder="Ordem"
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Quantidade"
                    name="quantidade"
                    casasInteiras={11}
                    casasDecimais={4}
                    onChange={(event) => {
                      if (debounceChangeRef.current) {
                        clearTimeout(debounceChangeRef.current);
                      }

                      debounceChangeRef.current = window.setTimeout(
                        async () => {
                          await handleCalcularItemEngenhariaPesagem();
                          await handleCalcularItemEngenhariaMetragem();

                          const valorUnitario =
                            formRefDetalhe.current?.getFieldValue(
                              'valorUnitario'
                            );

                          if (
                            event.target?.value?.ConverterParaNumber() !== 0 &&
                            valorUnitario > 0
                          ) {
                            await handleObterRegraEscolhaAliquota();
                          }

                          await handleCalcularValorTotalProdutos();
                          await handleCalcularQuantidadeTributadoProdutoTributado();
                        },
                        170
                      );
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Unitário"
                    name="valorUnitario"
                    casasInteiras={11}
                    casasDecimais={10}
                    onChange={async (event) => {
                      if (debounceChangeRef.current) {
                        clearTimeout(debounceChangeRef.current);
                      }

                      debounceChangeRef.current = window.setTimeout(
                        async () => {
                          const quantidade =
                            formRefDetalhe.current?.getFieldValue('quantidade');

                          if (
                            event.target?.value?.ConverterParaNumber() !== 0 &&
                            quantidade > 0
                          ) {
                            handleObterRegraEscolhaAliquota();
                          }

                          handleCalcularValorTotalProdutos();
                          handleCalcularValorUnitarioTributadoProdutoTributado();
                        },
                        170
                      );
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Subtotal (Qtde. * Val. Unit.)"
                    name="valorTotalProduto"
                    casasInteiras={13}
                    casasDecimais={2}
                    disabled
                    ref={subtotalRef}
                    onChangeValue={(event, { valorAnteriorOnChange }) => {
                      if (
                        valorAnteriorOnChange.ConverterParaNumber() === 0 &&
                        event.valor > 0
                      ) {
                        handleObterRegraEscolhaAliquota();
                      }
                      handleCalcularRateioItem();
                      subtotalRef.current && subtotalRef.current.focus();
                    }}
                  />
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <InputDecimal
                    label="Valor Total Pedido de Compra"
                    name="valorTotalBruto"
                    casasInteiras={13}
                    casasDecimais={2}
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputDateTime
                    label="Data Prevista Recebimento"
                    name="dataPrevistaRecebimento"
                  />
                </Col>
              </Row>
            </Divisor>

            <Tabs
              id={v4()}
              defaultActiveKey="impostos"
              activeKey={tabSelecionada}
              onSelect={(k) => setTabSelecionada(k || '')}
            >
              <Tab eventKey="impostos" title="Impostos">
                <ItemImpostoTab />
              </Tab>

              <Tab eventKey="outrosValores" title="Outros Valores">
                <ItemOutrosValoresTab />
              </Tab>

              <Tab eventKey="informacoesAdicionais" title="Inf. Adicionais">
                <ItemInformacaoAdicionalTab />
              </Tab>

              <Tab eventKey="engenharia" title="Engenharia">
                <ItemEngenhariaTab />
              </Tab>
            </Tabs>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer style={telaPequena ? { display: 'block' } : {}}>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
              style={
                telaPequena
                  ? {
                      width: '100%',
                      marginBottom: 5,
                    }
                  : {}
              }
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarENovo}
              id="salvarENovo"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAll />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar e Novo' : 'Adicionar e Novo'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              onClick={handleClickSalvarEContinuar}
              id="salvarEContinuar"
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <VscSaveAs />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro
                    ? 'Salvar e Continuar'
                    : 'Adicionar e Continuar'}
                </span>
              </TextoLoading>
            </Button>

            <Button
              style={
                telaPequena
                  ? {
                      width: '100%',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                      justifyContent: 'center',
                    }
                  : {
                      marginLeft: 15,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }
              }
              type="button"
              id="salvar"
              onClick={handleClickSalvar}
              disabled={
                (idDetalheRegistro ? !permissao?.altera : !permissao?.inclui) ||
                loading
              }
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>
                  {idDetalheRegistro ? 'Salvar' : 'Adicionar'}
                </span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default PedidoCompraItemDetalhe;
