/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { TipoAdiantamentoEnum, TipoContaEnum } from '@elogestor/util';
import AutoCompleteBase, {
  IInputAutoCompleteBaseRef,
  IOnChangeItemAtualEvent,
  IOnChangeTextoEvent,
} from '../../AutoCompleteBase';
import UseRegisterAutoComplete from '../../AutoCompleteBase/Hooks/UseRegisterAutoComplete';
import ContaTipoAdiantamentoEnumAutoCompleteComunicador from '../../../../../Comunicador/Financeiro/Movimentacoes/Conta/AutoComplete/ContaTipoAdiantamentoEnumAutoComplete/ContaTipoAdiantamentoEnumAutoCompleteComunicador';
import { Item } from '../../AutoCompleteBase/styles';
import TratarErros from '../../../../../Util/Erro/TratarErros';

interface IOnChange {
  valorAnterior: any;
}

interface IContaTipoAdiantamento {
  id: string;
  numeroDocumento: string;
  tipoAdiantamento: TipoAdiantamentoEnum;
  pessoa?: {
    id: string;
    nomeRazaoSocial: string;
  };
  valorDisponivelAdiantamento: number;
  valorUtilizadoAdiantamento: number;
  tipo: TipoContaEnum | null;
}

export interface IOnChangeItemAtualAutoCompleteContaTipoAdiantamentoEvent {
  itemAtual: IContaTipoAdiantamento | null;
  input: HTMLInputElement | null;
}

export interface IOnChangeTextoAutoCompleteContaTipoAdiantamentoEvent {
  input: HTMLInputElement | null;
}

interface IInputAutoCompleteContaTipoAdiantamentoProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string;
  nomeObjeto?: string;
  label?: string | JSX.Element;
  listaIdParaRemover?: string[];
  listaIdParcelaParaRemover?: string[];
  listaIdParcelasContaAtual?: string[];
  listaAdiantamentoVinculadoUtilizado?: Array<{
    idContaAdiantamento: string;
    valor: number;
  }>;
  listaTipoContaAdiantamento?: TipoContaEnum[];
  idPessoa?: string;
  onChangeItemAtual?: (
    event: IOnChangeItemAtualAutoCompleteContaTipoAdiantamentoEvent,
    props: IOnChange
  ) => void | Promise<void>;
  onChangeTexto?: (
    event: IOnChangeTextoAutoCompleteContaTipoAdiantamentoEvent
  ) => void | Promise<void>;
  onChangeItemAtualAposCarregarSemClear?: (
    event: IOnChangeItemAtualAutoCompleteContaTipoAdiantamentoEvent
  ) => void | Promise<void>;
}

export interface IInputAutoCompleteContaTipoAdiantamentoRef {
  autoCompleteRef: React.RefObject<IInputAutoCompleteBaseRef>;
}

const InputAutoCompleteContaTipoAdiantamento: React.ForwardRefRenderFunction<
  IInputAutoCompleteContaTipoAdiantamentoRef,
  IInputAutoCompleteContaTipoAdiantamentoProps
> = (
  {
    name,
    nomeObjeto,
    label,
    listaIdParaRemover = [],
    listaIdParcelaParaRemover = [],
    listaIdParcelasContaAtual = [],
    listaAdiantamentoVinculadoUtilizado = [],
    listaTipoContaAdiantamento = [],
    idPessoa,
    onChangeItemAtual,
    onChangeTexto,
    onChangeItemAtualAposCarregarSemClear,
    ...rest
  },
  ref
) => {
  const quantidadeAlterado = useRef(0);
  const [pesquisando, setPesquisando] = useState(false);

  const {
    autoCompleteRef,
    error: erroUnform,
    flags,
  } = UseRegisterAutoComplete<IContaTipoAdiantamento | null>(
    {
      nome: name,
    },
    {
      nomeObjeto,

      setSemExecutarEvento(_, value) {
        quantidadeAlterado.current++;

        autoCompleteRef.current?.selecionarItemSemEvento(value);
      },
    }
  );
  const [erro, setErro] = useState(erroUnform);

  useEffect(() => {
    setErro(erroUnform);
  }, [erroUnform]);

  const handleChangeItemAtual = useCallback(
    (
      { itemAtual, input }: IOnChangeItemAtualEvent,
      { valorAnterior }: IOnChange
    ) => {
      quantidadeAlterado.current++;

      if (onChangeItemAtual)
        onChangeItemAtual({ itemAtual, input }, { valorAnterior });

      if (flags.current.clearValue) {
        flags.current.clearValue = false;
        quantidadeAlterado.current = 1;
      } else if (
        quantidadeAlterado.current > 1 &&
        onChangeItemAtualAposCarregarSemClear
      ) {
        onChangeItemAtualAposCarregarSemClear({ itemAtual, input });
      }
    },
    [flags, onChangeItemAtual, onChangeItemAtualAposCarregarSemClear]
  );

  const handleChangeTexto = useCallback(
    ({ input }: IOnChangeTextoEvent) => {
      if (onChangeTexto) onChangeTexto({ input });
    },
    [onChangeTexto]
  );

  useImperativeHandle(ref, () => ({
    autoCompleteRef,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ width: '100%' }}>
        {label && <label>{label}</label>}
        <div
          style={{ display: 'flex' }}
          onKeyDown={(e) => {
            if (pesquisando && (e.key === 'Tab' || e.key === 'Enter')) {
              e.preventDefault();
            }
          }}
        >
          <AutoCompleteBase
            ref={(instance) => {
              if (instance) {
                autoCompleteRef.current = instance;
              }
            }}
            setPesquisando={(valor) => {
              setPesquisando(valor);
            }}
            error={erro}
            filtrarItens={async ({ valor, offset, limite }) => {
              setPesquisando(true);

              try {
                const response =
                  await ContaTipoAdiantamentoEnumAutoCompleteComunicador.index({
                    params: {
                      offset,
                      limite,
                      texto: valor,
                      listaIdParaRemover,
                      listaIdParcelaParaRemover,
                      listaIdParcelasContaAtual,
                      listaAdiantamentoVinculadoUtilizado:
                        listaAdiantamentoVinculadoUtilizado.map(
                          (item) => item.idContaAdiantamento
                        ),
                      listaTipoContaAdiantamento,
                      idPessoa,
                    },
                  });

                response.forEach((item) => {
                  item.valorDisponivelAdiantamento =
                    Number(item.valorDisponivelAdiantamento) -
                    Number(
                      listaAdiantamentoVinculadoUtilizado.find(
                        (vinculado) => vinculado.idContaAdiantamento === item.id
                      )?.valor ?? 0
                    ) +
                    Number(item.valorUtilizadoContaAtual);
                });

                setPesquisando(false);
                return response;
              } catch (err) {
                TratarErros(err);
                setPesquisando(false);
                return false;
              }
            }}
            obterChaveUnica={(item: IContaTipoAdiantamento) => {
              return item.id;
            }}
            obterLabel={(item: IContaTipoAdiantamento) => {
              if (!item.tipoAdiantamento) return '';

              let retorno = item.numeroDocumento;
              if (item.pessoa && item.pessoa.nomeRazaoSocial) {
                retorno += ` - ${item.pessoa.nomeRazaoSocial}`;
              }

              retorno += ` - ${item.tipoAdiantamento}`;

              return retorno;
            }}
            personalizarItem={({ selecionado, navegacao, item }) => {
              let identificacao = '';
              if (item.tipoAdiantamento) {
                identificacao = item.numeroDocumento;
                if (item.pessoa && item.pessoa.nomeRazaoSocial) {
                  identificacao += ` - ${item.pessoa.nomeRazaoSocial}`;
                }

                identificacao += ` - ${item.tipoAdiantamento}`;
              }

              return (
                <Item $selecionado={selecionado} $navegacao={navegacao}>
                  <strong>{identificacao}</strong>
                  <span>
                    {identificacao
                      ? ` ⬌ Saldo: ${Number(
                          item.valorDisponivelAdiantamento
                        ).FormatarParaPtBr()}`
                      : ''}
                  </span>
                  <br />
                  <span>{item.associacao}</span>
                </Item>
              );
            }}
            onChangeItemAtual={handleChangeItemAtual}
            onChangeTexto={handleChangeTexto}
            inputProps={rest}
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(InputAutoCompleteContaTipoAdiantamento);
