import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/comissoes/comissoes-pedido-venda/comissoes-pedido-venda-por-faturamento-com-cliente';

interface IComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicador {
  public async index(
    params: IComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ComissoesPedidoVendaPorFaturamentoComClienteRelatorioComunicador();
