import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { AppErro, ModeloImpressaoOrdemServicoEnum } from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { GiMoneyStack } from 'react-icons/gi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import { UseOrdemServico } from '../Hook/OrdemServicoHook';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import ImpressaoOrdemServicoRetratoComFotoProduto from './Impressao/Template/OrdemServicoRetratoComFotoProdutoImpressao';
import ImpressaoOrdemServicoRetratoSemFotoProduto from './Impressao/Template/OrdemServicoRetratoSemFotoProdutoImpressao';
import ImpressaoOrdemServicoPaisagemComFotoProduto from './Impressao/Template/OrdemServicoPaisagemComFotoProdutoImpressao';
import ImpressaoOrdemServicoPaisagemSemFotoProduto from './Impressao/Template/OrdemServicoPaisagemSemFotoProdutoImpressao';
import ImpressaoOrdemServicoRetratoComRepresentante from './Impressao/Template/OrdemServicoRetratoComRepresentanteImpressao';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import ImpressaoOrdemServicoRetratoComSemValoresItens from './Impressao/Template/OrdemServicoRetratoComSemValoresItensImpressao';
import ImpressaoOrdemServicoRetratoComFotoProdutoComSemValoresItens from './Impressao/Template/OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressao';
import ImpressaoOrdemServicoRetratoComProdutosServicosPagamentos from './Impressao/Template/OrdemServicoRetratoComProdutosServicosPagamentosImpressao';
import ToastAviso from '../../../../../../Util/Toasts/ToastAviso';
import { UseNotaFiscalSaidaImportacaoOrdemServico } from '../../../../../Comercial/Vendas/NotaFiscalSaidaImportacaoOrdemServicoHook';
import { UseOrdemServicoImportacaoOrcamento } from '../../../OrdemServicoImportacaoOrcamentoHook';
import ToastErro from '../../../../../../Util/Toasts/ToastErro';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import OrdemServicoRetratoSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoRetratoSemFotoProdutoImpressao/OrdemServicoRetratoSemFotoProdutoImpressaoComunicador';
import OrdemServicoPaisagemSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoPaisagemSemFotoProdutoImpressao/OrdemServicoPaisagemSemFotoProdutoImpressaoComunicador';
import OrdemServicoRetratoComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoRetratoComFotoProdutoImpressao/OrdemServicoRetratoComFotoProdutoImpressaoComunicador';
import OrdemServicoPaisagemComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoPaisagemComFotoProdutoImpressao/OrdemServicoPaisagemComFotoProdutoImpressaoComunicador';
import OrdemServicoRetratoComRepresentanteImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoRetratoComRepresentanteImpressao/OrdemServicoRetratoComRepresentanteImpressaoComunicador';
import OrdemServicoRetratoComSemValoresItensImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoRetratoComSemValoresItensImpressao/OrdemServicoRetratoComSemValoresItensImpressaoComunicador';
import OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicador from '../../../../../../Comunicador/Servicos/OrdensServicos/OrdemServico/Impressao/OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressao/OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicador';

interface IAcoesOrdemServico {
  permissao?: IPermissao;
}

const AcoesOrdemServico: React.FC<IAcoesOrdemServico> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();
  const parametros = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();

  const { listaRepresentante } = UseOrdemServico();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const [imprimirFinanceiro, setImprimirFinanceiro] = useState(false);
  const [enviarPorEmailFinanceiro, setEnviarPorEmailFinanceiro] =
    useState(false);
  const [gerarNotaFiscalSaidaFinanceiro, setGerarNotaFiscalSaidaFinanceiro] =
    useState(false);

  const {
    setTitulo: setTituloNotaFiscalSaidaImportacaoOrdemServico,
    abrirNotaFiscalSaidaImportacaoOrdemServico,
  } = UseNotaFiscalSaidaImportacaoOrdemServico();

  const {
    setTitulo: setTituloOrdemServicoImportacaoOrcamento,
    abrirOrdemServicoImportacaoOrcamento,
  } = UseOrdemServicoImportacaoOrcamento();

  const situacaoOrdemServico =
    formRef.current?.getFieldValueNomeObjeto('situacao');
  const idPessoa = formRef.current?.getFieldValue(
    'ordemServicoPessoa.idPessoa'
  );
  const numero = formRef.current?.getFieldValue('numero');
  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');
  const listaOrdemServicoItem = formRef.current?.getFieldValue(
    'listaOrdemServicoItem'
  );

  const handleValidar = useCallback(
    async (validarGeracaoNota?: boolean): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (parametros.RepresentanteObrigatorioOrdemServico) {
          if (listaRepresentante.length < 1) {
            throw new AppErro({
              mensagem: 'No mínimo um Representante é obrigatório!',
            });
          }
        }

        if (validarGeracaoNota) {
          if (situacaoOrdemServico && !situacaoOrdemServico?.permiteGerarNota) {
            formRef.current?.setFieldError(
              'ordemServico.situacao',
              `Não é permitido gerar Nota Fiscal com a Situação: ${situacaoOrdemServico.descricao}!`
            );
            ToastErro(
              `Não é permitido gerar Nota Fiscal com a Situação: ${situacaoOrdemServico.descricao}!`
            );
            return false;
          }
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [
      formRef,
      listaRepresentante.length,
      parametros.RepresentanteObrigatorioOrdemServico,
      situacaoOrdemServico,
    ]
  );

  const handleSalvar = useCallback(
    async (validarGeracaoNota?: boolean): Promise<boolean> => {
      try {
        if (!(await handleValidar(validarGeracaoNota))) {
          return false;
        }

        if (formRef.current?.validarSeAlterou()) {
          const data = formRef.current?.getData();
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar]
  );

  const handleVisualizarOuObterBlob = useCallback(
    async (visualizarPdf: boolean): Promise<Blob | undefined> => {
      const idRegistro = getIdDetalheRegistro() || '';

      if (listaOrdemServicoItem && listaOrdemServicoItem.length === 0) {
        ToastInfo('Informe pelo menos um item para realizar a impressão!');
      } else {
        switch (parametros.ModeloParaImpressaoOrdemServico) {
          case ModeloImpressaoOrdemServicoEnum.retratoSemFotoProduto:
            {
              const response =
                await OrdemServicoRetratoSemFotoProdutoImpressaoComunicador.show(
                  { id: idRegistro }
                );

              if (visualizarPdf) {
                await ImpressaoOrdemServicoRetratoSemFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoOrdemServicoRetratoSemFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoOrdemServicoEnum.paisagemSemFotoProduto: {
            const response =
              await OrdemServicoPaisagemSemFotoProdutoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoOrdemServicoPaisagemComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrdemServicoPaisagemSemFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrdemServicoEnum.retratoComFotoProduto:
            {
              const response =
                await OrdemServicoRetratoComFotoProdutoImpressaoComunicador.show(
                  {
                    id: idRegistro,
                  }
                );

              if (visualizarPdf) {
                await ImpressaoOrdemServicoRetratoComFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoOrdemServicoRetratoComFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoOrdemServicoEnum.paisagemComFotoProduto: {
            const response =
              await OrdemServicoPaisagemComFotoProdutoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoOrdemServicoPaisagemComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrdemServicoPaisagemComFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrdemServicoEnum.retratoComRepresentante:
            {
              const response =
                await OrdemServicoRetratoComRepresentanteImpressaoComunicador.show(
                  {
                    id: idRegistro,
                  }
                );

              if (visualizarPdf) {
                await ImpressaoOrdemServicoRetratoComRepresentante.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoOrdemServicoRetratoComRepresentante.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoOrdemServicoEnum.retratoComSemValoresItens: {
            const response =
              await OrdemServicoRetratoComSemValoresItensImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            const resposta = await abrirJanela({
              titulo: <h2>Impressão Ordens de Serviço</h2>,

              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Imprimir a Ordem de Serviço com Valores nos Itens?
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (visualizarPdf) {
              await ImpressaoOrdemServicoRetratoComSemValoresItens.handleVisualizarPdf(
                resposta,
                response
              );
            } else {
              const blob =
                await ImpressaoOrdemServicoRetratoComSemValoresItens.handleObterBlobPdf(
                  resposta,
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrdemServicoEnum.retratoComFotoProdutoComSemValoresItens: {
            const response =
              await OrdemServicoRetratoComFotoProdutoComSemValoresItensImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            const resposta = await abrirJanela({
              titulo: <h2>Impressão Ordens de Serviço</h2>,

              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Imprimir a Ordem de Serviço com Valores?
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (visualizarPdf) {
              await ImpressaoOrdemServicoRetratoComFotoProdutoComSemValoresItens.handleVisualizarPdf(
                resposta,
                response
              );
            } else {
              const blob =
                await ImpressaoOrdemServicoRetratoComFotoProdutoComSemValoresItens.handleObterBlobPdf(
                  resposta,
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoOrdemServicoEnum.retratoComProdutosServicosPagamentos: {
            const response =
              await OrdemServicoRetratoComFotoProdutoImpressaoComunicador.show({
                id: idRegistro,
              });
            if (visualizarPdf) {
              await ImpressaoOrdemServicoRetratoComProdutosServicosPagamentos.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoOrdemServicoRetratoComProdutosServicosPagamentos.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          default:
            ToastAviso(
              'Selecione um Modelo de Impressão para a Ordem de Serviço, nos Parâmetros!'
            );
        }
      }
      return undefined;
    },
    [abrirJanela, getIdDetalheRegistro, listaOrdemServicoItem, parametros]
  );

  const handleImprimirFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      await handleVisualizarOuObterBlob(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleVisualizarOuObterBlob, setLoading]);

  const handleEnviarPorEmailFinanceiro = useCallback(async () => {
    setShowEnviarEmail(true);
  }, []);

  const handleGerarNotaFiscalSaidaFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalSaidaImportacaoOrdemServico(
        'Gerar Nota Fiscal de Saída'
      );
      const response = await abrirNotaFiscalSaidaImportacaoOrdemServico({
        idPessoa: String(idPessoa),
        idOrdemServico: idRegistro,
      });

      if (response) {
        navigate({
          pathname: `/comercial/vendas/nota-fiscal-saida/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaImportacaoOrdemServico,
    getIdDetalheRegistro,
    idPessoa,
    navigate,
    setLoading,
    setTituloNotaFiscalSaidaImportacaoOrdemServico,
  ]);

  const handleImportarOrcamentos = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloOrdemServicoImportacaoOrcamento('Importar Orçamento');
      const response = await abrirOrdemServicoImportacaoOrcamento({
        idPessoa: String(idPessoa),
        idOrdemServico: idRegistro,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirOrdemServicoImportacaoOrcamento,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloOrdemServicoImportacaoOrcamento,
  ]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();

    await handleSubmit(data);

    setShowFinanceiro(false);

    if (imprimirFinanceiro) {
      await handleImprimirFinanceiro();
      setImprimirFinanceiro(false);
    }

    if (enviarPorEmailFinanceiro) {
      await handleEnviarPorEmailFinanceiro();
      setEnviarPorEmailFinanceiro(false);
    }

    if (gerarNotaFiscalSaidaFinanceiro) {
      await handleGerarNotaFiscalSaidaFinanceiro();
      setGerarNotaFiscalSaidaFinanceiro(false);
    }
  }, [
    enviarPorEmailFinanceiro,
    formRef,
    gerarNotaFiscalSaidaFinanceiro,
    handleEnviarPorEmailFinanceiro,
    handleGerarNotaFiscalSaidaFinanceiro,
    handleImprimirFinanceiro,
    handleSubmit,
    imprimirFinanceiro,
  ]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoOrdemServico))) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setImprimirFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleImprimirFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleImprimirFinanceiro,
    handleSalvar,
    setLoading,
    situacaoOrdemServico,
    valorFinanceiro,
  ]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    // #region Financeiro

    const financeiroConferido = formRef.current?.getFieldValue(
      'financeiroConferido'
    );

    if (!financeiroConferido && valorFinanceiro > 0) {
      await handleFinanceiro();
      setLoading(false);
      setEnviarPorEmailFinanceiro(true);
      return;
    }

    // #endregion Financeiro

    await handleEnviarPorEmailFinanceiro();
  }, [
    formRef,
    handleEnviarPorEmailFinanceiro,
    handleFinanceiro,
    handleSalvar,
    setLoading,
    valorFinanceiro,
  ]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: false,
            direcionarPedidoVenda: false,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: true,
          },
        });
      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: false,
          direcionarOrcamento: false,
          direcionarPedidoVenda: false,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: true,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      const anexo = await handleVisualizarOuObterBlob(false);

      await formRefEmail.current?.setDataInicial({
        assunto: `Ordem de Serviço número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo: [
          {
            nomeArquivo: `OrdemServiço${numero}.pdf`,
            file: anexo,
            permitirExcluir: false,
          },
        ],
      });
    },
    [handleVisualizarOuObterBlob, idPessoa, numero]
  );

  // #endregion Email

  const handleGerarNotaFiscalSaida = useCallback(async () => {
    try {
      if (!(await handleSalvar(true))) {
        return;
      }
      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setGerarNotaFiscalSaidaFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleGerarNotaFiscalSaidaFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleGerarNotaFiscalSaidaFinanceiro,
    handleSalvar,
    setLoading,
    valorFinanceiro,
  ]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleEnviarPorEmail}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={!permissao?.altera || loading}
          onClick={handleImportarOrcamentos}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Importar Orçamentos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            !situacaoOrdemServico?.permiteGerarNota
          }
          onClick={handleGerarNotaFiscalSaida}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Nota Fiscal de Saída
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Ordens de Serviço por E-mail"
          mensagemPadrao={`Segue em anexo a Ordem de Serviço Número: ${numero}`}
          direcionarOrdemServico
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}
    </>
  );
};

export default AcoesOrdemServico;
