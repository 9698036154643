import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { toast } from 'react-toastify';
import { IJuncaoProdutosValoresAlterar } from '@elogestor/util';
import { UseRedirecionar } from '../../../../../Hooks/RedirecionarContext';
import IPadraoProps from '../../../../../Comum/Interface/IPadraoProps';
import {
  FormProvider,
  IHandleCarregarDadosParametros,
  ISubmitProps,
} from '../../../../../Componentes/Detalhe/Hooks/FormContext';

import TratarErros from '../../../../../Util/Erro/TratarErros';
import GetValidationErrors from '../../../../../Util/Erro/GetValidationErrors';
import ToastSucesso from '../../../../../Util/Toasts/ToastSucesso';
import { UseConfirmacao } from '../../../../../Componentes/Confirmacao/HooksConfirmacao';
import JuncaoProdutosComunicador from '../../../../../Comunicador/Suprimentos/Produtos/JuncaoProdutos/Comunicador/JuncaoProdutosComunicador';

const FormHook: React.FC<IPadraoProps> = ({ children }) => {
  const { abrirJanela } = UseConfirmacao();

  const { redirecionar } = UseRedirecionar();

  const [terminouCarregarDados, setTerminouCarregarDados] = useState(false);

  const inputRefFocus = useRef<HTMLInputElement>(null);

  const handleSetarFocus = useCallback(async (): Promise<void> => {
    if (inputRefFocus.current) {
      inputRefFocus.current.focus();
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<IFormCiaHandles>(null);

  const idDetalheRegistro = useRef<string | null>(null);

  const [, setRefresh] = useState(0);

  const refresh = useCallback(() => {
    setRefresh(Math.random());
  }, []);

  const getIdDetalheRegistro = useCallback(() => {
    return idDetalheRegistro.current;
  }, []);

  const setIdDetalheRegistro = useCallback((valor: string | null) => {
    idDetalheRegistro.current = valor;
  }, []);

  const handleCarregarDados = useCallback(
    async (
      {
        dadosRecuperados,

        dadosObrigatorios,
      } = {} as IHandleCarregarDadosParametros
    ) => {
      async function SetarDadosObrigatorios(): Promise<void> {
        if (dadosObrigatorios) {
          const chaves = Object.keys(dadosObrigatorios) as Array<
            keyof IJuncaoProdutosValoresAlterar
          >;

          chaves.forEach((key) => {
            const inputRef = formRef.current?.getFieldRef(key);

            if (inputRef) {
              inputRef.disabled = true;
            }
          });
        }
      }

      async function SetarDadosRecuperados(): Promise<void> {
        SetarDadosObrigatorios();

        await formRef.current?.setDataRecuperarFormulario(dadosRecuperados);
      }

      async function SetarDadosBackend(): Promise<void> {
        // const response = await JuncaoProdutosComunicador.show();

        await formRef.current?.setDataInicial({
          idProdutoBase: undefined,
          idProdutoJuntarExcluir: undefined,
        });

        /* setIdDetalheRegistro(response.id); */

        SetarDadosObrigatorios();
      }

      async function SelecionarDadosIniciais(): Promise<void> {
        try {
          setLoading(true);

          if (dadosRecuperados) {
            SetarDadosRecuperados();
          } else {
            await SetarDadosBackend();
          }

          setTerminouCarregarDados(true);

          handleSetarFocus();

          refresh();

          setLoading(false);
        } catch (error) {
          TratarErros(error, { redirecionar });

          setLoading(false);
        }
      }

      await SelecionarDadosIniciais();
    },

    [handleSetarFocus, redirecionar, refresh]
  );

  const handleValidar = useCallback(async (data: any): Promise<boolean> => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        idProdutoBase: Yup.string().required(
          'Obrigatório informar o produto base!'
        ),

        idProdutoJuntarExcluir: Yup.string().required(
          'Obrigatório informar o produto a juntar/excluir!'
        ),
      });

      await schema.validate(data, { abortEarly: false });

      return true;
    } catch (error) {
      const errors = GetValidationErrors(error);

      formRef.current?.setErrors(errors);

      return false;
    }
  }, []);

  const handleSubmit = useCallback(async (): Promise<ISubmitProps> => {
    const data = formRef.current?.getData();

    const produtoBase = formRef.current?.getFieldValueNomeObjeto('produtoBase');

    const produtoJuntarExcluir = formRef.current?.getFieldValueNomeObjeto(
      'produtoJuntarExcluir'
    );

    try {
      setLoading(true);

      if (await handleValidar(data)) {
        const resposta = await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: (
            <span style={{ fontSize: 20 }}>
              Deseja alterar a movimentação do produto:{' '}
              {produtoJuntarExcluir?.descricao} para o produto:{' '}
              {produtoBase?.descricao} e excluir o produto a ser excluído sendo
              irreversível este processo?
            </span>
          ),
          padraoSelecionadoSim: true,
          confimar: 'Sim',
          cancelar: 'Não',
        });

        if (resposta) {
          await JuncaoProdutosComunicador.update({
            params: {
              idProdutoBase: formRef.current?.getFieldValue('idProdutoBase'),
              idProdutoJuntarExcluir: formRef.current?.getFieldValue(
                'idProdutoJuntarExcluir'
              ),
            },
          });

          ToastSucesso('Registro Salvo!');

          formRef.current?.clearField('produtoJuntarExcluir');
          refresh();
        }
      }

      setLoading(false);

      return { id: '', erro: false };
    } catch (error) {
      TratarErros(error, { redirecionar });

      setLoading(false);

      return { id: '', erro: true };
    }
  }, [abrirJanela, handleValidar, redirecionar, refresh]);

  return (
    <FormProvider
      value={{
        terminouCarregarDados,
        inputRefFocus,
        handleSetarFocus,
        formRef,
        getIdDetalheRegistro,
        setIdDetalheRegistro,
        loading,
        setLoading,
        handleValidar,
        handleSubmit,
        handleCarregarDados,
        refresh,
      }}
    >
      {children}
    </FormProvider>
  );
};

export default FormHook;
