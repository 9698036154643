import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { AiOutlineCheck } from 'react-icons/ai/index.mjs';
import { TipoProducaoEnum } from '@elogestor/util';
import BtnCloseButton from '../../../../../../../Componentes/Buttons/BtnCloseButton';
import { UseForm } from '../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import TextoLoading from '../../../../../../../Componentes/TextoLoading';
import GerarOrdemProducaoItem from './GerarOrdemProducaoItem';
import { Tabela } from '../../../../../../../Componentes/Styles/Tabela';
import ToastErro from '../../../../../../../Util/Toasts/ToastErro';
import ToastSucesso from '../../../../../../../Util/Toasts/ToastSucesso';
import { UseParametros } from '../../../../../../../Hooks/ParametrosHook';
import ProdutoPossuiEstruturaComunicador from '../../../../../../../Comunicador/Suprimentos/Produtos/Produto/Comunicador/ProdutoPossuiEstruturaComunicador';
import GerarOrdemProducaoItemAutomaticaComunicador from '../../../../../../../Comunicador/Manufatura/Producao/OrdemProducao/Comunicador/GerarOrdemProducaoItemAutomaticaComunicador';

interface IProps {
  show: boolean;
  onClose: () => void;
}

export interface IOrdemProducaoItem {
  id: string;
  selecionado: boolean;
  idProduto: string;
  codigo: string;
  descricao: string;
  quantidade: number;
  idPessoa?: string;
  tipoProducao: TipoProducaoEnum;
}

const GerarOrdemProducaoItens: React.FC<IProps> = ({ show, onClose }) => {
  const { formRef, loading, setLoading, handleCarregarDados } = UseForm();
  const parametros = UseParametros();

  const [marcarTodos, setMarcarTodos] = useState<boolean>(false);

  const [listaOrdemProducaoItem, setListaOrdemProducaoItem] = useState<
    IOrdemProducaoItem[]
  >([]);

  const obterDadosIniciais = useCallback(async (): Promise<void> => {
    const listaItem = formRef?.current?.getFieldValue('listaOrdemProducaoItem');
    const idPessoa = formRef?.current?.getFieldValue('idPessoa');
    const tipoProducao = formRef?.current?.getFieldValue('tipoProducao');

    for (let i = 0; i < listaItem.length; i++) {
      const item = listaItem[i];

      const response = await ProdutoPossuiEstruturaComunicador.show({
        id: item.idProduto,
      });

      if (response) {
        setListaOrdemProducaoItem((listaAnterior) => [
          ...listaAnterior,
          {
            id: item.id,
            selecionado: true,
            idProduto: item.idProduto,
            codigo: item.produto.codigo,
            descricao: item.produto.descricao,
            quantidade: item.quantidadeDemandaTotal,
            idPessoa,
            tipoProducao,
          },
        ]);
      }
    }
  }, [formRef]);

  useEffect(() => {
    obterDadosIniciais();
  }, [obterDadosIniciais]);

  const handleOnChange = (item: IOrdemProducaoItem): void => {
    setListaOrdemProducaoItem((prevList) => {
      const index = prevList.findIndex((_item) => _item.id === item.id);
      if (index !== -1) {
        const updatedList = [...prevList];
        updatedList[index] = item;
        return updatedList;
      }
      return [...prevList, item];
    });
  };

  const handleValidarSalvar = async (): Promise<void> => {
    setLoading(true);

    const listaOrdemProducaoItemSelecionado = listaOrdemProducaoItem.filter(
      (item) => item.selecionado
    );

    if (
      !listaOrdemProducaoItemSelecionado ||
      listaOrdemProducaoItemSelecionado.length === 0
    ) {
      ToastErro('Selecione ao menos um item para prosseguir!');
      setLoading(false);
      return;
    }

    const listaItemValido = listaOrdemProducaoItemSelecionado.filter(
      (item) => item.quantidade > 0
    );

    if (!listaItemValido || listaItemValido.length === 0) {
      ToastErro(
        'Selecione ao menos um item com quantidade maior que zero para prosseguir!'
      );
      setLoading(false);
      return;
    }

    const dataHoraLancamento =
      formRef.current.getFieldValue('dataHoraLancamento');
    const dataHoraInicio = formRef.current.getFieldValue('dataHoraInicio');
    const dataHoraTermino = formRef.current.getFieldValue('dataHoraTermino');

    const dataHoraInicioFormatada = dataHoraInicio
      ? new Date(dataHoraInicio).AddMinutes(-1)
      : new Date(dataHoraLancamento).AddMinutes(-1);

    const dataHoraTerminoFormatada = dataHoraTermino
      ? new Date(dataHoraTermino).AddMinutes(-1)
      : dataHoraInicioFormatada;

    try {
      const response = await GerarOrdemProducaoItemAutomaticaComunicador.store({
        params: listaItemValido,
        gerarFinalizado: parametros.GerarOrdemProducaoFinalizada,
        dataHoraInicio: dataHoraInicioFormatada,
        dataHoraTermino: dataHoraTerminoFormatada,
      });

      if (response.listaOrdemProducaoGerada) {
        for (let i = 0; i < response.listaOrdemProducaoGerada.length; i++) {
          const ordemProducaoGerada = response.listaOrdemProducaoGerada[i];

          ToastSucesso(
            `Ordem de produção número ${ordemProducaoGerada.numeroOrdemProducao} gerada com sucesso.`
          );

          window.open(
            `/manufatura/producao/ordem-producao/detalhe/${ordemProducaoGerada.idOrdemProducao}`
          );
        }
      }

      handleCarregarDados();
      onClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const listaMensagem = error?.response.data.listaMensagem;

      if (listaMensagem) {
        listaMensagem.forEach((item: string) => {
          ToastErro(item);
        });
      }

      setLoading(false);
    }
  };

  return (
    <Modal show={show} size="xl">
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Gerar Ordem de Produção</Modal.Title>
        <BtnCloseButton type="button" onClick={onClose}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>
      <Modal.Body>
        <Tabela style={{ overflow: 'auto', maxHeight: 400 }}>
          <Table striped bordered variant="light">
            <thead>
              <tr>
                <th
                  className="lista-texto"
                  style={{
                    width: '5%',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                  }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={marcarTodos}
                    onChange={(event) => {
                      setMarcarTodos(event.target.checked);
                    }}
                  />
                </th>
                <th className="lista-texto" style={{ width: '20%' }}>
                  <span>Código</span>
                </th>
                <th className="lista-texto" style={{ width: '45%' }}>
                  <span>Descrição</span>
                </th>
                <th className="lista-valor" style={{ width: '20%' }}>
                  <span>Quantidade</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {listaOrdemProducaoItem.map((item: any, index: any) => {
                return (
                  <GerarOrdemProducaoItem
                    key={index}
                    item={item}
                    onChange={handleOnChange}
                    index={index}
                    marcarTodos={marcarTodos}
                  />
                );
              })}
            </tbody>
          </Table>
        </Tabela>
      </Modal.Body>
      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onClose}
              disabled={loading}
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 16,
                }}
              >
                <TextoLoading loading={loading}>
                  <MdCancel />
                  <span style={{ marginLeft: 10 }}>Cancelar</span>
                </TextoLoading>
              </span>
            </button>

            <Button
              style={{
                fontWeight: 'bold',
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleValidarSalvar}
              disabled={
                loading ||
                !listaOrdemProducaoItem.some(
                  (item) => item.selecionado === true
                )
              }
            >
              <TextoLoading loading={loading}>
                <AiOutlineCheck />
                <span style={{ marginLeft: 10 }}>OK</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GerarOrdemProducaoItens;
