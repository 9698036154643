/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Divisor from '../../../../../Componentes/Divisor';
import IListaDetalheUsuarioAcesso from './ListaDetalheUsuarioAcesso/Lista';
import UsuarioAcessoHook from './ListaDetalheUsuarioAcesso/Hooks';

const TributacaoTab: React.FC = () => {
  return (
    <div>
      <Divisor>
        <Row>
          <Col lg={12} md={12}>
            <UsuarioAcessoHook>
              <IListaDetalheUsuarioAcesso name="listaUsuarioAcesso" />
            </UsuarioAcessoHook>
          </Col>
        </Row>
      </Divisor>
    </div>
  );
};

export default TributacaoTab;
