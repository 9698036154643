import api from '../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/tabela-preco/produtos-com-valor-venda-custo-markup';

interface ITabelaPrecoProdutoComValorVendaCustoMarkupComunicadorIndex {
  params: {
    filtro: any;
  };
}

class TabelaPrecoProdutoComValorVendaCustoMarkupRelatorioComunicador {
  public async index(
    params: ITabelaPrecoProdutoComValorVendaCustoMarkupComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new TabelaPrecoProdutoComValorVendaCustoMarkupRelatorioComunicador();
