import React, { createContext, useCallback, useContext } from 'react';
import {
  ConfiguracaoTributacaoTipoLancamentoEnum,
  IcmsOperacoesInterestaduaisCalculo,
  ImpostoCalculoPorCst,
  IOrdemServicoItemComercialRepresentanteLista,
  ModalidadeBaseCalculoIcmsEnum,
  ObterTipoMercado,
  RateioItemComCasaDecimalCalculo,
  TipoMovimentoEntradaSaidaEnum,
  ValorTotalDocumentoCalculo,
} from '@elogestor/util';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import { UseListaItem } from '../../../Hook/ListaItemContext';
import { UseItemIcmsTab } from './ItemIcmsTabHook';
import { UseItemIpiTab } from './ItemIpiTabHook';
import { UseItemPisTab } from './ItemPisTabHook';
import { UseItemCofinsTab } from './ItemCofinsTabHook';
import { UseItemIcmsOperacaoInterestadualTab } from './ItemIcmsOperacaoInterestadualTabHook';
import ConfiguracaoTributariaComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/ConfiguracaoTributaria/Comunicador/ConfiguracaoTributariaComunicador';
import ConfiguracaoTributariaObterConformeProdutoComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/ConfiguracaoTributaria/Comunicador/ConfiguracaoTributariaObterConformeProdutoComunicador';
import RegraEscolhaAliquotaObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaAliquota/Comunicador/RegraEscolhaAliquotaObterConformeCriteriosComunicador';
import RegraCalculoComissaoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Comercial/Comissao/Comunicador/RegraCalculoComissaoObterConformeCriteriosComunicador';
import RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Comercial/TabelaPreco/RegraEscolhaTabelaPreco/Comunicador/RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador';
import RegraEscolhaParametroObterConformeCriteriosComunicador from '../../../../../../../../Comunicador/Fiscal/Tributacao/RegraEscolhaParametro/Comunicador/RegraEscolhaParametroObterConformeCriteriosComunicador';
import IPadraoProps from '../../../../../../../../Comum/Interface/IPadraoProps';
import { UseItemEstoque } from './ItemEstoqueHook';
import { UseItemOrdemServico } from './ItemOrdemServicoHook';
import { UseAuth } from '../../../../../../../../Hooks/Auth';
import { Sleep } from '../../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';

interface IItemTabContext {
  validador: boolean;
  idDetalheRegistro: string;
  setItemOrdem(): Promise<void>;
  atualizarCampos(): Promise<void>;
  calculaValorTotalProduto(): Promise<void>;
  calcularRateioItem(): Promise<void>;
  calcularIcmsOperacaoInterestadual(): Promise<void>;
  calcularImpostosPorCst(): Promise<void>;
  calcularValorTotalBruto(): Promise<void>;
  obterConfiguracaoTributariaConformeProduto(): Promise<void>;
  obterConfiguracaoTributaria(): Promise<void>;
  calcularQuantidadeTributadoProdutoTributado(): Promise<void>;
  calcularQuantidadeUnitariaTributadoProdutoTributado(): Promise<void>;
  calcularValorUnitarioTributadoProdutoTributado(): Promise<void>;
  obterRegraEscolhaAliquota(): Promise<void>;
  obterRegraEscolhaAliquotaOperacaoInterestadual(): Promise<void>;
  obterDadosItemComercialRepresentante(): Promise<void>;
  limparDadosItemComercialRepresentante(): Promise<void>;
  calcularBaseCalculoItemComercialRepresentante(): Promise<void>;
  obterRegraEscolhaTabelaPreco(): Promise<void>;
  obterRegraEscolhaParametros(): void;
  calcularItemEngenhariaPesagem(): Promise<void>;
  calcularItemEngenhariaMetragem(): Promise<void>;
  limparConfiguracaoTributaria(): Promise<void>;
}

const ItemTabContext = createContext<IItemTabContext>({} as IItemTabContext);

const ItemTabHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRefDetalhe, getIdDetalheRegistro, setLoading } =
    UseListaDetalheForm();

  const { empresa } = UseAuth();

  const {
    formRef: formPrincipal,
    getIdDetalheRegistro: getIdDetalheRegistroFormPrincipal,
  } = UseForm();
  const { obterVisibilidadeBloqueioCamposPorIcmsCst } = UseItemIcmsTab();
  const { obterVisibilidadeBloqueioCamposPorIpiCst } = UseItemIpiTab();
  const { obterVisibilidadeBloqueioCamposPorPisCst } = UseItemPisTab();
  const { obterVisibilidadeBloqueioCamposPorCofinsCst } = UseItemCofinsTab();
  const { obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual } =
    UseItemIcmsOperacaoInterestadualTab();
  const { selecionarItemEstoqueLocalEstoque } = UseItemEstoque();
  const { setListaLocalEstoque } = UseItemOrdemServico();

  const { listaItem } = UseListaItem();

  const idDetalheRegistro = getIdDetalheRegistro();

  const handleSetItemOrdem = useCallback(async () => {
    const ordem = listaItem.length + 1;
    formRefDetalhe.current?.setFieldValorInicial('ordem', ordem);
  }, [formRefDetalhe, listaItem.length]);

  const handleCalcularValorTotalBruto = useCallback(async () => {
    const deduzirDesoneracaoIcms = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.deduzirDesoneracaoIcms'
    );
    const valorDesoneracaoIcms = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.valorDesoneracaoIcms'
    );

    const resultado = ValorTotalDocumentoCalculo({
      valorSubtotalItem:
        formRefDetalhe.current?.getFieldValue('valorTotalProduto'),
      valorDescontoItem: formRefDetalhe.current?.getFieldValue('valorDesconto'),
      valorFreteItem: formRefDetalhe.current?.getFieldValue('valorFrete'),
      valorSeguroItem: formRefDetalhe.current?.getFieldValue('valorSeguro'),
      valorOutrasDespesasItem: formRefDetalhe.current?.getFieldValue(
        'valorOutrasDespesas'
      ),
      valorIcmsSt: formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemImpostoIcms.valorIcmsSt'
      ),
      valorFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemImpostoIcms.valorFcpIcmsSt'
      ),
      valorIpi: formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemImpostoIpi.valor'
      ),
      valorImpostoImportacao: null,
      valorServicos: null,

      valorDesoneracaoIcmsDeduzir: deduzirDesoneracaoIcms
        ? Number(valorDesoneracaoIcms)
        : null,
    });

    formRefDetalhe.current?.setFieldValue('valorTotalBruto', resultado);
  }, [formRefDetalhe]);

  const handleAtualizarCampos = useCallback(async () => {
    obterVisibilidadeBloqueioCamposPorIcmsCst();
    obterVisibilidadeBloqueioCamposPorIpiCst();
    obterVisibilidadeBloqueioCamposPorPisCst();
    obterVisibilidadeBloqueioCamposPorCofinsCst();
    obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual();
  }, [
    obterVisibilidadeBloqueioCamposPorCofinsCst,
    obterVisibilidadeBloqueioCamposPorIcmsCst,
    obterVisibilidadeBloqueioCamposPorIcmsOperacaoInterestadual,
    obterVisibilidadeBloqueioCamposPorIpiCst,
    obterVisibilidadeBloqueioCamposPorPisCst,
  ]);

  const handleObterRegraEscolhaTabelaPreco = useCallback(async () => {
    await Sleep(1);

    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
      'ordemServicoPessoa.pessoa'
    );
    const destinado = formRefDetalhe.current?.getFieldValue('destinado');

    const listaRepresentante = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemComercial.listaOrdemServicoItemComercialRepresentante'
    );
    const utilizarValorTabelaPreco = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemComercial.utilizarValorTabelaPreco'
    );

    const listaIdPessoaRepresentante = listaRepresentante.map(
      (representante: IOrdemServicoItemComercialRepresentanteLista) => {
        return representante.pessoaRepresentante.id;
      }
    );

    if (!dataHoraEmissao || !produto || !pessoa) {
      return;
    }

    try {
      setLoading(true);

      const response =
        await RegraEscolhaTabelaPrecoObterConformeCriteriosComunicador.show({
          params: {
            dataPesquisaVigencia: dataHoraEmissao,
            idProduto: produto.id,
            idPessoa: pessoa.id,
            listaIdPessoaRepresentante,
            destinado,
          },
        });

      if (response && response.regraEscolhaTabelaPreco) {
        if (utilizarValorTabelaPreco) {
          formRefDetalhe.current?.setFieldValue(
            'valorUnitario',
            response.regraEscolhaTabelaPreco.produtoRegraEscolhaTabelaPreco
              .valorUnitarioVenda
          );
        }

        await formRefDetalhe.current?.setSemExecutarEvento({
          ordemServicoItemComercial: {
            regraEscolhaTabelaPreco: response.regraEscolhaTabelaPreco,
            valorUnitarioTabelaPreco:
              response.regraEscolhaTabelaPreco.produtoRegraEscolhaTabelaPreco
                .valorUnitarioVenda,
          },
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [formPrincipal, formRefDetalhe, setLoading]);

  const handleCalcularIcmsOperacaoInterestadual = useCallback(async () => {
    try {
      const resultado = IcmsOperacoesInterestaduaisCalculo({
        valorFreteItem: formRefDetalhe.current?.getFieldValue('valorFrete'),
        valorSeguroItem: formRefDetalhe.current?.getFieldValue('valorSeguro'),
        valorOutrasDespesasItem: formRefDetalhe.current?.getFieldValue(
          'valorOutrasDespesas'
        ),
        valorDescontoItem:
          formRefDetalhe.current?.getFieldValue('valorDesconto'),
        quantidadeItem: formRefDetalhe.current?.getFieldValue('quantidade'),
        valorUnitarioItem:
          formRefDetalhe.current?.getFieldValue('valorUnitario'),

        utiliza: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.utiliza'
        ),
        calcular: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.calcular'
        ),
        aliquotaEstadoDestino: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino'
        ),
        aliquotaInterestadual: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual'
        ),
        aliquotaOrigemDestino: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino'
        ),
        estadoOrigemBaseCalculo: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo'
        ),
        estadoOrigemPercentualPartilha: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha'
        ),
        estadoOrigemValorIcms: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms'
        ),
        estadoDestinoBaseCalculo: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo'
        ),
        estadoDestinoPercentualPartilha: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha'
        ),
        estadoDestinoValorIcmsSemFcp: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp'
        ),
        estadoDestinoPercentualFcp: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp'
        ),
        estadoDestinoValorFcp: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp'
        ),
        estadoDestinoValorIcmsComFcp: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp'
        ),
        valorIpiItem: formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIpi.valor'
        ),
      });

      await formRefDetalhe.current?.setSemExecutarEvento({
        ordemServicoItemImpostoIcmsOperacaoInterestadual: {
          calcular: resultado.calcular,
          aliquotaEstadoDestino: resultado.aliquotaEstadoDestino,
          aliquotaInterestadual: resultado.aliquotaInterestadual,
          aliquotaOrigemDestino: resultado.aliquotaOrigemDestino,
          estadoOrigemBaseCalculo: resultado.estadoOrigemBaseCalculo,
          estadoOrigemPercentualPartilha:
            resultado.estadoOrigemPercentualPartilha,
          estadoOrigemValorIcms: resultado.estadoOrigemValorIcms,
          estadoDestinoBaseCalculo: resultado.estadoDestinoBaseCalculo,
          estadoDestinoPercentualPartilha:
            resultado.estadoDestinoPercentualPartilha,
          estadoDestinoValorIcmsSemFcp: resultado.estadoDestinoValorIcmsSemFcp,
          estadoDestinoPercentualFcp: resultado.estadoDestinoPercentualFcp,
          estadoDestinoValorFcp: resultado.estadoDestinoValorFcp,
          estadoDestinoValorIcmsComFcp: resultado.estadoDestinoValorIcmsComFcp,
        },

        aliquotaOrigem: resultado.aliquotaOrigemDestino,
        aliquotaDestino: resultado.aliquotaOrigemDestino,
      });

      handleAtualizarCampos();
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe, handleAtualizarCampos]);

  const handleCalcularImpostosPorCst = useCallback(async () => {
    try {
      const resultado = ImpostoCalculoPorCst({
        valorDescontoItem:
          formRefDetalhe.current?.getFieldValue('valorDesconto'),
        valorFreteItem: formRefDetalhe.current?.getFieldValue('valorFrete'),
        valorSeguroItem: formRefDetalhe.current?.getFieldValue('valorSeguro'),
        valorOutrasDespesasItem: formRefDetalhe.current?.getFieldValue(
          'valorOutrasDespesas'
        ),
        quantidadeItem: formRefDetalhe.current?.getFieldValue('quantidade'),
        valorUnitarioItem:
          formRefDetalhe.current?.getFieldValue('valorUnitario'),

        icms: {
          valorIpiItem: null,
          destinadoItem: formRefDetalhe.current?.getFieldValue('destinado'),

          cstCsosn:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'ordemServicoItemImpostoIcms.situacaoTributaria'
            )?.codigo || '',
          tipoMercado:
            formRefDetalhe.current?.getFieldValueNomeObjeto('operacaoFiscal')
              ?.tipoMercado || '',

          // #region ICMS

          calcularIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularIcms'
          ),
          modalidadeBaseCalculoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.modalidadeBaseCalculoIcms'
          ),
          aliquotaInterestadualIcms: null,

          percentualReducaoBaseCalculoIcms:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.percentualReducaoBaseCalculoIcms'
            ),
          baseCalculoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoIcms'
          ),
          aliquotaIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaIcms'
          ),
          valorIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorIcms'
          ),

          baseCalculoFcpIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoFcpIcms'
          ),
          aliquotaFcpIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaFcpIcms'
          ),
          valorFcpIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorFcpIcms'
          ),

          percentualDiferimentoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.percentualDiferimentoIcms'
          ),
          valorSemDiferimentoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorSemDiferimentoIcms'
          ),
          valorDiferidoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorDiferidoIcms'
          ),

          valorUnitarioPautaIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorUnitarioPautaIcms'
          ),
          valorTotalPautaIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorTotalPautaIcms'
          ),

          calcularIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularIcmsEfetivo'
          ),
          percentualReducaoBaseCalculoIcmsEfetivo:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.percentualReducaoBaseCalculoIcmsEfetivo'
            ),
          baseCalculoIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoIcmsEfetivo'
          ),
          aliquotaIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaIcmsEfetivo'
          ),
          valorIcmsEfetivo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorIcmsEfetivo'
          ),

          calcularIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularIcmsSubstituto'
          ),
          valorUnitarioIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorUnitarioIcmsSubstituto'
          ),
          valorIcmsSubstituto: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorIcmsSubstituto'
          ),

          motivoDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.motivoDesoneracaoIcms'
          ),
          calcularDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularDesoneracaoIcms'
          ),
          baseCalculoDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoDesoneracaoIcms'
          ),
          aliquotaDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaDesoneracaoIcms'
          ),
          valorDesoneracaoIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorDesoneracaoIcms'
          ),

          baseCalculoSemReducaoIcms: null,
          valorSemReducaoIcms: null,
          baseCalculoReducaoIcms: null,
          valorReducaoIcms: null,

          // #endregion ICMS

          // #region ICMS ST

          calcularIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularIcmsSt'
          ),
          modalidadeBaseCalculoIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.modalidadeBaseCalculoIcmsSt'
          ),

          percentualMvaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.percentualMvaIcmsSt'
          ),
          percentualReducaoMvaIcmsSt: null,
          percentualReducaoBaseCalculoIcmsSt:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.percentualReducaoBaseCalculoIcmsSt'
            ),
          aliquotaInterestadualIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaInterestadualIcmsSt'
          ),
          aliquotaInternaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaInternaIcmsSt'
          ),
          baseCalculoIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoIcmsSt'
          ),
          valorIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorIcmsSt'
          ),

          baseCalculoFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoFcpIcmsSt'
          ),
          aliquotaFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaFcpIcmsSt'
          ),
          valorFcpIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorFcpIcmsSt'
          ),

          calcularIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularIcmsStRetido'
          ),
          baseCalculoIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoIcmsStRetido'
          ),
          baseCalculoUnitariaProdutoIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.baseCalculoUnitariaProdutoIcmsStRetido'
            ),
          aliquotaIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaIcmsStRetido'
          ),
          valorIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorIcmsStRetido'
          ),
          valorUnitarioProdutoIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.valorUnitarioProdutoIcmsStRetido'
            ),

          baseCalculoFcpIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.baseCalculoFcpIcmsStRetido'
          ),
          baseCalculoUnitariaProdutoFcpIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.baseCalculoUnitariaProdutoFcpIcmsStRetido'
            ),
          aliquotaFcpIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.aliquotaFcpIcmsStRetido'
          ),
          valorFcpIcmsStRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorFcpIcmsStRetido'
          ),
          valorUnitarioProdutoFcpIcmsStRetido:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcms.valorUnitarioProdutoFcpIcmsStRetido'
            ),

          valorUnitarioPautaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorUnitarioPautaIcmsSt'
          ),
          valorTotalPautaIcmsSt: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorTotalPautaIcmsSt'
          ),

          // #endregion ICMS ST

          // #region ICMS SN

          calcularCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.calcularCreditoIcmsSn'
          ),
          percentualCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.percentualCreditoIcmsSn'
          ),
          valorCreditoIcmsSn: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcms.valorCreditoIcmsSn'
          ),

          // #endregion ICMS SN
        },

        icmsOperacaoInterestadual: {
          utiliza: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.utiliza'
          ),
          calcular: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.calcular'
          ),
          aliquotaEstadoDestino: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino'
          ),
          aliquotaInterestadual: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual'
          ),
          aliquotaOrigemDestino: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino'
          ),
          estadoOrigemBaseCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo'
          ),
          estadoOrigemPercentualPartilha: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha'
          ),
          estadoOrigemValorIcms: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms'
          ),
          estadoDestinoBaseCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo'
          ),
          estadoDestinoPercentualPartilha:
            formRefDetalhe.current?.getFieldValue(
              'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha'
            ),
          estadoDestinoValorIcmsSemFcp: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp'
          ),
          estadoDestinoPercentualFcp: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp'
          ),
          estadoDestinoValorFcp: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp'
          ),
          estadoDestinoValorIcmsComFcp: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp'
          ),
        },

        ipi: {
          calcular: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.calcular'
          ),
          tipoCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.tipoCalculo'
          ),
          cst:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'ordemServicoItemImpostoIpi.situacaoTributaria'
            )?.codigo || '',
          quantidadeUnidadeIpi: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.quantidadeUnidade'
          ),
          valorUnidadeIpi: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.valorUnidade'
          ),
          baseCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.baseCalculo'
          ),
          aliquota: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.aliquota'
          ),
          valor: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoIpi.valor'
          ),
        },

        pis: {
          calcular: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.calcular'
          ),
          tipoCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.tipoCalculo'
          ),
          cst:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'ordemServicoItemImpostoPis.situacaoTributaria'
            )?.codigo || '',
          quantidadeUnidadePis: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.quantidadeUnidade'
          ),
          valorUnidadePis: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.valorUnidade'
          ),
          baseCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.baseCalculo'
          ),
          aliquota: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.aliquota'
          ),
          valor: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.valor'
          ),

          calcularRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.calcularRetido'
          ),
          baseCalculoRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.baseCalculoRetido'
          ),
          aliquotaRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.aliquotaRetido'
          ),
          valorRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoPis.valorRetido'
          ),
        },

        cofins: {
          calcular: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.calcular'
          ),
          tipoCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.tipoCalculo'
          ),
          cst:
            formRefDetalhe.current?.getFieldValueNomeObjeto(
              'ordemServicoItemImpostoCofins.situacaoTributaria'
            )?.codigo || '',
          quantidadeUnidadeCofins: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.quantidadeUnidade'
          ),
          valorUnidadeCofins: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.valorUnidade'
          ),
          baseCalculo: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.baseCalculo'
          ),
          aliquota: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.aliquota'
          ),
          valor: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.valor'
          ),

          calcularRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.calcularRetido'
          ),
          baseCalculoRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.baseCalculoRetido'
          ),
          aliquotaRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.aliquotaRetido'
          ),
          valorRetido: formRefDetalhe.current?.getFieldValue(
            'ordemServicoItemImpostoCofins.valorRetido'
          ),
        },
      });

      await formRefDetalhe.current?.setSemExecutarEvento({
        ordemServicoItemImpostoIcms: {
          // #region ICMS

          modalidadeBaseCalculoIcms: resultado.icms?.modalidadeBaseCalculoIcms,
          // aliquotaInterestadualIcms: number | null;

          percentualReducaoBaseCalculoIcms:
            resultado.icms?.percentualReducaoBaseCalculoIcms,
          baseCalculoIcms: resultado.icms?.baseCalculoIcms,
          aliquotaIcms: resultado.icms?.aliquotaIcms,
          valorIcms: resultado.icms?.valorIcms,

          baseCalculoFcpIcms: resultado.icms?.baseCalculoFcpIcms,
          aliquotaFcpIcms: resultado.icms?.aliquotaFcpIcms,
          valorFcpIcms: resultado.icms?.valorFcpIcms,
          valorIcmsEValorFcpIcms: resultado.icms?.valorIcmsEValorFcpIcms,

          percentualDiferimentoIcms: resultado.icms?.percentualDiferimentoIcms,
          valorSemDiferimentoIcms: resultado.icms?.valorSemDiferimentoIcms,
          valorDiferidoIcms: resultado.icms?.valorDiferidoIcms,

          valorUnitarioPautaIcms: resultado.icms?.valorUnitarioPautaIcms,
          valorTotalPautaIcms: resultado.icms?.valorTotalPautaIcms,

          percentualReducaoBaseCalculoIcmsEfetivo:
            resultado.icms?.percentualReducaoBaseCalculoIcmsEfetivo,
          baseCalculoIcmsEfetivo: resultado.icms?.baseCalculoIcmsEfetivo,
          aliquotaIcmsEfetivo: resultado.icms?.aliquotaIcmsEfetivo,
          valorIcmsEfetivo: resultado.icms?.valorIcmsEfetivo,

          valorUnitarioIcmsSubstituto:
            resultado.icms?.valorUnitarioIcmsSubstituto,
          valorIcmsSubstituto: resultado.icms?.valorIcmsSubstituto,

          motivoDesoneracaoIcms: resultado.icms?.motivoDesoneracaoIcms,
          calcularDesoneracaoIcms: resultado.icms?.calcularDesoneracaoIcms,
          baseCalculoDesoneracaoIcms:
            resultado.icms?.baseCalculoDesoneracaoIcms,
          aliquotaDesoneracaoIcms: resultado.icms?.aliquotaDesoneracaoIcms,
          valorDesoneracaoIcms: resultado.icms?.valorDesoneracaoIcms,

          // baseCalculoSemReducao: number | null;
          // valorSemReducao: number | null;
          // baseCalculoReducao: number | null;
          // valorReducao: number | null;

          // #endregion ICMS

          // #region ICMS ST

          modalidadeBaseCalculoIcmsSt:
            resultado.icms?.modalidadeBaseCalculoIcmsSt,

          percentualMvaIcmsSt: resultado.icms?.percentualMvaIcmsSt,
          // percentualReducaoMvaIcmsSt: number | null;
          percentualReducaoBaseCalculoIcmsSt:
            resultado.icms?.percentualReducaoBaseCalculoIcmsSt,
          aliquotaInterestadualIcmsSt:
            resultado.icms?.aliquotaInterestadualIcmsSt,
          aliquotaInternaIcmsSt: resultado.icms?.aliquotaInternaIcmsSt,
          baseCalculoIcmsSt: resultado.icms?.baseCalculoIcmsSt,
          valorIcmsSt: resultado.icms?.valorIcmsSt,

          baseCalculoFcpIcmsSt: resultado.icms?.baseCalculoFcpIcmsSt,
          aliquotaFcpIcmsSt: resultado.icms?.aliquotaFcpIcmsSt,
          valorFcpIcmsSt: resultado.icms?.valorFcpIcmsSt,
          valorIcmsStEValorFcpIcmsSt:
            resultado.icms?.valorIcmsStEValorFcpIcmsSt,

          baseCalculoIcmsStRetido: resultado.icms?.baseCalculoIcmsStRetido,
          baseCalculoUnitariaProdutoIcmsStRetido:
            resultado.icms?.baseCalculoUnitariaProdutoIcmsStRetido,
          aliquotaIcmsStRetido: resultado.icms?.aliquotaIcmsStRetido,
          valorIcmsStRetido: resultado.icms?.valorIcmsStRetido,
          valorUnitarioProdutoIcmsStRetido:
            resultado.icms?.valorUnitarioProdutoIcmsStRetido,

          baseCalculoFcpIcmsStRetido:
            resultado.icms?.baseCalculoFcpIcmsStRetido,
          baseCalculoUnitariaProdutoFcpIcmsStRetido:
            resultado.icms?.baseCalculoUnitariaProdutoFcpIcmsStRetido,
          aliquotaFcpIcmsStRetido: resultado.icms?.aliquotaFcpIcmsStRetido,
          valorFcpIcmsStRetido: resultado.icms?.valorFcpIcmsStRetido,
          valorUnitarioProdutoFcpIcmsStRetido:
            resultado.icms?.valorUnitarioProdutoFcpIcmsStRetido,

          valorUnitarioPautaIcmsSt: resultado.icms?.valorUnitarioPautaIcmsSt,
          valorTotalPautaIcmsSt: resultado.icms?.valorTotalPautaIcmsSt,

          // #endregion ICMS ST

          // #region ICMS SN

          percentualCreditoIcmsSn: resultado.icms?.percentualCreditoIcmsSn,
          valorCreditoIcmsSn: resultado.icms?.valorCreditoIcmsSn,

          // #endregion ICMS SN
        },

        ordemServicoItemImpostoIcmsOperacaoInterestadual: {
          calcular: resultado.icmsOperacaoInterestadual?.calcular,
          aliquotaEstadoDestino:
            resultado.icmsOperacaoInterestadual?.aliquotaEstadoDestino,
          aliquotaInterestadual:
            resultado.icmsOperacaoInterestadual?.aliquotaInterestadual,
          aliquotaOrigemDestino:
            resultado.icmsOperacaoInterestadual?.aliquotaOrigemDestino,
          estadoOrigemBaseCalculo:
            resultado.icmsOperacaoInterestadual?.estadoOrigemBaseCalculo,
          estadoOrigemPercentualPartilha:
            resultado.icmsOperacaoInterestadual?.estadoOrigemPercentualPartilha,
          estadoOrigemValorIcms:
            resultado.icmsOperacaoInterestadual?.estadoOrigemValorIcms,
          estadoDestinoBaseCalculo:
            resultado.icmsOperacaoInterestadual?.estadoDestinoBaseCalculo,
          estadoDestinoPercentualPartilha:
            resultado.icmsOperacaoInterestadual
              ?.estadoDestinoPercentualPartilha,
          estadoDestinoValorIcmsSemFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoValorIcmsSemFcp,
          estadoDestinoPercentualFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoPercentualFcp,
          estadoDestinoValorFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoValorFcp,
          estadoDestinoValorIcmsComFcp:
            resultado.icmsOperacaoInterestadual?.estadoDestinoValorIcmsComFcp,
        },

        aliquotaOrigem:
          resultado.icmsOperacaoInterestadual?.aliquotaOrigemDestino,
        aliquotaDestino:
          resultado.icmsOperacaoInterestadual?.aliquotaOrigemDestino,

        ordemServicoItemImpostoIpi: {
          tipoCalculo: resultado.ipi?.tipoCalculo,
          quantidadeUnidade: resultado.ipi?.quantidadeUnidadeIpi,
          valorUnidade: resultado.ipi?.valorUnidadeIpi,
          baseCalculo: resultado.ipi?.baseCalculo,
          aliquota: resultado.ipi?.aliquota,
          valor: resultado.ipi?.valor,
        },

        ordemServicoItemImpostoPis: {
          tipoCalculo: resultado.pis?.tipoCalculo,
          quantidadeUnidade: resultado.pis?.quantidadeUnidadePisCofins,
          valorUnidade: resultado.pis?.valorUnidadePisCofins,
          baseCalculo: resultado.pis?.baseCalculo,
          aliquota: resultado.pis?.aliquota,
          valor: resultado.pis?.valor,

          baseCalculoRetido: resultado.pis?.baseCalculoRetido,
          aliquotaRetido: resultado.pis?.aliquotaRetido,
          valorRetido: resultado.pis?.valorRetido,
        },

        ordemServicoItemImpostoCofins: {
          tipoCalculo: resultado.cofins?.tipoCalculo,
          quantidadeUnidade: resultado.cofins?.quantidadeUnidadePisCofins,
          valorUnidade: resultado.cofins?.valorUnidadePisCofins,
          baseCalculo: resultado.cofins?.baseCalculo,
          aliquota: resultado.cofins?.aliquota,
          valor: resultado.cofins?.valor,

          baseCalculoRetido: resultado.cofins?.baseCalculoRetido,
          aliquotaRetido: resultado.cofins?.aliquotaRetido,
          valorRetido: resultado.cofins?.valorRetido,
        },
      });

      handleCalcularValorTotalBruto();
      handleAtualizarCampos();
    } catch (error) {
      TratarErros(error);
    }
  }, [formRefDetalhe, handleAtualizarCampos, handleCalcularValorTotalBruto]);

  const handleCalcularRateioItem = useCallback(async () => {
    const valorTotalProduto =
      formRefDetalhe.current?.getFieldValue('valorTotalProduto');

    const valorTotal =
      listaItem.reduce((acumulador, item) => {
        if (item.id !== idDetalheRegistro)
          acumulador += Number(item.valorTotalProduto);
        return acumulador;
      }, 0) + valorTotalProduto;

    const valorFrete = formPrincipal.current?.getFieldValue('valorFrete');
    const valorSeguro = formPrincipal.current?.getFieldValue('valorSeguro');
    const valorOutrasDespesas = formPrincipal.current?.getFieldValue(
      'valorOutrasDespesas'
    );
    const valorDesconto = formPrincipal.current?.getFieldValue('valorDesconto');

    const formaCalculoRateioFrete = formPrincipal.current?.getFieldValue(
      'freteFormaCalculoPorRateio'
    );
    const formaCalculoRateioSeguro = formPrincipal.current?.getFieldValue(
      'seguroFormaCalculoPorRateio'
    );
    const formaCalculoRateioOutrasDespesas =
      formPrincipal.current?.getFieldValue(
        'outrasDespesasFormaCalculoPorRateio'
      );
    const formaCalculoRateioDesconto = formPrincipal.current?.getFieldValue(
      'descontoFormaCalculoPorRateio'
    );

    if (formaCalculoRateioFrete) {
      const rateioFrete = RateioItemComCasaDecimalCalculo({
        valorRateado: valorFrete,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue('valorFrete', rateioFrete);
    }

    if (formaCalculoRateioSeguro) {
      const rateioSeguro = RateioItemComCasaDecimalCalculo({
        valorRateado: valorSeguro,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue('valorSeguro', rateioSeguro);
    }

    if (formaCalculoRateioOutrasDespesas) {
      const rateioOutrasDespesas = RateioItemComCasaDecimalCalculo({
        valorRateado: valorOutrasDespesas,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue(
        'valorOutrasDespesas',
        rateioOutrasDespesas
      );
    }

    if (formaCalculoRateioDesconto) {
      const rateioDesconto = RateioItemComCasaDecimalCalculo({
        valorRateado: valorDesconto,
        valorTotal,
        valorItem: valorTotalProduto,
        casasDecimais: 2,
      });

      formRefDetalhe.current?.setFieldValue('valorDesconto', rateioDesconto);
    }

    handleCalcularImpostosPorCst();
  }, [
    formRefDetalhe,
    listaItem,
    formPrincipal,
    handleCalcularImpostosPorCst,
    idDetalheRegistro,
  ]);

  const handleCalculaValorTotalProduto = useCallback(async () => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const valorUnitario =
      formRefDetalhe.current?.getFieldValue('valorUnitario');

    const valor = quantidade * valorUnitario;
    await formRefDetalhe.current?.setSemExecutarEvento({
      valorTotalProduto: valor,
    });

    handleCalcularRateioItem();
  }, [formRefDetalhe, handleCalcularRateioItem]);

  const handleCalcularBaseCalculoItemComercialRepresentante = useCallback(
    async (listaDados?: any[]) => {
      if (!listaDados) {
        listaDados = formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemComercial.listaOrdemServicoItemComercialRepresentante'
        );
      }

      if (listaDados && listaDados.length > 0) {
        const {
          valorTotalBruto,
          valorSeguro,
          valorFrete,
          valorOutrasDespesas,
          ordemServicoItemImpostoIpi,
          ordemServicoItemImpostoIcms,
        } = formRefDetalhe.current?.getData() as any;

        const valorImpostoIpi = ordemServicoItemImpostoIpi
          ? ordemServicoItemImpostoIpi.valor
          : 0;
        const valorImpostoIcms = ordemServicoItemImpostoIcms
          ? ordemServicoItemImpostoIcms.valorIcmsStEValorFcpIcmsSt
          : 0;

        const baseCalculo =
          valorTotalBruto -
          (valorSeguro +
            valorFrete +
            valorOutrasDespesas +
            valorImpostoIpi +
            valorImpostoIcms);

        const listaValor = listaDados.map((item: any) => {
          return {
            ...item,
            baseCalculo,
            valor: baseCalculo * (item.aliquota / 100),
          };
        });

        formRefDetalhe.current?.setSemExecutarEvento({
          ordemServicoItemComercial: {
            listaOrdemServicoItemComercialRepresentante: listaValor,
          },
        });
      }
    },
    [formRefDetalhe]
  );

  const handleCarregarDadosItemComercialRepresentante =
    useCallback(async () => {
      let listaDados;

      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      const listaOrdemServicoComercialRepresentante =
        formPrincipal.current?.getFieldValue(
          'ordemServicoComercial.listaOrdemServicoComercialRepresentante'
        );
      const idPessoa = formPrincipal.current?.getFieldValue(
        'ordemServicoPessoa.idPessoa'
      );
      const dataHoraEmissao =
        formPrincipal.current?.getFieldValue('dataHoraEmissao');

      if (
        listaOrdemServicoComercialRepresentante &&
        listaOrdemServicoComercialRepresentante.length > 0
      ) {
        listaDados = await Promise.all(
          listaOrdemServicoComercialRepresentante.map(async (item: any) => {
            delete item.id;

            if (item.formaCalculoPorRegra === true) {
              const response =
                await RegraCalculoComissaoObterConformeCriteriosComunicador.show(
                  {
                    params: {
                      idPessoa,
                      idPessoaRepresentante: item.idPessoaRepresentante,
                      idProduto: produto.id,
                      dataPesquisaVigencia: new Date(dataHoraEmissao),
                      percentualDesconto: 0,
                      idGrupoProdutoServico: produto.idGrupoProdutoServico,
                    },
                  }
                );

              item.aliquota = response.regraCalculoComissao
                ? response.regraCalculoComissao.percentualComissao
                : 0;
            }

            return item;
          })
        );

        await formRefDetalhe.current?.setSemExecutarEvento({
          ordemServicoItemComercial: {
            listaOrdemServicoItemComercialRepresentante: listaDados,
          },
        });

        handleCalcularBaseCalculoItemComercialRepresentante(listaDados);
        handleObterRegraEscolhaTabelaPreco();
      }
    }, [
      formPrincipal,
      formRefDetalhe,
      handleCalcularBaseCalculoItemComercialRepresentante,
      handleObterRegraEscolhaTabelaPreco,
    ]);

  const handleLimparDadosItemComercialRepresentante = useCallback(async () => {
    formRefDetalhe.current?.clearField(
      'ordemServicoItemComercial.listaOrdemServicoItemComercialRepresentante'
    );
  }, [formRefDetalhe]);

  const handleLimparConfiguracaoTributaria = useCallback(async () => {
    formRefDetalhe.current?.clearField('operacaoFiscal', {
      setValorInicial: false,
    });

    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.situacaoTributaria',
      {
        setValorInicial: false,
      }
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.calcularIcms'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.calcularIcmsEfetivo'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.calcularIcmsSubstituto'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.calcularIcmsSt'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.calcularIcmsStRetido'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcms.calcularCreditoIcmsSn'
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.percentualReducaoBaseCalculoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoSemReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorSemReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorReducaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorUnitarioPautaIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorTotalPautaIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorSemDiferimentoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.percentualDiferimentoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorDiferidoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcmsEValorFcpIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.percentualMvaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.percentualReducaoBaseCalculoIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaInternaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaInterestadualIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoUnitariaProdutoIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorUnitarioProdutoIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorUnitarioIcmsSubstituto',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcmsSubstituto',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorUnitarioPautaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorTotalPautaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorDesoneracaoIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorTotalPautaIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcmsStEValorFcpIcmsSt',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoUnitariaProdutoFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorUnitarioProdutoFcpIcmsStRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.percentualReducaoBaseCalculoIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.baseCalculoIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.aliquotaIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorIcmsEfetivo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.percentualCreditoIcmsSn',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcms.valorCreditoIcmsSn',
      null
    );

    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.utiliza'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.calcular'
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaEstadoDestino',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaInterestadual',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.aliquotaOrigemDestino',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemBaseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemPercentualPartilha',
      null
    );
    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoOrigemValorIcms',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoBaseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualPartilha',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsSemFcp',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoPercentualFcp',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorFcp',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIcmsOperacaoInterestadual.estadoDestinoValorIcmsComFcp',
      null
    );

    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIpi.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoIpi.ipiCodigoEnquadramentoLegal',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField('ordemServicoItemImpostoIpi.calcular');

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIpi.baseCalculoSpedCompleto',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIpi.baseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIpi.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIpi.quantidadeUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIpi.valorUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoIpi.valor',
      null
    );

    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoPis.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField('ordemServicoItemImpostoPis.calcular');

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.baseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.quantidadeUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.valorUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.valor',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.baseCalculoRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.aliquotaRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoPis.valorRetido',
      null
    );

    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoCofins.situacaoTributaria',
      { setValorInicial: false }
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemImpostoCofins.calcular'
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.baseCalculo',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.quantidadeUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.valorUnidade',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.aliquota',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.valor',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.baseCalculoRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.aliquotaRetido',
      null
    );

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemImpostoCofins.valorRetido',
      null
    );

    formRefDetalhe.current?.clearField(
      'ordemServicoItemInformacaoSped.codigoBeneficiarioFiscalSped'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemInformacaoSped.baseLegalIcms'
    );

    formRefDetalhe.current?.clearField('baseLegalIpi');
    formRefDetalhe.current?.clearField('outraBaseLegal');
    formRefDetalhe.current?.clearField('informacoesContribuinte');

    formRefDetalhe.current?.clearField(
      'ordemServicoItemComercial.gerarFinanceiro'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemComercial.gerarFaturamento'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemComercial.gerarComissao'
    );

    formRefDetalhe.current?.clearField(
      'ordemServicoItemEstoque.movimentarEstoque'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemEstoque.indicadorTipoEstoque'
    );
    formRefDetalhe.current?.clearField(
      'ordemServicoItemEstoque.porContaOrdemTerceiro'
    );

    // formRefDetalhe.current?.clearField('movimentarEstoqueFiscal');

    handleCalcularImpostosPorCst();
  }, [formRefDetalhe, handleCalcularImpostosPorCst]);

  const handleObterConfiguracaoTributaria = useCallback(async () => {
    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');
    const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
      'ordemServicoPessoa.pessoa'
    );
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const tipoNota =
      formRefDetalhe.current?.getFieldValueNomeObjeto('tipoNota');
    const tipoNotaMotivo =
      formRefDetalhe.current?.getFieldValueNomeObjeto('tipoNotaMotivo');
    const destinado = formRefDetalhe.current?.getFieldValue('destinado');
    const finalidadeEmissao =
      formPrincipal.current?.getFieldValueNomeObjeto('finalidadeEmissao');
    const origemIcms = formRefDetalhe.current?.getFieldValueNomeObjeto(
      'ordemServicoItemImpostoIcms.origemIcms'
    );

    if (
      !dataHoraEmissao ||
      !pessoa ||
      !produto ||
      !tipoNota ||
      !tipoNotaMotivo ||
      !destinado
    ) {
      handleLimparConfiguracaoTributaria();
      return;
    }

    const indicadorPresenca =
      formPrincipal.current?.getFieldValue('indicadorPresenca');

    const ufPessoa =
      formPrincipal.current?.getFieldValue(
        'ordemServicoPessoaEndereco.cidade.estado.sigla'
      ) || null;

    const ufPessoaEntrega =
      formPrincipal.current?.getFieldValue(
        'ordemServicoPessoaEnderecoEntrega.cidade.estado.sigla'
      ) || null;

    const tipoMercado = ObterTipoMercado({
      ufEstadoOrigem: empresa.uf,
      ufEstadoDestino: ufPessoaEntrega ?? ufPessoa,
      indicadorPresenca,
    });

    try {
      setLoading(true);

      const response = await ConfiguracaoTributariaComunicador.show({
        params: {
          dataPesquisaVigencia: dataHoraEmissao,
          idPessoa: pessoa.id,
          idProduto: produto.id,
          idTipoNota: tipoNota.id,
          idTipoNotaMotivo: tipoNotaMotivo.id,
          destinado,
          finalidadeEmissao,
          idOrigemIcms: origemIcms?.id,
          tipoEntradaSaida: TipoMovimentoEntradaSaidaEnum.saida,
          tipoMercado,
        },
      });

      if (response) {
        // #region Operacao Fiscal

        await formRefDetalhe.current?.setSemExecutarEvento({
          operacaoFiscal: response?.regraEscolhaOperacaoFiscal?.operacaoFiscal,
          naturezaOperacao:
            response?.regraEscolhaOperacaoFiscal?.naturezaOperacao,
        });

        // #endregion Operacao Fiscal

        // #region CST

        await formRefDetalhe.current?.setSemExecutarEvento({
          ordemServicoItemImpostoIcms: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaIcms,
            calcularIcms: response?.regraEscolhaCst?.calcularIcms,
            calcularIcmsEfetivo: response?.regraEscolhaCst?.calcularIcmsEfetivo,
            calcularIcmsSubstituto:
              response?.regraEscolhaCst?.calcularIcmsSubstituto,
            calcularIcmsSt: response?.regraEscolhaCst?.calcularSt,
            calcularIcmsStRetido:
              response?.regraEscolhaCst?.calcularIcmsStRetido,
            calcularCreditoIcmsSn:
              response?.regraEscolhaCst?.calcularCreditoIcmsSn,
            modalidadeBaseCalculoIcms:
              response?.regraEscolhaCst?.modalidadeBaseCalculoIcms,
            modalidadeBaseCalculoIcmsSt:
              response?.regraEscolhaCst?.modalidadeBaseCalculoIcmsSt,
            motivoDesoneracaoIcms:
              response?.regraEscolhaCst?.motivoDesoneracaoIcms,
            calcularDesoneracaoIcms:
              response?.regraEscolhaCst?.calcularDesoneracaoIcms,
            deduzirDesoneracaoIcms:
              response?.regraEscolhaCst?.deduzirDesoneracaoIcms,
          },

          ordemServicoItemImpostoIcmsOperacaoInterestadual: {
            utiliza:
              response?.regraEscolhaCst?.utilizaIcmsOperacaoInterestadual,
            calcular:
              response?.regraEscolhaCst?.calcularIcmsOperacaoInterestadual,
          },

          ordemServicoItemImpostoIpi: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaIpi,
            ipiCodigoEnquadramentoLegal:
              response?.regraEscolhaCst?.ipiCodigoEnquadramentoLegal,
            calcular: response?.regraEscolhaCst?.calcularIpi,
          },

          ordemServicoItemImpostoPis: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaPisCofins,
            calcular: response?.regraEscolhaCst?.calcularPisCofins,
            calcularRetido: response?.regraEscolhaCst?.calcularPisCofinsRetido,
          },

          ordemServicoItemImpostoCofins: {
            situacaoTributaria:
              response?.regraEscolhaCst?.codigoSituacaoTributariaPisCofins,
            calcular: response?.regraEscolhaCst?.calcularPisCofins,
            calcularRetido: response?.regraEscolhaCst?.calcularPisCofinsRetido,
          },

          ordemServicoItemInformacaoSped: {
            codigoBeneficiarioFiscalSped:
              response?.regraEscolhaCst?.codigoBeneficiarioFiscalSped,
            baseLegalIcms: response?.regraEscolhaCst?.baseLegalIcms,
          },

          baseLegalIpi: response?.regraEscolhaCst?.baseLegalIpi,
          outraBaseLegal: response?.regraEscolhaCst?.outraBaseLegal,
          informacoesContribuinte:
            response?.regraEscolhaCst?.informacoesContribuinte,
        });

        // #endregion CST

        // #region Parametro

        if (response.regraEscolhaParametro) {
          let movimentarEstoque = false;
          if (!produto.produtoEstoque.controlarEstoque) {
            movimentarEstoque = false;
          } else {
            movimentarEstoque =
              response.regraEscolhaParametro.movimentarEstoqueFisico;
          }

          await formRefDetalhe.current?.setSemExecutarEvento({
            ordemServicoItemEstoque: {
              movimentarEstoque,
              indicadorTipoEstoque:
                response.regraEscolhaParametro.indicadorTipoEstoque,
              porContaOrdemTerceiro:
                response.regraEscolhaParametro.porContaOrdemTerceiro,
            },
          });

          await formRefDetalhe.current?.setSemExecutarEvento({
            ordemServicoItemComercial: {
              gerarFinanceiro: response.regraEscolhaParametro.gerarFinanceiro,
              gerarFaturamento: response.regraEscolhaParametro.gerarFaturamento,
              gerarComissao: response.regraEscolhaParametro.gerarComissao,
            },
          });

          if (response.regraEscolhaParametro.gerarComissao) {
            handleCarregarDadosItemComercialRepresentante();
          } else {
            handleLimparDadosItemComercialRepresentante();
          }

          handleObterRegraEscolhaTabelaPreco();

          setListaLocalEstoque([]);
          selecionarItemEstoqueLocalEstoque(true);
        }

        // #endregion Parametro

        // #region Aliquota

        await formRefDetalhe.current?.setSemExecutarEvento({
          ordemServicoItemImpostoIcms: {
            // #region ICMS

            percentualReducaoBaseCalculoIcms:
              response?.regraEscolhaAliquota?.percentualReducaoBaseCalculoIcms,
            aliquotaIcms: response?.regraEscolhaAliquota?.aliquotaIcms,
            aliquotaFcpIcms: response?.regraEscolhaAliquota?.aliquotaFcpIcms,

            aliquotaIcmsEfetivo:
              response?.regraEscolhaAliquota?.aliquotaIcmsEfetivo,

            valorUnitarioIcmsSubstituto:
              response?.regraEscolhaAliquota?.valorUnitarioIcmsSubstituto,

            aliquotaDesoneracaoIcms:
              response?.regraEscolhaAliquota?.aliquotaDesoneracaoIcms,

            // #endregion ICMS

            // #region ICMS ST

            modalidadeBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota?.modalidadeBaseCalculoIcmsSt,
            aliquotaInternaIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInternaIcmsSt,
            aliquotaInterestadualIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInterestadualIcmsSt,
            percentualMvaIcmsSt:
              response?.regraEscolhaAliquota?.percentualMvaIcmsSt,

            valorUnitarioPautaIcmsSt:
              response?.regraEscolhaAliquota?.valorUnitarioPautaIcmsSt,
            percentualReducaoBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota
                ?.percentualReducaoBaseCalculoIcmsSt,
            aliquotaFcpIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaFcpIcmsSt,

            baseCalculoUnitariaProdutoIcmsStRetido:
              response?.regraEscolhaAliquota
                ?.baseCalculoUnitariaProdutoIcmsStRetido,
            aliquotaIcmsStRetido:
              response?.regraEscolhaAliquota?.aliquotaIcmsStRetido,
            valorUnitarioProdutoIcmsStRetido:
              response?.regraEscolhaAliquota?.valorUnitarioProdutoIcmsStRetido,

            baseCalculoUnitariaProdutoFcpIcmsStRetido:
              response?.regraEscolhaAliquota
                ?.baseCalculoUnitariaProdutoFcpIcmsStRetido,
            aliquotaFcpIcmsStRetido:
              response?.regraEscolhaAliquota?.aliquotaFcpIcmsStRetido,
            valorUnitarioProdutoFcpIcmsStRetido:
              response?.regraEscolhaAliquota
                ?.valorUnitarioProdutoFcpIcmsStRetido,

            // #endregion ICMS ST

            // #region ICMS SN

            percentualCreditoIcmsSn:
              response?.regraEscolhaAliquota?.percentualCreditoIcmsSn,

            // #endregion ICMS SN
          },

          ordemServicoItemImpostoIcmsOperacaoInterestadual: {
            estadoOrigemPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoOrigem,
            estadoDestinoPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoDestino,

            aliquotaEstadoDestino:
              response?.regraEscolhaAliquota
                ?.aliquotaEstadoDestinoIcmsOperacaoInterestadual,
            aliquotaInterestadual:
              response?.regraEscolhaAliquota
                ?.aliquotaInterestadualIcmsOperacaoInterestadual,
          },

          ordemServicoItemImpostoIpi: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaIpi,
          },

          ordemServicoItemImpostoPis: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaPis,
            aliquotaRetido: response?.regraEscolhaAliquota?.aliquotaPisRetido,
          },

          ordemServicoItemImpostoCofins: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaCofins,
            aliquotaRetido:
              response?.regraEscolhaAliquota?.aliquotaCofinsRetido,
          },
        });

        // #endregion Aliquota

        const modalidadeBaseCalculo = formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcms.modalidadeBaseCalculoIcms'
        );

        if (
          response?.regraEscolhaCst?.calcularIcms &&
          response?.regraEscolhaAliquota?.aliquotaIcms &&
          !modalidadeBaseCalculo
        ) {
          await formRefDetalhe.current?.setSemExecutarEvento({
            ordemServicoItemImpostoIcms: {
              modalidadeBaseCalculoIcms:
                ModalidadeBaseCalculoIcmsEnum.valorOperacao,
            },
          });
        }
      } else {
        handleLimparConfiguracaoTributaria();
      }

      handleCalcularImpostosPorCst();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [
    empresa.uf,
    formPrincipal,
    formRefDetalhe,
    handleCalcularImpostosPorCst,
    handleCarregarDadosItemComercialRepresentante,
    handleLimparConfiguracaoTributaria,
    handleLimparDadosItemComercialRepresentante,
    handleObterRegraEscolhaTabelaPreco,
    selecionarItemEstoqueLocalEstoque,
    setListaLocalEstoque,
    setLoading,
  ]);

  const handleObterConfiguracaoTributariaConformeProduto =
    useCallback(async () => {
      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');

      if (!produto) {
        handleLimparConfiguracaoTributaria();
        return;
      }

      const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );
      const idOrdemServico = getIdDetalheRegistroFormPrincipal();

      try {
        setLoading(true);

        const response =
          await ConfiguracaoTributariaObterConformeProdutoComunicador.show({
            params: {
              idProduto: produto.id,
              idPessoa: pessoa.id,
              tipoLancamento:
                ConfiguracaoTributacaoTipoLancamentoEnum.ordemServico,
              idLancamento: idOrdemServico,
            },
          });

        await formRefDetalhe.current?.setSemExecutarEvento({
          destinado: response?.destinado,
          tipoNota: response?.tipoNota,
          tipoNotaMotivo: response?.tipoNotaMotivo,
          unidadeMedida: response?.unidadeMedida,
          produtoTributado: response?.produtoTributado,
          unidadeMedidaTributado: response?.unidadeMedidaTributado,

          ordemServicoItemImpostoIcms: {
            origemIcms: response?.origemIcms,
          },

          ordemServicoItemEstoque: {
            unidadeMedidaEstoque: response?.unidadeMedida,
          },
        });

        formRefDetalhe.current?.setFieldValue(
          'quantidadeUnitariaTributado',
          response?.quantidadeUnitariaTributado
        );

        handleObterConfiguracaoTributaria();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    }, [
      formPrincipal,
      formRefDetalhe,
      getIdDetalheRegistroFormPrincipal,
      handleLimparConfiguracaoTributaria,
      handleObterConfiguracaoTributaria,
      setLoading,
    ]);

  const handleCalcularValorUnitarioTributadoProdutoTributado =
    useCallback(async () => {
      const valorUnitario =
        formRefDetalhe.current?.getFieldValue('valorUnitario');
      const quantidadeUnitariaTributado = formRefDetalhe.current?.getFieldValue(
        'quantidadeUnitariaTributado'
      );

      const valorUnitarioTributado = quantidadeUnitariaTributado
        ? valorUnitario / quantidadeUnitariaTributado
        : 0;

      await formRefDetalhe.current?.setSemExecutarEvento({
        valorUnitarioTributado,
      });
    }, [formRefDetalhe]);

  const handleCalcularQuantidadeTributadoProdutoTributado =
    useCallback(async () => {
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
      const quantidadeUnitariaTributado = formRefDetalhe.current?.getFieldValue(
        'quantidadeUnitariaTributado'
      );

      if (quantidadeUnitariaTributado > 0 && quantidade > 0) {
        const quantidadeTributado = quantidade * quantidadeUnitariaTributado;

        await formRefDetalhe.current?.setSemExecutarEvento({
          quantidadeTributado,
        });
      }

      handleCalcularValorUnitarioTributadoProdutoTributado();
    }, [formRefDetalhe, handleCalcularValorUnitarioTributadoProdutoTributado]);

  const handleCalcularQuantidadeUnitariaTributadoProdutoTributado =
    useCallback(async () => {
      const quantidadeTributado = formRefDetalhe.current?.getFieldValue(
        'quantidadeTributado'
      );
      const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');

      if (quantidade > 0) {
        const quantidadeUnitariaTributado = quantidadeTributado / quantidade;

        await formRefDetalhe.current?.setSemExecutarEvento({
          quantidadeUnitariaTributado,
        });
      }

      handleCalcularValorUnitarioTributadoProdutoTributado();
    }, [formRefDetalhe, handleCalcularValorUnitarioTributadoProdutoTributado]);

  const handleObterRegraEscolhaAliquota = useCallback(async () => {
    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');
    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
      'ordemServicoPessoa.pessoa'
    );
    const destinado = formRefDetalhe.current?.getFieldValue('destinado');

    const calcularIcms = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.calcularIcms'
    );
    const calcularSt = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.calcularIcmsSt'
    );
    const calcularIpi = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIpi.calcular'
    );
    const calcularPis = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoPis.calcular'
    );
    const calcularPisRetido = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoPis.calcularRetido'
    );
    const calcularCofins = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoCofins.calcular'
    );
    const calcularCofinsRetido = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoCofins.calcularRetido'
    );
    const calcularCreditoIcmsSn = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.calcularCreditoIcmsSn'
    );
    const utilizaIcmsOperacaoInterestadual =
      formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemImpostoIcmsOperacaoInterestadual.utiliza'
      );
    const calcularIcmsOperacaoInterestadual =
      formRefDetalhe.current?.getFieldValue(
        'ordemServicoItemImpostoIcmsOperacaoInterestadual.calcular'
      );
    const origemIcms = formRefDetalhe.current?.getFieldValueNomeObjeto(
      'ordemServicoItemImpostoIcms.origemIcms'
    );
    const calcularDesoneracaoIcms = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.calcularDesoneracaoIcms'
    );

    const idTipoNota = formRefDetalhe.current?.getFieldValue('idTipoNota');
    const idTipoNotaMotivo =
      formRefDetalhe.current?.getFieldValue('idTipoNotaMotivo');

    const idCodigoSituacaoTributaria = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemImpostoIcms.idSituacaoTributaria'
    );

    const calcularPisCofins = calcularPis || calcularCofins;
    const calcularPisCofinsRetido = calcularPisRetido || calcularCofinsRetido;

    if (
      !dataHoraEmissao ||
      !produto ||
      !pessoa ||
      !destinado ||
      !idTipoNota ||
      !idTipoNotaMotivo ||
      !idCodigoSituacaoTributaria
    ) {
      return;
    }

    try {
      setLoading(true);

      const response =
        await RegraEscolhaAliquotaObterConformeCriteriosComunicador.show({
          params: {
            dataPesquisaVigencia: dataHoraEmissao,
            idProduto: produto.id,
            idPessoa: pessoa.id,
            destinado,
            idTipoNota,
            idTipoNotaMotivo,
            idCodigoSituacaoTributaria,

            calcularIcms,
            calcularSt,
            calcularIpi,
            calcularPisCofins,
            calcularPisCofinsRetido,
            calcularCreditoIcmsSn,

            utilizaIcmsOperacaoInterestadual,
            calcularIcmsOperacaoInterestadual,

            idOrigemIcms: origemIcms?.id,

            calcularDesoneracaoIcms,
          },
        });

      if (response) {
        await formRefDetalhe.current?.setSemExecutarEvento({
          ordemServicoItemImpostoIcms: {
            percentualReducaoBaseCalculoIcms:
              response?.regraEscolhaAliquota?.percentualReducaoBaseCalculoIcms,
            aliquotaIcms: response?.regraEscolhaAliquota?.aliquotaIcms,
            aliquotaFcpIcms: response?.regraEscolhaAliquota?.aliquotaFcpIcms,
            percentualDiferimentoIcms:
              response?.regraEscolhaAliquota?.percentualDiferimentoIcms,
            modalidadeBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota?.modalidadeBaseCalculoIcmsSt,
            aliquotaInternaIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInternaIcmsSt,
            aliquotaInterestadualIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaInterestadualIcmsSt,
            percentualMvaIcmsSt:
              response?.regraEscolhaAliquota?.percentualMvaIcmsSt,
            valorUnitarioPautaIcmsSt:
              response?.regraEscolhaAliquota?.valorUnitarioPautaIcmsSt,
            percentualReducaoBaseCalculoIcmsSt:
              response?.regraEscolhaAliquota
                ?.percentualReducaoBaseCalculoIcmsSt,
            aliquotaFcpIcmsSt:
              response?.regraEscolhaAliquota?.aliquotaFcpIcmsSt,
            aliquotaDesoneracaoIcms:
              response?.regraEscolhaAliquota?.aliquotaDesoneracaoIcms,
          },

          ordemServicoItemImpostoIcmsOperacaoInterestadual: {
            estadoOrigemPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoOrigem,
            estadoDestinoPercentualPartilha:
              response?.regraEscolhaAliquota?.percentualPartilhaEstadoDestino,
          },

          ordemServicoItemImpostoIpi: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaIpi,
          },

          ordemServicoItemImpostoPis: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaPis,
            aliquotaRetido: response?.regraEscolhaAliquota?.aliquotaPisRetido,
          },

          ordemServicoItemImpostoCofins: {
            aliquota: response?.regraEscolhaAliquota?.aliquotaCofins,
            aliquotaRetido:
              response?.regraEscolhaAliquota?.aliquotaCofinsRetido,
          },
        });
      }

      handleCalcularImpostosPorCst();
      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [formPrincipal, formRefDetalhe, handleCalcularImpostosPorCst, setLoading]);

  const handleObterRegraEscolhaAliquotaOperacaoInterestadual =
    useCallback(async () => {
      const dataHoraEmissao =
        formPrincipal.current?.getFieldValue('dataHoraEmissao');
      const produto =
        formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
      const pessoa = formPrincipal.current?.getFieldValueNomeObjeto(
        'ordemServicoPessoa.pessoa'
      );
      const destinado = formRefDetalhe.current?.getFieldValue('destinado');

      const utilizaIcmsOperacaoInterestadual =
        formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.utiliza'
        );
      const calcularIcmsOperacaoInterestadual =
        formRefDetalhe.current?.getFieldValue(
          'ordemServicoItemImpostoIcmsOperacaoInterestadual.calcular'
        );
      const origemIcms = formRefDetalhe.current?.getFieldValueNomeObjeto(
        'ordemServicoItemImpostoIcms.origemIcms'
      );

      if (!dataHoraEmissao || !produto || !pessoa || !destinado) {
        return;
      }

      try {
        setLoading(true);

        const response =
          await RegraEscolhaAliquotaObterConformeCriteriosComunicador.show({
            params: {
              dataPesquisaVigencia: dataHoraEmissao,
              idProduto: produto.id,
              idPessoa: pessoa.id,
              destinado,

              utilizaIcmsOperacaoInterestadual,
              calcularIcmsOperacaoInterestadual,

              idOrigemIcms: origemIcms?.id,
            },
          });

        if (response) {
          await formRefDetalhe.current?.setSemExecutarEvento({
            ordemServicoItemImpostoIcmsOperacaoInterestadual: {
              estadoOrigemPercentualPartilha:
                response?.regraEscolhaAliquota?.percentualPartilhaEstadoOrigem,
              estadoDestinoPercentualPartilha:
                response?.regraEscolhaAliquota?.percentualPartilhaEstadoDestino,

              aliquotaEstadoDestino:
                response?.regraEscolhaAliquota
                  ?.aliquotaEstadoDestinoIcmsOperacaoInterestadual,
              aliquotaInterestadual:
                response?.regraEscolhaAliquota
                  ?.aliquotaInterestadualIcmsOperacaoInterestadual,
            },
          });
        }

        handleCalcularIcmsOperacaoInterestadual();
        setLoading(false);
      } catch (error) {
        TratarErros(error, { redirecionar: false });
        setLoading(false);
      }
    }, [
      formPrincipal,
      formRefDetalhe,
      handleCalcularIcmsOperacaoInterestadual,
      setLoading,
    ]);

  const handleObterRegraEscolhaParametros = useCallback(async () => {
    const dataHoraEmissao =
      formPrincipal.current?.getFieldValue('dataHoraEmissao');

    const produto = formRefDetalhe.current?.getFieldValueNomeObjeto('produto');
    const idTipoNota = formRefDetalhe.current?.getFieldValue('idTipoNota');
    const idTipoNotaMotivo =
      formRefDetalhe.current?.getFieldValue('idTipoNotaMotivo');
    const idOperacaoFiscal =
      formRefDetalhe.current?.getFieldValue('idOperacaoFiscal');

    const destinado = formRefDetalhe.current?.getFieldValue('destinado');

    if (
      !dataHoraEmissao ||
      !produto ||
      !idTipoNota ||
      !idTipoNotaMotivo ||
      !idOperacaoFiscal ||
      !destinado
    ) {
      return;
    }

    try {
      setLoading(true);

      const response =
        await RegraEscolhaParametroObterConformeCriteriosComunicador.show({
          params: {
            dataPesquisaVigencia: dataHoraEmissao,
            idOperacaoFiscal,
            destinado,
            idProduto: produto.id,
            idTipoNota,
            idTipoNotaMotivo,
            tipoMovimento: TipoMovimentoEntradaSaidaEnum.saida,
          },
        });

      if (response) {
        if (response.regraEscolhaParametro) {
          let movimentarEstoque = false;
          if (!produto.produtoEstoque.controlarEstoque) {
            movimentarEstoque = false;
          } else {
            movimentarEstoque =
              response.regraEscolhaParametro.movimentarEstoqueFisico;
          }

          await formRefDetalhe.current?.setSemExecutarEvento({
            ordemServicoItemEstoque: {
              movimentarEstoque,
              indicadorTipoEstoque:
                response.regraEscolhaParametro.indicadorTipoEstoque,
              porContaOrdemTerceiro:
                response.regraEscolhaParametro.porContaOrdemTerceiro,
            },
          });

          await formRefDetalhe.current?.setSemExecutarEvento({
            ordemServicoItemComercial: {
              gerarFinanceiro: response.regraEscolhaParametro.gerarFinanceiro,
              gerarFaturamento: response.regraEscolhaParametro.gerarFaturamento,
              gerarComissao: response.regraEscolhaParametro.gerarComissao,
            },
          });

          if (response?.regraEscolhaParametro?.gerarComissao) {
            handleCarregarDadosItemComercialRepresentante();
          } else {
            handleLimparDadosItemComercialRepresentante();
          }

          setListaLocalEstoque([]);
          selecionarItemEstoqueLocalEstoque(true);
        }
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error, { redirecionar: false });
      setLoading(false);
    }
  }, [
    formPrincipal,
    formRefDetalhe,
    handleCarregarDadosItemComercialRepresentante,
    handleLimparDadosItemComercialRepresentante,
    selecionarItemEstoqueLocalEstoque,
    setListaLocalEstoque,
    setLoading,
  ]);

  const handleCalcularItemEngenhariaPesagem = useCallback(async () => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const pesoLiquido = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemEngenharia.pesoLiquidoUnitario'
    );

    const pesoBruto = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemEngenharia.pesoBrutoUnitario'
    );

    const quantidadeVolumes = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemEngenharia.quantidadeVolumesUnitario'
    );

    const calculoPesoBruto = quantidade * pesoBruto;
    const calculoPesoLiquido = quantidade * pesoLiquido;
    const calculoQuantidadeVolumes = Number(
      Number(quantidade) * Number(quantidadeVolumes)
    ).ArredondarParaCima(0);

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.quantidadeVolumes',
      calculoQuantidadeVolumes
    );
    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.pesoBruto',
      calculoPesoBruto
    );
    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.pesoLiquido',
      calculoPesoLiquido
    );
  }, [formRefDetalhe]);

  const handleCalcularItemEngenhariaMetragem = useCallback(async () => {
    const quantidade = formRefDetalhe.current?.getFieldValue('quantidade');
    const metroQuadrado = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemEngenharia.metroQuadradoUnitario'
    );

    const metroCubico = formRefDetalhe.current?.getFieldValue(
      'ordemServicoItemEngenharia.metroCubicoUnitario'
    );
    const calculoMetroQuadrado = quantidade * metroQuadrado;
    const calculoMetroCubico = quantidade * metroCubico;

    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.metroQuadrado',
      calculoMetroQuadrado
    );
    formRefDetalhe.current?.setFieldValue(
      'ordemServicoItemEngenharia.metroCubico',
      calculoMetroCubico
    );
  }, [formRefDetalhe]);

  return (
    <ItemTabContext.Provider
      value={{
        idDetalheRegistro,
        setItemOrdem: handleSetItemOrdem,
        atualizarCampos: handleAtualizarCampos,
        calculaValorTotalProduto: handleCalculaValorTotalProduto,
        calcularRateioItem: handleCalcularRateioItem,
        calcularIcmsOperacaoInterestadual:
          handleCalcularIcmsOperacaoInterestadual,
        calcularImpostosPorCst: handleCalcularImpostosPorCst,
        calcularValorTotalBruto: handleCalcularValorTotalBruto,
        obterConfiguracaoTributariaConformeProduto:
          handleObterConfiguracaoTributariaConformeProduto,
        obterConfiguracaoTributaria: handleObterConfiguracaoTributaria,
        calcularQuantidadeTributadoProdutoTributado:
          handleCalcularQuantidadeTributadoProdutoTributado,
        calcularQuantidadeUnitariaTributadoProdutoTributado:
          handleCalcularQuantidadeUnitariaTributadoProdutoTributado,
        calcularValorUnitarioTributadoProdutoTributado:
          handleCalcularValorUnitarioTributadoProdutoTributado,
        obterRegraEscolhaAliquota: handleObterRegraEscolhaAliquota,
        obterRegraEscolhaAliquotaOperacaoInterestadual:
          handleObterRegraEscolhaAliquotaOperacaoInterestadual,
        obterDadosItemComercialRepresentante:
          handleCarregarDadosItemComercialRepresentante,
        limparDadosItemComercialRepresentante:
          handleLimparDadosItemComercialRepresentante,
        calcularBaseCalculoItemComercialRepresentante:
          handleCalcularBaseCalculoItemComercialRepresentante,
        obterRegraEscolhaTabelaPreco: handleObterRegraEscolhaTabelaPreco,
        obterRegraEscolhaParametros: handleObterRegraEscolhaParametros,
        calcularItemEngenhariaPesagem: handleCalcularItemEngenhariaPesagem,
        calcularItemEngenhariaMetragem: handleCalcularItemEngenhariaMetragem,
        limparConfiguracaoTributaria: handleLimparConfiguracaoTributaria,
        validador: true,
      }}
    >
      {children}
    </ItemTabContext.Provider>
  );
};

function UseItemTab(): Omit<IItemTabContext, 'validador'> {
  const context = useContext(ItemTabContext);

  if (!context.validador) {
    throw new Error('UseItemTab deve ser usado com um ItemTabHook');
  }

  return context;
}

export { ItemTabHook, UseItemTab };
