import React, { useEffect, useCallback } from 'react';
import { FormCia } from '@elogestor/unformcia';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IRegraEscolhaFormaPagamentoValoresAlterar } from '@elogestor/util';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import { UseRota } from '../../../../../../Componentes/Detalhe/Hooks/RotasContext';
import IMain from '../../../../../../Componentes/Detalhe/Interface/IMain';
import LoadingDiv from '../../../../../../Componentes/LoadingDiv';
import TextoLoadingSalvar from '../../../../../../Componentes/TextoLoadingSalvar';
import { UsePermissoes } from '../../../../../../Hooks/Permissoes';
import { Divisor } from '../../../../../Suprimentos/Produtos/Produto/Detalhe/AcoesProduto/Imagem/styles';
import { BtnContainer } from './styles';
import InputInteiro from '../../../../../../Componentes/Inputs/InputInteiro';
import InputDateSemTimeZone from '../../../../../../Componentes/Inputs/InputDateSemTimeZone';
import InputSwitch from '../../../../../../Componentes/Inputs/InputSwitch';
import InputAutoCompleteTagFormaPagamento from '../../../../../../Componentes/Inputs/AutoCompleteTag/Financeiro/InputAutoCompleteTagFormaPagamento';
import { Sleep } from '../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import InputAutoCompleteFormaPagamento from '../../../../../../Componentes/Inputs/AutoComplete/Financeiro/InputAutoCompleteFormaPagamento';
import InputAutoCompleteTagPessoa from '../../../../../../Componentes/Inputs/AutoCompleteTag/Comercial/InputAutoCompleteTagPessoa';
import InputAutoCompleteTagSerie from '../../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagSerie';

type IProps = IMain<IRegraEscolhaFormaPagamentoValoresAlterar>;

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();
  const { FinanceiroGeralRegraEscolhaFormaPagamento: permissao } = permissoes;
  const navigate = useNavigate();
  const rotas = UseRota();

  const {
    formRef,
    loading,
    handleSubmit,
    handleCarregarDados,
    getIdDetalheRegistro,
    setIdDetalheRegistro,
    inputRefFocus,
  } = UseForm();

  const idDetalheRegistro = getIdDetalheRegistro();

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosDuplicados,
      dadosObrigatorios,
      dadosPadrao,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();
    const { erro, id } = await handleSubmit(data);
    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  const handleAtualizarQuantidadeCriterios = useCallback(async () => {
    let quantidadeCriterios = 0;
    await Sleep(1);
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaFormaPagamentoPessoa')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }
    await Sleep(1);
    if (
      formRef.current?.getFieldValue('listaRegraEscolhaFormaPagamentoSerie')
        .listaValor.length > 0
    ) {
      await quantidadeCriterios++;
    }

    formRef.current?.setFieldValue('quantidadeCriterios', quantidadeCriterios);
  }, [formRef]);

  return (
    <FormCia ref={formRef}>
      <LoadingDiv isLoading={loading} />

      <div>
        <Divisor>
          <Row>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                ref={inputRefFocus}
                label="Data Vigência Incial"
                name="dataInicioVigencia"
              />
            </Col>
            <Col lg={2} md={6} sm={12}>
              <InputDateSemTimeZone
                label="Data Vigência Final"
                name="dataFinalVigencia"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputSwitch
                label="Ativo"
                name="listaRegraEscolhaFormaPagamentoEmpresa[0].ativo"
              />
            </Col>
            <Col lg={2} md={6} sm={6}>
              <InputInteiro
                label="Num. Critérios"
                name="quantidadeCriterios"
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagPessoa
                label="Clientes"
                name="listaRegraEscolhaFormaPagamentoPessoa"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteTagSerie
                label="Séries"
                name="listaRegraEscolhaFormaPagamentoSerie"
                onChangeListaItemAtualAposCarregarSemClear={
                  handleAtualizarQuantidadeCriterios
                }
              />
            </Col>
          </Row>
        </Divisor>

        <Divisor>
          <h4>Resultado para Regras</h4>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <InputAutoCompleteFormaPagamento
                label="Forma de Pagamento"
                name="regraEscolhaFormaPagamentoResultado.idFormaPagamento"
                nomeObjeto="regraEscolhaFormaPagamentoResultado.formaPagamento"
              />
            </Col>
          </Row>
        </Divisor>
      </div>

      <BtnContainer>
        <button
          type="button"
          onClick={handleClickSalvar}
          className="btn-padrao btn-verde"
          disabled={
            loading ||
            (idDetalheRegistro && !permissao?.altera) ||
            (!idDetalheRegistro && !permissao?.inclui)
          }
        >
          <TextoLoadingSalvar loading={loading} />
        </button>
      </BtnContainer>
    </FormCia>
  );
};

export default Main;
