import {
  IcmsCteObterVisibilidadeBloqueioCamposPorCst,
  IcmsObterVisibilidadeBloqueioCamposPorCst,
  IpiObterVisibilidadeBloqueioCamposPorCst,
  ModeloDocumentoTipoEnum,
  PisCofinsObterVisibilidadeBloqueioCamposPorCst,
} from '@elogestor/util';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';

// #region ICMS

const camposEscondidosResultadoPadraoIcms = {
  calcularIcms: true,
  calcularCreditoIcmsSn: true,
  calcularSt: true,
  calcularIcmsStRetido: true,
  calcularIcmsEfetivo: true,
  calcularIcmsSubstituto: true,
  utilizaIcmsOperacaoInterestadual: true,
  calcularIcmsOperacaoInterestadual: true,

  modalidadeBaseCalculoIcms: true,
  modalidadeBaseCalculoIcmsSt: true,

  motivoDesoneracaoIcms: true,
  calcularDesoneracaoIcms: true,
  deduzirDesoneracaoIcms: true,
};

const camposBloqueadosResultadoPadraoIcms = {
  calcularIcms: true,
  calcularCreditoIcmsSn: true,
  calcularSt: true,
  calcularIcmsStRetido: true,
  calcularIcmsEfetivo: true,
  calcularIcmsSubstituto: true,
  utilizaIcmsOperacaoInterestadual: true,
  calcularIcmsOperacaoInterestadual: true,

  modalidadeBaseCalculoIcms: true,
  modalidadeBaseCalculoIcmsSt: true,

  motivoDesoneracaoIcms: true,
  calcularDesoneracaoIcms: true,
  deduzirDesoneracaoIcms: true,
};

type ICamposEscondidosResultadoIcms =
  typeof camposEscondidosResultadoPadraoIcms;
type ICamposBloqueadosResultadoIcms =
  typeof camposBloqueadosResultadoPadraoIcms;

// #endregion ICMS

// #region IPI

const camposEscondidosResultadoPadraoIpi = {
  codigoSituacaoTributariaIpi: true,
  ipiCodigoEnquadramentoLegal: true,
  calcularIpi: true,

  baseLegalIpi: true,
};

const camposBloqueadosResultadoPadraoIpi = {
  codigoSituacaoTributariaIpi: true,
  ipiCodigoEnquadramentoLegal: true,
  calcularIpi: true,

  baseLegalIpi: true,
};

type ICamposEscondidosResultadoIpi = typeof camposEscondidosResultadoPadraoIpi;
type ICamposBloqueadosResultadoIpi = typeof camposBloqueadosResultadoPadraoIpi;

// #endregion IPI

// #region PIS/COFINS

const camposEscondidosResultadoPadraoPisCofins = {
  codigoSituacaoTributariaPisCofins: true,
  calcularPisCofins: true,
  calcularPisCofinsRetido: true,
};

const camposBloqueadosResultadoPadraoPisCofins = {
  codigoSituacaoTributariaPisCofins: true,
  calcularPisCofins: true,
  calcularPisCofinsRetido: true,
};

type ICamposEscondidosResultadoPisCofins =
  typeof camposEscondidosResultadoPadraoPisCofins;
type ICamposBloqueadosResultadoPisCofins =
  typeof camposBloqueadosResultadoPadraoPisCofins;

// #endregion PIS/COFINS

interface IRegraEscolhaCstResultadoContext {
  validador: boolean;
  camposEscondidosResultadoIcms: ICamposEscondidosResultadoIcms;
  camposBloqueadosResultadoIcms: ICamposBloqueadosResultadoIcms;

  camposEscondidosResultadoIpi: ICamposEscondidosResultadoIpi;
  camposBloqueadosResultadoIpi: ICamposBloqueadosResultadoIpi;

  camposEscondidosResultadoPisCofins: ICamposEscondidosResultadoPisCofins;
  camposBloqueadosResultadoPisCofins: ICamposBloqueadosResultadoPisCofins;

  obterVisibilidadeBloqueioCampos(): void;
}

const RegraEscolhaCstResultadoContext =
  createContext<IRegraEscolhaCstResultadoContext>(
    {} as IRegraEscolhaCstResultadoContext
  );

const RegraEscolhaCstResultadoHook: React.FC<IPadraoProps> = ({ children }) => {
  const { formRef } = UseForm();

  // #region ICMS

  const [camposEscondidosResultadoIcms, setCamposEscondidosResultadoIcms] =
    useState(camposEscondidosResultadoPadraoIcms);

  const [camposBloqueadosResultadoIcms, setCamposBloqueadosResultadoIcms] =
    useState(camposBloqueadosResultadoPadraoIcms);

  const handleObterVisibilidadeBloqueioCamposPorResultadoIcms =
    useCallback(() => {
      const retornoIcms = IcmsObterVisibilidadeBloqueioCamposPorCst({
        cst:
          formRef.current?.getFieldValueNomeObjeto(
            'regraEscolhaCstResultado.codigoSituacaoTributariaIcms'
          )?.codigo || '',
        calcularIcms: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularIcms'
        ),
        calcularIcmsEfetivo: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularIcmsEfetivo'
        ),
        calcularIcmsSubstituto: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularIcmsSubstituto'
        ),
        calcularIcmsSt: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularSt'
        ),
        calcularIcmsStRetido: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularIcmsStRetido'
        ),
        calcularCreditoIcmsSn: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularCreditoIcmsSn'
        ),
        calcularDesoneracaoIcms: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularDesoneracaoIcms'
        ),
      });

      const motivoDesoneracaoIcms = formRef.current?.getFieldValue(
        'regraEscolhaCstResultado.motivoDesoneracaoIcms'
      );

      if (!motivoDesoneracaoIcms) {
        retornoIcms.bloqueado.calcularDesoneracaoIcms = true;
        retornoIcms.bloqueado.deduzirDesoneracaoIcms = true;

        formRef.current.setFieldValue(
          'regraEscolhaCstResultado.calcularDesoneracaoIcms',
          null
        );
        formRef.current.setFieldValue(
          'regraEscolhaCstResultado.deduzirDesoneracaoIcms',
          null
        );
      }

      setCamposEscondidosResultadoIcms({
        calcularIcms: retornoIcms.escondido.calcularIcms,
        calcularCreditoIcmsSn: retornoIcms.escondido.calcularCreditoIcmsSn,
        calcularSt: retornoIcms.escondido.calcularIcmsSt,
        calcularIcmsStRetido: retornoIcms.escondido.calcularIcmsStRetido,
        calcularIcmsEfetivo: retornoIcms.escondido.calcularIcmsEfetivo,
        calcularIcmsSubstituto: retornoIcms.escondido.calcularIcmsSubstituto,

        utilizaIcmsOperacaoInterestadual: false,
        calcularIcmsOperacaoInterestadual: false,

        modalidadeBaseCalculoIcms:
          retornoIcms.escondido.modalidadeBaseCalculoIcms,
        modalidadeBaseCalculoIcmsSt:
          retornoIcms.escondido.modalidadeBaseCalculoIcmsSt,

        motivoDesoneracaoIcms: retornoIcms.escondido.motivoDesoneracaoIcms,
        calcularDesoneracaoIcms: retornoIcms.escondido.calcularDesoneracaoIcms,
        deduzirDesoneracaoIcms: retornoIcms.escondido.deduzirDesoneracaoIcms,
      });
      setCamposBloqueadosResultadoIcms({
        calcularIcms: retornoIcms.bloqueado.calcularIcms,
        calcularCreditoIcmsSn: retornoIcms.bloqueado.calcularCreditoIcmsSn,
        calcularSt: retornoIcms.bloqueado.calcularIcmsSt,
        calcularIcmsStRetido: retornoIcms.bloqueado.calcularIcmsStRetido,
        calcularIcmsEfetivo: retornoIcms.bloqueado.calcularIcmsEfetivo,
        calcularIcmsSubstituto: retornoIcms.bloqueado.calcularIcmsSubstituto,

        utilizaIcmsOperacaoInterestadual: false,
        calcularIcmsOperacaoInterestadual: false,

        modalidadeBaseCalculoIcms:
          retornoIcms.bloqueado.modalidadeBaseCalculoIcms,
        modalidadeBaseCalculoIcmsSt:
          retornoIcms.bloqueado.modalidadeBaseCalculoIcmsSt,

        motivoDesoneracaoIcms: retornoIcms.bloqueado.motivoDesoneracaoIcms,
        calcularDesoneracaoIcms: retornoIcms.bloqueado.calcularDesoneracaoIcms,
        deduzirDesoneracaoIcms: retornoIcms.bloqueado.deduzirDesoneracaoIcms,
      });
    }, [formRef]);

  // #endregion ICMS

  // #region IPI

  const [camposEscondidosResultadoIpi, setCamposEscondidosResultadoIpi] =
    useState(camposEscondidosResultadoPadraoIpi);

  const [camposBloqueadosResultadoIpi, setCamposBloqueadosResultadoIpi] =
    useState(camposBloqueadosResultadoPadraoIpi);

  const handleObterVisibilidadeBloqueioCamposPorResultadoIpi =
    useCallback(() => {
      const listaModeloDocumento = formRef.current?.getFieldValue(
        'listaRegraEscolhaCstModeloDocumento'
      );

      if (
        listaModeloDocumento &&
        listaModeloDocumento.listaValor &&
        listaModeloDocumento.listaValor.length === 1 &&
        listaModeloDocumento.listaValor[0].codigo === '65'
      ) {
        setCamposEscondidosResultadoIpi({
          codigoSituacaoTributariaIpi: true,
          ipiCodigoEnquadramentoLegal: true,
          calcularIpi: true,

          baseLegalIpi: true,
        });
        setCamposBloqueadosResultadoIpi({
          codigoSituacaoTributariaIpi: true,
          ipiCodigoEnquadramentoLegal: true,
          calcularIpi: true,

          baseLegalIpi: true,
        });
      } else {
        const retornoIpi = IpiObterVisibilidadeBloqueioCamposPorCst({
          cst:
            formRef.current?.getFieldValueNomeObjeto(
              'regraEscolhaCstResultado.codigoSituacaoTributariaIpi'
            )?.codigo || '',
          calcular: formRef.current?.getFieldValue(
            'regraEscolhaCstResultado.calcularIpi'
          ),
          tipoCalculo: null,
        });

        setCamposEscondidosResultadoIpi({
          codigoSituacaoTributariaIpi: false,
          ipiCodigoEnquadramentoLegal:
            retornoIpi.escondido.ipiCodigoEnquadramentoLegal,
          calcularIpi: retornoIpi.escondido.calcular,

          baseLegalIpi: false,
        });
        setCamposBloqueadosResultadoIpi({
          codigoSituacaoTributariaIpi: false,
          ipiCodigoEnquadramentoLegal:
            retornoIpi.bloqueado.ipiCodigoEnquadramentoLegal,
          calcularIpi: retornoIpi.bloqueado.calcular,

          baseLegalIpi: false,
        });
      }
    }, [formRef]);

  // #endregion IPI

  // #region PIS/COFINS

  const [
    camposEscondidosResultadoPisCofins,
    setCamposEscondidosResultadoPisCofins,
  ] = useState(camposEscondidosResultadoPadraoPisCofins);

  const [
    camposBloqueadosResultadoPisCofins,
    setCamposBloqueadosResultadoPisCofins,
  ] = useState(camposBloqueadosResultadoPadraoPisCofins);

  const handleObterVisibilidadeBloqueioCamposPorResultadoPisCofins =
    useCallback(() => {
      const retornoPisCofins = PisCofinsObterVisibilidadeBloqueioCamposPorCst({
        cst:
          formRef.current?.getFieldValueNomeObjeto(
            'regraEscolhaCstResultado.codigoSituacaoTributariaPisCofins'
          )?.codigo || '',
        calcular: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularPisCofins'
        ),
        calcularRetido: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularPisCofinsRetido'
        ),
        tipoCalculo: null,
      });

      setCamposEscondidosResultadoPisCofins({
        codigoSituacaoTributariaPisCofins: false,
        calcularPisCofins: retornoPisCofins.escondido.calcular,
        calcularPisCofinsRetido: retornoPisCofins.escondido.calcularRetido,
      });
      setCamposBloqueadosResultadoPisCofins({
        codigoSituacaoTributariaPisCofins: false,
        calcularPisCofins: retornoPisCofins.bloqueado.calcular,
        calcularPisCofinsRetido: retornoPisCofins.bloqueado.calcularRetido,
      });
    }, [formRef]);

  // #endregion PIS/COFINS

  // #region CTE

  const handleObterVisibilidadeBloqueioCamposPorResultadoCte =
    useCallback(() => {
      const listaRegimeTributario = formRef.current?.getFieldValue(
        'listaRegraEscolhaCstRegimeTributarioEmitente'
      );

      const retornoCte = IcmsCteObterVisibilidadeBloqueioCamposPorCst({
        cst:
          formRef.current?.getFieldValueNomeObjeto(
            'regraEscolhaCstResultado.codigoSituacaoTributariaIcms'
          )?.codigo || '',
        calcularIcms: formRef.current?.getFieldValue(
          'regraEscolhaCstResultado.calcularIcms'
        ),
        regimeTributario:
          listaRegimeTributario &&
          listaRegimeTributario.listaValor &&
          listaRegimeTributario.listaValor.length > 0
            ? listaRegimeTributario.listaValor[0].descricao
            : null,
      });

      setCamposEscondidosResultadoIcms({
        calcularIcms: !retornoCte.visivel.calcularIcms,
        calcularCreditoIcmsSn: true,
        calcularSt: true,
        calcularIcmsStRetido: true,
        calcularIcmsEfetivo: true,
        calcularIcmsSubstituto: true,

        utilizaIcmsOperacaoInterestadual: true,
        calcularIcmsOperacaoInterestadual: true,

        modalidadeBaseCalculoIcms: true,
        modalidadeBaseCalculoIcmsSt: true,

        motivoDesoneracaoIcms: true,
        calcularDesoneracaoIcms: true,
        deduzirDesoneracaoIcms: true,
      });
      setCamposBloqueadosResultadoIcms({
        calcularIcms: retornoCte.bloqueado.calcularIcms,
        calcularCreditoIcmsSn: true,
        calcularSt: true,
        calcularIcmsStRetido: true,
        calcularIcmsEfetivo: true,
        calcularIcmsSubstituto: true,

        utilizaIcmsOperacaoInterestadual: true,
        calcularIcmsOperacaoInterestadual: true,

        modalidadeBaseCalculoIcms: true,
        modalidadeBaseCalculoIcmsSt: true,

        motivoDesoneracaoIcms: true,
        calcularDesoneracaoIcms: true,
        deduzirDesoneracaoIcms: true,
      });

      setCamposEscondidosResultadoIpi({
        codigoSituacaoTributariaIpi: true,
        ipiCodigoEnquadramentoLegal: true,
        calcularIpi: true,

        baseLegalIpi: true,
      });
      setCamposBloqueadosResultadoIpi({
        codigoSituacaoTributariaIpi: true,
        ipiCodigoEnquadramentoLegal: true,
        calcularIpi: true,

        baseLegalIpi: true,
      });

      setCamposEscondidosResultadoPisCofins({
        codigoSituacaoTributariaPisCofins: true,
        calcularPisCofins: true,
        calcularPisCofinsRetido: true,
      });
      setCamposBloqueadosResultadoPisCofins({
        codigoSituacaoTributariaPisCofins: true,
        calcularPisCofins: true,
        calcularPisCofinsRetido: true,
      });
    }, [formRef]);

  // #endregion CTE

  const handleObterVisibilidadeBloqueioCampos = useCallback(() => {
    const listaModeloDocumento = formRef.current?.getFieldValue(
      'listaRegraEscolhaCstModeloDocumento'
    );

    const possuiModeloDocumentoCte = listaModeloDocumento?.listaValor?.some(
      (valor: any) =>
        valor.tipoDocumentoFiscal ===
        ModeloDocumentoTipoEnum.conhecimentoTransporte
    );

    if (possuiModeloDocumentoCte) {
      handleObterVisibilidadeBloqueioCamposPorResultadoCte();
    } else {
      handleObterVisibilidadeBloqueioCamposPorResultadoIcms();
      handleObterVisibilidadeBloqueioCamposPorResultadoIpi();
      handleObterVisibilidadeBloqueioCamposPorResultadoPisCofins();
    }
  }, [
    formRef,
    handleObterVisibilidadeBloqueioCamposPorResultadoCte,
    handleObterVisibilidadeBloqueioCamposPorResultadoIcms,
    handleObterVisibilidadeBloqueioCamposPorResultadoIpi,
    handleObterVisibilidadeBloqueioCamposPorResultadoPisCofins,
  ]);

  return (
    <RegraEscolhaCstResultadoContext.Provider
      value={{
        camposEscondidosResultadoIcms,
        camposBloqueadosResultadoIcms,

        camposEscondidosResultadoIpi,
        camposBloqueadosResultadoIpi,

        camposEscondidosResultadoPisCofins,
        camposBloqueadosResultadoPisCofins,

        obterVisibilidadeBloqueioCampos: handleObterVisibilidadeBloqueioCampos,

        validador: true,
      }}
    >
      {children}
    </RegraEscolhaCstResultadoContext.Provider>
  );
};

function UseRegraEscolhaCstResultado(): Omit<
  IRegraEscolhaCstResultadoContext,
  'validador'
> {
  const context = useContext(RegraEscolhaCstResultadoContext);

  if (!context.validador) {
    throw new Error(
      'UseRegraEscolhaCstResultado deve ser usado com um RegraEscolhaCstResultadoHook'
    );
  }

  return context;
}

export { RegraEscolhaCstResultadoHook, UseRegraEscolhaCstResultado };
