import { IEmpresaSimplo7ValoresAlterar } from '@elogestor/util';
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormCia } from '@elogestor/unformcia';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { TbHelpOctagon } from 'react-icons/tb/index.mjs';
import { BtnContainer } from './styles';
import IMain from '../../../../../Componentes/Detalhe/Interface/IMain';
import { UsePermissoes } from '../../../../../Hooks/Permissoes';
import TextoLoadingSalvar from '../../../../../Componentes/TextoLoadingSalvar';
import { UseRota } from '../../../../../Componentes/Detalhe/Hooks/RotasContext';
import Divisor from '../../../../../Componentes/Divisor';
import Input from '../../../../../Componentes/Inputs/Input';
import { UseForm } from '../../../../../Componentes/Detalhe/Hooks/FormContext';
import InputTag from '../../../../../Componentes/Inputs/TagsInput/InputTag';
import EmpresaSimplo7ConsultarSituacoesComunicador from '../../../../../Comunicador/Configuracao/Integracao/Simplo7/Comunicador/EmpresaSimplo7ConsultarSituacoesComunicador';
import InputInteiroNulavel from '../../../../../Componentes/Inputs/InputInteiroNulavel';
import InputAutoCompleteRegraEscolhaTabelaPreco from '../../../../../Componentes/Inputs/AutoComplete/Comercial/InputAutoCompleteRegraEscolhaTabelaPreco';

type IProps = IMain<IEmpresaSimplo7ValoresAlterar>;

type Situacao = {
  Wsstatuspedido: {
    nome: string;
    id: string;
    ordem: number;
  };
};

const Main: React.FC<IProps> = ({
  idEditar,
  dadosRecuperados,
  dadosDuplicados,
  onClickSalvar,
  dadosObrigatorios,
  dadosPadrao,
}) => {
  const { permissoes } = UsePermissoes();

  const { ConfiguracaoIntegracaoEmpresaSimplo7: permissao } = permissoes;

  const [showSituacoes, setShowSituacoes] = React.useState(false);

  const [situacoes, setSituacoes] = useState<Situacao[]>([]);

  const navigate = useNavigate();

  const rotas = UseRota();

  const {
    formRef,

    loading,

    inputRefFocus,

    refresh,

    handleSubmit,

    handleCarregarDados,

    setIdDetalheRegistro,
  } = UseForm();

  const urlBase = formRef.current?.getFieldValue('urlBase');

  const appKey = formRef.current?.getFieldValue('appKey');

  useEffect(() => {
    setIdDetalheRegistro(idEditar);
  }, [idEditar, setIdDetalheRegistro]);

  useEffect(() => {
    handleCarregarDados({
      dadosObrigatorios,
      dadosRecuperados,
    });
  }, [
    dadosDuplicados,
    dadosObrigatorios,
    dadosPadrao,
    dadosRecuperados,
    handleCarregarDados,
  ]);

  const handleConsultarSituacoes = useCallback(async () => {
    if (situacoes.length > 0) {
      setShowSituacoes(true);

      return;
    }

    const response = await EmpresaSimplo7ConsultarSituacoesComunicador.index();

    if (response) {
      setSituacoes(response);

      setShowSituacoes(true);
    } else {
      setShowSituacoes(true);

      setSituacoes([
        {
          Wsstatuspedido: {
            nome: 'Nenhuma Situação Encontrada',

            id: '',

            ordem: 0,
          },
        },
      ]);
    }
  }, [situacoes.length]);

  const handleClickSalvar = useCallback(async () => {
    const data = formRef.current?.getData();

    const { erro, id } = await handleSubmit(data);

    if (erro) return;

    if (onClickSalvar) {
      onClickSalvar({ ...formRef.current?.getData(), id });
    } else {
      navigate(rotas.listagem);
    }
  }, [formRef, handleSubmit, navigate, onClickSalvar, rotas.listagem]);

  return (
    <FormCia ref={formRef}>
      <Divisor>
        <Row>
          <Col xl={12} lg={12}>
            <Input
              ref={inputRefFocus}
              label="urlBase"
              name="urlBase"
              required
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12}>
            <Input
              ref={inputRefFocus}
              label="appKey"
              name="appKey"
              required
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={3} lg={3}>
            <InputInteiroNulavel
              label="Tempo Sinc. Produtos (Min)"
              name="tempoSincronizarProduto"
              onChange={refresh}
              disabled
            />
          </Col>
          <Col xl={3} lg={3}>
            <InputInteiroNulavel
              label="Tempo Sinc. Estoque (Min)"
              name="tempoSincronizarEstoque"
              onChange={refresh}
            />
          </Col>
          <Col xl={3} lg={3}>
            <InputInteiroNulavel
              label="Tempo Sinc. Pedidos (Min)"
              name="tempoSincronizarPedido"
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={3} lg={3}>
            <InputInteiroNulavel
              label="Tempo Sinc. Preços Venda (Min)"
              name="tempoSincronizarValorVenda"
              onChange={refresh}
            />
          </Col>
          <Col xl={9} lg={9}>
            <InputAutoCompleteRegraEscolhaTabelaPreco
              label="Regra de Escolha da Tabela de Preço"
              name="idRegraEscolhaTabelaPreco"
              nomeObjeto="regraEscolhaTabelaPreco"
              permitirAdicionar={false}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12}>
            <InputTag
              label="Situações Integrar (id no Simplo7)"
              name="listaEmpresaSimplo7SituacaoIntegrar"
              nomeCampo="idSituacaoSimplo7"
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12}>
            <InputTag
              label="Situações Cancelar (id no Simplo7)"
              name="listaEmpresaSimplo7SituacaoCancelar"
              nomeCampo="idSituacaoSimplo7"
              onChange={refresh}
            />
          </Col>
        </Row>

        <Row>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              style={{
                display: 'flex',

                alignSelf: 'flex-end',

                alignItems: 'center',
              }}
              onClick={
                showSituacoes
                  ? () => setShowSituacoes(false)
                  : handleConsultarSituacoes
              }
            >
              Consultar Situações
              <TbHelpOctagon size={20} style={{ marginLeft: '5px' }} />
            </Button>

            <div
              style={{
                marginTop: '10px',

                display: showSituacoes ? 'flex' : 'none',

                alignItems: 'center',

                flexDirection: 'row',

                border: '1px solid #ccc',

                borderRadius: '5px',

                padding: '10px',
              }}
            >
              {situacoes?.length > 0 &&
                situacoes

                  .sort(
                    (a, b) => a.Wsstatuspedido.ordem - b.Wsstatuspedido.ordem
                  )

                  .map((situacao, i) => (
                    <h5 style={{ margin: 0 }} key={i}>
                      <Badge style={{ marginRight: '5px' }}>
                        {situacao?.Wsstatuspedido?.nome}
                      </Badge>
                    </h5>
                  ))}
            </div>
          </Col>
        </Row>

        <Row>
          <BtnContainer>
            <button
              type="button"
              onClick={handleClickSalvar}
              className="btn-padrao btn-verde"
              disabled={
                loading ||
                !permissao?.altera ||
                !permissao?.inclui ||
                !urlBase ||
                !appKey
              }
            >
              <TextoLoadingSalvar loading={loading} />
            </button>
          </BtnContainer>
        </Row>
      </Divisor>
    </FormCia>
  );
};

export default Main;
