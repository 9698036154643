import { FormatarDataHoraCompletaParaPtBr } from '@elogestor/util';
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Modal, Container, Button, Row, Col } from 'react-bootstrap';
import { FormCia } from '@elogestor/unformcia';
import { isAfter, parseISO } from 'date-fns';
import { RiDeleteBin6Line, RiPassValidLine } from 'react-icons/ri/index.mjs';
import { IoMdReturnLeft } from 'react-icons/io/index.mjs';
import { BiSave } from 'react-icons/bi/index.mjs';
import { UsePermissoes } from '../../../../../../../../Hooks/Permissoes';
import Divisor from '../../../../../../../../Componentes/Divisor';
import JanelaDetalhe from '../../../../../../../../Componentes/JanelaDetalhe';
import InputLabel from '../../../../../../../../Componentes/Inputs/InputLabel';
import InputSenha from '../../../../../../../../Componentes/Inputs/InputSenha';
import { UseListaDetalheForm } from '../../../../../../../../Hooks/ListaDetalheJanela/ListaDetalheFormContext';
import TratarErros from '../../../../../../../../Util/Erro/TratarErros';
import ToastErro from '../../../../../../../../Util/Toasts/ToastErro';
import Input from '../../../../../../../../Componentes/Inputs/Input';
import ButtonDeletar from '../../../../../../../../Componentes/SubHeaderGeral/BtnDeletar';
import { UseReactSizeMeBodyHook } from '../../../../../../../../Hooks/ReactSizeMeBodyHook';
import ToastSucesso from '../../../../../../../../Util/Toasts/ToastSucesso';
import TextoLoading from '../../../../../../../../Componentes/TextoLoading';
import LoadingDiv from '../../../../../../../../Componentes/LoadingDiv';
import PortadorBoletoCertificadoUploadComunicador from '../../../../../../../../Comunicador/Financeiro/Geral/Portador/Certificado/Comunicador/PortadorBoletoCertificadoUploadComunicador';
import { UseForm } from '../../../../../../../../Componentes/Detalhe/Hooks/FormContext';

interface IApiCertificadoModal {
  onSalvarFormModal(): void;
  onFecharFormModal(): void;
}

const ApiCertificadoDetalhe: React.FC<IApiCertificadoModal> = ({
  onFecharFormModal,
  onSalvarFormModal,
}) => {
  const { telaPequena } = UseReactSizeMeBodyHook();
  const { permissoes } = UsePermissoes();
  const { FinanceiroGeralPortador: permissao } = permissoes;
  const {
    formRefDetalhe,
    loading,
    handleCarregarDados,
    handleSubmit,
    handleValidar,
    setLoading,
    refresh,
    inputRefFocus,
  } = UseListaDetalheForm();
  const formPrincipal = UseForm();

  const [certificadoValido, setCertificadValido] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const possuiCertificado = formRefDetalhe.current?.getFieldValue(
    'emitidoParaCertificadoItau'
  );

  const dataHoraValidadeFinal = formRefDetalhe.current?.getFieldValue(
    'dataHoraValidadeFinalCertificadoItau'
  );
  const certificadoVencido = dataHoraValidadeFinal
    ? !isAfter(parseISO(dataHoraValidadeFinal), new Date())
    : false;

  const permiteValidar =
    !formRefDetalhe.current
      ?.getFieldValue('senhaCertificado')
      .IsNullOrEmpty() &&
    inputFileRef.current?.files &&
    inputFileRef.current.files[0];

  const handleClickSalvar = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files || !inputFileRef.current.files[0]) {
      ToastErro('Arquivo de Certificado Digital não informado!');
      return;
    }

    const data = formRefDetalhe.current?.getData();
    const { erro } = await handleSubmit(data, formRefDetalhe.current);
    if (erro) return;

    if (onSalvarFormModal) onSalvarFormModal();
  }, [formRefDetalhe, handleSubmit, onSalvarFormModal]);

  const handleValidarCertificado = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files || !inputFileRef.current.files[0]) return;

    try {
      setLoading(true);

      const formData = new FormData();

      const data = formRefDetalhe.current?.getData();
      if (!(await handleValidar(data, formRefDetalhe.current))) {
        setLoading(false);
        return;
      }

      const arquivo = inputFileRef.current.files[0] as any;
      const senhaCertificado =
        formRefDetalhe.current?.getFieldValue('senhaCertificado');
      const banco =
        formPrincipal.formRef.current?.getFieldValueNomeObjeto('banco');

      formData.append('certificado', arquivo, `codigoBanco:${banco.codigo}`);
      formData.append('senhaCertificado', senhaCertificado);

      const idDetalheRegistroPrincipal =
        formPrincipal.getIdDetalheRegistro() || '';

      const response = await PortadorBoletoCertificadoUploadComunicador.update({
        id: idDetalheRegistroPrincipal,
        params: formData,
      });

      await formRefDetalhe.current?.setSemExecutarEvento({
        emitidoParaCertificadoItau:
          response.informacoesCertificado.emitidoParaCertificadoItau,
        emitidoPorCertificadoItau:
          response.informacoesCertificado.emitidoPorCertificadoItau,
        dataHoraValidadeInicialCertificadoItau:
          response.informacoesCertificado
            .dataHoraValidadeInicialCertificadoItau,
        dataHoraValidadeFinalCertificadoItau:
          response.informacoesCertificado.dataHoraValidadeFinalCertificadoItau,
      });

      const dataValidade = formRefDetalhe.current?.getFieldValue(
        'dataHoraValidadeFinalCertificadoItau'
      );

      if (dataValidade && isAfter(parseISO(dataValidade), new Date())) {
        ToastSucesso('Certificado Válido!');
      } else if (dataValidade === null || dataValidade === undefined) {
        ToastErro('Data de validade do certificado não foi informada!');
      } else {
        ToastErro('Certificado Vencido!');
      }

      setCertificadValido(true);
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setCertificadValido(false);
      setLoading(false);
    }
  }, [formPrincipal, formRefDetalhe, handleValidar, setLoading]);

  const handleOnSelecionarCertificado = useCallback(async (): Promise<void> => {
    if (!inputFileRef.current?.files || !inputFileRef.current.files[0]) return;

    formRefDetalhe.current?.setFieldValue(
      'arquivo',
      inputFileRef.current.files[0].name
    );

    formRefDetalhe.current?.clearField('senhaCertificado');
  }, [formRefDetalhe]);

  const handleExcluirCertificado = useCallback(async (): Promise<void> => {
    formRefDetalhe.current?.clearField('arquivo');
    formRefDetalhe.current?.clearField('senhaCertificado');

    if (inputFileRef.current && inputFileRef.current.files) {
      inputFileRef.current.value = '';
    }
  }, [formRefDetalhe]);

  useEffect(() => {
    handleCarregarDados();
  }, [handleCarregarDados]);

  return (
    <JanelaDetalhe
      titulo="Selecione o arquivo do certificado digital"
      tamanho="lg"
      onFecharFormModal={onFecharFormModal}
    >
      <LoadingDiv isLoading={loading} isToggleJanela />

      <Modal.Body>
        <Container>
          <FormCia ref={formRefDetalhe}>
            <Divisor>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <input
                    type="file"
                    accept=".pfx, .p12"
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    onChange={handleOnSelecionarCertificado}
                  />
                  <button
                    type="button"
                    style={{
                      border: '0',
                      borderRadius: '5px',
                      color: '#fff',
                      cursor: 'pointer',
                      marginTop: '25px',
                      padding: '6px 20px',
                      height: '35px',
                    }}
                    className="btn-azul-escuro"
                    onClick={() => {
                      inputFileRef.current?.click();
                    }}
                    disabled={loading}
                  >
                    <TextoLoading loading={loading}>
                      Selecionar um arquivo &#187;
                    </TextoLoading>
                  </button>
                </Col>
              </Row>

              <Row>
                <Col
                  lg={10}
                  md={10}
                  sm={10}
                  xs={12}
                  style={{ paddingRight: telaPequena ? 15 : 2 }}
                >
                  <Input
                    label="Arquivo"
                    name="arquivo"
                    placeholder="Arquivo"
                    disabled
                  />
                </Col>
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingLeft: telaPequena ? 15 : 2,
                  }}
                >
                  <ButtonDeletar
                    type="button"
                    style={{ height: '35px', marginBottom: '5px' }}
                    onClick={handleExcluirCertificado}
                    disabled={loading}
                  >
                    <TextoLoading loading={loading}>
                      <RiDeleteBin6Line />
                    </TextoLoading>
                  </ButtonDeletar>
                </Col>
              </Row>

              <Row>
                <Col
                  lg={10}
                  md={10}
                  sm={10}
                  xs={12}
                  style={{ paddingRight: telaPequena ? 15 : 2 }}
                >
                  <InputSenha
                    ref={inputRefFocus}
                    autoComplete="off"
                    label="Senha"
                    name="senhaCertificado"
                    placeholder="Senha"
                    onChange={() => {
                      refresh();
                    }}
                  />
                </Col>
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={3}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingLeft: telaPequena ? 15 : 2,
                  }}
                >
                  <Button
                    type="button"
                    style={{
                      height: '35px',
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="btn-azul-escuro"
                    onClick={handleValidarCertificado}
                    disabled={!permiteValidar || loading}
                  >
                    <TextoLoading loading={loading}>
                      <RiPassValidLine />
                      <span style={{ marginLeft: 10 }}>Validar</span>
                    </TextoLoading>
                  </Button>
                </Col>
              </Row>

              <div
                style={{
                  display: possuiCertificado ? 'block' : 'none',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <span style={{ marginBottom: 5 }}>Seu Certificado:</span>
                </div>

                <div style={{ fontWeight: 'bold', display: 'flex' }}>
                  <span style={{ marginRight: 5 }}>Emitido Para:</span>
                  <InputLabel name="emitidoParaCertificadoItau" label="" />
                </div>

                <div style={{ fontWeight: 'bold', display: 'flex' }}>
                  <span style={{ marginRight: 5 }}>Emitido Por:</span>
                  <InputLabel name="emitidoPorCertificadoItau" label="" />
                </div>

                <div style={{ fontWeight: 'bold', display: 'flex' }}>
                  <span style={{ marginRight: 5 }}>Valido de</span>
                  <InputLabel
                    name="dataHoraValidadeInicialCertificadoItau"
                    label=""
                    formatarValor={(value) => {
                      return FormatarDataHoraCompletaParaPtBr(value);
                    }}
                  />
                  <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                  <InputLabel
                    name="dataHoraValidadeFinalCertificadoItau"
                    label=""
                    formatarValor={(value) => {
                      return FormatarDataHoraCompletaParaPtBr(value);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: !certificadoVencido ? 'block' : 'none',
                    fontWeight: 'bold',
                    color: 'green',
                  }}
                >
                  ESTE CERTIFICADO ESTÁ VÁLIDO
                </div>

                <div
                  style={{
                    display: certificadoVencido ? 'block' : 'none',
                    fontWeight: 'bold',
                    color: 'red',
                  }}
                >
                  ESTE CERTIFICADO ESTÁ VENCIDO
                </div>
              </div>
            </Divisor>
          </FormCia>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={onFecharFormModal}
              disabled={loading}
            >
              <TextoLoading loading={loading}>
                <IoMdReturnLeft />
                <span style={{ marginLeft: 10 }}>Voltar</span>
              </TextoLoading>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleClickSalvar}
              disabled={!permissao?.altera || loading || !certificadoValido}
            >
              <TextoLoading loading={loading}>
                <BiSave />
                <span style={{ marginLeft: 10 }}>Salvar</span>
              </TextoLoading>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </JanelaDetalhe>
  );
};

export default ApiCertificadoDetalhe;
