import {
  BooleanEnum,
  DestinadoEnum,
  FormatarEnum,
  RegimeTributarioEnum,
} from '@elogestor/util';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import {
  IoIosCloseCircleOutline,
  IoMdReturnLeft,
} from 'react-icons/io/index.mjs';
import { FormCia, IFormCiaHandles } from '@elogestor/unformcia';
import { MdFilterTiltShift } from 'react-icons/md/index.mjs';
import { useLocation } from 'react-router-dom';
import { UseTableDraggable } from '../../../../../Componentes/TableDraggable/HooksTable/TableDraggableContext';
import { UseLista } from '../../../../../Componentes/Lista/ListaHooks';
import { Modal } from './styles';
import Divisor from '../../../../../Componentes/Divisor';
import InputAutoCompleteTagNcm from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagNcm';
import Select from '../../../../../Componentes/Select';
import InputAutoCompleteTagTipoNota from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagTipoNota';
import InputAutoCompleteTagTipoNotaMotivo from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagTipoNotaMotivo';
import InputAutoCompleteTagOperacaoFiscal from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagOperacaoFiscal';
import InputAutoCompleteTagCodigoSituacaoTributariaIcms from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCodigoSituacaoTributariaIcms';
import InputAutoCompleteTagCodigoSituacaoTributariaIpi from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCodigoSituacaoTributariaIpi';
import InputAutoCompleteTagCodigoSituacaoTributariaPisCofins from '../../../../../Componentes/Inputs/AutoCompleteTag/Fiscal/InputAutoCompleteTagCodigoSituacaoTributariaPisCofins';
import BtnCloseButton from '../../../../../Componentes/Buttons/BtnCloseButton';
import InputAutoCompleteTagRegimeTributarioEnum from '../../../../../Componentes/Inputs/AutoCompleteTagEnum/InputAutoCompleteTagRegimeTributarioEnum';

const PesquisaAvancada: React.FC = () => {
  const { pathname: path } = useLocation();
  const { showPesquisaAvancada, fecharPesquisaAvancada } = UseLista();
  const { setParametros, parametros, alterarOverlayPesquisaAvancadaValores } =
    UseTableDraggable();

  const formRef = useRef<IFormCiaHandles>(null);
  const [listaIdTipoNota, setListaIdTipoNota] = useState<string[]>([]);

  const handleSubmit = useCallback(() => {
    const data = formRef.current?.getDataPesquisa();
    data.pesquisaAvancada = true;
    setParametros({ pagina: 1, pesquisaAvancada: data, textoPesquisa: '' });

    sessionStorage.setItem(
      '@EloGestorle:pesquisaAvancada',
      JSON.stringify({ path, ...data })
    );

    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada, path, setParametros]);

  const handleFecharPesquisaAvancada = useCallback(() => {
    fecharPesquisaAvancada();
  }, [fecharPesquisaAvancada]);

  useEffect(() => {
    if (!parametros?.pesquisaAvancada) {
      alterarOverlayPesquisaAvancadaValores([]);
    } else {
      const listaValoresParaExibirNoOverlay = [];

      const listaTipoNota = parametros.pesquisaAvancada?.listaTipoNota;
      if (listaTipoNota.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Tipo Nota',
          valor: listaTipoNota.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.descricao}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaTipoNotaMotivo =
        parametros.pesquisaAvancada?.listaTipoNotaMotivo;
      if (listaTipoNotaMotivo.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Motivo',
          valor: listaTipoNotaMotivo.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.descricao}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaNcm = parametros.pesquisaAvancada?.listaNcm;
      if (listaNcm.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'NCM',
          valor: listaNcm.reduce((acumulador: string, valorAtual: any) => {
            acumulador += `${valorAtual.codigo}; `;
            return acumulador;
          }, ''),
        });
      }

      const listaOperacaoFiscal =
        parametros.pesquisaAvancada?.listaOperacaoFiscal;
      if (listaOperacaoFiscal.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'CFOP',
          valor: listaOperacaoFiscal.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.cfop}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaCodigoSituacaoTributariaIcms =
        parametros.pesquisaAvancada?.listaCodigoSituacaoTributariaIcms;
      if (listaCodigoSituacaoTributariaIcms.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código ST. ICMS',
          valor: listaCodigoSituacaoTributariaIcms.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaCodigoSituacaoTributariaIpi =
        parametros.pesquisaAvancada?.listaCodigoSituacaoTributariaIpi;
      if (listaCodigoSituacaoTributariaIpi.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código ST. IPI',
          valor: listaCodigoSituacaoTributariaIpi.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaCodigoSituacaoTributariaPisCofins =
        parametros.pesquisaAvancada?.listaCodigoSituacaoTributariaPisCofins;
      if (listaCodigoSituacaoTributariaPisCofins.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Código ST. PIS COFINS',
          valor: listaCodigoSituacaoTributariaPisCofins.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${valorAtual.codigo}; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const listaRegimeTributario =
        parametros.pesquisaAvancada?.listaRegimeTributario;
      if (listaRegimeTributario.length > 0) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Regime Tributário',
          valor: listaRegimeTributario.reduce(
            (acumulador: string, valorAtual: any) => {
              acumulador += `${
                FormatarEnum({
                  enumObj: RegimeTributarioEnum,
                })[valorAtual.codigo]
              }; `;
              return acumulador;
            },
            ''
          ),
        });
      }

      const destinado = parametros.pesquisaAvancada?.destinado;
      if (!destinado) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Destinado',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Destinado',
          valor: destinado,
        });
      }

      const ativo = parametros.pesquisaAvancada?.ativo;
      if (!ativo) {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: 'Todos',
        });
      } else {
        listaValoresParaExibirNoOverlay.push({
          titulo: 'Ativo',
          valor: ativo === 'true' ? 'Sim' : 'Não',
        });
      }

      alterarOverlayPesquisaAvancadaValores(listaValoresParaExibirNoOverlay);
    }
  }, [alterarOverlayPesquisaAvancadaValores, parametros]);

  useEffect(() => {
    if (showPesquisaAvancada) {
      formRef.current?.setDataInicial({
        ativo: true,
        ...parametros.pesquisaAvancada,
      });
    }
  }, [parametros.pesquisaAvancada, showPesquisaAvancada]);

  const handleChangeListaItemAtualTipoNota = useCallback(
    (listaItemAtual: any[] | null) => {
      const listaId = listaItemAtual?.map((value) => value.id) || [];
      setListaIdTipoNota(listaId);
    },
    []
  );

  return (
    <Modal
      show={showPesquisaAvancada}
      onHide={handleFecharPesquisaAvancada}
      size="lg"
    >
      <Modal.Header
        style={{
          background: '#3397c4',
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Modal.Title>Pesquisa Avançada</Modal.Title>
        <BtnCloseButton type="button" onClick={handleFecharPesquisaAvancada}>
          <IoIosCloseCircleOutline />
        </BtnCloseButton>
      </Modal.Header>

      <Modal.Body>
        <Divisor>
          <FormCia ref={formRef}>
            <Row>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagTipoNota
                  label="Tipo Nota"
                  name="listaTipoNota"
                  onChangeListaItemAtual={({ listaItemAtual }) => {
                    handleChangeListaItemAtualTipoNota(listaItemAtual);
                  }}
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagTipoNotaMotivo
                  label="Motivo"
                  name="listaTipoNotaMotivo"
                  listaIdTipoNota={listaIdTipoNota}
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagNcm label="NCM" name="listaNcm" />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagOperacaoFiscal
                  label="CFOP"
                  name="listaOperacaoFiscal"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagCodigoSituacaoTributariaIcms
                  label="Código ST. ICMS"
                  name="listaCodigoSituacaoTributariaIcms"
                />
              </Col>
              <Col lg={6} md={12}>
                <InputAutoCompleteTagCodigoSituacaoTributariaIpi
                  label="Código ST. IPI"
                  name="listaCodigoSituacaoTributariaIpi"
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagCodigoSituacaoTributariaPisCofins
                  label="Código ST. PIS COFINS"
                  name="listaCodigoSituacaoTributariaPisCofins"
                />
              </Col>
              <Col lg={6} md={12}>
                <Select
                  name="destinado"
                  label="Destinado"
                  options={FormatarEnum({
                    enumObj: DestinadoEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <Select
                  name="ativo"
                  label="Ativo"
                  options={FormatarEnum({
                    enumObj: BooleanEnum,
                    todos: true,
                  })}
                />
              </Col>

              <Col lg={6} md={12}>
                <InputAutoCompleteTagRegimeTributarioEnum
                  label="Regimes Tributarios"
                  name="listaRegimeTributario"
                />
              </Col>
            </Row>
          </FormCia>
        </Divisor>
      </Modal.Body>

      <Modal.Footer>
        <div className="alinhar-direita espacamento-interno-para-esquerda-15">
          <Container style={{ display: 'flex' }}>
            <button
              type="button"
              className="btn-padrao btn-cinza-claro"
              onClick={handleFecharPesquisaAvancada}
            >
              <IoMdReturnLeft />
              <span style={{ marginLeft: 10 }}>Voltar</span>
            </button>

            <Button
              style={{
                marginLeft: 15,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              type="button"
              onClick={handleSubmit}
            >
              <MdFilterTiltShift />
              <span style={{ marginLeft: 10 }}>Filtrar</span>
            </Button>
          </Container>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PesquisaAvancada;
