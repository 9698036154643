import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/comissoes/comissoes-pedido-venda/comissoes-pedido-venda-por-faturamento-resumo-com-cliente';

interface IComissoesPedidoVendaPorFaturamentoResumoComClienteRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class ComissoesPedidoVendaPorFaturamentoResumoComClienteRelatorioComunicador {
  public async index(
    params: IComissoesPedidoVendaPorFaturamentoResumoComClienteRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new ComissoesPedidoVendaPorFaturamentoResumoComClienteRelatorioComunicador();
