import React from 'react';
import { ComunicadorProvider } from '../../../../../../Componentes/Detalhe/Hooks/ComunicadorContext';
import { ButtonsSubHeaderProvider } from '../../../../../../Componentes/Detalhe/Hooks/UseButtonsSubHeader';
import IPadraoProps from '../../../../../../Comum/Interface/IPadraoProps';
import FormHook from './FormHook';
import RotasHook from './RotasHook';
import SubHeaderHook from './SubHeaderHook';
import RegraEscolhaFormaPagamentoComunicador from '../../../../../../Comunicador/Financeiro/Geral/RegraEscolhaFormaPagamento/Comunicador/RegraEscolhaFormaPagamentoComunicador';

const RegraEscolhaFormaPagamentoHook: React.FC<IPadraoProps> = ({
  children,
}) => {
  return (
    <ComunicadorProvider
      value={{ comunicador: RegraEscolhaFormaPagamentoComunicador }}
    >
      <ButtonsSubHeaderProvider>
        <RotasHook>
          <FormHook>
            <SubHeaderHook>{children}</SubHeaderHook>
          </FormHook>
        </RotasHook>
      </ButtonsSubHeaderProvider>
    </ComunicadorProvider>
  );
};

export default RegraEscolhaFormaPagamentoHook;
