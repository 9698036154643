import api from '../../../../Comum/Services/Api';

const rota = 'contabil/geral/fechamento-periodo';

interface IFechamentoPeriodoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IFechamentoPeriodoComunicadorShow {
  id: string;
}

interface IFechamentoPeriodoComunicadorStore {
  params: any;
}

interface IFechamentoPeriodoComunicadorUpdate {
  params: {
    listaDadosAlterar: any[];
  };
}

interface IFechamentoPeriodoComunicadorDeletar {
  id: string;
}

class FechamentoPeriodoComunicador {
  public async index(params: IFechamentoPeriodoComunicadorIndex): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(params: IFechamentoPeriodoComunicadorShow): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(params: IFechamentoPeriodoComunicadorStore): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IFechamentoPeriodoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}`, params.params);

    return response.data;
  }

  public async delete(
    params: IFechamentoPeriodoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new FechamentoPeriodoComunicador();
