import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IPessoaPorDataCadastroRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IPessoaPorDataCadastroPDF {
  dados: IPessoaPorDataCadastroRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const PessoaPorDataCadastroPDF: React.FC<IPessoaPorDataCadastroPDF> = ({
  dados,
}) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="landscape">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {!dados.cabecalho.filtroTipoPessoa?.IsNullOrEmpty() && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tipo: '}</Text>
                      {dados.cabecalho.filtroTipoPessoa}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroEstado && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Estados: '}</Text>
                      {dados.cabecalho.filtroEstado}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroCidade && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Cidades: '}</Text>
                      {dados.cabecalho.filtroCidade}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRepresentante && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Representante: '}
                      </Text>
                      {dados.cabecalho.filtroRepresentante}
                    </Text>
                  </View>
                )}
                {(dados.cabecalho.filtroDataInicial ||
                  dados.cabecalho.filtroDataFinal) && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                      {dados.cabecalho.filtroDataInicial ?? '∞'} -{' '}
                      {dados.cabecalho.filtroDataFinal ?? 'Agora'}
                    </Text>
                  </View>
                )}
                {dados.cabecalho.filtroTag && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Tags: '}</Text>
                      {dados.cabecalho.filtroTag}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              marginBottom: 5,
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '5%' }}>
              <Text>Código</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '18%' }}>
              <Text>Nome/RazaoSocial</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '8%' }}>
              <Text>CNPJ/CPF</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '13%' }}>
              <Text>Inscr. Estadual</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>Telefone</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '14%' }}>
              <Text>Cidade</Text>
            </View>
            <View style={{ width: '1%' }} />

            <View style={{ width: '5%' }}>
              <Text>Estado</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '16%' }}>
              <Text>Email</Text>
            </View>
          </View>

          {dados.listaDados.map((grupoData) => {
            return (
              <View key={grupoData.chave}>
                <Text style={{ marginTop: 5, fontWeight: 'bold', fontSize: 9 }}>
                  {`Data Cadastro: ${FormatarDataParaPtBr(grupoData.chave)}`}
                </Text>

                {grupoData.listaPessoa.map((pessoa, index) => {
                  return (
                    <View
                      key={pessoa.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#E4E4E4' : '#fff',
                      }}
                    >
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: '5%', textAlign: 'right' }}>
                          <Text>{pessoa.codigo}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '18%' }}>
                          <Text>{pessoa.nomeRazaoSocial}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '8%' }}>
                          <Text>{pessoa.cnpjCpfVirtual}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '13%' }}>
                          <Text>{pessoa.inscricaoEstadual}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '14%' }}>
                          <Text>{pessoa.telefone}</Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '14%' }}>
                          <Text>
                            {pessoa.pessoaEnderecoPrincipal?.cidade?.nome}
                          </Text>
                        </View>

                        <View style={{ width: '1%' }} />

                        <View style={{ width: '5%' }}>
                          <Text>
                            {
                              pessoa.pessoaEnderecoPrincipal?.cidade?.estado
                                ?.sigla
                            }
                          </Text>
                        </View>
                        <View style={{ width: '1%' }} />

                        <View style={{ width: '16%' }}>
                          <Text>{pessoa.email}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}

                <View
                  style={{
                    borderBottom: '1pt dashed black',
                    width: '100%',
                    marginTop: 5,
                  }}
                >
                  <Text
                    style={{
                      textAlign: 'left',
                      fontSize: 9,
                      fontWeight: 'bold',
                    }}
                  >
                    Quantidade por Data:
                    {grupoData.somatorioDadosAgrupadosPorData}
                  </Text>
                </View>
              </View>
            );
          })}

          <View
            style={{
              width: '100%',
              marginTop: 5,
            }}
          >
            <Text
              style={{
                textAlign: 'left',
                fontSize: 9,
                fontWeight: 'bold',
              }}
            >
              Quantidade total por Data:
              {dados.somatorioGeralTotal}
            </Text>
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>

          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PessoaPorDataCadastroPDF;
