import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import {
  AppErro,
  IPedidoVendaItemValoresAlterar,
  ModeloImpressaoConsignadoEnum,
  ModeloImpressaoPedidoVendaEnum,
  SituacaoPedidoVendaEnum,
  TipoPedidoVendaEnum,
} from '@elogestor/util';
import { AiOutlinePrinter } from 'react-icons/ai/index.mjs';
import { BiDetail, BiMailSend } from 'react-icons/bi/index.mjs';
import { RiCheckboxCircleLine } from 'react-icons/ri/index.mjs';
import { MdCancel } from 'react-icons/md/index.mjs';
import { useNavigate } from 'react-router-dom';
import { IFormCiaHandles } from '@elogestor/unformcia';
import { GiMoneyStack } from 'react-icons/gi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseParametros } from '../../../../../../Hooks/ParametrosHook';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import ImpressaoPedidoVendaRetratoSemFotoProduto from './Impressao/Template/PedidoVendaRetratoSemFotoProdutoImpressao';
import ImpressaoPedidoVendaPaisagemSemFotoProduto from './Impressao/Template/PedidoVendaPaisagemSemFotoProdutoImpressao';
import ImpressaoPedidoVendaPaisagemComPesoVolumeFinanceiro from './Impressao/Template/PedidoVendaPaisagemComPesoVolumeFinanceiroImpressao';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';
import ToastSucesso from '../../../../../../Util/Toasts/ToastSucesso';
import PedidoVendaRetratoSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoSemFotoProdutoImpressao/PedidoVendaRetratoSemFotoProdutoImpressaoComunicador';
import PedidoVendaPaisagemSemFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaPaisagemSemFotoProdutoImpressao/PedidoVendaPaisagemSemFotoProdutoImpressaoComunicador';
import PedidoVendaAprovarComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaAprovarComunicador';
import PedidoVendaCancelarComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Comunicador/PedidoVendaCancelarComunicador';
import { UsePedidoVenda } from '../Hook/PedidoVendaHook';
import { UsePedidoVendaImportacaoOrcamento } from '../../../PedidoVendaImportacaoOrcamentoHook';
import { UseNotaFiscalSaidaImportacaoPedidoVenda } from '../../../NotaFiscalSaidaImportacaoPedidoVendaHook';
import PessoaContatoObterEmailComunicador from '../../../../../../Comunicador/Comercial/Pessoas/Pessoa/Contato/Comunicador/PessoaContatoObterEmailComunicador';
import JanelaEnvioEmail from '../../../../../../Componentes/JanelaEnvioEmail';
import ContaEmailObterDadosComunicador from '../../../../../../Comunicador/Configuracao/ContaEmail/Comunicador/ContaEmailObterDadosComunicador';
import FinanceiroDetalhe from './Financeiro/Detalhe';
import PedidoVendaRetratoComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoComFotoProdutoImpressao/PedidoVendaRetratoComFotoProdutoImpressaoComunicador';
import PedidoVendaPaisagemComFotoProdutoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaPaisagemComFotoProdutoImpressao/PedidoVendaPaisagemComFotoProdutoImpressaoComunicador';
import ImpressaoPedidoVendaRetratoComFotoProduto from './Impressao/Template/PedidoVendaRetratoComFotoProdutoImpressao';
import ImpressaoPedidoVendaPaisagemComFotoProduto from './Impressao/Template/PedidoVendaPaisagemComFotoProdutoImpressao';
import ImpressaoPedidoVendaRetratoComRepresentante from './Impressao/Template/PedidoVendaRetratoComRepresentanteImpressao';
import PedidoVendaRetratoComRepresentanteImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoComRepresentanteImpressao/PedidoVendaRetratoComRepresentanteImpressaoComunicador';
import ToastInfo from '../../../../../../Util/Toasts/ToastInfo';
import PedidoVendaRetratoComSemValoresItensImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoComSemValoresItensImpressao/PedidoVendaRetratoComSemValoresItensImpressaoComunicador';
import ImpressaoPedidoVendaRetratoComSemValoresItens from './Impressao/Template/PedidoVendaRetratoComSemValoresItensImpressao';
import ImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto from './Impressao/Template/PedidoVendaRetratoComSemValoresItensTranspFotoImpressao';
import ImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens from './Impressao/Template/PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao';
import ImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao from './Impressao/Template/PedidoVendaRetratoComFinanceiroTransporteImpressao';
import PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressao/PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicador';
import ToastAviso from '../../../../../../Util/Toasts/ToastAviso';
import PedidoVendaRetratoComFinanceiroTransporteImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoComFinanceiroTransporteImpressao/PedidoVendaRetratoComFinanceiroTransporteImpressaoComunicador';
import PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoRetiradaConsignadoImpressao/PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador';
import PedidoVendaRetratoRetiradaConsignadoImpressao from './Impressao/Template/PedidoVendaRetratoRetiradaConsignadoImpressao';
import PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao from './Impressao/Template/PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao';
import GerarOrdemProducao from './GerarOrdemProducao';
import PedidoVendaRetratoComSemValoresItensTranspFotoImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaRetratoComSemValoresItensTranspFotoImpressao/PedidoVendaRetratoComSemValoresItensTranspFotoImpressaoComunicador';
import IPermissoes from '../../../../../../Hooks/Interfaces/IPermissoes';
import PedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoComunicador from '../../../../../../Comunicador/Comercial/Vendas/PedidoVenda/Impressao/PedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoComunicador/PedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoComunicador';

interface IAcoesPedidoVenda {
  permissao?: IPermissao;
  permissaoHook?: IPermissoes;
}

const AcoesPedidoVenda: React.FC<IAcoesPedidoVenda> = ({
  permissao,
  permissaoHook,
}) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const {
    formRef,
    loading,
    setLoading,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSubmit,
  } = UseForm();
  const parametros = UseParametros();
  const { abrirJanela } = UseConfirmacao();
  const navigate = useNavigate();

  const { listaRepresentante } = UsePedidoVenda();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [showEnviarEmail, setShowEnviarEmail] = useState(false);

  const [imprimirFinanceiro, setImprimirFinanceiro] = useState(false);
  const [enviarPorEmailFinanceiro, setEnviarPorEmailFinanceiro] =
    useState(false);
  const [gerarNotaFiscalSaidaFinanceiro, setGerarNotaFiscalSaidaFinanceiro] =
    useState(false);
  const [gerarOrdemProducao, setGerarOrdemProducao] = useState(false);

  const {
    setTitulo: setTituloPedidoVendaImportacaoOrcamento,
    abrirPedidoVendaImportacaoOrcamento,
  } = UsePedidoVendaImportacaoOrcamento();

  const {
    setTitulo: setTituloNotaFiscalSaidaImportacaoPedidoVenda,
    abrirNotaFiscalSaidaImportacaoPedidoVenda,
  } = UseNotaFiscalSaidaImportacaoPedidoVenda();

  const situacaoPedidoVenda = formRef.current?.getFieldValue('situacao');
  const idPessoa = formRef.current?.getFieldValue('pedidoVendaPessoa.idPessoa');
  const numero = formRef.current?.getFieldValue('numero');
  const valorFinanceiro = formRef.current?.getFieldValue('valorFinanceiro');
  const listaPedidoVendaItem = formRef.current?.getFieldValue(
    'listaPedidoVendaItem'
  );
  const tipoPedidoVenda = formRef.current?.getFieldValue('tipoPedidoVenda');

  const handleValidar = useCallback(
    async (
      situacaoPedidoVendaValidar?: SituacaoPedidoVendaEnum
    ): Promise<boolean> => {
      try {
        formRef.current?.setErrors({});

        if (
          situacaoPedidoVendaValidar &&
          situacaoPedidoVendaValidar !== SituacaoPedidoVendaEnum.pendente
        )
          return true;

        if (parametros.RepresentanteObrigatorioPedidoVenda) {
          if (listaRepresentante.length < 1) {
            throw new AppErro({
              mensagem: 'No mínimo um Representante é obrigatório!',
            });
          }
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [
      formRef,
      listaRepresentante.length,
      parametros.RepresentanteObrigatorioPedidoVenda,
    ]
  );

  const handleSalvar = useCallback(
    async (
      situacaoPedidoVendaValidar?: SituacaoPedidoVendaEnum
    ): Promise<boolean> => {
      try {
        if (!handleValidar(situacaoPedidoVendaValidar)) return false;

        if (formRef.current?.validarSeAlterou()) {
          const data = formRef.current?.getData();
          const { erro } = await handleSubmit(data);
          return !erro;
        }

        return true;
      } catch (error) {
        TratarErros(error);
        return false;
      }
    },
    [formRef, handleSubmit, handleValidar]
  );

  const handleVisualizarOuObterBlob = useCallback(
    async (visualizarPdf: boolean): Promise<Blob | undefined> => {
      const idRegistro = getIdDetalheRegistro() || '';

      if (listaPedidoVendaItem && listaPedidoVendaItem.length === 0) {
        ToastInfo('Informe pelo menos um item para realizar a impressão!');
      } else if (tipoPedidoVenda === TipoPedidoVendaEnum.consignado) {
        switch (parametros.ModeloImpressaoConsignado) {
          case ModeloImpressaoConsignadoEnum.termica80mm:
            {
              const response =
                await PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador.show(
                  { params: { id: idRegistro } }
                );

              if (visualizarPdf) {
                await PedidoVendaRetratoRetiradaConsignadoImpressao.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await PedidoVendaRetratoRetiradaConsignadoImpressao.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoConsignadoEnum.A4Paisagem2Vias:
            {
              const response =
                await PedidoVendaRetratoRetiradaConsignadoImpressaoComunicador.show(
                  { params: { id: idRegistro } }
                );

              if (visualizarPdf) {
                await PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await PedidoVendaRetratoRetiradaConsignadoDuasViasImpressao.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          default:
            ToastAviso(
              'Selecione um Modelo de Impressão para a Retirada Consignada, nos Parâmetros!'
            );
        }
      } else {
        switch (parametros.ModeloParaImpressaoPedidoVenda) {
          case ModeloImpressaoPedidoVendaEnum.retratoSemFotoProduto:
            {
              const response =
                await PedidoVendaRetratoSemFotoProdutoImpressaoComunicador.show(
                  { id: idRegistro }
                );

              if (visualizarPdf) {
                await ImpressaoPedidoVendaRetratoSemFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoPedidoVendaRetratoSemFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoPedidoVendaEnum.paisagemSemFotoProduto: {
            const response =
              await PedidoVendaPaisagemSemFotoProdutoImpressaoComunicador.show({
                id: idRegistro,
              });

            if (visualizarPdf) {
              await ImpressaoPedidoVendaPaisagemSemFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaPaisagemSemFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoVendaEnum.paisagemComPesoVolumeFinanceiro: {
            const response =
              await PedidoVendaPaisagemComPesoVolumeFinanceiroImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoPedidoVendaPaisagemComPesoVolumeFinanceiro.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaPaisagemComPesoVolumeFinanceiro.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoVendaEnum.retratoComFotoProduto:
            {
              const response =
                await PedidoVendaRetratoComFotoProdutoImpressaoComunicador.show(
                  {
                    id: idRegistro,
                  }
                );

              if (visualizarPdf) {
                await ImpressaoPedidoVendaRetratoComFotoProduto.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoPedidoVendaRetratoComFotoProduto.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoPedidoVendaEnum.paisagemComFotoProduto: {
            const response =
              await PedidoVendaPaisagemComFotoProdutoImpressaoComunicador.show({
                id: idRegistro,
              });

            if (visualizarPdf) {
              await ImpressaoPedidoVendaPaisagemComFotoProduto.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaPaisagemComFotoProduto.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoVendaEnum.retratoComRepresentante:
            {
              const response =
                await PedidoVendaRetratoComRepresentanteImpressaoComunicador.show(
                  {
                    id: idRegistro,
                  }
                );

              if (visualizarPdf) {
                await ImpressaoPedidoVendaRetratoComRepresentante.handleVisualizarPdf(
                  response
                );
              } else {
                const blob =
                  await ImpressaoPedidoVendaRetratoComRepresentante.handleObterBlobPdf(
                    response
                  );

                return blob;
              }
            }
            break;

          case ModeloImpressaoPedidoVendaEnum.retratoComSemValoresItensTransporteFoto: {
            const response =
              await PedidoVendaRetratoComSemValoresItensTranspFotoImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            const resposta = await abrirJanela({
              titulo: <h2>Impressão Pedidos de Vendas</h2>,

              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Imprimir o Pedido de Venda com Valores nos Itens?
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (visualizarPdf) {
              await ImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto.handleVisualizarPdf(
                resposta,
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaRetratoComSemValoresItensTranspFoto.handleObterBlobPdf(
                  resposta,
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoVendaEnum.retratoComSemValoresItens: {
            const response =
              await PedidoVendaRetratoComSemValoresItensImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            const resposta = await abrirJanela({
              titulo: <h2>Impressão Pedidos de Vendas</h2>,

              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Imprimir o Pedido de Venda com Valores nos Itens?
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (visualizarPdf) {
              await ImpressaoPedidoVendaRetratoComSemValoresItens.handleVisualizarPdf(
                resposta,
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaRetratoComSemValoresItens.handleObterBlobPdf(
                  resposta,
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoVendaEnum.retratoComFotoProdutoComSemValoresItens: {
            const response =
              await PedidoVendaRetratoComFotoProdutoComSemValoresItensImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );
            const resposta = await abrirJanela({
              titulo: <h2>Impressão Pedidos de Vendas</h2>,

              mensagem: (
                <span style={{ fontSize: 18 }}>
                  Deseja Imprimir o Pedido de Venda com Valores?
                </span>
              ),
              cancelar: 'Não',
              confimar: 'Sim',
            });

            if (visualizarPdf) {
              await ImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens.handleVisualizarPdf(
                resposta,
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaRetratoComFotoProdutoComSemValoresItens.handleObterBlobPdf(
                  resposta,
                  response
                );

              return blob;
            }
            break;
          }

          case ModeloImpressaoPedidoVendaEnum.retratoComFinanceiroTransporte: {
            const response =
              await PedidoVendaRetratoComFinanceiroTransporteImpressaoComunicador.show(
                {
                  id: idRegistro,
                }
              );

            if (visualizarPdf) {
              await ImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao.handleVisualizarPdf(
                response
              );
            } else {
              const blob =
                await ImpressaoPedidoVendaRetratoComFinanceiroTransporteImpressao.handleObterBlobPdf(
                  response
                );

              return blob;
            }
            break;
          }

          default:
            ToastAviso(
              'Selecione um Modelo de Impressão para o Pedido de Venda, nos Parâmetros!'
            );
        }
      }
      return undefined;
    },
    [
      abrirJanela,
      getIdDetalheRegistro,
      listaPedidoVendaItem,
      parametros.ModeloImpressaoConsignado,
      parametros.ModeloParaImpressaoPedidoVenda,
      tipoPedidoVenda,
    ]
  );

  const handleImprimirFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      await handleVisualizarOuObterBlob(true);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [handleVisualizarOuObterBlob, setLoading]);

  const handleEnviarPorEmailFinanceiro = useCallback(async () => {
    setShowEnviarEmail(true);
  }, []);

  const handleGerarNotaFiscalSaidaFinanceiro = useCallback(async () => {
    try {
      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloNotaFiscalSaidaImportacaoPedidoVenda(
        'Gerar Nota Fiscal de Saída'
      );
      const response = await abrirNotaFiscalSaidaImportacaoPedidoVenda({
        idPessoa: String(idPessoa),
        idPedidoVenda: idRegistro,
      });

      if (response) {
        navigate({
          pathname: `/comercial/vendas/nota-fiscal-saida/detalhe/${response.id}`,
        });

        ToastSucesso(response.mensagem);
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirNotaFiscalSaidaImportacaoPedidoVenda,
    getIdDetalheRegistro,
    idPessoa,
    navigate,
    setLoading,
    setTituloNotaFiscalSaidaImportacaoPedidoVenda,
  ]);

  // #region Financeiro

  const handleFinanceiro = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    setShowFinanceiro(true);
  }, [handleSalvar]);

  const handleFinanceiroSalvarFormModal = useCallback(async () => {
    formRef.current?.setFieldValue('financeiroConferido', true);

    const data = formRef.current?.getData();

    await handleSubmit(data);

    setShowFinanceiro(false);

    if (imprimirFinanceiro) {
      await handleImprimirFinanceiro();
      setImprimirFinanceiro(false);
    }

    if (enviarPorEmailFinanceiro) {
      await handleEnviarPorEmailFinanceiro();
      setEnviarPorEmailFinanceiro(false);
    }

    if (gerarNotaFiscalSaidaFinanceiro) {
      await handleGerarNotaFiscalSaidaFinanceiro();
      setGerarNotaFiscalSaidaFinanceiro(false);
    }
  }, [
    enviarPorEmailFinanceiro,
    formRef,
    gerarNotaFiscalSaidaFinanceiro,
    handleEnviarPorEmailFinanceiro,
    handleGerarNotaFiscalSaidaFinanceiro,
    handleImprimirFinanceiro,
    handleSubmit,
    imprimirFinanceiro,
  ]);

  const handleFinanceiroFecharFormModal = useCallback(async () => {
    setShowFinanceiro(false);
  }, []);

  // #endregion Financeiro

  const handleImprimir = useCallback(async () => {
    try {
      if (!(await handleSalvar(situacaoPedidoVenda))) {
        return;
      }

      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setImprimirFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleImprimirFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleImprimirFinanceiro,
    handleSalvar,
    setLoading,
    situacaoPedidoVenda,
    valorFinanceiro,
  ]);

  const handleImprimirEtiquetasPedidoVenda = useCallback(async () => {
    try {
      setLoading(true);

      if (!(await handleSalvar())) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      window.open('/comercial/vendas/impressao-etiquetas-pedido-venda');
      localStorage.setItem('@EloGestorle:etiquetaPedidoVenda', idRegistro);

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [getIdDetalheRegistro, handleSalvar, setLoading]);

  // #region Email

  const handleEnviarPorEmail = useCallback(async () => {
    if (!(await handleSalvar())) {
      return;
    }

    // #region Financeiro

    const financeiroConferido = formRef.current?.getFieldValue(
      'financeiroConferido'
    );

    if (!financeiroConferido && valorFinanceiro > 0) {
      await handleFinanceiro();
      setLoading(false);
      setEnviarPorEmailFinanceiro(true);
      return;
    }

    // #endregion Financeiro

    await handleEnviarPorEmailFinanceiro();
  }, [
    formRef,
    handleEnviarPorEmailFinanceiro,
    handleFinanceiro,
    handleSalvar,
    setLoading,
    valorFinanceiro,
  ]);

  const handleOnCarregarDadosIniciaisEmail = useCallback(
    async (formRefEmail: React.RefObject<IFormCiaHandles>): Promise<void> => {
      const responseDestinatario =
        await PessoaContatoObterEmailComunicador.index({
          params: {
            listaIdPessoa: [idPessoa],
            direcionarBoletos: false,
            direcionarDocumentosFiscais: false,
            direcionarOrcamento: false,
            direcionarPedidoVenda: true,
            direcionarCotacaoCompra: false,
            direcionarPedidoCompra: false,
            direcionarOrdemServico: false,
          },
        });

      const listaDestinatario = responseDestinatario.map((valor: string) => {
        return { email: valor };
      });

      const responseRemetente = await ContaEmailObterDadosComunicador.index({
        params: {
          ativo: true,
          verificado: true,
          direcionarBoletos: false,
          direcionarDocumentosFiscais: false,
          direcionarOrcamento: false,
          direcionarPedidoVenda: true,
          direcionarCotacaoCompra: false,
          direcionarPedidoCompra: false,
          direcionarOrdemServico: false,
        },
      });

      let remetente;
      if (responseRemetente) remetente = { email: responseRemetente[0] };

      const anexo = await handleVisualizarOuObterBlob(false);

      await formRefEmail.current?.setDataInicial({
        assunto: `Pedido de Venda número: ${numero}`,
        copiaEmail: true,
        listaDestinatario,
        remetente,
        listaAnexo: [
          {
            nomeArquivo: `Pedido Venda ${numero}.pdf`,
            file: anexo,
            permitirExcluir: false,
          },
        ],
      });
    },
    [handleVisualizarOuObterBlob, idPessoa, numero]
  );

  // #endregion Email

  const handleAprovar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente aprovar esse Pedido de Venda?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response = await PedidoVendaAprovarComunicador.update({
        id: idRegistro,
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
  ]);

  const handleCancelar = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const resposta = await abrirJanela({
        titulo: <h2>Confirmação</h2>,
        mensagem: (
          <span style={{ fontSize: 20 }}>
            Deseja realmente cancelar esse Pedido de Venda?
          </span>
        ),
      });

      if (!resposta) {
        setLoading(false);
        return;
      }

      const idRegistro = getIdDetalheRegistro() || '';

      const response = await PedidoVendaCancelarComunicador.update({
        id: idRegistro,
      });

      ToastSucesso(response.mensagem);
      handleCarregarDados();
      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirJanela,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    setLoading,
  ]);

  const handleImportarOrcamentos = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }

      setLoading(true);

      const idRegistro = getIdDetalheRegistro() || '';

      setTituloPedidoVendaImportacaoOrcamento('Importar Orçamento');
      const response = await abrirPedidoVendaImportacaoOrcamento({
        idPessoa: String(idPessoa),
        idPedidoVenda: idRegistro,
      });

      if (response) {
        ToastSucesso(response.mensagem);
        handleCarregarDados();
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    abrirPedidoVendaImportacaoOrcamento,
    getIdDetalheRegistro,
    handleCarregarDados,
    handleSalvar,
    idPessoa,
    setLoading,
    setTituloPedidoVendaImportacaoOrcamento,
  ]);

  const handleGerarNotaFiscalSaida = useCallback(async () => {
    try {
      if (!(await handleSalvar())) {
        return;
      }
      setLoading(true);

      // #region Financeiro

      const financeiroConferido = formRef.current?.getFieldValue(
        'financeiroConferido'
      );

      if (!financeiroConferido && valorFinanceiro > 0) {
        await handleFinanceiro();
        setLoading(false);
        setGerarNotaFiscalSaidaFinanceiro(true);
        return;
      }

      // #endregion Financeiro

      await handleGerarNotaFiscalSaidaFinanceiro();

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [
    formRef,
    handleFinanceiro,
    handleGerarNotaFiscalSaidaFinanceiro,
    handleSalvar,
    setLoading,
    valorFinanceiro,
  ]);

  const handleGerarOrdemProducao = useCallback(async () => {
    setGerarOrdemProducao(!gerarOrdemProducao);
  }, [gerarOrdemProducao]);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || Number(valorFinanceiro) === 0}
          onClick={handleFinanceiro}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiMoneyStack style={{ marginRight: 5 }} />
            Financeiro
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleImprimir}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            loading || situacaoPedidoVenda !== SituacaoPedidoVendaEnum.aprovado
          }
          onClick={handleImprimirEtiquetasPedidoVenda}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <AiOutlinePrinter style={{ marginRight: 5 }} />
            Imprimir Etiquetas Pedido Venda
          </span>
        </Dropdown.Item>

        <Dropdown.Item disabled={loading} onClick={handleEnviarPorEmail}>
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiMailSend style={{ marginRight: 5 }} />
            Enviar por E-mail
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.aprovado ||
            !listaPedidoVendaItem ||
            listaPedidoVendaItem.length === 0
          }
          onClick={handleAprovar}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <RiCheckboxCircleLine style={{ marginRight: 5 }} />
            Aprovar
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            !permissaoHook.ComercialVendasOrcamento?.altera ||
            loading ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.cancelado ||
            tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
          }
          onClick={handleImportarOrcamentos}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Importar Orçamentos
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissaoHook.ComercialVendasNotaFiscalSaida?.altera ||
            !permissao?.altera ||
            loading ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.pendente ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.cancelado ||
            tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
          }
          onClick={handleGerarNotaFiscalSaida}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Nota Fiscal de Saída
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            listaPedidoVendaItem?.every(
              (item: IPedidoVendaItemValoresAlterar) =>
                item.quantidadeDisponivel === 0
            ) ||
            !permissaoHook.ManufaturaProducaoOrdemProducao?.altera ||
            !permissao?.altera ||
            loading ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.pendente ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.cancelado ||
            tipoPedidoVenda === TipoPedidoVendaEnum.preVenda
          }
          onClick={handleGerarOrdemProducao}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <BiDetail style={{ marginRight: 5 }} />
            Gerar Ordem de Produção
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={
            !permissao?.altera ||
            loading ||
            situacaoPedidoVenda === SituacaoPedidoVendaEnum.cancelado
          }
          onClick={handleCancelar}
        >
          <span style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
            <MdCancel style={{ marginRight: 5 }} />
            Cancelar
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {gerarOrdemProducao && (
        <GerarOrdemProducao
          show={gerarOrdemProducao}
          onClose={() => setGerarOrdemProducao(false)}
        />
      )}

      {showEnviarEmail && (
        <JanelaEnvioEmail
          titulo="Envio de Pedido de Venda por E-mail"
          mensagemPadrao={`Segue em anexo o Pedido de Venda número: ${numero}`}
          direcionarPedidoVenda
          onCarregarDadosInciais={handleOnCarregarDadosIniciaisEmail}
          onFecharFormModal={() => {
            setShowEnviarEmail(false);
          }}
        />
      )}

      {showFinanceiro && (
        <FinanceiroDetalhe
          onSalvarFormModal={handleFinanceiroSalvarFormModal}
          onFecharFormModal={handleFinanceiroFecharFormModal}
        />
      )}
    </>
  );
};

export default AcoesPedidoVenda;
