import api from '../../../../../../Comum/Services/Api';

const rota =
  'comercial/relatorio/vendas/nota-fiscal-saida/nota-fiscal-saida-por-estado-com-difal-e-fcp';

interface INotaFiscalSaidaPorEstadoComDifalEFcpRelatorioComunicadorIndex {
  params: {
    filtro: any;
  };
}

class NotaFiscalSaidaPorEstadoComDifalEFcpRelatorioComunicador {
  public async index(
    params: INotaFiscalSaidaPorEstadoComDifalEFcpRelatorioComunicadorIndex
  ): Promise<any> {
    const response = await api.post(rota, params.params);
    return response.data;
  }
}

export default new NotaFiscalSaidaPorEstadoComDifalEFcpRelatorioComunicador();
