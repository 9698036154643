import api from '../../../../../Comum/Services/Api';

const rota = 'financeiro/geral/regra-escolha-forma-pagamento';

interface IRegraEscolhaFormaPagamentoComunicadorIndex {
  params: {
    pesquisaAvancada?: object;
    order?: string;
    descAsc?: string;
    limite?: number;
    pagina?: number;
    textoPesquisa?: string;
  };
}

interface IRegraEscolhaFormaPagamentoComunicadorShow {
  id: string;
}

interface IRegraEscolhaFormaPagamentoComunicadorStore {
  params: any;
}

interface IRegraEscolhaFormaPagamentoComunicadorUpdate {
  id: string;
  params: any;
}

interface IRegraEscolhaFormaPagamentoComunicadorDeletar {
  id: string;
}

class RegraEscolhaFormaPagamentoComunicador {
  public async index(
    params: IRegraEscolhaFormaPagamentoComunicadorIndex
  ): Promise<any> {
    const response = await api.get(rota, { params: params.params });

    return response.data;
  }

  public async show(
    params: IRegraEscolhaFormaPagamentoComunicadorShow
  ): Promise<any> {
    const response = await api.get(`${rota}/${params.id}`);

    return response.data;
  }

  public async store(
    params: IRegraEscolhaFormaPagamentoComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, params.params);

    return response.data;
  }

  public async update(
    params: IRegraEscolhaFormaPagamentoComunicadorUpdate
  ): Promise<any> {
    const response = await api.put(`${rota}/${params.id}`, params.params);

    return response.data;
  }

  public async delete(
    params: IRegraEscolhaFormaPagamentoComunicadorDeletar
  ): Promise<any> {
    const response = await api.delete(`${rota}/${params.id}`);

    return response.data;
  }
}

export default new RegraEscolhaFormaPagamentoComunicador();
