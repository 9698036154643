import React from 'react';
import {
  Text,
  View,
  Document as _document,
  Page as _page,
  StyleSheet,
} from '@react-pdf/renderer';
import {
  FormatarDataParaPtBr,
  FormatarHoraParaPtBr,
  IEstoqueResumoCustoPorTipoEstoqueSpedRelatorio,
} from '@elogestor/util';
import { usuarioLogado } from '../../../../../../Hooks/Auth';

const Document: any = _document;
const Page: any = _page;

interface IEstoqueResumoCustoPorTipoEstoqueSpedPDF {
  dados: IEstoqueResumoCustoPorTipoEstoqueSpedRelatorio;
}

const styles = StyleSheet.create({
  pagina: {
    fontFamily: 'Tinos',
    padding: '25 15 25 15',
  },

  header: {
    position: 'relative',
  },

  data: {
    fontSize: 9,
  },
});

const EstoqueResumoCustoPorTipoEstoqueSpedRelatorioRelatorioPDF: React.FC<
  IEstoqueResumoCustoPorTipoEstoqueSpedPDF
> = ({ dados }) => {
  return (
    <Document>
      <Page style={styles.pagina} orientation="portrait">
        <View style={styles.header} fixed>
          <View style={{ width: '100%', alignItems: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.nomeEmpresa}
              </Text>
              <Text style={{ fontSize: 11 }}>
                {dados.cabecalho.tituloRelatorio}
              </Text>
            </View>
          </View>

          {dados.cabecalho.possuiFiltro && (
            <View
              style={{
                fontSize: 9,
                width: '100%',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: '6pt',
              }}
            >
              <View
                style={{
                  width: '10%',
                  height: '100%',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-start',
                  marginRight: '5px',
                }}
              >
                <Text>Filtros:</Text>
              </View>
              <View
                style={{
                  width: '90%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                {dados.cabecalho.filtroData && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>{'Data: '}</Text>
                      {dados.cabecalho.filtroData}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroRegimeTributario && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {'Regime Tributario: '}
                      </Text>
                      {dados.cabecalho.filtroRegimeTributario}
                    </Text>
                  </View>
                )}

                {dados.cabecalho.filtroSomenteProdutoComSaldo && (
                  <View>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        Somente Produtos com Saldo
                      </Text>
                    </Text>
                  </View>
                )}
              </View>
            </View>
          )}

          <View
            style={{
              position: 'absolute',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <Text
              style={{ fontSize: 9 }}
              render={({ pageNumber, totalPages }) => {
                return `Página ${pageNumber} de ${totalPages}`;
              }}
              fixed
            />
          </View>
        </View>

        <View style={{ fontSize: 8, width: '100%', flex: 1 }}>
          <View
            fixed
            style={{
              flexDirection: 'row',
              borderBottom: '2pt solid black',
              fontSize: 9,
              fontWeight: 'bold',
            }}
          >
            <View style={{ width: '10%', fontWeight: 'bold' }}>
              <Text>Cód SPED</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View style={{ width: '24%', fontWeight: 'bold' }}>
              <Text>Descrição</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{ width: '31%', fontWeight: 'bold', textAlign: 'right' }}
            >
              <Text>V.Custo Est. ICMS IPI</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{ width: '31%', fontWeight: 'bold', textAlign: 'right' }}
            >
              <Text>V. Custo Est. IR</Text>
            </View>

            <View style={{ width: '1%' }} />
          </View>

          {dados.listaDados.map((dado, index) => {
            return (
              <View key={index}>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    marginBottom: '5pt',
                    marginTop: '8pt',
                  }}
                >
                  <Text style={{ fontWeight: 'bold', fontSize: 9 }}>
                    {`Tipo de Estoque: ${dado.chave} `}
                  </Text>
                </View>

                {dado.listaEstoque.map((item, i) => (
                  <View
                    key={i}
                    style={{
                      flexDirection: 'row',
                      backgroundColor: i % 2 === 0 ? '#E4E4E4' : '#fff',
                    }}
                  >
                    <View style={{ width: '10%' }}>
                      <Text>{item.codigoSped}</Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '24%' }}>
                      <Text>{item.tipoSped}</Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '31%', textAlign: 'right' }}>
                      <Text>{item.valorCustoEstoqueICMSIPI}</Text>
                    </View>

                    <View style={{ width: '1%' }} />

                    <View style={{ width: '31%', textAlign: 'right' }}>
                      <Text>{item.valorCustoEstoqueIR}</Text>
                    </View>

                    <View style={{ width: '1%' }} />
                  </View>
                ))}

                <View
                  style={{
                    flexDirection: 'row',
                    borderBottom: '1pt dashed black',
                    marginTop: '5pt',
                    fontSize: 9,
                    fontWeight: 'bold',
                  }}
                >
                  <View style={{ width: '36%' }}>
                    <Text>Total Indicador de Tipo de Estoque</Text>
                  </View>

                  <View
                    style={{
                      width: '31%',
                      textAlign: 'right',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text>{String(dado.somatorioItensICMSIPI)}</Text>
                  </View>

                  <View style={{ width: '1%' }} />

                  <View
                    style={{
                      width: '31%',
                      textAlign: 'right',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Text>{String(dado.somatorioItensIR)}</Text>
                  </View>

                  <View style={{ width: '1%' }} />
                </View>
              </View>
            );
          })}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-start',
              marginBottom: '5pt',
              marginTop: '5pt',
              fontWeight: 'bold',
              fontSize: 9,
            }}
          >
            <View style={{ width: '36%' }}>
              <Text>Total Geral</Text>
            </View>

            <View
              style={{
                fontWeight: 'bold',
                width: '31%',
                textAlign: 'right',
                alignItems: 'flex-end',
              }}
            >
              <Text>{String(dados.totais.totalGeralICMSIPI)}</Text>
            </View>

            <View style={{ width: '1%' }} />

            <View
              style={{
                fontWeight: 'bold',
                width: '31%',
                textAlign: 'right',
                alignItems: 'flex-end',
              }}
            >
              <Text>{String(dados.totais.totalGeralIR)}</Text>
            </View>

            <View style={{ width: '1%' }} />
          </View>
        </View>

        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 9,
          }}
        >
          <Text>
            {`Impresso por ${usuarioLogado.nome} em ${FormatarDataParaPtBr(
              dados.cabecalho.dataHoraAtual
            )} às ${FormatarHoraParaPtBr(dados.cabecalho.dataHoraAtual)}`}
          </Text>
          <Text>https://www.elogestor.com.br</Text>
        </View>
      </Page>
    </Document>
  );
};

export default EstoqueResumoCustoPorTipoEstoqueSpedRelatorioRelatorioPDF;
