import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CgMoreO } from 'react-icons/cg/index.mjs';
import { v4 } from 'uuid';
import { CiMenuBurger } from 'react-icons/ci/index.mjs';
import { GiBreakingChain } from 'react-icons/gi/index.mjs';
import { UseForm } from '../../../../../../Componentes/Detalhe/Hooks/FormContext';
import SplitAcoes from '../../../../../../Componentes/SubHeaderGeral/SplitAcoes';
import TextoLoading from '../../../../../../Componentes/TextoLoading';
import IPermissao from '../../../../../../Hooks/Interfaces/IPermissao';
import { UseReactSizeMeBodyHook } from '../../../../../../Hooks/ReactSizeMeBodyHook';
import MenuSiTefDetalhe from './SiTefMenu/Detalhe';
import { UseSiTef } from '../../../FrenteCaixaPdv/Detalhe/AcoesFinalizarVenda/FinalizarVenda/Hooks/TefHook/SiTefHook';
import TratarErros from '../../../../../../Util/Erro/TratarErros';
import { UseConfirmacao } from '../../../../../../Componentes/Confirmacao/HooksConfirmacao';

interface IAcoesTerminalCaixa {
  permissao?: IPermissao;
}

const AcoesTerminalCaixa: React.FC<IAcoesTerminalCaixa> = ({ permissao }) => {
  const { telaGrande } = UseReactSizeMeBodyHook();
  const { loading, setLoading, getIdDetalheRegistro, formRef } = UseForm();
  const { verificarPresencaPinPad } = UseSiTef();
  const { abrirJanela } = UseConfirmacao();

  const idRegistroDetalhe = getIdDetalheRegistro();

  const possuiCertificado = formRef.current?.getFieldValue(
    'informacoesCertificado.emitidoPor'
  );

  const [showMenuSiTef, setShowMenuSiTef] = useState(false);

  const handlePresencaPinPad = useCallback(async () => {
    try {
      setLoading(true);

      const idTerminalCaixa = getIdDetalheRegistro() || '';

      const response = await verificarPresencaPinPad({ idTerminalCaixa });

      if (response) {
        await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: <span style={{ fontSize: 20 }}>PinPad Presente!</span>,
          confimar: 'OK',
        });
      } else {
        await abrirJanela({
          titulo: <h2>Confirmação</h2>,
          mensagem: <span style={{ fontSize: 20 }}>PinPad Ausente!</span>,
          confimar: 'OK',
        });
      }

      setLoading(false);
    } catch (error) {
      TratarErros(error);
      setLoading(false);
    }
  }, [abrirJanela, getIdDetalheRegistro, setLoading, verificarPresencaPinPad]);

  const handleMenuSiTef = useCallback(async () => {
    setShowMenuSiTef(true);
  }, []);

  const handleMenuSiTefSalvarFormModal = useCallback(async () => {
    setShowMenuSiTef(false);
  }, []);

  const handleMenuSiTefFecharFormModal = useCallback(async () => {
    setShowMenuSiTef(false);
  }, []);

  return (
    <>
      <SplitAcoes
        id={v4()}
        disabled={loading || !idRegistroDetalhe}
        className="btn-group"
        title={
          <div>
            <TextoLoading loading={loading}>
              <CgMoreO />
              {telaGrande && (
                <span style={{ marginLeft: 10, marginRight: 10 }}>Ações</span>
              )}
            </TextoLoading>
          </div>
        }
      >
        <Dropdown.Item
          disabled={loading || !possuiCertificado}
          onClick={handleMenuSiTef}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <CiMenuBurger style={{ marginRight: 5 }} />
            Menu SiTef
          </span>
        </Dropdown.Item>

        <Dropdown.Item
          disabled={loading || !possuiCertificado}
          onClick={handlePresencaPinPad}
        >
          <span style={{ display: 'flex', fontSize: 16 }}>
            <GiBreakingChain style={{ marginRight: 5 }} />
            Presença PinPad
          </span>
        </Dropdown.Item>
      </SplitAcoes>

      {showMenuSiTef && (
        <MenuSiTefDetalhe
          onSalvarFormModal={handleMenuSiTefSalvarFormModal}
          onFecharFormModal={handleMenuSiTefFecharFormModal}
        />
      )}
    </>
  );
};

export default AcoesTerminalCaixa;
