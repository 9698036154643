import api from '../../../../../Comum/Services/Api';

const rota = 'comercial/vendas/pedido-venda/gerar-ordem-producao-automatica';

interface IPedidoVendaGerarOrdemProducaoAutomaticaComunicadorStore {
  params?: any;
  gerarFinalizado?: boolean;
}

class PedidoVendaGerarOrdemProducaoAutomaticaComunicador {
  public async store(
    data: IPedidoVendaGerarOrdemProducaoAutomaticaComunicadorStore
  ): Promise<any> {
    const response = await api.post(rota, data);

    return response.data;
  }
}

export default new PedidoVendaGerarOrdemProducaoAutomaticaComunicador();
